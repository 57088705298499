export const DISTANCE_OPTIONS = [
  { value: 10, label: "10 Km" },
  { value: 20, label: "20 Km" },
  { value: 30, label: "30 Km" },
  { value: 40, label: "40 Km" },
  { value: 50, label: "50 Km" },
  { value: 100, label: "100 Km" },
  { value: 200, label: "200 Km" },
  { value: 300, label: "300 Km" },
  { value: 400, label: "400 Km" },
  { value: 500, label: "500 km" },
];

export const MAIN_SEARCH_CUSTOM_STYLES = {
  option: (provided) => ({
    ...provided,
    color: "black",
  }),
  control: (provided) => ({
    ...provided,
    color: "black",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "black",
  }),
};

export const MAIN_SEARCH_LOCATION_TYPES = [
  "current_location",
  "auto_location",
  "local",
  "any",
  "suggested",
];

export const COURSE_SORT_OPTIONS = [
  {
    label: "Reset",
    value: '0',
    selected: false,
    image: require("../../static/images/sort-icons/duration.svg").default,
    imageR: "",
  },
  {
    value: '1',
    label: "Fee Low to High",
    selected: false,
    image: require("../../static/images/sort-icons/Arrow up.svg").default,
    icon: "",
    imageR: "",
  },

  {
    value: '2',
    label: "Fee High to Low",
    selected: false,
    image: require("../../static/images/sort-icons/Arrow down.svg")
      .default,
    icon: "",
    imageR: "",
  },
  {
    value: '3',
    label: "Duration",
    selected: false,
    image: require("../../static/images/sort-icons/duration.svg").default,
    imageR: require("../../static/images/sort-icons/Arrow up.svg").default,
    icon: require("../../static/images/sort-icons/Arrow up.svg").default,
  },
  {
    value: '4',
    label: "Duration",
    selected: false,
    image: require("../../static/images/sort-icons/duration.svg").default,
    imageR: require("../../static/images/sort-icons/Arrow down.svg")
      .default,
    icon: require("../../static/images/sort-icons/Arrow down.svg").default,
  },
  {
    value: '5',
    label: "QS Ranking",
    selected: false,
    image: require("../../static/images/sort-icons/Arrow down.svg")
      .default,
    icon: "",
    imageR: "",
  },
];

export const COURSES_SEARCH_PARAMS = {
  discipline: 'discipline',
  specialization: 'specialization',
  degreelevel_type: 'degreelevel_type',
  country: 'country',
  state: 'state',
  institute: 'institute',
  institute_type: 'institute_type',
  course_duration: 'course_duration',
  fee_range: 'fee_range',
  order_by: 'order_by',
  page: 'page',
  intake_month: 'intake_month'
};

export const ENVIRONMENT = {
  development: 'development',
  staging: 'staging',
  beta: 'beta',
  production: 'production'
}

export const AUTH_PAGES = [
  '/login',
  '/registration',
  '/verification',
  '/recovery',
  '/settings'
]

export const DEGREE_LEVELS = [
  { "label": "Bachelor's Degree", "value": "bachelors" },
  { "label": "Master's Degree", "value": "masters" },
  { "label": "Doctorate Degree", "value": "doctorate" },
  { "label": "Associate's Degree", "value": "associates" },
  { "label": "Diploma", "value": "diploma" },
  { "label": "FSC/A-Level", "value": "FSC/A-Level" },
  { "label": "Matriculation (Matric)/O-Level", "value": "(Matric)/O-Level" }
]

export const DISCIPLINES = [
  {
    "label": "Architecture",
    "value": "architecture"
  },
  {
    "label": "Arts & Humanities",
    "value": "arts-humanities"
  },
  {
    "label": "Business & Management",
    "value": "business-management"
  },
  {
    "label": "Computer & IT",
    "value": "computer-it"
  },
  {
    "label": "Education",
    "value": "education"
  },
  {
    "label": "Engineering & Technology",
    "value": "engineering-technology"
  },
  {
    "label": "Health & Medicine",
    "value": "health-medicine"
  },
  {
    "label": "Law",
    "value": "law"
  },
  {
    "label": "Life Sciences",
    "value": "life-sciences"
  },
  {
    "label": "Physical Science & Math",
    "value": "physical-science-math"
  },
  {
    "label": "Social & Behavioural Science",
    "value": "social-behavioural-science"
  },
  {
    "label": "Tourism & Hospitality",
    "value": "tourism-hospitality"
  }
]

export const SCHOLARSHIPS = [
  'Need Base Scholarships',
  '2025 Champion Of Public Relations Excellence',
  '2025 International Masters Scholarship',
  'Fully Funded DAAD Scholarship',
  'HEC Scholarships',
  'Romanian State Scholarship Program',
  'American University Dubai Scholarships',
  '15% Foundation Program Bursary 2025 '
]