import * as ActionTypes from "../actions/ranking.action";

const countryRankingsByIDInitialState = {
	loading: false,
	data: [],
	error: "",
	success: false,
};


export const countryRankingsByID = (state = countryRankingsByIDInitialState, {type, payload}) => {
	switch (type) {
		case ActionTypes.GET_COUNTRY_RANKING:
			return {
				...state,
				loading: payload.loading,
				data: payload.data,
				error: payload.error,
				success: payload.success,
			};
		case ActionTypes.LOADING_COUNTRY_RANKING:
			return {
				...state,
				loading: payload.loading,
			};
		case ActionTypes.SUCCESS_COUNTRY_RANKING:
			return {
				...state,
				success: payload.success,
			};
		case ActionTypes.ERROR_COUNTRY_RANKING:
			return {
				...state,
				error: payload.error,
			};
		default:
			return state;
	}
};
