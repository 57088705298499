import * as Actions from "../actions/region.actions";

const allRegionsInitialState = {
    data: [],
};

export const allRegions = (
    state = allRegionsInitialState,
    action
) => {
    switch (action.type) {
        case Actions.GET_ALL_REGIONS_SUCCESS: {
            return {
                ...state,
                data: action.payload,
            };
        }
        case Actions.GET_ALL_REGIONS_FAIL: {
            return {
                ...state,
                data: null,
            };
        }
        default: {
            return state;
        }
    }
};


const allPopularCountriesInitialState = {
    data: [],
};

export const allPopularCountries = (
    state = allPopularCountriesInitialState,
    action
) => {
    switch (action.type) {
        case Actions.GET_ALL_POPULAR_COUNTRIES_SUCCESS: {
            return {
                ...state,
                data: action.payload,
            };
        }
        case Actions.GET_ALL_POPULAR_COUNTRIES_FAIL: {
            return {
                ...state,
                data: null,
            };
        }
        default: {
            return state;
        }
    }
};


const allRegionsCountriesInitialState = {
    data: [],
};

export const allRegionsCountries = (
    state = allRegionsCountriesInitialState,
    action
) => {
    switch (action.type) {
        case Actions.GET_ALL_REGIONS_COUNTRIES_SUCCESS: {
            return {
                ...state,
                data: action.payload,
            };
        }
        case Actions.GET_ALL_REGIONS_COUNTRIES_FAIL: {
            return {
                ...state,
                data: null,
            };
        }
        default: {
            return state;
        }
    }
};



const allPopularCitiesInitialState = {
    data: [],
};

export const allPopularCities = (
    state = allPopularCitiesInitialState,
    action
) => {
    switch (action.type) {
        case Actions.GET_ALL_POPULAR_CITIES_SUCCESS: {
            return {
                ...state,
                data: action.payload,
            };
        }
        case Actions.GET_ALL_POPULAR_CITIES_FAIL: {
            return {
                ...state,
                data: null,
            };
        }
        default: {
            return state;
        }
    }
};
