import React from "react";
import CustomButton from "../CustomButton";

const FeeRangeIndividual = ({
  selectedCurrency = 33,
  setFeeUpperRange,
  ...props
}) => {
  const selectedCurrencyObj = props.currencies.filter((item) => {
    return item.id === Number(selectedCurrency);
  });

  return (
    <div className="filterBox">
      <h6 className="filterOpen">
        Fee Range ({selectedCurrencyObj[0]?.display_name})
        <i className="fa fa-angle-down"></i>
      </h6>
      <div className="content show">
        <div className="filterBoxScroll">
          <div className="rangeInputs">
            <div className="inputWrap">
              <label>From:</label>
              <input
                type="number"
                value={props.feeLowerRange}
                onChange={(e) => props.handleFeeLowerRangeValue(e.target.value)}
                className="form-control"
              ></input>
            </div>
            <div className="inputWrap">
              <label>To:</label>
              <input
                type="number"
                value={props.feeUpperRange}
                onChange={(e) =>
                  props.handleFeeUpperChange(e.target.value)
                }
                className="form-control"
              ></input>
            </div>
          </div>
          <CustomButton 
            onClick={props.applyFeeRange}
            buttonText="Apply Fee Range"
          />
        </div>
      </div>
    </div>
  );
};

export default FeeRangeIndividual;
