import { useEffect, useState } from "react";
import { getDisciplinesReq } from "../../../services/apiService";
import CheckboxList from "../../../components/loaders/checklist";
import { useNavigate } from "react-router-dom-v5-compat";

export default function DisciplineFilter({
  setOpenModal,
  isBlurred,
  filterName,
  updateSearchParams,
  searchParams,
}) {

  let disciplineParams = searchParams.discipline;
  if (searchParams.discipline?.length) {
    disciplineParams = disciplineParams.split(",");
  }

  const [selectedDisciplines, setSelectedDisciplines] = useState(disciplineParams);
  const [isOpen, setIsOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [disciplineData, setDisciplineData] = useState([])

  const getFilterData = async () => {
    setIsLoading(true)
    try {
      const res = await getDisciplinesReq()
      const { data } = res;
      setDisciplineData((prevData) => [...prevData, ...data]);

    } catch (err) {
      console.log('Error', err)
    }
    setIsLoading(false)
  }

  const handleDisciplineSelect = (disciplineSlug) => {
    const _selectedDisciplines = selectedDisciplines?.includes(disciplineSlug)
      ? []
      : [disciplineSlug];

    setSelectedDisciplines(_selectedDisciplines);

    updateSearchParams({
      discipline: _selectedDisciplines.join(","),
      specialization: '',
      search: ''
    });
  };

  useEffect(() => {
    getFilterData();
  }, []);


  return (
    <>
      <div className="filterBox">
        <h6
          style={{ cursor: "pointer" }}
          onClick={() => setIsOpen((isOpen) => !isOpen)}
          className="filterOpen"
        >
          {filterName}
          {isOpen ? (
            <i className="fa fa-angle-down"></i>
          ) : (
            <i className="fa fa-angle-right"></i>
          )}
        </h6>
        {isOpen && (
          <div className="show">
            {isLoading ?
              <CheckboxList /> :
              <>
                <div className="filterBoxScroll">
                  {disciplineData.map((discipline, index) => (
                    <Discipline
                    setOpenModal={setOpenModal}
                    isBlurred={isBlurred}
                      key={index}
                      discipline={discipline}
                      selectedDisciplines={selectedDisciplines}
                      onDisciplineSelect={handleDisciplineSelect}
                      setSelectedDisciplines={setSelectedDisciplines}
                      searchParams={searchParams}
                    />
                  ))}
                </div>
              </>
            }
          </div>
        )}
      </div>
    </>
  );
}

const Discipline = ({
  setOpenModal,
  isBlurred,
  discipline,
  selectedDisciplines,
  onDisciplineSelect,
  isDisciplineArrowClicked,
  setSelectedDisciplines,
  searchParams
}) => {
  useEffect(() => {
    if(searchParams?.search.length > 0){
      setSelectedDisciplines([])
    }
}, [searchParams.search]);
const navigate= useNavigate();

  return (
    <>
      <div className="filterItemWrap">
        <div
          id={discipline?.name}
          className={"filterItem single-select " + (isDisciplineArrowClicked && "filterOpen")}
        >
          <input
            type="checkbox"
            id={discipline.slug}
            name={discipline.name}
            checked={selectedDisciplines?.includes(discipline.slug)}
            onChange={!isBlurred ?() => onDisciplineSelect(discipline.slug, discipline.name):()=>setOpenModal(true)}
          />
          <label htmlFor={discipline.slug}>{discipline.name}</label>
        </div>
      </div>
    </>
  );
};
