import {
  Box,
  Grid,
  Button,
  Chip,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { DateRangeIcon } from "@mui/x-date-pickers";
import comingSoonImg from "../../../../static/images/c-soon.gif";
import ProfileTop from "./profileTop";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
// import { DISCIPLINES } from "../../constants/index";
import { DISCIPLINES } from "../../../constants";
import { event } from "react-ga";
import { BASE_URL, http } from "../../../services/apiService/apiService";

import axios from "axios";
import {
  getAllCountriesReq,
  getAllCurrenciesReq,
  getUserInterestsReq,
  userInterestsReq,
} from "../../../services/apiService";
import { ToastContainer, toast } from "react-toastify";

const UserInterest = ({
  user,
  flow,
  csrfField,
  isMobile,
  register,
  errors,
}) => {
  const [openDisciplineDropDown, setOpenDisciplineDropDown] = useState(false);
  const [openCountriesDropDown, setOpenCountriesDropDown] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [showAllCountries, setShowAllCountries] = useState(false);
  const [userInterests, setUserInterests] = useState({});
  const [forceUpdate, setForceUpdate] = useState(true);

  const { enqueueSnackbar } = useSnackbar();

  const handleChangeDisciplineDropDown = (event, onChange) => {
    const { value } = event.target;
    onChange(value);
    setOpenDisciplineDropDown(false);
  };

  const handleChangeCountriesDropDown = (event, field) => {
    const { value } = event.target;
    field.onChange(value);
    setOpenCountriesDropDown(false);
  };
  const getAllCurrencies = async () => {
    try {
      const res = await getAllCurrenciesReq();
      const { data } = res;
      setCurrencies(data);
    } catch (err) {
      console.log("Error", err);
    }
  };
  const getAllCountries = async () => {
    try {
      const res = await getAllCountriesReq();
      setCountryList(res.data);
    } catch (error) {
      console.log("errorr getting countries", error);
    }
  };
  const {
    control: interestControl,
    register: registerInterest,
    handleSubmit: handleSubmitInterest,
    watch: watchInterest,
    setValue: setInterestValue,
    getValues: getValuesInterest,
    formState: { errors: errorsInterest },
  } = useForm({
    defaultValues: {
      disciplines: "",
      countries: [],
      month: "",
      currency: "",
    },
  });
  const selectedCurrency = watchInterest("currency");

  const handleDeleteDiscipline = (event, value) => {
    event.stopPropagation();
    const currentValues = getValuesInterest("disciplines");
    const newValues = currentValues.filter((item) => item !== value);
    setInterestValue("disciplines", newValues);
  };
  const handleDeletecountry = (event, value) => {
    event.stopPropagation();
    const currentValues = getValuesInterest("countries");
    const newValues = currentValues.filter((item) => item !== value);
    setInterestValue("countries", newValues);
  };
  const getUserIneterests = async () => {
    try {
      getAllCurrencies();
      const { data } = await getUserInterestsReq();
      const userData = data[0];
      // Handling countries
      const countryNames = userData.countries.map((country) => country.name);
      // Setting interest values
      setInterestValue("disciplines", userData.discipline);
      setInterestValue("countries", countryNames);
      setInterestValue("month", userData.intake);
      setUserInterests(userData);
      setInterestValue("currency", userData.currency);
      setInterestValue("from", userData.fee_range_min);
      setInterestValue("to", userData.fee_range_max);
    } catch (error) {
      console.log("error while fetching interests", error);
    }
  };

  const onSubmit = async (data) => {
    try {
      const payload = {
        fee_range: [data.from, data.to],
        discipline: data.disciplines,
        intake_month: data.month,
        currency: data.currency,
        countries: data.countries,
      };
      const response = await userInterestsReq(payload);
      if (response && response.data) {
        setInterestValue("disciplines", response.data.discipline);
        setInterestValue("countries", response.data.countries);
        setInterestValue("month", response.data.intake);
        setInterestValue("currency", response.data.currency);
        setInterestValue("from", response.data.fee_range_min);
        setInterestValue("to", response.data.fee_range_max);

        enqueueSnackbar("Your changes have been saved", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
      }
    } catch (error) {
      console.error("Error during API call:", error);
      enqueueSnackbar("Failed to save your changes", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    }
  };

  useEffect(() => {
    getAllCountries();
    getUserIneterests();
  }, []);
  return (
    <form
      action={flow?.ui.action}
      method={flow?.ui.method}
      onSubmit={handleSubmitInterest(onSubmit)}
    >
      {csrfField && (
        <input
          {...register("csrf_token")}
          type={csrfField.attributes.type}
          name={csrfField.attributes.name}
          value={csrfField.attributes.value}
          disabled={csrfField.attributes.disabled}
        />
      )}
      <ProfileTop />

      <Box
        className="tcf-v2"
        sx={{
          background: "#fff",
          marginTop: "40px",
          borderRadius: "12px",
        }}
      >
        <div className="p-4">
          <h4 className="font-weight-bold">Study interests</h4>
          <Divider className="pt-1 pb-1" />
        </div>
        <div className="w-100 ory-input-fields pl-4 pr-4 pt-2">
          <Grid container spacing={2} className="pb-4">
            <Grid item xs={isMobile ? 12 : 6}>
              <FormControl fullWidth error={!!errorsInterest.disciplines}>
                <InputLabel id="disciplines-label">
                  What do you want to study?
                </InputLabel>
                <Controller
                  name="disciplines"
                  control={interestControl}
                  rules={{ required: "this field is required" }}
                  render={({ field }) => (
                    <Select
                      labelId="disciplines-label"
                      id="disciplines"
                      {...field}
                      onChange={(event) =>
                        handleChangeDisciplineDropDown(event, field.onChange)
                      }
                      onClose={() => setOpenDisciplineDropDown(false)}
                      onOpen={() => setOpenDisciplineDropDown(true)}
                      open={openDisciplineDropDown}
                      label="What do you want to study?"
                      renderValue={(selected) => (
                        <span>
                          {DISCIPLINES.find((d) => d.value === selected)
                            ?.label || "select"}
                        </span>
                      )}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {DISCIPLINES.map((discipline) => (
                        <MenuItem
                          key={discipline.value}
                          value={discipline.value}
                        >
                          {discipline.label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                {errorsInterest.disciplines && (
                  <FormHelperText sx={{ color: "red !important" }}>
                    {errorsInterest.disciplines.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <FormControl fullWidth error={!!errorsInterest.countries}>
                <InputLabel id="countries-label">
                  Where do you want to study?
                </InputLabel>
                <Controller
                  name="countries"
                  control={interestControl}
                  rules={{ required: "this field is required" }}
                  render={({ field }) => (
                    <Select
                      labelId="countries-label"
                      id="countries"
                      multiple
                      value={field.value || []}
                      onChange={(event) =>
                        handleChangeCountriesDropDown(event, field)
                      }
                      onClose={() => setOpenCountriesDropDown(false)}
                      onOpen={() => setOpenCountriesDropDown(true)}
                      open={openCountriesDropDown}
                      label="Where do you want to study?"
                      renderValue={(selected) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected
                            .slice(0, showAllCountries ? selected.length : 3)
                            .map((value) => (
                              <Chip
                                key={value}
                                label={
                                  countryList.find((d) => d.name === value)
                                    ?.name
                                }
                                onDelete={(event) =>
                                  handleDeletecountry(event, value)
                                }
                                onMouseDown={(event) => event.stopPropagation()}
                              />
                            ))}
                          {selected.length > 3 && !showAllCountries && (
                            <Button
                              sx={{
                                color: "#5551FF",
                                textTransform: "none",
                                fontSize: "0.875rem",
                                padding: "4px 8px",
                              }}
                              onClick={() => setShowAllCountries(true)}
                              onMouseDown={(event) => event.stopPropagation()}
                            >
                              View More
                            </Button>
                          )}
                          {selected.length > 3 && showAllCountries && (
                            <Button
                              sx={{
                                color: "#5551FF",
                                textTransform: "none",
                                fontSize: "0.875rem",
                                padding: "4px 8px",
                              }}
                              onClick={() => setShowAllCountries(false)}
                              onMouseDown={(event) => event.stopPropagation()}
                            >
                              View Less
                            </Button>
                          )}
                          {selected.length === 0 && <span>select</span>}
                        </Box>
                      )}
                    >
                      {countryList.map((country) => (
                        <MenuItem key={country.isoCode} value={country.name}>
                          {country.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                {errorsInterest.countries && (
                  <FormHelperText sx={{ color: "red !important" }}>
                    {errorsInterest.countries.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="pb-4">
            <Grid item xs={isMobile ? 12 : 6}>
              <FormControl fullWidth error={!!errorsInterest.month}>
                <InputLabel id="month-label">
                  When do you want to start studying?
                </InputLabel>
                <Controller
                  name="month"
                  control={interestControl}
                  rules={{ required: "this field is required" }}
                  render={({ field }) => (
                    <Select
                      labelId="month-label"
                      id="month"
                      value={field.value}
                      onChange={field.onChange}
                      label="When do you want to start studying?"
                      IconComponent={DateRangeIcon}
                    >
                      <MenuItem value="">Select</MenuItem>
                      <MenuItem value="Jan,Feb,Mar">Jan,Feb,Mar</MenuItem>
                      <MenuItem value="Apr,May,Jun">Apr,May,Jun</MenuItem>
                      <MenuItem value="Jul,Aug,Sep">Jul,Aug,Sep</MenuItem>
                      <MenuItem value="Oct,Nov,Dec">Oct,Nov,Dec</MenuItem>
                    </Select>
                  )}
                />
                {errorsInterest.month && (
                  <FormHelperText sx={{ color: "red !important" }}>
                    {errorsInterest.month.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              <FormControl fullWidth error={!!errorsInterest.currency}>
                <InputLabel id="currency-label">Select Currency</InputLabel>
                <Controller
                  name="currency"
                  rules={{ required: "this field is required" }}
                  control={interestControl}
                  render={({ field }) => (
                    <Select
                      labelId="currency-label"
                      id="currency"
                      {...field}
                      label="Select Currency"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {currencies.length > 0 ? (
                        currencies
                          .sort((a, b) =>
                            a.description.localeCompare(b.description)
                          )
                          .map((item) => (
                            <MenuItem value={item.display_name} key={item.id}>
                              {item.description}
                            </MenuItem>
                          ))
                      ) : (
                        <MenuItem disabled>...Loading</MenuItem>
                      )}
                    </Select>
                  )}
                />
                {errorsInterest.currency && (
                  <FormHelperText>
                    {errorsInterest.currency.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="pb-4">
            <Grid item xs={isMobile ? 12 : 12}>
              <Typography id="fee-range-slider" gutterBottom>
                Select Fee Range ({selectedCurrency ? selectedCurrency : "USD"})
              </Typography>
            </Grid>
            <Grid container spacing={2} item xs={12}>
              <Grid item xs={isMobile ? 12 : 3}>
                <Controller
                  name="from"
                  control={interestControl}
                  rules={{
                    required: "This field is required",
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="From"
                      variant="outlined"
                      className="w-100"
                      autoComplete="off"
                      error={!!errorsInterest.from}
                      helperText={
                        errorsInterest.from ? errorsInterest.from.message : ""
                      }
                      InputLabelProps={{
                        shrink: !!field.value,
                      }}
                      onChange={(e) => {
                        const { value } = e.target;
                        if (/^\d*$/.test(value)) {
                          field.onChange(e); 
                        }
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={isMobile ? 12 : 3}>
                <Controller
                  name="to"
                  control={interestControl}
                  rules={{
                    required: "This field is required",
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="To"
                      variant="outlined"
                      className="w-100"
                      autoComplete="off"
                      error={!!errorsInterest.to}
                      helperText={
                        errorsInterest.to ? errorsInterest.to.message : ""
                      }
                      InputLabelProps={{
                        shrink: !!field.value,
                      }}
                       onChange={(e) => {
                        const { value } = e.target;
                        if (/^\d*$/.test(value)) {
                          field.onChange(e); 
                        }
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Box>
      <Box component="div" className="pt-2">
        <Grid container>
          <Grid item xs={12}>
            <Button
              variant="contained"
              className="tcf-button-small float-right mb-4"
              type="submit"
            >
              Save changes
            </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};

export default UserInterest;
