import { http } from "../../../services/apiService/apiService";


export const SEARCH_COURSE = "SEARCH_COURSE";
export const SEARCH_COURSE_SUCCESS = "SEARCH_COURSE_SUCCESS";
export const SEARCH_COURSE_FAIL = "SEARCH_COURSE_FAIL";

export const COURSE_DETAIL = "COURSE_DETAIL";
export const COURSE_DETAIL_SUCCESS = "COURSE_DETAIL_SUCCESS";
export const COURSE_DETAIL_FAIL = "COURSE_DETAIL_FAIL";

export const DISCIPLINE_COURSE = "DISCIPLINE_COURSE_DETAIL";
export const DISCIPLINE_COURSE_SUCCESS = "DISCIPLINE_COURSE_DETAIL_SUCCESS";
export const DISCIPLINE_COURSE_FAIL = "DISCIPLINE_COURSE_DETAIL_FAIL";

export const COUNTRY_DISCIPLINE_COURSE = "COUNTRY_COURSE_DETAIL";
export const COUNTRY_DISCIPLINE_COURSE_SUCCESS =
  "COUNTRY_COURSE_DETAIL_SUCCESS";
export const COUNTRY_DISCIPLINE_COURSE_FAIL = "COUNTRY_COURSE_DETAIL_FAIL";

export function searchCourse(params) {
  const url = "course/search?" + params;
  const request = http.get(url);

  return (dispatch) =>
    request
      .then((response) =>
        dispatch({
          type: SEARCH_COURSE_SUCCESS,
          payload: response.data,
        })
      )
      .catch((error) => {
        return dispatch({
          type: SEARCH_COURSE_FAIL,
          payload: [],
        });
      });
}

export const SEARCH_COURSES = "SEARCH_COURSES";
export const SEARCH_COURSES_SUCCESS = "SEARCH_COURSES_SUCCESS";
export const SEARCH_COURSES_FAIL = "SEARCH_COURSES_FAIL";

export function searchCourses(params, pageId) {
  let url = "course/course_search/";
  if (pageId) {
    url = url + "?page=" + pageId;
  }
  const request = http.post(url, params);

  return (dispatch) =>
    request
      .then((response) =>
        dispatch({
          type: SEARCH_COURSES_SUCCESS,
          payload: response.data,
        })
      )
      .catch((error) => {
        return dispatch({
          type: SEARCH_COURSES_FAIL,
          payload: [],
        });
      });
}

export function searchCourseWithPagination(url) {
  const request = http.get(url);

  return (dispatch) =>
    request
      .then((response) =>
        dispatch({
          type: SEARCH_COURSE_SUCCESS,
          payload: response.data,
        })
      )
      .catch((error) => {
        return dispatch({
          type: SEARCH_COURSE_FAIL,
          payload: [],
        });
      });
}

export function getCourseDetail(courseId) {
  const url = "course/" + courseId;
  const request = http.get(url);
  return (dispatch) =>
    request
      .then((response) =>
        dispatch({
          type: COURSE_DETAIL_SUCCESS,
          payload: response.data,
        })
      )
      .catch((error) => {
        return dispatch({
          type: COURSE_DETAIL_FAIL,
          payload: [],
        });
      });
}

export function getDisciplineCourse(instituteId, params, pageId) {
  let url = "course/institute/" + instituteId + "/";
  if (pageId) {
    url = url + "/?page=" + pageId;
  }
  const request = http.post(url, params);

  return (dispatch) =>
    request
      .then((response) =>
        dispatch({
          type: DISCIPLINE_COURSE_SUCCESS,
          payload: response.data,
        })
      )
      .catch((error) => {
        return dispatch({
          type: DISCIPLINE_COURSE_FAIL,
          payload: [],
        });
      });
}
export function getCountryDisciplineCourse(countryId, params, pageId) {
  let url = "course/country-courses/" + countryId + "/";
  if (pageId) {
    url = "course/country-courses/" + countryId + "/?page=" + pageId + "/";
  }
  const request = http.post(url, params);

  return (dispatch) =>
    request
      .then((response) =>
        dispatch({
          type: COUNTRY_DISCIPLINE_COURSE_SUCCESS,
          payload: response.data,
        })
      )
      .catch((error) => {
        return dispatch({
          type: COUNTRY_DISCIPLINE_COURSE_FAIL,
          payload: [],
        });
      });
}

// filters apis

export const COURSE_RELATED_LOCATIONS = "COURSE_RELATED_LOCATIONS";
export const COURSE_RELATED_LOCATIONS_SUCCESS =
  "COURSE_RELATED_LOCATIONS_SUCCESS";
export const COURSE_RELATED_LOCATIONS_FAIL = "COURSE_RELATED_LOCATIONS_FAIL";

export function getCourseRelatedLocations(params) {
  const url = "course/course_related_locations/";
  const request = http.post(url, params);

  return (dispatch) =>
    request
      .then((response) =>
        dispatch({
          type: COURSE_RELATED_LOCATIONS_SUCCESS,
          payload: response.data,
        })
      )
      .catch((error) => {
        return dispatch({
          type: COURSE_RELATED_LOCATIONS_FAIL,
          payload: [],
        });
      });
}

export const COURSE_RELATED_DEGREE_LEVELS = "COURSE_RELATED_DEGREE_LEVELS";
export const COURSE_RELATED_DEGREE_LEVELS_SUCCESS =
  "COURSE_RELATED_DEGREE_LEVELS_SUCCESS";
export const COURSE_RELATED_DEGREE_LEVELS_FAIL =
  "COURSE_RELATED_DEGREE_LEVELS_FAIL";

export function getCourseRelatedDegreelevels(params) {
  const url = "course/course_related_degree_levels/";
  const request = http.post(url, params);

  return (dispatch) =>
    request
      .then((response) =>
        dispatch({
          type: COURSE_RELATED_DEGREE_LEVELS_SUCCESS,
          payload: response.data,
        })
      )
      .catch((error) => {
        return dispatch({
          type: COURSE_RELATED_DEGREE_LEVELS_FAIL,
          payload: [],
        });
      });
}

export const COURSE_RELATED_SPECIALIZATIONS = "COURSE_RELATED_SPECIALIZATIONS";
export const COURSE_RELATED_SPECIALIZATIONS_SUCCESS =
  "COURSE_RELATED_SPECIALIZATIONS_SUCCESS";
export const COURSE_RELATED_SPECIALIZATIONS_FAIL =
  "COURSE_RELATED_DEGREE_LEVELS_FAIL";

export function getCourseRelatedSpecialization(params) {
  const url = "course/course_related_specializations_new/";
  const request = http.post(url, params);

  return (dispatch) =>
    request
      .then((response) =>
        dispatch({
          type: COURSE_RELATED_SPECIALIZATIONS_SUCCESS,
          payload: response.data,
        })
      )
      .catch((error) => {
        return dispatch({
          type: COURSE_RELATED_SPECIALIZATIONS_FAIL,
          payload: [],
        });
      });
}
export const COURSE_RELATED_INSTITUTE_NAME = "COURSE_RELATED_INSTITUTE_NAME";
export const COURSE_RELATED_INSTITUTE_NAME_SUCCESS =
  "COURSE_RELATED_INSTITUTE_NAME_SUCCESS";
export const COURSE_RELATED_INSTITUTE_NAME_FAIL =
  "COURSE_RELATED_INSTITUTE_NAME_FAIL";

export function getCourseRelatedInstituteName(params) {
  const url = "course/course_related_institutes/";
  const request = http.post(url, params);

  return (dispatch) =>
    request
      .then((response) =>
        dispatch({
          type: COURSE_RELATED_INSTITUTE_NAME_SUCCESS,
          payload: response.data,
        })
      )
      .catch((error) => {
        return dispatch({
          type: COURSE_RELATED_INSTITUTE_NAME_FAIL,
          payload: [],
        });
      });
}

export const COURSE_RELATED_INTAKES = "COURSE_RELATED_INTAKES";
export const COURSE_RELATED_INTAKES_SUCCESS = "COURSE_RELATED_INTAKES_SUCCESS";
export const COURSE_RELATED_INTAKES_FAIL = "COURSE_RELATED_INTAKES_FAIL";

export function getCourseRelatedIntakes(params) {
  const url = "course/course_related_intakes/";
  const request = http.post(url, params);

  return (dispatch) =>
    request
      .then((response) =>
        dispatch({
          type: COURSE_RELATED_INTAKES_SUCCESS,
          payload: response.data,
        })
      )
      .catch((error) => {
        return dispatch({
          type: COURSE_RELATED_INTAKES_FAIL,
          payload: [],
        });
      });
}

export const COURSE_COMPARE_DETAIL = "COURSE_COMPARE_DETAIL";
export const COURSE_COMPARE_DETAIL_SUCCESS = "COURSE_COMPARE_DETAIL_SUCCESS";
export const COURSE_COMPARE_DETAIL_FAIL = "COURSE_COMPARE_DETAIL_FAIL";

export function getCourseCompareDetail(courseId, fee_id, duration_id) {
	const url = `course/${courseId}/course_compare`;
  
	const request = http.get(url);
  
	return (dispatch) =>
	  request
		.then((response) => {
		  
		  const filteredFee = response.data.fee.filter((fee) => fee.id == fee_id);
  
		  const filteredDuration = response.data.course_duration.filter((duration) => duration.id == duration_id);
  
		  const defaultFee = filteredFee.length > 0 ? filteredFee : [{ id: 0, type: '', currency: null, ceil_value: 0, floor_value: 0 }];
		  const defaultDuration = filteredDuration.length > 0 ? filteredDuration : [{ id: 0, type: '', duration_one: 0, duration_two: 0, duration_three: 0 }];
  
		  const filteredPayload = {
			...response.data,
			fee: defaultFee,
			course_duration: defaultDuration,
		  };
  
		return  dispatch({
			type: COURSE_COMPARE_DETAIL_SUCCESS,
			payload: filteredPayload,
		  });
  
		
		})
		.catch((error) => {
		  console.error("Error fetching course compare detail:", error);
		  dispatch({
			type: COURSE_COMPARE_DETAIL_FAIL,
			payload: [],
		  });
		  throw error; 
		});
  }
  
