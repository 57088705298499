import * as Actions from "../actions/country.actions";

const allCountriesInitialState = {
	data: [],
};

export const allCountries = (state = allCountriesInitialState, action) => {
	switch (action.type) {
		case Actions.GET_ALL_COUNTRIES_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.GET_ALL_COUNTRIES_FAIL: {
			return {
				...state,
				data: null,
			};
		}
		default: {
			return state;
		}
	}
};

const countryDetailInitialState = {
	data: {},
};

export const countryDetail = (state = countryDetailInitialState, action) => {
	switch (action.type) {
		case Actions.GET_COUNTRY_DETAIL_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.GET_COUNTRY_DETAIL_FAIL: {
			return {
				...state,
				data: null,
			};
		}
		default: {
			return state;
		}
	}
};

const universitiesLocationInitialState = {
	data: [],
	universitiesLocation:[]
};

export const allUniversitiesLocation = (
	state = universitiesLocationInitialState,
	{type,payload}
) => {
	switch (type) {
		case Actions.GET_ALL_INSTITUTE_LOCATION_SUCCESS: {
			return {
				...state,
				data: payload,
			};
		}
		case Actions.GET_ALL_INSTITUTE_LOCATION_FAIL: {
			return {
				...state,
				data: null,
			};
		}
		case Actions.UPDATE_ALL_INSTITUTE_LOCATION: {
			return {
				...state,
				universitiesLocation: payload,
			};
		}
		default: {
			return state;
		}
	}
};

const allCountriesInstitutesInitialState = {
	data: [],
};

export const allInstitutesByCountry = (
	state = allCountriesInstitutesInitialState,
	action
) => {
	switch (action.type) {
		case Actions.GET_COUNTRY_INSTITUTES_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.GET_COUNTRY_INSTITUTES_FAIL: {
			return {
				...state,
				data: null,
			};
		}
		default: {
			return state;
		}
	}
};
const countryAllFAQsInitialState = {
	data: [],
};

export const allFAQsByCountry = (
	state = countryAllFAQsInitialState,
	action
) => {
	switch (action.type) {
		case Actions.GET_COUNTRY_FAQS_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.GET_COUNTRY_FAQS_FAIL: {
			return {
				...state,
				data: null,
			};
		}
		default: {
			return state;
		}
	}
};

const allCountriesWithDetailInitialState = {
	data: [],
};

export const allCountriesWithDetail = (
	state = allCountriesWithDetailInitialState,
	action
) => {
	switch (action.type) {
		case Actions.GET_ALL_COUNTRIES_WITH_DETAIL_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.GET_ALL_COUNTRIES_WITH_DETAIL_FAIL: {
			return {
				...state,
				data: null,
			};
		}
		default: {
			return state;
		}
	}
};

const getCountryDisciplineRelatedDegreeLevelsInitialState = {
	data: [],
};

export const countryDisciplineRelatedDegreeLevels = (
	state = getCountryDisciplineRelatedDegreeLevelsInitialState,
	action
) => {
	switch (action.type) {
		case Actions.GET_COUNTRY_DISCIPLINE_RELATED_DEGREE_LEVELS_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.GET_COUNTRY_DISCIPLINE_RELATED_DEGREE_LEVELS_FAIL: {
			return {
				...state,
				data: null,
			};
		}
		default: {
			return state;
		}
	}
};

const getCountryDisciplineRelatedIntakesInitialState = {
	data: [],
};

export const countryDisciplineRelatedIntakes = (
	state = getCountryDisciplineRelatedIntakesInitialState,
	action
) => {
	switch (action.type) {
		case Actions.GET_COUNTRY_DISCIPLINE_RELATED_INTAKES_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.GET_COUNTRY_DISCIPLINE_RELATED_INTAKES_FAIL: {
			return {
				...state,
				data: null,
			};
		}
		default: {
			return state;
		}
	}
};

const getCountryDisciplineRelatedSpecializationsInitialState = {
	data: [],
};

export const countryDisciplineRelatedSpecializations = (
	state = getCountryDisciplineRelatedSpecializationsInitialState,
	action
) => {
	switch (action.type) {
		case Actions.GET_COUNTRY_DISCIPLINE_RELATED_SPECIALIZATIONS_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.GET_COUNTRY_DISCIPLINE_RELATED_SPECIALIZATIONS_FAIL: {
			return {
				...state,
				data: null,
			};
		}
		default: {
			return state;
		}
	}
};

const getCountryDisciplineRelatedInstitutesInitialState = {
	data: [],
};

export const countryDisciplineRelatedInstitutes = (
	state = getCountryDisciplineRelatedInstitutesInitialState,
	action
) => {
	switch (action.type) {
		case Actions.GET_COUNTRY_DISCIPLINE_RELATED_INSTITUTES_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.GET_COUNTRY_DISCIPLINE_RELATED_INSTITUTES_FAIL: {
			return {
				...state,
				data: null,
			};
		}
		default: {
			return state;
		}
	}
};

const getCountryDisciplineRelatedLocationsInitialState = {
	data: [],
};

export const countryDisciplineRelatedLocations = (
	state = getCountryDisciplineRelatedLocationsInitialState,
	action
) => {
	switch (action.type) {
		case Actions.GET_COUNTRY_DISCIPLINE_RELATED_LOCATIONS_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.GET_COUNTRY_DISCIPLINE_RELATED_LOCATIONS_FAIL: {
			return {
				...state,
				data: null,
			};
		}
		default: {
			return state;
		}
	}
};
