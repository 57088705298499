export const ADD_UNIVERSITY_PROGRAM_FILTER_DATA =
	"ADD_UNIVERSITY_PROGRAM_FILTER_DATA";
export const ADD_UNIVERSITY_PROGRAM_FILTER_DATA_SUCCESS =
	"ADD_UNIVERSITY_PROGRAM_FILTER_DATA_SUCCESS";
export const ADD_UNIVERSITY_PROGRAM_FILTER_DATA_FAIL =
	"ADD_UNIVERSITY_PROGRAM_FILTER_DATA_FAIL";

export function addUniversityProgramFilterData(data = {}) {
	return (Dispatch) =>
		Dispatch({
			type: "ADD_UNIVERSITY_PROGRAM_FILTER_DATA_SUCCESS",
			payload: data,
		});
}
