import {http} from "../../../services/apiService/apiService";
import axios from "axios";
import {handleLoadingDispatcher, handleErrorDispatcher, handleSuccessDispatcher, dataDispatcher} from "./data.handler"


export const LOADING_COUNTRY_RANKING = "LOADING_COUNTRY_RANKING";
export const GET_COUNTRY_RANKING = "GET_COUNTRY_RANKING";
export const SUCCESS_COUNTRY_RANKING = "SUCCESS_COUNTRY_RANKING";
export const ERROR_COUNTRY_RANKING = "ERROR_COUNTRY_RANKING";


export const getCountryRanking = (countryId, orderBy = null, pageId = null) => async (dispatch) => {
	dispatch(handleLoadingDispatcher(LOADING_COUNTRY_RANKING, true));
	dispatch(handleSuccessDispatcher(SUCCESS_COUNTRY_RANKING, false));
	let url = "country/" + countryId + "/institutes/rankings?";
	if (pageId) {
		url = url + "&page=" + pageId;
	}
	if (orderBy) {
		url = url + "&order=" + orderBy;
	}

	try {
		url = process.env.REACT_APP_BASE_URL + url
		const response = await axios.get(url);
		dispatch(
			dataDispatcher(GET_COUNTRY_RANKING, {
				loading: false,
				data: response.data,
				error: "",
				success: true,
			})
		);
	} catch (e) {
		dispatch(handleErrorDispatcher(ERROR_COUNTRY_RANKING, e.response.data.message));
		dispatch(handleLoadingDispatcher(LOADING_COUNTRY_RANKING, false));
	}
};
