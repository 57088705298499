import { useCallback, useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom-v5-compat"
import { ory, useSdkError } from "../../ory/pkg/sdk"
import registerImg from "../../../static/images/auth/forgot-bg.jpeg"
import logo from "../../../static/images/tcf.svg"

import { Button, FormHelperText, TextField, } from "@mui/material"
import { ThemeProvider, useTheme } from '@mui/material/styles';
import { Link } from "react-router-dom"
import { useForm } from "react-hook-form"
import LoadingSpinner from "../../components/loader/loader"
import { inputTheme } from "../../shared/utils/muiInputTheme"
import OTPInput from "../../components/OTPInput"

const Recovery = () => {
  const outerTheme = useTheme();
  const [flow, setFlow] = useState(null)
  const [searchParams, setSearchParams] = useSearchParams()
  const [otp, setOtp] = useState('');
  const [showEmailField, setShowEmailField] = useState(true)

  const { register, handleSubmit, getValues, formState: { errors } } = useForm();

  const navigate = useNavigate()

  const getFlow = useCallback(
    (flowId) =>
      ory
        .getRecoveryFlow({ id: flowId })
        .then(({ data: flow }) => setFlow(flow))
        .catch(sdkErrorHandler),
    [],
  )

  const sdkErrorHandler = useSdkError(getFlow, setFlow, "/recovery")

  const emailField = flow?.ui.nodes.find(node => node.attributes.name === 'email');
  const csrfField = flow?.ui.nodes.find(node => node.attributes.name === 'csrf_token');
  const otpField = flow?.ui.nodes.find(node => node.attributes.name === 'code');

  const createFlow = () => {
    ory
      .createBrowserRecoveryFlow()
      // flow contains the form fields, error messages and csrf token
      .then(({ data: flow }) => {
        // Update URI query params to include flow id
        setSearchParams({ ["flow"]: flow.id })
        // Set the flow data
        setFlow(flow)
      })
      .catch(sdkErrorHandler)
  }

  const onResend = () => {
    let body = {
      email: getValues("email"),
      csrf_token: getValues("csrf_token"),
      code: "",
      method: getValues("method")
    }
    setOtp("")
    submitFlow(body)
  }

  const submitFlow = (body) => {
    setShowEmailField(false)
    // something unexpected went wrong and the flow was not set
    if (!flow) return navigate("/login", { replace: true })

    if(otp.length > 0){
      const { email, ..._body } = { ...body };
      body = _body
    }
    body.code = otp
    ory
      .updateRecoveryFlow({ flow: flow.id, updateRecoveryFlowBody: body })
      .then(({ data }) => {
        setFlow(data)
      })
      .catch(sdkErrorHandler)
  }

  useEffect(() => {
    // we might redirect to this page after the flow is initialized, so we check for the flowId in the URL
    const flowId = searchParams.get("flow")
    // the flow already exists
    if (flowId) {
      getFlow(flowId).catch(createFlow) // if for some reason the flow has expired, we need to get a new one
      return
    }
    // we assume there was no flow, so we create a new one
    createFlow()
  }, [])
  // we check if the flow is set, if not we show a loading indicator
  return flow ? (
    <div className="container-fluid">
      <div className="row auth verification align-items-center"style={{ minHeight: '100vh' }}>
        <div className="col-sm-12 col-md-12 col-lg-5 ory-fields-container">
          <div className="logo w-100 text-center">
            <Link to="/">
              <img src={logo} alt="logo" />
            </Link>
          </div>

          <div className="title-container pt-4">
            <h2>Forgot Password</h2>
          </div>

          {flow.ui.messages &&
            <FormHelperText className={`${flow?.ui?.messages[0].type === "error" ? "Mui-error" : ""} ory-helper-text pt-1 pb-4`}>{flow?.ui?.messages[0].text}</FormHelperText>
          }

          <div className="w-100 ory-input-fields">
            <ThemeProvider theme={inputTheme(outerTheme)}>
              <form
                action={flow.ui.action}
                method={flow.ui.method}
                onSubmit={handleSubmit(submitFlow)}
              >
                {showEmailField && flow.state !== "sent_email" &&
                  <>
                    <p>Enter your email address in the below field to reset your account password.</p>

                    <TextField
                      error={errors.email}
                      {...register("email", {
                        required: true,
                        maxLength: 30,
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: "Entered value does not match email format. (e.g., user@example.com)",
                        },
                      })}
                      type={emailField.type}
                      label="Email"
                      variant="outlined"
                      className="w-100 pb-2"
                      helperText={errors.email?.type === "required" ? "This field is required" : errors.email?.message}

                    />
                  </>
                }
                {otpField &&
                  <OTPInput {...register("code")} value={otp} onChange={setOtp} length={6} />
                }
                {csrfField &&
                  <input
                    {...register("csrf_token")}
                    type={csrfField.attributes.type}
                    name={csrfField.attributes.name}
                    value={csrfField.attributes.value}
                    disabled={csrfField.attributes.disabled}
                  />
                }

                <input {...register("method")} type="hidden" value="code" />

                <Button
                  variant="contained"
                  name="method"
                  type="submit"
                  value="code"
                  disabled={flow.state === "sent_email" && otp.length !== 6}
                  className="w-100 ory-submit-button mt-4"
                >
                  {flow.state !== "sent_email" ? "Reset Password" : "Validate"}
                </Button>
              </form>
            </ThemeProvider>
            {showEmailField && flow.state !== "sent_email"  ?
              <div className="need-account pt-4 text-center">
                <p>Already have an account? <Link to={`/login`}>Sign in</Link></p>
              </div> :
              <div className="need-account pt-4 text-center">
                <p>Didn't get the code? <Button variant="text" onClick={onResend}>Resend</Button></p>
              </div>
            }
          </div>
        </div>
        <div className="col-7 d-none d-lg-block p-0">
          <div className="image-container register">
            <div className="overlay"></div>
            <img className="main-image" src={registerImg} alt="Recovery" />
          </div>
        </div>
      </div>

    </div>
  ) : (
    <LoadingSpinner height={'100vh'} />
  )
}

export default Recovery
