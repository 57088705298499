import * as Actions from "../actions/top_words";

const topWordsInitialState = {
    data: [],
};

export const allTopWords= (
    state = topWordsInitialState,
    action
) => {
    switch (action.type) {
        case Actions.GET_ALL_TOP_WORDS_SUCCESS: {
            return {
                ...state,
                data: action.payload,
            };
        }
        case Actions.GET_ALL_TOP_WORDS_FAIL: {
            return {
                ...state,
                data: null,
            };
        }
        default: {
            return state;
        }
    }
};

