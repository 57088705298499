import React, { useState, useEffect } from "react";
import $ from "jquery";
import followIcon from "../../../static/images/follow-white.png";
import shareIcon from "../../../static/images/share-black.png";
import tcfRankingImg from "../../../static/images/fact4.svg";
import typeImg from "../../../static/images/fact1.svg";
import yearImg from "../../../static/images/fact2.svg";
import locationImg from "../../../static/images/fact3.svg";

import {
  EmailShareButton,
  FacebookMessengerShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  FacebookIcon,
  EmailIcon,
  WhatsappIcon,
  TwitterIcon,
} from "react-share";

const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

function UniversityIndividualBanner(props) {
  const [url, setUrl] = useState(window.location.href);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [textColor, setTextColor] = useState(getRandomColor());

  useEffect(() => {
    setTextColor(getRandomColor());
  }, [props.university.logo]);

  const handleModalOpen = () => {
    localStorage.setItem("ShareModal", true);
    $("#share").modal("show");
  };

  const handleImageError = () => {
    props.setLogoError(true);
    setImageLoaded(true);
  };

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const getInitials = (name) => {
    if (!name) return "";
    return name
      .split(" ")
      .map((word) => word[0].toUpperCase())
      .join("");
  };

  return (
    <div className="disciplineBanner">
      <img
        src="https://assets.timescoursefinder.com/Media/banner-large.jpg"
        alt="banner"
      />
      <div className="disciplineFlex">
        <div className="left">
          <a href="#">
            {props.logoError ? (
              <div className="defaultLogo" style={{ color: textColor }}>
                {getInitials(props.university.institute_name)}
              </div>
            ) : (
              <img
                src={
                  props.university.logo
                    ? props.university.logo
                    : "invalid_image_source"
                }
                alt="university_logo"
                onError={handleImageError}
                onLoad={handleImageLoad}
              />
            )}
          </a>
        </div>
        <div className="right">
          <h2 style={{ color: "#fff" }}>{props.university.institute_name}</h2>
          <div className="whiteWrap">
            <h2>University Facts</h2>
            <div className="factFlex">
              <div className="factBox">
                <img src={typeImg} alt="Type" />
                <div>
                  <label>Type</label>
                  <p>{props.university.sector}</p>
                </div>
              </div>
              <div className="factBox">
                <img src={yearImg} alt="established_year" />
                <div>
                  <label>Established Year</label>
                  <p>{props.university.established}</p>
                </div>
              </div>
              <div className="factBox">
                <img src={locationImg} alt="location" />
                <div>
                  <label>Location</label>
                  <p>{props.university?.country?.name}</p>
                </div>
              </div>
              <div className="factBox">
                <img src={tcfRankingImg} alt="tcf_ranking" />
                <div id="factBox">
                  <label>TCF Ranking</label>
                  <p>
                    {props?.university?.tcf_ranking
                      ? props?.university?.tcf_ranking
                      : "--"}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="uniBannerBtnGroup">
            <button className="btn">
              <img src={followIcon} alt="banner" />
              Follow
            </button>
            <button className="btn" onClick={handleModalOpen}>
              <img src={shareIcon} alt="banner" />
              Share
            </button>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="share"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="shareLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="shareModalLabel">
                Share via
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  localStorage.removeItem("ShareModal");
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <FacebookMessengerShareButton
                appId={"867455750856341"}
                redirectUri={url}
                to={"Usman Awan"}
                link={url}
                title="Tap the Link below to open website "
              >
                <FacebookIcon size={"2rem"} />
              </FacebookMessengerShareButton>
              {"   "}
              <EmailShareButton
                subject={"Course Detail"}
                body={url}
                title="Tap the Link below to open website"
              >
                <EmailIcon size={"2rem"} />
              </EmailShareButton>
              {"   "}
              <WhatsappShareButton
                url={url}
                title={"Tap the Link below to open website"}
              >
                <WhatsappIcon size="2rem" />
              </WhatsappShareButton>
              {"   "}
              <TwitterShareButton
                url={url}
                title="Tap the Link below to open website"
              >
                <TwitterIcon size="2rem" />
              </TwitterShareButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UniversityIndividualBanner;
