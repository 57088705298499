import { useState, useEffect } from "react";
import SearchBanner from "./SearchPage.SearchBanner";
import Filters from "./SearchPage.Filters";
import Courses from "./SearchPage.Courses";
import {
  getAllCurrenciesReq,
  searchCourseCountReq,
  searchCourseReq,
} from "../../services/apiService";
import { COURSE_SORT_OPTIONS } from "../../constants";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../../apis/store/actions";
import { useMatch } from "react-router-dom-v5-compat";
import LoginModal from "../../components/LoginModal-v1";

const SearchPage = () => {
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const [courseLoader, setCourseLoader] = useState(true);
  const [countLoader, setCountLoader] = useState(true);
  const [courses, setCourses] = useState([]);
  const [coursesCount, setCoursesCount] = useState(null);
  const [currencies, setCurrencies] = useState([]);
  const [greaterCount, setGreaterCount] = useState(false);
  const [searchParams, setSearchParams] = useState({
    fee_range: urlSearchParams.get("fee_range") || "",
    discipline: urlSearchParams.get("discipline") || "",
    degreelevel: urlSearchParams.get("degreelevel") || "",
    specialization: urlSearchParams.get("specialization") || "",
    course_duration: urlSearchParams.get("course_duration") || "",
    institute_type: urlSearchParams.get("institute_type") || "",
    institute: urlSearchParams.get("institute") || "",
    degreelevel_type: urlSearchParams.get("degreelevel_type") || "",
    order_by: parseInt(urlSearchParams.get("order_by")) || "",
    intake_month: urlSearchParams.get("intake_month") || "",
    country: urlSearchParams.get("country") || "",
    state: urlSearchParams.get("state") || "",
    city: urlSearchParams.get("city") || "",
    search: urlSearchParams.get("search") || "",
    page: parseInt(urlSearchParams.get("page")) || 1,
    currency: urlSearchParams.get("currency") || "",
  });
  const [selectedSortOption, setSelectedSortOption] = useState(
    parseInt(urlSearchParams.get("order_by")).order_by || ""
  );

  const [openModal, setOpenModel] = useState(false);
  const [showView, setShowView] = useState("grid");
  const [pageState, setPageState] = useState({
    offset: 0,
    perPage: 21,
    currentPage: 1,
    pageCount: 0,
  });

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const isUserRoute = useMatch("/search");
  const dispatch = useDispatch();

  const updateSearchParams = (newParams) => {
    setSearchParams((prevParams) => {
      const updatedParams = { ...prevParams, ...newParams };    
      const otherParamsChanged = Object.keys(newParams).some(
        (key) => key !== "page" && key !== "currency" && prevParams[key] !== newParams[key]
      );
      if (otherParamsChanged) {
        updatedParams.page = 1;
      }

      // Check if 'page' or otherParamsChanged triggered an update, then scroll to top
      if (updatedParams.page !== prevParams.page || otherParamsChanged) {
        window.scrollTo({ top: 200, behavior: 'smooth' });
      }
      updateQueryParams(updatedParams);
      return updatedParams;
    });
  };
  
  const [firstLoad, setFirstLoad] = useState(true);
  const history = useHistory();

  const getAllCurrencies = async () => {
    try {
      const res = await getAllCurrenciesReq();
      const { data } = res;
      setCurrencies(
        data.sort((a, b) => a.description.localeCompare(b.description))
      );
    } catch (err) {
      console.log(err);
    }
  };
  const getSearchedCourses = async (marketingCards) => {
    setCourseLoader(true);
    setCountLoader(true);

    try {
      let params = {
        ...searchParams,
        first_load: firstLoad ? "True" : "False",
        currency: searchParams.currency
          ? searchParams.currency.toLowerCase()
          : "",
      };

      const res = await searchCourseReq(params);

      const { data } = res;

      let withMarketingCard = data.result;
      var marketingCards_new = marketingCards;
      let obj = [];
      let index = 6;

      if (marketingCards) {
        if (withMarketingCard.length >= 21) {
          marketingCards_new.sort((a, b) => a.priority - b.priority);
          if (marketingCards_new.length < 4) {
            marketingCards.sort((a, b) => a.priority - b.priority);
            marketingCards_new = marketingCards_new.concat(...marketingCards);
            obj = [];
          }
          let j = 0;
          for (let i = 0; i < marketingCards_new.length; i++) {
            if (i <= 3) {
              withMarketingCard.splice(index, 0, marketingCards_new[i]);
              obj.push({
                id: marketingCards_new[i].id,
                seen_status: true,
              });
            }
            index = index + 7;
            j = j + 1;
          }

          setCourses(withMarketingCard);
        } else {
          setCourses(data.result);
        }
      } else {
        setCourses(data.result);
      }

      setCourseLoader(false);

      try {
        const resCount = await searchCourseCountReq(params);
        const { data: countData } = resCount;
        setCoursesCount(countData.count);
        setPageState((prevState) => ({
          ...prevState,
          currentPage: searchParams.page,
          pageCount: Math.ceil(countData.count / countData.rows_per_page),
          offset: (searchParams.page - 1) * prevState.perPage,
          perPage: countData.rows_per_page,
        }));
        if (parseInt(searchParams.page) > countData.total_pages) {
          setGreaterCount(true);
        } else {
          setGreaterCount(false);
        }
      } catch (err) {
        console.error("Error while getting courses count:", err);
      } finally {
        setCountLoader(false);
      }
    } catch (err) {
      console.log("Error while getting courses: ", err);
      setCourseLoader(false);
      setCountLoader(false);
    }
  };

  const handlePageClick = (pageNum) => {
    updateSearchParams({ page: pageNum });
  };

  const clickSortOption = (option, e) => {
    setSelectedSortOption(option);

    if (option.value === "reset") {
      updateSearchParams({
        order_by: "",
        campus__institute__instituteranking__ranking_type: "",
      });
    } else {
      updateSearchParams({ order_by: option.value });
    }
  };

  const handleCloseMOdal = () => {
    setOpenModel(false);
  };
  const handlePopularityCheck = (value, flag) => {
    if (flag) {
      updateSearchParams({ popularity: value });
    } else {
      updateSearchParams({ popularity: "" });
    }
  };

  const checkView = (view) => {
    setShowView(view);
  };

  const updateQueryParams = (params) => {
    const urlParams = new URLSearchParams();

    Object.entries(params).forEach(([paramName, paramValue]) => {
      if (paramValue !== "") {
        urlParams.set(paramName, paramValue.toString());
      }
    });

    history.push({ search: urlParams.toString() });
  };

  useEffect(() => {
    getAllCurrencies();
    setFirstLoad(false);
  }, []);

  useEffect(() => {
    dispatch(Actions.getAllMarketingCard(searchParams.page))
      .then((result) => {
        if (searchParams.discipline || searchParams.search) {
          getSearchedCourses(result.payload.results);
        }
      })
      .catch((err) => {
        console.log(err);
        getSearchedCourses();
      });
  }, [searchParams]);

  return (
    <div className="main">
      {/* Top Banner with Search Bar */}
      {isUserRoute && (
        <SearchBanner
          updateSearchParams={updateSearchParams}
          searchParams={searchParams}
        />
      )}

      <div className="container">
        <div className="mainFlex">
          <div className="sidebar filterSidebar webFilter v2">
            <Filters
              setOpenModal={setOpenModel}
              searchParams={searchParams}
              updateSearchParams={updateSearchParams}
              setSearchParams={setSearchParams}
            />
          </div>
          {/* Main Content Area */}
          <Courses
            setOpenModal={setOpenModel}
            greaterCount={greaterCount}
            showView={showView}
            courses={courses}
            coursesCount={coursesCount}
            courseLoader={courseLoader}
            pageState={pageState}
            sortOptions={COURSE_SORT_OPTIONS}
            currencies={currencies}
            advance={true}
            showCounter={true}
            handlePageClick={handlePageClick}
            clickSortOption={clickSortOption}
            selectedSortOption={selectedSortOption}
            checkView={checkView}
            countLoader={countLoader}
            searchParams={searchParams}
            handlePopularityCheck={handlePopularityCheck}
            updateSearchParams={updateSearchParams}
            setSearchParams={setSearchParams}
            coursesToShow={pageState.perPage || 21}
          />
        </div>
        <LoginModal openModal={openModal} closeModal={handleCloseMOdal} />
      </div>
    </div>
  );
};

export default SearchPage;
