import { Close } from "@material-ui/icons";
import { Box, Button, Modal } from "@mui/material";
import { useNavigate } from "react-router-dom-v5-compat";

const LoginModal = ({
  openModal,
  closeModal
}) => {
  const navigate = useNavigate()
  const style = {
    modal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '30%',
      bgcolor: 'background.paper',
      boxShadow: 24,
      borderRadius: '15px'
    }
  }
  return (
    <Modal
      open={openModal}
      onClose={closeModal}
      aria-labelledby="login"
    >
      <Box className="tcf-v2" sx={style.modal}>
        <div className="text-right p-2">
          <Close className="cursor-pointer tcf-icon" onClick={closeModal} />
        </div>
        <div className="text-center p-3">
          <h5>Create an account</h5>
          <p>You'll need a profile to apply to a university or shortlist courses, please login to continue.</p>
        </div>
        <div className="text-right pb-4 pr-3">
          <Button
            variant="contained"
            className="tcf-older-button-small outlined older-color mr-1"
            onClick={() => navigate('/login')}

          >
            Login
          </Button>
          <Button
            variant="contained"
            className="tcf-older-button-small older-color"
            onClick={() => navigate('/registration')}
          >
            Create account
          </Button>
        </div>
      </Box>
    </Modal>
  )
}

export default LoginModal;