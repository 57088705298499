import { combineReducers } from "redux";
import {
	countriesScholarshipsCount,
	countryScholarships,
	scholarshipDetails,
	searchScholarships,
	scholarshipRelatedDegreeLevels,
	scholarshipRelatedDisciplines,
	scholarshipRelatedTypes,
	scholarshipRelatedMonths,
	scholarshipRelatedYears,
	scholarshipRelatedCountries,
	instituteScholarships,
	scholarshipRelatedInstitutes,
} from "./scholarship_reducer";
import {
	courseDetail,
	searchCourse,
	searchCourses,
	disciplineCourse,
	countryDisciplineCourse,
	courseRelatedLocations,
	courseRelatedDegreeLevels,
	courseRelatedSpecializations,
	courseRelatedInstituteName,
	courseRelatedIntakes,
	courseCompareDetail,
} from "./course.reducer";
import {
	allInstituteGroups,
	instituteGroupDetail,
	instituteGroupUniversities,
	instituteGroupRelatedLocations,
	allInstituteGroupCourses,
	allUniversitiesOfInstitutesGroup,
	allInstituteGroupDisciplines,
	allDisciplinesByInstituteGroup,
	allScholarshipsByInstituteGroup,
} from "./institute_group.reducer";
import {
	allCountries,
	countryDetail,
	allUniversitiesLocation,
	allCountriesWithDetail,
	allInstitutesByCountry,
	allFAQsByCountry,
	countryDisciplineRelatedDegreeLevels,
	countryDisciplineRelatedIntakes,
	countryDisciplineRelatedSpecializations,
	countryDisciplineRelatedInstitutes,
	countryDisciplineRelatedLocations,
} from "./country_reducer";
import { allDegreeLevels } from "./degree_level_reducer";
import {
	allDisciplineLevels,
	countryAllDisciplines,
	disciplineDetailById,
	disciplineAllSpecialization,
} from "./discipline.reducer";
import {
	allPathwayGroups,
	pathwayGroupDetail,
	pathwayGroupUniversities,
	pathwayGroupRelatedLocations,
	allDisciplineOfPathwayGroup,
	allScholarshipsOfPathwayGroup,
	allCourseOfPathwayGroup,
} from "./pathway_reducer";
import {
	allInstitutesDisciplineLevels,
	institutesDetailByID,
	institutesDisciplinesRelatedDegreeLevels,
	institutesDisciplinesRelatedIntakes,
	institutesDisciplinesRelatedSpecializations,
	allInstitutes,
} from "./institute.reducer";
import {
	allApplyPortals,
	applyPortalDetails,
	applyPortalUniversities,
	applyPortalRelatedLocations,
} from "./apply_portal.reducer";
import { countryRankingsByID } from "./ranking.reducer";
import { allBlogs, allBlogsById } from "./blogs.reducer";
import {
	getAllDynamicPagesByType,
	getDynamicPageById,
} from "./dynamic_pages.reducer";
import { searchCoursesLocation } from "./map.reducer";
import {
	courseCompareCountry,
	courseCompareDegreeLevels,
	courseCompareDisciplines,
	courseCompareInstitutes,
	courseCompareSpecializations,
	courseCompareCourses,
} from "./search_course.reducer";

import {
	allRegions,
	allPopularCountries,
	allRegionsCountries,
	allPopularCities,
} from "./region.reducer";
import { userLoginProfileData } from "./user_account.reducer";
import { allTopWords } from "./top_words_reducers";
import {
	allDiscoverDisciplines,
	allDiscoverScholarships,
} from "./discover.reducer";
import { currencies } from "./currency.reducer";
import {
	allMarketingCards,
	marketingCardDetail,
} from "./marketing_card.reducer";
import { filtersReducer } from "./course-list-filters.reducer";
import { disciplineFiltersReducer } from "./discipline-filters.reducer";
import { instituteProgramFiltersReducer } from "./institute_program-filters.reducer";
import { pathwayProgramFiltersReducer } from "./pathways_program-filters.reducer";
import { universityProgramFiltersReducer } from "./university-program-filters.reducer";
import { scholarshipsFiltersReducer } from "./scholarship-filters.reducer";
import { allScholarshipsFiltersReducer } from "./all-scholarships-filters.reducer";
import { instituteScholarshipsFiltersReducer } from "./institute-scholarship-filter.reducer";
import {pageDescriptionDetailReducer} from "./pageDescription.reducer";
const apiReducers = combineReducers({
	///filters state management//
	instituteScholarshipsFiltersReducer,
	pageDescriptionDetailReducer,
	allScholarshipsFiltersReducer,
	scholarshipsFiltersReducer,
	universityProgramFiltersReducer,
	pathwayProgramFiltersReducer,
	instituteProgramFiltersReducer,
	disciplineFiltersReducer,
	filtersReducer,
	// end//
	allMarketingCards,
	marketingCardDetail,
	allInstitutes,
	allDiscoverDisciplines,
	allDiscoverScholarships,
	userLoginProfileData,
	courseCompareCountry,
	courseCompareDisciplines,
	courseCompareDegreeLevels,
	courseCompareInstitutes,
	courseCompareSpecializations,
	courseCompareCourses,
	allUniversitiesLocation,
	allInstitutesByCountry,
	allFAQsByCountry,
	allInstituteGroupDisciplines,
	allDisciplinesByInstituteGroup,
	allDisciplineOfPathwayGroup,
	allScholarshipsByInstituteGroup,
	allScholarshipsOfPathwayGroup,
	allCourseOfPathwayGroup,
	countriesScholarshipsCount,
	countryScholarships,
	instituteScholarships,
	scholarshipDetails,
	searchScholarships,
	scholarshipRelatedInstitutes,
	scholarshipRelatedDegreeLevels,
	scholarshipRelatedDisciplines,
	scholarshipRelatedTypes,
	scholarshipRelatedMonths,
	scholarshipRelatedYears,
	scholarshipRelatedCountries,
	searchCourse,
	searchCourses,
	courseDetail,
	courseCompareDetail,
	disciplineCourse,
	countryDisciplineCourse,
	allInstituteGroups,
	countryAllDisciplines,
	disciplineDetailById,
	countryDetail,
	instituteGroupDetail,
	instituteGroupUniversities,
	instituteGroupRelatedLocations,
	allInstituteGroupCourses,
	allUniversitiesOfInstitutesGroup,
	allPathwayGroups,
	pathwayGroupDetail,
	pathwayGroupUniversities,
	pathwayGroupRelatedLocations,
	allApplyPortals,
	applyPortalDetails,
	applyPortalUniversities,
	applyPortalRelatedLocations,
	allCountries,
	allCountriesWithDetail,
	allDegreeLevels,
	allDisciplineLevels,
	disciplineAllSpecialization,
	allInstitutesDisciplineLevels,
	institutesDetailByID,
	countryRankingsByID,
	/// dynamic pages
	getAllDynamicPagesByType,
	getDynamicPageById,
	/// map
	searchCoursesLocation,

	//	 filters
	courseRelatedLocations,
	courseRelatedDegreeLevels,
	courseRelatedSpecializations,
	courseRelatedInstituteName,
	courseRelatedIntakes,
	institutesDisciplinesRelatedDegreeLevels,
	institutesDisciplinesRelatedIntakes,
	institutesDisciplinesRelatedSpecializations,
	countryDisciplineRelatedDegreeLevels,
	countryDisciplineRelatedIntakes,
	countryDisciplineRelatedSpecializations,
	countryDisciplineRelatedInstitutes,
	countryDisciplineRelatedLocations,
	// BLOGS
	allBlogs,
	allBlogsById,

	//	  regions

	allRegions,
	allPopularCountries,
	allRegionsCountries,
	allPopularCities,

	//	 top words
	allTopWords,

	//	 currencies
	currencies,
});

export default apiReducers;
