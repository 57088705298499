const FilterItem = ({ item, isChecked, onChange }) => {
    return (
        <div className="filterItem">
            <input
                type="checkbox"
                id={item.id}
                name={item.name}
                defaultChecked={isChecked}
                onChange={onChange}
                value={item?.slug || ''}
            />
            <label htmlFor={item.id}>
                {item.name} {item.count && (`${'(' + item.count + ')'}`)}
            </label>
        </div>
    );
};

export default FilterItem;