export const ADD_FILTER_DATA = "ADD_FILTER_DATA";
export const ADD_FILTER_DATA_SUCCESS = "ADD_FILTER_DATA_SUCCESS";
export const ADD_FILTER_DATA_FAIL = "ADD_FILTER_DATA_FAIL";

export function addFilterData(data = {}) {
	return (Dispatch) =>
		Dispatch({
			type: "ADD_FILTER_DATA_SUCCESS",
			payload: data,
		});
}
