import { TextField } from "@mui/material";
import countryBanner from "../../../static/images/country-banner.svg";
import { useEffect, useState } from "react";

const SearchBanner = ({ updateSearchParams, searchParams }) => {
  let selectedCourse = [];
  const [courseInputError, setCourseInputError] = useState(false);
  const [courseInput, setCourseInput] = useState([]);

  const inputHandleChange = (event) => {
    if (selectedCourse) {
      selectedCourse = [event.target.value];
    }
    let keyValue = []
    if (event.target.value?.split(',')?.length) {
      keyValue = event.target.value.split(',')
    } else {
      keyValue = [event.target.value]
    }
    setCourseInput(keyValue);
  };
  const onSubmit = (e) => {
    setCourseInputError(false);
    if (courseInput !== "") {
      updateSearchParams({
        search: courseInput[0],
        specialization: '',
        discipline: ''
      });
    }

  };
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      onSubmit();
    }
  };

  // useEffect(() => {
  //   setCourseInput([])
  // }, [searchParams.discipline]);

  useEffect(() => {
    let typeParams = searchParams.search;
    if (searchParams.search?.length) {
      typeParams = typeParams.replaceAll("%20", " ");
      setCourseInput(typeParams)
    } else {
      setCourseInput([])
    }
  }, [searchParams.discipline]);

  return (
    <div className="countryBanner marginBanner">
      <img
        src={countryBanner}
        alt="banner"
      />
      <div className="content">
        <h1>Explore thousands of courses all over the world.</h1>
        <div className="countrySearchWrap">
          <input
            fullWidth
            placeholder="Type here"
            value={courseInput}
            autoFocus={true}
            type={"string"}
            onChange={inputHandleChange}
            onKeyPress={handleKeyPress}
            className={`${courseInputError ? " form-control is-invalid" : " form-control"}`}
          />
          <button className="btn" onClick={onSubmit}>
            Search
          </button>
          <i className="fa fa-search"></i>
        </div>
      </div>
    </div>
  )
}

export default SearchBanner;
