import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import LoadingSpinner from "../../loader/loader";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";
import { http } from "../../../services/apiService/apiService";
import CourseApplyForm from "../../forms/apply_form";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GridViewDataRenderingV2 from "./GridViewRendering_v2";
import { useTheme } from "@mui/material/styles";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookMessengerShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import Pagination from "react-responsive-pagination";
import { Button, Drawer, Grid, useMediaQuery } from "@mui/material";
import { getSingleRoute } from "../../../../common/utils";
import { slugSet } from "../../../common/getQueryString";
import { Close } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { getFavouriteCoursesReq } from "../../../services/apiService";

let selectedCourse = [];

function CourseGridCardViewV2(props) {
  const { courses, coursesToShow } = props;
  const history = useHistory();
  const [applyCourse, setApplyCourse] = useState(null);
  const [relevantCourses, setRelevantCourses] = useState([]);
  const [institute, setInstitute] = useState(null);
  const [favCourses, setFavCourses] = useState([]);
  const [pageState, setPageState] = useState({
    offset: 0,
    perPage: 27,
    currentPage: 1,
    pageCount: 0,
  });
  const [relevantLoading, setRelevantLoading] = useState(true);
  const [relatedCoursesList, setRelatedCoursesList] = useState(null);
  const [url, setUrl] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [compareCourses, setCompareCourses] = useState([]);
  const [selected, setSelected] = useState(false);

  const outerTheme = useTheme();
  const isMobile = useMediaQuery(outerTheme.breakpoints.down("sm"));

  const { enqueueSnackbar } = useSnackbar();

  const getFavouriteCourses = async () => {
    try {
      const { data: courses } = await getFavouriteCoursesReq();
      setFavCourses(courses.data);
    } catch (err) {
      console.warn("Error while fetching favourite courses", err);
    }
  };

  useEffect(() => {
    getFavouriteCourses();
  }, []);

  const commaSeparators = (num) => {
    let numParts = num.toString().split(".");
    numParts[0] = numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return numParts.join(".");
  };

  function clickApplyForm(course) {
    if (localStorage.getItem("user_Id")) {
      setApplyCourse(course);
      localStorage.setItem("ApplyCourseModal", "true");
      $("#applyModal").modal("show");
    } else {
      $("#login").modal("show");
    }
  }

  let data = [];

  const handleModalOpen = (item) => {
    localStorage.setItem("ShareModal", "true");
    // $("#share").modal("show");
    setUrl(`${window.location.origin}/course-detail/${item.course_slug}`);
    $("#shareCourse").modal("show");
  };

  async function onClickMoreRelevantCourses(institute, courses) {
    setRelevantLoading(true);
    setInstitute(institute);
    setRelatedCoursesList(courses);
    $("#MoreCoursesModal").modal("show");

    await http
      .post(`course/courses_by_ids/`, (data = { courses_ids: courses }))
      .then((res) => {
        pageState.pageCount = Math.ceil(res.data.count / pageState.perPage);
        pageState.offset = pageState.current * pageState.perPage;
        setPageState(pageState);
        setRelevantCourses(res?.data?.results);
        setRelevantLoading(false);
      });
  }

  function handlePageClick(page_number) {
    let selectedPage = page_number;
    const offset = selectedPage * pageState.perPage;
    pageState.currentPage = selectedPage;
    pageState.offset = offset;
    setPageState(pageState);
    setRelevantLoading(true);
    http
      .post(
        `course/courses_by_ids/?page=${page_number}`,
        (data = { courses_ids: relatedCoursesList })
      )
      .then((res) => {
        pageState.pageCount = Math.ceil(res.data.count / pageState.perPage);
        pageState.offset = pageState.current * pageState.perPage;
        setPageState(pageState);
        setRelevantCourses(res?.data?.results);
        setRelevantLoading(false);
      });
  }

  const openDrawer = (course) => {
    const { course_id, course_slug, name, logo, course_fee_id, duration_id } =
      course;
    // Check if a course with the same id, duration, and fee_id is already selected
    const isDuplicateCourse = selectedCourse.some(
      (element) =>
        element.id === course_id &&
        element.duration === duration_id &&
        element.fee_id === course_fee_id
    );

    if (isDuplicateCourse) {
      enqueueSnackbar("Course already selected", {
        variant: "warning",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
      setOpenModal(false);
    } else if (selectedCourse.length === 3) {
      enqueueSnackbar("You can compare 3 courses at a time", {
        variant: "warning",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
      setOpenModal(true);
    } else {
      
      selectedCourse.push({
        id: course_id,
        slug: course_slug,
        name,
        logo,
        fee_id: course_fee_id,
        duration: duration_id,
      });
      setCompareCourses([...selectedCourse]); 
      setOpenModal(true);
    }
  };

  const removeSelectedCourse = (e, course) => {
    e.preventDefault();
    selectedCourse = selectedCourse.filter((item) => item.id !== course.id);
    if (selectedCourse.length === 0) {
      setOpenModal(false);
    }
    setCompareCourses(selectedCourse);
  };
  const removeAllSelectedCourse = (e) => {
    // e.preventDefault();
    selectedCourse = [];
    setCompareCourses([]);
    setOpenModal(false);
  };

  function navigateToCompare() {
    const params = selectedCourse.map((course, index) => ({
      [`crs${index + 1}`]: course.slug || '',
      [`crs${index + 1}_fee`]: course.fee_id || '',
      [`crs${index + 1}_duration`]: course.duration || '',
    }));
  
    const queryString = $.param(Object.assign({}, ...params));
    window.open(`/compare-course?${queryString}`);
  }
  

  function copyToClickBoard() {
    navigator.clipboard.writeText(url);
    if (navigator.clipboard.writeText(url)) {
      setSelected(true);
    }
  }

  const getRoute = (page, id) => {
    getSingleRoute(page).then((d) => {
      history.push({
        pathname: d,
        search: id?.search ? id?.search : "",
      });
    });
  };

  return (
    <div className="courseFlex">
      <ToastContainer
        position="top-center"
        autoClose={700}
        hideProgressBar={true}
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss={false}
        pauseOnHover={false}
      />
      {courses
        ? courses.slice(0, coursesToShow).map((item, index) => {
            if (item) {
              return (
                <GridViewDataRenderingV2
                  key={index}
                  item={item}
                  handleModalOpen={handleModalOpen}
                  commaSeparators={commaSeparators}
                  relevantCoursesClick={onClickMoreRelevantCourses}
                  openDrawer={openDrawer}
                  data={data}
                  favouriteCourses={favCourses}
                />
              );
            }
          })
        : null}
      <div
        className="modal fade signinModal shareCourse"
        id="shareCourse"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                id=""
                aria-label="Close"
              >
                <img
                  src={
                    require("../../../../static/images/modal-times.svg").default
                  }
                  alt="banner"
                />
              </button>

              <h3>Share Course Link</h3>
              <div className="shareSearch">
                <input
                  type="text"
                
                  className="form-control"
                  defaultValue={url}
                />
                <button
                  className="btn defaultBtn"
                  onClick={copyToClickBoard}
                >
                  {selected ? <span>Copied</span> : <span>Copy</span>}
                </button>
              </div>
              <p>or Share via</p>
              <ul>
                <li>
                  <WhatsappShareButton
                    url={url}
                    title={"Tap the Link below to open the website"}
                  >
                    <WhatsappIcon size="3rem" />
                  </WhatsappShareButton>
                </li>
                <li>
                  <FacebookMessengerShareButton
                    appId={"867455750856341"}
                    redirectUri="https://timescoursefinder.com"
                    url={url}
                    clientid={"102"}
                    title="Tap the Link below to open website "
                  >
                    <FacebookIcon size={"3rem"} />
                  </FacebookMessengerShareButton>
                </li>
                <li>
                  <EmailShareButton
                    subject={"Course Detail"}
                    body={"Click the given link " + url + " ."}
                  >
                    <EmailIcon size={"3rem"} />
                  </EmailShareButton>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* <div
                className="modal fade"
                id="share"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="shareLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="shareModalLabel">
                                Share via
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    localStorage.removeItem("ShareModal");
                                }}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <FacebookMessengerShareButton
                                appId={"867455750856341"}
                                redirectUri={url}
                                clientid={"102"}
                                title="Tap the Link below to open website "
                            >
                                <FacebookIcon size={"2rem"} />
                            </FacebookMessengerShareButton>
                            {"   "}
                            <EmailShareButton
                                subject={"Course Detail"}
                                body={"Click the given link " + url + " ."}
                            >
                                <EmailIcon size={"2rem"} />
                            </EmailShareButton>
                            {"   "}
                            <WhatsappShareButton
                                url={url}
                                title={"Tap the Link below to open the website"}
                            >
                                <WhatsappIcon size="2rem" />
                            </WhatsappShareButton>
                            {"   "}
                            <TwitterShareButton
                                url={url}
                                title="Tap the Link below to open website"
                            >
                                <TwitterIcon size="2rem" />
                            </TwitterShareButton>
                        </div>
                    </div>
                </div>
            </div> */}
      <div
        className="modal signinModal fade"
        id="applyModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="applyModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                id="login_modal_close_btn"
                aria-label="Close"
                onClick={() => {
                  localStorage.removeItem("ApplyCourseModal");
                }}
              >
                <img
                  src={
                    require("../../../../static/images/modal-times.svg").default
                  }
                  alt="banner"
                />
              </button>
              <CourseApplyForm course={applyCourse} />
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade coursesModal"
        id="MoreCoursesModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="applyModalLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="requestInfoModalLabel">
                Relevant Courses of {institute?.institute_name}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="courseFlex">
                {relevantLoading === true ? (
                  <LoadingSpinner />
                ) : (
                  relevantCourses.map((item) => (
                    <div className="courseBox" id={item.id}>
                      <div className="imgWrap">
                        <div className="courseBoxTop">
                          <button
                            onClick={() =>
                              getRoute("university_details", {
                                id: `${slugSet(item.slug)}${slugSet(
                                  item.campus_slug
                                )}`,
                              })
                            }
                          >
                            <img
                              src={courses?.logo}
                              alt={institute.institute_name}
                            />
                          </button>
                          <div>
                            <button
                              onClick={() =>
                                getRoute("university_details", {
                                  id: `${slugSet(item.slug)}${slugSet(
                                    item.campus_slug
                                  )}`,
                                })
                              }
                            >
                              <h6>{institute.institute_name}</h6>
                            </button>
                            <p>{item.campus.campus}</p>
                          </div>
                        </div>
                        <div className="btnGroup">
                          <button
                            onClick={() => handleModalOpen(item.id)}
                            className="btn"
                            data-dismiss="modal"
                          >
                            <img
                              src={
                                require("../../../../static/images/share-purple.svg")
                                  .default
                              }
                              alt="share-purple"
                            />
                          </button>
                          <button className="btn">
                            <img
                              src={
                                require("../../../../static/images/fav-purple.svg")
                                  .default
                              }
                              alt="fav-purple"
                            />
                          </button>
                        </div>
                      </div>
                      <div className="courseBoxCont">
                        <h6>
                          <b>{item.name}</b>
                        </h6>
                        <p>
                          <img
                            src={
                              require("../../../../static/images/Specialization1.svg")
                                .default
                            }
                            alt="Specialization1"
                          />
                          {item.specialization.name}
                        </p>

                        <div>
                          <div>
                            <p>
                              <img
                                src={
                                  require("../../../../static/images/bachelor_honors.svg")
                                    .default
                                }
                                alt="bachelor_honors"
                              />
                              {item.degree_level.display_name} |{" "}
                              {item.course_title.display_name}
                            </p>
                            <p>
                              <img
                                src={
                                  require("../../../../static/images/on_campus.svg")
                                    .default
                                }
                                alt="on_campus"
                              />
                              On Campus
                            </p>
                            <p>
                              <img
                                src={
                                  require("../../../../static/images/Amount.svg")
                                    .default
                                }
                                alt="Amount"
                              />
                              {item.course_fee.length >= 1
                                ? (item.course_fee[0]?.converted_value
                                    ? commaSeparators(
                                        item.course_fee[0]?.converted_value.toFixed(
                                          0
                                        )
                                      ) +
                                      " " +
                                      item.course_fee[0]?.currency
                                        ?.converted_currency
                                    : commaSeparators(
                                        item.course_fee[0]?.ceil_value
                                      ) +
                                      " " +
                                      item.course_fee[0]?.currency
                                        ?.display_name) + "/Year"
                                : item.item.base_fee === 0 &&
                                  item.item.course_fee === 0
                                ? "To Be Confirmed"
                                : "Free Education"}
                            </p>
                          </div>
                          <div>
                            <p>
                              <img
                                src={
                                  require("../../../../static/images/Years.svg")
                                    .default
                                }
                                alt="Years"
                              />
                              {item.course_duration}
                            </p>
                            <p>
                              <img
                                src={
                                  require("../../../../static/images/English.svg")
                                    .default
                                }
                                alt="English"
                              />
                              {item.course_language}
                            </p>
                            <p>
                              <img
                                src={
                                  require("../../../../static/images/full_time.svg")
                                    .default
                                }
                                alt="full_time"
                              />
                              Full Time
                            </p>
                          </div>
                        </div>
                        <div className="courseBoxFooter">
                          <div className="btnGroup">
                            <button
                              className="btn"
                              onClick={() =>
                                getRoute("course_detail", {
                                  search: $.param({
                                    course_id: item.id,
                                    course_name: item.name,
                                  }),
                                })
                              }
                            >
                              Discover
                            </button>
                            <button
                              className="btn"
                              onClick={() => openDrawer(item)}
                            >
                              Compare
                            </button>
                            <button
                              className="btn"
                              data-dismiss="modal"
                              onClick={() => clickApplyForm(item)}
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
              <div className="paginationWrap">
                <Pagination
                  current={pageState.currentPage}
                  total={pageState.pageCount}
                  onPageChange={handlePageClick}
                  previousLabel={"Prev"}
                  nextLabel={"Next"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade signinModal editModal"
        id="editModal"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                id="login_modal_close_btn"
                aria-label="Close"
                onClick={() => {
                  localStorage.removeItem("EditModal");
                }}
              >
                <img
                  src={
                    require("../../../../static/images/modal-times.svg").default
                  }
                  alt="modal-times"
                />
              </button>
              <h6>Suggest an Edit or Post Review</h6>
              <p>
                Studied or Worked here? Share your Review or suggest editing
              </p>
              <form>
                <div className="row">
                  <div className="col-md">
                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="First Name"
                        className="form-control"
                      />
                      <img
                        src={
                          require("../../../../static/images/forms/full_name.svg")
                            .default
                        }
                        alt="full_name"
                        height=""
                        width=""
                        className="formIcon"
                      />
                    </div>
                  </div>
                  <div className="col-md">
                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="Last Name"
                        className="form-control"
                      />
                      <img
                        src={
                          require("../../../../static/images/forms/full_name.svg")
                            .default
                        }
                        alt="full_name"
                        height=""
                        width=""
                        className="formIcon"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md">
                    <div className="form-group">
                      <Select
                        name="filters"
                        placeholder={"Phone Type"}
                        isSearchable={false}
                        theme={(theme) => ({
                          ...theme,
                        })}
                      />

                      <img
                        src={
                          require("../../../../static/images/forms/phone.svg")
                            .default
                        }
                        alt="phone"
                        height=""
                        width=""
                        className="formIcon"
                      />
                    </div>
                  </div>
                  <div className="col-md">
                    <div className="form-group">
                      <PhoneInput
                        autoFormat
                        country={"pk"}
                        placeholder="Enter the Number"
                        enableAreaCodes="true"
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    placeholder="Email Address"
                    className="form-control"
                  />
                  <img
                    src={
                      require("../../../../static/images/forms/Email.svg")
                        .default
                    }
                    alt="Email"
                    height=""
                    width=""
                    className="formIcon"
                  />
                </div>

                <div className="form-group">
                  <textarea placeholder="Comment" className="form-control" />
                  <img
                    src={
                      require("../../../../static/images/forms/comment.svg")
                        .default
                    }
                    alt="comment"
                    height=""
                    width=""
                    className="formIcon"
                  />
                </div>
              </form>
              <h6>Please do not post: </h6>
              <ul>
                <li>Aggressive or discriminatory language</li>
                <li>Profanities</li>
                <li>Trade secrets or confidential information</li>
              </ul>
              <p>
                We appreciate you for doing your part to Keep Times Course
                Finder most trusted education portal
              </p>
              <div className="btnGroup">
                <button className="btn defaultBtn">Add Review</button>
                <button className="btn primaryBtn">Suggest Editing</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Drawer
          anchor={"bottom"}
          open={openModal}
          hideBackdrop={true}
          disableScrollLock
          sx={{ height: "120px !important", top: "unset !important" }}
          // className="compare-courser-drawer"
        >
          <Grid container>
            <Grid item xs={isMobile ? 12 : 9}>
              <Grid container>
                {compareCourses.length
                  ? compareCourses.map((item) => (
                      <Grid item xs={isMobile ? 12 : 4}>
                        <div
                          className="d-flex"
                          style={{
                            margin: "15px",
                            borderRadius: "10px",
                            boxShadow: "rgba(0, 0, 0, 0.05) 1px 3px 5px 3px",
                          }}
                        >
                          <img
                            alt="name"
                            src={item.logo}
                            style={{
                              minWidth: "80px",
                              height: "80px",
                              margin: "5px",
                              background: "#e9defa",
                              borderRadius: "12px",
                              padding: "10px",
                            }}
                          />
                          <p
                            className="font-weight-bold align-content-center m-0"
                            style={{ width: "-webkit-fill-available" }}
                          >
                            {item.name}
                          </p>
                          <Close
                            className="cursor-pointer"
                            onClick={(e) => removeSelectedCourse(e, item)}
                          />
                        </div>
                      </Grid>
                    ))
                  : null}
              </Grid>
            </Grid>
            <Grid
              item
              xs={isMobile ? 12 : 3}
              alignContent="center"
              textAlign="center"
            >
              <Button
                variant="contained"
                className="tcf-older-button-small outlined older-color w-50 m-1"
                onClick={removeAllSelectedCourse}
              >
                Clear
              </Button>
              <Button
                variant="contained"
                className="tcf-older-button-small older-color w-50 m-1"
                onClick={navigateToCompare}
                disabled={selectedCourse.length <= 1}
              >
                Compare
              </Button>
            </Grid>
          </Grid>
        </Drawer>
      </div>
    </div>
  );
}

export default CourseGridCardViewV2;
