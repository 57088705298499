import * as Actions from "../actions/marketing_card.action";
const allMarketingCardInitialState = {
	data: [],
};

export const allMarketingCards = (
	state = allMarketingCardInitialState,
	action
) => {
	switch (action.type) {
		case Actions.GET_ALL_MARKETING_CARD_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.GET_ALL_MARKETING_CARD_FAIL: {
			return {
				...state,
				data: null,
			};
		}
		default: {
			return state;
		}
	}
};
const marketingCardDetailInitialState = {
	data: [],
};

export const marketingCardDetail = (
	state = marketingCardDetailInitialState,
	action
) => {
	switch (action.type) {
		case Actions.GET_MARKETING_CARD_DETAIL_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.GET_MARKETING_CARD_DETAIL_FAIL: {
			return {
				...state,
				data: null,
			};
		}
		default: {
			return state;
		}
	}
};
