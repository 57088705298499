import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import $ from "jquery";
import { getSingleRoute } from "../../../common/utils";
import {
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
  PinterestIcon,
  RedditIcon,
} from "react-share";
import CallIcon from '@mui/icons-material/Call';
import PlaceIcon from '@mui/icons-material/Place';
const logo = require("../../../static/images/new-tcf-logo.svg");

function TopHeader() {
  const history = useHistory();
  const ref = useRef(null);
  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleClickOutside = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setIsNavOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  useEffect(() => {
    $(".openTopNav").on("click", function () {
      $(".headTopNav").addClass("show");
    });
    $(".closeTopNav").on("click", function () {
      $(".headTopNav").removeClass("show");
    });
    $(".headTopNav").on("click", function () {
      $(".headTopNav").removeClass("show");
    });
  }, []);
  const getRoute = (page, id) => {
    getSingleRoute(page).then((d) => {
      history.push(d + `${id?.id ? "/" + id?.id : ""}`);
      setIsNavOpen(false);
    });
  };


  return (
    <div className="headTop">
      <div className="container-fluid ">
        <button className="openTopNav" onClick={toggleNav}>
          <img
            src={require("../../../static/images/list.svg").default}
            alt="list"
          />
        </button>
        <div className={`headTopNav ${isNavOpen ? "show" : ""}`} ref={ref}>
          <button className="logo">
            <img src={logo.default} height="" width="" alt="logo" />{" "}
          </button>
          <button className="closeTopNav">
            <img
              src={require("../../../static/images/modal-times.svg").default}
              alt="modal-times"
            />
          </button>

          <div className="d-md-flex justify-content-between">
            {/* <ul>
              <li> */}
            <ul className="sociallinks">
              <li>
                <a href="https://www.facebook.com/TimesCF" target={"_blank"} rel="noreferrer">
                  <FacebookIcon size={"2rem"} />
                </a>
              </li>
              <li>
                <a href="https://wa.me/923452066100" target={"_blank"} rel="noreferrer">
                  <WhatsappIcon size="2rem" />
                </a>
              </li>
              <li>
                <a href="https://twitter.com/FinderTimes" target={"_blank"} rel="noreferrer">
                  <TwitterIcon size="2rem" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/timescoursefinder/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <LinkedinIcon />
                </a>
              </li>
              <li>
                <a
                  href="https://www.pinterest.com/timescoursefinder/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <PinterestIcon />
                </a>
              </li>
              <li>
                <a
                  href="https://www.reddit.com/user/TimescourseFinder/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <RedditIcon />
                </a>
              </li>
            </ul>

            <ul>
              <li>
                <button type="button" onClick={() => getRoute("all_countries")}>
                  <img
                    src={
                      require("../../../static/images/top_contries.svg").default
                    }
                    alt="top_contries"
                  />
                  Find Top Countries
                </button>{" "}
              </li>
              <li>
                <button type="button" onClick={() => getRoute("all_groups")}>
                  <img
                    src={
                      require("../../../static/images/top_universities.svg")
                        .default
                    }
                    alt="top_universities"
                  />
                  Find Top Universities
                </button>{" "}
              </li>
              <li>
                <button type="button" onClick={() => getRoute("all_programs")}>
                  <img
                    src={require("../../../static/images/top_fields.svg").default}
                    alt="top_fields"
                  />
                  Find Top Fields
                </button>{" "}
              </li>
              <li>
                <button
                  type="button"
                  onClick={() => getRoute("all_scholarships")}
                >
                  <img
                    src={
                      require("../../../static/images/top_scholarships.svg")
                        .default
                    }
                    alt="top_scholarships"
                  />
                  Find Top Scholarships
                </button>{" "}
              </li>
            </ul>
            <ul>
              <li>
                <CallIcon style={{ color: "white" }} />
                <a href="tel:+923171118338" target="_blank" rel="noopener noreferrer"> 0317 111 8338</a>
                <PlaceIcon className="ml-2" style={{ color: "white" }} />
                <a href="https://timesconsultant.com/contact" target="_blank" rel="noreferrer">Office Location</a>

              </li>

            </ul>
          </div>

        </div>

      </div>
    </div>
  );
}
export default TopHeader;
