import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom-v5-compat";
import { ory, useSdkError } from "../../ory/pkg/sdk";
import registerImg from "../../../static/images/auth/register-bg.jpeg";
import logo from "../../../static/images/tcf.svg";
import googleIcon from "../../../static/images/auth/google.svg";
import facebookIcon from "../../../static/images/auth/facebook.svg";

import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import LoadingSpinner from "../../components/loader/loader";
import { inputTheme } from "../../shared/utils/muiInputTheme";
import { useSnackbar } from "notistack";

const Registration = () => {
  const outerTheme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const [flow, setFlow] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const loginChallenge = searchParams.get("login_challenge");
  const returnTo = searchParams.get("return_to");

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const notify = (flow) => {
    const passwordField = flow?.ui.nodes.find(
      (node) => node.attributes.name === "password"
    );
    if (passwordField?.messages[0]?.text) {
      enqueueSnackbar(passwordField.messages[0].text, {
        variant: passwordField?.messages[0].type,
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    }
  };
  // Get the flow based on the flowId in the URL (.e.g redirect to this page after flow initialized)
  const getFlow = useCallback(
    (flowId) =>
      ory
        .getRegistrationFlow({ id: flowId })
        .then(({ data: flow }) => setFlow(flow))
        .catch(sdkErrorHandler),
    []
  );

  // initialize the sdkError for generic handling of errors
  const sdkErrorHandler = useSdkError(
    getFlow,
    setFlow,
    "/registration",
    true,
    notify
  );

  // create a new registration flow
  const createFlow = () => {
    ory
      // we don't need to specify the return_to here since we are building an SPA. In server-side browser flows we would need to specify the return_to
      .createBrowserRegistrationFlow({
        ...(returnTo && { returnTo: returnTo }),
        ...(loginChallenge && { loginChallenge: loginChallenge }),
      })
      .then(({ data: flow }) => {
        // Update URI query params to include flow id
        setSearchParams({ ["flow"]: flow.id });
        // Set the flow data
        setFlow(flow);
      })
      .catch(sdkErrorHandler);
  };

  // submit the registration form data to Ory
  const submitFlow = (data) => {
    let body = {
      "traits.userDetails.first": data.firstName,
      "traits.userDetails.last": data.lastName,
      "traits.email": data.identifier,
      method: data.method,
      csrf_token: data.csrf_token,
      password: data.password,
      "traits.userDetails.primaryPhone": data.phoneNumber,
      "traits.userDetails.needAssistance": data.needAssistance,
    };
    // something unexpected went wrong and the flow was not set
    if (!flow) return navigate("/registration", { replace: true });

    ory
      .updateRegistrationFlow({
        flow: flow.id,
        updateRegistrationFlowBody: body,
      })
      .then(({ data }) => {
        if ("continue_with" in data) {
          for (const cw of data.continue_with ?? []) {
            if (cw.action === "show_verification_ui") {
              const search = new URLSearchParams();
              search.set("flow", cw.flow.id);
              navigate(
                {
                  pathname: "/verification",
                  search: search.toString(),
                },
                { replace: true }
              );
              return;
            }
          }
        }

        // we successfully submitted the login flow, so lets redirect to the dashboard
        navigate("/", { replace: true });
      })
      .catch(sdkErrorHandler);
  };
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const csrfField = flow?.ui.nodes.find(
    (node) => node.attributes.name === "csrf_token"
  );

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    // we might redirect to this page after the flow is initialized, so we check for the flowId in the URL
    const flowId = searchParams.get("flow");
    if (flowId) {
      getFlow(flowId).catch(createFlow); // if for some reason the flow has expired, we need to get a new one
      return;
    }
    // we assume there was no flow, so we create a new one
    createFlow();
  }, [navigate]);

  // we check if the flow is set, if not we show a loading indicator
  return flow ? (
    <div className="container-fluid">
      <div className="row auth registration" style={{ minHeight: "100vh" }}>
        <div className="col-sm-12 col-md-12 col-lg-5 ory-fields-container">
          <div className="logo w-100 text-center">
            <Link to="/">
              <img src={logo} alt="logo" />
            </Link>
          </div>

          <div className="title-container pt-4">
            <h2>Create account</h2>
            <p>
              Take the first step toward your dream university by quickly and
              freely creating account.
            </p>
          </div>

          {flow.ui.messages && (
            <FormHelperText className="Mui-error ory-helper-text pt-1 pb-4">
              {flow?.ui?.messages[0]?.text}
            </FormHelperText>
          )}

          <div className="w-100 ory-input-fields">
            <ThemeProvider theme={inputTheme(outerTheme)}>
              <form
                action={flow.ui.action}
                method={flow.ui.method}
                onSubmit={handleSubmit(submitFlow)}
              >
                <TextField
                  error={errors.firstName}
                  {...register("firstName", {
                    required: true,
                    maxLength: 30,
                  })}
                  type="text"
                  label="First Name"
                  variant="outlined"
                  className="w-50 pb-4 pr-1"
                  autoComplete="false"
                  helperText={
                    errors.firstName?.type === "required"
                      ? "This field is required"
                      : errors.firstName?.message
                  }
                />
                <TextField
                  error={errors.lastName}
                  {...register("lastName", {
                    required: true,
                    maxLength: 30,
                  })}
                  type="text"
                  label="Last Name"
                  variant="outlined"
                  className="w-50 pb-4 pl-1"
                  helperText={
                    errors.lastName?.type === "required"
                      ? "This field is required"
                      : errors.lastName?.message
                  }
                />
                <TextField
                  error={errors.identifier}
                  {...register("identifier", {
                    required: true,
                    maxLength: 30,
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message:
                        "Entered value does not match email format. (e.g., user@example.com)",
                    },
                  })}
                  type="email"
                  label="Email"
                  variant="outlined"
                  className="w-100 pb-4"
                  helperText={
                    errors.identifier?.type === "required"
                      ? "This field is required"
                      : errors.identifier?.message
                  }
                />

                <TextField
                  error={errors.phoneNumber}
                  {...register("phoneNumber", {
                    required: true,
                    maxLength: 18,
                    pattern: {
                      value:
                        /^\+\d{1,3}\s?\d{3}\s?\d{3}\s?\d{4}$/,
                      message:
                        "Entered value does not match phone number format. (e.g., +92 317 111 8338)",
                    },
                  })}
                  type="text"
                  label="Phone Number"
                  variant="outlined"
                  className="w-100 pb-4"
                  helperText={
                    errors.phoneNumber?.type === "required"
                      ? "This field is required"
                      : errors.phoneNumber?.message
                  }
                />
                <FormControl
                  sx={{ m: 1, width: "25ch" }}
                  variant="outlined"
                  className="w-100 m-0 pb-2"
                >
                  <InputLabel
                    className={errors.password ? "Mui-error" : ""}
                    htmlFor="outlined-adornment-password"
                  >
                    Password
                  </InputLabel>
                  <OutlinedInput
                    error={errors.password}
                    {...register("password", { required: true, minLength: 8 })}
                    type={showPassword ? "text" : "password"}
                    label="Password"
                    variant="outlined"
                    className="w-100"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {errors.password && (
                    <FormHelperText className="Mui-error">
                      {errors.password?.type === "required"
                        ? "This field is required"
                        : "Must be at least 8 characters"}
                    </FormHelperText>
                  )}
                </FormControl>

                <FormGroup style={{ fontSize: "10px !important" }}>
                  <FormControlLabel
                    {...register("needAssistance")}
                    control={
                      <Checkbox
                        defaultChecked
                        sx={{
                          color: "#5551FF",
                          "&.Mui-checked": {
                            color: "#5551FF",
                          },
                        }}
                        size="small"
                      />
                    }
                    label="Do you need personal assistance from one of our partner counselors?"
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "13px",
                      },
                    }}
                  />
                  <FormControlLabel
                    required
                    control={
                      <Checkbox
                        size="small"
                        sx={{
                          color: "#5551FF",
                          "&.Mui-checked": {
                            color: "#5551FF",
                          },
                        }}
                      />
                    }
                    label="Do you agree to our Terms and Conditions?"
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "13px",
                      },
                    }}
                  />
                </FormGroup>

                {csrfField && (
                  <input
                    {...register("csrf_token")}
                    type={csrfField.attributes.type}
                    name={csrfField.attributes.name}
                    value={csrfField.attributes.value}
                    disabled={csrfField.attributes.disabled}
                  />
                )}
                <input {...register("method")} type="hidden" value="password" />

                <Button
                  variant="contained"
                  name="method"
                  type="submit"
                  value="password"
                  className="w-100 ory-submit-button mt-3"
                >
                  Verify email
                </Button>
              </form>
            </ThemeProvider>
            <div className="need-account pt-4 text-center">
              <p>
                Already have an account? <Link to="/login">Login</Link>
              </p>
            </div>
          </div>

          {flow.ui.nodes.some((item) => item.group === "oidc") && (
            <>
              <Divider className="pt-0 pb-3 divider">or</Divider>

              <form action={flow.ui.action} method={flow.ui.method}>
                <div className="social-sign-in-container text-center">
                  {flow.ui.nodes.map((buttonData, index) => {
                    if (buttonData.group === "oidc") {
                      return (
                        <div className="d-inline pr-1">
                          <IconButton
                            key={index}
                            variant="outlined"
                            name={buttonData.attributes.name}
                            value={buttonData.attributes.value}
                            disabled={buttonData.attributes.disabled}
                            type={buttonData.attributes.type}
                            className="icon"
                          >
                            {buttonData.attributes.value === "facebook" ? (
                              <img src={facebookIcon} alt="icon" />
                            ) : buttonData.attributes.value === "google" ? (
                              <img src={googleIcon} alt="icon" />
                            ) : (
                              ""
                            )}
                          </IconButton>
                        </div>
                      );
                    }
                    return null; // Skip rendering for non-'oidc' items
                  })}
                </div>
              </form>
            </>
          )}
        </div>
        <div className="col-7 d-none d-lg-block p-0">
          <div className="image-container register">
            <div className="overlay"></div>
            <img
              className="main-image register"
              src={registerImg}
              alt="Registration"
            />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <LoadingSpinner height={"100vh"} />
  );
};

export default Registration;
