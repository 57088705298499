import { http } from "../../../services/apiService/apiService";
import axios from "axios";
import {GET_ALL_DEGREE_LEVELS_FAIL, GET_ALL_DEGREE_LEVELS_SUCCESS} from "./degree_level.actions";
// /countrieS ranking

export const GET_ALL_REGIONS = "GET_ALL_REGIONS";
export const GET_ALL_REGIONS_SUCCESS = "GET_ALL_REGIONS_SUCCESS";
export const GET_ALL_REGIONS_FAIL = "GET_ALL_REGIONS_FAIL";

// export function getAllRegions() {
// 	let url = "regions/all";
// 	const request = http.get(url);
//
// 	return (dispatch) =>
// 		request
// 			.then((response) =>
// 				dispatch({
// 					type: GET_ALL_REGIONS_SUCCESS,
// 					payload: response.data,
// 				})
// 			)
// 			.catch((error) => {
// 				return dispatch({
// 					type: GET_ALL_REGIONS_FAIL,
// 					payload: [],
// 				});
// 			});
// }

export const getAllRegions = () => async (dispatch,getState) => {
	// dispatch(handleLoadingDispatcher(LOADING_COUNTRY_RANKING, true));
	// dispatch(handleSuccessDispatcher(SUCCESS_COUNTRY_RANKING, false));
	if(!getState().apis.allRegions.data.length) {
		let url = "regions/all/";
		try {
			url = process.env.REACT_APP_BASE_URL + url
			const response = await axios.get(url);
			dispatch(
				{
					type: GET_ALL_REGIONS_SUCCESS,
					payload: response.data,
				}
			);
		} catch (e) {
			return dispatch({
				type: GET_ALL_REGIONS_FAIL,
				payload: [],
			});
		}
	}
};



export const GET_ALL_POPULAR_COUNTRIES = "GET_ALL_POPULAR_COUNTRIES";
export const GET_ALL_POPULAR_COUNTRIES_SUCCESS =
	"GET_ALL_POPULAR_COUNTRIES_SUCCESS";
export const GET_ALL_POPULAR_COUNTRIES_FAIL = "GET_ALL_POPULAR_COUNTRIES_FAIL";

// export function getAllPopularCountries() {
// 	let url = "regions/popular-countries";
// 	const request = http.get(url);
//
// 	return (dispatch) =>
// 		request
// 			.then((response) =>
// 				dispatch({
// 					type: GET_ALL_POPULAR_COUNTRIES_SUCCESS,
// 					payload: response.data,
// 				})
// 			)
// 			.catch((error) => {
// 				return dispatch({
// 					type: GET_ALL_POPULAR_COUNTRIES_FAIL,
// 					payload: [],
// 				});
// 			});
// }

export const getAllPopularCountries = () => async (dispatch,getState) => {
	// dispatch(handleLoadingDispatcher(LOADING_COUNTRY_RANKING, true));
	// dispatch(handleSuccessDispatcher(SUCCESS_COUNTRY_RANKING, false));
	if(!getState().apis.allPopularCountries.data.length) {
		let url = "regions/popular-countries/";
		try {
			url = process.env.REACT_APP_BASE_URL + url
			const response = await axios.get(url);
			dispatch(
				{
					type: GET_ALL_POPULAR_COUNTRIES_SUCCESS,
					payload: response.data,
				}
			);
		} catch (e) {
			return dispatch({
				type: GET_ALL_POPULAR_COUNTRIES_FAIL,
				payload: [],
			});
		}
	}
};

export const GET_ALL_REGIONS_COUNTRIES = "GET_ALL_REGIONS_COUNTRIES";
export const GET_ALL_REGIONS_COUNTRIES_SUCCESS =
	"GET_ALL_REGIONS_COUNTRIES_SUCCESS";
export const GET_ALL_REGIONS_COUNTRIES_FAIL = "GET_ALL_REGIONS_COUNTRIES_FAIL";

// export function getAllRegionsCountries() {
// 	let url = "regions/all-countries";
// 	const request = http.get(url);
//
// 	return (dispatch) =>
// 		request
// 			.then((response) =>
// 				dispatch({
// 					type: GET_ALL_REGIONS_COUNTRIES_SUCCESS,
// 					payload: response.data,
// 				})
// 			)
// 			.catch((error) => {
// 				return dispatch({
// 					type: GET_ALL_REGIONS_COUNTRIES_FAIL,
// 					payload: [],
// 				});
// 			});
// }

export const getAllRegionsCountries = () => async (dispatch,getState) => {
	// dispatch(handleLoadingDispatcher(LOADING_COUNTRY_RANKING, true));
	// dispatch(handleSuccessDispatcher(SUCCESS_COUNTRY_RANKING, false));
	if(!getState().apis.allRegionsCountries.data.length) {
		let url = "regions/all-countries/";
		try {
			url = process.env.REACT_APP_BASE_URL + url
			const response = await axios.get(url);
			dispatch(
				{
					type: GET_ALL_REGIONS_COUNTRIES_SUCCESS,
					payload: response.data,
				}
			);
		} catch (e) {
			return dispatch({
				type: GET_ALL_REGIONS_COUNTRIES_FAIL,
				payload: [],
			});
		}
	}
};

export const GET_ALL_POPULAR_CITIES = "GET_ALL_POPULAR_CITIES";
export const GET_ALL_POPULAR_CITIES_SUCCESS = "GET_ALL_POPULAR_CITIES_SUCCESS";
export const GET_ALL_POPULAR_CITIES_FAIL = "GET_ALL_POPULAR_CITIES_FAIL";

// export function getAllPopularCities() {
// 	let url = "regions/popular-cities";
// 	const request = http.get(url);
//
// 	return (dispatch) =>
// 		request
// 			.then((response) =>
// 				dispatch({
// 					type: GET_ALL_POPULAR_CITIES_SUCCESS,
// 					payload: response.data,
// 				})
// 			)
// 			.catch((error) => {
// 				return dispatch({
// 					type: GET_ALL_POPULAR_CITIES_FAIL,
// 					payload: [],
// 				});
// 			});
// }

export const getAllPopularCities = () => async (dispatch,getState) => {
	// dispatch(handleLoadingDispatcher(LOADING_COUNTRY_RANKING, true));
	// dispatch(handleSuccessDispatcher(SUCCESS_COUNTRY_RANKING, false));
	if(!getState().apis.allPopularCities.data.length) {
		let url = "regions/popular-cities/";
		try {
			url = process.env.REACT_APP_BASE_URL + url
			const response = await axios.get(url);
			dispatch(
				{
					type: GET_ALL_POPULAR_CITIES_SUCCESS,
					payload: response.data,
				}
			);
		} catch (e) {
			return dispatch({
				type: GET_ALL_POPULAR_CITIES_FAIL,
				payload: [],
			});
		}
	}
};
