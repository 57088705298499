import React, {memo,useState} from "react";
import CourseInputSearch from "../../components/filters_individuals/CourseInput";
import CoursesFilters from "../../components/CommonComponents/CoursesFilters";


function Courses(props) {
const [selectedCourse,setSelectedCourse]=useState([])
    const submitSearch = (courseInput) => {
    setSelectedCourse(courseInput.split(','));
    };
    return (
        <div className="main">
            <CourseInputSearch submitSearch={submitSearch}/>
            <CoursesFilters onClickCourses={selectedCourse}/>
            {/* <section className="compareSwiper">
                <h5>1 course Selected</h5>
                <h6>You can compare upto 3 courses</h6>

                <p>* Add course from main page</p>
            </section> */}
        </div>


    );
}

export default memo(Courses);

