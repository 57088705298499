import { httpCrm } from "../../../services/apiService/apiService";
export const USER_PROFILE = "USER_PROFILE";
export const USER_PROFILE_SUCCESS = "USER_PROFILE_SUCCESS";
export const USER_PROFILE_FAIL = "USER_PROFILE_FAIL";

export function userProfileData(crmId) {
	let url = `myAccount.php?rec_ID=${crmId}`;

	const request = httpCrm.post(url);

	return (dispatch) =>
		request
			.then((response) =>
				dispatch({
					type: USER_PROFILE_SUCCESS,
					payload: response.data,
				})
			)
			.catch((error) => {
				return dispatch({
					type: USER_PROFILE_FAIL,
					payload: [],
				});
			});
}
