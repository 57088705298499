import { http } from "../../../services/apiService/apiService";
/// marketing card / advertisement card

export const GET_RELATED_PAGE_DETAIL = "GET_RELATED_PAGE_DETAIL";
export const GET_RELATED_PAGE_DETAIL_SUCCESS = "GET_RELATED_PAGE_DETAIL_SUCCESS";
export const GET_RELATED_PAGE_DETAIL_FAIL = "GET_RELATED_PAGE_DETAIL_FAIL";

export function getRelatedPageDetail(pageRoute) {
	let url = `page/category/${pageRoute}`;

	const request = http.get(url);

	return (dispatch) =>
		request
			.then((response) =>
				dispatch({
					type: GET_RELATED_PAGE_DETAIL_SUCCESS,
					payload: response.data,
				})
			)
			.catch((error) => {
				return dispatch({
					type: GET_RELATED_PAGE_DETAIL_FAIL,
					payload: [],
				});
			});
}
