import React, {useMemo, useState} from "react";
import * as a from "../../apis/store/actions";
import countryList from "react-select-country-list";
import Select from "react-select";
import {Alert} from "@mui/lab";
import {httpCrm} from "../../services/apiService/apiService";
import PhoneInput from "react-phone-input-2";
import {useDispatch} from "react-redux";
import {toast} from "react-toastify";

const customStyles = {
    option: (provided) => ({
        ...provided,
        color: "black",
    }),
    control: (provided) => ({
        ...provided,
        color: "black",
    }),
    singleValue: (provided) => ({
        ...provided,
        color: "black",
    }),
};

function CourseRequestInfoForm(props) {
    const options = useMemo(() => countryList().getData(), []);
    const dispatch = useDispatch();
    const ageOptions = getAgeOptions();

    function getAgeOptions() {
        var elements = [];
        for (let i = 15; i < 56; i++) {
            elements.push({value: i, label: i});
        }
        return elements;
    }

    const [fullName, setFullName] = useState(() => {
        if (
            localStorage.getItem("fname") != null &&
            localStorage.getItem("lname") != null
        ) {
            return (
                localStorage.getItem("fname") + " " + localStorage.getItem("lname")
            );
        } else {
            return "";
        }
    });
    const [firstName, setFirstName] = useState(() => {
        if (localStorage.getItem("fname") != null) {
            return localStorage.getItem("fname");
        } else {
            return "";
        }
    });
    const [lastName, setLastName] = useState(() => {
        if (localStorage.getItem("lname") != null) {
            return localStorage.getItem("lname");
        } else {
            return "";
        }
    });
    const [email, setEmail] = useState(localStorage.getItem("user_email"));
    // const [number, setNumber] = useState(null);
    const [reqCheck, setReqCheck] = useState(false);
    const [reqCheckError, setReqCheckError] = useState("");
    const [living, setLiving] = useState(null);
    const [locations, setLocations] = useState(null);
    const [phNumber, setPhNumber] = useState(localStorage.getItem("user_number"));

    const [year, setYear] = useState(null);
    const [question, setQuestion] = useState(null);
    const [age, setAge] = useState(null);
    const [ageError, setAgeError] = useState(null);
    const [nameError, setNameError] = useState(null);
    const [emailError, setEmailError] = useState(null);
    const [livingError, setLivingError] = useState(null);
    const [yearError, setYearError] = useState(null);
    const [numberError, setNumberError] = useState(null);
    const [questionError, setQuestionError] = useState(null);
    const [locationError, setLocationError] = useState(null);
    const [nationality, setNationality] = useState(null);
    const [nationalityError, setNationalityError] = useState(null);
    const emailHandleChange = (event) => {
        if (!event) {
            setEmailError("Email is required");
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(event)) {
            setEmailError("Invalid email address");
        } else {
            setEmailError(null);
        }
        setEmail(event);
    };
    const nameHandleChange = (value) => {
        if (!value) {
            setNameError("Name is required");
        } else if (!/^[a-zA-Z]{4,}(?: [a-zA-Z]+)?(?: [a-zA-Z]+)?$/i.test(value)) {
            setNameError("incorrect name");
        } else if (/(?=.*[0-9])/i.test(value)) {
            setNameError("Invalid - should be a character");
        } else {
            setNameError(null);
            setFullName(value);
            setFirstName(value.split(" ").slice(0, -1).join(" "));
            setLastName(value.split(" ").slice(-1).join(" "));
        }
    };
    const numberHandleChange = (value, data, event) => {
        if (!value) {
            setNumberError("Phone number is required");
        } else if (!data.countryCode) {
            setNumberError("Country code is required");
        } else {
            setNumberError("");
        }
        setPhNumber(value);
    };
    const livingHandleChange = (value) => {
        if (!value) {
            setLivingError("Living is required");
        } else if (/(?=.*[0-9])/i.test(value)) {
            setLivingError("Invalid - should be a character");
        } else {
            setLivingError(null);
        }
        setLiving(value);
    };

    const yearHandleChange = (value) => {
        if (!value) {
            setYearError("Year is required");
        } else if (!/(?=.*[0-9])/i.test(value)) {
            setYearError("Invalid - should be a number");
        } else {
            setYearError(null);
        }
        setYear(value);
    };
    const checkBoxValidate = (event) => {
        if (event) {
            setReqCheck(event);
            setReqCheckError(null);
            return event;
        } else {
            setReqCheck(event);
        }
    };
    const questionHandleChange = (value) => {
        if (!value) {
            setQuestionError("This field is required");
        } else {
            setQuestionError(null);
        }
        setQuestion(value);
    };

    async function onSubmit(event) {
        event.preventDefault();
        if (
            email != null &&
            fullName != null &&
            living != null &&
            year != null &&
            phNumber != null &&
            question != null &&
            age != null &&
            locations != null &&
            nationality != null &&
            reqCheck === true
        ) {

            setEmailError(null);
            setNameError(null);
            setLivingError(null);
            setYearError(null);
            setQuestionError(null);
            setNumberError(null);
            setAgeError(null);
            setLocationError(null);
            setNationalityError(null);
            setReqCheckError(null);
            if (emailError == "") {
                await httpCrm
                    .post(
                        `webForms.php?user_id=${localStorage.getItem(
                            "user_Id"
                        )}&first_name=${firstName}&
			last_name=${lastName}&email_address=${email}&mobile=${phNumber}
			&residence= ${nationality}&citizenship=${nationality}&course_id= ${
                            props?.course?.id
                        }&campus_name= ${props?.course?.campus?.campus}&country_name= ${
                            props?.course?.campus?.city?.state?.country?.name
                        }
			&discipline_name= ${props?.course?.discipline?.name}&institute_name=${
                            props?.course?.campus?.institute?.institute_name
                        }&
			campus_address=${props?.course?.campus?.address}&
			course_language=${props?.course?.course_language}&course_title=${
                            props?.course?.course_title?.display_name
                        }&degree_level=${
                            props?.course?.degree_level?.name
                        }&institute_sector=${
                            props?.course?.campus?.institute?.sector
                        }&program_discipline=${props?.course?.discipline?.name}&
			specialization=${
                            props?.course?.specialization?.name
                        }&source=Request-Info&nationality=${nationality}&age=${age}&preferredCountry=${locations}&message=${question}&year=${year}&currentLiving=${living}`
                    )
                    .then((result) => {
                        toast.success("Submitted Successfully")
                    });

            }

        } else {
            if (email == null) {
                setEmailError("Email is required");
            }
            if (fullName == null) {
                setNameError("Full name is required");
            }
            if (living == null) {
                setLivingError("Living is required");
            }
            if (year == null) {
                setYearError("Year is required");
            }
            if (phNumber == null) {
                setNumberError("Phone number is required");
            }
            if (question == null) {
                setQuestionError("This field is  required");
            }
            if (age == null) {
                setAgeError("Age is required");
            }
            if (locations == null) {
                setLocationError("Location is required");
            }
            if (nationality == null) {
                setNationalityError("Nationality is required");
            }
            if (reqCheck === false) {
                setReqCheckError("required");
            }
        }
    }

    const handleSelectChange = (selectedOption) => {
        setNationality(selectedOption);
        if (!selectedOption) {
            setNationalityError("Nationality is required");
        } else {
            setNationalityError(null);
        }
    };

    const handleMultiChange = (option) => {
        setLocations(option);
        if (!option) {
            setLocationError("Location is required");
        } else {
            setLocationError(null);
        }
    };

    const handleAgeChange = (selectedOption) => {
        setAge(selectedOption);
    };

    // useEffect(() => {
    // 	register({ name: "nationality" });
    // 	register({ name: "locations" });
    // 	register({ name: "age" });
    // }, []);
    const AgePlaceholder = "Choose Age";
    const NationPlaceholder = "Choose Nationality";

    async function form_submission(e) {
        e.preventDefault();
        if (emailError === "" && reqCheck) {
            await httpCrm
                .post(
                    `createCase.php?user_id=${localStorage.getItem(
                        "user_Id"
                    )}&subject=${"Request Info"}&
			type=${"request_info"}&birthdate=${
                        age.value
                    }&primary_address_country=${living}&nationality_c=${nationality.name}&
			country_pref=${locations.name}&intake_c=${
                        props?.course?.admission_schedule[0]?.intake_month
                    }&level_c=${props.course.degree_level.level_type}&discipline_c=${
                        props.course.discipline.name
                    }&description=${question}`
                )
                .then((res) => {
                    toast.success("Submitted Successfully")
                });
        } else {
            setEmailError("*required");
        }

    }

    return (
        <div
            className="modal fade"
            id="requestInfoModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="requestInfoModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="requestInfoModalLabel">
                            Request More Info
                        </h5>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="cdSidebar courseDetailPage">
                            <h5>Request Information</h5>
                            <p>
                                Want to know more about {props.course?.campus?.institute.institute_name}
                                ? Fill out the form and include any questions you have. This information
                                will be sent directly to the school, and a representative will respond
                                to your enquiry.
                            </p>
                            <form>
                                <div className="form-group">
                                    <input
                                        className={"form-control" + (nameError ? " is-invalid" : "")}
                                        name="name"
                                        value={fullName}
                                        onChange={(e) => nameHandleChange(e.target.value)}
                                        placeholder="Full Name"
                                    />
                                    <img
                                        src={require("../../../static/images/forms/full_name.svg").default}
                                        alt="full_name"
                                        height=""
                                        width=""
                                        className="formIcon"
                                    />

                                    {nameError ? <Alert severity="error">{nameError}</Alert> : null}
                                </div>
                                <div className="form-group">
                                    <input
                                        className={"form-control" + (emailError ? " is-invalid" : "")}
                                        name="email"
                                        type="email"
                                        value={email}
                                        onChange={(e) => emailHandleChange(e.target.value)}
                                        placeholder="Email Address"
                                    />
                                    <img
                                        src={require("../../../static/images/forms/Email.svg").default}
                                        alt="Email"
                                        height=""
                                        width=""
                                        className="formIcon"
                                    />
                                    {emailError ? <Alert severity="error">{emailError}</Alert> : null}
                                </div>
                                <div className="form-group">
                                    <PhoneInput
                                        value={phNumber}
                                        onChange={(value, data, event) =>
                                            numberHandleChange(value, data, event)
                                        }
                                        autoFormat
                                        country={"pk"}
                                        placeholder="Enter the Number"
                                        enableAreaCodes="true"
                                        className={"form-control" + (numberError ? " is-invalid" : "")}
                                    />

                                    {numberError ? <Alert severity="error">{numberError}</Alert> : null}
                                </div>
                                <div className="form-group">
                                    <Select
                                        name="age"
                                        placeholder={AgePlaceholder}
                                        value={age}
                                        options={ageOptions}
                                        onChange={handleAgeChange}
                                        styles={customStyles}
                                    />

                                    <img
                                        src={require("../../../static/images/forms/age.svg").default}
                                        alt="age"
                                        height=""
                                        width=""
                                        className="formIcon"
                                    />
                                    {!age && ageError ? <Alert severity="error">{ageError}</Alert> : null}
                                </div>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        value={living}
                                        onChange={(e) => livingHandleChange(e.target.value)}
                                        className={"form-control" + (livingError ? " is-invalid" : "")}
                                        placeholder="Currently living in "
                                    />
                                    <img
                                        src={
                                            require("../../../static/images/forms/currently_living_in.svg")
                                                .default
                                        }
                                        alt="currently_living_in"
                                        height=""
                                        width=""
                                        className="formIcon"
                                    />
                                    {livingError ? <Alert severity="error">{livingError}</Alert> : null}
                                </div>
                                <div className="form-group">
                                    <Select
                                        name="filters"
                                        placeholder={NationPlaceholder}
                                        value={nationality}
                                        options={options}
                                        onChange={handleSelectChange}
                                        styles={customStyles}
                                    />
                                    <img
                                        src={
                                            require("../../../static/images/forms/Nationality.svg").default
                                        }
                                        alt="Nationality"
                                        height=""
                                        width=""
                                        className="formIcon"
                                    />
                                    {!nationality && nationalityError ? (
                                        <Alert severity="error">{nationalityError} </Alert>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className={"form-control" + (yearError ? " is-invalid" : "")}
                                        placeholder="Year I would like to study"
                                        value={year}
                                        onChange={(e) => yearHandleChange(e.target.value)}
                                    />
                                    <img
                                        src={require("../../../static/images/forms/year.svg").default}
                                        alt="year"
                                        height=""
                                        width=""
                                        className="formIcon"
                                    />
                                    {yearError ? <Alert severity="error">{yearError}</Alert> : null}
                                </div>
                                <div className="form-group">
                                    <Select
                                        name="location"
                                        placeholder="Preferred study Location"
                                        value={locations}
                                        options={options}
                                        onChange={handleMultiChange}
                                        styles={customStyles}
                                        isMulti
                                    />
                                    <img
                                        src={
                                            require("../../../static/images/forms/preferred_location.svg")
                                                .default
                                        }
                                        alt="preferred_location"
                                        height=""
                                        width=""
                                        className="formIcon"
                                    />
                                    {locationError && !locations ? (
                                        <Alert severity="error">{locationError}</Alert>
                                    ) : null}
                                </div>
                                <div className="form-group">
					<textarea
                        className={"form-control" + (questionError ? " is-invalid" : "")}
                        placeholder="Your Question"
                        value={question}
                        onChange={(e) => questionHandleChange(e.target.value)}
                    />
                                    <img
                                        src={require("../../../static/images/forms/question.svg").default}
                                        alt="question"
                                        height=""
                                        width=""
                                        className="formIcon"
                                    />
                                    {questionError ? (
                                        <Alert severity="error">{questionError}</Alert>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <label className="customCheckbox">
                                        <input
                                            type="checkbox"
                                            checked={reqCheck}
                                            className={"form-control" + (reqCheckError ? " is-invalid" : "")}
                                            onClick={(e) => checkBoxValidate(e.target.checked)}
                                        />
                                        <span>
							{" "}
                                            I accept the <a href="/terms-of-use">
								Terms and Conditions
							</a> & <a href="/privacy-policy">Privacy Policy</a>
						</span>{" "}
                                    </label>
                                </div>
                                <button
                                    disabled={reqCheck ? false : true}
                                    className="btn sendBtn"
                                    onClick={(e) => form_submission(e)}
                                >
                                    Request
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CourseRequestInfoForm;
