import { useState, useEffect } from "react"
import {ory} from "./sdk"
import { useHistory } from "react-router"
import { logoutOryUser } from "../../store/actions/authActions"
import { useDispatch } from "react-redux"

// Returns a function which will log the user out
export function LogoutLink(deps) {
  const [logoutToken, setLogoutToken] = useState("")
  const router = useHistory()
  const dispatch = useDispatch();

  useEffect(() => {
    ory
      .createBrowserLogoutFlow()
      .then(({ data }) => {
        setLogoutToken(data.logout_token)
      })
      .catch((err) => {
        switch (err.response?.status) {
          case 401:
            // do nothing, the user is not logged in
            return
        }

        // Something else happened!
        return Promise.reject(err)
      })
  }, [deps])

  return () => {
    if (logoutToken) {
      ory
        .updateLogoutFlow({ token: logoutToken })
        .then(() => dispatch(logoutOryUser()))
        .then(() => router.push("/login"))
        // .then(() => router.go(0))
    } else {
      dispatch(logoutOryUser())
      router.push("/")
    }
  }
}
