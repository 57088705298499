const QualificationSearchBox = ({
    selectedDegreeLevel,
    clearDegreeLevel,
    onRemoveDegreeLevel,
    onClickViewMoreDegreeLevels,
    degreeLevelPlaceholder,
    degreeLevelInput,
    onFocusDiscipline,
    onFocusOutDiscipline,
    searchDegreeLevels,
    onFocusDisciplineArrow,
    selectedDegreeLevelError,
    degreeLevelsOptions,
    onSelectUndergraduate,
    setDegreeType,
    undergraduate,
    arrowUpDownClick,
    degreeLevelSearch,
    onSelectDegreeLevel,
    onSelectPostgraduate,
    postgraduate,
    onSelectResearch,
    research
}) => {
    return (
        <div className="searchBox">
            {/* {selectedDegreeLevel.length >= 2 && (
                <button className="unselectBtn" onClick={clearDegreeLevel}>
                    x
                </button>
            )} */}
            <div className="custom-dropdown">
                {/* Can be used in multi select Qualifications */}
                {/* <div className="searchTagWrap">
                    {selectedDegreeLevel.map((item, index) => {
                        if (index < 3) {
                            return (
                                <div key={index} className="searchTag">
                                    {item.name}
                                    <button
                                        className="unselectBtn"
                                        onClick={(e) => {
                                            onRemoveDegreeLevel(index);
                                        }}
                                    >
                                        x
                                    </button>
                                </div>
                            );
                        } else {
                            return null;
                        }
                    })}
                    {selectedDegreeLevel.length > 3 && (
                        <div className="searchTag" style={{ paddingRight: 5 }}>
                            <a href
                                onClick={(e) => {
                                    onClickViewMoreDegreeLevels();
                                }}
                            >
                                view More
                            </a>
                        </div>
                    )}
                </div> */}
                <div className="btnWrap">
                    <input
                        id="degree_levels_input"
                        placeholder={degreeLevelPlaceholder}
                        type="text"
                        spellCheck={"on"}
                        autoCapitalize={"on"}
                        autoComplete={"off"}
                        autoCorrect={"on"}
                        className={"form-control"}
                        value={degreeLevelInput}
                        onFocus={onFocusDiscipline}
                        onBlur={onFocusOutDiscipline}
                        onChange={(e) => searchDegreeLevels(e)}
                        onKeyPress={(e) => searchDegreeLevels(e)}
                    />
                    <i
                        className="fa fa-angle-down"
                        onClick={onFocusDisciplineArrow}
                    />
                    {selectedDegreeLevelError && (
                        <p className="text-danger">Degree level is required</p>
                    )}
                </div>
                <div
                    id="disciplineOpen"
                    className="custom-dropdown-menu qualificationDrop"
                >
                    <div className="accordion" id="accordionExample">
                        {Object.keys(degreeLevelsOptions).length !== 0 ? (
                            <div>
                                {degreeLevelsOptions?.undergraduate && (
                                    <div className="card">
                                        <a
                                            href
                                            className="btn dropdown-item accordBtn searchItemBtn"
                                            onClick={(e) => {
                                                onSelectUndergraduate(e);
                                                setDegreeType('types')
                                            }}
                                        >
                                            <span>
                                                {undergraduate && (
                                                    <i
                                                        className="fa fa-check-circle"
                                                        style={{ color: "#291C5A", marginRight: 5 }}
                                                    />
                                                )}
                                                Undergraduate
                                            </span>
                                            <a
                                                onClick={arrowUpDownClick}
                                                data-toggle="collapse"
                                                href="#collapseOne"
                                                role="button"
                                                aria-expanded="false"
                                                aria-controls="collapseExample"
                                            >
                                                <i className="fa fa-angle-right" />
                                                <i className="fa fa-angle-down" />
                                            </a>
                                        </a>

                                        <div
                                            id="collapseOne"
                                            className={
                                                degreeLevelSearch
                                                    ? "collapse accordCollapse show"
                                                    : "collapse"
                                            }
                                        >

                                            {degreeLevelsOptions?.undergraduate.map(
                                                (item, index) => {
                                                    return (
                                                        <a href
                                                            key={index}
                                                            className={"dropdown-item"}
                                                            onClick={() => {
                                                                onSelectDegreeLevel(item, item.active);
                                                                setDegreeType('levels')
                                                            }
                                                            }
                                                        >

                                                            {item.active ? (
                                                                <i
                                                                    className="fa fa-check-circle"
                                                                    style={{ color: "#291C5A" }}
                                                                />
                                                            ) : ""}
                                                            {item.name}
                                                        </a>
                                                    );
                                                }
                                            )}
                                        </div>
                                    </div>
                                )}
                                {degreeLevelsOptions?.postgraduate && (
                                    <div className="card">
                                        <a
                                            href
                                            className="btn dropdown-item accordBtn searchItemBtn"
                                            onClick={(e) => {
                                                onSelectPostgraduate(e);
                                                setDegreeType('types')
                                            }}
                                        >
                                            {" "}
                                            <span>
                                                {postgraduate && (
                                                    <i
                                                        className="fa fa-check-circle"
                                                        style={{ color: "#291C5A", marginRight: 5 }}
                                                    />
                                                )}
                                                Postgraduate{" "}
                                            </span>
                                            <a
                                                onClick={arrowUpDownClick}
                                                data-toggle="collapse"
                                                href="#collapseTwo"
                                                role="button"
                                                aria-expanded="false"
                                                aria-controls="collapseExample"
                                            >
                                                <i className="fa fa-angle-right" />
                                                <i className="fa fa-angle-down" />
                                            </a>
                                        </a>

                                        <div
                                            id="collapseTwo"
                                            className={
                                                degreeLevelSearch
                                                    ? "collapse accordCollapse show"
                                                    : "collapse"
                                            }
                                        >
                                            {degreeLevelsOptions?.postgraduate.map(
                                                (item, index) => {
                                                    return (
                                                        <a
                                                            href
                                                            key={index}
                                                            className={"dropdown-item"}
                                                            onClick={() => { onSelectDegreeLevel(item); setDegreeType('levels') }}
                                                        >
                                                            {item.active ? (
                                                                <i
                                                                    className="fa fa-check-circle"
                                                                    style={{ color: "#291C5A" }}
                                                                />
                                                            ) : ""}
                                                            {item.name}
                                                        </a>
                                                    );
                                                }
                                            )}
                                        </div>
                                    </div>
                                )}
                                {degreeLevelsOptions?.postgraduate_by_research && (
                                    <div className="card">
                                        <a
                                            href
                                            className="btn dropdown-item accordBtn searchItemBtn"
                                            onClick={(e) => {
                                                onSelectResearch(e);
                                                setDegreeType('types')
                                            }}
                                        >
                                            <span>
                                                {research && (
                                                    <i
                                                        className="fa fa-check-circle"
                                                        style={{ color: "#291C5A", marginRight: 5 }}
                                                    />
                                                )}
                                                Postgraduate by Research
                                            </span>
                                            <a
                                                onClick={arrowUpDownClick}
                                                data-toggle="collapse"
                                                href="#collapseThree"
                                                role="button"
                                                aria-expanded="false"
                                                aria-controls="collapseExample"
                                            >
                                                <i className="fa fa-angle-right" />
                                                <i className="fa fa-angle-down" />
                                            </a>
                                        </a>

                                        <div
                                            id="collapseThree"
                                            className={
                                                degreeLevelSearch
                                                    ? "collapse accordCollapse show"
                                                    : "collapse"
                                            }
                                        >
                                            {degreeLevelsOptions?.postgraduate_by_research.map(
                                                (item, index) => {
                                                    return (
                                                        <a href
                                                            key={index}
                                                            className={"dropdown-item"}
                                                            onClick={() => { onSelectDegreeLevel(item); setDegreeType('levels') }}
                                                        >
                                                            {item.active && (
                                                                <i
                                                                    className="fa fa-check-circle"
                                                                    style={{ color: "#291C5A" }}
                                                                />
                                                            )}
                                                            {item.name}
                                                        </a>
                                                    );
                                                }
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <p>No match</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QualificationSearchBox;