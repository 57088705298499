import React, { useEffect, useMemo, useState } from "react";
import * as a from "../../apis/store/actions";
import countryList from "react-select-country-list";
import Select from "react-select";
import { httpCrm } from "../../services/apiService/apiService";
import { Alert } from "@mui/lab";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LocationSearchInput from "../map/autoloaction";
import PhoneInput from "react-phone-input-2";
import $ from "jquery";
import LoadingSpinner from "../loader/loader";

const customStyles = {
    option: (provided) => ({
        ...provided,
        color: "black",
    }),
    control: (provided) => ({
        ...provided,
        color: "black",
    }),
    singleValue: (provided) => ({
        ...provided,
        color: "black",
    }),
};
const intakesOptions = [
    { value: "January", label: "January" },
    { value: "February", label: "February" },
    { value: "March", label: "March" },
    { value: "April", label: "April" },
    { value: "May", label: "May" },
    { value: "June", label: "June" },
    { value: "July", label: "July" },
    { value: "August", label: "August" },
    { value: "September", label: "September" },
    { value: "October", label: "October" },
    { value: "November", label: "November" },
    { value: "December", label: "December" },
];

function CourseApplyForm(props) {
    const options = useMemo(() => countryList().getData(), []);
    const emailRegrex = "/^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$/";
    const [first_name, setFirst_name] = useState(localStorage.getItem("fname"));
    const [last_name, setLast_name] = useState(localStorage.getItem("lname"));
    const [email, setEmail] = useState(localStorage.getItem("user_email"));
    const [nationality, setNationality] = useState(null);
    const [residence, setResidence] = useState(null);
    const [intakes, setIntakes] = useState([]);
    const [city, setCity] = useState(null);
    const [reqCheck, setReqCheck] = useState(false);
    const [question, setQuestion] = useState(null);
    const [loader, setLoader] = useState(false)
    const [reqCheckError, setReqCheckError] = useState("");
    const [phNumber, setPhNumber] = useState(localStorage.getItem("user_number"));
    const [countryCode, setCountryCode] = useState("");
    const [start, setStart] = useState("");
    const [citizenship, setCitizenship] = useState("");
    const [preferredCountry, setPreferredCountry] = useState();
    const [first_nameError, setFirst_nameError] = useState("");
    const [last_nameError, setLast_nameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [phNumberError, setPhNumberError] = useState("");
    const [residenceError, setResidenceError] = useState("");
    const [questionError, setQuestionError] = useState(null);
    const [locationError, setLocationError] = useState(null);
    const [nationalityError, setNationalityError] = useState(null);

    const NationPlaceholder = "Choose Nationality";
    useEffect(()=>{
        setEmail(localStorage.getItem("user_email"))
    },[localStorage.getItem("user_email")])
    const [locations, setLocations] = useState([]);
    const handleSelectChange = (selectedOption) => {
        setNationality(selectedOption);
        if (!selectedOption) {
            setNationalityError("Nationality is required");
        } else {
            setNationalityError(null);
        }
    };
    const questionHandleChange = (value) => {
        if (!value) {
            setQuestionError("Message is required");
        } else {
            setQuestionError(null);
        }
        setQuestion(value);
    };

    const handleIntakeMultiChange = (option) => {
        // setIntakes(option);
        if (!option) {
            setIntakes([]);
            // setValue("intakes", []);
        } else {
            // const intakesList = option.map((item) => {
            // 	return item.label;
            // });
            // setValue("intakes", intakesList);
            setIntakes(option);
        }
    };

    function getIntakes() {
        let elements = [];
        for (let i = 0; i < props.course?.admission_schedule?.length; i++) {
            elements.push({
                value: props?.course?.admission_schedule[i].intake_month,
                label: props?.course?.admission_schedule[i].intake_month,
            });
        }
        return elements;
    }

    const newIntakes = getIntakes();

    const first_nameHandleChange = (value) => {
        if (!value) {
            setFirst_nameError("First name is required");
        } else if (!/^[A-Za-z]+$/.test(value)) {
            setFirst_nameError("invalid - name should be  a character.");
        } else {
            setFirst_nameError("");
        }
        setFirst_name(value);
    };
    const last_nameHandleChange = (value) => {
        if (!value) {
            setLast_nameError("Last name is required");
        } else if (!/^[A-Za-z]+$/.test(value)) {
            setLast_nameError("invalid - name should be  a character.");
        } else {
            setLast_nameError("");
        }
        setLast_name(value);
    };
    const emailHandleChange = (value) => {
        if (!value) {
            setEmailError("Email is required");
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
            setEmailError("Invalid email address");
        } else {
            setEmailError("");
        }
        setEmail(value);
    };
    const residenceHandleChange = (value) => {
        if (!value) {
            setResidence(null);
            setResidenceError("Residence is required");

        } else {
            setResidenceError("");
            setResidence(value);
        }

    };
    const handleMultiChange = (option) => {
        setLocations(option);
        if (!option) {
            setLocationError("Location is required");
        } else {
            setLocationError(null);
        }
    };
    const phNumberHandleChange = (value, data, event) => {
        if (!value) {
            setPhNumberError("Phone number is required");
        } else if (!data.countryCode) {
            setPhNumberError("Country Code is required");
        } else {
            setPhNumberError("");
        }
        setCountryCode(data.countryCode);

        setPhNumber(value);
    };

    function clearAllInputs() {
        // setFirst_name(null);
        // setLast_name(null);
        // setEmail(null);
        // setPhNumber(null);
        setResidence(null);
        setNationality(null);
        setCity("");
        setQuestion("");
        setLocations(null);
    }
    async function addApplication(e) {
        e.preventDefault();
        if (
            first_name !== null &&
            last_name !== null &&
            email !== null &&
            // city !== null &&
            residence !== null &&
            phNumber !== null &&
            nationality !== null &&
            question !== null &&
            reqCheck
        ) {
            if (emailError === "") {
                setLoader(true)
                await httpCrm
                    .post(
                        `applyToCourse.php?user_id=${localStorage.getItem("user_Id")}&
				first_name=${first_name}&app_slug=${props?.course?.slug}&last_name=${last_name}&last_name=${last_name}&email_address=${email}&mobile=${phNumber}&
				city=${city}&residence= ${residence.label}&citizenship=${nationality.label}&
				preferredCountry=${locations.label}&course_id= ${props.course.id
                        }&campus_name= ${props.course.campus.name}&country_name= ${props.course.country
                        }&
				discipline_name= ${props.course.discipline}&institute_name=${props.course.institute.institute_name
                        }&
				campus_address=${props.course.campus.name}&course_language=${props.course.course_language
                        }
				&course_title=${props.course.course_title}&degree_level=${props.course.degree_level
                        }&institute_sector=${props.course.institute.sector}&specialization=${props.course.specialization
                        }
				&source=Course-Apply&nationality=${nationality.label
                        }&intake_c=${intakes.value}&course_fee=${props.course?.course_fee[0].ceil_value}
				&program_discipline=${props.course.discipline}&message=${question}`
                    )
                    .then((res) => {
                        clearAllInputs();
                        toast.success("Application Submitted");
                        setLoader(false)
                        // $("#eligibility").modal("hide");
                        setTimeout(() => {
                            $("#applyModal").modal("hide");
                        }, 2000);
                        // $("#applyModal").modal("hide");
                    });
            } else {
                setEmailError("Invalid Email.");
            }

        } else {
            if (first_name === null) {
                setFirst_nameError("First name is required");
            }
            if (last_name === null) {
                setLast_nameError("Last name is required");
            }
            if (email === null) {
                setEmailError("Email is required");
            }
            if (phNumber === null) {
                setPhNumberError("Phone number is required");
            }
            if (residence === null) {
                setResidenceError("Residence is required");
            }
            if (nationality === null) {
                setNationalityError("Nationality is required");
            }
            if (reqCheck === false) {
                setReqCheckError("required");
            }
            if (locations === null) {
                setLocationError("Location is required");
            }
        }
    }

    const handleAutoLocation = (location) => {
        setCity(location.address);
    };
    const checkBoxValidate = (event) => {
        if (event) {
            setReqCheck(event);
            setReqCheckError(null);
            return event;
        } else {
            setReqCheck(event);
        }
    };
    return (
        <div
            className="modalFade"
            id="applyModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="applyModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-body">
                        <div>
                            <ToastContainer
                                position="top-center"
                                autoClose={1000}
                                hideProgressBar={true}
                            />
                            <h3>Request Information</h3>
                            <p>
                                Your study abroad journey begins here! Get personalized and
                                comprehensive guidance by our expert counselors. Just fill out the form
                                below to get started, and our team will be in touch shortly.
                            </p>
                            <form>
                                <div className="form-group">
                                    <img
                                        src={require("../../../static/images/forms/full_name.svg").default}
                                        alt="full_name"
                                        height=""
                                        width=""
                                        className="formIcon"
                                    />
                                    <input
                                        type="text"
                                        value={first_name}
                                        className={"form-control" + (first_nameError ? " is-invalid" : "")}
                                        placeholder="first_name"
                                        onChange={(e) => first_nameHandleChange(e.target.value)}
                                    />

                                    {first_nameError ? (
                                        <Alert severity="error">{first_nameError}</Alert>
                                    ) : null}
                                </div>

                                <div className="form-group">
                                    <input
                                        type="text"
                                        value={last_name}
                                        className={"form-control" + (last_nameError ? " is-invalid" : "")}
                                        placeholder="last_name"
                                        onChange={(e) => last_nameHandleChange(e.target.value)}
                                    />
                                    <img
                                        src={require("../../../static/images/forms/full_name.svg").default}
                                        alt="full_name"
                                        height=""
                                        width=""
                                        className="formIcon"
                                    />

                                    {last_nameError ? (
                                        <Alert severity="error">{last_nameError}</Alert>
                                    ) : null}
                                </div>

                                <div className="form-group">
                                    <img
                                        src={require("../../../static/images/forms/Email.svg").default}
                                        alt="Email"
                                        height=""
                                        width=""
                                        className="formIcon"
                                    />
                                    <input
                                        type="email"
                                        value={email}
                                        placeholder="Email Address"
                                        className={"form-control" + (emailError ? " is-invalid" : "")}
                                        onChange={(e) => emailHandleChange(e.target.value)}
                                    />

                                    {emailError ? <Alert severity="error">{emailError}</Alert> : null}
                                </div>
                                <div className="form-group">
                                    <PhoneInput
                                        value={phNumber}
                                        onChange={(value, data, event) =>
                                            phNumberHandleChange(value, data, event)
                                        }
                                        autoFormat
                                        country={"pk"}
                                        placeholder="Enter the Number"
                                        enableAreaCodes="true"
                                        className={"form-control" + (phNumberError ? " is-invalid" : "")}
                                    />

                                    {phNumberError ? (
                                        <Alert severity="error">{phNumberError}</Alert>
                                    ) : null}
                                </div>

                                <div className="form-group">
                                    <Select
                                        name="filters"
                                        placeholder={NationPlaceholder}
                                        value={nationality}
                                        options={options}
                                        onChange={handleSelectChange}
                                        styles={customStyles}
                                    />
                                    <img
                                        src={
                                            require("../../../static/images/forms/Nationality.svg").default
                                        }
                                        alt="Nationality"
                                        height=""
                                        width=""
                                        className="formIcon"
                                    />
                                    {!nationality && nationalityError ? (
                                        <Alert severity="error">{nationalityError} </Alert>
                                    ) : null}
                                </div>

                                <div className="form-group">
                                    <Select
                                        name="residense"
                                        placeholder={"Country of Residence"}
                                        value={residence}
                                        isSearchable={true}
                                        options={options}
                                        onChange={residenceHandleChange}
                                        styles={customStyles}
                                    />

                                    <img
                                        src={
                                            require("../../../static/images/forms/preferred_location.svg")
                                                .default
                                        }
                                        alt="preferred_location"
                                        height=""
                                        width=""
                                        className="formIcon"
                                    />
                                    {residenceError ? (
                                        <Alert severity="error">{residenceError}</Alert>
                                    ) : null}
                                </div>

                                <div className="form-group">
                                    <img
                                        src={
                                            require("../../../static/images/forms/preferred_location.svg")
                                                .default
                                        }
                                        alt="preferred_location"
                                        height=""
                                        width=""
                                        className="formIcon"
                                    />
                                    <LocationSearchInput onLoadAutoLocation={handleAutoLocation} />
                                </div>

                                <div className="form-group">
                                    <Select
                                        name="location"
                                        placeholder="Preferred study Location"
                                        value={locations}
                                        options={options}
                                        onChange={handleMultiChange}
                                        styles={customStyles}
                                    // isMulti
                                    />
                                    <img
                                        src={
                                            require("../../../static/images/forms/preferred_location.svg")
                                                .default
                                        }
                                        alt="preferred_location"
                                        height=""
                                        width=""
                                        className="formIcon"
                                    />
                                    {locationError && !locations ? (
                                        <Alert severity="error">{locationError}</Alert>
                                    ) : null}
                                </div>

                                <div className="form-group">
                                    <Select
                                        name="intake"
                                        placeholder="Target intakes (optional)"
                                        value={intakes}
                                        options={intakesOptions}
                                        onChange={handleIntakeMultiChange}
                                        styles={customStyles}
                                    // isMulti
                                    />
                                    <img
                                        src={
                                            require("../../../static/images/forms/target-intakes.svg").default
                                        }
                                        alt="target-intakes"
                                        height=""
                                        width=""
                                        className="formIcon"
                                    />

                                </div>

                                <div className="form-group">
                                    <textarea
                                        className={"form-control" + (questionError ? " is-invalid" : "")}
                                        placeholder="Your Question"
                                        value={question}
                                        onChange={(e) => questionHandleChange(e.target.value)}
                                    />
                                    <img
                                        src={require("../../../static/images/forms/question.svg").default}
                                        alt="question"
                                        height=""
                                        width=""
                                        className="formIcon"
                                    />
                                    {questionError ? (
                                        <Alert severity="error">{questionError}</Alert>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <label className="customCheckbox">
                                        <input
                                            name="acceptTerms"
                                            type="checkbox"
                                            defaultChecked={reqCheck}
                                            className={"form-control" + (reqCheckError ? " is-invalid" : "")}
                                            onClick={(e) => checkBoxValidate(e.target.checked)}
                                        />
                                        <span>
                                            I accept the <a href="/terms-of-use">
                                                Terms and Conditions
                                            </a> & <a href="/privacy-policy">Privacy Policy</a>
                                        </span>
                                    </label>
                                    {reqCheckError ? (
                                        <Alert severity="error">{reqCheckError}</Alert>
                                    ) : null}
                                </div>
                                <button
                                    className="btn sendBtn formBtn"
                                    onClick={(e) =>
                                        addApplication(e)
                                    }
                                    disabled={!reqCheck || loader}
                                >
                                    {loader ? <LoadingSpinner height='auto' color='#fff'/> : 'Submit'}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CourseApplyForm;
