import React, { useEffect, useState } from 'react'
import CityFilter from './city_filter'

function StateFilter(props) {
  const { state, selectedLocations, filteredStateValue } = props
  const [activeClass, setActiveClass] = useState(false)
  const [stateCheck, setStateCheck] = useState(() => {
    return !!props.status
  })
  useEffect(() => {
    if (props.status) {
      setStateCheck(true)
    } else {
      if (selectedLocations.states.length >= 1) {
        if (selectedLocations.states.some((e) => e?.slug === state?.slug)) {
          setStateCheck(true)
        } else {
          setStateCheck(false)
        }
      } else {
        setStateCheck(false)
      }
    }
	// console.log(filteredStateValue,'filteredCountriesValue==');
    if (filteredStateValue?.length) {
      stateArrowDownClick(props.state.state_name, props.index)
    }
  }, [props])

  useEffect(() => {
    if (
      selectedLocations.states.length === 0 &&
      selectedLocations.cities.length === 0
    ) {
      setActiveClass(false)
    } else {
      var status = true
      if (status) {
        if (selectedLocations.states.length >= 1) {
          var stateInfo = selectedLocations.states.find(
            (s) => s?.slug === state?.slug,
          )
          if (stateInfo) {
            setActiveClass(true)
            status = false
          }
        }
      }
      if (status) {
        if (selectedLocations.cities.length >= 1) {
          var cityInfo = selectedLocations.cities.find(
            (c) => c.state_id === state?.slug,
          )
          if (cityInfo) {
            setActiveClass(true)
            status = false
          }
        }
      }
      if (status) {
        setActiveClass(false)
      }
    }
  }, [props])

  const onChangeStateCheck = (state, check) => {
    setStateCheck(!check)
    if (!check) {
      props.callStateUpdate(state, 'state', true)
    } else {
      props.callStateUpdate(state, 'state', false)
    }
  }
  const stateArrowToggleClick = (stateName, index) => {
    var subElement = document.getElementById(stateName + String(index))
    var className = subElement.className
    if (className.search('show') === -1) {
      var element = document.getElementById(stateName)
      element.className += ' filterOpen'
      var subElement = document.getElementById(stateName + String(index))
      subElement.className += ' show'
    } else {
      var element = document.getElementById(stateName)
      element.className = ' filterItem'
      var subElement = document.getElementById(stateName + String(index))
      subElement.className = ' filterItemCont'
    }
  }

  const stateArrowDownClick = (stateName, index) => {
    var element = document.getElementById(stateName)
    element.className += ' filterOpen'
    var subElement = document.getElementById(stateName + String(index))
    subElement.className += ' show'
  }
  const stateArrowUpClick = (stateName, index) => {
    var element = document.getElementById(stateName)
    element.className = ' filterItem'
    var subElement = document.getElementById(stateName + String(index))
    subElement.className = ' filterItemCont'
  }
  const onCityUpdate = (obj, type, status) => {
    props.callStateUpdate(obj, type, status)
  }
  return (
    <>
      <div className="filterItemWrap">
        <div id={props.state.state_name} className="filterItem ">
          <input
            className={activeClass === true ? 'childChecked' : ''}
            key={props.index + Math.floor(Math.random() * 1000)}
            type="checkbox"
            id={'state'+props.state.state_name}
            onChange={() => {
              onChangeStateCheck(props.state, stateCheck)
            }}
            checked={stateCheck}
          ></input>
          <label
          htmlFor={'state'+props.state.state_name}
            className={activeClass === true ? 'active' : ''}
            // onClick={(e) => {
            //   stateArrowToggleClick(props.state.state_name, props.index)
            // }}
          >
            {props.state.state_name} 
            {props.state.course_count
            ? ` (${props.state.course_count})`
              : props.state.institute_count
              ? ` (${props.state.institute_count})`
              : ''}
          </label>
          <i
            className="fa fa-angle-right"
            onClick={(e) => {
              stateArrowDownClick(props.state.state_name, props.index)
            }}
          ></i>
          <i
            className="fa fa-angle-down"
            onClick={(e) => {
              stateArrowUpClick(props.state.state_name, props.index)
            }}
          ></i>
        </div>
        <div
          id={props.state.state_name + String(props.index)}
          className="filterItemCont "
        >
          {stateCheck
            ? props.state.cities?.map((city, index) => (
                <CityFilter
                  key={index}
                  city={city}
                  index={index}
                  status={true}
                  selectedLocations={selectedLocations}
                  callCityUpdate={onCityUpdate}
                />
              ))
            : props.state.cities?.map((city, index) => (
                <CityFilter
                  key={index}
                  city={city}
                  index={index}
                  status={false}
                  selectedLocations={selectedLocations}
                  callCityUpdate={onCityUpdate}
                />
              ))}
        </div>
      </div>
    </>
  )
}
export default StateFilter
