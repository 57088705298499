import { http } from "../../../services/apiService/apiService";

export const DYNAMIC_PAGES = "DYNAMIC_PAGES";
export const DYNAMIC_PAGES_SUCCESS = "DYNAMIC_PAGES_SUCCESS";
export const DYNAMIC_PAGES_FAIL = "DYNAMIC_PAGES_FAIL";

export function allDynamicPagesByType() {
	let url = "pages/";
	// if (pageId) {
	// 	url = url + "?page=" + pageId;
	// }
	const request = http.get(url);

	return (dispatch) =>
		request
			.then((response) =>
				dispatch({
					type: DYNAMIC_PAGES_SUCCESS,
					payload: response.data,
				})
			)
			.catch((error) => {
				return dispatch({
					type: DYNAMIC_PAGES_FAIL,
					payload: [],
				});
			});
}
export const DYNAMIC_PAGE_BY_ID = "DYNAMIC_PAGE_BY_ID";
export const DYNAMIC_PAGE_BY_ID_SUCCESS = "DYNAMIC_PAGE_BY_ID_SUCCESS";
export const DYNAMIC_PAGE_BY_ID_FAIL = "DYNAMIC_PAGE_BY_ID_FAIL";

export function dynamicPageById(pageId) {
	let url = "pages/" + pageId;
	// if (pageId) {
	// 	url = url + "?page=" + pageId;
	// }
	const request = http.get(url);

	return (dispatch) =>
		request
			.then((response) =>
				dispatch({
					type: DYNAMIC_PAGE_BY_ID_SUCCESS,
					payload: response.data,
				})
			)
			.catch((error) => {
				return dispatch({
					type: DYNAMIC_PAGE_BY_ID_FAIL,
					payload: [],
				});
			});
}
