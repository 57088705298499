import InfiniteScroll from "react-infinite-scroll-component";
import LoadingSpinner from "../loader/loader";

const DisciplineModal = ({
    selectedCourses,
    handleSpecializationSearch,
    onRemoveCourse,
    onClickSelectedCoursesDone,
    disciplines,
    onSelectDisciplineWithOpenSpecializations,
    onArrowDisciplineSpecializations,
    disciplineId,
    loadSpecializations,
    specializations,
    onSelectCourses,
    fetchData,
    page
}) => {
    return (
        <div
            className="modal fade regionModal"
            id="coursesModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
            data-backdrop="static"
            data-keyboard="false"
        >
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-body">
                        <button
                            type="button"
                            className="discipline-modal close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <i className="fa fa-times"></i>
                        </button>
                        <div className="modalTagWrap">
                            {selectedCourses?.map((item, index) => {
                                return (
                                    <div key={index} className="searchTag">
                                        {item.name}
                                        <button
                                            className="unselectBtn"
                                            onClick={() => {
                                                onRemoveCourse(index, item);
                                            }}
                                        >
                                        </button>
                                    </div>
                                );
                            })}
                            {selectedCourses?.length >= 1 && (
                                <button
                                    className="btn defaultBtn"
                                    onClick={(e) => {
                                        onClickSelectedCoursesDone();
                                    }}
                                >
                                    Done
                                </button>
                            )}
                        </div>

                        <div className="regionFlex regionCourseFlex">

                            <div className="right">
                                {disciplineId ? (
                                    loadSpecializations ? (
                                        <LoadingSpinner />
                                    ) : (
                                        <div>
                                            <h5 style={{ marginLeft: "13px" }}>Specializations</h5>
                                            {specializations?.length === 0 ? (
                                                <h4 style={{ textAlign: "center" }}>
                                                    No Record Found
                                                </h4>
                                            ) : loadSpecializations ? (
                                                <LoadingSpinner />
                                            ) : (
                                                <div
                                                    id="scrollableDiv"
                                                    style={{ maxHeight: 300, overflow: "auto" }}
                                                >
                                                    <InfiniteScroll
                                                        dataLength={specializations?.length} //This is important field to render the next data
                                                        next={(e) => fetchData(e)}
                                                        // pageStart={0}
                                                        hasMore={true || false}
                                                        loader={page != null ? <LoadingSpinner /> : null}
                                                        endMessage={
                                                            <p style={{ textAlign: "center" }}>
                                                                <b>Yay! You have seen it all</b>
                                                            </p>
                                                        }
                                                        scrollableTarget="scrollableDiv"
                                                    >
                                                        <ul>
                                                            {specializations?.map((item, index) => {
                                                                return (
                                                                    <li>
                                                                        <a className="searchItemBtn" href key={index} >
                                                                            <a key={item?.id} className={selectedCourses.some(d => d.id === item.id) ? "active" : ""}
                                                                                href
                                                                                onClick={() => {
                                                                                    onSelectCourses(item);
                                                                                }}
                                                                            >
                                                                                {item?.name}
                                                                            </a>
                                                                        </a>
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>
                                                    </InfiniteScroll>
                                                </div>
                                            )}
                                        </div>
                                    )
                                ) : (
                                    <div className="text-center">
                                        <p>
                                            Click arrow to view Specializations for specific
                                            Discipline{" "}
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default DisciplineModal;