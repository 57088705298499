import React, { useEffect, useState } from "react";

function DegreeLevelCheckInPut(props) {
	const { level, selectedDegreeLevels } = props;
	const [levelCheck, setLevelCheck] = useState(false);
	useEffect(() => {
		if (props.status) {
			setLevelCheck(true);
		} else {
			if (selectedDegreeLevels.levels.length >= 1) {
				if (selectedDegreeLevels.levels.some((e) => e.slug === level.slug)) {
					setLevelCheck(true);
				} else {
					setLevelCheck(false);
				}
			} else {
				setLevelCheck(false);
			}
		}
	}, [props]);
	const onChangeStateCheck = (obj, check, type) => {
		setLevelCheck(!check);
		obj.type = type;
		props.onLevelUpdate(obj, "level", !check);
	};
	return (
		<>
			<div className="filterItemWrap">
				<div id={props.level.name} className="filterItem ">
					<input
						key={props.index + Math.floor(Math.random() * 1000)}
						id={'degreelevel'+props.level}
						type="checkbox"
						onChange={() => {
							onChangeStateCheck(props.level, levelCheck, props.type);
						}}
						checked={levelCheck}
					></input>
					<label 
						id={'degreelevel'+props.level}
					className={levelCheck === true ? "active" : ""}>
						{props.level.name} (
						{props.level.course_count
							? props.level.course_count
							: props.level.scholarship_count
							? props.level.scholarship_count
							: "0"}
						)
					</label>
				</div>
			</div>
		</>
	);
}

export default DegreeLevelCheckInPut;
