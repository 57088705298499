import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UniversitiesIndividualDisciplines from "../../components/card/universities_disciplines_card";
import * as actions from "../../apis/store/actions";
import $ from "jquery";
import { useHistory, useLocation } from "react-router-dom";
import UniversityIndividualBanner from "../../components/university/university_individual_banner";
import LoadingSpinner from "../../components/loader/loader";
import FormsComponent from "../../components/modals/three_forms_component";
import { getQueryString } from "../../common/getQueryString";
import { Helmet } from "react-helmet";
import ErrorPage from "../Error/404";

var settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  arrows: true,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

let institute_campus = [];
let other_campus = [];
let selected_campusess = [];
let options = [];

let newData = [];

function UniversityDetail(props) {
  const location = useLocation();
  const paramslocation = props.match?.params
  const params = Object.fromEntries(new URLSearchParams(location.search));
  const history = useHistory();
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(true);
  const [disciplineLoader, setDisciplineLoader] = useState(true);
  const disciplinesList = useSelector(
    ({ apis }) => apis.allInstitutesDisciplineLevels.data
  );
  const instituteById = useSelector(
    ({ apis }) => apis.institutesDetailByID.data
  );
  const [availableDisciplines1, setAvailableDisciplines] = useState([]);
  const [institutes_details, setInstitutes_details] = useState([]);
  const [readMoreStatus, setReadMoreStatus] = useState(false);
  const [error, setError] = useState(false);
  const [logoError, setLogoError] = useState(false); // New state for logo error

  function scrollUpToTop() {
    $("html, body").animate({ scrollTop: 0 }, "slow");
  }
  
  function getUrlParam() {
    for (const [key, value] of Object.entries(params)) {
      if (key.includes("campuses")) {
        let instance = key.split("[");
        let keyValue = parseInt(instance[1].replace("]", ""));
        let attribute = instance[2].replace("]", "");
        let index = -1;
        if (index != -1) {
          if (attribute === "id") {
            selected_campusess[index][attribute] = parseInt(value);
          } else {
            selected_campusess[index][attribute] = value;
          }
        } else {
          const myObj = {};
          if (attribute === "id") {
            myObj[attribute] = parseInt(value);
          }
          selected_campusess.push(myObj);
          newData.push({
            id: parseInt(value),
          });
        }
      }
    }
    const obj = {};
    if (selected_campusess.length) {
      selected_campusess = selected_campusess.filter(
        (v, i, a) => a.findIndex((t) => t.id === v.id) === i
      );
      obj.campuses = selected_campusess.map((item) => {
        return item.id;
      });
    }
    return obj;
  }

  useEffect(() => {
    institute_campus = [];
    other_campus = [];
    options = [];
    newData = [];
    selected_campusess = [];
    setShowLoader(true);
    dispatch(actions.getInstituteDetailById(paramslocation.slug)).then(
      (response) => {
        let actualResponse = response.payload.campus_locations;
        for (let i = 0; i < actualResponse.length; i++) {
          options.push({
            status: false,
            value: actualResponse[i].id,
            slug: actualResponse[i].slug,
            label: `${actualResponse[i].campus} (${actualResponse[i].city})`,
          });
        }
        const ids = options.map((o) => o.slug);
        options = options.filter(
          ({ slug }, index) => !ids.includes(slug, index + 1)
        );
        if (options.length) {
          setShowLoader(false);
        }

        // Validate logo
        if (!response.payload.logo) {
          setLogoError(true);
        } else {
          setLogoError(false);
        }
      }
    ).catch(err => {
      console.log('Unable to fetch Institute', err)
      setError(true)
    })
    if (paramslocation.campusSlug) {
      setDisciplineLoader(true)
      selected_campusess.push({
        slug: paramslocation.campusSlug
      })
      newData.push({ slug: paramslocation.campusSlug })
      const param = {
        campuses: [paramslocation.campusSlug]
      }
      dispatch(
        actions.getInstitutesDisciplines(
          paramslocation.slug,
          {}
        )
      ).then((res) => {
        setDisciplineLoader(false)
      });
    }
    setShowLoader(false)
  }, [props]);

  useEffect(() => {
    scrollUpToTop();
    clickSortOption()
  }, [])
  useEffect(() => {
    if (disciplinesList) {
      setAvailableDisciplines(disciplinesList);
    }
    if (instituteById) {
      setInstitutes_details(instituteById);
    }
  }, [disciplinesList, instituteById]);

  if (error) {
    return <ErrorPage />
  }

  function readMore() {
    setReadMoreStatus(!readMoreStatus);
  }
  
  const viewAllCourse = (e) => {
    if (newData.length) {
      const ids = newData.map((o) => o.slug);
      newData = newData.filter(
        ({ slug }, index) => !ids.includes(slug, index + 1)
      );
      let seacrhparam = {
        page: "discipline",
        // caid: newData.map(d => d.slug),
        institutes: [paramslocation.slug],
        country: [institutes_details?.country_slug],
      }
      history.push({
        pathname: "/discipline",
        // pathname: `/discipline/${paramslocation.slug}/${}`,
        search: getQueryString(seacrhparam)
      });
    } else {
      let seacrhparam = {
        page: "discipline",
        institutes: [paramslocation.slug],
        country: [institutes_details?.country_slug],
      }
      history.push({
        pathname: "/discipline",
        search: getQueryString(seacrhparam),
      });
    }
  };


  function clickSortOption(checked, item) {
    if (checked) {
      newData.push({
        slug: item.slug
      })
      for (let i = 0; i < options.length; i++) {
        if (options[i].slug === item.slug) {
          options[i].status = true;
        }
      }
    } else if (!checked) {
      newData = newData.filter((value) => value?.id !== item?.value)
      for (let i = 0; i < options.length; i++) {
        if (options[i].slug === item.slug) {
          options[i].status = false;
        }
      }
    }
    const ids = newData.map((o) => o.slug);
    newData = newData.filter(
      ({ slug }, index) => !ids.includes(slug, index + 1)
    );
    const obj = {}
    obj.institute = params.institute_id;
    obj.campuses = newData;
    obj.page = "discipline";
    let url = `/university-details/${paramslocation.slug}/${paramslocation.courseSlug}/${paramslocation.levelSlug}/${paramslocation.disiplineSlug}/${paramslocation.countrySlug}/${paramslocation.campusSlug}`
    // history.replace({
    //     pathname: "/university-details/",
    //     search: $.param(obj),
    // });
    let campuses = newData.map((item) => {
      return item.slug
    })
    let param = {
      campuses: campuses,
    }
    setDisciplineLoader(true)
    dispatch(
      actions.getInstitutesDisciplines(
        paramslocation.slug,
        param
      )
    ).then((res) => {
      setDisciplineLoader(false)
    });
  }
  return (
    <>
      <Helmet>
        <title>{institutes_details?.institute_meta_title}</title>
        <meta name="description" content={institutes_details?.institute_meta_description} />
      </Helmet>
      {showLoader ? (
        <LoadingSpinner />
      ) : (
        <div className="main">
          <UniversityIndividualBanner
            university={institutes_details}
            logoError={logoError}
            setLogoError={setLogoError}
          />
          <div className="disciplineInfoBanner">
            <div className="container">
              <h1>Study abroad at {institutes_details.institute_name}</h1>
              {readMoreStatus ? (
                <div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: institutes_details.institute_description,
                    }}
                  />
                  <button className="btn" onClick={readMore}>
                    Read Less
                  </button>
                </div>
              ) : (
                <div>
                  <p>{institutes_details.institute_short_description}</p>
                  <button className="btn" onClick={readMore}>
                    Read More
                  </button>
                </div>
              )}
              <FormsComponent detail={institutes_details} />
            </div>
          </div>
          <div className="container">
            <div className="mainFlex">
              <div>
                <div className="mainContTop">
                  <div className="sortFilterTop">
                    <h5>
                      Discover disciplines available at{" "}
                      <span>
                        <b>
                          {institutes_details.institute_name}{" "}
                        </b>
                      </span>
                    </h5>
                  </div>
                  {/*<div className="sortFilter">*/}
                  {/* <div id="sortFilter" className="sortFilter">
                                        <div className="campusFilter">
                                            <p>Other Campuses</p>
                                            <Select
                                                closeMenuOnSelect={false}
                                                // defaultValue={other_campus}
                                                value={other_campus}
                                                isMulti
                                                onChange={clickSortOption}
                                                options={options}
                                            />
                                            
                                        </div>
                                    </div> */}
                </div>
                <div className="uniIndFilters">
                  <h6>Campuses:</h6>
                  <div className="uniCheckArea">
                    {options?.length > 0 ? options.map((item, index) => (
                      <div key={index}>
                        <label className="customCheckbox">
                          <input type="checkbox"
                            className="form-control"
                            checked={item.state}
                            onChange={(event) => clickSortOption(event.target.checked, item)}
                            value="" /><span>{item.label}</span>
                        </label></div>
                    )) : "No campus associated with the given institute."}
                  </div>
                </div>

                {disciplineLoader ? (
                  <LoadingSpinner />
                ) : availableDisciplines1?.length > 0 ? (
                  <div>
                    <UniversitiesIndividualDisciplines
                      availableDisciplines={availableDisciplines1}
                      institute_id={instituteById?.slug}
                      campuses={newData.length ? newData : []}
                      country_slug={institutes_details?.country_slug}
                      country={institutes_details?.country}
                      institute={institutes_details?.slug}
                    />
                  </div>
                ) : (
                  <p style={{
                    textAlign: "center",
                  }}>Record Not Found</p>
                )}

              </div>

            </div>
            {availableDisciplines1.length > 0 ?
              <div
                style={{
                  textAlign: "center",
                  marginBottom: "30px"
                }}
              >
                {/* <button
                      className="btn defaultBtn"
                      onClick={(e) => viewAllCourse(e)}
                  >
                      View all Courses of {institutes_details.institute_name}

                  </button> */}
              </div>
              : null}
          </div>

          {/* {institutes_details.campus_locations ? (
                        <UniMap data={institutes_details} />
                    ) :
                        null
                    } */}
        </div>
      )}
    </>
  );
}

export default UniversityDetail;