import { http } from "../../../services/apiService/apiService";
import axios from "axios";
import {GET_ALL_INSTITUTE_GROUPS_FAIL, GET_ALL_INSTITUTE_GROUPS_SUCCESS} from "./institute_group.actions";
//Apply portals

export const GET_ALL_APPLY_PORTAL = "GET_ALL_APPLY_PORTAL";
export const GET_ALL_APPLY_PORTAL_SUCCESS = "GET_ALL_APPLY_PORTAL_SUCCESS";
export const GET_ALL_APPLY_PORTAL_FAIL = "GET_ALL_APPLY_PORTAL_FAIL";

// export function getAllApplyPortals() {
// 	const url = "apply-portal/";
// 	const request = http.get(url);
//
// 	return (dispatch) =>
// 		request
// 			.then((response) =>
// 				dispatch({
// 					type: GET_ALL_APPLY_PORTAL_SUCCESS,
// 					payload: response.data,
// 				})
// 			)
// 			.catch((error) => {
// 				return dispatch({
// 					type: GET_ALL_APPLY_PORTAL_FAIL,
// 					payload: [],
// 				});
// 			});
// }

export const getAllApplyPortals = () => async (dispatch,getState) => {
	// dispatch(handleLoadingDispatcher(LOADING_COUNTRY_RANKING, true));
	// dispatch(handleSuccessDispatcher(SUCCESS_COUNTRY_RANKING, false));
	if(!getState().apis.allApplyPortals.data.length) {
		let url = "apply-portal/";
		try {
			url = process.env.REACT_APP_BASE_URL + url
			const response = await axios.get(url);
			dispatch(
				{
					type: GET_ALL_APPLY_PORTAL_SUCCESS,
					payload: response.data.results,
				}
			);
		} catch (e) {
			return dispatch({
				type: GET_ALL_APPLY_PORTAL_FAIL,
				payload: [],
			});
		}
	}
};

export const GET_APPLY_PORTAL_DETAIL = "GET_APPLY_PORTAL_DETAIL";
export const GET_APPLY_PORTAL_DETAIL_SUCCESS =
	"GET_APPLY_PORTAL_DETAIL_SUCCESS";
export const GET_APPLY_PORTAL_DETAIL_FAIL = "GET_APPLY_PORTAL_DETAIL_FAIL";

export function getApplyPortalDetail(portalId) {
	const url = "apply-portal/" + portalId;
	const request = http.get(url);

	return (dispatch) =>
		request
			.then((response) =>
				dispatch({
					type: GET_APPLY_PORTAL_DETAIL_SUCCESS,
					payload: response.data,
				})
			)
			.catch((error) => {
				return dispatch({
					type: GET_APPLY_PORTAL_DETAIL_FAIL,
					payload: [],
				});
			});
}

export const GET_APPLY_PORTAL_ALL_UNIVERSITIES =
	"GET_APPLY_PORTAL_ALL_UNIVERSITIES";
export const GET_APPLY_PORTAL_ALL_UNIVERSITIES_SUCCESS =
	"GET_APPLY_PORTAL_ALL_UNIVERSITIES_SUCCESS";
export const GET_APPLY_PORTAL_ALL_UNIVERSITIES_FAIL =
	"GET_APPLY_PORTAL_ALL_UNIVERSITIES_FAIL";

export function getApplyPortalUniversities(portalId, params, pageId) {
	let url = "apply-portal/" + portalId + "/universities/";
	if (pageId) {
		url = url + "?page=" + pageId;
	}
	const request = http.post(url, params);

	return (dispatch) =>
		request
			.then((response) =>
				dispatch({
					type: GET_APPLY_PORTAL_ALL_UNIVERSITIES_SUCCESS,
					payload: response.data,
				})
			)
			.catch((error) => {
				return dispatch({
					type: GET_APPLY_PORTAL_ALL_UNIVERSITIES_FAIL,
					payload: [],
				});
			});
}

export const GET_LOCATIONS_OF_APPLY_PORTAL = "GET_LOCATIONS_OF_APPLY_PORTAL";
export const GET_LOCATIONS_OF_APPLY_PORTAL_SUCCESS =
	"GET_LOCATIONS_OF_APPLY_PORTAL_SUCCESS";
export const GET_LOCATIONS_OF_APPLY_PORTAL_FAIL =
	"GET_LOCATIONS_OF_APPLY_PORTAL_FAIL";

export function getApplyPortalRelatedLocations(groupId) {
	let url = "apply-portal/" + groupId + "/related_locations";
	const request = http.get(url);

	return (dispatch) =>
		request
			.then((response) =>
				dispatch({
					type: GET_LOCATIONS_OF_APPLY_PORTAL_SUCCESS,
					payload: response.data,
				})
			)
			.catch((error) => {
				return dispatch({
					type: GET_LOCATIONS_OF_APPLY_PORTAL_FAIL,
					payload: [],
				});
			});
}
