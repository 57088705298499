import { Close } from "@material-ui/icons";
import { Box, Button, Modal, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom-v5-compat";
import image from "../../static/images/illustrations/laptop snippet.png";
import modalAnimation from "../../static/images/illustrations/lock.GIF.gif";
import linePattern from "../../static/images/illustrations/LinesPattern.png";
import arrowDoodle from "../../static/images/illustrations/DoodleArrow-min.png";
import { useTheme } from "@mui/material/styles";

const LoginModal = ({ openModal, closeModal }) => {
  const outerTheme = useTheme();
  const isMobile = useMediaQuery(outerTheme.breakpoints.down("sm"));
  const navigate = useNavigate();

  const style = {
    modal: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: isMobile ? "90%" : "60%",
      height: isMobile ? "60%" : "77%",
      bgcolor: "background.paper",
      boxShadow: 24,
      borderRadius: "15px",
      backgroundImage: `url(${linePattern})`,
      backgroundSize: "70% 80%", // Adjust the size to cover, contain, or specify a percentage
      backgroundPosition: isMobile ? "center" : "calc(100% - 1px) bottom", // Position the background image (e.g., top right, center, bottom left)
      backgroundRepeat: "no-repeat",
    },
  };

  return (
    <Modal open={openModal} onClose={closeModal} aria-labelledby="login">
      <Box className="tcf-v2" sx={style.modal}>
        <div className="text-right p-2">
          <Close className="cursor-pointer tcf-icon" onClick={closeModal} />
        </div>
        <div
          style={{
            position: "relative",
            display: isMobile ? "flex" : "grid",
            gridTemplateColumns: "1fr 1fr",
            gridTemplateAreas: `'content image'`,
            gap: isMobile ? "0px" : "20px",
            justifyItems: "center",
            flexDirection: "column-reverse",
            alignItems: "center",
            padding: isMobile ? "0px 15px" : "0px 30px",
          }}
        >
          <img
            src={arrowDoodle}
            style={{
              position: "absolute",
              top: isMobile ? "30%" : "25%",
              right: isMobile ? "65%" : "65%",
              rotate: isMobile ? "85deg" : "0deg",
              width: isMobile ? "20%" : "10%", // Add this line to control the size
              transform: `translate(${isMobile ? "0%" : "50%"}, ${
                isMobile ? "0%" : "-50%"
              })`,
            }}
          />
          <div
            className="text-center"
            style={{
              gridArea: isMobile ? "content" : "content",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: isMobile ? "2px" : "5px",
              alignItems: isMobile ? "center" : "start",
              flexBasis:"50%"
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: isMobile ? "column-reverse" : "column",
                justifyContent: "flex-start",
                alignItems: isMobile ? "center" : "flex-start",
              }}
            >
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: isMobile ? "12px" : "20px",
                  color: "black",
                  textAlign: isMobile ? "center" : "start",
                  whiteSpace: "wrap",
                  width: "290px",
                  lineHeight: "25px",
                }}
              >
                Discover your perfect course from 300k options with our{" "}
                {!isMobile && <br />}
                <span
                  style={{ color: "rgb(85, 81, 255)", fontWeight: "bolder" }}
                >
                  Advanced Filters!
                </span>
              </p>
              <img
                src={modalAnimation}
                alt="modal animation"
                style={{
                  maxWidth: isMobile ? "20%" : "70%",
                  minHeight: "auto",
                }}
              />
            </div>
           
              <div
                style={{
                  display: "flex",
                  flexDirection: isMobile ? "row-reverse" : "row",
                  justifyContent: isMobile ? "center" : "flex-start",
                  width: isMobile ? "50%" : "100%",
                  gap: isMobile && "15px",
                }}
              >
                <Button
                  sx={{
                    textTransform: "none",
                    backgroundColor: "rgb(85, 81, 255)",
                    borderRadius: "25px",
                  }}
                  variant="contained"
                  className={`signUP ${isMobile ? "mobile" : ""}`}
                  onClick={() => navigate("/registration")}
                  style={{
                    marginRight: isMobile ? "0" : "10px",
                  }}
                >
                  Create account
                </Button>
                <Button
                  sx={{ textTransform: "none" }}
                  variant="text"
                  className={`login ${isMobile ? "mobile" : ""}`}
                  onClick={() => navigate("/login")}
                >
                  Login
                </Button>
              </div>
            
          </div>
          <div
            style={{
              gridArea: isMobile ? "image" : "image",
              display: "flex",
              flexDirection: "column",
              justifyContent: isMobile ? "start" : "center",
              alignItems: "center", // Use center to align items horizontally
              width: isMobile ? "60%" : "100%",
              flexBasis: "50%"
            }}
          >
            <img
              src={image}
              alt=""
              style={{
                width:isMobile? "80%": "100%",
                // height: "auto",
                // maxHeight: isMobile ? "90%" : "100%",
              }}
            />
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default LoginModal;
