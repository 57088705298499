import React from "react";
import Provider from "react-redux/es/components/Provider";
import ReactDOM from "react-dom";
import Favicon from "react-favicon";

// import './styles/style.css';
import App from "./App";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from "./reportWebVitals";
import {persistor, store} from "./app/store";
import { PersistGate } from 'redux-persist/integration/react';
import LoadingSpinner from "./app/components/loader/loader";
// import { PersistGate } from "redux-persist/lib/integration/react";

if ('serviceWorker' in navigator) {
	window.addEventListener('load', () => {
		navigator.serviceWorker.register('/service-worker.js').then(registration => {
		}).catch(registrationError => {
		});
	});
}

ReactDOM.render(
	<React.StrictMode>
		<Favicon url="https://times-con.s3.us-east-2.amazonaws.com/institutes_logos/application_portal_logo/TCF-ICON.png" />
		<Provider store={store} context={null}>
			<PersistGate loading={null} persistor={persistor}>
				<App />
			</PersistGate>
		</Provider>
	</React.StrictMode>,
	document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
