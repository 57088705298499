import * as Actions from "../actions/scholarship.actions";

const countriesScholarshipsCountInitialState = {
  countriesScholarshipCountLoading: false,
  countriesScholarshipCountData: [],
  countriesScholarshipCountError: "",
  countriesScholarshipCountSuccess: false,
};

export const countriesScholarshipsCount = (
  state = countriesScholarshipsCountInitialState,
  { type, payload }
) => {
  switch (type) {
    case Actions.GET_ALL_COUNTRY_SCHOLARSHIPS_COUNT:
      return {
        ...state,
        countriesScholarshipCountLoading: payload.loading,
        countriesScholarshipCountData: payload.data,
        countriesScholarshipCountError: payload.error,
        countriesScholarshipCountSuccess: payload.success,
      };
    case Actions.GET_ALL_COUNTRY_SCHOLARSHIPS_COUNT_LOADING:
      return {
        ...state,
        countriesScholarshipCountLoading: payload.loading,
      };
    case Actions.GET_ALL_COUNTRY_SCHOLARSHIPS_COUNT_SUCCESS:
      return {
        ...state,
        countriesScholarshipCountSuccess: payload.success,
      };
    case Actions.GET_ALL_COUNTRY_SCHOLARSHIPS_COUNT_FAIL:
      return {
        ...state,
        countriesScholarshipCountError: payload.error,
      };
    default:
      return state;
  }
};

const countryScholarshipsInitialState = {
  countryScholarshipLoading: false,
  countryScholarshipData: [],
  countryScholarshipError: "",
  countryScholarshipSuccess: false,
};

export const countryScholarships = (
  state = countryScholarshipsInitialState,
  { type, payload }
) => {
  switch (type) {
    case Actions.GET_COUNTRY_SCHOLARSHIPS:
      return {
        ...state,
        countryScholarshipLoading: payload.loading,
        countryScholarshipSuccess: payload.success,
        countryScholarshipError: payload.error,
        countryScholarshipData: payload.data,
      };
    case Actions.GET_COUNTRY_SCHOLARSHIPS_LOADING:
      return {
        ...state,
        countryScholarshipLoading: payload.loading,
      };
    case Actions.GET_COUNTRY_SCHOLARSHIPS_SUCCESS:
      return {
        ...state,
        countryScholarshipSuccess: payload.success,
      };
    case Actions.GET_COUNTRY_SCHOLARSHIPS_FAIL:
      return {
        ...state,
        countryScholarshipError: payload.error,
      };
    default:
      return state;
  }
};
const instituteScholarshipsInitialState = {
  instituteScholarshipLoading: false,
  instituteScholarshipData: [],
  instituteScholarshipError: "",
  instituteScholarshipSuccess: false,
};

export const instituteScholarships = (
  state = instituteScholarshipsInitialState,
  { type, payload }
) => {
  switch (type) {
    case Actions.GET_INSTITUTE_SCHOLARSHIPS:
      return {
        ...state,
        instituteScholarshipData: payload.data,
        instituteScholarshipLoading: payload.loading,
        instituteScholarshipSuccess: payload.success,
        instituteScholarshipError: payload.error,
      };
    case Actions.GET_INSTITUTE_SCHOLARSHIPS_LOADING:
      return {
        ...state,
        instituteScholarshipLoading: payload.loading,
      };
    case Actions.GET_INSTITUTE_SCHOLARSHIPS_SUCCESS:
      return {
        ...state,
        instituteScholarshipSuccess: payload.success,
      };
    case Actions.GET_INSTITUTE_SCHOLARSHIPS_FAIL:
      return {
        ...state,
        instituteScholarshipError: payload.error,
      };
    default:
      return state;
  }
};

const scholarshipDetailsInitialState = {
  scholarshipDetailLoading: false,
  scholarshipDetailData: [],
  scholarshipDetailSuccess: false,
  scholarshipDetailError: "",
};

export const scholarshipDetails = (
  state = scholarshipDetailsInitialState,
  { type, payload }
) => {
  switch (type) {
    case Actions.GET_SCHOLARSHIP_DETAIL:
      return {
        ...state,
        scholarshipDetailLoading: payload.loading,
        scholarshipDetailData: payload.data,
        scholarshipDetailError: payload.error,
        scholarshipDetailSuccess: payload.success,
      };
    case Actions.GET_SCHOLARSHIP_DETAIL_LOADING:
      return {
        ...state,
        scholarshipDetailLoading: payload.loading,
      };
    case Actions.GET_SCHOLARSHIP_DETAIL_SUCCESS:
      return {
        ...state,
        scholarshipDetailSuccess: payload.success,
      };
    case Actions.GET_SCHOLARSHIP_DETAIL_FAIL:
      return {
        ...state,
        scholarshipDetailError: payload.error,
      };
    default:
      return state;
  }
};

const searchScholarshipInitialState = {
  searchScholarshipLoading: false,
  searchScholarshipData: [],
  searchScholarshipSuccess: false,
  searchScholarshipError: "",
};

export const searchScholarships = (
  state = searchScholarshipInitialState,
  { type, payload }
) => {
  switch (type) {
    case Actions.GET_SEARCH_SCHOLARSHIPS:
      return {
        ...state,
        searchScholarshipLoading: payload.loading,
        searchScholarshipData: payload.data,
        searchScholarshipSuccess: payload.success,
        searchScholarshipError: payload.error,
      };
    case Actions.SEARCH_SCHOLARSHIPS_LOADING:
      return {
        ...state,
        searchScholarshipLoading: payload.loading,
      };
    case Actions.SEARCH_SCHOLARSHIPS_SUCCESS:
      return {
        ...state,
        searchScholarshipSuccess: payload.success,
      };
    case Actions.SEARCH_SCHOLARSHIPS_FAIL:
      return {
        ...state,
        searchScholarshipError: payload.error,
      };
    default:
      return state;
  }
};

const scholarshipRelatedInstitutesInitialState = {
  institutesLoading: false,
  institutesData: [],
  institutesError: "",
  institutesSuccess: false,
};

export const scholarshipRelatedInstitutes = (
  state = scholarshipRelatedInstitutesInitialState,
  { type, payload }
) => {
  switch (type) {
    case Actions.SCHOLARSHIP_RELATED_INSTITUTES:
      return {
        ...state,
        institutesLoading: payload.loading,
        institutesData: payload.data,
        institutesError: payload.error,
        institutesSuccess: payload.success,
      };
    case Actions.SCHOLARSHIP_RELATED_INSTITUTES_LOADING:
      return {
        ...state,
        institutesLoading: payload.loading,
      };
    case Actions.SCHOLARSHIP_RELATED_INSTITUTES_SUCCESS:
      return {
        ...state,
        institutesSuccess: payload.success,
      };
    case Actions.SCHOLARSHIP_RELATED_INSTITUTES_FAIL:
      return {
        ...state,
        institutesError: payload.error,
      };
    default:
      return state;
  }
};

const scholarshipRelatedDisciplinesInitialState = {
  disciplineLoading: false,
  disciplineData: [],
  disciplineError: "",
  disciplineSuccess: false,
};

export const scholarshipRelatedDisciplines = (
  state = scholarshipRelatedDisciplinesInitialState,
  { type, payload }
) => {
  switch (type) {
    case Actions.GET_SCHOLARSHIP_RELATED_DISCIPLINES:
      return {
        ...state,
        disciplineLoading: payload.loading,
        disciplineData: payload.data,
        disciplineError: payload.error,
        disciplineSuccess: payload.success,
      };
    case Actions.SCHOLARSHIP_RELATED_DISCIPLINES_LOADING:
      return {
        ...state,
        disciplineLoading: payload.loading,
      };
    case Actions.SCHOLARSHIP_RELATED_DISCIPLINES_SUCCESS:
      return {
        ...state,
        disciplineSuccess: payload.success,
      };
    case Actions.SCHOLARSHIP_RELATED_DISCIPLINES_FAIL:
      return {
        ...state,
        disciplineError: payload.error,
      };
    default:
      return state;
  }
};

const scholarshipRelatedDegreeLevelsInitialState = {
  degreeLevelLoading: false,
  degreeLevelData: [],
  degreeLevelError: "",
  degreeLevelSuccess: false,
};

export const scholarshipRelatedDegreeLevels = (
  state = scholarshipRelatedDegreeLevelsInitialState,
  { type, payload }
) => {
  switch (type) {
    case Actions.GET_SCHOLARSHIP_RELATED_DEGREE_LEVELS:
      return {
        ...state,
        degreeLevelLoading: payload.loading,
        degreeLevelData: payload.data,
        degreeLevelError: payload.error,
        degreeLevelSuccess: payload.success,
      };
    case Actions.LOADING_SCHOLARSHIP_RELATED_DEGREE_LEVELS:
      return {
        ...state,
        degreeLevelLoading: payload.loading,
      };
    case Actions.SCHOLARSHIP_RELATED_DEGREE_LEVELS_SUCCESS:
      return {
        ...state,
        degreeLevelSuccess: payload.success,
      };
    case Actions.SCHOLARSHIP_RELATED_DEGREE_LEVELS_ERROR:
      return {
        ...state,
        degreeLevelError: payload.error,
      };
    default:
      return state;
  }
};

const scholarshipRelatedTypesInitialState = {
  typesLoading: false,
  typesData: [],
  typesSuccess: false,
  typesError: "",
};

export const scholarshipRelatedTypes = (
  state = scholarshipRelatedTypesInitialState,
  { type, payload }
) => {
  switch (type) {
    case Actions.GET_SCHOLARSHIP_RELATED_TYPES:
      return {
        ...state,
        typesLoading: payload.loading,
        typesData: payload.data,
        typesSuccess: payload.success,
        typesError: payload.error,
      };
    case Actions.SCHOLARSHIP_RELATED_TYPES_LOADING:
      return {
        ...state,
        typesLoading: payload.loading,
      };
    case Actions.SCHOLARSHIP_RELATED_TYPES_SUCCESS:
      return {
        ...state,
        typesSuccess: payload.success,
      };
    case Actions.SCHOLARSHIP_RELATED_TYPES_FAIL:
      return {
        ...state,
        typesError: payload.error,
      };
    default:
      return state;
  }
};

const scholarshipRelatedMonthsInitialState = {
  monthsLoading: false,
  monthsSuccess: false,
  monthsData: [],
  monthsError: "",
};

export const scholarshipRelatedMonths = (
  state = scholarshipRelatedMonthsInitialState,
  { type, payload }
) => {
  switch (type) {
    case Actions.SCHOLARSHIP_RELATED_MONTHS:
      return {
        ...state,
        monthsLoading: payload.loading,
        monthsSuccess: payload.success,
        monthsData: payload.data,
        monthsError: payload.error,
      };
    case Actions.SCHOLARSHIP_RELATED_MONTHS_LOADING:
      return {
        ...state,
        monthsLoading: payload.loading,
      };
    case Actions.SCHOLARSHIP_RELATED_MONTHS_SUCCESS:
      return {
        ...state,
        monthsSuccess: payload.success,
      };
    case Actions.SCHOLARSHIP_RELATED_MONTHS_FAIL:
      return {
        ...state,
        monthsError: payload.error,
      };
    default:
      return state;
  }
};

const scholarshipRelatedYearsInitialState = {
  yearsLoading: false,
  yearsData: [],
  yearsSuccess: false,
  yearsError: "",
};

export const scholarshipRelatedYears = (
  state = scholarshipRelatedYearsInitialState,
  { type, payload }
) => {
  switch (type) {
    case Actions.SCHOLARSHIP_RELATED_YEARS:
      return {
        ...state,
        yearsData: payload.data,
        yearsSuccess: payload.success,
        yearsError: payload.error,
        yearsLoading: payload.loading,
      };
    case Actions.SCHOLARSHIP_RELATED_YEARS_LOADING:
      return {
        ...state,
        yearsLoading: payload.loading,
      };
    case Actions.SCHOLARSHIP_RELATED_YEARS_SUCCESS:
      return {
        ...state,
        yearsSuccess: payload.success,
      };
    case Actions.SCHOLARSHIP_RELATED_YEARS_FAIL:
      return {
        ...state,
        yearsError: payload.error,
      };
    default:
      return state;
  }
};

const scholarshipRelatedCountriesInitialState = {
  data: [],
};

export const scholarshipRelatedCountries = (
  state = scholarshipRelatedCountriesInitialState,
  action
) => {
  switch (action.type) {
    case Actions.SCHOLARSHIP_RELATED_COUNTRIES_SUCCESS: {
      return {
        ...state,
        data: action.payload,
      };
    }
    case Actions.SCHOLARSHIP_RELATED_COUNTRIES_FAIL: {
      return {
        ...state,
        data: [],
      };
    }
    default: {
      return state;
    }
  }
};
