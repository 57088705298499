import React, { memo } from 'react';
import { useHistory } from "react-router-dom";
import { getSingleRoute } from '../../../../common/utils';
import { slugSet } from '../../../common/getQueryString';

const GridMarketingCard = ({ detail }) => {
    const history = useHistory()
    const getRoute = (page, id) => {
        getSingleRoute(page).then(d => {
            history.push({
                pathname: d + '/' + id.id,
                search: id?.search ? id?.search : '',
            });
        })
    }
    return (
        <div className="courseBox adBox">
            <div className="imgWrapADD">
                <img alt={detail.heading}
                    className="cimg1"
                    src={detail.grid_card_img_front}
                />
            </div>
            <button
                onClick={() => getRoute('university_details', {
                    id: `${slugSet(detail.institute_slug) + slugSet(detail.institute_campus_slug[0])}`
                })}
                // onClick={()=>getRoute('marketing_detail',{
                //     search: $.param({
                //         id: detail.id,
                //         name: detail.heading,
                //     })
                // })}
                // to={{
                //     pathname: "/marketing-detail",
                //     search: $.param({
                //         id: detail.id,
                //         name: detail.heading,
                //     }),
                // }} 
                className="adBoxOverlay">
                <div className="imgWrap">
                    <img
                        alt={detail.heading}
                        src={detail.grid_card_img_back}
                    />
                </div>
                <div className="overlayCont">
                    <div className="btnGroup" style={{
                        textAlign: "center"
                    }}>
                        <button
                            onClick={() => getRoute('university_details', {
                                id: `${slugSet(detail.institute_slug) + slugSet(detail.institute_campus_slug[0])}`
                            })}
                            // onClick={()=>getRoute('marketing_detail',{
                            //     search: $.param({
                            //         id: detail.id,
                            //         name: detail.heading,
                            //     })
                            // })}
                            // to={{
                            //     pathname: "/marketing-detail",
                            //     search: $.param({
                            //         id: detail.id,
                            //         name: detail.heading,
                            //     }),
                            // }}
                            className="btn"
                            style={{
                                background: "#f9b316",
                                color: "#fff",
                                width: "49%",
                                fontWeight: "500",
                                fontSize: "12px",
                                padding: "5px 10px"
                            }}
                        >Discover</button>
                    </div>
                </div>
            </button>
        </div>
    );
};

export default memo(GridMarketingCard);
