import React, { useEffect, useState } from "react";
import StateFilter from "./state_filter";
import { http } from "../../../services/apiService/apiService";

function CountryFilter(props) {
    const { country, selectedLocations, filteredCountriesValue, getLocationCount, selectedCountry, setselectedCountry } = props;
    const [countryCheck, setCountryCheck] = useState(false);
    const [showStates, setShowStates] = useState(false);

    const [countryWithStates, setCountryWithStates] = useState([])
    const [activeClass, setActiveClass] = useState(false);
    const [filteredStateValue, setfilteredStateValue] = useState('')
    useEffect(() => {
        if (selectedLocations.countries.length >= 1) {
            if (selectedLocations.countries.some((e) => e?.slug === country?.slug)) {
                setCountryCheck(true);
            } else {
                setCountryCheck(false);
            }
        } else {
            setCountryCheck(false);
        }
        if (filteredCountriesValue?.length) {
            setfilteredStateValue(filteredCountriesValue)
            countryArrowDownClick(props.country?.name, props.index)
        }
    }, [props.rand]);
    useEffect(()=>{
        if(filteredCountriesValue?.length > 0){
            countryArrowToggleClick(props.country?.name, props.index);
            countryArrowDownClick(props.country?.name, props.index);
        }
    },[filteredCountriesValue?.length])

    useEffect(()=>{
        
    })

    useEffect(() => {

        if (selectedLocations?.countries.some(c => c?.slug === country?.slug) ||
            selectedLocations.states.some(
                (s) =>
                    country?.states.some(st =>
                        s?.slug === st?.slug
                    )) ||
            selectedLocations.cities.some(
                (c) =>
                    country?.states.some(ct =>
                        ct?.cities.some(f =>
                            c?.slug === f?.slug
                        )
                    )
            )) {
            setActiveClass(true)
        }
        else {
            setActiveClass(false);
        }

    }, [props.rand]);
    const onChangeCountryCheck = (country, type, check) => {
        //     if (

        //     }
        //     else {
        // }
        setCountryCheck(!check);
        if (!check) {
            props.callParentLoadFilter(country, type, true);
        } else {
            props.callParentLoadFilter(country, type, false);
        }
    };
    const countryArrowToggleClick = (coutryName, index) => {
        var subElement = document.getElementById(coutryName + String(index));
        var className = subElement.className;
        if (className.search("show") === -1) {
            var element = document.getElementById(coutryName);
            element.className += " filterOpen";
            var subElement = document.getElementById(coutryName + String(index));
            subElement.className += " show";
        } else {
            var element = document.getElementById(coutryName);
            element.className = " filterItem";
            var subElement = document.getElementById(coutryName + String(index));
            subElement.className = " filterItemCont";
        }
    };
    const countryArrowDownClick = (coutryName, index) => {
        var element = document.getElementById(coutryName);
        element.className += " filterOpen";
        var subElement = document.getElementById(coutryName + String(index));
        subElement.className += " show";
    };
    const countryArrowUpClick = (coutryName, index) => {
        var element = document.getElementById(coutryName);
        element.className = " filterItem";
        var subElement = document.getElementById(coutryName + String(index));
        subElement.className = " filterItemCont";
    };
    const onStateUpdate = (obj, type, status) => {
        props.callParentLoadFilter(obj, type, status);
    };

    const getStates = async (ctry) => {
        setCountryCheck(true)
        setselectedCountry(...selectedCountry, ctry)
        selectedLocations.countries.push(ctry.slug)
        try{
            const request = await http.post(`course/country_states/?country_id=${country.id}`);
            country.states = Object.values(request.data);
            setCountryWithStates(Object.values(request.data))
            setShowStates(true)
        }catch{
            setShowStates(false)
            console.log('Error while geting states.')
        }
    }
    let type = props.country?.country_id && props.country?.state_id ? 'city' : props.country?.country_id && !props.country?.state_id ? 'state' : 'country'
    return (
        <>
            <div className="filterItemWrap">
                <div id={props.country?.name} className="filterItem">
                    <input className={activeClass === true ? "childChecked" : ""}
                        key={props.index + Math.floor(Math.random() * 1000)}
                        type="checkbox"
                        id={'country' + props.index}
                        onChange={() => {
                            onChangeCountryCheck(props.country, type, countryCheck);
                        }}

                        checked={countryCheck}
                    />
                    <label
                        htmlFor={"currancy" + props.index}
                        className={activeClass === true ? "active" : ""}
                        onClick={(e) => {
                            countryArrowToggleClick(props.country?.id, props.index);
                        }}
                    >
                        {country?.name}
                        {/*(*/}
                        {/*{country?.course_count*/}
                        {/*  ? country?.course_count*/}
                        {/*  : props.country?.institute_count*/}
                        {/*  ? props.country?.institute_count*/}
                        {/*  : "0"}*/}
                        {/*)*/}
                    </label>
                    <i
                        className="fa fa-angle-right"
                        onClick={(e) => {
                            // console.log('clicked ', props.country.id, props.country.name, props.country)
                            getStates(props.country);
                            countryArrowDownClick(props.country?.name, props.index); getLocationCount(props.country)
                        }}
                    />
                    <i
                        className="fa fa-angle-down"
                        onClick={(e) => {
                            countryArrowUpClick(props.country?.name, props.index);
                        }}
                    />
                </div>
                <div
                    id={props.country?.name + String(props.index)}
                    className="filterItemCont "
                >
                    {showStates
                        &&  
                        // <p>{countryWithStates.map((state,index)=> state.name)}</p>
                        country.states.map((state, index) => {
                            return <StateFilter
                                key={index}
                                state={state}
                                filteredStateValue={filteredStateValue}
                                index={index}
                                status={true}
                                selectedLocations={selectedLocations}
                                callStateUpdate={onStateUpdate}
                                getLocationCount={getLocationCount}
                            />
                        })
                        // : props.country?.states?.map((state, index) => (
                        //     <StateFilter
                        //         key={index}
                        //         filteredStateValue={filteredStateValue}
                        //         state={state}
                        //         index={index}
                        //         status={false}
                        //         selectedLocations={selectedLocations}
                        //         callStateUpdate={onStateUpdate}
                        //         getLocationCount={getLocationCount}
                        //     />
                        // ))
                        }
                </div>
            </div>
        </>
    );
}

export default CountryFilter;