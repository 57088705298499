import React, { memo, useEffect, useState } from "react";
import { httpCrm } from "../../../services/apiService/apiService";
import { toast } from "react-toastify";
import $ from "jquery";
import { Link, useHistory } from "react-router-dom";
import GridMarketingCard from "./GridViewMarketingCard";
import { getSingleRoute } from "../../../../common/utils";

const GridViewDataRendering = (item) => {
  const [isFavorite, setIsFavorite] = useState(false);
  const history = useHistory();
  let variable = item.data;
  let favCourses = item.oldFav;
  useEffect(() => {
    if (favCourses) {
      // eslint-disable-next-line array-callback-return
      favCourses.map((subItem) => {
        if (subItem.id === item.item.id) {
          setIsFavorite(true);
        }
      });
    }
  }, [favCourses, item.item.id]);

  function capitalFirstLetter(word) {
    return word.charAt(0).toUpperCase() + word.substring(1);
  }

  // const onClickApplyForm () => {

  // }

  const heartClick = (item) => {
    if (localStorage.getItem("user_Id")) {
      setIsFavorite(!isFavorite);
      if (isFavorite === false) {
        httpCrm
          .post(
            `favCourse.php?user_id=${localStorage.getItem(
              "user_Id"
            )}&course_id=${item.id}&course_name=${item.name}&course-title=${
              item.course_title
            }&university_name=${item.institute.institute_name}&country=${
              item.country
            }&specialization=${item.specialization}&fee_per_year=${
              item.base_fee
            }&fee_per_year=${item?.course_fee[0]?.ceil_value}&fav_check=1`
          )
          .then((res) => {
            if (res.data.message === "Course already Marked as Fav") {
              toast.success("Course already Marked as Favorite");
            } else {
              toast.success("Course Marked as Favorite");

              variable.push({ id: item.id });
              window.onbeforeunload = function () {
                if (localStorage.getItem("fav_course")) {
                  let favCoursesHolder = JSON.parse(
                    localStorage.getItem("fav_course")
                  );
                  const allFavCourses = favCoursesHolder.concat(variable);
                  localStorage.setItem(
                    "fav_course",
                    JSON.stringify(allFavCourses)
                  );
                } else {
                  localStorage.setItem("fav_course", JSON.stringify(variable));
                }
              };
            }
          });
      } else if (isFavorite === true) {
        httpCrm
          .post(
            `favCourse.php?user_id=${localStorage.getItem(
              "user_Id"
            )}&course_id=${item.id}&course_name=${item.name}&course-title=${
              item.course_title
            }&university_name=${item.institute.institute_name}&country=${
              item.country
            }&specialization=${item.specialization}&fee_per_year=${
              item.base_fee
            }&course_fee=${
              item?.course_fee[0]?.ceil_value +
              " " +
              item?.course_fee[0]?.currency.display_name
            }&fav_check=0`
          )
          .then((res) => {
            if (res.data.message === "Marketd As un Fav") {
              toast.success("Course remove from Favorite");
            }
          });
      }
    } else {
      $("#login").modal("show");
    }
  };
  const getRoute = (page, id) => {
    getSingleRoute(page).then((d) => {
      history.push({
        pathname: d + `${id?.id ? "/" + id?.id : ""}`,
        search: id?.search ? id?.search : "",
      });
    });
  };

  // const applyForm = (page, id) =>{
  //     getSingleRoute(page).then(d => {
  //         history.push({
  //             pathname:d,
  //             search: id.search
  //         });
  //     })
  // }
  // const openWindow = (item) => {
  //     window.open(CourseApplyForm)
  // }

  return (
    <>
      {item.item.name ? (
        <div className="courseBox">
          <div className="imgWrap">
            <img
              src="https://assets.timescoursefinder.com/Media/course-img.svg"
              alt="course-img"
            />
            <div className="courseBoxTop">
              <button
                onClick={() =>
                  getRoute("university_details", {
                    id:
                      item.item.institute_slug +
                      `/${item.item.campus.slug ? item.item.campus.slug : ""}`,
                    // id:item.item.institute_slug+`/${item.item.slug}`+`/${item.item.degree_level_slug}`+`/${item.item.discipline_slug}`+`/${item.item.country_slug}`+`/${item.item.campus.slug}`
                    // search: $.param({
                    //     institute_id: item.item.institute.id,
                    //     institute_name: item.item.institute.institute_name,
                    //     campus_id: item.item.campus.id,
                    //     campus_name: item.item.campus.name,
                    // })
                  })
                }
                // to={{
                //     pathname: "/university-details/",
                //     search: $.param({
                //         institute_id: item.item.institute.id,
                //         institute_name: item.item.institute.institute_name,
                //         campus_id: item.item.campus.id,
                //         campus_name: item.item.campus.name,
                //     }),
                // }}
              >
                <img
                  src={item.item.institute?.logo}
                  width="100px"
                  alt={item.item.institute?.institute_name}
                />
              </button>
              <div>
                <button
                  onClick={() =>
                    getRoute("university_details", {
                      id:
                        item.item.institute_slug +
                        `/${
                          item.item.campus.slug ? item.item.campus.slug : ""
                        }`,
                      // id:item.item.institute_slug+`/${item.item.slug}`+`/${item.item.degree_level_slug}`+`/${item.item.discipline_slug}`+`/${item.item.country_slug}`+`/${item.item.campus.slug}`
                      // search: $.param({
                      //     institute_id: item.item.institute.id,
                      //     institute_name: item.item.institute.institute_name,
                      //     campus_id: item.item.campus.id,
                      //     campus_name: item.item.campus.name,
                      // })
                    })
                  }
                  // to={{
                  //     pathname: "/university-details/",
                  //     search: $.param({
                  //         institute_id: item.item.institute.id,
                  //         institute_name: item.item.institute.institute_name,
                  //         campus_id: item.item.campus.id,
                  //         campus_name: item.item.campus.name,
                  //     }),
                  // }}
                >
                  <h6>{item.item.institute?.institute_name}</h6>{" "}
                </button>
                <p>{item?.item?.campus?.name}</p>
              </div>
            </div>
            <div className="btnGroup">
              <button
                onClick={() => item.handleModalOpen(item.item)}
                className="btn"
              >
                <img
                  src={
                    require("../../../../static/images/share-purple.svg")
                      .default
                  }
                  alt="share-purple"
                />
              </button>
              <button className="btn" onClick={() => heartClick(item.item)}>
                {isFavorite ? (
                  <img
                    key={item.item.id}
                    src={
                      require("../../../../static/images/yellow_heart.svg")
                        .default
                    }
                    alt="yellow_heart"
                  />
                ) : (
                  <img
                    key={item.item.id}
                    src={
                      require("../../../../static/images/fav-purple.svg")
                        .default
                    }
                    alt="fav-purple"
                  />
                )}
              </button>
            </div>
          </div>
          <div className="courseBoxCont">
            <h6>
              <b>{item.item?.name}</b>
            </h6>
            <p>
              <img
                title="Specialization"
                src={
                  require("../../../../static/images/Specialization1.svg")
                    .default
                }
                alt="Specialization1"
              />
              {/* {item.item?.discipline} */}
              {item.item.specialization}
              <a
                href="#editModal"
                data-toggle="modal"
                title="Suggest an Edit"
                data-target="#editModal"
                onClick={() => {
                  localStorage.setItem("EditModal", "true");
                }}
              >
                <img
                  src={
                    require("../../../../static/images/special-edit.png")
                      .default
                  }
                  alt="special-edit"
                />
              </a>
            </p>

            <div>
              <div>
                <p>
                  <img
                    src={
                      require("../../../../static/images/bachelor_honors.svg")
                        .default
                    }
                    alt="bachelor_honors"
                  />
                  {item.item?.degree_level} | {item.item?.course_title}{" "}
                </p>
                <p>
                  <img
                    src={
                      require("../../../../static/images/on_campus.svg").default
                    }
                    alt="on_campus"
                  />
                  On Campus{" "}
                </p>
                <p>
                  <img
                    src={
                      require("../../../../static/images/Amount.svg").default
                    }
                    alt="Amount"
                  />
                  {item.item?.course_fee?.length > 0
                    ? // {item.item?.course_fee?.length>0 && item.item.base_fee !== null
                      (item.item.course_fee[0]?.converted_value
                        ? item.commaSeparators(
                            item.item.course_fee[0]?.converted_value.toFixed(0)
                          ) +
                          " " +
                          item.item.course_fee[0]?.currency?.converted_currency
                        : item.commaSeparators(
                            item.item.course_fee[0]?.ceil_value
                          ) +
                          " " +
                          item.item.course_fee[0]?.currency?.display_name) +
                      "/Year"
                    : "To Be Confirmed"}
                </p>
              </div>
              <div>
                <p>
                  <img
                    src={require("../../../../static/images/Years.svg").default}
                    alt="Years"
                  />

                  {item.item?.course_duration}
                </p>
                <p>
                  <img
                    src={
                      require("../../../../static/images/English.svg").default
                    }
                    alt="English"
                  />
                  {capitalFirstLetter(item.item?.course_language)}
                </p>
                <p>
                  <img
                    src={
                      require("../../../../static/images/full_time.svg").default
                    }
                    alt="full_time"
                  />
                  Full Time{" "}
                </p>
              </div>
            </div>

            {/*{item.item.related_course.length > 0 ? (*/}
            {/*    <p className="relevantP">*/}
            {/*        <a*/}
            {/*            href*/}
            {/*            onClick={(e) => {*/}
            {/*                item.relevantCoursesClick(item.item.institute, item.item.related_course);*/}
            {/*            }}*/}
            {/*        >*/}
            {/*            {item.item.related_course.length} relevant programs also available**/}
            {/*        </a>*/}
            {/*    </p>*/}
            {/*) : null}*/}

            <div className="courseBoxFooter">
              {/*<p className="relevantP">*/}
              {/*	<a href="#">4 relevant programs also available</a>*/}
              {/*</p>*/}
              <div className="btnGroup">
                <button
                  className="btn"
                  onClick={() =>
                    getRoute("course_detail", {
                      id: item.item.slug,
                    })
                  }
                  // to={{
                  //     pathname: "/course-detail",
                  //     search: $.param({
                  //         course_id: item.item.id,
                  //         course_name: item.item.name,
                  //     }),
                  // }}
                >
                  Discover
                </button>
                <button
                  className="btn"
                  onClick={() => item.openDrawer(item.item)}
                >
                  Compare
                </button>
                {/* {console.log(item,"iiiiiii")} */}
                <button
                  className="btn"
                  onClick={() => item.onClickApplyForm(item.item)}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : item.item.card_type && item.item.grid_card_img_back ? (
        <GridMarketingCard detail={item.item} />
      ) : null}
    </>
  );
};

export default memo(GridViewDataRendering);
