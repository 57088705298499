import * as Actions from '../actions/apply_portal.actions';
import {
    GET_LOCATIONS_OF_APPLY_PORTAL_FAIL,
    GET_LOCATIONS_OF_APPLY_PORTAL_SUCCESS
} from "../actions/apply_portal.actions";

const allPortalsInitialState = {
    data: [],
};

export const allApplyPortals = (state = allPortalsInitialState, action) => {
    switch (action.type) {
        case Actions.GET_ALL_APPLY_PORTAL_SUCCESS: {
            return {
                ...state,
                data: action.payload
            };
        }
        case Actions.GET_ALL_APPLY_PORTAL_FAIL: {
            return {
                ...state,
                data: null
            };
        }
        default: {
            return state;
        }
    }
};

const portalDetailinitialState = {
    data: [],
};

export const applyPortalDetails = (state = portalDetailinitialState, action) => {
    switch (action.type) {
        case Actions.GET_APPLY_PORTAL_DETAIL_SUCCESS: {
            return {
                ...state,
                data: action.payload
            };
        }
        case Actions.GET_APPLY_PORTAL_DETAIL_FAIL: {
            return {
                ...state,
                data: null
            };
        }
        default: {
            return state;
        }
    }
};

const portalUniversitiesinitialState = {
    data: [],
};

export const applyPortalUniversities = (state = portalUniversitiesinitialState, action) => {
    switch (action.type) {
        case Actions.GET_APPLY_PORTAL_ALL_UNIVERSITIES_SUCCESS: {
            return {
                ...state,
                data: action.payload
            };
        }
        case Actions.GET_APPLY_PORTAL_ALL_UNIVERSITIES_FAIL: {
            return {
                ...state,
                data: []
            };
        }
        default: {
            return state;
        }
    }
};



const applyPortalRelatedLocationsInitialState = {
    data: [],
};

export const applyPortalRelatedLocations = (state = applyPortalRelatedLocationsInitialState, action) => {
    switch (action.type) {
        case Actions.GET_LOCATIONS_OF_APPLY_PORTAL_SUCCESS: {
            return {
                ...state,
                data: action.payload
            };
        }
        case Actions.GET_LOCATIONS_OF_APPLY_PORTAL_FAIL: {
            return {
                ...state,
                data: []
            };
        }
        default: {
            return state;
        }
    }
};

