import React from 'react';

const ErrorPage = () => {
    return (
        <div className="w-100 text-center p-5" >
        <h1 >Error 404</h1>
        <h2 >Page not found</h2>
        <p >The page you are looking for does not exist or you have provided invalid parameters.</p>
        <a href="/" >Go to Home</a>
    </div>
    );
};



export default ErrorPage;