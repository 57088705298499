import React, { useState, useEffect } from "react";
import CheckboxList from "../loaders/checklist";
import CountryFilter from "../filters/locations/country_filter";
import $ from "jquery";



const CountriesIndividual = (props) => {
  const {
    countriesLoader,
    filteredCountries,
    filteredCountriesValue,
    searchCountriesHandler,
    selectedLocations,
    updateSelectedCountries,
    selectedCountry,
    //handleDialogConfirmOpen,
    locationModalCheck2,
    locationModalCheck,
    getLocationCount,
    setselectedCountry
  } = props;

  let countryState = false;
  let statesState = false;
  let cityState = false;
  const locations_types = ["locations_types[0]current_location", "any"];
  const [confirmDialogHeading, setConfirmDialogHeading] = useState("");
  const [locationType, setLocationType] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [confirmDialogContent, setConfirmDialogContent] = useState("");
  const [confirmDialog, setConfirmDialog] = useState(false);

  const handleDialogConfirmOpen = (type) => {
    setLocationType(type);
    if (type === locations_types[0]) {
      if (selectedLocation.length) {
        if (selectedLocation[0].type === locations_types[0]) {
          $("#currentLocation").modal("show");
        } else {
          setConfirmDialogHeading("Use my current location");
          setConfirmDialogContent(
            "All pre selected Location will be remove Results will be according to your current Location .Please confirm to proceed next or Cancel."
          );
          setConfirmDialog(true);
        }
      } else {
        if (filteredCountries?.name?.length > 0 || filteredCountries?.states?.length > 0 || filteredCountries?.cities?.length > 0) {
          let statusConfirm = window.confirm("All pre selected Location will be remove Results will be according to your current Location .Please confirm to proceed OK or Cancel.")
          if (statusConfirm) {
            filteredCountries = { name: [], states: [], cities: [] };
            countryState = false;
            statesState = false;
            cityState = false;
            $("#currentLocation").modal("show");
          } else {
            $("#currentLocation").modal("hide");
          }

        } else {
          $("#currentLocation").modal("show");
        }
      }
    }

    if (type === locations_types[1]) {
      if (selectedLocation.length) {
        if (selectedLocation[0].type === locations_types[1]) {
          $("#autoLocation").modal("show");
        } else {
          setConfirmDialogHeading("Enter a Town/ city or Postcode");
          setConfirmDialogContent(
            "All pre selected Location will be remove if you Select Town, City or any Location from google.Please confirm to proceed next or Cancel."
          );
          setConfirmDialog(true);
        }
      } else {
        if (selectedLocations.countries.length > 0) {
          $("#autoLocation").modal("hide");
          alert(
            "Kindly de-select the country to view Courses at your location."
          );
        } else {
          $("#autoLocation").modal("show");
        }
      }
    }
  };
  return (
    <div className="filterBox">
      <h6 className={filteredCountries ? "filterOpen" : ""}>
        Location <i className="fa fa-angle-right" />
        <i className="fa fa-angle-down" />
      </h6>
      <div className={"content" + filteredCountries ? " show" : ""}>
        {countriesLoader === true ? (
          <CheckboxList />
        ) : (
          <div>
            <div className="filterSearchWrap">
              <input
                type="text"
                className="form-control"
                placeholder="Search Country"
                onChange={searchCountriesHandler}
              />
              <i className="fa fa-search" />
            </div>
            <div className="filterBoxScroll">
              {filteredCountries.length > 0 ? (
                filteredCountries.map((country, index) => {
                  return (
                    <CountryFilter
                      key={index + country?.slug}
                      country={country}
                      filteredCountriesValue={filteredCountriesValue}
                      index={index}
                      getLocationCount={getLocationCount}
                      selectedCountry={selectedCountry}
                      selectedLocations={selectedLocations}
                      callParentLoadFilter={updateSelectedCountries}
                      filteredCountries={filteredCountries}
                      rand={Math.random()}
                      setselectedCountry={setselectedCountry}
                    />
                  );
                })
              ) : (
                <p style={{ textAlign: "center" }}>No Record Found</p>
              )}
            </div>
            <div className="text-center">
              <button
                onClick={() => props.coutryApplyNow("country")}
                className="btn defaultBtn sharePgBtn my-2"
              >
                Apply Selection
              </button>
            </div>
            <a
              href={void 0}
              onClick={() => handleDialogConfirmOpen(locations_types[0], "")}
              className={
                locationModalCheck ? "filterModalBtnactive" : "filterModalBtn"
              }
            >
              <img
                src={
                  require("../../../static/images/search/use_my_current_location.svg")
                    .default
                }
                alt="use_my_current_location"
              />{" "}
              Use my current location {/*<button className='close'>x</button>*/}
            </a>
            <a
              href
              onClick={() => handleDialogConfirmOpen(locations_types[1], "")}
              className={
                locationModalCheck2 ? "filterModalBtnactive" : "filterModalBtn"
              }
            >
              <img
                src={
                  require("../../../static/images/search/enter_a_town_city_or_postcode.svg")
                    .default
                }
                alt="enter_a_town_city_or_postcode"
              />
              Enter a Town/ city or Postcode{" "}
              {/*<button className='close'>x</button>*/}
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default CountriesIndividual;
