import { useEffect, useState } from "react";
import { getCountryFilterDataReq } from "../../../services/apiService";
import CheckboxList from "../../../components/loaders/checklist";
import CustomButton from "../../../components/CustomButton";
import { useNavigate } from "react-router-dom-v5-compat";

export default function CountryFilter({
  setOpenModal,
  isBlurred,
  filterName,
  updateSearchParams,
  searchParams,
}) {
  let countryParams = searchParams.country;
  if (searchParams.country?.length) {
    countryParams = countryParams.split(",");
  }

  let stateParams = searchParams.state;
  if (searchParams.state?.length) {
    stateParams = stateParams.split(",");
  }

  let cityParams = searchParams.city;
  if (searchParams.city?.length) {
    cityParams = cityParams.split(",");
  }

  const [selectedCountries, setSelectedCountries] = useState(countryParams);
  const [selectedStates, setSelectedStates] = useState(stateParams);
  const [selectedCities, setSelectedCities] = useState(cityParams);
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(true);
  const [countryData, setCountryData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const navigate=useNavigate();

  let filtered = "";

  const getFilterData = async () => {
    setIsLoading(true)
    try {
      const res = await getCountryFilterDataReq(searchParams)
      const { data } = res;
      setCountryData(data)

      for (let country of data) {
        if (selectedCountries.includes(country.slug)) {
          const stateSlugs = country.states.map((state) => state.slug);
          setSelectedStates((prevStates) => [...new Set([...prevStates, ...stateSlugs])]);

          // Update selectedCities with unique city_slugs
          const citySlugs = country.states.reduce((acc, state) => {
            const stateCities = state.cities.map((city) => city.slug);
            return [...acc, ...stateCities];
          }, []);
          setSelectedCities((prevCities) => [...new Set([...prevCities, ...citySlugs])]);
        }

        // Iterate over selected states and update selectedCities
        for (let selectedStateSlug of selectedStates) {
          const selectedState = country.states.find((state) => state.slug === selectedStateSlug);
          if (selectedState) {
            const selectedStateCitySlugs = selectedState.cities.map((city) => city.slug);
            setSelectedCities((prevCities) => [...new Set([...prevCities, ...selectedStateCitySlugs])]);
          }
        }
      }

      handleSelection(data)
    } catch (err) {
      console.log('Error', err)
    }
    setIsLoading(false)
  }

  if (countryData) {
    filtered = countryData.filter((country) =>
      country?.name?.toLowerCase().includes(query.toLowerCase())
    );
  }

  const handleSelect = (
    slug,
    type,
    country
  ) => {
    if (type === "Country") {

      setSelectedCountries((prevSelected) => {
        const selectedCountryEntry = countryData.find((entry) => entry.slug === slug);

        if (prevSelected.includes(slug)) {
          // Uncheck logic
          if (selectedCountryEntry) {
            const statesSlugs = selectedCountryEntry.states.map((state) => state.slug);
            setSelectedStates((prevStates) =>
              prevStates.filter((stateSlug) => !statesSlugs.includes(stateSlug))
            );

            const citiesSlugs = selectedCountryEntry.states.flatMap((state) =>
              state.cities.map((city) => city.slug)
            );

            setSelectedCities((prevCities) =>
              prevCities.filter((citySlug) => !citiesSlugs.includes(citySlug))
            );
          }

          return prevSelected.filter((id) => id !== slug);
        } else {
          // Check logic
          if (selectedCountryEntry) {
            const statesSlugs = selectedCountryEntry.states.map((state) => state.slug);
            setSelectedStates((prevStates) => [...prevStates, ...statesSlugs]);

            const citiesSlugs = selectedCountryEntry.states.flatMap((state) =>
              state.cities.map((city) => city.slug)
            );

            setSelectedCities((prevCities) => [...prevCities, ...citiesSlugs]);
          }

          return [...prevSelected, slug];
        }
      });


    }
    else if (type === "State") {
      setSelectedStates((prevSelected) => {
        if (prevSelected.includes(slug)) {

          const selectedStateEntry = country.states.find((entry) => entry.slug === slug);

          if (selectedStateEntry) {
            const statesSlugs = selectedStateEntry.cities.map((state) => state.slug);
            if (selectedCities.length > 0 && selectedCities.some(element => statesSlugs.includes(element))) {
              setSelectedCities((prevStates) =>
                prevStates.filter((citySlug) => !statesSlugs.includes(citySlug))
              );
            }
          }
          return prevSelected.filter((id) => id !== slug);
        } else {
          const selectedStateEntry = country.states.find((entry) => entry.slug === slug);

          if (selectedStateEntry) {
            const statesSlugs = selectedStateEntry.cities.map((city) => city.slug);
            setSelectedCities((prevStates) => [...prevStates, ...statesSlugs]);
          }
          return [...prevSelected, slug];
        }
      });

    } else {
      setSelectedCities((prevSelected) => {
        if (prevSelected.includes(slug)) {
          return prevSelected.filter((id) => id !== slug);
        } else {
          return [...prevSelected, slug];
        }
      });
    }
  };

  const onApply = () => {
    let _countries = [...new Set(selectedCountries)]
    let _states = [...new Set(selectedStates)]
    let _cities = [...new Set(selectedCities)]

    for (let country of countryData) {
      if (_countries.includes(country.slug) && country.fullySelected) {
        // Filter out fully selected country's states from _states
        _states = _states.filter(state => !country.states.map(s => s.slug).includes(state));

        // Filter out cities of fully selected country's states from _cities
        _cities = _cities.filter(city => !country.states.some(state => state.cities.map(c => c.slug).includes(city)));
      } else {
        // Check fully selected states within the selected country
        for (let state of country.states) {
          if (_countries.includes(state.country_slug)) {
            _countries = _countries.filter(c => c !== country.slug);
          }
          if (_states.includes(state.slug) && state.fullySelected) {
            //Check if country is still in _countries
            // Filter out fully selected state's cities from _cities
            _cities = _cities.filter(city => !state.cities.map(c => c.slug).includes(city));
          } else {
            // Check if city is in _cities and state is not fully selected, remove state from _states
            if (_cities.some(city => state.cities.map(c => c.slug).includes(city) && !state.fullySelected)) {
              _states = _states.filter(s => s !== state.slug);
            }
          }
        }
      }
    }

    updateSearchParams({
      country: selectedCountries && _countries.join(","),
      state: selectedStates && _states.join(","),
      city: selectedCities && _cities.join(",")
    });
  }

  const handleSelection = (data) => {
    const updatedData = [...data];

    // Iterate through countries
    updatedData.forEach((country) => {
      // Update the country's selected status based on the states
      country.partialSelected = country.states.some((state) =>
        selectedStates.includes(state.slug)
      );
      country.fullySelected =
        // country.partialSelected &&
        country.states.every((state) =>
          selectedStates.includes(state.slug)
        );

      // Iterate through states
      country.states.forEach((state) => {

        // Check if any city of this state is selected
        const partialSelected = state.cities.some((city) =>
          selectedCities.includes(city.slug)
        );

        // Check if all cities of this state are selected
        const fullySelected = state.cities.every((city) =>
          selectedCities.includes(city.slug)
        );

        // Update the state with the new properties
        state.partialSelected = partialSelected;
        state.fullySelected = fullySelected;

        if (partialSelected) {
          country.partialSelected = true;
        }

      });
      // Check if all states are fully selected and update country.fullySelected
      const allStatesFullySelected = country.states.every((state) => state.fullySelected);
      country.fullySelected = allStatesFullySelected;
    });

    // Update the state with the modified data
    setCountryData(updatedData);
  }

  useEffect(() => {
    getFilterData()
  }, []);

  useEffect(() => {
    // Clone the initial data to avoid mutating the original data
    handleSelection(countryData)

  }, [selectedCountries, selectedStates, selectedCities]);



  return (
    <>
      <div className="filterBox">
        <h6
          style={{ cursor: "pointer" }}
          onClick={() => setIsOpen((isOpen) => !isOpen)}
          className="filterOpen"
        >
          {filterName}
          {isOpen ? (
            <i className="fa fa-angle-down"></i>
          ) : (
            <i className="fa fa-angle-right"></i>
          )}
        </h6>
        {isOpen && (
          <div className="show">
            {isLoading ?
              <CheckboxList /> :
              <>
                <div className="filterSearchWrap">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={"Search " + filterName}
                    onChange={(e) => setQuery(e.target.value)}
                  />
                  <i className="fa fa-search"></i>
                </div>
                <div className="filterBoxScroll">
                  {countryData &&
                    filtered.map((country, index) => (
                      <Country
                      setOpenModal={setOpenModal}
                       isBlurred={isBlurred}
                        key={index}
                        country={country}
                        selectedCountries={selectedCountries}
                        selectedStates={selectedStates}
                        selectedCities={selectedCities}
                        onCountryChange={handleSelect}
                      />
                    ))}
                </div>
                <div className="text-center pb-2">
                  <CustomButton
                    onClick={!isBlurred?onApply:()=>setOpenModal(true)}
                    buttonText="Apply Filter"
                  />
                </div>
              </>
            }
          </div>
        )}
      </div>
    </>
  );
}

const Country = ({
  setOpenModal,
  isBlurred,
  country,
  onCountryChange,
  selectedStates,
  selectedCountries,
  selectedCities,
}) => {
  const [isCountryArrowClicked, setCountryArrowClicked] = useState(false)
  const [stateArrowClicked, setStateArrowClicked] = useState({})
  const navigate=useNavigate();

  return (
    <>
      <div className="filterItemWrap">
        <div
          id={country?.name}
          className={"filterItem " + (isCountryArrowClicked && "filterOpen")}
        >
          <input
            className={(country.partialSelected && !country.fullySelected && "childChecked")}
            type="checkbox"
            id={country.id}
            name={country.name}
            checked={selectedCountries.includes(country.slug) || country.fullySelected}
            onChange={!isBlurred?() => onCountryChange(country.slug, "Country", country):()=>setOpenModal(true)}
          />
          <label htmlFor={country.id}>{country.name}</label>
          <i
            className={`fa fa-angle-${isCountryArrowClicked ? 'down' : 'right'}`}
            onClick={() => setCountryArrowClicked((prevValue) => !prevValue)}
          ></i>
        </div>
        <div
          className={"filterItemCont " + (isCountryArrowClicked && "show")}
        >
          {country.states.map((state, index) => (
            <State
              key={index}
              state={state}
              country={country}
              stateArrowClicked={stateArrowClicked}
              selectedStates={selectedStates}
              onCountryChange={onCountryChange}
              setStateArrowClicked={setStateArrowClicked}
              selectedCities={selectedCities}
            />
          ))}
        </div>
      </div>
    </>
  );
};

const State = ({
  state,
  country,
  stateArrowClicked,
  selectedStates,
  onCountryChange,
  setStateArrowClicked,
  selectedCities,
}) => {

  return (

    <div className="filterItemWrap">
      <div
        id={state?.name}
        className={
          "filterItem " + (stateArrowClicked[state.id] && "filterOpen")
        }
      >
        <input
          className={(state.partialSelected && !state.fullySelected && "childChecked")}
          type="checkbox"
          id={state.id}
          name={state.name}
          checked={selectedStates.includes(state.slug) || state.fullySelected}
          onChange={() => onCountryChange(state.slug, "State", country)}
        />
        <label htmlFor={state.id}>{state.name}</label>
        <i
          className={`fa fa-angle-${stateArrowClicked[state.id] ? 'down' : 'right'}`}
          onClick={() => setStateArrowClicked((prevValue) => ({
            ...prevValue,
            [state.id]: !prevValue[state.id], // Toggle the state for this specific state country
          }))}
        ></i>
      </div>

      <div
        id={state.name}
        className={"filterItemCont " + (stateArrowClicked[state.id] && "show")}
      >
        {state.cities.map((city, i) => (
          <div key={i} className="filterItemWrap">
            <div id={city?.name} className="filterItem">
              <input
                type="checkbox"
                id={city.id}
                name={city.name}
                checked={selectedCities.includes(city.slug)}
                onChange={() => onCountryChange(city.slug, "City", country)}
              />
              <label htmlFor={city.id}>{city.name}</label>
            </div>
          </div>
        ))}
      </div>
    </div>

  )
}