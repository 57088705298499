import apis from "../../apis/store/reducer";
import { combineReducers } from "redux";
import auth from "./auth";

const rootReducer = (asyncReducers) =>
	combineReducers({
		apis,
		auth,

		...asyncReducers,
	});

export default rootReducer;
