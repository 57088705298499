import React from "react";
import ApplyNowModal from "./applynow_modal";
import CheckEligibility from "./checkEligibilty_modal";
import GetFreeCounsellingModal from "./getFreeCounselling_modal";
import icon1 from "../../../static/images/dis-icon1.png"
import icon2 from "../../../static/images/dis-icon2.png"
import icon3 from "../../../static/images/dis-icon3.png"
import { ToastContainer, toast } from "react-toastify";


export default function FormsComponent(props) {
	function showToaster(){
toast.success("Application Submitted");
	}
	const threeFormClick = (id) => {
		if (id === "apply") {
			localStorage.setItem("ApplyModal", true);
		} else if (id === "check") {
			localStorage.setItem("CheckEligibilityModal", true);
		} else if (id === "free") {
			localStorage.setItem("GetFreeModal", true);
		}
	};
	return (
		<>
			<ToastContainer
				position="top-center"
				autoClose={1000}
				hideProgressBar={true}
				newestOnTop={false}
				closeOnClick={false}
				rtl={false}
				pauseOnFocusLoss={false}
				draggable={false}
				pauseOnHover={false}
			/>
			<div className="disInfoFlex">
				<div className="box">
					<img
						src={icon1}
						alt="banner"
					/>
					<button
						className="btn defaultBtn"
						data-toggle="modal"
						data-dismiss="modal"
						data-target="#ApplyNow"
						id="apply"
						onClick={(e) => threeFormClick(e.target.id)}
					>
						Apply Now
					</button>
				</div>
				<div className="box">
					<img
						src={icon2}
						alt="banner"
					/>
					<button
						className="btn defaultBtn"
						data-toggle="modal"
						data-dismiss="modal"
						data-target="#eligibility"
						id="check"
						onClick={(e) => threeFormClick(e.target.id)}
					>
						Check Eligibility
					</button>
				</div>
				<div className="box">
					<img
						src={icon3}
						alt="banner"
					/>
					<button
						className="btn defaultBtn"
						data-toggle="modal"
						data-dismiss="modal"
						data-target="#freeCounselling"
						id="free"
						onClick={(e) => threeFormClick(e.target.id)}
					>
						Get Free Counselling
					</button>
				</div>
			</div>
			{/*---------------- eligibility form ---------------*/}
			<div
				className="modal fade signinModal whyFormModal"
				id="eligibility"
				tabindex="-1"
				role="dialog"
				aria-labelledby="exampleModalCenterTitle"
				aria-hidden="true"
			>
				<CheckEligibility instituteDetail={props.detail} ftn={showToaster} />
			</div>
			{/*---------------- apply form ---------------*/}
			<div
				className="modal fade signinModal whyFormModal"
				id="ApplyNow"
				tabindex="-1"
				role="dialog"
				aria-labelledby="exampleModalCenterTitle"
				aria-hidden="true"
			>
				<ApplyNowModal  ftn={showToaster}/>
			</div>
			{/*---------------- counseling form ---------------*/}
			<div
				className="modal fade signinModal whyFormModal"
				id="freeCounselling"
				tabindex="-1"
				role="dialog"
				aria-labelledby="exampleModalCenterTitle"
				aria-hidden="true"
			>
				<GetFreeCounsellingModal  ftn={showToaster}/>
			</div>
		</>
	);
}
