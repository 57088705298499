import React from 'react';

const InstituteTypesIndividual = (props) => {
    return (
        <div className="filterBox">
            <h6 className={"filterOpen"}>
                Institute Type <i className="fa fa-angle-right"></i>
                <i className="fa fa-angle-down"></i>
            </h6>
            <div className={"content show"}>
                <div className="filterBoxScroll">
                    <div className="filterItemWrap">
                        <div className="filterItem">
                            <input
                                type="checkbox"
                                value="COLLEGE"
                                checked={props.collegeCheck}
                                onChange={(e) =>
                                    props.handleInstituteType(e.target.value, e.target.checked)
                                }
                            ></input>
                            <span className={props.collegeCheck ? "active" : ""}>
												{" "}
                                College
											</span>
                        </div>
                        <div className="filterItem">
                            <input
                                checked={props.universityCheck}
                                type="checkbox"
                                value="UNIVERSITY"
                                onChange={(e) =>
                                    props.handleInstituteType(e.target.value, e.target.checked)
                                }
                            ></input>
                            <span className={props.universityCheck ? "active" : ""}>
												{" "}
                                University
											</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InstituteTypesIndividual;
