import React, {useEffect, useState} from "react";
import Geocode from 'react-geocode';


Geocode.setApiKey("AIzaSyDzETqKlZ-nTU-JkLM1e72IlLIX2pTsxZs");
const CurrentLocation = (props) => {
    // console.log(props, "from current location components")
    const [address, setAddress] = useState("");
    const [location, setLocation] = useState({
        loaded: false,
        coordinates: {lat: "", lng: ""},
    });
    const onSuccess = (location) => {
        setLocation({
            loaded: true,
            coordinates: {
                lat: location.coords.latitude,
                lng: location.coords.longitude,
            },
        });

        getAddress(location.coords.latitude, location.coords.longitude);
    };
    const onError = (error) => {
        setLocation({
            loaded: true,
            error,
        });
    };
    useEffect(() => {
        // if (address !== "") {

        // } else {
        //     if (("geolocation" in navigator)) {
        //         navigator.geolocation.getCurrentPosition(onSuccess, onError);
        //     } else {
        //         onError({
        //             error: {
        //                 code: 0,
        //                 message: "Location not Supported",
        //             },
        //         });
        //         navigator.geolocation.getCurrentPosition(onSuccess, onError);
        //     }
        // }


        // navigator.geolocation.getCurrentPosition(onSuccess, onError);
    }, []);


    // Geocode.setApiKey("")
    // Geocode.setLanguage("en");
    // Geocode.setRegion("es");

    // useEffect(() => {
    // 	getCoordinates();
    // }, []);

    // function getCoordinates() {
    // 	if (navigator.geolocation) {
    // 		navigator.permissions
    // 			.query({ name: "geolocation" })
    // 			.then(function (result) {
    // 				if (result.state === "granted") {
    // 					navigator.geolocation.getCurrentPosition(
    // 						function (position) {
    // 							getAddress(position.coords.latitude, position.coords.longitude);
    // 						},
    // 						function (error) {
    // 							alert(error.message);
    // 							// console.error(
    // 							// 	"Error Code = " + error.code + " - " + error.message
    // 							// );
    // 						}
    // 					);
    // 					//If granted then you can directly call your function here
    // 				} else if (result.state === "prompt") {
    // 					console.log(result.state);
    // 				} else if (result.state === "denied") {
    // 					alert("Denied enable location");
    // 					//If denied then you have to show instructions to enable location
    // 				}
    // 				result.onchange = function () {
    // 					console.log(result.state);
    // 				};
    // 			});
    // 	} else {
    // 		alert("Sorry Not available!");
    // 	}
    // }

    function getAddress(latitude, longitude) {
        if (address !== "") {

        } else {
            Geocode.fromLatLng(latitude, longitude).then(
                (response) => {
                    setAddress(response.results[0].formatted_address);
                    const obj = {
                        lat: latitude,
                        lng: longitude,
                        address: response.results[0].formatted_address,
                    };
                    localStorage.setItem('urlocMain',JSON.stringify(obj));
                    props.onLoadCurrentLocation(obj);
                    const newAddress = response.results[0].formatted_address;
                    let city, state, country;
                    for (let i = 0; i < response.results[0].address_components.length; i++) {
                        for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
                            switch (response.results[0].address_components[i].types[j]) {
                                case "locality":
                                    city = response.results[0].address_components[i].long_name;
                                    break;
                                case "administrative_area_level_1":
                                    state = response.results[0].address_components[i].long_name;
                                    break;
                                case "country":
                                    country = response.results[0].address_components[i].long_name;
                                    break;
                            }
                        }
                    }
                    // console.log(city, state, country);
                    // console.log(address);
                },
                (error) => {
                    // console.error(error);
                }
            );
        }

    }

    return (
        <div>
            {address ? (
                <p>{address}</p>
            ) : (
                "Your Location is disabled (Allow Location from URL bar where you can see a location sign)"
            )}
        </div>
    );
};

export default CurrentLocation;
