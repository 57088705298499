export const INSTITUTE_TYPES = [
    {
        id: 1,
        name: "College",
        slug: "COLLEGE"
    },
    {
        id: 2,
        name: "University",
        slug: "UNIVERSITY"
    }
]

export const INTAKE_MONTHS = [
    {
        id: 1,
        name: "Jan, Feb, Mar",
        type: "monthGroup",
        slug: "firstQuarter",
        months: [
            {
                id: 11,
                name: "January",
                slug: "january"
            },
            {
                id: 12,
                name: "February",
                slug: "february"
            },
            {
                id: 13,
                name: "March",
                slug: "march"
            }
        ]
    },
    {
        id: 2,
        name: "Apr, May, Jun",
        type: "monthGroup",
        slug: "secondQuarter",
        months: [
            {
                id: 21,
                name: "April",
                slug: "april"
            },
            {
                id: 22,
                name: "May",
                slug: "may"
            },
            {
                id: 23,
                name: "June",
                slug: "june"
            }
        ]
    },
    {
        id: 3,
        name: "Jul, Aug, Sep",
        type: "monthGroup",
        slug: "thirdQuarter",
        months: [
            {
                id: 31,
                name: "July",
                slug: "july"
            },
            {
                id: 32,
                name: "August",
                slug: "august"
            },
            {
                id: 33,
                name: "September",
                slug: "september"
            }
        ]
    },
    {
        id: 4,
        name: "Oct, Nov, Dec",
        type: "monthGroup",
        slug: "fourthQuarter",
        months: [
            {
                id: 41,
                name: "October",
                slug: "october"
            },
            {
                id: 42,
                name: "November",
                slug: "november"
            },
            {
                id: 43,
                name: "December",
                slug: "december"
            }
        ]
    },
]
