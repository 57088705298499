import * as Actions from "../actions/blog.actions";

const initialState = {
	data: [],
};

export const allBlogs = (state = initialState, action) => {
	switch (action.type) {
		case Actions.GET_ALL_BLOGS_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.GET_ALL_BLOGS_FAIL: {
			return {
				...state,
				data: null,
			};
		}
		default: {
			return state;
		}
	}
};

const allBlogsByIdInitialState = {
	data: [],
};

export const allBlogsById = (state = allBlogsByIdInitialState, action) => {
	switch (action.type) {
		case Actions.GET_BLOGS_BY_ID_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.GET_BLOGS_BY_ID_FAIL: {
			return {
				...state,
				data: null,
			};
		}
		default: {
			return state;
		}
	}
};
