import { http } from "../../../services/apiService/apiService";
import axios from "axios";
import {GET_ALL_COUNTRIES_WITH_DETAIL_FAIL, GET_ALL_COUNTRIES_WITH_DETAIL_SUCCESS} from "./country.actions";

// Degree levels

export const GET_ALL_DEGREE_LEVELS = "GET_ALL_DEGREE_LEVELS";
export const GET_ALL_DEGREE_LEVELS_SUCCESS = "GET_ALL_DEGREE_LEVELS_SUCCESS";
export const GET_ALL_DEGREE_LEVELS_FAIL = "GET_ALL_DEGREE_LEVELS_FAIL";

// export function getAllDegreeLevels() {
// 	const request = http.get("degree_level/");
//
// 	return (dispatch) =>
// 		request
// 			.then((response) =>
// 				dispatch({
// 					type: GET_ALL_DEGREE_LEVELS_SUCCESS,
// 					payload: response.data,
// 				})
// 			)
// 			.catch((error) => {
// 				return dispatch({
// 					type: GET_ALL_DEGREE_LEVELS_FAIL,
// 					payload: [],
// 				});
// 			});
// }


export const getAllDegreeLevels = () => async (dispatch,getState) => {
	// dispatch(handleLoadingDispatcher(LOADING_COUNTRY_RANKING, true));
	// dispatch(handleSuccessDispatcher(SUCCESS_COUNTRY_RANKING, false));
	if(!getState().apis.allDegreeLevels.data.length) {
		let url = "degree_level/";
		try {
			url = process.env.REACT_APP_BASE_URL + url
			const response = await axios.get(url);
			dispatch(
				{
					type: GET_ALL_DEGREE_LEVELS_SUCCESS,
					payload: response.data.results,
				}
			);
		} catch (e) {
			return dispatch({
				type: GET_ALL_DEGREE_LEVELS_FAIL,
				payload: [],
			});
		}
	}
};
