import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getSingleRoute } from "../../../common/utils";
import { getQueryString } from "../../common/getQueryString";

let selectedCampuses = [];
let campuse = [];

function UniversitiesIndividualDisciplines(props) {
    const history = useHistory()
    const { availableDisciplines, campuses, institute_id, country, country_slug, institute } = props;
    const [availableDisciplines1, setAvailableDisciplines] = useState(
        availableDisciplines
    );

    useEffect(() => {
        const ids = campuses?.map((o) => o.slug);
        campuse = campuses?.filter(
            ({ slug }, index) => !ids.includes(slug, index + 1)
        );
        setAvailableDisciplines(availableDisciplines);
        selectedCampuses = [];
        if (campuse?.length !== 0) {
            for (let i = 0; i < campuse?.length; i++) {
                selectedCampuses.push({
                    slug: campuse[i]?.slug
                })
            }
        }
    }, [props]);

    const checkResults = (newitem, item) => {
        // history.push({
        //     pathname: "/discipline",
        //     search: $.param({
        //         inid: props?.institute_id,
        //         main: true,
        //         dpid: item.id,
        //         dlid: newitem.id,
        //         caid: selectedCampuses,
        //         cnt: props?.country.id,
        //     }),
        // })
    };
    const getRoute = (page, id) => {
        getSingleRoute(page).then(d => {
            history.push({
                pathname: d,
                search: id?.search ? id?.search : '',
            });
        })
    }
    return (
        <div>
            <section className="twelveFlex">
                {availableDisciplines1
                    ? availableDisciplines1?.map((item) => (
                        <div className="twelveBox">
                            <div className="imgWrap">
                                <button
                                    onClick={() => getRoute('discipline_detail', {
                                        search: `?discipline=${item.slug}${institute_id ? '&institutes=' + institute_id : null}`
                                        // $.param({
                                        //     dcp: [{id: item.id}],
                                        //     institutes: institute_id ? [{id: institute_id}] : null,
                                        // })
                                    })}
                                // to={{
                                //     pathname: "/discipline-detail",
                                //     search: $.param({
                                //         dcp: [{id: item.id}],
                                //         inst: institute_id ? [{id: institute_id}] : null,
                                //     }),
                                // }}
                                >
                                    <img src={item.logo} alt={item.name} />
                                    <div className="titleBox">
                                        <img src={item.icon} alt={item.name} />
                                        <h6 key={item.id}>{item.name}</h6>
                                    </div>
                                </button>
                            </div>

                            {/* <button
									className="btn"
									type="button"
									id="dropdownMenuButton"
									data-toggle="dropdown"
									aria-haspopup="true"
									aria-expanded="false"
								>
									<span>Undergraduate</span>
									<i className="fa fa-angle-right"></i>
								</button> */}
                            {/* {item.Undergraduate.length ? ( */}
                            <div className="dropdown">
                                <button
                                    className="btn"
                                    type="button"
                                    id="dropdownMenuButton"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    <span>Undergraduate</span>
                                    <i className="fa fa-angle-right" />
                                </button>
                                <div
                                    className="dropdown-menu"
                                    aria-labelledby="dropdownMenuButton"
                                >
                                    {item.undergraduate?.length > 0 ? (
                                        item.undergraduate.map((newitem) => (
                                            <button
                                                key={newitem.id}
                                                onClick={() => getRoute('discipline', {
                                                    search: getQueryString({
                                                        discipline: [item.slug],
                                                        levels: [newitem.slug],
                                                        institute: institute ? [institute] : '',
                                                        page: "discipline",
                                                        country: country_slug ? [country_slug] : [],
                                                    })
                                                    // $.param({
                                                    //     inst: [{id: institute_id}],
                                                    //     dcp: [{id: item.id}],
                                                    //     dlvl: [{id: newitem.id}],
                                                    //     caid: campuse.map(d=>d.slug),
                                                    //     page:"discipline",
                                                    //     cnt: [{id: country?.id}],
                                                    // })
                                                })}
                                                // to={{
                                                //     pathname: "/discipline",
                                                //     search: $.param({
                                                //         inst: [{id: institute_id}],
                                                //         dcp: [{id: item.id}],
                                                //         dlvl: [{id: newitem.id}],
                                                //         caid: campuse.map(d=>d.slug),
                                                //         page:"discipline",
                                                //         cnt: [{id: country?.id}],
                                                //     }),
                                                // }}
                                                className="dropdown-item"
                                            // onClick={() => checkResults(newitem, item)}
                                            >
                                                {newitem.name ? newitem.name : "Degree not available"}
                                            </button>
                                        ))
                                    ) : (
                                        <button className="dropdown-item" disabled={true}>
                                            Degree not available
                                        </button>
                                    )}
                                </div>
                            </div>
                            {/* ) : (
                                ""
                            )} */}
                            {/* {item.postgraduate.length ? ( */}
                            <div className="dropdown">
                                <button
                                    className="btn"
                                    type="button"
                                    id="dropdownMenuButton"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    <span>Postgraduate</span>
                                    <i className="fa fa-angle-right"></i>
                                </button>
                                <div
                                    className="dropdown-menu"
                                    aria-labelledby="dropdownMenuButton"
                                >
                                    {item.postgraduate?.length > 0 ? (
                                        item.postgraduate.map((newitem) => (
                                            <button
                                                key={newitem.id}
                                                onClick={() => getRoute('discipline', {
                                                    search: getQueryString({
                                                        institute: institute ? [institute] : '',
                                                        discipline: [item.slug],
                                                        levels: [newitem.slug],
                                                        page: "discipline",
                                                        country: country_slug ? [country_slug] : [],
                                                        // country: [country_slug],
                                                    })
                                                })}
                                                // to={{
                                                //     pathname: "/discipline",
                                                //     search: $.param({
                                                //         inst: [{id: institute_id}],
                                                //         dcp: [{id: item.id}],
                                                //         dlvl: [{id: newitem.id}],
                                                //         caid: campuse.map(d=>d.slug),
                                                //         page:"discipline",
                                                //         cnt: [{id: country?.id}],
                                                //     }),
                                                // }}
                                                // onClick={() => checkResults(newitem, item)}
                                                className="dropdown-item"
                                            >
                                                {newitem.name ? newitem.name : "Degree not available"}
                                            </button>
                                        ))
                                    ) : (
                                        <button className="dropdown-item" disabled={true}>
                                            Degree not available
                                        </button>
                                    )}
                                </div>
                            </div>
                            {/* ) : (
                                ""
                            )} */}
                            {/* {item.research.length ? ( */}
                            <div className="dropdown">
                                <button
                                    className="btn"
                                    type="button"
                                    id="dropdownMenuButton"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    <span>Postgraduate by Research</span>
                                    <i className="fa fa-angle-right"></i>
                                </button>
                                <div
                                    className="dropdown-menu"
                                    aria-labelledby="dropdownMenuButton"
                                >
                                    {item.research?.length > 0 ? (
                                        item.research.map((newitem) => (
                                            <button
                                                key={newitem.id}
                                                onClick={() => getRoute('discipline', {
                                                    search: getQueryString({
                                                        institute: institute ? [institute] : '',
                                                        discipline: [item.slug],
                                                        levels: [newitem.slug],
                                                        page: "discipline",
                                                        country: country_slug ? [country_slug] : [],
                                                        // country: [country_slug],
                                                    })
                                                })}
                                                // to={{
                                                //     pathname: "/discipline",
                                                //     search: $.param({
                                                //         inst: [{id: institute_id}],
                                                //         dcp: [{id: item.id}],
                                                //         dlvl: [{id: newitem.id}],
                                                //         caid: campuse.map(d=>d.slug),
                                                //         page:"discipline",
                                                //         cnt: [{id: country?.id}],
                                                //     }),
                                                // }}
                                                // onClick={() => checkResults(newitem, item)}
                                                className="dropdown-item"
                                            >
                                                {newitem.name ? newitem.name : "Degree not available"}
                                            </button>
                                        ))
                                    ) : (
                                        <button className="dropdown-item" disabled={true}>
                                            Degree not available
                                        </button>
                                    )}
                                </div>
                            </div>
                            {/* ) : (
                                ""
                            )} */}
                            {/* <button

									className="btn"
									type="button"
									id="dropdownMenuButton"
									data-toggle="dropdown"
									aria-haspopup="true"
									aria-expanded="false"
								>
									<span>Postgraduate</span>
									<i className="fa fa-angle-right"></i>
								</button>
								<button
									className="btn"
									type="button"
									id="dropdownMenuButton"
									data-toggle="dropdown"
									aria-haspopup="true"
									aria-expanded="false"
								>
									<span>Postgraduate by Research</span>
									<i className="fa fa-angle-right"></i>
								</button> */}
                        </div>
                    ))
                    : ""}
                {/* <div className="twelveBox">
                                <div className="imgWrap">
                                    <img src = {require('../../../static/images/discipline-img.svg').default} alt = "course-img" />
                                    <div className="titleBox">
                                        <img src = {require('../../../static/images/art-design.svg').default} alt = "course-img" /><h6>Arts & Humanities</h6>
                                    </div>
                                </div>
                                <button className="btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span>Undergraduate</span><i className="fa fa-angle-right"></i>
                                </button>
                                <button className="btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span>Postgraduate</span><i className="fa fa-angle-right"></i>
                                </button>
                                <button className="btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span>Postgraduate by Research</span><i className="fa fa-angle-right"></i>
                                </button>

                            </div>
                            <div className="twelveBox">
                                <div className="imgWrap">
                                    <img src = {require('../../../static/images/discipline-img.svg').default} alt = "course-img" />
                                    <div className="titleBox">
                                        <img src = {require('../../../static/images/art-design.svg').default} alt = "course-img" /><h6>Arts & Humanities</h6>
                                    </div>
                                </div>
                                <button className="btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span>Undergraduate</span><i className="fa fa-angle-right"></i>
                                </button>
                                <button className="btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span>Postgraduate</span><i className="fa fa-angle-right"></i>
                                </button>
                                <button className="btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span>Postgraduate by Research</span><i className="fa fa-angle-right"></i>
                                </button>

                            </div>
                            <div className="twelveBox">
                                <div className="imgWrap">
                                    <img src = {require('../../../static/images/discipline-img.svg').default} alt = "course-img" />
                                    <div className="titleBox">
                                        <img src = {require('../../../static/images/art-design.svg').default} alt = "course-img" /><h6>Arts & Humanities</h6>
                                    </div>
                                </div>
                                <button className="btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span>Undergraduate</span><i className="fa fa-angle-right"></i>
                                </button>
                                <button className="btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span>Postgraduate</span><i className="fa fa-angle-right"></i>
                                </button>
                                <button className="btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span>Postgraduate by Research</span><i className="fa fa-angle-right"></i>
                                </button>

                            </div> */}
            </section>
        </div>
    );
}

export default UniversitiesIndividualDisciplines;
