import React from 'react';
import CheckboxList from "../loaders/checklist";
import DegreeLevelFilters from "../filters/degreeLevels/degree_level_filters";

const DegreeLevelsIndividual = (props) => {
    return (
        <div className="filterBox">
            <h6 className={"filterOpen"}>
                Degree Level <i className="fa fa-angle-right"></i>
                <i className="fa fa-angle-down"></i>
            </h6>
            {props.degreeLevelLoader === true ? (
                <CheckboxList />
            ) : (
                <div className={"content show"}>
                    {/* <div className="filterSearchWrap">
										<input
											type="text"
											className="form-control"
											placeholder="Degree Level"
											onChange={searchDegreeLevelHandler}
										></input>
										<i className="fa fa-search"></i>
									</div> */}
                    <div className="filterBoxScroll">
                        {props?.filteredDegreeLevels?.map((item, index) => (
                            <DegreeLevelFilters
                                key={index}
                                degree_level={item}
                                index={index}
                                selectedDegreeLevels={props.selectedDegreeLevels}
                                updateSelectedDegreeLevels={props.updateSelectedDegreeLevels}
                                rand={Math.random()}
                            />
                        ))}
                    </div>
                    <div className="text-center">
                        <button
                            onClick={() => props.coutryApplyNow("degreeLevels")}
                            className="btn defaultBtn sharePgBtn my-2"
                        >
                            Apply Selection
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DegreeLevelsIndividual;
