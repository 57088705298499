import * as Actions from "../actions/institute.actions";
import { GET_ALL_INSTITUTES_DISCIPLINES_RELATED_INTAKES_SUCCESS } from "../actions/institute.actions";

const InstitutesDisciplineLevelsInitialState = {
	data: [],
};

export const allInstitutesDisciplineLevels = (
	state = InstitutesDisciplineLevelsInitialState,
	action
) => {
	switch (action.type) {
		case Actions.GET_ALL_INSTITUTES_DISCIPLINES_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.GET_ALL_INSTITUTES_DISCIPLINES_FAIL: {
			return {
				...state,
				data: null,
			};
		}
		default: {
			return state;
		}
	}
};

const InstitutesDetailByIDinitialState = {
	data: [],
};

export const institutesDetailByID = (
	state = InstitutesDetailByIDinitialState,
	action
) => {
	switch (action.type) {
		case Actions.GET_INSTITUTE_DETAIL_BY_ID_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.GET_INSTITUTE_DETAIL_BY_ID_FAIL: {
			return {
				...state,
				data: null,
			};
		}
		default: {
			return state;
		}
	}
};

const institutesDisciplinesRelatedDegreeLevelsinitialState = {
	data: [],
};

export const institutesDisciplinesRelatedDegreeLevels = (
	state = institutesDisciplinesRelatedDegreeLevelsinitialState,
	action
) => {
	switch (action.type) {
		case Actions.GET_ALL_INSTITUTES_DISCIPLINES_RELATED_DEGREE_LEVELS_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.GET_ALL_INSTITUTES_DISCIPLINES_RELATED_DEGREE_LEVELS_FAIL: {
			return {
				...state,
				data: null,
			};
		}
		default: {
			return state;
		}
	}
};

const institutesDisciplinesRelatedIntakesinitialState = {
	data: [],
};

export const institutesDisciplinesRelatedIntakes = (
	state = institutesDisciplinesRelatedIntakesinitialState,
	action
) => {
	switch (action.type) {
		case Actions.GET_ALL_INSTITUTES_DISCIPLINES_RELATED_INTAKES_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.GET_ALL_INSTITUTES_DISCIPLINES_RELATED_INTAKES_FAIL: {
			return {
				...state,
				data: null,
			};
		}
		default: {
			return state;
		}
	}
};

const institutesDisciplinesRelatedSpecializationsinitialState = {
	data: [],
};

export const institutesDisciplinesRelatedSpecializations = (
	state = institutesDisciplinesRelatedSpecializationsinitialState,
	action
) => {
	switch (action.type) {
		case Actions.GET_ALL_INSTITUTES_DISCIPLINES_RELATED_SPECIALIZATIONS_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.GET_ALL_INSTITUTES_DISCIPLINES_RELATED_SPECIALIZATIONS_FAIL: {
			return {
				...state,
				data: null,
			};
		}
		default: {
			return state;
		}
	}
};
const allInstitutesInitialState = {
	data: [],
};

export const allInstitutes = (state = allInstitutesInitialState, action) => {
	switch (action.type) {
		case Actions.GET_ALL_INSTITUTES_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.GET_ALL_INSTITUTES_FAIL: {
			return {
				...state,
				data: null,
			};
		}
		default: {
			return state;
		}
	}
};
