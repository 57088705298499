import { useState, useEffect } from "react";
import CustomButton from "../../../components/CustomButton";
import { INTAKE_MONTHS } from "../SearchPage.constants";
import { useNavigate } from "react-router-dom-v5-compat";

export default function IntakeFilter({
  setOpenModal,
  isBlurred,
  filterName,
  updateSearchParams,
  searchParams,
}) {
  let monthParams = searchParams.intake_month;
  if (searchParams.intake_month?.length) {
    monthParams = monthParams.split(",");
  }
  const [selectedMonths, setSelectedMonths] = useState(monthParams);
  const [selectedMonthGroups, setSelectedMonthGroups] = useState(monthParams);
  const [isOpen, setIsOpen] = useState(true);

  const handleSelectIntake = (slug, selectionType) => {
    if (selectionType === "group") {
      setSelectedMonthGroups((prevSelected) => {
        if (prevSelected.includes(slug)) {
          const selectedIntakeEntry = INTAKE_MONTHS.find(
            (entry) => entry.slug === slug
          );
          if (selectedIntakeEntry) {
            const monthSlugs = selectedIntakeEntry.months.map(
              (month) => month.slug
            );
            setSelectedMonths((prevMonths) =>
              prevMonths.filter((month) => !monthSlugs.includes(month))
            );
          }
          return prevSelected.filter((id) => id !== slug);
        } else {
          const selectedIntakeEntry = INTAKE_MONTHS.find(
            (entry) => entry.slug === slug
          );
          if (selectedIntakeEntry) {
            const monthSlugs = selectedIntakeEntry.months.map(
              (month) => month.slug
            );
            setSelectedMonths((prevMonths) => [...prevMonths, ...monthSlugs]);
          }
          return [...prevSelected, slug];
        }
      });
    } else {
      setSelectedMonths((prevSelected) => {
        if (prevSelected.includes(slug)) {
          return prevSelected.filter((id) => id !== slug);
        } else {
          return [...prevSelected, slug];
        }
      });
    }
  };

  const onApply = () => {
    updateSearchParams({
      intake_month: selectedMonths?.join(","),
    });
  };

  useEffect(() => {
    const updatedGroups = INTAKE_MONTHS.map((group) => {
      const groupMonthSlugs = group.months.map((month) => month.slug);
      const allMonthsSelected = groupMonthSlugs.every((monthSlug) =>
        selectedMonths.includes(monthSlug)
      );
      return {
        slug: group.slug,
        allSelected: allMonthsSelected,
        partialSelected:
          !allMonthsSelected &&
          groupMonthSlugs.some((monthSlug) =>
            selectedMonths.includes(monthSlug)
          ),
      };
    });
    const updatedSelectedGroups = updatedGroups
      .map((group) =>
        group.allSelected
          ? group.slug
          : group.partialSelected
          ? `${group.slug}_partial`
          : null
      )
      .filter(Boolean);

    setSelectedMonthGroups(updatedSelectedGroups);
  }, [selectedMonths]);
  return (
    <>
      <div className="filterBox">
        <h6
          style={{ cursor: "pointer" }}
          onClick={() => setIsOpen((isOpen) => !isOpen)}
          className="filterOpen"
        >
          {filterName}
          {isOpen ? (
            <i className="fa fa-angle-down"></i>
          ) : (
            <i className="fa fa-angle-right"></i>
          )}
        </h6>
        {isOpen && (
          <div className="show">
            <>
              <div className="filterBoxScroll">
                {INTAKE_MONTHS.map((monthGroup, index) => (
                  <Intake
                  setOpenModal={setOpenModal}
                   isBlurred={isBlurred}
                    key={index}
                    monthGroup={monthGroup}
                    index={index}
                    selectedMonth={selectedMonths}
                    selectedMonthGrooup={selectedMonthGroups}
                    onSelectIntake={handleSelectIntake}
                  />
                ))}
              </div>
              <div className="text-center pb-2">
                <CustomButton onClick={!isBlurred?onApply:()=>setOpenModal(true)} buttonText="Apply Filter" />
              </div>
            </>
          </div>
        )}
      </div>
    </>
  );
}

const Intake = ({
  setOpenModal,
  isBlurred,
  key,
  monthGroup,
  index,
  selectedMonth,
  onSelectIntake,
  selectedMonthGrooup,
}) => {
  const [intakeArrowClicked, setIntakeArrowClicked] = useState(false);
  const isGroupFullySelected = monthGroup.months.every(month =>
    selectedMonth.includes(month.slug)
  );

  const handleGroupCheckboxChange = (slug) => {
    onSelectIntake(slug, "group");
  };
  return (
    <>
      <div key={key} className="filterItemWrap">
        <div
          id={monthGroup?.name}
          className={"filterItem " + (intakeArrowClicked && "filterOpen")}
        >
          <input
            className={`${
              isGroupFullySelected
                ? "fullyChecked"
                : monthGroup.months.some((obj) =>
                    selectedMonth.includes(obj.slug)
                  )
                ? "childChecked"
                : ""
            }`}
            type="checkbox"
            id={monthGroup.id}
            name={monthGroup.name}
            defaultChecked={selectedMonthGrooup.includes(monthGroup.slug)}
            checked={selectedMonthGrooup.includes(monthGroup.slug)}
            onChange={!isBlurred?() => handleGroupCheckboxChange(monthGroup.slug):()=>setOpenModal(true)}
          />
          <label htmlFor={monthGroup.id}>{monthGroup.name}</label>
          {!intakeArrowClicked ? (
            <i
              className="fa fa-angle-right"
              onClick={() => setIntakeArrowClicked(true)}
            />
          ) : (
            <i
              className="fa fa-angle-down"
              onClick={() => setIntakeArrowClicked(false)}
            />
          )}
        </div>

        <div
          id={monthGroup.name + index}
          className={"filterItemCont " + (intakeArrowClicked && "show")}
        >
          {monthGroup.months.map((month, index) => (
            <div key={index} className="filterItemWrap">
              <div
                id={month?.name}
                className={"filterItem " + (intakeArrowClicked && "filterOpen")}
              >
                <input
                  type="checkbox"
                  id={month.id}
                  name={month.name}
                  defaultChecked={selectedMonth.includes(month.slug)}
                  checked={selectedMonth.includes(month.slug)}
                  onChange={!isBlurred?() => onSelectIntake(month.slug, "month"):()=>setOpenModal(true)}
                />
                <label htmlFor={month.id}>{month.name}</label>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
