import React, { useEffect, useRef, useState, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import "../node_modules/font-awesome/css/font-awesome.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import "bootstrap/dist/js/bootstrap.min.js";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import LoadingBar from "react-top-loading-bar";
import Header from "./app/components/header/header";
import Footer from "./app/components/footer/footer";
import GuardedRoute from "../src/app/routes/GuardedRoutes";
import { http } from "./app/services/apiService/apiService";
import { routes } from "./app/routes/routesAll";
import CourseNew from "./app/pages/course/courses_list_new";
import UniversityDetail from "./app/pages/DetailPages/university_details";
import LoadingSpinner from "./app/components/loader/loader";
import { useDispatch, useSelector } from "react-redux";
import { ory } from "./app/ory/pkg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { setOryUserData } from "./app/store/actions/authActions";
import { CompatRoute, CompatRouter } from "react-router-dom-v5-compat";
import { SnackbarProvider } from "notistack";
import Maintenance from "./app/pages/Maintainance";
import ErrorPage from "./app/pages/Error/404";

const routerrouter = require("./app/routes/demoroute");
const FooterComponent = () => <Footer />;
const HeaderComponent = () => <Header />;

function App() {
  const [route, setRoute] = useState([]);
  const [loader, setLoader] = useState(true);
  const tagManagerArgs = {
    gtmId: "G-TLKSQZT7CC",
  };
  const dispatch = useDispatch();
  const hasAuthSession = useSelector(({ auth }) => auth.isAuthenticated);
  TagManager.initialize(tagManagerArgs);

  const ref = useRef(0);
  const TRACKING_ID = "UA-195353104-1";
  ReactGA.initialize(TRACKING_ID);
  const history = createBrowserHistory();
  const browserHistory = useHistory();

  // Initialize google analytics page view tracking
  history.listen((location, action) => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname + location.search); // Record a pageview for the given page
  });
  // const [isAuthenticated, setIsAuthenticated] = useState(() => {
  //     if (localStorage.getItem("user_Id")) {
  //         return true;
  //     } else {
  //         return false;
  //     }
  // })

  useEffect(() => {
    http.get("/cms/routing/").then((d) => {
      if (d?.data) {
        const { data } = d;
        let dataRoutes = [];
        dataRoutes = routes?.map((person) => {
          const addressItem = data?.find(
            (component) => component?.page_type === person?.page_type
          );
          person = {
            ...person,
            component: person?.component,
            // auth: person?.auth,
            auth: hasAuthSession,
            afterAuthRoute: person?.afterAuthRoute,
            slug: person.route
              ? person.route
              : addressItem?.page_type === "landing"
              ? "/"
              : "/" +
                (person?.slugId
                  ? addressItem.slug + person?.slugId
                  : addressItem.slug),
          };
          return person;
        });
        setLoader(false);
        setRoute(dataRoutes);
      }
    });
  }, []);
  // useEffect(() => {
  //     ReactGA.pageview(window.location.pathname + window.location.search);
  //     setIsAuthenticated(() => {
  //         if (localStorage.getItem("user_Id")) {
  //             return true;
  //         } else {
  //             return false;
  //         }
  //     })// Record a pageview for the given page
  // }, [window.location]);
  useEffect(() => {
    // caches.keys().then((names) => {
    //   for (const name of names) {
    //     caches.delete(name);
    //   }})
    // let name = 'Time-Cone-Frontend'
    // let version = '1.0.0'
    // console.log(`${name} v${version} 😎`)
    // const last_version = localStorage.getItem(`${name}-Version`)
    // if(last_version !== version){
    //     console.log('New Version Available ! 😝')
    //     localStorage.setItem(`${name}-Version`, version)
    //     window.location.reload(true);
    // }
    ref.current.continuousStart();
    setTimeout(() => {
      ref.current?.complete();
    }, 2500);
  }, [ref]);

  useEffect(() => {
    if (!hasAuthSession) {
      ory
        .toSession()
        .then(({ data }) => {
          dispatch(setOryUserData(data));
        })
        .catch((err) => {
          switch (err.response?.status) {
            case 403:
            // This is a legacy error code thrown. See code 422 for
            // more details.
            case 422:
              // This status code is returned when we are trying to
              // validate a session which has not yet completed
              // its second factor
              return browserHistory.push("/login?aal=aal2");
            case 401:
              // do nothing, the user is not logged in
              return;
          }

          // Something else happened!
          return Promise.reject(err);
        });
    }
  }, []);

  return (
    <>
      <Router history={history}>
        <CompatRouter>
          <Suspense fallback={<LoadingSpinner height={"100vh"} />}>
            <div>
              <LoadingBar color="#F9B316" ref={ref} />
              <SnackbarProvider maxSnack={3}>
                <HeaderComponent />

                <Switch>
                  {/* Place specific routes first */}
                  <Route path={"/course_new"} component={CourseNew} />
                  {loader ? (
                    <LoadingSpinner height={"100vh"} />
                  ) : (
                    route?.length &&
                    route?.map((d, key) =>
                      d?.component ? (
                        d?.afterAuthRoute ? (
                          <GuardedRoute
                            key={key}
                            path={d.slug}
                            auth={hasAuthSession}
                            component={d?.component}
                          />
                        ) : (
                          <CompatRoute
                            exact
                            key={key}
                            path={d.slug}
                            component={d?.component}
                          />
                        )
                      ) : null
                    )
                  )}
                  <Route
                    path={"/university-details/:slug/:campusSlug"}
                    component={UniversityDetail}
                    exact
                  />
                  <Route
                    path={"/university-details/:slug"}
                    component={UniversityDetail}
                    exact
                  />
                  {/* Place the catch-all route last */}
                  <Route path="*" component={ErrorPage} />
                </Switch>

                <FooterComponent />
              </SnackbarProvider>
            </div>
          </Suspense>
        </CompatRouter>
      </Router>
    </>
  );
}

export default App;
