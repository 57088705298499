import React, {memo, useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import $ from "jquery";
import {useSelector} from "react-redux";
import LoadingSpinner from "../../loader/loader";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";
import {http} from "../../../services/apiService/apiService";
import CourseApplyForm from "../../forms/apply_form";
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GridViewDataRendering from "./GridViewRendering"

import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookMessengerShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton,
} from "react-share";
import Pagination from "react-responsive-pagination";
import {Alert, Drawer} from "@mui/material";
import { getSingleRoute } from "../../../../common/utils";
import { slugSet } from "../../../common/getQueryString";

let selectedCourse = [];

function CourseGridCardView(props) {
    const {courses} = props;
    const history = useHistory();
    const marketingCard = useSelector(({apis}) => apis.allMarketingCards.data);
    const [applyCourse, setApplyCourse] = useState(null);
    const [relevantCourses, setRelevantCourses] = useState([]);
    const [institute, setInstitute] = useState(null);
    const [newCourses, setNewCourses] = useState();
    const [fav_Course, setFavCourse] = useState(
        JSON.parse(localStorage.getItem("fav_course"))
    );
    const [pageState, setPageState] = useState({
        offset: 0,
        perPage: 27,
        currentPage: 1,
        pageCount: 0,
    });
    const [relevantLoading, setRelevantLoading] = useState(true);
    const [relatedCoursesList, setRelatedCoursesList] = useState(null);
    const [url, setUrl] = useState(window.location.href);
    const [openModal, setOpenModal] = useState(false)
    const [drawerMessage, setDrawerMessage] = useState("")
    const [compareCourses, setCompareCourses] = useState([]);
    // let Data = [];
    // useEffect(() => {
    //     setFavCourse(JSON.parse(localStorage.getItem("fav_course")));
    //     if (marketingCard.results) {
    //         Data = [];
    //         if (courses.length >= 21) {
    //             Data = [...new Map(courses.map(item => [JSON.stringify(item), item])).values()];
    //             marketingCard.results.sort((a, b) => a.priority - b.priority);
    //             let j = 0;
    //             let index = 6;
    //             for (let i = 0; i < marketingCard.results.length; i++) {
    //                 if (marketingCard.results[i].priority === j + 1) {
    //                     if (i <= 2) {
    //                         Data.splice(index, 0, marketingCard.results[i])
    //                         index = index + 7 + 1;
    //                     }
    //                 }
    //                 j = j + 1;
    //             }
    //             setNewCourses(Data);
    //         }
    //     }
    // }, [marketingCard.results]);
    let index = 6;
    useEffect(() => {
        setFavCourse(JSON.parse(localStorage.getItem("fav_course")));
        // if (marketingCard.results) {
        //     if (courses.length >= 21) {
        //         marketingCard.results.sort((a, b) => a.priority - b.priority);
        //         let j = 0;
        //         for (let i = 0; i < marketingCard.results.length; i++) {
        //             if (marketingCard.results[i].grid_card_img_back) {
        //                 if (i <= 2) {
        //                     courses.splice(index, 0, marketingCard.results[i])
        //                 }
        //                 index = index + 7;
        //             }
        //             j = j + 1;
        //         }
        //         let Data = [];
        //         Data = [...new Map(courses.map(item => [JSON.stringify(item), item])).values()];
        //         setNewCourses(Data);
        //     }
        // }
    }, [props]);
    const commaSeparators = (num) => {
        let numParts = num.toString().split(".");
        numParts[0] = numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return numParts.join(".");
    };

    function clickApplyForm(course) {
        if (localStorage.getItem("user_Id")) {
            setApplyCourse(course);
            localStorage.setItem("ApplyCourseModal", "true");
            $("#applyModal").modal("show");
        } else {
            $("#login").modal("show");
        }
    }

    let data = [];

    const handleModalOpen = (item) => {
        localStorage.setItem("ShareModal", "true");
        $("#share").modal("show");
        setUrl(
            `https://timescoursefinder.com/course-detail?${$.param({
                course_id: item.id,
                course_name: item.name,
            })}`
        );
    };

    async function onClickMoreRelevantCourses(institute, courses) {
        setRelevantLoading(true);
        setInstitute(institute);
        setRelatedCoursesList(courses);
        $("#MoreCoursesModal").modal("show");

        await http.post(`course/courses_by_ids/`, data = {'courses_ids': courses}).then((res) => {

            pageState.pageCount = Math.ceil(res.data.count / pageState.perPage);
            pageState.offset = pageState.current * pageState.perPage;
            setPageState(pageState);
            setRelevantCourses(res?.data?.results);
            setRelevantLoading(false);
        })
    }

    function handlePageClick(page_number) {
        let selectedPage = page_number;
        const offset = selectedPage * pageState.perPage;
        pageState.currentPage = selectedPage;
        pageState.offset = offset;
        setPageState(pageState);
        setRelevantLoading(true);
        http.post(`course/courses_by_ids/?page=${page_number}`, data = {'courses_ids': relatedCoursesList}).then((res) => {
            pageState.pageCount = Math.ceil(res.data.count / pageState.perPage);
            pageState.offset = pageState.current * pageState.perPage;
            setPageState(pageState);
            setRelevantCourses(res?.data?.results);
            setRelevantLoading(false);
        })
    }

    const openDrawer = (course) => {
        if (selectedCourse.length === 3) {
            setDrawerMessage("You can compare 3 courses ")
            setOpenModal(true);
        } else {
            if (selectedCourse.length > 1) {
                const status = selectedCourse.some((element) => {
                    return element.id === course.id;
                });
                if (status) {
                    toast.error("Course Already selected")
                    setOpenModal(false);
                } else {
                    setOpenModal(true);
                }
            }
            selectedCourse.push({id: course.id,slug: course.slug, name: course.name, logo: course.institute.logo})
            setOpenModal(true);
            setDrawerMessage("");

        }
        const ids = selectedCourse.map(o => o.id)
        selectedCourse = selectedCourse.filter(({id}, index) => !ids.includes(id, index + 1))
        setCompareCourses(selectedCourse);

    }
    const toggleDrawer = () => () => {
        setOpenModal(false);
        setDrawerMessage("");
    };
    const removeSelectedCourse = (e, course) => {
        e.preventDefault();
        setDrawerMessage("");
        selectedCourse = selectedCourse.filter(item => item.id !== course.id);
        if (selectedCourse.length === 0) {
            setOpenModal(false);
        }
        setCompareCourses(selectedCourse);
    }
    function navigateToCompare() {
        if (selectedCourse.length === 2) {
            history.push({
                pathname: "/compare-course",
                search: $.param({
                    crs1: selectedCourse[0]?.slug,
                    crs2: selectedCourse[1]?.slug,
                }),
            })
        } else {
            history.push({
                pathname: "/compare-course",
                search: $.param({
                    crs1: selectedCourse[0]?.slug,
                    crs2: selectedCourse[1]?.slug,
                    crs3: selectedCourse[2]?.slug,
                }),
            })
        }

    }
    useEffect(()=>{
    },[courses])
    const getRoute = (page, id) => {
        getSingleRoute(page).then(d => {
            history.push({
                pathname: d,
                search: id?.search?id?.search:'',
            });
        })
    }
    return (
        <div className="courseFlex">
            <ToastContainer
                position="top-center"
                autoClose={700}
                hideProgressBar={true}
                newestOnTop={false}
                rtl={false}
                pauseOnFocusLoss={false}
                pauseOnHover={false}
            />
            {courses ? courses.map((item, index) => {
                if (item) {
                    return (
                        <GridViewDataRendering
                            key={index}
                            item={item}
                            handleModalOpen={handleModalOpen}
                            onClickApplyForm={clickApplyForm}
                            commaSeparators={commaSeparators}
                            relevantCoursesClick={onClickMoreRelevantCourses}
                            openDrawer={openDrawer}
                            data={data}
                            oldFav={fav_Course}
                        />
                    );
                }
                // 		{/*{item.courses.length >= 2 ? (*/}
                // 		{/*	<p className="relevantP">*/}
                // 		{/*		<a*/}
                // 		{/*			href*/}
                // 		{/*			onClick={(e) => {*/}
                // 		{/*				onClickMoreRelevantCourses(item.institute, item);*/}
                // 		{/*			}}*/}
                // 		{/*		>*/}
                // 		{/*			{item.courses.length - 1} relevant programs also available**/}
                // 		{/*		</a>*/}
                // 		{/*	</p>*/}
                // 		{/*) : null}*/}
            }) :
            //     courses?.map((item, index) => {
            //     if (item) {
            //         return (
            //             <GridViewDataRendering
            //                 key={index}
            //                 item={item}
            //                 handleModalOpen={handleModalOpen}
            //                 onClickApplyForm={clickApplyForm}
            //                 commaSeparators={commaSeparators}
            //                 relevantCoursesClick={onClickMoreRelevantCourses}
            //                 openDrawer={openDrawer}
            //                 data={data}
            //                 oldFav={fav_Course}
            //             />
            //         );
            //     }
            // })
                null
            }
            <div
                className="modal fade"
                id="share"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="shareLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="shareModalLabel">
                                Share via
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    localStorage.removeItem("ShareModal");
                                }}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <FacebookMessengerShareButton
                                appId={"867455750856341"}
                                redirectUri={url}
                                clientId={"102"}
                                title="Tap the Link below to open website "
                            >
                                <FacebookIcon size={"2rem"}/>
                            </FacebookMessengerShareButton>
                            {"   "}
                            <EmailShareButton
                                subject={"Course Detail"}
                                body={"Click the given link " + url + " ."}
                            >
                                <EmailIcon size={"2rem"}/>
                            </EmailShareButton>
                            {"   "}
                            <WhatsappShareButton
                                url={url}
                                title={"Tap the Link below to open the website"}
                            >
                                <WhatsappIcon size="2rem"/>
                            </WhatsappShareButton>
                            {"   "}
                            <TwitterShareButton
                                url={url}
                                title="Tap the Link below to open website"
                            >
                                <TwitterIcon size="2rem"/>
                            </TwitterShareButton>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="modal signinModal fade"
                id="applyModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="applyModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                id="login_modal_close_btn"
                                aria-label="Close"
                                onClick={() => {
                                    localStorage.removeItem("ApplyCourseModal");
                                }}
                            >
                                <img
                                    src={
                                        require("../../../../static/images/modal-times.svg").default
                                    }
                                    alt="banner"
                                />
                            </button>
                            <CourseApplyForm course={applyCourse}/>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="modal fade coursesModal"
                id="MoreCoursesModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="applyModalLabel"
                aria-hidden="true"
                data-backdrop="static"
                data-keyboard="false"
            >
                <div className="modal-dialog modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="requestInfoModalLabel">
                                Relevant Courses of {institute?.institute_name}
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">

                            <div className="courseFlex">
                                {relevantLoading === true ? <LoadingSpinner/> :
                                    relevantCourses.map((item) => (
                                        <div className="courseBox" id={item.id}>
                                            <div className="imgWrap">
                                                {/* <img
                                                    src={
                                                        require("../../../../static/images/course-img.svg")
                                                            .default
                                                    }
                                                    alt="course-img"
                                                /> */}
                                                <div className="courseBoxTop">

                                                    <button
                                                    onClick={()=>getRoute('university_details',{
										id:`${slugSet(item.slug)}${slugSet(item.campus_slug)}`
										// id:`${slugSet(item.slug)}${slugSet(item.course_slug)}${slugSet(item.degree_level_slug)}${slugSet(item.discipline_slug)}${slugSet(item.course_slug)}${slugSet(item.campus_slug)}`
                                                    })}
                                                        // to={{
                                                        //     pathname: "/university-details/",
                                                        //     search: $.param({
                                                        //         institute_id: institute.id,
                                                        //         institute_name: institute.institute_name,
                                                        //         campus_id: item.campus.id,
                                                        //         campus_name: item.campus.name,
                                                        //     }),
                                                        // }}
                                                    >
                                                        <img
                                                                
                                                            src={institute.logo}
                                                            alt={institute.institute_name}
                                                        />
                                                    </button>
                                                    <div>
                                                        <button
                                                        onClick={()=>getRoute('university_details',{
										id:`${slugSet(item.slug)}${slugSet(item.campus_slug)}`
										// id:`${slugSet(item.slug)}${slugSet(item.course_slug)}${slugSet(item.degree_level_slug)}${slugSet(item.discipline_slug)}${slugSet(item.course_slug)}${slugSet(item.campus_slug)}`
                                                        })}
                                                            // to={{
                                                            //     pathname: "/university-details/",
                                                            //     search: $.param({
                                                            //         institute_id: institute.id,
                                                            //         institute_name: institute.institute_name,
                                                            //         campus_id: item.campus.id,
                                                            //         campus_name: item.campus.name,
                                                            //     }),
                                                            // }}
                                                        >
                                                            <h6>{institute.institute_name}</h6>
                                                        </button>
                                                        <p>{item.campus.name}</p>
                                                    </div>
                                                </div>
                                                <div className="btnGroup">
                                                    <button
                                                        onClick={() => handleModalOpen(item.id)}
                                                        className="btn"
                                                        data-dismiss="modal"
                                                    >
                                                        <img
                                                            src={
                                                                require("../../../../static/images/share-purple.svg")
                                                                    .default
                                                            }
                                                            alt="share-purple"
                                                        />
                                                    </button>
                                                    <button className="btn">
                                                        <img
                                                            src={
                                                                require("../../../../static/images/fav-purple.svg")
                                                                    .default
                                                            }
                                                            alt="fav-purple"
                                                        />
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="courseBoxCont">
                                                <h6>
                                                    <b>{item.name}</b>
                                                </h6>
                                                <p>
                                                    <img
                                                        src={
                                                            require("../../../../static/images/Specialization1.svg")
                                                                .default
                                                        }
                                                        alt="Specialization1"
                                                    />
                                                    {/* {item.discipline} */}
                                                    {item.specialization}
                                                </p>

                                                <div>
                                                    <div>
                                                        <p>
                                                            <img
                                                                src={
                                                                    require("../../../../static/images/bachelor_honors.svg")
                                                                        .default
                                                                }
                                                                alt="bachelor_honors"
                                                            />
                                                            {item.degree_level} | {item.course_title}
                                                        </p>
                                                        <p>
                                                            <img
                                                                src={
                                                                    require("../../../../static/images/on_campus.svg")
                                                                        .default
                                                                }
                                                                alt="on_campus"
                                                            />
                                                            On Campus
                                                        </p>
                                                        <p>
                                                            <img
                                                                src={
                                                                    require("../../../../static/images/Amount.svg")
                                                                        .default
                                                                }
                                                                alt="Amount"
                                                            />
                                                            {item.course_fee.length >= 1
                                                                ? (item.course_fee[0]?.converted_value
                                                                    ? commaSeparators(
                                                                        item.course_fee[0]?.converted_value.toFixed(0)
                                                                    ) +
                                                                    " " +
                                                                    item.course_fee[0]?.currency?.converted_currency
                                                                    : commaSeparators(
                                                                        item.course_fee[0]?.ceil_value
                                                                    ) +
                                                                    " " +
                                                                    item.course_fee[0]?.currency?.display_name) +
                                                                "/Year"
                                                                : item.item.base_fee === 0 && item.item.course_fee === 0 ? "To Be Confirmed" : "Free Education"}
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p>
                                                            <img
                                                                src={
                                                                    require("../../../../static/images/Years.svg")
                                                                        .default
                                                                }
                                                                alt="Years"
                                                            />
                                                            {item.course_duration}
                                                        </p>
                                                        <p>
                                                            <img
                                                                src={
                                                                    require("../../../../static/images/English.svg")
                                                                        .default
                                                                }
                                                                alt="English"
                                                            />
                                                            {item.course_language}
                                                        </p>
                                                        <p>
                                                            <img
                                                                src={
                                                                    require("../../../../static/images/full_time.svg")
                                                                        .default
                                                                }
                                                                alt="full_time"
                                                            />
                                                            Full Time
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="courseBoxFooter">
                                                    <div className="btnGroup">
                                                        <button
                                                            className="btn"
                                                            onClick={()=>getRoute('course_detail',{
                                                                search: $.param({
                                                                    course_id: item.id,
                                                                    course_name: item.name,
                                                                }),
                                                            })}
                                                            // to={{
                                                            //     pathname: "/course-detail",
                                                            //     search: $.param({
                                                            //         course_id: item.id,
                                                            //         course_name: item.name,
                                                            //     }),
                                                            // }}
                                                        >
                                                            Discover
                                                        </button>
                                                        <button
                                                            className="btn"
                                                            onClick={() => openDrawer(item)}
                                                        >
                                                            Compare
                                                        </button>
                                                        <button
                                                            className="btn"
                                                            data-dismiss="modal"
                                                            onClick={() => clickApplyForm(item)}
                                                        >
                                                            Apply
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            <div className="paginationWrap">
                                <Pagination
                                    current={pageState.currentPage}
                                    total={pageState.pageCount}
                                    onPageChange={handlePageClick}
                                    previousLabel={"Prev"}
                                    nextLabel={"Next"}

                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* edit modal */}
            <div
                className="modal fade signinModal editModal"
                id="editModal"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                id="login_modal_close_btn"
                                aria-label="Close"
                                onClick={() => {
                                    localStorage.removeItem("EditModal");
                                }}
                            >
                                <img
                                    src={
                                        require("../../../../static/images/modal-times.svg").default
                                    }
                                    alt="modal-times"
                                />
                            </button>
                            <h>Suggest an Edit or Post Review</h>
                            <p>
                                Studied or Worked here? Share your Review or suggest editing
                            </p>
                            <form>
                                <div className="row">
                                    <div className="col-md">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                placeholder="First Name"
                                                className="form-control"
                                            />
                                            <img
                                                src={
                                                    require("../../../../static/images/forms/full_name.svg")
                                                        .default
                                                }
                                                alt="full_name"
                                                height=""
                                                width=""
                                                className="formIcon"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                placeholder="Last Name"
                                                className="form-control"
                                            />
                                            <img
                                                src={
                                                    require("../../../../static/images/forms/full_name.svg")
                                                        .default
                                                }
                                                alt="full_name"
                                                height=""
                                                width=""
                                                className="formIcon"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md">
                                        <div className="form-group">
                                            <Select
                                                name="filters"
                                                placeholder={"Phone Type"}
                                                isSearchable={false}
                                                theme={(theme) => ({
                                                    ...theme,
                                                })}
                                            />

                                            <img
                                                src={
                                                    require("../../../../static/images/forms/phone.svg")
                                                        .default
                                                }
                                                alt="phone"
                                                height=""
                                                width=""
                                                className="formIcon"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md">
                                        <div className="form-group">
                                            <PhoneInput
                                                autoFormat
                                                country={"pk"}
                                                placeholder="Enter the Number"
                                                enableAreaCodes="true"
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <input
                                        type="email"
                                        placeholder="Email Address"
                                        className="form-control"
                                    />
                                    <img
                                        src={
                                            require("../../../../static/images/forms/Email.svg").default
                                        }
                                        alt="Email"
                                        height=""
                                        width=""
                                        className="formIcon"
                                    />
                                </div>

                                <div className="form-group">
									<textarea
                                        placeholder="Comment"
                                        className="form-control"
                                    />
                                    <img
                                        src={
                                            require("../../../../static/images/forms/comment.svg")
                                                .default
                                        }
                                        alt="comment"
                                        height=""
                                        width=""
                                        className="formIcon"
                                    />
                                </div>
                            </form>
                            <h6>Please do not post: </h6>
                            <ul>
                                <li>Aggressive or discriminatory language</li>
                                <li>Profanities</li>
                                <li>Trade secrets or confidential information</li>
                            </ul>
                            <p>
                                We appreciate you for doing your part to Keep Times Course
                                Finder most trusted education portal
                            </p>
                            <div className="btnGroup">
                                <button className="btn defaultBtn">Add Review</button>
                                <button className="btn primaryBtn">Suggest Editing</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <Drawer
                    anchor={'bottom'}
                    open={openModal}
                >
                    <div style={{textAlign: "center", padding: "20px", maxHeight: "600px", background: "#f5f5f5"}}>
                        <button
                            type="button"
                            className="close"
                            aria-label="Close"
                            onClick={toggleDrawer()}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <h5>You can compare upto 3 Universities</h5>
                        {drawerMessage !== "" ? <Alert severity={"error"}>{drawerMessage}</Alert> : null}

                        <div style={{
                            display: "flex",
                            justifyContent: "space-evenly", flexDirection: "row",
                            flexWrap: "wrap",
                            alignItems: "center",
                            padding: "50px"
                        }}>

                            {compareCourses.length ? compareCourses.map((item) => (
                                <div style={{
                                    width: "300px",
                                    background: "#E9DEFA"
                                }}>

                                    <button
                                        style={{display: "block"}}
                                    >
                                        <div style={{display: "block", padding: "8px"}}>
                                            <button
                                                // type="button"
                                                className="close"
                                                style={{
                                                    float: "none",
                                                    display: "block",
                                                    color: "#ff0303",
                                                    width: "max-content",
                                                    marginLeft: "auto",
                                                }}
                                                onClick={(e) => removeSelectedCourse(e, item)}
                                            >
                                                <span className={"fa fa-trash"} aria-hidden="true"/>
                                            </button>
                                            <img
                                                style={{height: "100px", width: "100px"}}
                                                src={item.logo}
                                                alt={item.logo}
                                            />
                                        </div>
                                        <h6 style={{
                                            borderTop: "3px solid rebeccapurple",
                                            fontSize: "12px",
                                            margin: "0px",
                                            padding: "15px 10px",
                                            background: "#fff"
                                        }}>{item.name}</h6>
                                    </button>

                                </div>
                            )) : "No record Found"}
                            {selectedCourse.length > 1 ? <button style={{
                                fontSize: "14px",
                                background: "#ffb100",
                                color: "white",
                                padding: "14px 12px",
                                borderRadius: "4px",
                                border: "0px"

                            }} onClick={navigateToCompare}>Compare Course
                            </button> : null}
                        </div>


                    </div>
                </Drawer>
            </div>
        </div>
    );
}

export default CourseGridCardView
