import axios from "axios";

export const BASE_URL = process.env.REACT_APP_BASE_URL;

var headers = {'Content-Type': "application/json"};
var formDataHeaders = {"Content-Type": "multipart/form-data"}

export const http = axios.create({
	baseURL: BASE_URL,
	headers: headers,
});

export const CRM_BASE_URL = process.env.REACT_APP_CRM_BASE_URL;


export const httpCrm = axios.create({
	baseURL: CRM_BASE_URL,
	headers: headers,
	withCredentials: true
});

export const httpCrmFormData = axios.create({
	baseURL: CRM_BASE_URL,
	headers: formDataHeaders,
});

export function callGetApi(url,success,fail) {
	const request = http.get(url);

	return dispatch =>
		request.then(response =>
			dispatch({
				type: success,
				payload: response.data
			})
		).catch(error => {
			return dispatch({
				type: fail,
				payload: []
			});
		});
}
