import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../../components/loader/loader";
import {
	GoogleMap,
	useLoadScript,
	Marker,
	InfoWindow,
	MarkerClusterer,
} from "@react-google-maps/api";
import { getSingleRoute } from "../../../common/utils";
import { slugSet } from "../../common/getQueryString";



const mapContainerStyle = {
	height: "100vh",
	width: "100%",

};

const options = {
	disableDefaultUI: true,
	zoomControl: true,
	// imagePath:
	//     'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m', // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
};
const clusterIconStyle = [{ textColor: 'yellow', fontWeight: 500, url: "/cluster-images/m3.png", fontSize: '50px' }]
const clusterOptions = {
	imagePath: "/cluster-images/m",



};

const CourseListMap = (institutesLocation) => {
	const [center, setCenter] = useState({
		lat: institutesLocation?.institutesLocation[0]?.campus.latitude,
		lng: institutesLocation?.institutesLocation[0]?.campus.longitude,
	});

	const { isLoaded, loadError } = useLoadScript({
		googleMapsApiKey: "AIzaSyC0MGZPpZYu79oFwRFCG0yu-IfQHyBPTnM",
	});
	const history = useHistory()
	const dispatch = useDispatch();

	const [selected, setSelected] = React.useState(null);
	const [selectedHover, setSelectedHover] = React.useState(null);

	const [isHover, setHover] = useState(false);

	const instituteLogo = (location) => {
		if (location?.institute?.logo) {
			return "https://times-con.s3.us-east-2.amazonaws.com/map/Marker+02.png";
		} else {
			return "https://times-con.s3.us-east-2.amazonaws.com/map/Marker+02.png";
		}
	};
	const [zoom, setZoom] = useState(2);

	if (loadError) return "Error";
	if (!isLoaded) return (<LoadingSpinner />);
	return (
		<div>
			<GoogleMap
				id="map"
				mapContainerStyle={mapContainerStyle}
				zoom={zoom}
				center={center}
				options={options}
			// styles={clusterIconStyle}
			// onClick={onMapClick}
			// onLoad={onMapLoad}
			>
				<MarkerClusterer options={clusterOptions}>
					{(clusterer) =>
						institutesLocation?.institutesLocation?.map((marker) => (
							<Marker
								key={marker.id}
								position={{
									lat: marker?.campus?.latitude,
									lng: marker?.campus?.longitude,
								}}
								onMouseOver={() => {
									setSelectedHover(marker);
									setHover(true);

								}}
								onMouseOut={() => {
									setHover(false);
									setSelectedHover(null);

								}}
								onClick={() => {
									setSelected(marker);
									setHover(false);

									setZoom(9);
								}}
								icon={{
									url: instituteLogo(marker),
									origin: new window.google.maps.Point(0, 0),
									anchor: new window.google.maps.Point(15, 15),
									scaledSize: new window.google.maps.Size(30, 30),
								}}
								clusterer={clusterer}
							/>
						))
					}
				</MarkerClusterer>
				{selected ? (
					<InfoWindow
						position={{
							lat: selected.campus.latitude,
							lng: selected.campus.longitude,
						}}
						onCloseClick={() => {
							setSelected(null);
							setZoom(3);
						}}
					>
						<div style={{ textAlign: "center" }}>
							<span role="img" aria-label="bear">
								<img
									src={selected.institute.logo}
									alt={selected.institute.institute_name}
									width="70px"
									height="70px"
								/>
							</span>
							<h6>{selected.institute.institute_name}</h6>
							<button
								onClick={() => getSingleRoute('university_details').then(d => {
									history.push({
										pathname: d+`${slugSet(selected.institute_slug)}${slugSet(selected.campus.slug)}`,
										// search: $.param({
										// 	institute_id: selected.institute.id,
										// 	institute_name: selected.institute.institute_name,
										// })
									})
								})}
								// to={{
								// 	pathname: "/university-details/",
								// 	search: $.param({
								// 		institute_id: selected.institute.id,
								// 		institute_name: selected.institute.institute_name,
								// 	}),
								// }}
								className="btn"
								style={{
									backgroundColor: "#f9b316",
									color: "white",
								}}
							>
								Explore All Programs
							</button>
						</div>
					</InfoWindow>
				) : null}
				{isHover ? (
					<InfoWindow
						onMouseOut={() => {
							setSelectedHover(null);
							setHover(false);
						}}
						position={{
							lat: (selectedHover.campus.latitude),
							lng: (selectedHover.campus.longitude),
						}}

					>
						<div>
							<h6>{selectedHover.institute.institute_name}</h6>
						</div>
					</InfoWindow>
				) : null}
			</GoogleMap>
		</div>
	);
};

export default CourseListMap;
