import * as Actions from "../actions/currency";

const searchCurrencyInitialState = {
    data: [],
};

export const currencies = (state = searchCurrencyInitialState, action) => {
    switch (action.type) {
        case Actions.GET_ALL_CURRENCY_SUCCESS: {
            return {
                ...state,
                data: action.payload,
            };
        }
        case Actions.GET_ALL_CURRENCY_FAIL: {
            return {
                ...state,
                data: null,
            };
        }
        default: {
            return state;
        }
    }
};
