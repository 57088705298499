import * as Actions from "../actions/dynamic_pages.action";
const dynamicPagesTypeInitialState = {
	data: [],
};

export const getAllDynamicPagesByType = (
	state = dynamicPagesTypeInitialState,
	action
) => {
	switch (action.type) {
		case Actions.DYNAMIC_PAGES_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.DYNAMIC_PAGES_FAIL: {
			return {
				...state,
				data: null,
			};
		}
		default: {
			return state;
		}
	}
};

const dynamicPageByIdInitialState = {
	data: [],
};

export const getDynamicPageById = (
	state = dynamicPageByIdInitialState,
	action
) => {
	switch (action.type) {
		case Actions.DYNAMIC_PAGE_BY_ID_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.DYNAMIC_PAGE_BY_ID_FAIL: {
			return {
				...state,
				data: null,
			};
		}
		default: {
			return state;
		}
	}
};
