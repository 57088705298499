import { http } from "../../../services/apiService/apiService";

//Top words

export const GET_ALL_TOP_WORDS_SUCCESS = "GET_ALL_TOP_WORDS_SUCCESS";
export const GET_ALL_TOP_WORDS_FAIL = "GET_ALL_TOP_WORDS_FAIL";

export function getAllTopWords() {
    const url = "v2/search/topkeywords";
    const request = http.get(url);

    return (dispatch) =>
        request
            .then((response) =>
                dispatch({
                    type: GET_ALL_TOP_WORDS_SUCCESS,
                    payload: response.data,
                })
            )
            .catch((error) => {
                return dispatch({
                    type: GET_ALL_TOP_WORDS_FAIL,
                    payload: [],
                });
            });
}

