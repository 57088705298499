import { http, httpCrmFormData } from "./apiService";

export const getCountryFilterDataReq = () => {
  return http.get(`/v2/search/locations/`);
};

export const getSpecializationFilterReq = (params) => {
  return http.get(`/v2/search/specializations_v2/`, { params });
};

export const searchCourseReq = (params) => {
  return http.get(`/v2/search/courses_v2/`, { params });
};

// export const searchCourseReq = (params) => {
//     return http.post(`/course/course_search/`);
// };

export const getAllCurrenciesReq = () => {
  return http.get(`/v2/search/currency/`);
};

export const getDegreeLevelsReq = () => {
  return http.get(`/v2/search/degree_levels/`);
};

export const getDisciplinesReq = () => {
  return http.get(`/v2/search/disciplines/`);
};
export const getInstituteNamesFilterReq = (params) => {
  return http.get(`/v2/search/institutes_v2/`, { params });
};

export const getDisciplineSpecializationReq = (discipline) => {
  return http.get(`/discipline/${discipline}/specializations/`);
};

export const searchCourseCountReq = (params) => {
  return http.get(`/v2/search/courses_count_v2/`, { params });
};

export const maxFeeReq = (params) => {
  return http.get(`/v2/search/fee_range_max/`, { params });
};

/* Add to favourite */
export const addToFavouriteReq = (body) => {
  return http.post(`/v2/search/add_favourite`, body, { withCredentials: true });
};

export const getFavouriteCoursesReq = () => {
  return http.get(`/v2/search/add_favourite`, { withCredentials: true });
};

/* CRM APIs */
export const getAuthCookie = () => {
  return http.get(`/session_cookie`, { withCredentials: true });
};

export const getUserApplications = (params) => {
  return httpCrmFormData.get(`TcfGetAppList.php`, { params });
};

export const getUserQuotes = (params) => {
  return httpCrmFormData.get(`TcfGetQuotesList.php`, { params });
};

export const getUserVisas = (params) => {
  return httpCrmFormData.get(`TcfGetVisaList.php`, { params });
};

export const getSyncUserWithCrm = (params) => {
  return httpCrmFormData.get(`TcfUsersSync.php`, { params });
};


export const getUserInterestsReq = () => {
  return http.get("v2/search/user_interests/", { withCredentials: true });
};
export const userInterestsReq = (payload) => {
  return http.post("v2/search/user_interests/", payload, {
    withCredentials: true,
  });
};
export const getAllCountriesReq = () => {
  return http.get("country/all-countries/");
};
