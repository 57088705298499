import { useEffect, useState } from "react";
import CustomButton from "../../../components/CustomButton";
import React from 'react';
import Slider from "@material-ui/core/Slider";
import { maxFeeReq } from "../../../services/apiService";
import { lowerCase } from "lodash";
import { useNavigate } from "react-router-dom-v5-compat";

const FeeRangeFilter = ({ updateSearchParams, searchParams,isBlurred,setOpenModal }) => {
    const [feeRangeSliderValue, setFeeRangeSliderValue] = useState([0, 50000]);
    const [feeMarks, setFeeMarks] = useState([
        { value: 0, label: "0" },
        { value: 500000, label: "500000" },
    ]);
    const [isOpen, setIsOpen] = useState(true);
    const [maxFee, setMaxFee] = useState(5000)

    const navigate= useNavigate();
    
    const handleFeeSlider = (e, value) => {
        const updatedMarks = [
            {
                value: value[0],
                label: value[0].toString(),
            },
            {
                value: value[1],
                label: value[1].toString(),
            },
        ];

        setFeeRangeSliderValue(value);
        setFeeMarks(updatedMarks);
    };

    const handleApplySelection = () => {
        updateSearchParams({ fee_range: feeRangeSliderValue.join(',') });
    }

    const getMaxFee = async () => {
      let params = {
        discipline: searchParams?.discipline,
        currency: lowerCase(searchParams?.currency),
        search: searchParams?.search
      }

      const res = await maxFeeReq(params);
      const { data } = res;

      setMaxFee(data.max_fee)
      handleFeeSlider(null, [0, data.max_fee]) //we are passing null in place of event because it is used by mui slider, will throw error if we don't use
    }
    useEffect(() => {
        getMaxFee();
      }, [searchParams.discipline, searchParams.currency]);
    
    return (
        <>
            <div className="filterBox">
                <h6
                    style={{ cursor: "pointer" }}
                    onClick={() => setIsOpen((isOpen) => !isOpen)}
                    className="filterOpen"
                >
                    Fee Range ({searchParams?.currency ? searchParams.currency : "USD" })
                    {isOpen ? (
                        <i className="fa fa-angle-down"></i>
                    ) : (
                        <i className="fa fa-angle-right"></i>
                    )}
                </h6>
                {isOpen && (
                    <div className="content show">
                        <div className="filterBoxScroll">
                            <div className="rangeSlider">
                                <Slider
                                    max={maxFee}
                                    value={feeRangeSliderValue}
                                    step={5000}
                                    marks={feeMarks}
                                    onChange={!isBlurred?handleFeeSlider:()=>setOpenModal(true)}
                                    aria-labelledby="range-slider"
                                />
                            </div>
                            <div className="text-center pb-2">
                                <CustomButton
                                    onClick={!isBlurred?handleApplySelection:()=>setOpenModal(true)}
                                    buttonText="Apply Fee Range"
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>

    )
}
export default FeeRangeFilter;

