import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import {http} from "../../services/apiService/apiService";
import LoadingSpinner from "../../components/loader/loader";
import $ from "jquery";

const MarketingDetal = props => {

    const location = useLocation();
    const params = Object.fromEntries(new URLSearchParams(location.search));
    const [marketingCardDetail, setMarketingCardDetail] = useState(null);
    const [loading, setLoading] = useState(true);

    async function getDetail() {
        scrollUpToTop();
        await http.get(`marketing-card/${params.id}`).then((response) => {

            if (response.data) {
                setMarketingCardDetail(response.data);
                setLoading(false);
            }
        })
    }
function scrollUpToTop() {
		$("html, body").animate({ scrollTop: 0 }, "slow");
	}
    useEffect(() => {
        getDetail();
    }, [])

    return (
        <>
            {loading ? <LoadingSpinner/> :
                <div className="main courseDetailPage">

                    <div className="courseDetailBanner">
                        <img
                            src={marketingCardDetail.detail_page_img}
                        />
                        <div className="content">
                            {/*<h1>{marketingCardDetail.heading}</h1>*/}

                        </div>
                    </div>
                    <div className="container">
                        <h3>{marketingCardDetail.heading}</h3>
                        <div className="blogDetailCont">
                            <div
                                dangerouslySetInnerHTML={{__html: marketingCardDetail.content}}
                            ></div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};


export default MarketingDetal;
