import React, { useEffect, useState } from "react";
import IntakeCheckInput from "./intake_check_input";
function IntakeFilters(props) {
	const { intakegroup, index, selectedIntakes } = props;
	const [intake_levelCheck, setIntake_levelCheck] = useState(false);
	const [activeClass, setActiveClass] = useState(false);

	useEffect(() => {
		if (selectedIntakes.length >= 1) {
			if (
				selectedIntakes.some((month) => month.month === intakegroup.value[0].month) &&
				selectedIntakes.some((month) => month.month === intakegroup.value[1].month) &&
				selectedIntakes.some((month) => month.month === intakegroup.value[2].month)
			) {
				setIntake_levelCheck(true);
			} else {
				setIntake_levelCheck(false);
			}
		} else {
			setIntake_levelCheck(false);
		}
	}, [props.rand]);
	useEffect(() => {
		if (selectedIntakes.length >= 1) {
			if (
				selectedIntakes.some((month) => month.month === intakegroup.value[0].month) ||
				selectedIntakes.some((month) => month.month === intakegroup.value[1].month) ||
				selectedIntakes.some((month) => month.month === intakegroup.value[2].month)
			) {
				setActiveClass(true);
			} else {
				setActiveClass(false);
			}
		} else {
			setActiveClass(false);
		}
	}, [props.rand]);

	const onChangeIntakeLevelCheck = (intake_level, check) => {
		setIntake_levelCheck(!check);
		props.updateSelectedIntakes(intake_level, "group", !check);
	};
	const [upDown, setUpDown] = useState(false);
	const countryArrowDownClick = (intake_level, index) => {
		if (upDown === false) {
			let element = document.getElementById(intake_level);
			element.className += " filterOpen";
			let subElement = document.getElementById(intake_level + String(index));
			subElement.className += " show";
			setUpDown(true);
		} else {
			let element = document.getElementById(intake_level);
			element.className = " filterItem";
			let subElement = document.getElementById(intake_level + String(index));
			subElement.className = " filterItemCont";
			setUpDown(false);
		}
	};
	const countryArrowUpClick = (intake_level, index) => {
		let element = document.getElementById(intake_level);
		element.className = " filterItem";
		let subElement = document.getElementById(intake_level + String(index));
		subElement.className = " filterItemCont";
	};
	const onIntakeUpdate = (obj, status) => {
		props.updateSelectedIntakes(obj, "individual", status);
	};
	return (
		<>
			<div className="filterItemWrap">
				<div id={intakegroup.key} className="filterItem">
					<input
						className={activeClass === true ? "childChecked" : ""}
						key={index + Math.floor(Math.random() * 1000)}
						type="checkbox"
						id={'intake' + index}
						onChange={() => {
							onChangeIntakeLevelCheck(intakegroup, intake_levelCheck);
						}}
						checked={intake_levelCheck}
					></input>
					<label
						htmlFor={'intake' + index}
						className={activeClass === true ? "active" : ""}
						onClick={(e) => {
							countryArrowDownClick(intakegroup.key, index);
						}}
					>
						<b>
							{intakegroup.key}
							{intakegroup.course_count ? ` (${intakegroup.course_count})` : ""}
						</b>
					</label>
					<i
						className="fa fa-angle-right"
						onClick={(e) => {
							countryArrowDownClick(intakegroup.key, index);
						}}
					></i>
					<i
						className="fa fa-angle-down"
						onClick={(e) => {
							countryArrowUpClick(intakegroup.key, index);
						}}
					></i>
				</div>
				<div id={intakegroup.key + String(index)} className="filterItemCont ">
					{intake_levelCheck
						? intakegroup?.value?.map((level, index) => (
							<IntakeCheckInput
								key={index}
								levels={level}
								index={index}
								status={true}
								selectedIntakes={selectedIntakes}
								onIntakeUpdate={onIntakeUpdate}
								rand={Math.random()}
							/>
						))
						: intakegroup?.value?.map((level, index) => (
							<IntakeCheckInput
								key={index}
								levels={level}
								index={index}
								status={false}
								selectedIntakes={selectedIntakes}
								onIntakeUpdate={onIntakeUpdate}
								rand={Math.random()}
							/>
						))}
				</div>
			</div>
		</>
	);
}
export default IntakeFilters;
