import axios from 'axios';
import React from 'react';
import { Route } from "react-router-dom";
import { http } from '../services/apiService/apiService';
const routes = [
  {
    id: 1,
    page_type: "terms_of_use",
  },
  {
    id: 2,
    page_type: "tcf",
  },
  {
    id: 3,
    page_type: "privacy_policy",
  },
  {
    id: 4,
    page_type: "cancellation_policy",
  },
  {
    id: 5,
    page_type: "careers",
  },
  {
    id: 6,
    page_type: "marketing",
  },
  {
    id: 7,
    page_type: "apply",
  },
  {
    id: 8,
    page_type: "mba",
  },
  {
    id: 9,
    page_type: "contact_us",
  },
  {
    id: 11,
    page_type: "all_interesting_about_tcf",
  },
  {
    id: 14,
    page_type: "forget_password",
  },
  {
    id: 15,
    page_type: "reset_password",
  },
  {
    id: 16,
    page_type: "new_password",
  },
  {
    id: 17,
    page_type: "confirm_password",
  },
  {
    id: 18,
    page_type: "otp_screen",
  },
  {
    id: 23,
    page_type: "discover_courses",
  },
  {
    id: 24,
    page_type: "course",
  },
  {
    id: 31,
    page_type: "university_course",
  },
  {
    id: 231,
    page_type: "discover_courses",
    slugId: '/:id/'
  },
  {
    id: 242,
    page_type: "course",
    slugId: '/:id/'
  },
  {
    id: 313,
    page_type: "university_course",
    slugId: '/:id/'
  },
  {
    id: 25,
    page_type: "course_detail",
  },
  {
    id: 26,
    page_type: "discipline_detail",
    // slugId:'/:id/'
  },
  {
    id: 27,
    page_type: "landing",
  },
  {
    id: 28,
    page_type: "all_countries",
  },
  {
    id: 29,
    page_type: "universities",
  },
  {
    id: 30,
    page_type: "top_universities",
  },
  {
    id: 32,
    page_type: "university_details",
  },
  {
    id: 33,
    page_type: "marketing_detail",
  },
  {
    id: 34,
    page_type: "all_pathway_groups",
  },
  {
    id: 35,
    page_type: "pathway_courses",
  },
  {
    id: 36,
    page_type: "all_groups",
  },
  {
    id: 37,
    page_type: "institute_courses",
  },
  {
    id: 38,
    page_type: "institute_scholarships",
  },
  {
    id: 39,
    page_type: "all_apply_portals",
  },
  {
    id: 40,
    page_type: "apply_portal_universities",
  },
  {
    id: 41,
    page_type: "scholarship_country",
  },
  {
    id: 42,
    page_type: "scholarships",
  },
  {
    id: 43,
    page_type: "all_scholarships",
  },
  {
    id: 44,
    page_type: "scholarship_detail",
  },
  {
    id: 45,
    page_type: "discipline",
  },
  {
    id: 46,
    page_type: "all_programs",
  },
  {
    id: 47,
    page_type: "compare_course",
  },
  {
    id: 48,
    page_type: "course_compare",
  },
  {
    id: 49,
    page_type: "discover_course",
  },
  {
    id: 50,
    page_type: "blog",
  },
  {
    id: 51,
    page_type: "payment_jazcash",
  },
  {
    id: 52,
    page_type: "loading",
  },
  {
    id: 53,
    page_type: "apply_portal_university",
    slugId: '/:portalId/:uniId/'
  },
  {
    id: 54,
    page_type: "blog_detail",
    slugId: '/:blogId'
  },
  {
    id: 55,
    page_type: "What_is_interesting",
    slugId: '/:id',
  },
  {
    id: 56,
    page_type: "country",
    slugId: '/:countryId/:countryName/',
  },
  {
    id: 57,
    page_type: "university_Individual",
    slugId: '/:uniId',
  },
  {
    id: 58,
    page_type: "pathway_group_universities",
    slugId: '/:groupId',
  },
  {
    id: 59,
    page_type: "pathway_university",
    slugId: '/:pathwayID/:uniId/',
  },
  {
    id: 60,
    page_type: "group_universities",
    slugId: '/:groupId',
  },
  {
    id: 61,
    page_type: "institute_group_university",
    slugId: '/:instituteGroupId/:uniId/',
  },
  {
    id: 62,
    page_type: "search",
  },
]
async function routesAll() {
  var posts = []
  await axios.get('https://main.timescoursefinder.com/api/cms/routing/').then(d => {
    if (d.data) {
      const { data } = d
      if (data.length) {
        routes.map(person => {
          const addressItem = data.find(component => component.page_type === person.page_type)
          person = {
            slug: addressItem.page_type === "landing" ? '/' : '/' + (person.slugId ? addressItem.slug + person.slugId : addressItem.slug),
          }
          posts.push(person)
        })
      }
    }
  })
  return posts
}
function routeslll() {
  let sd = []
  routesAll().then(res => res.map(d => sd.push(<Route path={d.slug} />)))
  return sd
}
export default (
  <Route>
    <Route path="/" />
    <Route path="/search" />
    <Route path="/terms-of-use" />
    <Route path="/tcf" />
    <Route path="/privacy-policy" />
    <Route path="/cancellation-policy" />
    <Route path="/careers" />
    <Route path="/marketing" />
    <Route path="/apply" />
    <Route path="/mba" />
    <Route path="/contact-us" />
    <Route path="/What+is+interesting/:id" />
    <Route path="/forget-password" />
    <Route path="/reset-password" />
    <Route path="/new-password" />
    <Route path="/confirm-password" />
    <Route path="/otp-screen" />
    <Route path="/application-detail" />
    <Route path="/shortlist" />
    <Route path="/track-application" />
    <Route path="/upload-documents" />
    <Route path="/discover-courses" />
    <Route path="/course" />
    <Route path="/course-detail" />
    <Route path="/discipline-detail" />
    <Route path="/all-countries" />
    <Route path="/country/:countryName" />
    <Route path="/universities" />
    <Route path="/top-universities" />
    <Route path="/university-Individual/:uniId" />
    <Route path="/university-program" />
    <Route path="/university-details/" />
    <Route path="/all-pathway-groups" />
    <Route path="/pathway-group-universities/:groupId" />
    <Route path="/pathway-university/:pathwayID/:uniId/" />
    <Route path="/pathway-courses/" />
    <Route path="/all-groups" />
    <Route path="/group-universities/:groupId" />
    <Route path="/institute-group-university/:instituteGroupId/:uniId/" />
    <Route path="/institute-courses/" />
    <Route path="/institute-scholarships" />
    <Route path="/all-apply-portals" />
    <Route path="/apply-portal-universities" />
    <Route path="/apply-portal-university/:portalId/:uniId/" />
    <Route path="/scholarship-country" />
    <Route path="/scholarships" />
    <Route path="/all-scholarships" />
    <Route path="/scholarship-detail" />
    <Route path="/discipline" />
    <Route path="/all-programs" />
    <Route path="/compare-course" />
    <Route path="/course-compare" />
    <Route path="/discover-course" />
    <Route path="/blog" />
    <Route path="/blog-detail/:blogId" />
  </Route>
);