import { http } from "../../../services/apiService/apiService";
import axios from "axios";
import {GET_ALL_INSTITUTE_GROUPS_FAIL, GET_ALL_INSTITUTE_GROUPS_SUCCESS} from "./institute_group.actions";
//Pathway

export const GET_ALL_PATHWAY_GROUPS = "GET_ALL_PATHWAY_GROUPS";
export const GET_ALL_PATHWAY_GROUPS_SUCCESS = "GET_ALL_PATHWAY_GROUPS_SUCCESS";
export const GET_ALL_PATHWAY_GROUPS_FAIL = "GET_ALL_PATHWAY_GROUPS_FAIL";

// export function getAllPathwayGroups() {
// 	const url = "pathway-group/";
// 	const request = http.get(url);
//
// 	return (dispatch) =>
// 		request
// 			.then((response) =>
// 				dispatch({
// 					type: GET_ALL_PATHWAY_GROUPS_SUCCESS,
// 					payload: response.data,
// 				})
// 			)
// 			.catch((error) => {
// 				return dispatch({
// 					type: GET_ALL_PATHWAY_GROUPS_FAIL,
// 					payload: [],
// 				});
// 			});
// }

export const getAllPathwayGroups = () => async (dispatch,getState) => {
	// dispatch(handleLoadingDispatcher(LOADING_COUNTRY_RANKING, true));
	// dispatch(handleSuccessDispatcher(SUCCESS_COUNTRY_RANKING, false));
	if(!getState().apis.allPathwayGroups.data.length) {
		let url = "pathway-group/";
		try {
			url = process.env.REACT_APP_BASE_URL + url
			const response = await axios.get(url);
			dispatch(
				{
					type: GET_ALL_PATHWAY_GROUPS_SUCCESS,
					payload: response.data.results,
				}
			);
		} catch (e) {
			return dispatch({
				type: GET_ALL_PATHWAY_GROUPS_FAIL,
				payload: [],
			});
		}
	}
};

export const GET_PATHWAY_GROUP_DETAIL = "GET_PATHWAY_GROUP_DETAIL";
export const GET_PATHWAY_GROUP_DETAIL_SUCCESS =
	"GET_PATHWAY_GROUP_DETAIL_SUCCESS";
export const GET_PATHWAY_GROUP_DETAIL_FAIL = "GET_PATHWAY_GROUP_DETAIL_FAIL";

export function getPathwayGroupDetail(pathwayGroupId) {
	const url = "pathway-group/" + pathwayGroupId;
	const request = http.get(url);

	return (dispatch) =>
		request
			.then((response) =>
				dispatch({
					type: GET_PATHWAY_GROUP_DETAIL_SUCCESS,
					payload: response.data,
				})
			)
			.catch((error) => {
				return dispatch({
					type: GET_PATHWAY_GROUP_DETAIL_FAIL,
					payload: [],
				});
			});
}

export const GET_PATHWAY_GROUP_ALL_UNIVERSITIES =
	"GET_PATHWAY_GROUP_ALL_UNIVERSITIES";
export const GET_PATHWAY_GROUP_ALL_UNIVERSITIES_SUCCESS =
	"GET_PATHWAY_GROUP_ALL_UNIVERSITIES_SUCCESS";
export const GET_PATHWAY_GROUP_ALL_UNIVERSITIES_FAIL =
	"GET_PATHWAY_GROUP_ALL_UNIVERSITIES_FAIL";

export function getPathwayGroupUniversities(pathwayGroupId, params, pageId) {
	let url = "pathway-group/" + pathwayGroupId + "/universities/";
	if (pageId) {
		url = url + "?page=" + pageId;
	}
	const request = http.post(url, params);

	return (dispatch) =>
		request
			.then((response) =>
				dispatch({
					type: GET_PATHWAY_GROUP_ALL_UNIVERSITIES_SUCCESS,
					payload: response.data,
				})
			)
			.catch((error) => {
				return dispatch({
					type: GET_PATHWAY_GROUP_ALL_UNIVERSITIES_FAIL,
					payload: [],
				});
			});
}

export const GET_LOCATIONS_OF_PATHWAY_GROUP = "GET_LOCATIONS_OF_PATHWAY_GROUP";
export const GET_LOCATIONS_OF_PATHWAY_GROUP_SUCCESS =
	"GET_LOCATIONS_OF_PATHWAY_GROUP_SUCCESS";
export const GET_LOCATIONS_OF_PATHWAY_GROUP_FAIL =
	"GET_LOCATIONS_OF_PATHWAY_GROUP_FAIL";

export function getPathwayGroupRelatedLocations(groupId) {
	let url = "pathway-group/" + groupId + "/related_locations";
	const request = http.get(url);

	return (dispatch) =>
		request
			.then((response) =>
				dispatch({
					type: GET_LOCATIONS_OF_PATHWAY_GROUP_SUCCESS,
					payload: response.data,
				})
			)
			.catch((error) => {
				return dispatch({
					type: GET_LOCATIONS_OF_PATHWAY_GROUP_FAIL,
					payload: [],
				});
			});
}
export const GET_DISCIPLINES_OF_PATHWAY_GROUP =
	"GET_DISCIPLINES_OF_PATHWAY_GROUP";
export const GET_DISCIPLINES_OF_PATHWAY_GROUP_SUCCESS =
	"GET_DISCIPLINES_OF_PATHWAY_GROUP_SUCCESS";
export const GET_DISCIPLINES_OF_PATHWAY_GROUP_FAIL =
	"GET_DISCIPLINES_OF_PATHWAY_GROUP_FAIL";

export function getDisciplinesByPathwayGroup(groupId) {
	let url = "pathway-group/" + groupId + "/disciplines";
	const request = http.get(url);

	return (dispatch) =>
		request
			.then((response) =>
				dispatch({
					type: GET_DISCIPLINES_OF_PATHWAY_GROUP_SUCCESS,
					payload: response.data,
				})
			)
			.catch((error) => {
				return dispatch({
					type: GET_DISCIPLINES_OF_PATHWAY_GROUP_FAIL,
					payload: [],
				});
			});
}
export const GET_SCHOLARSHIPS_OF_PATHWAY_GROUP =
	"GET_SCHOLARSHIPS_OF_PATHWAY_GROUP";
export const GET_SCHOLARSHIPS_OF_PATHWAY_GROUP_SUCCESS =
	"GET_SCHOLARSHIPS_OF_PATHWAY_GROUP_SUCCESS";
export const GET_SCHOLARSHIPS_OF_PATHWAY_GROUP_FAIL =
	"GET_SCHOLARSHIPS_OF_PATHWAY_GROUP_FAIL";

export function getScholarshipsByPathwayGroup(groupId) {
	let url = "pathway-group/" + groupId + "/scholarships";
	const request = http.get(url);

	return (dispatch) =>
		request
			.then((response) =>
				dispatch({
					type: GET_SCHOLARSHIPS_OF_PATHWAY_GROUP_SUCCESS,
					payload: response.data,
				})
			)
			.catch((error) => {
				return dispatch({
					type: GET_SCHOLARSHIPS_OF_PATHWAY_GROUP_FAIL,
					payload: [],
				});
			});
}
export const GET_ALL_COURSES_OF_ALL_PATHWAYS_GROUP =
	"GET_ALL_COURSES_OF_ALL_PATHWAYS_GROUP";
export const GET_ALL_COURSES_OF_ALL_PATHWAYS_GROUP_SUCCESS =
	"GET_ALL_COURSES_OF_ALL_PATHWAYS_GROUP_SUCCESS";
export const GET_ALL_COURSES_OF_ALL_PATHWAYS_GROUP_FAIL =
	"GET_ALL_COURSES_OF_ALL_PATHWAYS_GROUP_FAIL";
export function getCoursesOfPathwaysGroup(params, pageID) {
	let url = "pathway-group/courses/";
	if (pageID) {
		url = url + "?page=" + pageID;
	}
	const request = http.post(url, params);

	return (dispatch) =>
		request
			.then((response) =>
				dispatch({
					type: GET_ALL_COURSES_OF_ALL_PATHWAYS_GROUP_SUCCESS,
					payload: response.data,
				})
			)
			.catch((error) => {
				return dispatch({
					type: GET_ALL_COURSES_OF_ALL_PATHWAYS_GROUP_FAIL,
					payload: [],
				});
			});
}
