const PopularRegionsModal = ({
    regionModal,
    selectedLocation,
    onLocationRemove,
    closeRegionModal,
    showCountryDetail,
    countryInput,
    handleCountriesSearch,
    regions,
    onSelectLocation,
    setShowRegion,
    popularCountries,
    showRegion,
    regionsCountries,
    countries,
    onClickCountryDetail,
    showStateDetail,
    onClickCountryDetailBack,
    stateInput,
    handleStatesSearch,
    filteredStates,
    onClickStateDetail,
    onClickStateDetailBack,
    cityInput,
    handleCitiesSearch,
    filterCities
}) => {
    return (
        <div
        className="modal fade regionModal"
        id="regionModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
    >
        <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    {regionModal ? (
                        <h5>Popular Regions</h5>
                    ) : (
                        <h5>Popular Countries</h5>
                    )}
                    <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <i className="fa fa-times"></i>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="modalTagWrap">
                        {selectedLocation?.map((item, index) => {
                            return (
                                <div key={index} className="searchTag">
                                    {item.name}
                                    <button
                                        className="unselectBtn"
                                        onClick={() => {
                                            onLocationRemove(index, item);
                                        }}
                                    >
                                        x
                                    </button>
                                </div>
                            );
                        })}
                        {selectedLocation?.length >= 1 && (
                            <button
                                onClick={(e) => {
                                    closeRegionModal(e);
                                }}
                                className="btn defaultBtn"
                            >
                                Done
                            </button>
                        )}
                    </div>
                    {showCountryDetail ? (
                        <div>
                            <div className="searchBackWrap">
                                <div
                                    className="countrySearchWrap"
                                    style={{ marginBottom: 15 }}
                                >
                                    <input
                                        value={countryInput}
                                        type="text"
                                        placeholder="Search Country here"
                                        className="form-control"
                                        onChange={(e) => {
                                            handleCountriesSearch(e.target.value);
                                        }}
                                    />
                                    <i className="fa fa-search"></i>
                                </div>
                            </div>
                            <div className="regionFlex">
                                {regionModal ? (
                                    <div className="left">
                                        <h5>Popular Regions</h5>
                                        <ul>
                                            {regions?.map((item, index) => {
                                                return (
                                                    <li>
                                                        <a href className="searchItemBtn">
                                                            {" "}
                                                            <a
                                                                className={item.checked === true ? "active" : ""}
                                                                href
                                                                key={index}
                                                                onClick={() => {
                                                                    onSelectLocation(item, "Region");
                                                                }}
                                                            >
                                                                {item.name}
                                                            </a>
                                                            <span>({item.countries.length})</span>
                                                        </a>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                        <h5>
                                            <a href className="searchItemBtn">
                                                {" "}
                                                <a
                                                    href
                                                    onClick={() => {
                                                        setShowRegion(false);
                                                    }}
                                                >
                                                    All Countries
                                                </a>
                                                <a
                                                    href
                                                    onClick={() => {
                                                        setShowRegion(false);
                                                    }}
                                                >
                                                    <i className="fa fa-angle-right"></i>
                                                </a>
                                            </a>
                                        </h5>
                                        <h5>Popular Countries</h5>
                                        <ul>
                                            {popularCountries?.map((item, index) => {
                                                return (
                                                    <li>
                                                        <a
                                                            className="searchItemBtn"
                                                            href
                                                            key={index}
                                                        >
                                                            <a
                                                                className={item.checked === true ? "active" : ""}
                                                                href
                                                                onClick={() => {
                                                                    onSelectLocation(item, "all_country");
                                                                }}
                                                            >
                                                                {item.name}
                                                            </a>
                                                        </a>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                ) : (
                                    <div className="left">
                                        <h5>Popular Countries</h5>
                                        <ul>
                                            {popularCountries?.map((item, index) => {

                                                return (
                                                    <li>
                                                        <a
                                                            className="searchItemBtn"
                                                            href
                                                            key={index}
                                                        >
                                                            <a
                                                                key={item.id}
                                                                className={item.checked === true ? "active" : ""}
                                                                href
                                                                onClick={() => {
                                                                    onSelectLocation(item, "all_country");
                                                                }}
                                                            >
                                                                {item.name}
                                                            </a>
                                                        </a>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                        {/* <h5>All Countries</h5> */}
                                        <h5>Popular Regions</h5>
                                        <ul>
                                            {regions?.map((item, index) => {
                                                return (
                                                    <li>
                                                        <a
                                                            href
                                                            key={item.id}
                                                            className="searchItemBtn"
                                                        >
                                                            {" "}
                                                            <a
                                                                className={item.checked === true ? "active" : ""}
                                                                href
                                                                key={index}
                                                                onClick={() => {
                                                                    onSelectLocation(item, "Region");
                                                                }}
                                                            >
                                                                {item.name}
                                                            </a>
                                                            <span>({item.countries.length})</span>
                                                        </a>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                )}

                                {showRegion ? (
                                    <div className="right">
                                        <h5 style={{ paddingLeft: 14 }}>Region Countries</h5>
                                        <ul>
                                            {regionsCountries.length > 0 ? (
                                                regionsCountries?.map((item, index) => {
                                                    return (
                                                        <li>
                                                            <a
                                                                href
                                                                key={item.id}
                                                                className="searchItemBtn"
                                                            >
                                                                {" "}
                                                                <a
                                                                    className={item.checked === true ? "active" : ""}
                                                                    href
                                                                    onClick={() =>
                                                                        onSelectLocation(item, "Region")
                                                                    }
                                                                    key={index}
                                                                >
                                                                    {item.name}
                                                                </a>
                                                            </a>
                                                        </li>
                                                    );
                                                })
                                            ) : (
                                                <li style={{ textAlign: "center" }}>
                                                    No match found
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                ) : (
                                    <div className="right">
                                        <h5 style={{ paddingLeft: 14 }}>All Countries</h5>
                                        <ul>
                                            {countries.length > 0 ? (
                                                countries?.map((item, index) => {
                                                    return (
                                                        <li>
                                                            <a
                                                                href
                                                                key={item.id}
                                                                className="searchItemBtn"
                                                            >
                                                                {" "}
                                                                <a
                                                                    className={item.checked === true ? "active" : ""}
                                                                    href
                                                                    onClick={() =>
                                                                        onSelectLocation(item, "all_country")
                                                                    }
                                                                    key={index}
                                                                >
                                                                    {item.name}
                                                                </a>
                                                                <a
                                                                    href
                                                                    onClick={() => onClickCountryDetail(item.states)}
                                                                >
                                                                    <i className="fa fa-angle-right"></i>
                                                                </a>
                                                            </a>
                                                        </li>
                                                    );
                                                })
                                            ) : (
                                                <li style={{ textAlign: "center" }}>
                                                    No match found
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div>
                            {showStateDetail ? (
                                <div>
                                    <div className="searchBackWrap">
                                        <button
                                            onClick={() => onClickCountryDetailBack()}
                                            className="backBtn"
                                        >
                                            <i className="fa fa-angle-left"></i>Back To country
                                        </button>
                                        <div
                                            className="countrySearchWrap"
                                            style={{ marginBottom: 15 }}
                                        >
                                            <input
                                                value={stateInput}
                                                type="text"
                                                placeholder="Type State here"
                                                className="form-control"
                                                onChange={(e) => {
                                                    handleStatesSearch(e.target.value);
                                                }}
                                            />
                                            <i className="fa fa-search"></i>
                                        </div>
                                    </div>
                                    <div className="regionFlex">
                                        <div className="right">
                                            <h5 style={{ paddingLeft: 14 }}>All States</h5>
                                            <ul>
                                                {filteredStates?.map((item, index) => {
                                                    return (
                                                        <li>
                                                            <div>
                                                                <a
                                                                    href
                                                                    key={item.id}
                                                                    className="searchItemBtn"
                                                                >
                                                                    <a
                                                                        className={item.checked === true ? "active" : ""}
                                                                        href
                                                                        onClick={() => {
                                                                            onSelectLocation(item, "all_country");
                                                                        }}
                                                                        key={index}
                                                                    >
                                                                        {item.name}
                                                                    </a>
                                                                    <a
                                                                        href
                                                                        onClick={() =>
                                                                            onClickStateDetail(item.cities)
                                                                        }
                                                                    >
                                                                        <i className="fa fa-angle-right"></i>
                                                                    </a>
                                                                </a>
                                                            </div>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <div className="searchBackWrap">
                                        <button
                                            onClick={() => onClickStateDetailBack()}
                                            className="backBtn"
                                        >
                                            <i className="fa fa-angle-left"></i>Back To States
                                        </button>
                                        <div
                                            className="countrySearchWrap"
                                            style={{ marginBottom: 15 }}
                                        >
                                            <input
                                                value={cityInput}
                                                type="text"
                                                placeholder="Search City here"
                                                className="form-control"
                                                onChange={(e) => {
                                                    handleCitiesSearch(e.target.value);
                                                }}
                                            />
                                            <i className="fa fa-search"></i>
                                        </div>
                                    </div>
                                    <div className="regionFlex">
                                        <div className="right">
                                            <h5 style={{ paddingLeft: 14 }}>All cities</h5>
                                            <ul>
                                                {filterCities.length > 0 ? (
                                                    filterCities?.map((item, index) => {
                                                        return (
                                                            <li>
                                                                <a
                                                                    className="searchItemBtn"
                                                                    href
                                                                    key={index}
                                                                >
                                                                    <a href
                                                                        key={item.id}
                                                                        className={item.checked === true ? "active" : ""}
                                                                        herf="#"
                                                                        onClick={() => {
                                                                            onSelectLocation(item, "all_country");
                                                                        }}
                                                                    >
                                                                        {item.name}
                                                                    </a>
                                                                </a>
                                                            </li>
                                                        );
                                                    })
                                                ) : (
                                                    <li>No result found</li>
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    </div>
    )
}

export default PopularRegionsModal;