import React, { useEffect, useState } from "react";
import $ from "jquery";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";
import { getData } from "country-list";
import { httpCrm } from "../../services/apiService/apiService";
import "react-toastify/dist/ReactToastify.css";
import {Alert}from "@mui/material";
import { toast } from "react-toastify";
import LocationSearchInput from "../map/autoloaction";
import { http } from "../../services/apiService/apiService";
import { useDispatch } from "react-redux";
import CourseSelectionFilter from "./course_selection_filter";

const customStyles = {
	option: (provided) => ({
		...provided,
		color: "black",
	}),
	control: (provided) => ({
		...provided,
		color: "black",
	}),
	singleValue: (provided) => ({
		...provided,
		color: "black",
	}),
};
const courseOptions = [];
export default function CheckEligibility(props) {
	const phoneTypeOptions = [
		{ value: "call", label: "Call" },
		{ value: "whatsapp", label: "WhatsApp" },
		{ value: "other", label: "Other" },
	];
	const SchoolOptions = [
		{ value: "Matric", label: "Matric" },
		{ value: "O-Levels'", label: "O-Levels" },
		{ value: "other", label: "Other" },
	];
	const EnglishTestOptions = [
		{
			value: "IELTS",
			label: "International English Language Testing System (IELTS)",
		},
		{
			value: "TOEIC",
			label: "Test Of English for International Communication (TOEIC)",
		},
		{ value: "OPI", label: " Oral Proficiency Interview (OPI)" },
		{ value: "PET", label: "Cambridge English Preliminary (PET)" },
		{ value: "FCE", label: "Cambridge English First (FCE)" },
		{ value: "CAE", label: "Cambridge English Advanced (CAE)" },
		{ value: "CPE", label: "Cambridge English Proficiency (CPE)" },
		{ value: "TOEFL", label: "TOEFL" },
		{ value: "PTE", label: "PTE" },
	];
	const CollegeOptions = [
		{ value: "Inter Pre-Eng", label: "Inter Pre-Eng" },
		{ value: "Inter Pre-Medical", label: "Inter Pre-Medical" },
		{ value: "Inter Arts", label: "Inter Arts" },
		{ value: "Inter Commerce", label: "Inter Commerce" },
		{ value: "GCSEs", label: "GCSEs" },
		{ value: "As-levels", label: "As-levels" },
		{ value: "A-levels", label: "A-levels" },
		{
			value: "International Baccalaureate",
			label: "International Baccalaureate",
		},
		{ value: "Foundation", label: "Foundation" },
		{ value: "DAE", label: "DAE" },
		{ value: "I.COM", label: "I.COM" },
		{ value: "DIB", label: "DIB" },
		{ value: "DIT", label: "DIT" },
		{ value: "DBA", label: "DBA" },
	];
	const BachelorOptions = [
		{
			value:
				"Bachelor of Applied Science in Information Technology (BAppSc(IT))",
			label:
				"Bachelor of Applied Science in Information Technology (BAppSc(IT))",
		},
		{
			value: "Bachelor of Design (BDes, or SDes in Indonesia)",
			label: "Bachelor of Design (BDes, or SDes in Indonesia)",
		},
		{ value: "Bachelor of Accounting", label: "Bachelor of Accounting" },
		{
			value: "Bachelor of Business Studies",
			label: "Bachelor of Business Studies",
		},
		{ value: "Bachelor of ECommerce", label: "Bachelor of ECommerce" },
		{
			value: "Bachelor of Entrepreneurship",
			label: "Bachelor of Entrepreneurship",
		},
		{ value: "Bachelor of Finance", label: "Bachelor of Finance" },
		{
			value: "Bachelor of Human Resource Management",
			label: "Bachelor of Human Resource Management",
		},
		{ value: "Bachelor of Marketing", label: "Bachelor of Marketing" },
		{
			value: "Bachelor of Office Administration",
			label: "Bachelor of Office Administration",
		},
		{
			value: "Bachelor of Quality Management",
			label: "Bachelor of Quality Management",
		},
		{ value: "Bachelor of Retail", label: "Bachelor of Retail" },
		{
			value: "Bachelor of Transportation and Logistics",
			label: "Bachelor of Transportation and Logistics",
		},
		{ value: "Bachelor of Astronomy", label: "Bachelor of Astronomy" },
		{
			value: "Bachelor of Biomedical Sciences",
			label: "Bachelor of Biomedical Sciences",
		},
		{ value: "Bachelor of Chemistry", label: "Bachelor of Chemistry" },
		{
			value: "Bachelor of Earth Sciences",
			label: "Bachelor of Earth Sciences",
		},
		{
			value: "Bachelor of Environmental Sciences",
			label: "Bachelor of Environmental Sciences",
		},
		{
			value: "Bachelor of Food Science and Technology",
			label: "Bachelor of Food Science and Technology",
		},
		{
			value: "Bachelor of Forensic Science",
			label: "Bachelor of Forensic Science",
		},
		{
			value: "Bachelor of General Sciences",
			label: "Bachelor of General Sciences",
		},
		{ value: "Bachelor of Life Sciences", label: "Bachelor of Life Sciences" },
		{
			value: "Bachelor of Materials Sciences",
			label: "Bachelor of Materials Sciences",
		},
		{
			value: "Bachelor of Physical Geography",
			label: "Bachelor of Physical Geography",
		},
		{ value: "Bachelor of Physics", label: "Bachelor of Physics" },
		{
			value: "Bachelor of Sports Science",
			label: "Bachelor of Sports Science",
		},
		{
			value: "Bachelor of Aerospace Engineering",
			label: "Bachelor of Aerospace Engineering",
		},
		{
			value: "Bachelor of Biomedical Engineering",
			label: "Bachelor of Biomedical Engineering",
		},
		{
			value: "Bachelor of Chemical and Materials Engineering",
			label: "Bachelor of Chemical and Materials Engineering",
		},
		{
			value: "Bachelor of Civil Engineering",
			label: "Bachelor of Civil Engineering",
		},
		{
			value: "Bachelor of Electrical Engineering",
			label: "Bachelor of Electrical Engineering",
		},
		{
			value: "Bachelor of Electronic Engineering",
			label: "Bachelor of Electronic Engineering",
		},
		{
			value: "Bachelor of Mechanical Engineering",
			label: "Bachelor of Mechanical Engineering",
		},
		{ value: "Bachelor of Metallurgy", label: "Bachelor of Metallurgy" },
		{ value: "Bachelor of Architecture", label: "Bachelor of Architecture" },
		{
			value: "Bachelor of Built Environment",
			label: "Bachelor of Built Environment",
		},
		{
			value: "Bachelor of Maintenance Services",
			label: "Bachelor of Maintenance Services",
		},
		{ value: "Bachelor of Surveying", label: "Bachelor of Surveying" },
		{ value: "Bachelor of Planning", label: "Bachelor of Planning" },
		{ value: "Bachelor of Crafts", label: "Bachelor of Crafts" },
		{ value: "Bachelor of Dance", label: "Bachelor of Dance" },
		{
			value: "Bachelor of Health Studies",
			label: "Bachelor of Health Studies",
		},
		{
			value: "Bachelor of Health and Safety",
			label: "Bachelor of Health and Safety",
		},
		{ value: "Bachelor of Counselling", label: "Bachelor of Counselling" },
		{ value: "Bachelor of Dentistry", label: "Bachelor of Dentistry" },
		{ value: "Bachelor of Medicine", label: "Bachelor of Medicine" },
		{ value: "Bachelor of Ophthalmology", label: "Bachelor of Ophthalmology" },
		{
			value: "Bachelor of Nutrition and Health",
			label: "Bachelor of Nutrition and Health",
		},
		{ value: "Bachelor of Midwifery", label: "Bachelor of Midwifery" },
		{ value: "Bachelor of Archaeology", label: "Bachelor of Archaeology" },
		{ value: "Bachelor of Classics", label: "Bachelor of Classics" },
		{
			value: "Bachelor of International relations",
			label: "Bachelor of International relations",
		},
		{
			value: "Bachelor of International Development",
			label: "Bachelor of International Development",
		},
		{
			value: "Bachelor of Human Geography",
			label: "Bachelor of Human Geography",
		},
		{
			value: "Bachelor of Film & Television",
			label: "Bachelor of Film & Television",
		},
		{
			value: "Bachelor of English Studies",
			label: "Bachelor of English Studies",
		},
		{
			value: "Bachelor of Cultural Studies",
			label: "Bachelor of Cultural Studies",
		},
		{ value: "Bachelor of Pharmacology", label: "Bachelor of Pharmacology" },
		{ value: "Bachelor of Physiology", label: "Bachelor of Physiology" },
		{ value: "Bachelor of Languages", label: "Bachelor of Languages" },
		{ value: "Bachelor of Literature", label: "Bachelor of Literature" },
		{
			value: "Bachelor of Museum Studies",
			label: "Bachelor of Museum Studies",
		},
		{ value: "Bachelor of History", label: "Bachelor of History" },
		{ value: "Bachelor of Physiotherapy", label: "Bachelor of Physiotherapy" },
		{
			value: "Bachelor of Complementary Health",
			label: "Bachelor of Complementary Health",
		},
		{
			value: "Bachelor of Theatre and Drama Studies",
			label: "Bachelor of Theatre and Drama Studies",
		},
		{
			value: "Bachelor of Non-industrial Design",
			label: "Bachelor of Non-industrial Design",
		},
		{
			value: "Bachelor of Industrial Design",
			label: "Bachelor of Industrial Design",
		},
		{
			value: "Bachelor of Graphic Design",
			label: "Bachelor of Graphic Design",
		},
		{
			value: "Bachelor of Interior Design",
			label: "Bachelor of Interior Design",
		},
		{
			value: "Bachelor of Fashion and Textile Design",
			label: "Bachelor of Fashion and Textile Design",
		},
		{
			value: "Bachelor of Art Administration",
			label: "Bachelor of Art Administration",
		},
		{
			value: "Bachelor of Property Management",
			label: "Bachelor of Property Management",
		},
		{ value: "Bachelor of Construction", label: "Bachelor of Construction" },
		{
			value: "Bachelor of Regional Studies",
			label: "Bachelor of Regional Studies",
		},
		{ value: "Bachelor of Multimedia", label: "Bachelor of Multimedia" },
		{ value: "Bachelor of Anthropology", label: "Bachelor of Anthropology" },
		{ value: "Bachelor of Media", label: "Bachelor of Media" },
		{
			value: "Bachelor of Travel and Tourism",
			label: "Bachelor of Travel and Tourism",
		},
		{
			value: "Bachelor of Hotel Management",
			label: "Bachelor of Hotel Management",
		},
		{
			value: "Bachelor of Leisure Management",
			label: "Bachelor of Leisure Management",
		},
		{ value: "Bachelor of Hospitality", label: "Bachelor of Hospitality" },
		{
			value: "Bachelor of Food and Drink Production",
			label: "Bachelor of Food and Drink Production",
		},
		{ value: "Bachelor of Catering", label: "Bachelor of Catering" },
		{ value: "Bachelor of Aviation", label: "Bachelor of Aviation" },
		{ value: "Bachelor of Sociology", label: "Bachelor of Sociology" },
		{ value: "Bachelor of Photography", label: "Bachelor of Photography" },
		{ value: "Bachelor of Social Work", label: "Bachelor of Social Work" },
		{ value: "Bachelor of Politics", label: "Bachelor of Politics" },
		{ value: "Bachelor of Writing", label: "Bachelor of Writing" },
		{
			value: "Bachelor of Social Sciences",
			label: "Bachelor of Social Sciences",
		},
		{
			value: "Bachelor of Public Administration",
			label: "Bachelor of Public Administration",
		},
		{
			value: "Bachelor of Environmental Management",
			label: "Bachelor of Environmental Management",
		},
		{ value: "Bachelor of Economics", label: "Bachelor of Economics" },
		{
			value: "Bachelor of Library Studies",
			label: "Bachelor of Library Studies",
		},
		{ value: "Bachelor of Linguistics", label: "Bachelor of Linguistics" },
		{ value: "Bachelor of Public Law", label: "Bachelor of Public Law" },
		{ value: "Bachelor of Legal Studies", label: "Bachelor of Legal Studies" },
		{
			value: "Bachelor of International Law",
			label: "Bachelor of International Law",
		},
		{ value: "Bachelor of Civil Law", label: "Bachelor of Civil Law" },
		{ value: "Bachelor of Legal Advice", label: "Bachelor of Legal Advice" },
		{ value: "Bachelor of Criminal Law", label: "Bachelor of Criminal Law" },
		{
			value: "Bachelor of Vehicle Engineering",
			label: "Bachelor of Vehicle Engineering",
		},
		{
			value: "Bachelor of Quality Control",
			label: "Bachelor of Quality Control",
		},
		{
			value: "Bachelor of Telecommunications",
			label: "Bachelor of Telecommunications",
		},
		{
			value: "Bachelor of Structural Engineering",
			label: "Bachelor of Structural Engineering",
		},
		{
			value: "Bachelor of Power and Energy Engineering",
			label: "Bachelor of Power and Energy Engineering",
		},
		{
			value: "Bachelor of Mining and Oil & Gas Operations",
			label: "Bachelor of Mining and Oil & Gas Operations",
		},
		{
			value: "Bachelor of Environmental Engineering",
			label: "Bachelor of Environmental Engineering",
		},
		{
			value: "Bachelor of Marine Engineering",
			label: "Bachelor of Marine Engineering",
		},
		{
			value: "Bachelor of Software Engineering",
			label: "Bachelor of Software Engineering",
		},
		{
			value: "Bachelor of General Engineering and Technology",
			label: "Bachelor of General Engineering and Technology",
		},
		{
			value: "Bachelor of Manufacturing and Production",
			label: "Bachelor of Manufacturing and Production",
		},
		{
			value:
				"Bachelor of Engineering (BEng, BE, BSE, BESc, BSEng, BASc, BTech, BSc(Eng), AMIE,GradIETE)",
			label:
				"Bachelor of Engineering (BEng, BE, BSE, BESc, BSEng, BASc, BTech, BSc(Eng), AMIE,GradIETE)",
		},
		{
			value: "Bachelor of Science in Business (BSBA)",
			label: "Bachelor of Science in Business (BSBA)",
		},
		{
			value: "Bachelor of Engineering Technology (BSET)",
			label: "Bachelor of Engineering Technology (BSET)",
		},
		{
			value: "Bachelor of Technology (B.Tech. or B.Tech.)",
			label: "Bachelor of Technology (B.Tech. or B.Tech.)",
		},
		{
			value: "International Business Economics (BIBE)",
			label: "International Business Economics (BIBE)",
		},
		{
			value: "Bachelor of Business Administration (BBA)",
			label: "Bachelor of Business Administration (BBA)",
		},
		{
			value: "Bachelor of Management Studies (BMS)",
			label: "Bachelor of Management Studies (BMS)",
		},
		{
			value: "Bachelor of Administrative Studies",
			label: "Bachelor of Administrative Studies",
		},
		{
			value: "Bachelor of International Business Economics (BIBE)",
			label: "Bachelor of International Business Economics (BIBE)",
		},
		{
			value: "Bachelor of Commerce (BCom, or BComm)",
			label: "Bachelor of Commerce (BCom, or BComm)",
		},
		{
			value: "Bachelor of Fine Arts (BFA)",
			label: "Bachelor of Fine Arts (BFA)",
		},
		{
			value: "Bachelor of Business (BBus or BBus)",
			label: "Bachelor of Business (BBus or BBus)",
		},
		{
			value: "Bachelor of Management and Organizational Studies (BMOS)",
			label: "Bachelor of Management and Organizational Studies (BMOS)",
		},
		{
			value: "Bachelor of Business Science (BBusSc)",
			label: "Bachelor of Business Science (BBusSc)",
		},
		{
			value: "Bachelor of Accountancy (B.Acy. or B.Acc. or B. Accty)",
			label: "Bachelor of Accountancy (B.Acy. or B.Acc. or B. Accty)",
		},
		{
			value: "Bachelor of Comptrolling (B.Acc.Sci. or B.Compt.)",
			label: "Bachelor of Comptrolling (B.Acc.Sci. or B.Compt.)",
		},
		{
			value:
				"Bachelor of Economics (BEc, BEconSc; sometimes BA(Econ) or BSc(Econ))",
			label:
				"Bachelor of Economics (BEc, BEconSc; sometimes BA(Econ) or BSc(Econ))",
		},
		{
			value: "Bachelor of Arts in Organizational Management (BAOM)",
			label: "Bachelor of Arts in Organizational Management (BAOM)",
		},
		{
			value: "Bachelor of Computer Science (BCompSc)",
			label: "Bachelor of Computer Science (BCompSc)",
		},
		{
			value: "Bachelor of Computing (BComp)",
			label: "Bachelor of Computing (BComp)",
		},
		{
			value: "Bachelor of Science in Information Technology (BSc IT)",
			label: "Bachelor of Science in Information Technology (BSc IT)",
		},
		{
			value: "Bachelor of Computer Applications (BCA)",
			label: "Bachelor of Computer Applications (BCA)",
		},
		{
			value: "Bachelor of Business Information Systems (BBIS)",
			label: "Bachelor of Business Information Systems (BBIS)",
		},
		{
			value: "Intercalated Bachelor of Science (BSc)",
			label: "Intercalated Bachelor of Science (BSc)",
		},
		{
			value: "Bachelor of Medical Science (BMedSci)",
			label: "Bachelor of Medical Science (BMedSci)",
		},
		{
			value: "Bachelor of Medical Biology (BMedBiol)",
			label: "Bachelor of Medical Biology (BMedBiol)",
		},
		{
			value: "Bachelor of Science in Public Health (BSPH)",
			label: "Bachelor of Science in Public Health (BSPH)",
		},
		{
			value:
				"Bachelor of Science in Nursing (BN, BNSc, BScN, BSN, BNurs, BSN, BHSc.)",
			label:
				"Bachelor of Science in Nursing (BN, BNSc, BScN, BSN, BNurs, BSN, BHSc.)",
		},
		{
			value: "Bachelor of Health Science (BHS & BHSc)",
			label: "Bachelor of Health Science (BHS & BHSc)",
		},
		{
			value: "Bachelor of Kinesiology (BKin, BSc(Kin), BHK)",
			label: "Bachelor of Kinesiology (BKin, BSc(Kin), BHK)",
		},
		{
			value: "Bachelor of Arts for Teaching (BAT)",
			label: "Bachelor of Arts for Teaching (BAT)",
		},
		{
			value: "Bachelor of Aviation (BAvn)",
			label: "Bachelor of Aviation (BAvn)",
		},
		{
			value: "Bachelor of Divinity (BD or BDiv)",
			label: "Bachelor of Divinity (BD or BDiv)",
		},
		{
			value: "Bachelor of Theology (B.Th.; Th.B. or BTheol)",
			label: "Bachelor of Theology (B.Th.; Th.B. or BTheol)",
		},
		{
			value: "Bachelor of Religious Education (BRE)",
			label: "Bachelor of Religious Education (BRE)",
		},
		{
			value: "Bachelor of Religious Studies (BRS)",
			label: "Bachelor of Religious Studies (BRS)",
		},
		{
			value: "Bachelor of Film and Television (BF&TV)",
			label: "Bachelor of Film and Television (BF&TV)",
		},
		{
			value: "Bachelor of Integrated studies (BIS)",
			label: "Bachelor of Integrated studies (BIS)",
		},
		{
			value: "Bachelor of Journalism (BJ, BAJ, BSJ or BJourn)",
			label: "Bachelor of Journalism (BJ, BAJ, BSJ or BJourn)",
		},
		{
			value: "Bachelor of Landscape Architecture (BLArch)",
			label: "Bachelor of Landscape Architecture (BLArch)",
		},
		{
			value: "Bachelor of Liberal Arts (B.L.A.; occasionally A.L.B.)",
			label: "Bachelor of Liberal Arts (B.L.A.; occasionally A.L.B.)",
		},
		{
			value: "Bachelor of General Studies (BGS, BSGS)",
			label: "Bachelor of General Studies (BGS, BSGS)",
		},
		{
			value: "Bachelor of Science in Human Biology (BSc)",
			label: "Bachelor of Science in Human Biology (BSc)",
		},
		{
			value: "Bachelor of Applied Studies (BAS)",
			label: "Bachelor of Applied Studies (BAS)",
		},
		{
			value: "Bachelor of Liberal Studies",
			label: "Bachelor of Liberal Studies",
		},
		{
			value: "Bachelor of Professional Studies (BPS)",
			label: "Bachelor of Professional Studies (BPS)",
		},
		{
			value: "Bachelor of Library Science (B.L.S., B.Lib.)",
			label: "Bachelor of Library Science (B.L.S., B.Lib.)",
		},
		{
			value: "Bachelor of Library and Information Science (B.L.I.S.)",
			label: "Bachelor of Library and Information Science (B.L.I.S.)",
		},
		{
			value: "Bachelor of Music (BM or BMus)",
			label: "Bachelor of Music (BM or BMus)",
		},
		{
			value: "Bachelor of Art in Music (BA in Music)",
			label: "Bachelor of Art in Music (BA in Music)",
		},
		{
			value: "Bachelor of Music Education (BME)",
			label: "Bachelor of Music Education (BME)",
		},
		{
			value: "Bachelor of Philosophy (BPhil, PhB)",
			label: "Bachelor of Philosophy (BPhil, PhB)",
		},
		{
			value: "Bachelor of Arts in Psychology (BAPSY)",
			label: "Bachelor of Arts in Psychology (BAPSY)",
		},
		{
			value: "Bachelor of Mortuary Science (BMS)",
			label: "Bachelor of Mortuary Science (BMS)",
		},
		{
			value: "Bachelor of Science in Psychology (BSc(Psych)",
			label: "Bachelor of Science in Psychology (BSc(Psych)",
		},
		{
			value: "Bachelor of Science in Education (BSE, BS in Ed)",
			label: "Bachelor of Science in Education (BSE, BS in Ed)",
		},
		{
			value: "Bachelor of Science and/with education degree (BScEd)",
			label: "Bachelor of Science and/with education degree (BScEd)",
		},
		{
			value: "Bachelor of Science in Forestry (B.S.F. or B.Sc.F.)",
			label: "Bachelor of Science in Forestry (B.S.F. or B.Sc.F.)",
		},
		{
			value: "Bachelor of Applied Science (BASc)",
			label: "Bachelor of Applied Science (BASc)",
		},
		{
			value: "Bachelor of Science in Law (BSL)",
			label: "Bachelor of Science in Law (BSL)",
		},
		{
			value: "Bachelor of Social Science (BSocSc)",
			label: "Bachelor of Social Science (BSocSc)",
		},
		{
			value: "Bachelor of Arts in Social Work (BSW or BASW)",
			label: "Bachelor of Arts in Social Work (BSW or BASW)",
		},
		{
			value: "Bachelor of Talmudic Law (BTL)",
			label: "Bachelor of Talmudic Law (BTL)",
		},
		{
			value: "Bachelor of Technology (B.Tech)",
			label: "Bachelor of Technology (B.Tech)",
		},
		{
			value: "Bachelor of Tourism Studies (BTS)",
			label: "Bachelor of Tourism Studies (BTS)",
		},
		{
			value: "Bachelor of Mathematics (BMath)",
			label: "Bachelor of Mathematics (BMath)",
		},
		{
			value: "Bachelor of Mathematical Sciences (BMathSc)",
			label: "Bachelor of Mathematical Sciences (BMathSc)",
		},
		{
			value: "Bachelor of Public Affairs and Policy Management (BPAPM)",
			label: "Bachelor of Public Affairs and Policy Management (BPAPM)",
		},
		{
			value: "Bachelor of Urban and Regional Planning (BURP and BPlan)",
			label: "Bachelor of Urban and Regional Planning (BURP and BPlan)",
		},
		{ value: "ACCA/CA", label: "ACCA/CA" },
		{ value: "PHARM-D", label: "PHARM-D" },
		{ value: "Other", label: "Other" },
	];
	const months = {
		"01": "January",
		"02": "February",
		"03": "March",
		"04": "April",
		"05": "May",
		"06": "June",
		"07": "July",
		"08": "August",
		"09": "September",
		10: "October",
		11: "November",
		12: "December",
	};
	const MasterOptions = [
		{
			value: "Master of Accountancy (MAcc, MAc, or MAcy)",
			label: "Master of Accountancy (MAcc, MAc, or MAcy)",
		},
		{ value: "Master of Accounting", label: "Master of Accounting" },
		{ value: "Master of Archaeology", label: "Master of Archaeology" },
		{ value: "Master of Anthropology", label: "Master of Anthropology" },
		{ value: "Master of Aviation", label: "Master of Aviation" },
		{ value: "Master of Art", label: "Master of Art" },
		{
			value: "Master of Art Administration",
			label: "Master of Art Administration",
		},
		{ value: "Master of Architecture", label: "Master of Architecture" },
		{ value: "Master of Astronomy", label: "Master of Astronomy" },
		{
			value: "Master of Aerospace Engineering",
			label: "Master of Aerospace Engineering",
		},
		{
			value: "Master of Advanced Study (MAS)",
			label: "Master of Advanced Study (MAS)",
		},
		{
			value: "Master of Economics (MEcon)",
			label: "Master of Economics (MEcon)",
		},
		{
			value: "Master of Environmental Management",
			label: "Master of Environmental Management",
		},
		{
			value: "Master of Architecture (MArch)",
			label: "Master of Architecture (MArch)",
		},
		{
			value: "Master of Architecture (MArch)",
			label: "Master of Architecture (MArch)",
		},
		{
			value: "Master of Applied Science (MASc, MAppSc, MApplSc, MASc and MAS)",
			label: "Master of Applied Science (MASc, MAppSc, MApplSc, MASc and MAS)",
		},
		{
			value: "Master of Arts (MA, MA, AM, or AM)",
			label: "Master of Arts (MA, MA, AM, or AM)",
		},
		{
			value: "Master of Arts in Teaching (MAT)",
			label: "Master of Arts in Teaching (MAT)",
		},
		{
			value: "Master of Arts in Liberal Studies (MA, ALM, MLA, MLS or MALS)",
			label: "Master of Arts in Liberal Studies (MA, ALM, MLA, MLS or MALS)",
		},
		{ value: "Master of Business (MBus)", label: "Master of Business (MBus)" },
		{ value: "Master of Biology", label: "Master of Biology" },
		{
			value: "Master of Built Environment",
			label: "Master of Built Environment",
		},
		{
			value: "Master of Biomedical Sciences",
			label: "Master of Biomedical Sciences",
		},
		{
			value: "Master of Business Studies",
			label: "Master of Business Studies",
		},
		{
			value: "Master of Business Administration (MBA or MBA)",
			label: "Master of Business Administration (MBA or MBA)",
		},
		{
			value: "Master of Business Informatics (MBI)",
			label: "Master of Business Informatics (MBI)",
		},
		{
			value: "Master of Civil Engineering",
			label: "Master of Civil Engineering",
		},
		{ value: "Master of City Planning", label: "Master of City Planning" },
		{
			value: "Master of Computer Science",
			label: "Master of Computer Science",
		},
		{ value: "Master of Construction", label: "Master of Construction" },
		{ value: "Master of Civil Law", label: "Master of Civil Law" },
		{ value: "Master of Classics", label: "Master of Classics" },
		{ value: "Master of Catering", label: "Master of Catering" },
		{ value: "Master of Computing", label: "Master of Computing" },
		{ value: "Master of Criminal Law", label: "Master of Criminal Law" },
		{ value: "Master of Counselling", label: "Master of Counselling" },
		{
			value: "Master of Cultural Studies",
			label: "Master of Cultural Studies",
		},
		{ value: "Master of Crafts", label: "Master of Crafts" },
		{
			value: "Master of Complementary Health",
			label: "Master of Complementary Health",
		},
		{
			value: "Master of Chemical and Materials Engineering",
			label: "Master of Chemical and Materials Engineering",
		},
		{
			value: "Master of Chemistry (MChem)",
			label: "Master of Chemistry (MChem)",
		},
		{
			value: "Master of Commerce (MCom or MComm)",
			label: "Master of Commerce (MCom or MComm)",
		},
		{
			value: "Master of Computational Finance (or Quantitative Finance)",
			label: "Master of Computational Finance (or Quantitative Finance)",
		},
		{
			value: "Master of Computer Applications (MCA)",
			label: "Master of Computer Applications (MCA)",
		},
		{
			value: "Master in Creative Technologies",
			label: "Master in Creative Technologies",
		},
		{
			value: "Master of Criminal Justice (MCJ)",
			label: "Master of Criminal Justice (MCJ)",
		},
		{
			value: "Master of Design (MDes, MDes or MDesign)",
			label: "Master of Design (MDes, MDes or MDesign)",
		},
		{ value: "Master of Divinity (MDiv)", label: "Master of Divinity (MDiv)" },
		{ value: "Master of Dance", label: "Master of Dance" },
		{ value: "Master of Dentistry", label: "Master of Dentistry" },
		{
			value: "Master of Economics (MEcon)",
			label: "Master of Economics (MEcon)",
		},
		{ value: "Master of English Studies", label: "Master of English Studies" },
		{ value: "Master of Earth Sciences", label: "Master of Earth Sciences" },
		{
			value: "Master of Environmental Sciences",
			label: "Master of Environmental Sciences",
		},
		{ value: "Master of E-Commerce", label: "Master of E-Commerce" },
		{
			value: "Master of Entrepreneurship",
			label: "Master of Entrepreneurship",
		},
		{
			value: "Master of Education (MEd, MEd, EdM, MAEd, MSEd, MSE, or MEdL)",
			label: "Master of Education (MEd, MEd, EdM, MAEd, MSEd, MSE, or MEdL)",
		},
		{
			value: "Master of Enterprise (MEnt)",
			label: "Master of Enterprise (MEnt)",
		},
		{
			value: "Master of Engineering (MEng, ME or MEng)",
			label: "Master of Engineering (MEng, ME or MEng)",
		},
		{
			value: "Master of Engineering Management (MEM)",
			label: "Master of Engineering Management (MEM)",
		},
		{
			value: "Master of European Law (LLM Eur)",
			label: "Master of European Law (LLM Eur)",
		},
		{
			value: "Master of Electrical Engineering",
			label: "Master of Electrical Engineering",
		},
		{
			value: "Master of Electronic Engineering",
			label: "Master of Electronic Engineering",
		},
		{
			value: "Master of Environmental Engineering",
			label: "Master of Environmental Engineering",
		},
		{ value: "Master of Finance (MFin)", label: "Master of Finance (MFin)" },
		{
			value: "Master of Financial Mathematics (Master of Quantitative Finance)",
			label: "Master of Financial Mathematics (Master of Quantitative Finance)",
		},
		{
			value: "Master of Financial Engineering (Master of Quantitative Finance)",
			label: "Master of Financial Engineering (Master of Quantitative Finance)",
		},
		{
			value: "Master of Financial Economics",
			label: "Master of Financial Economics",
		},
		{
			value: "Master of Forensic science",
			label: "Master of Forensic science",
		},
		{
			value: "Master of Fashion and Textile Design",
			label: "Master of Fashion and Textile Design",
		},
		{
			value: "Master of Film & Television",
			label: "Master of Film & Television",
		},
		{
			value: "Master of General Sciences",
			label: "Master of General Sciences",
		},
		{
			value: "Master of General Engineering and Technology",
			label: "Master of General Engineering and Technology",
		},
		{ value: "Master of Graphic Design", label: "Master of Graphic Design" },
		{ value: "Master of General Studies", label: "Master of General Studies" },
		{
			value: "Master of Food Science and Technology",
			label: "Master of Food Science and Technology",
		},
		{
			value: "Master of Fine Arts (MFA, MFA)",
			label: "Master of Fine Arts (MFA, MFA)",
		},
		{
			value: "Master of Food and Drink Production",
			label: "Master of Food and Drink Production",
		},
		{
			value: "Master of Health Administration (MHA)",
			label: "Master of Health Administration (MHA)",
		},
		{
			value: "Master of Health Science (MHS)",
			label: "Master of Health Science (MHS)",
		},
		{ value: "Master of Humanities (MH)", label: "Master of Humanities (MH)" },
		{ value: "Master of Health Studies", label: "Master of Health Studies" },
		{ value: "Master of History", label: "Master of History" },
		{
			value: "Master of Human Resource Management",
			label: "Master of Human Resource Management",
		},
		{ value: "Master of Human Geography", label: "Master of Human Geography" },
		{ value: "Master of Hospitality", label: "Master of Hospitality" },
		{
			value: "Master of Hotel Management",
			label: "Master of Hotel Management",
		},
		{
			value: "Master of Industrial and Labor Relations (MILR)",
			label: "Master of Industrial and Labor Relations (MILR)",
		},
		{
			value: "Master of International Affairs",
			label: "Master of International Affairs",
		},
		{
			value: "Master of International Business",
			label: "Master of International Business",
		},
		{
			value: "Master of International Studies (MIS)",
			label: "Master of International Studies (MIS)",
		},
		{
			value: "Master of Industrial Design",
			label: "Master of Industrial Design",
		},
		{ value: "Master of Interior Design", label: "Master of Interior Design" },
		{
			value: "Master of International Law",
			label: "Master of International Law",
		},
		{
			value: "Masters in International Economics",
			label: "Masters in International Economics",
		},
		{
			value: "Masters in International Development",
			label: "Masters in International Development",
		},
		{
			value: "Masters in International relations",
			label: "Masters in International relations",
		},
		{
			value:
				"Master of Information System Management (abbreviated MISM, MSIM, MIS or similar)",
			label:
				"Master of Information System Management (abbreviated MISM, MSIM, MIS or similar)",
		},
		{
			value: "Master of IT (abbreviated MSIT, MScIT, MScIT, MScIT or MSc IT)",
			label: "Master of IT (abbreviated MSIT, MScIT, MScIT, MScIT or MSc IT)",
		},
		{
			value: "Master of Jurisprudence (MJ or MJur)",
			label: "Master of Jurisprudence (MJ or MJur)",
		},
		{ value: "Master of Journalism", label: "Master of Journalism" },
		{
			value: "Master of Laws (LLM or LLM)",
			label: "Master of Laws (LLM or LLM)",
		},
		{
			value: "Master of Studies in Law (MSL)",
			label: "Master of Studies in Law (MSL)",
		},
		{
			value: "Master of Landscape Architecture (MArch)",
			label: "Master of Landscape Architecture (MArch)",
		},
		{ value: "Master of Letters (MLitt)", label: "Master of Letters (MLitt)" },
		{ value: "Master of Life Sciences", label: "Master of Life Sciences" },
		{ value: "Master of Languages", label: "Master of Languages" },
		{
			value: "Master of Leisure Management",
			label: "Master of Leisure Management",
		},
		{ value: "Master of Literature", label: "Master of Literature" },
		{ value: "Master of Legal Advice", label: "Master of Legal Advice" },
		{ value: "Master of Legal Studies", label: "Master of Legal Studies" },
		{
			value: "Master of Liberal Arts (MA, ALM, MLA, MLS or MALS)",
			label: "Master of Liberal Arts (MA, ALM, MLA, MLS or MALS)",
		},
		{
			value: "Master of Library and Information Science (MLIS)",
			label: "Master of Library and Information Science (MLIS)",
		},
		{ value: "Master of Library Studies", label: "Master of Library Studies" },
		{ value: "Master of Linguistics", label: "Master of Linguistics" },
		{ value: "Master of Management (MM)", label: "Master of Management (MM)" },
		{
			value: "Master of Maintenance Services",
			label: "Master of Maintenance Services",
		},
		{ value: "Master of Marketing", label: "Master of Marketing" },
		{ value: "Master of Multimedia", label: "Master of Multimedia" },
		{ value: "Master of Media", label: "Master of Media" },
		{ value: "Master of Midwifery", label: "Master of Midwifery" },
		{ value: "Master of Museum Studies", label: "Master of Museum Studies" },
		{ value: "Master of Medicine", label: "Master of Medicine" },
		{ value: "Master of Metallurgy", label: "Master of Metallurgy" },
		{
			value: "Master of Mining and Oil & Gas Operations",
			label: "Master of Mining and Oil & Gas Operations",
		},
		{
			value: "Master of Mechanical Engineering",
			label: "Master of Mechanical Engineering",
		},
		{
			value: "Master of Materials Sciences",
			label: "Master of Materials Sciences",
		},
		{
			value: "Master of Mathematics (or MMath)",
			label: "Master of Mathematics (or MMath)",
		},
		{
			value: "Master of Mathematical Finance",
			label: "Master of Mathematical Finance",
		},
		{ value: "Master of Medical Science", label: "Master of Medical Science" },
		{
			value: "Master of Marine Engineering",
			label: "Master of Marine Engineering",
		},
		{
			value: "Master of Manufacturing and Production",
			label: "Master of Manufacturing and Production",
		},
		{
			value: "Master of Music (MM or MMus)",
			label: "Master of Music (MM or MMus)",
		},
		{
			value: "Master of Non-industrial Design",
			label: "Master of Non-industrial Design",
		},
		{ value: "Master of Nursing", label: "Master of Nursing" },
		{
			value: "Master of Nutrition and Health",
			label: "Master of Nutrition and Health",
		},
		{
			value: "Master of Occupational Therapy (OT)",
			label: "Master of Occupational Therapy (OT)",
		},
		{ value: "Master of Ophthalmology", label: "Master of Ophthalmology" },
		{
			value: "Master of Office Administration",
			label: "Master of Office Administration",
		},
		{
			value: "Master of Pharmacy (MPharm or MPharm)",
			label: "Master of Pharmacy (MPharm or MPharm)",
		},
		{
			value: "Master of Philosophy (MPhil)",
			label: "Master of Philosophy (MPhil)",
		},
		{ value: "Master of Physics (MPhys)", label: "Master of Physics (MPhys)" },
		{ value: "Master of Planning", label: "Master of Planning" },
		{
			value: "Master of Property Management",
			label: "Master of Property Management",
		},
		{
			value: "Master of Physician Assistant Studies",
			label: "Master of Physician Assistant Studies",
		},
		{
			value: "Master of Political Science",
			label: "Master of Political Science",
		},
		{
			value: "Master of Physical Geography",
			label: "Master of Physical Geography",
		},
		{
			value: "Master of Professional Studies (MPS or MPS)",
			label: "Master of Professional Studies (MPS or MPS)",
		},
		{
			value: "Master of Public Administration (MPA)",
			label: "Master of Public Administration (MPA)",
		},
		{
			value: "Master of Public Affairs (MPAff)",
			label: "Master of Public Affairs (MPAff)",
		},
		{
			value: "Master of Public Health (MPH)",
			label: "Master of Public Health (MPH)",
		},
		{
			value: "Master of Public Management",
			label: "Master of Public Management",
		},
		{
			value: "Master of Public Policy (MPP)",
			label: "Master of Public Policy (MPP)",
		},
		{ value: "Master of Public Law", label: "Master of Public Law" },
		{ value: "Master of Pharmacology", label: "Master of Pharmacology" },
		{ value: "Master of Physiology", label: "Master of Physiology" },
		{ value: "Master of Physiotherapy", label: "Master of Physiotherapy" },
		{ value: "Master of Public Health", label: "Master of Public Health" },
		{ value: "Master of Philosophy", label: "Master of Philosophy" },
		{ value: "Master of Photography", label: "Master of Photography" },
		{
			value: "Master of Public Administration",
			label: "Master of Public Administration",
		},
		{ value: "Master of Politics", label: "Master of Politics" },
		{
			value: "Master of Power and Energy Engineering",
			label: "Master of Power and Energy Engineering",
		},
		{
			value: "Master of Quantitative Finance",
			label: "Master of Quantitative Finance",
		},
		{ value: "Master of Quality Control", label: "Master of Quality Control" },
		{
			value: "Master of Quality Management",
			label: "Master of Quality Management",
		},
		{
			value: "Master of Rabbinic Studies (MRb)",
			label: "Master of Rabbinic Studies (MRb)",
		},
		{
			value: "Master of Real Estate Development",
			label: "Master of Real Estate Development",
		},
		{
			value: "Master of Religious Education",
			label: "Master of Religious Education",
		},
		{
			value: "Master of Research - MSc(R)",
			label: "Master of Research - MSc(R)",
		},
		{ value: "Master of Retail", label: "Master of Retail" },
		{
			value: "Master of Regional Studies",
			label: "Master of Regional Studies",
		},
		{
			value: "Master of Religious Studies",
			label: "Master of Religious Studies",
		},
		{
			value: "Master of Sacred Theology (STM)",
			label: "Master of Sacred Theology (STM)",
		},
		{
			value: "Master of Sacred Music (MSM)",
			label: "Master of Sacred Music (MSM)",
		},
		{
			value:
				"Master of Science (MSc, MSc, MSci, MSi, ScM, MS, MSHS, MS, Mag, Mg, Mgr, SM, or SM)",
			label:
				"Master of Science (MSc, MSc, MSci, MSi, ScM, MS, MSHS, MS, Mag, Mg, Mgr, SM, or SM)",
		},
		{
			value: "Master of Science in Education",
			label: "Master of Science in Education",
		},
		{
			value: "Master of Science in Engineering (MSE)",
			label: "Master of Science in Engineering (MSE)",
		},
		{
			value: "Master of Science in Finance (MFin)",
			label: "Master of Science in Finance (MFin)",
		},
		{
			value: "Master of Science in Human Resource Development (HRD or MSHRD)",
			label: "Master of Science in Human Resource Development (HRD or MSHRD)",
		},
		{
			value: "Master of Science in Information Systems Management (MSMIS)",
			label: "Master of Science in Information Systems Management (MSMIS)",
		},
		{
			value: "Master of Science in Information Systems (MSIS)",
			label: "Master of Science in Information Systems (MSIS)",
		},
		{
			value:
				"Master of Science in Information Technology (MSIT, MScIT, MScIT, MScIT or MSc IT)",
			label:
				"Master of Science in Information Technology (MSIT, MScIT, MScIT, MScIT or MSc IT)",
		},
		{
			value: "Master of Science in Nursing (MSN)",
			label: "Master of Science in Nursing (MSN)",
		},
		{
			value: "Master of Science in Project Management (MSPM)",
			label: "Master of Science in Project Management (MSPM)",
		},
		{
			value: "Master of Science in Management (MSc or MSM)",
			label: "Master of Science in Management (MSc or MSM)",
		},
		{
			value: "Master of Science in Leadership (MSL)",
			label: "Master of Science in Leadership (MSL)",
		},
		{
			value: "Master of Science in Supply Chain Management (SCM or MSSCM)",
			label: "Master of Science in Supply Chain Management (SCM or MSSCM)",
		},
		{
			value: "Master of Science in Taxation",
			label: "Master of Science in Taxation",
		},
		{
			value: "Master of Science in Transportation and Logistics",
			label: "Master of Science in Transportation and Logistics",
		},
		{
			value: "Master of Science in Teaching (MST)",
			label: "Master of Science in Teaching (MST)",
		},
		{
			value: "Master of Social Work (MSW)",
			label: "Master of Social Work (MSW)",
		},
		{
			value: "Master of Social Science (MSSc)",
			label: "Master of Social Science (MSSc)",
		},
		{
			value: "Master of Surgery (ChM or MS, as well as MCh and MChir)",
			label: "Master of Surgery (ChM or MS, as well as MCh and MChir)",
		},
		{
			value: "Master of Studies (MSt or MSt)",
			label: "Master of Studies (MSt or MSt)",
		},
		{ value: "Master of Sports Science", label: "Master of Sports Science" },
		{ value: "Master of Software", label: "Master of Software" },
		{ value: "Master of Surveying", label: "Master of Surveying" },
		{ value: "Master of Social Work", label: "Master of Social Work" },
		{ value: "Master of Sociology", label: "Master of Sociology" },
		{ value: "Master of Social Sciences", label: "Master of Social Sciences" },
		{
			value: "Master of Structural Engineering",
			label: "Master of Structural Engineering",
		},
		{
			value: "Master of Telecommunications",
			label: "Master of Telecommunications",
		},
		{
			value: "Master of Travel and Tourism",
			label: "Master of Travel and Tourism",
		},
		{
			value: "Master of Theology (ThM or MTh)",
			label: "Master of Theology (ThM or MTh)",
		},
		{
			value: "Master of Theological Studies (MTS)",
			label: "Master of Theological Studies (MTS)",
		},
		{
			value: "Master of Theatre and Drama Studies",
			label: "Master of Theatre and Drama Studies",
		},
		{ value: "Master of Urban Planning", label: "Master of Urban Planning" },
		{
			value: "Master of Veterinary Science (MVSC or MVSc)",
			label: "Master of Veterinary Science (MVSC or MVSc)",
		},
		{
			value: "Master of Vehicle Engineering",
			label: "Master of Vehicle Engineering",
		},
		{ value: "Master of Writing", label: "Master of Writing" },
	];
	let countriesOption = getData();
	countriesOption = countriesOption.map(function (item) {
		return {
			value: item.code,
			label: item.name,
		};
	});
	const dispatch = useDispatch();
	const [date, setDate] = useState();

	const [fullname, setFullname] = useState("");
	const [firstname, setFirstName] = useState(localStorage.getItem("fname"));
	const [lastname, setLastName] = useState(localStorage.getItem("lname"));
	const [email, setEmail] = useState(localStorage.getItem("user_email"));
	const [phoneType, setPhoneType] = useState("");
	const [phNumber, setPhNumber] = useState(localStorage.getItem("user_number"));
	const [countryCode, setCountryCode] = useState("");
	const [start, setStart] = useState("");
	const [residence, setResidence] = useState("");
	const [residenceError, setResidenceError] = useState("");
	const [citizenship, setCitizenship] = useState("");
	const [citizenshipError, setCitizenshipError] = useState("");
	const [selectCourse, setSelectCourse] = useState("");
	const [selectCourseError, setSelectCourseError] = useState("");
	const [currentCity, setCurrentCity] = useState("");
	const [currentCityError, setCurrentCityError] = useState("");
	const [preferredCountry, setPreferredCountry] = useState("");
	const [preferredCountryError, setPreferredCountryError] = useState("");

	const [fullnameError, setFullnameError] = useState("");
	const [firstnameError, setFirstnameError] = useState("");
	const [lastnameError, setLastnameError] = useState("");
	const [emailError, setEmailError] = useState("");
	const [phoneTypeError, setPhoneTypeError] = useState("");
	const [phNumberError, setPhNumberError] = useState("");
	const [courseError, setCourseError] = useState("");
	const [reqCheck, setReqCheck] = useState(false);
	const [reqCheckError, setReqCheckError] = useState("");
	const [startError, setStartError] = useState("");
	const [resultError,setResultError]=useState("");
	const [schoolQualificationError,setSchoolQualificationError] = useState("");
	const [collegeQualificationError,setCollegeQualificationError]=useState("");
	const [englishTestScoreError,setEnglishTestScoreError]=useState("");

const [studyLevelError,setStudyLevelError]=useState("");
const [englishTestError,setEnglishTestError]=useState("");
const [examTestError,setExamTestError]=useState("");
	const [englishTestScheduleDateError,setEnglishTestScheduleDateError]=useState("");
	const [schoolQualification, setSchoolQualification] = useState("");
	const [collegeQualification, setCollegeQualification] = useState("");
	const [bachelorQualification, setBachelorQualification] = useState("");
	const [masterQualification, setMasterQualification] = useState("");
	const [masterYear, setMasterYear] = useState("");
	const [bachelorYear, setBachelorYear] = useState("");
	const [collegeYear, setCollegeYear] = useState("");
	const [schoolYear, setSchoolYear] = useState("");
	const [englishProficiency, setEnglishProficiency] = useState("");
	const [englishTestType, setEnglishTestType] = useState("");
	const [englishTestScore, setEnglishTestScore] = useState("");
	const [englishTestSpeakingScore, setEnglishTestSpeakingScore] = useState("");
	const [englishTestListeningScore, setEnglishTestListeningScore] =
		useState("");
	const [englishTestReadingScore, setEnglishTestReadingScore] = useState("");
	const [englishTestWritingScore, setEnglishTestWritingScore] = useState("");
	const [englishTestScheduleDate, setEnglishTestScheduleDate] = useState("");
	const [englishTestTakenDate, setEnglishTestTakenDate] = useState("");
	const [satTest, setSatTestAppeared] = useState(false);
	const [gmatTest, setGmatTestAppeared] = useState(false);
	const [lsatTest, setLsatTestAppeared] = useState(false);
	const [greTest, setGreTestAppeared] = useState(false);
	const [mcatTest, setMcatTestAppeared] = useState(false);
	const [examInAppearedScore, setExamInAppearedScore] = useState("");
	const [levelForStudy, setLevelForStudy] = useState("");
	const [courseObject, setCourseObject] = useState(null);

	const examTestHandleChange = (value) => {
			if (value.target.value === "sat") {
			setSatTestAppeared(true);
			setExamTestError("");
		} else if (value.target.value === "gmat") {
			setGmatTestAppeared(true);
			setExamTestError("");
		} else if (value.target.value === "lsat") {
			setLsatTestAppeared(true);
			setExamTestError("");
		} else if (value.target.value === "gre") {
			setGreTestAppeared(true);
			setExamTestError("");
		} else if (value.target.value === "mcat") {
			setMcatTestAppeared(true);
			setExamTestError("");
		}else {
			setExamTestError("required");
		}
	};

	const examTestScoreHandleChange = (value) => {
		if(value.target.value)
		{
		setExamInAppearedScore(value.target.value);
		setResultError("")
		}else {
			setExamInAppearedScore("")
			setResultError("required");

		}
	};

	const schoolQualificationHandleChange = (value) => {
		setSchoolQualification(value);
	};

	const englishTestTypeHandleChange = (value) => {
		setEnglishTestType(value);
	};

	const handleEnglishTestScoreChange = (value) => {
		setEnglishTestScore(value);
	};

	const handleListeningScoreChange = (value) => {
		setEnglishTestListeningScore(value);
	};

	const handleSpeakingScoreChange = (value) => {
		setEnglishTestSpeakingScore(value);
	};

	const handleReadingScoreChange = (value) => {
		setEnglishTestReadingScore(value);
	};

	const handleWritingScoreChange = (value) => {
		setEnglishTestWritingScore(value);
	};

	const handleEnglishTestTakenDateChange = (value) => {
		setEnglishTestTakenDate(value.target.value);
	};

	const handleEnglishScheduledTestDate = (value) => {
		if(value.target.value)
		{
			setEnglishTestScheduleDate(value.target.value);
			setEnglishTestScheduleDateError("");
		}
		else{
            setEnglishTestScheduleDate("");
			setEnglishTestScheduleDateError("required");
		}
	};

	const handleSchoolYear = (value) => {
		setSchoolYear(value.target.value.split("-")[0]);
	};

	const collegeQualificationHandleChange = (value) => {
		setCollegeQualification(value);
	};

	const handleCollegeYear = (value) => {
		setCollegeYear(value.target.value.split("-")[0]);
	};

	const bachelorQualificationHandleChange = (value) => {
		setBachelorQualification(value);
	};

	const handleBachelorsYear = (value) => {
		setBachelorYear(value.target.value.split("-")[0]);
	};

	const masterQualificationHandleChange = (value) => {
		setMasterQualification(value);
	};

	const handleMastersYear = (value) => {
		setMasterYear(value.target.value.split("-")[0]);
	};

	const onChangeEnglish = (value) => {
		if(value.target.value==="no")
		{
		setEnglishProficiency(value.target.value);
		setEnglishTestScheduleDateError("")
		}else if(value.target.value) {
		setEnglishProficiency(value.target.value);
		setEnglishTestError("");

		}else {
			setEnglishTestError("required");
		}
	};

	const handleLevelForStudyChange = (value) => {
	if(value.target.value)
		{
		setLevelForStudy(value.target.value);
		setStudyLevelError("")
		}else {
			setStudyLevelError("required");
			setLevelForStudy("");
		}

	};

	const showPopup = () => {
		return (
			<div>
				<div className="form-group">
					<Select
						name="English_Test_Type"
						placeholder={"Select English Test Type"}
						value={englishTestType}
						isSearchable={true}
						options={EnglishTestOptions}
						// className={ ? "is-invalid" : ""}
						onChange={englishTestTypeHandleChange}
						styles={customStyles}
					/>
					<img
						src={require("../../../static/images/forms/test_type.svg").default}
						alt="test_type"
						height=""
						width=""
						className="formIcon"
					/>
				</div>

				<div className="row scoreRow">
					<div className="col-md-4 form-group">
						<label>Date Taken</label>
						<div>
							<input
								type="month"
								onChange={handleEnglishTestTakenDateChange}
								className="form-control eligibility-form-control"
								placeholder="Date"
							></input>
						</div>
					</div>
					<div className="col-md-4 form-group">
						<label>Score</label>
						<div>
							<input
								type="number"
								onChange={handleEnglishTestScoreChange}
								className="form-control eligibility-form-control"
							></input>
						</div>
					</div>
					<div className="col-md-4 form-group">
						<label>Listening Score</label>
						<div>
							<input
								type="number"
								onChange={handleListeningScoreChange}
								className="form-control eligibility-form-control"
							></input>
						</div>
					</div>
				</div>
				<div className="row scoreRow">
					<div className="col-md-4 form-group">
						<label>Speaking Score</label>
						<div>
							<input
								type="number"
								onChange={handleSpeakingScoreChange}
								className="form-control eligibility-form-control"
							></input>
						</div>
					</div>
					<div className="col-md-4 form-group">
						<label>Reading Score</label>
						<div>
							<input
								type="number"
								onChange={handleReadingScoreChange}
								className="form-control eligibility-form-control"
							></input>
						</div>
					</div>
					<div className="col-md-4 form-group">
						<label>Writing Score</label>
						<div>
							<input
								type="number"
								onChange={handleWritingScoreChange}
								className="form-control eligibility-form-control"
							></input>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const currentCityHandleChange = (value) => {
		setCurrentCity(value);
	};
	const preferredCountryHandleChange = (value) => {
		setPreferredCountry(value);
	};
	const selectCourseHandleChange = (value) => {
		setSelectCourse(value);
	};
	const residenceHandleChange = (value) => {
		if(value)
		{
			setResidence(value);
			setResidenceError("");
		}else {
			setResidence("");
			setResidenceError("required");
		}
	};
	const citizenshipHandleChange = (value) => {
		if(value)
		{
		setCitizenship(value);
		setCitizenshipError("");
		}else {
			setCitizenship("");
			setCitizenshipError("required");
		}
	};

	function filteredCourse(obj) {
		const url = "/course/" + obj[0].value + "/crm_course_detail";
		const request = http.get(url);
		request.then((response) => {
			if (response.data) {
				setCourseObject(response.data);
			}
		});
	}

	useEffect(() => {
		disciplineGet();
	}, [dispatch]);

	const disciplineGet = async () => {
		const url = "discipline/";
		const request = await http.get(url);
		var data = request.data.results;
		if (data) {
			for (let i = 0; i < data.length; i++) {
				courseOptions.push({ value: data[i]["id"], label: data[i]["name"] });
			}
		}
	};
	const firstNameHandleChange = (value) => {
		if (!value) {
			setFirstnameError("First name is required");
		} else if (!/^[A-Za-z]+$/.test(value)) {
			setFirstnameError("invalid - name should be  a character.");
		} else {
			setFirstnameError("");
		}
		setFirstName(value);
	};
	const lastNameHandleChange = (value) => {
		if (!value) {
			setLastnameError("Last name is required");
		} else if (!/^[A-Za-z]+$/.test(value)) {
			setLastnameError("invalid - name should be a character.");
		} else {
			setLastnameError("");
		}
		setLastName(value);
	};
	const emailHandleChange = (value) => {
		if (!value) {
			setEmailError("Email is required");
		} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
			setEmailError("Invalid email address");
		} else {
			setEmailError("");
		}
		setEmail(value);
		const url = "http://192.168.18.150/crmapi/Mobile/apis/checkUser.php";
		const request = http.post(url, { mobile: phNumber, email_address: value });
		request.then((response) => {
			if (response.data) {
			}
		});
	};
	const phoneTypeHandleChange = (value) => {
		if (!value) {
			setPhoneTypeError("Phone-type is required");
		} else {
			setPhoneTypeError("");
		}
		setPhoneType(value);
	};
	const phNumberHandleChange = (value, data, event) => {
		if (!value) {
			setPhNumberError("Phone Number is required");
		} else if (!data.countryCode) {
			setPhNumberError("Country Code is required");
		} else {
			setPhNumberError("");
		}
		setCountryCode(data.countryCode);

		setPhNumber(value);
	};
	const startHandleChange = (value) => {
		if (value.target.value) {
			 setStart(value.target.value);
			 setDate(value.target.value)
			setStartError("");
		} else {
			 setStart("");
			setStartError("required");
		}
	};

	function onEligibilityCrossPress() {
		// clearAllInputs();
		// clearAllInputsError();
		localStorage.removeItem("CheckEligibilityModal");
		$("#eligibility").modal("hide");
	}

	function clearAllInputs() {
		setFullname("");
		setEmail("");
		setPhNumber("");
		setPhoneType("");
		setStart("");
		setCitizenship("");
		setResidence("");
		setReqCheck(false);
		setFirstName("");
		setLastName("");
		setSelectCourse("");
		setCurrentCity("");
		setPreferredCountry("");

		setCourseObject(null);
		setSatTestAppeared(false);
		setGmatTestAppeared(false);
		setLsatTestAppeared(false);
		setGreTestAppeared(false);
		setMcatTestAppeared(false);
		setEnglishProficiency(false);
		setEnglishTestScheduleDate(null);
		setLevelForStudy(null);
		setExamInAppearedScore("");
	}

	function clearAllInputsError() {
		setEmailError("");
		setFirstnameError("");
		setLastnameError("");
		setPhoneTypeError("");
		setPhNumberError("");
		setReqCheckError("");
		setStartError("");
		setResidenceError("");
		setCitizenshipError("");
		setEnglishTestScheduleDateError("");
		setEnglishTestError("");
		setStudyLevelError("");
		setResultError("")
		setExamTestError("");
	}

	async function applyNowOnSubmit(e) {
		e.preventDefault();
		if (
			firstname !== "" &&
			lastname !== "" &&
			email &&
			phNumber  &&
			Object.keys(residence)&&
			Object.keys(phoneType)&&
			Object.keys(citizenship) &&
			Object.keys(preferredCountry) &&
			reqCheck&&start&&examInAppearedScore&&
			(satTest===true||gmatTest===true||lsatTest===true||greTest===true||mcatTest===true)&&
			levelForStudy&&
			currentCity !== "" &&
			(bachelorQualification ||
				collegeQualification ||
				masterQualification ||
				schoolQualification)
		) {

			let intake_c = date;
			let data = {
				first_name: firstname,
				last_name: lastname,
				email: email,
				phone_type: phoneType,
				mobile: phNumber,
				city: currentCity,
				residence: residence,
				citizenship: citizenship,
				preferredCountry: preferredCountry,
				discipline: selectCourse,
				bachelors_degree: bachelorQualification,
				college_qualification: collegeQualification,
				masters_degree: masterQualification,
				school_qualification: schoolQualification,
				bachelor_passing_year: bachelorYear,
				college_passing_year: collegeYear,
				master_passing_year: masterYear,
				school_passing_year: schoolYear,
				english_test_schedule_date: englishTestScheduleDate,
				intake_c: intake_c,
				sat: satTest,
				gmat: gmatTest,
				lsat: lsatTest,
				greTest: greTest,
				mcat: mcatTest,
				result_c: examInAppearedScore,
				level_c: levelForStudy,
			};
			data = Object.assign({}, data, courseObject);
			// toast.success("Application Submitted");
			if (reqCheck===true&&emailError===""&&data.residence&&data.citizenship) {
				await httpCrm
					.post(
						`webForms.php?user_id=${localStorage.getItem("user_Id")}
					&first_name=${data.first_name}&last_name=${data.last_name}&email_address=${
							data.email
						}&mobile=${data.mobile}
					&city=${data.city}&residence= ${data.residence.label}&citizenship=${
							data.citizenship.label
						}&bachelors_degree= ${data.bachelors_degree.label}&
					school_qualification= ${
						data.school_qualification.label
					}&bachelor_passing_year= ${
							data.bachelor_passing_year
						},&college_passing_year= ${data.college_passing_year},&
					master_passing_year= ${data.master_passing_year},&school_passing_year= ${
							data.school_passing_year
						},&english_test_schedule_date= ${data.english_test_schedule_date},&
					intake_c= ${data.intake_c},&sat= ${data.sat},&gmat= ${data.gmat},&lsat= ${
							data.lsat
						},&greTest= ${data.greTest},&mcat= ${data.mcat},&result_c= ${
							data.result_c
						},&
					level_c= ${data.level_c},&course_id= ${data.course_id},&campus_name= ${
							data.campus_name
						},&country_name= ${data.country_name},&
					discipline_name= ${data.discipline_name},&institute_name= ${
							data.institute_name
						},&
					campus_address= ${data.campus_address},&course_language= ${
							data.course_language
						},&
					course_title=${data.course_title}&degree_level= ${
							data.degree_level
						},&institute_sector= ${data.institute_sector},&
					program_discipline= ${data.program_discipline},&specialization= ${
							data.specialization
						}&
					source=Check-Eligibility&college_qualification=${
						data.college_qualification.label
					}&masters_degree=${data.masters_degree.label}`
					)
					.then((res) => {
						if (res.data.status === 200) {

							props.ftn();

								$("#eligibility").modal("hide");

						} else {
							toast.error("Not submitted");
						}
					});
			}else {
				if(emailError)
				{
				setEmailError("*required");
				}
				if(data.residence===null)
				{
					setResidenceError("required");
				}
				if(data.citizenship===null)
				{
					setCitizenshipError("required");
				}
				if(phoneType===null)
				{
					setPhoneTypeError("required");
				}
			}

		} else {
				if (email === "") {
				setEmailError("Email is required");
			}
			if (fullname === "") {
				setFullnameError("Name is required");
			}
			if (phoneType === "") {
				setPhoneTypeError("Phone-type is required");
			}
			if (phNumber === "") {
				setPhNumberError("Phone number is required");
			}

			if (citizenship === "") {
				setCitizenshipError("Citizenship is required");
			}
			if (residence === "") {
				setResidenceError("Residence is required");
			}

			if (reqCheck === false) {
				setReqCheckError("required");
			}
			if(englishTestScheduleDate==="")
				{
					setEnglishTestScheduleDateError("required")
				}
			if(start==="")
			{
				setStartError("required");
			}
			if(examInAppearedScore==="")
			{
				setResultError("required");
			}
			if(satTest===false&&greTest===false&&gmatTest===false&&lsatTest===false&&mcatTest===false)
			{
				setExamTestError("required");
			}
			if(levelForStudy==="")
			{
				setStudyLevelError("required");
			}
			if(englishProficiency==="")
			{
				setEnglishTestError("required");
			}
			if (firstname === "") {
				setFirstnameError("First name is required");
			}
			if (lastname === "") {
				setLastnameError("Last name is required");
			}

			if (currentCity === "") {
				setCurrentCityError("Current city is required");
			}
			if (reqCheck === false) {
				setReqCheckError("*required");
			}
			// clearAllInputs();
		}
	}
	const checkBoxValidate = (event) => {
		if (event) {
			setReqCheck(event);
			setReqCheckError(null);
			return event;
		} else {
			setReqCheck(event);
		}
	};
	const handleAutoLocation = (location) => {
		setCurrentCity(location.address);
	};

	const selectedCourse = ({ parentCallback }) => {
		var filtered_course = null;
	};
	return (
		<>
			<div className="modal-dialog modal-dialog-centered" role="document">
				<div className="modal-content">
					<div className="modal-body">

						<button
							type="button"
							className="close"
							// data-dismiss="modal"
							onClick={onEligibilityCrossPress}
							aria-label="Close"
						>
							<img
								src={require("../../../static/images/modal-times.svg").default}
								alt="modal-times"
							/>
						</button>
						<div className="formSection">
							<div className="formSide">
								<h1>Register Now to check Eligibility</h1>
								<p>
									{props.instituteDetail?.institute_name}
									{/* {props.instituteDetail?.country.name} */}
								</p>
								<form>
									<div className="row">
										<div className="col-md-6">
											<div className="form-group">
												<input
													type="text"
													value={firstname}
													className={
														"form-control"

													}
													placeholder="Firstname"
													onChange={(e) =>
														firstNameHandleChange(e.target.value)
													}
												></input>
												<img
													src={
														require("../../../static/images/forms/full_name.svg")
															.default
													}
													alt="full_name"
													height=""
													width=""
													className="formIcon"
												/>

												{firstnameError ? (
													<Alert severity="error">{firstnameError}</Alert>
												) : null}
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<input
													type="text"
													value={lastname}
													className={
														"form-control"
													}
													placeholder="Lastname"
													onChange={(e) => lastNameHandleChange(e.target.value)}
												></input>
												<img
													src={
														require("../../../static/images/forms/full_name.svg")
															.default
													}
													alt="full_name"
													height=""
													width=""
													className="formIcon"
												/>

												{lastnameError ? (
													<Alert severity="error">{lastnameError}</Alert>
												) : null}
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-6">
											<div className="form-group">
												<Select
													name="filters"
													placeholder={"Phone Type"}
													value={phoneType}
													isSearchable={false}
													options={phoneTypeOptions}
													onChange={phoneTypeHandleChange}
													styles={customStyles}
												/>

												<img
													src={
														require("../../../static/images/forms/phone.svg")
															.default
													}
													alt="phone"
													height=""
													width=""
													className="formIcon"
												/>
												{phoneTypeError ? (
													<Alert severity="error">{phoneTypeError}</Alert>
												) : null}
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<PhoneInput
													value={phNumber}
													onChange={(value, data, event) =>
														phNumberHandleChange(value, data, event)
													}
													autoFormat
													country={"pk"}
													placeholder="Enter the Number"
													enableAreaCodes="true"
													className={
														"form-control" }
												/>

												{phNumberError ? (
													<Alert severity="error">{phNumberError}</Alert>
												) : null}
											</div>
										</div>
									</div>

									<div className="form-group">
										<input
											type="email"
											value={email}
											className={
												"form-control"
											}
											placeholder="Email Address"
											onChange={(e) => emailHandleChange(e.target.value)}
										></input>

										<img
											src={
												require("../../../static/images/forms/Email.svg")
													.default
											}
											alt="Email"
											height=""
											width=""
											className="formIcon"
										/>
										{emailError ? (
											<Alert severity="error">{emailError}</Alert>
										) : null}
									</div>

									<div className="form-group">
										{/*<Select*/}
										{/*	name="city"*/}
										{/*	placeholder={"Current city"}*/}
										{/*	value={currentCity}*/}
										{/*	isSearchable={false}*/}
										{/*	options={citiesOptions}*/}
										{/*	// className={phoneTypeError ? " is-invalid" : ""}*/}
										{/*	onChange={currentCityHandleChange}*/}
										{/*	styles={customStyles}*/}
										{/*/>*/}
										<LocationSearchInput
											onLoadAutoLocation={handleAutoLocation}
										/>
										<img
											src={
												require("../../../static/images/forms/preferred_location.svg")
													.default
											}
											alt="preferred_location"
											height=""
											width=""
											className="formIcon"
										/>
										{currentCityError && (
											<Alert severity="error">{currentCityError}</Alert>
										)}
									</div>

									<div className="form-group">
										<Select
											name="residense"
											placeholder={"Country of Residence"}
											value={residence}
											isSearchable={true}
											options={countriesOption}
											// className={ ? "is-invalid" : ""}
											onChange={residenceHandleChange}
											styles={customStyles}
										/>

										<img
											src={
												require("../../../static/images/forms/preferred_location.svg")
													.default
											}
											alt="preferred_location"
											height=""
											width=""
											className="formIcon"
										/>
										{residenceError ? (
											<Alert severity="error">{residenceError}</Alert>
										) : null}
									</div>

									<div className="form-group">
										<Select
											name="filters"
											placeholder={"Country of Citizenship (if different)"}
											value={citizenship}
											isSearchable={true}
											options={countriesOption}
											// className={ ? "is-invalid" : ""}
											onChange={citizenshipHandleChange}
											styles={customStyles}
										/>

										<img
											src={
												require("../../../static/images/forms/preferred_location.svg")
													.default
											}
											alt="preferred_location"
											height=""
											width=""
											className="formIcon"
										/>
										{citizenshipError && (
											<Alert severity="error">{citizenshipError}</Alert>
										)}
									</div>

									<CourseSelectionFilter filteredCourse={filteredCourse} />

									<div className="eligibilityEducation">
										<h4>Education Details</h4>
										<div className="flex">
											<p>
												Accurate detail will help us to match your right
												Universities and scholarships. Fill with recent highest
												qualification first.
											</p>
											<div>
												<div className="row">
													<div className="col-sm-6 col-12 ">
														<div className="form-group">
															<Select
																name="School_Qualification"
																placeholder={"School Qualification"}
																value={schoolQualification}
																isSearchable={true}
																options={SchoolOptions}
																// className={ ? "is-invalid" : ""}
																onChange={schoolQualificationHandleChange}
																styles={customStyles}
															/>
															<img
																src={
																	require("../../../static/images/forms/school.svg")
																		.default
																}
																alt="school"
																height=""
																width=""
																className="formIcon"
															/>
														</div>
													</div>
													<div className=" col-sm-6 col-12">
														<div className="form-group">
															<input
																onChange={handleSchoolYear}
																className="form-control eligibility-form-control"
																type="month"
																placeholder="Year"
															></input>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-sm-6 col-12">
														<div className="form-group">
															<Select
																name="intermediate_Qualification"
																placeholder={"Intermediate Qualification"}
																value={collegeQualification}
																isSearchable={true}
																options={CollegeOptions}
																// className={ ? "is-invalid" : ""}
																onChange={collegeQualificationHandleChange}
																styles={customStyles}
															/>
															<img
																src={
																	require("../../../static/images/forms/Intermediate.svg")
																		.default
																}
																alt="Intermediate"
																height=""
																width=""
																className="formIcon"
															/>
														</div>
													</div>
													<div className="col-sm-6 col-12">
														<div className="form-group">
															<input
																onChange={handleCollegeYear}
																className="form-control eligibility-form-control"
																type="month"
																placeholder="Year"
															></input>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-sm-6 col-12">
														<div className="form-group">
															<Select
																name="Bachelors_Qualification"
																placeholder={"Bachelors Qualification"}
																value={bachelorQualification}
																isSearchable={true}
																options={BachelorOptions}
																// className={ ? "is-invalid" : ""}
																onChange={bachelorQualificationHandleChange}
																styles={customStyles}
															/>
															<img
																src={
																	require("../../../static/images/forms/bachelors.svg")
																		.default
																}
																alt="bachelors"
																height=""
																width=""
																className="formIcon"
															/>
														</div>
													</div>
													<div className="col-sm-6 col-12">
														<div className="form-group">
															<input
																onChange={handleBachelorsYear}
																className="form-control eligibility-form-control"
																type="month"
																placeholder="Year"
															></input>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-sm-6 col-12">
														<div className="form-group">
															<Select
																name="Maters_Qualification"
																placeholder={"Masters Qualification"}
																value={masterQualification}
																isSearchable={true}
																options={MasterOptions}
																// className={ ? "is-invalid" : ""}
																onChange={masterQualificationHandleChange}
																styles={customStyles}
															/>
															<img
																src={
																	require("../../../static/images/forms/masters.svg")
																		.default
																}
																alt="masters"
																height=""
																width=""
																className="formIcon"
															/>
														</div>
													</div>
													<div className="col-sm-6 col-12">
														<div className="form-group">
															<input
																onChange={handleMastersYear}
																className="form-control eligibility-form-control"
																type="month"
																placeholder="Year"
															></input>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="form-group">
										<label>When would you like to start?</label>
										<div className="checkboxGroup">
											<input
												type="date"
												onChange={startHandleChange}
												className={"form-control eligibility-form-control"+(startError?" is-invalid" : "")}
												placeholder="Date"
											></input>
										</div>
									</div>

									<div className="form-group">
										<label>
											Have you Appeared or Scheduled for any English Proficiency
											Test?
										</label>
										<div className="checkboxGroup">
											<label className="custom-radio">
												<input
													type="radio"
													onChange={onChangeEnglish}
													name="eng"
													value="yes"
												></input>
												<span>Yes</span>
											</label>
											<label className="custom-radio">
												<input
													type="radio"
													name="eng"
													onChange={onChangeEnglish}
													value="no"
												></input>
												<span>No</span>
											</label>
											<label className="custom-radio">
												<input
													type="radio"
													name="eng"
													onChange={onChangeEnglish}
													value="scheduled"
												></input>
												<span>Scheduled</span>
											</label>
											<input
												type="date"
												onChange={handleEnglishScheduledTestDate}
												className={"form-control eligibility-form-control"+(englishTestScheduleDateError?" is-invalid" : "")}
												placeholder="Date"
											></input>
											{englishTestError?<Alert severity="error">{englishTestError}</Alert> : null}

										</div>
									</div>
									<div className="form-group">
										{englishProficiency === "yes" ? showPopup() : false}
									</div>
									<div className="form-group">
										<label>
											Have you Appeared or Scheduled for any these Exams?
										</label>
										<div className="checkboxGroup">
											<label className="custom-radio">
												<input
													type="radio"
													name="exam"
													onChange={examTestHandleChange}
													value="sat"
												></input>
												<span>SAT</span>
											</label>
											<label className="custom-radio">
												<input
													type="radio"
													name="exam"
													onChange={examTestHandleChange}
													value="gmat"
												></input>
												<span>GMAT</span>
											</label>
											<label className="custom-radio">
												<input
													type="radio"
													name="exam"
													onChange={examTestHandleChange}
													value="lsat"
												></input>
												<span>LSAT</span>
											</label>
											<label className="custom-radio">
												<input
													type="radio"
													name="exam"
													onChange={examTestHandleChange}
													value="gre"
												></input>
												<span>GRE</span>
											</label>
											<label className="custom-radio">
												<input
													type="radio"
													name="exam"
													onChange={examTestHandleChange}
													value="mcat"
												></input>
												<span>MCAT</span>
											</label>
											<input
												type="number"
												onChange={examTestScoreHandleChange}
												className={"form-control eligibility-form-control"+(resultError?" is-invalid":"")}
												placeholder="Result"
											></input>
											{examTestError?<Alert severity="error">{examTestError}</Alert> : null}
										</div>
									</div>
									<div className="form-group">
										<label>Choose Level for Study</label>
										<div className="checkboxGroup">
											<label className="custom-radio">
												<input
													type="radio"
													name="level"
													onChange={handleLevelForStudyChange}
													value="Bachelors Degree"
												></input>
												<span>Bachelor</span>
											</label>
											<label className="custom-radio">
												<input
													type="radio"
													name="level"
													onChange={handleLevelForStudyChange}
													value="Masters Degree"
												></input>
												<span>Masters</span>
											</label>
											<label className="custom-radio">
												<input
													type="radio"
													name="level"
													onChange={handleLevelForStudyChange}
													value="Professional Certificates"
												></input>
												<span>Certificate</span>
											</label>
											<label className="custom-radio">
												<input
													type="radio"
													name="level"
													onChange={handleLevelForStudyChange}
													value="Short Term Diploma"
												></input>
												<span>Short Term Diploma</span>
											</label>
											<label className="custom-radio">
												<input
													type="radio"
													name="level"
													onChange={handleLevelForStudyChange}
													value="Other"
												></input>
												<span>Other</span>
											</label>
											{studyLevelError?<Alert severity="error">{studyLevelError}</Alert> : null}
										</div>
									</div>
									<div className="form-group">
										<label className="customCheckbox">
											<input
												required
												type="checkbox"
												checked={reqCheck}
												className={
													"form-control" + (reqCheckError ? " is-invalid" : "")
												}
												onClick={(e) => checkBoxValidate(e.target.checked)}
											></input>
											<span>
												Yes I hereby confirm that my age is over 16 and
												understand Times Course Finder{" "}
												<a href="/terms-of-use"> Terms & Conditions</a> and{" "}
												<a href="/privacy-policy">Privacy Policy</a>.{" "}
											</span>
										</label>
										{reqCheckError ? (
											<Alert severity="error">{reqCheckError}</Alert>
										) : null}
									</div>
									<div className="form-group">
										<label className="customCheckbox">
											<input
												type="checkbox"
												required
												className="form-control"
											></input>
											I agree to be
											<span>
												contacted for service related information and
												promotional purposes.
											</span>
										</label>
									</div>

									<button
										className="formBtn"
										disabled={reqCheck ? false : true}
										onClick={(e) => applyNowOnSubmit(e)}
									>
										Check Eligibility
									</button>

									{/* <p className="noAccP">
											Already a Member? <a href="#">Login</a> it's Easy
										</p> */}
								</form>
							</div>
							<div className="whyRegSide">
								<h5>Why Sign Up?</h5>
								<div className="whyBoxWrap">
									<div>
										<img
											src={require("../../../static/images/why1.svg").default}
											alt="Explore unlimited Programs & Fee"
											height=""
											width=""
										/>
										<h6>Explore unlimited Programs & Fee</h6>
									</div>
									<div>
										<img
											src={require("../../../static/images/why2.svg").default}
											alt="Admission Support to Apply"
											height=""
											width=""
										/>
										<h6>Admission Support to Apply</h6>
									</div>
									<div>
										<img
											src={require("../../../static/images/why3.svg").default}
											alt="Compare multiple Institutes"
											height=""
											width=""
										/>
										<h6>Compare multiple Institutes</h6>
									</div>
									<div>
										<img
											src={require("../../../static/images/why4.svg").default}
											alt="Update on Scholarships & Admission Deadlines"
											height=""
											width=""
										/>
										<h6>Update on Scholarships & Admission Deadlines</h6>
									</div>
									<div>
										<img
											src={require("../../../static/images/why5.svg").default}
											alt="Shortlist & save your searches"
											height=""
											width=""
										/>
										<h6>Shortlist & save your searches</h6>
									</div>
									<div>
										<img
											src={require("../../../static/images/why6.svg").default}
											alt="Online Counselling"
											height=""
											width=""
										/>
										<h6>Online Counselling</h6>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
