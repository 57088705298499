import { http } from "../../../services/apiService/apiService";

export const GET_ALL_DISCOVER_DISCIPLINES = "GET_ALL_DISCOVER_DISCIPLINES";
export const GET_ALL_DISCOVER_DISCIPLINES_SUCCESS =
	"GET_ALL_DISCOVER_DISCIPLINES_SUCCESS";
export const GET_ALL_DISCOVER_DISCIPLINES_FAIL =
	"GET_ALL_DISCOVER_DISCIPLINES_FAIL";

export function getAllDiscoverPageDiscipline() {
	const request = http.get("discipline/all");

	return (dispatch) =>
		request
			.then((response) =>
				dispatch({
					type: GET_ALL_DISCOVER_DISCIPLINES_SUCCESS,
					payload: response.data,
				})
			)
			.catch((error) => {
				return dispatch({
					type: GET_ALL_DISCOVER_DISCIPLINES_FAIL,
					payload: [],
				});
			});
}
export const GET_ALL_DISCOVER_SCHOLARSHIPS = "GET_ALL_DISCOVER_SCHOLARSHIPS";
export const GET_ALL_DISCOVER_SCHOLARSHIPS_SUCCESS =
	"GET_ALL_DISCOVER_SCHOLARSHIPS_SUCCESS";
export const GET_ALL_DISCOVER_SCHOLARSHIPS_FAIL =
	"GET_ALL_DISCOVER_SCHOLARSHIPS_FAIL";

export function getAllDiscoverPageScholarships(pageId) {
	let url = "scholarship/all";
	if (pageId) {
		url = "scholarship/all?page=" + pageId;
	}
	const request = http.get(url);

	return (dispatch) =>
		request
			.then((response) =>
				dispatch({
					type: GET_ALL_DISCOVER_SCHOLARSHIPS_SUCCESS,
					payload: response.data,
				})
			)
			.catch((error) => {
				return dispatch({
					type: GET_ALL_DISCOVER_SCHOLARSHIPS_FAIL,
					payload: [],
				});
			});
}
