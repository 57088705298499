import React from 'react';
import CheckboxList from "../loaders/checklist";
import IntakeFilters from "../filters/intakes/intakes_filters";

const IntakesIndividual = (props) => {
    return (
        <div className="filterBox">
            <h6 className="filterOpen">
                Intake<i className="fa fa-angle-right"></i>
                <i className="fa fa-angle-down"></i>
            </h6>
            {props.intakeLoader === true ? (
                <CheckboxList />
            ) : (
                <div className="content show">
                    <div className="filterBoxScroll ">
                        {props?.intakes?.map((item, index) => (
                            <IntakeFilters
                                intakegroup={item}
                                index={index}
                                selectedIntakes={Object.values(props.selectedIntakes)}
                                updateSelectedIntakes={props.updateSelectedIntakes}
                                rand={Math.random()}
                            />
                        ))}
                    </div>
                    <div className="text-center">
                        <button
                            onClick={() => props.coutryApplyNow("intakes")}
                            className="btn defaultBtn sharePgBtn my-2"
                        >
                            Apply Selection
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default IntakesIndividual;
