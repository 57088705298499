import * as reduxModule from 'redux';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
// import createReducer from './reducers';
import storage from "redux-persist/lib/storage";
// import 'dotenv/config'
import { persistStore, persistReducer } from "redux-persist";
import rootReducer from './reducers';

// require('dotenv').config()
/*
Fix for Firefox redux dev tools extension
https://github.com/zalmoxisus/redux-devtools-instrument/pull/19#issuecomment-400637274
 */
reduxModule.__DO_NOT_USE__ActionTypes.REPLACE = '@@redux/INIT';

// const composeEnhancers =
// 	process.env.NODE_ENV !== 'production' && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
// 		? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
// 				// Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
// 		  })
// 		: compose;
let composeEnhancers = compose;

if (process.env.NODE_ENV !== "production" && typeof window === "object") {
	if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
		composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});
}


const persistConfig = {
	key: "root",
	storage,
	whitelist: ['auth']
};

// const middlewares = [thunk];
// const initialState = {};
const enhancer = composeEnhancers(applyMiddleware(thunk));
// const enhancer = [applyMiddleware(...middlewares)];

const persistedReducer = persistReducer(persistConfig, rootReducer());


const store = createStore(
	persistedReducer, enhancer
);
//   const store = createStore(rootReducer(), enhancer);





store.asyncReducers = {};

export const injectReducer = (key, reducer) => {
	if (store.asyncReducers[key]) {
		return false;
	}
	store.asyncReducers[key] = reducer;
	store.replaceReducer(rootReducer(store.asyncReducers));
	return store;
};

// export default store;
const persistor = persistStore(store);

export { store, persistor };

// import { createStore, compose, applyMiddleware } from "redux";
// import thunk from "redux-thunk";
// import { persistStore, persistReducer } from "redux-persist";
// import storage from "redux-persist/lib/storage";
// import rootReducer from "./reducers";
// require('dotenv').config()

// let composeEnhancers = compose;

// if (process.env.NODE_ENV !== "production" && typeof window === "object") {
//   if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
//     composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});
// }

// const persistConfig = {
//   key: "root",
//   storage,
// };
// const middlewares = [thunk];
// const initialState = {};
// const enhancers = [applyMiddleware(...middlewares)];
// const persistedReducer = persistReducer(persistConfig, rootReducer);

// // const store = createStore(persistedReducer);
// const store = createStore(
//   persistedReducer,
//   initialState,
//   composeEnhancers(...enhancers)
// );

// export const injectReducer = (key, reducer) => {
// 	if (store.asyncReducers[key]) {
// 		return false;
// 	}
// 	store.asyncReducers[key] = reducer;
// 	store.replaceReducer(rootReducer(store.asyncReducers));
// 	return store;
// };

// const persistor = persistStore(store);

// export { store, persistor };
