import {
  Box,
  Button,
  Checkbox,
  Chip,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Tabs } from "@mui/base/Tabs";
import schoolImg from "../../../static/images/account/school.png";
import examImg from "../../../static/images/account/exam.png";
import congratsImg from "../../../static/images/congrats.gif";
import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { inputTheme } from "../../shared/utils/muiInputTheme";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Country, City } from "country-state-city";
import { DISCIPLINES } from "../../constants/index";
import addEducationImg from "../../../static/images/account/add-education.png";
import {
  AddOutlined,
  Close,
  EditOutlined,
  Visibility,
  VisibilityOff,
} from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { ory, useSdkError } from "../../ory/pkg/sdk";
import { useSearchParams } from "react-router-dom-v5-compat";
import { useSnackbar } from "notistack";
import { setOryUserData } from "../../store/actions/authActions";
import dayjs from "dayjs";
import UserInterest from "./components/userInterest";
import { Tab, TabPanel, TabsList } from "./constants";
import ProfileTop from "./components/profileTop";
import EducationModal from "./components/educationModal";
import CertificationModal from "./components/certificationModal";




function EditProfile() {
  const countryList = Country.getAllCountries();
 
  const [cities, setCities] = useState([]);
  
  const [openEducationModal, setOpenEducationModal] = useState(false);
  const [openCertificationModal, setOpenCertificationModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [flow, setFlow] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [userEducation, setUserEducation] = useState([]);
  const [userCertification, setUserCertification] = useState([]);
  const [editEducation, setEditEducation] = useState(null);
  const [editCertification, setEditCertification] = useState(null);
  const [showGif, setShowGif] = useState(false);

  const { user } = useSelector((state) => state.auth);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChangeCountry = async (country) => {
    setCities(City.getCitiesOfCountry(country));
  };
 

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm();
  const {
    register: registerEducation,
    handleSubmit: handleSubmitEducation,
    setValue: setValueEducation,
    reset: resetEducation,
    formState: { errors: errorsEducation },
  } = useForm();

  const {
    register: registerCertification,
    handleSubmit: handleSubmitCertification,
    setValue: setValueCertification,
    reset: resetCertification,
    formState: { errors: errorsCertification },
  } = useForm();

  const {
    register: registerPassword,
    handleSubmit: handleSubmitPassword,
    watch: watchPassword,
    getValues: getValuesPassword,
    formState: { errors: errorsPassword },
  } = useForm();

 

  const outerTheme = useTheme();
  const isMobile = useMediaQuery(outerTheme.breakpoints.down("sm"));
  const handleOpenEducationModal = () => setOpenEducationModal(true);
  const handleOpenCertificationModal = () => setOpenCertificationModal(true);
  const handleCloseEducationModal = () => {
    setOpenEducationModal(false);
    setEditEducation(null);
    resetEducation();
  };

  const handleCloseCertificationModal = () => {
    setOpenCertificationModal(false);
    setEditCertification(null);
    resetCertification();
  };

  const handleAddEducation = (data) => {
    setUserEducation((prevState) => [...prevState, data]);
    setOpenEducationModal(false);
  };

  const handleAddCertification = (data) => {
    setUserCertification((prevState) => [...prevState, data]);
    setOpenCertificationModal(false);
  };

  const handleEditEducation = (data) => {
    setUserEducation((prevState) => {
      const updatedEducation = [...prevState];
      updatedEducation[editEducation.index] = data;
      return updatedEducation;
    });

    handleCloseEducationModal();
  };

  const handleEditCertification = (data) => {
    setUserCertification((prevState) => {
      const updatedCertification = [...prevState];
      updatedCertification[editCertification.index] = data;
      return updatedCertification;
    });

    handleCloseCertificationModal();
  };

  const handleDeleteEducation = (index) => {
    setUserEducation((prevState) => {
      const updatedEducation = [...prevState];
      updatedEducation.splice(index, 1);
      return updatedEducation;
    });
  };

  const handleDeleteCertification = (index) => {
    setUserCertification((prevState) => {
      const updatedCertification = [...prevState];
      updatedCertification.splice(index, 1);
      return updatedCertification;
    });
  };
  
  const handleClickEditEducation = (education, index) => {
    education.index = index;
    setEditEducation(education);

    setValueEducation("degreeLevel", education.degreeLevel);
    setValueEducation("discipline", education.discipline);
    setValueEducation("courseMajor", education.courseMajor);
    setValueEducation("institute", education.institute);
    setValueEducation("country", education.country);
    setValueEducation("gradeSystem", education.gradeSystem);
    setValueEducation("grade", education.grade);
    setValueEducation("startDate", education.startDate);
    setValueEducation("endDate", education.endDate);

    handleOpenEducationModal();
  };

  const handleClickEditCertification = (certification, index) => {
    certification.index = index;
    setEditCertification(certification);

    setValueCertification("name", certification.name);
    setValueCertification("score", certification.score);
    setValueCertification("completionDate", certification.completionDate);

    handleOpenCertificationModal();
  };

  const submitFlow = (data) => {
    const _education =
      userEducation.length > 0 ? JSON.stringify(userEducation) : "";
    const profileFields = [
      data.email,
      data.firstName,
      data.lastName,
      data.phoneNumber,
      data.dob,
      data.country,
      data.city,
      data.citizenshipCountry,
      data.zipCode,
      _education,
    ];

    // Count the number of filled fields
    const filledFieldsCount = profileFields.filter(
      (field) => field !== ""
    ).length;

    // Calculate the completion percentage
    const totalFields = profileFields.length;
    const completionPercentage = (filledFieldsCount / totalFields) * 100;

    let body = {
      method: "profile",
      csrf_token: data.csrf_token,
      "traits.email": data.email,
      "traits.userDetails.first": data.firstName,
      "traits.userDetails.last": data.lastName,
      "traits.picture": user.picture || "",
      "traits.userDetails.primaryPhone": data.phoneNumber,
      "traits.userDetails.secondaryPhone": data.secondaryPhone,
      "traits.userDetails.secondaryEmail": data.secondaryEmail,
      "traits.userDetails.dob": data.dob,
      "traits.userDetails.profileCompletion": completionPercentage,
      "traits.address.country": data.country,
      "traits.address.city": data.city,
      "traits.address.citizenshipCountry": data.citizenshipCountry,
      "traits.address.zipCode": data.zipCode,
      "traits.education":
        userEducation.length > 0 ? JSON.stringify(userEducation) : "",
      "traits.certifications":
        userCertification.length > 0 ? JSON.stringify(userCertification) : "",
    };

    ory
      .updateSettingsFlow({ flow: flow.id, updateSettingsFlowBody: body })
      .then(({ data: flow }) => {
        flow.active = true;
        dispatch(setOryUserData(flow));
        setFlow(flow);

        enqueueSnackbar(flow.ui.messages[0].text, {
          variant: flow.ui.messages[0].type,
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });

        if (completionPercentage === 100) {
          setShowGif(true);
          enqueueSnackbar(
            "Profile completed successfully. You can now redeem your free counselling session.",
            {
              variant: "success",
              anchorOrigin: { vertical: "top", horizontal: "center" },
            }
          );
          // Hide the GIF after 3 seconds
          setTimeout(() => {
            setShowGif(false);
          }, 4000);
        }
      })
      .catch(sdkErrorHandler);
  };

  

  const submitPassword = (data) => {
    let body = {
      method: "password",
      csrf_token: getValues("csrf_token"),
      password: data.password,
    };

    ory
      .updateSettingsFlow({ flow: flow.id, updateSettingsFlowBody: body })
      .then(({ data: flow }) => {
        flow.active = true;
        dispatch(setOryUserData(flow));
        setFlow(flow);

        enqueueSnackbar(flow.ui.messages[0].text, {
          variant: flow.ui.messages[0].type,
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
      })
      .catch(sdkErrorHandler);
  };

  const getFlow = useCallback(
    (flowId) =>
      ory
        // the flow data contains the form fields, error messages and csrf token
        .getSettingsFlow({ id: flowId })
        .then(({ data: flow }) => {
          // notify(flow)
          setFlow(flow);
        })
        .catch(sdkErrorHandler),
    []
  );

  const notify = (flow) => {
    const passwordField = flow?.ui.nodes.find(
      (node) => node.attributes.name === "password"
    );
    if (passwordField?.messages[0].text) {
      enqueueSnackbar(passwordField.messages[0].text, {
        variant: passwordField?.messages[0].type,
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    }
  };

  const sdkErrorHandler = useSdkError(
    getFlow,
    setFlow,
    "/edit-profile",
    true,
    notify
  );
  const csrfField = flow?.ui.nodes.find(
    (node) => node.attributes.name === "csrf_token"
  );

  const createFlow = () => {
    ory
      .createBrowserSettingsFlow()
      .then(({ data: flow }) => {
        setSearchParams({ ["flow"]: flow.id });
        setFlow(flow);
      })
      .catch(sdkErrorHandler);
  };

  useEffect(() => {
    const flowId = searchParams.get("flow");
    if (flowId) {
      getFlow(flowId).catch(createFlow);

      return;
    }
    createFlow();
  
  }, []);

  useEffect(() => {
    if (user.address.country) {
      handleChangeCountry(user.address.country);
    }

    if (user.education) {
      setUserEducation(JSON.parse(user.education));
    }
    if (user.certifications) {
      setUserCertification(JSON.parse(user.certifications));
    }
   
  }, [user]);

  return (
    <>
      <ThemeProvider theme={inputTheme(outerTheme)}>
        <Container>
          <Box
            sx={{
              bgcolor: "#F4F7FC",
              paddingTop: "20px",
              background: "#f5f5f5",
            }}
          >
            <Box component="div">
              <h2>My Account</h2>
              <p>Please fill all the details</p>
            </Box>
            <Box component="div">
              <Tabs defaultValue={1}>
                <TabsList>
                  <Tab value={0}>Interests</Tab>
                  <Tab value={1}>Profile</Tab>
                  <Tab value={2}>Settings</Tab>
                </TabsList>
                <TabPanel value={0}>
                  <UserInterest user={user} flow={flow}  csrfField={csrfField} isMobile={isMobile} countryList={countryList} register={register} errors={errors}/>
                </TabPanel>
                <TabPanel value={1}>
                  <form
                    action={flow?.ui.action}
                    method={flow?.ui.method}
                    onSubmit={handleSubmit(submitFlow)}
                  >
                    {csrfField && (
                      <input
                        {...register("csrf_token")}
                        type={csrfField.attributes.type}
                        name={csrfField.attributes.name}
                        value={csrfField.attributes.value}
                        disabled={csrfField.attributes.disabled}
                      />
                    )}
                    <ProfileTop />

                    <Box
                      className="tcf-v2"
                      sx={{
                        background: "#fff",
                        marginTop: "40px",
                        borderRadius: "12px",
                      }}
                    >
                      <div className="p-4">
                        <h4 className="font-weight-bold">Basic Info</h4>
                        <Divider className="pt-1 pb-1" />
                      </div>
                      <div className="w-100 ory-input-fields pl-4 pr-4 pt-2">
                        <Grid container spacing={2} className="pb-4">
                          <Grid item xs={isMobile ? 12 : 6}>
                            <TextField
                              error={errors.firstName}
                              {...register("firstName", {
                                required: true,
                                maxLength: 30,
                              })}
                              type="text"
                              label="First Name"
                              variant="outlined"
                              className="w-100"
                              autoComplete="false"
                              defaultValue={user.userDetails.first}
                              helperText={
                                errors.firstName?.type === "required"
                                  ? "This field is required"
                                  : errors.firstName?.message
                              }
                            />
                          </Grid>
                          <Grid item xs={isMobile ? 12 : 6}>
                            <TextField
                              error={errors.lastName}
                              {...register("lastName", {
                                required: true,
                                maxLength: 30,
                              })}
                              type="text"
                              label="Last Name"
                              variant="outlined"
                              className="w-100"
                              defaultValue={user.userDetails.last}
                              helperText={
                                errors.lastName?.type === "required"
                                  ? "This field is required"
                                  : errors.lastName?.message
                              }
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} className="pb-4">
                          <Grid item xs={isMobile ? 12 : 6}>
                            <TextField
                              error={errors.email}
                              {...register("email", {
                                required: true,
                                maxLength: 30,
                                pattern: {
                                  value: /\S+@\S+\.\S+/,
                                  message:
                                    "Entered value does not match email format. (e.g., user@example.com)",
                                },
                              })}
                              type="email"
                              label="Email"
                              variant="outlined"
                              className="w-100"
                              defaultValue={user.email}
                              helperText={
                                errors.email?.type === "required"
                                  ? "This field is required"
                                  : errors.email?.message
                              }
                            />
                          </Grid>
                          <Grid item xs={isMobile ? 12 : 6}>
                            <TextField
                              error={errors.secondaryEmail}
                              {...register("secondaryEmail", {
                                required: false,
                                maxLength: 30,
                                pattern: {
                                  value: /\S+@\S+\.\S+/,
                                  message:
                                    "Entered value does not match email format. (e.g., user@example.com)",
                                },
                              })}
                              type="email"
                              label="Secondary Email"
                              variant="outlined"
                              className="w-100"
                              defaultValue={user.userDetails.secondaryEmail}
                              helperText={errors.secondaryEmail?.message}
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} className="pb-4">
                          <Grid item xs={isMobile ? 12 : 6}>
                            <TextField
                              error={errors.phoneNumber}
                              {...register("phoneNumber", {
                                required: true,
                                maxLength: 30,
                                pattern: {
                                  value:
                                    /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
                                  message:
                                    "Entered value does not match phone number format. (e.g., +92 317 111 8338)",
                                },
                              })}
                              type="text"
                              label="Phone number"
                              placeholder="+92 317 111 8338"
                              variant="outlined"
                              className="w-100"
                              defaultValue={user.userDetails.primaryPhone}
                              helperText={
                                errors.phoneNumber?.type === "required"
                                  ? "This field is required"
                                  : errors.phoneNumber?.message
                              }
                            />
                          </Grid>
                          <Grid item xs={isMobile ? 12 : 6}>
                            <TextField
                              error={errors.secondaryPhone}
                              {...register("secondaryPhone", {
                                maxLength: 30,
                                pattern: {
                                  value:
                                    /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
                                  message:
                                    "Entered value does not match phone number format. (e.g., +92 317 111 8338)",
                                },
                              })}
                              type="text"
                              label="Secondary phone number"
                              variant="outlined"
                              className="w-100"
                              defaultValue={user.userDetails.secondaryPhone}
                              helperText={errors.secondaryPhone?.message}
                            />
                          </Grid>
                        </Grid>

                        <Grid container spacing={2} className="pb-4">
                          <Grid item xs={isMobile ? 12 : 6}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                defaultValue={dayjs(user?.userDetails.dob)}
                                onChange={(newValue) =>
                                  setValue("dob", newValue.format("MM/DD/YYYY"))
                                }
                                className="w-100"
                                name="dob"
                                label="Date of Birth"
                              />
                            </LocalizationProvider>
                          </Grid>
                        </Grid>
                      </div>
                    </Box>

                    <Box
                      className="tcf-v2"
                      sx={{
                        background: "#fff",
                        marginTop: "40px",
                        borderRadius: "12px",
                      }}
                    >
                      <div className="p-4">
                        <h4 className="font-weight-bold">Address</h4>
                        <Divider className="pt-1 pb-1" />
                      </div>
                      <div className="w-100 ory-input-fields pl-4 pr-4 pt-2">
                        <Grid container spacing={2} className="pb-4">
                          <Grid item xs={isMobile ? 12 : 6}>
                            <FormControl fullWidth>
                              <InputLabel>Country</InputLabel>
                              <Select
                                label="Country"
                                defaultValue={user.address.country}
                                {...register("country")}
                              >
                                {countryList.map((country, i) => (
                                  <MenuItem
                                    key={i}
                                    onClick={() =>
                                      handleChangeCountry(country.isoCode)
                                    }
                                    value={country.isoCode}
                                  >
                                    {country.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={isMobile ? 12 : 6}>
                            <FormControl fullWidth>
                              <InputLabel>City</InputLabel>
                              <Select
                                {...register("city")}
                                label="City"
                                disabled={cities.length === 0}
                                defaultValue={user.address?.city}
                              >
                                {cities?.map((city, i) => (
                                  <MenuItem key={i} value={city.name}>
                                    {city.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} className="pb-4">
                          <Grid item xs={isMobile ? 12 : 6}>
                            <FormControl fullWidth>
                              <InputLabel>Country of Citizenship</InputLabel>
                              <Select
                                defaultValue={user.address.citizenshipCountry}
                                label="Country of Citizenship"
                                {...register("citizenshipCountry")}
                              >
                                {countryList.map((country, i) => (
                                  <MenuItem key={i} value={country.isoCode}>
                                    {country.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={isMobile ? 12 : 6}>
                            <TextField
                              error={errors.zipCode}
                              {...register("zipCode", {
                                maxLength: 6,
                              })}
                              type="text"
                              label="Zip Code"
                              variant="outlined"
                              className="w-100"
                              defaultValue={user.address.zipCode}
                              helperText={errors.zipCode?.message}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </Box>

                    <Box
                      className="tcf-v2"
                      sx={{
                        background: "#fff",
                        marginTop: "40px",
                        borderRadius: "12px",
                      }}
                    >
                      <div className="d-flex justify-content-between pl-4 pr-4 pt-4">
                        <h4 className="font-weight-bold">Education</h4>
                        <AddOutlined
                          className="cursor-pointer tcf-icon border rounded-circle mb-1"
                          onClick={handleOpenEducationModal}
                        />
                      </div>
                      <div className="pl-4 pr-4 pb-2">
                        <Divider />
                      </div>
                      <div className="w-100 ory-input-fields pl-4 pr-4 pt-2">
                        <Grid container spacing={2} className="pb-4 w-100">
                          <Grid item xs={12}>
                            {userEducation.length > 0 ? (
                              userEducation.map((education, i) => (
                                <div
                                  key={i}
                                  className="p-2 mb-2 tcf-border-dashed"
                                >
                                  <Grid
                                    container
                                    spacing={2}
                                    className=" w-100"
                                  >
                                    <Grid item xs={1} alignContent="center">
                                      <img alt="School" src={schoolImg} />
                                    </Grid>
                                    <Grid item xs={11}>
                                      <div className="d-flex justify-content-between">
                                        <div>
                                          <h5>{education.institute}</h5>
                                          <p className="m-0">
                                            {education.degreeLevel} -{" "}
                                            {education.courseMajor}
                                          </p>
                                          <p className="m-0">
                                            {education.startDate} -{" "}
                                            {education.endDate}
                                          </p>
                                          <p className="m-0">
                                            {education.gradeSystem} (
                                            {education.grade})
                                          </p>
                                        </div>
                                        <div className="d-grid">
                                          <EditOutlined
                                            onClick={() =>
                                              handleClickEditEducation(
                                                education,
                                                i
                                              )
                                            }
                                            className="cursor-pointer border rounded-circle p-1 mr-2"
                                            style={{ fontSize: "30px" }}
                                          />
                                          <Close
                                            onClick={handleDeleteEducation}
                                            className="cursor-pointer border rounded-circle p-1"
                                            style={{ fontSize: "30px" }}
                                          />
                                        </div>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              ))
                            ) : (
                              <div className="p-5 text-center tcf-border-dashed">
                                {/* <h2 className="p-5 text-center"> Coming Soon!</h2> */}
                                <div>
                                  <img
                                    src={addEducationImg}
                                    alt="Add Education"
                                    width="150px"
                                  />
                                  <p className="mt-2 mb-3 text-disabled">
                                    Nothing added yet
                                  </p>
                                </div>
                                <Button
                                  variant="contained"
                                  className="tcf-button-small"
                                  onClick={handleOpenEducationModal}
                                >
                                  Add a degree
                                </Button>
                              </div>
                            )}
                          </Grid>
                        </Grid>
                      </div>
                    </Box>

                    <Box
                      className="tcf-v2"
                      sx={{
                        background: "#fff",
                        marginTop: "40px",
                        borderRadius: "12px",
                      }}
                    >
                      <div className="d-flex justify-content-between pl-4 pr-4 pt-4">
                        <h4 className="font-weight-bold">Certifications</h4>
                        <AddOutlined
                          className="cursor-pointer tcf-icon border rounded-circle mb-1"
                          onClick={handleOpenCertificationModal}
                        />
                      </div>
                      <div className="pl-4 pr-4 pb-2">
                        <Divider />
                      </div>
                      <div className="w-100 ory-input-fields pl-4 pr-4 pt-2">
                        <Grid container spacing={2} className="pb-4 w-100">
                          <Grid item xs={12}>
                            {userCertification.length > 0 ? (
                              userCertification.map((certification, i) => (
                                <div
                                  key={i}
                                  className="p-2 mb-2 tcf-border-dashed"
                                >
                                  <Grid
                                    container
                                    spacing={2}
                                    className=" w-100"
                                  >
                                    <Grid item xs={1} alignContent="center">
                                      <img alt="School" src={examImg} />
                                    </Grid>
                                    <Grid item xs={11}>
                                      <div className="d-flex justify-content-between">
                                        <div>
                                          <h5>{certification.name}</h5>
                                          <p className="m-0">
                                            Completion Date:{" "}
                                            {certification.completionDate}
                                          </p>
                                          <p className="m-0">
                                            Score: {certification.score}
                                          </p>
                                        </div>
                                        <div className="d-grid">
                                          <EditOutlined
                                            onClick={() =>
                                              handleClickEditCertification(
                                                certification,
                                                i
                                              )
                                            }
                                            className="cursor-pointer border rounded-circle p-1 mr-2"
                                            style={{ fontSize: "30px" }}
                                          />
                                          <Close
                                            onClick={handleDeleteCertification}
                                            className="cursor-pointer border rounded-circle p-1"
                                            style={{ fontSize: "30px" }}
                                          />
                                        </div>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              ))
                            ) : (
                              <div className="p-5 text-center tcf-border-dashed">
                                {/* <h2 className="p-5 text-center"> Coming Soon!</h2> */}
                                <div>
                                  <p className="mt-2 mb-2 text-disabled">
                                    Nothing added yet
                                  </p>
                                  <p className="mb-3 text-disabled">
                                    Add now (e.g IELTS, TOFEL, GRE, SAT etc.)
                                  </p>
                                </div>
                                <Button
                                  variant="contained"
                                  className="tcf-button-small"
                                  onClick={handleOpenCertificationModal}
                                >
                                  Add certification
                                </Button>
                              </div>
                            )}
                          </Grid>
                        </Grid>
                      </div>
                    </Box>
                    <Box component="div" className="pt-2">
                      <Grid container>
                        <Grid item xs={12}>
                          <Button
                            variant="contained"
                            className="tcf-button-small float-right mb-4"
                            type="submit"
                            // onClick={handleOpenEducationModal}
                          >
                            Save
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </form>
                </TabPanel>
                <TabPanel value={2}>
                  <ProfileTop />

                  <form
                    action={flow?.ui.action}
                    method={flow?.ui.method}
                    onSubmit={handleSubmitPassword(submitPassword)}
                  >
                    <Box
                      className="tcf-v2"
                      sx={{
                        background: "#fff",
                        marginTop: "40px",
                        borderRadius: "12px",
                      }}
                    >
                      <div className="p-4">
                        <h4 className="font-weight-bold">Password</h4>
                        <Divider className="pt-1 pb-1" />
                      </div>
                      <div className="w-100 ory-input-fields pl-4 pr-4 pt-2">
                        <Grid container spacing={2} className="pb-4">
                          <Grid item xs={isMobile ? 12 : 6}>
                            <FormControl
                              sx={{ m: 1, width: "25ch" }}
                              variant="outlined"
                              className="w-100 m-0 pb-3"
                            >
                              <InputLabel
                                className={errors.password ? "Mui-error" : ""}
                                htmlFor="outlined-adornment-password"
                              >
                                New Password
                              </InputLabel>
                              <OutlinedInput
                                name="password"
                                error={errorsPassword.password}
                                {...registerPassword("password", {
                                  required: false,
                                  minLength: 8,
                                })}
                                type={showPassword ? "text" : "password"}
                                label="New Password"
                                variant="outlined"
                                className="w-100"
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {showPassword ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                }
                              />
                              {errorsPassword.password && (
                                <FormHelperText className="Mui-error">
                                  {errorsPassword.password?.type === "required"
                                    ? "This field is required"
                                    : "Must be at least 8 characters"}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={isMobile ? 12 : 6}>
                            <FormControl
                              sx={{ m: 1, width: "25ch" }}
                              variant="outlined"
                              className="w-100 m-0 pb-2"
                            >
                              <InputLabel
                                className={
                                  errorsPassword.password ? "Mui-error" : ""
                                }
                                htmlFor="outlined-adornment-password"
                              >
                                Confirm new password
                              </InputLabel>
                              <OutlinedInput
                                error={errorsPassword.confirmPassword}
                                name="confirmPassword"
                                {...registerPassword("confirmPassword", {
                                  required: false,
                                })}
                                type={showPassword ? "text" : "password"}
                                label="Confirm new password"
                                variant="outlined"
                                className="w-100"
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {showPassword ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                }
                              />
                              {errorsPassword.confirmPassword && (
                                <FormHelperText className="Mui-error">
                                  {errorsPassword.password?.type === "required"
                                    ? "This field is required"
                                    : "Must be at least 8 characters"}
                                </FormHelperText>
                              )}
                              {watchPassword("confirmPassword") !==
                                watchPassword("password") &&
                                getValuesPassword("confirmPassword") && (
                                  <FormHelperText className="Mui-error">
                                    {errorsPassword.password?.type ===
                                    "required"
                                      ? "This field is required"
                                      : "Password does not match."}
                                  </FormHelperText>
                                )}
                            </FormControl>
                          </Grid>
                        </Grid>
                      </div>
                    </Box>
                    <Box component="div" className="pt-2">
                      <Grid container>
                        <Grid item xs={12}>
                          <Button
                            variant="contained"
                            className="tcf-button-small float-right mb-4"
                            type="submit"
                            // onClick={handleOpenEducationModal}
                          >
                            Save
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </form>
                  <Box
                    className="tcf-v2"
                    sx={{
                      background: "#fff",
                      marginTop: "40px",
                      borderRadius: "12px",
                    }}
                  >
                    <div className="p-4">
                      <h4 className="font-weight-bold">Email preference</h4>
                      <Divider className="pt-1 pb-1" />
                    </div>
                    <div className="w-100 ory-input-fields pl-4 pr-4 pt-2">
                      <Grid container spacing={2} className="pb-4">
                        <Grid item xs={12}>
                          <div>
                            <p>
                              If you opted in to have your details shared with
                              our trusted partners, you will need to opt-out of
                              their communications with each partner directly.
                              You should be able to do this via any
                              communication you have with them.
                            </p>
                            <FormControlLabel
                              control={<Checkbox defaultChecked />}
                              label="As a registered user we will email you with relevant information. Untick to opt out."
                            />
                            <Divider className="pt-2" />
                          </div>
                          <div className="pt-3">
                            <p>Daily news briefing (global edition)</p>
                            <FormControlLabel
                              control={<Checkbox defaultChecked />}
                              label="All the latest higher education news and commentary from around the world, and essential analysis from TCF journalists, delivered to your inbox every morning"
                            />
                            <Divider className="pt-2" />
                          </div>
                          <div className="pt-3">
                            <p>Times course finder weekly newsletter</p>
                            <FormControlLabel
                              control={<Checkbox defaultChecked />}
                              label="Practical, how-to advice for time-poor academics and university staff written by your peers and curated by the TCF Campus team. Delivered to your inbox every Friday"
                            />
                            <Divider className="pt-2" />
                          </div>
                          <div className="pt-3">
                            <p>Times course finder Student weekly newsletter</p>
                            <FormControlLabel
                              control={<Checkbox defaultChecked />}
                              label="Weekly study advice and rankings insights for students from our student content editor"
                            />
                            <Divider className="pt-2" />
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </Box>
                  <Box
                    className="tcf-v2"
                    sx={{
                      background: "#fff",
                      marginTop: "40px",
                      borderRadius: "12px",
                    }}
                  >
                    <div className="p-4">
                      <h4 className="font-weight-bold">Legal</h4>
                      <Divider className="pt-1 pb-1" />
                    </div>
                    <div className="w-100 ory-input-fields pl-4 pr-4 pt-2">
                      <Grid container spacing={2} className="pb-4">
                        <Grid item xs={12}>
                          <div>
                            <p>
                              Universities and partners may require details to
                              contact you. To find out more please get in touch
                            </p>
                          </div>
                          <div>
                            <Button
                              variant="contained"
                              className="tcf-button-small outlined mb-4 mr-2"
                              onClick={() =>
                                window.open(
                                  "/privacy-policy",
                                  "_blank",
                                  "rel=noopener noreferrer"
                                )
                              }
                            >
                              Terms of service
                            </Button>
                            <Button
                              variant="contained"
                              className="tcf-button-small outlined mb-4"
                              onClick={() =>
                                window.open(
                                  "/terms-of-use",
                                  "_blank",
                                  "rel=noopener noreferrer"
                                )
                              }
                            >
                              Privacy Policy
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </Box>

                  <Box
                    className="tcf-v2"
                    sx={{
                      background: "#fff",
                      marginTop: "40px",
                      borderRadius: "12px",
                    }}
                  >
                    <div className="p-4">
                      <h4 className="font-weight-bold">Danger</h4>
                      <Divider className="pt-1 pb-1" />
                    </div>
                    <div className="w-100 ory-input-fields pl-4 pr-4 pt-2">
                      <Grid container spacing={2} className="pb-4">
                        <Grid item xs={12}>
                          <div>
                            <p>
                              Send us email on support@timescoursefinder.com to
                              delete or deactivate your account.
                            </p>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </Box>
                  <Box component="div" className="pt-2">
                    <Grid container>
                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          className="tcf-button-small float-right mb-4"
                          type="submit"
                          // onClick={handleOpenEducationModal}
                        >
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </TabPanel>
              </Tabs>
            </Box>
          </Box>
        </Container>
        <EducationModal
          openEducationModal={openEducationModal}
          handleCloseEducationModal={handleCloseEducationModal}
          editEducation={editEducation}
          handleSubmitEducation={handleSubmitEducation}
          handleEditEducation={handleEditEducation}
          handleAddEducation={handleAddEducation}
          isMobile={isMobile}
          errorsEducation={errorsEducation}
          registerEducation={registerEducation}
          countryList={countryList}
          handleChangeCountry={handleChangeCountry}
          setValueEducation={setValueEducation}
        />
        <CertificationModal
          openCertificationModal={openCertificationModal}
          handleCloseCertificationModal={handleCloseCertificationModal}
          editCertification={editCertification}
          handleSubmitCertification={handleSubmitCertification}
          handleEditCertification={handleEditCertification}
          handleAddCertification={handleAddCertification}
          isMobile={isMobile}
          errorsCertification={errorsCertification}
          registerCertification={registerCertification}
          setValueCertification={setValueCertification}
        />
        {showGif && (
          <img className="tcf-congrats-animation" src={congratsImg} alt="gif" />
        )}
      </ThemeProvider>
    </>
  );
}
export default EditProfile;
