import { useEffect, useRef, useState } from "react";
import { getSpecializationFilterReq } from "../../../services/apiService";
import CustomFilter from "../../../components/CustomFilter";
import { useNavigate } from "react-router-dom-v5-compat";

const SpecializationFilter = ({
  searchParams,
  updateSearchParams,
  isBlurred,
  setOpenModal,
}) => {
  const [specializationData, setSpecializationData] = useState([]);
  const [checkedSpecialization, setCheckedSpecialization] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [pageSpecialization, setCurrentPage] = useState(0);
  const [specializationNextDataLoader, setSpecializationNextDataLoader] =
    useState(false);
  const [query, setQuery] = useState("");
  const [isFetchedAll, setIsFetchedAll] = useState(false);
  const [updatedFilters, setUpdatedFilters] = useState([]);

  const naviagte = useNavigate();

  const listInnerRef = useRef();

  let filtered = "";

  const getFilterData = async (page) => {
    setIsLoading(true);
    try {
      let params = {
        discipline: searchParams?.discipline,
        specialization: searchParams?.specialization,
        degreelevel_type: searchParams?.degreelevel_type,
        degreelevel: searchParams?.degreelevel,
        country: searchParams?.country,
        state: searchParams?.state,
        city: searchParams?.city,
        institute: searchParams?.institute,
        institute_type: searchParams?.institute_type,
        course_duration: searchParams?.course_duration,
        fee_range: searchParams?.fee_range,
        intake_month: searchParams?.intake_month,
        search: searchParams?.search,
        page: page,
      };
      const res = await getSpecializationFilterReq(params);
      const { data } = res;

      setCurrentPage(Number(data.page));
      if (data.result.length > 0) {
        setSpecializationData((prevData) => [...prevData, ...data.result]);
      } else {
        setIsFetchedAll(true);
      }
    } catch (err) {
      console.log("Error", err);
    }
    setIsLoading(false);
  };

  if (specializationData) {
    filtered = specializationData?.filter((item) =>
      item.name.toLowerCase().includes(query.toLowerCase())
    );
  }

  const handleChange = (e) => {
    const filterValue = e.target.value;
    const { id, checked } = e.target;
    setCheckedSpecialization({ ...checkedSpecialization, [id]: checked });

    const _updatedFilters = updatedFilters.includes(filterValue)
      ? updatedFilters.filter((value) => value !== filterValue)
      : [...updatedFilters, filterValue];

    setUpdatedFilters(_updatedFilters);
  };

  const onApply = () => {
    updateSearchParams({ specialization: updatedFilters.join(",") });
  };

  const onScroll = async () => {
    if (!isFetchedAll) {
      if (listInnerRef.current) {
        const element = document.getElementById("scrollableDivSpecialization");
        const { scrollTop, scrollHeight, clientHeight } = element;

        if (Math.round(scrollTop + clientHeight) === scrollHeight) {
          if (pageSpecialization) {
            setSpecializationNextDataLoader(true);
            await getFilterData(pageSpecialization + 1);

            element.scrollTo({
              bottom: scrollTop + clientHeight === scrollHeight,
              behavior: "smooth",
            });
          }
        }
      }
    }
    setSpecializationNextDataLoader(false);
  };

  useEffect(() => {
    setSpecializationData([]);
    getFilterData(1);
  }, [!searchParams.specialization && searchParams]);

  useEffect(() => {
    let typeParams = searchParams?.specialization;
    if (searchParams.specialization?.length) {
      typeParams = typeParams.split(",");
    }
    const formattedData =
      typeParams &&
      typeParams?.map((item, index) => {
        const parts = item.split("-");
        const hasID = !isNaN(parts[parts.length - 1]); // Check if the last part is a number

        let nameParts, slug;
        if (hasID) {
          nameParts = parts.slice(0, -1);
          slug = nameParts.join("-");
        } else {
          nameParts = parts;
          slug = item;
        }

        const name = nameParts
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");

        const id = (index + 1).toString().padStart(3, "0");

        return { id, name, slug };
      });

    setSpecializationData(formattedData);

    const idObject =
      formattedData &&
      formattedData?.reduce((obj, item) => {
        obj[item.id] = true;
        return obj;
      }, {});

    setCheckedSpecialization(idObject);
  }, []);

  return (
    <>
      <CustomFilter
        setOpenModal={setOpenModal}
        isBlurred={isBlurred}
        title="Specialization"
        data={specializationData}
        searchPlaceholder="Search Specialization"
        checkedItems={checkedSpecialization}
        handleChangeItem={handleChange}
        isLoading={isLoading}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onScroll={onScroll}
        listInnerRef={listInnerRef}
        nextLoader={specializationNextDataLoader}
        hasSearch={true}
        setQuery={setQuery}
        filtered={filtered}
        onApply={onApply}
      />
    </>
  );
};
export default SpecializationFilter;
