const PopularCitiesModal = ({
    selectedLocation,
    onLocationRemove,
    closeRegionModal,
    handlePopularCitiesSearch,
    cityInput,
    regions,
    popularCountries,
    onSelectLocation,
    popularCities
}) => {
    return (
        <div
            className="modal fade regionModal"
            id="citiesModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
            data-backdrop="static"
            data-keyboard="false"
        >
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5>Popular Cities</h5>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <i className="fa fa-times"></i>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="modalTagWrap">
                            {selectedLocation?.map((item, index) => {
                                return (
                                    <div key={index} className="searchTag">
                                        {item.name}
                                        <button
                                            className="unselectBtn"
                                            onClick={() => {
                                                onLocationRemove(index);
                                            }}
                                        >
                                            x
                                        </button>
                                    </div>
                                );
                            })}
                            {selectedLocation?.length >= 1 && (
                                <button
                                    onClick={(e) => {
                                        closeRegionModal(e);
                                    }}
                                    className="btn defaultBtn"
                                >
                                    Done
                                </button>
                            )}
                        </div>
                        <div className="countrySearchWrap" style={{ marginBottom: 15 }}>
                            <input
                                value={cityInput}
                                type="text"
                                placeholder="Type here"
                                className="form-control"
                                onChange={(e) => {
                                    handlePopularCitiesSearch(e.target.value);
                                }}
                            />
                            <i className="fa fa-search"></i>
                        </div>
                        <div className="regionFlex">
                            <div className="left">
                                <h5>Popular Regions</h5>
                                <ul>
                                    {regions?.map((item, index) => {
                                        return (
                                            <li>
                                                <a href className="searchItemBtn">
                                                    {" "}
                                                    <a
                                                        href
                                                        className={
                                                            item.checked === true ? "active" : ""
                                                        }
                                                        key={index}
                                                        onClick={() => {
                                                            onSelectLocation(item, "Region");
                                                        }}
                                                    >
                                                        {item.name}
                                                    </a>
                                                    <span>({item.countries.length})</span>
                                                </a>
                                            </li>
                                        );
                                    })}
                                </ul>
                                <h5>Popular Countries</h5>
                                <ul>
                                    {popularCountries?.map((item, index) => {
                                        return (
                                            <li>
                                                <a
                                                    className="searchItemBtn"
                                                    href
                                                    key={index}
                                                >
                                                    <a
                                                        key={item.id}
                                                        className={item.checked === true ? "active" : ""}
                                                        href
                                                        onClick={() => {
                                                            onSelectLocation(item, "all_country");
                                                        }}
                                                    >
                                                        {item.name}
                                                    </a>
                                                </a>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                            <div className="right">
                                <h5 style={{ paddingLeft: 14 }}>All cities</h5>
                                <ul>
                                    {popularCities.length > 0 ? (
                                        popularCities?.map((item, index) => {
                                            return (
                                                <li>
                                                    <a
                                                        href
                                                        className="searchItemBtn"
                                                        key={index}
                                                    >
                                                        <a
                                                            key={item.id}
                                                            className={item.checked === true ? "active" : ""}
                                                            href
                                                            onClick={() => {
                                                                onSelectLocation(item, "all_country");
                                                            }}
                                                        >
                                                            {item.name} ({item.country})
                                                        </a>
                                                    </a>
                                                </li>
                                            );
                                        })
                                    ) : (
                                        <li style={{ textAlign: "center" }}>No match found</li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PopularCitiesModal;