export const FAQS = [
  {
    "question": "How to Use TimesCourseFinder (TCF)?",
    "answer": "Utilizing Times Course Finder (TCF) revolves around three simple steps: Discover, Compare, Apply. Start by exploring courses using our user-friendly filters. Once you've found courses, easily compare them for informed decision-making. For the next step you'll get access to our admission support after signing up, complete your profile, and effortlessly submit your application for the desired course. With TCF, your academic journey is simplified into these three seamless and effective stages."
  },
  {
    "question": "How can I find universities in Europe that fit my budget and academic interests?",
    "answer": "Use Times Course Finder's advanced search filters according to your required location to pinpoint programs based on your desired study field, tuition fees, and scholarship availability. You can also explore university profiles to learn more about their academic strengths and student life."
  },
  {
    "question": "What factors should I consider when comparing programs abroad?",
    "answer": "Use Times Course Finder's compare system to compare highly-rated engineering programs. Remember, rankings aren't everything! You can also discover the program page for details like specialization options, tuition fees, and scholarship availability to find the best fit for your goals."
  },
  {
    "question": "What resources does Times Course Finder offer for career counseling?",
    "answer": "Times Course Finder's counseling service takes the stress out of studying abroad, handling everything from understanding your profile to securing your visa. All you need to do is share your academic background and goals, then a personalized list of universities. Our experts will refine your application materials, ensuring accuracy and completeness. Finally, you will get expert guidance on visa document preparation and interview tips for a smooth process."
  },
  {
    "question": "How can I connect with online counseling services for academic guidance?",
    "answer": "Sign up for Times Course Finder to access our free virtual counseling services, and book it according to your availability. Connect with counselors to discuss your academic goals, get answers to your queries, and make confident decisions about your educational future."
  },
  {
    "question": "How can I access Times Course Finder's Counseling Services?",
    "answer": "To benefit from our counseling services, begin by registering with accurate details. Once registered, book your counseling session to receive guidance on Personalized Counseling Documents, SOPs, LORs, and more. Enjoy instant chat availability, access admission support to apply, and personalized Visa Counseling. Ensure a smooth process by registering first and unlocking valuable assistance for your academic journey."
  },
  {
    "question": "What benefits can I expect from Times Course Finder's premium services?",
    "answer": "Times Course Finder's premium services offer a comprehensive end-to-end solution, including personalized career counseling, assistance in course selection, support with admission applications, guidance in visa filing, and assistance with accommodation arrangements."
  },
  {
    "question": "Can I save and revisit my favorite university and course searches on Times Course Finder?",
    "answer": "Yes, signing up allows you to effortlessly save and revisit your preferred searches. Build a personalized shortlist for easy reference during your academic decision-making process."
  },
  {
    "question": "Can I get assistance with the admission process through Times Course Finder?",
    "answer": "Yes, our platform provides support for the admission process. Sign up to access our premium services for personalized guidance, including selecting the right program, preparing documentation, and submitting applications."
  },
  {
    "question": "How can I discover top universities in a specific country abroad?",
    "answer": "Explore the rankings on Times Course Finder to identify leading universities. Additionally, you can also find their institutions, programs, and scholarships for a comprehensive overview."
  },
  {
    "question": "How do I find scholarships for international students?",
    "answer": "To explore the latest scholarships easily, click on \"Find Top Scholarships\" in our header. Use the filters to refine your search based on your desired country, degree level, discipline, scholarship type, funding type, deadline, and year. Be sure to filter precisely to uncover the best results according to your needs."
  },
  {
    "question": "Is the information about courses up to date?",
    "answer": "Yes, Times Course Finder ensures that course information is regularly updated. Utilize our advanced filters to check for the latest programs offered by leading universities and their upcoming intakes, ensuring you have the most current information for your academic decisions."
  },
  {
    "question": "How can I search for universities that offer affordable rates and scholarships for students?",
    "answer": "Navigate to Times Course Finder's advanced search filters to set a specific fee range according to your budget and preferred location. Explore institutions that align with your financial considerations while uncovering scholarship opportunities for added financial support."
  },
  {
    "question": "What is the application process for my desired university on Times Course Finder?",
    "answer": "To apply, just sign up, upload your previous academic documents, complete your profile, and submit your application for the desired course. Our team of experts will then contact you shortly to guide you through the further steps in the process."
  },
  {
    "question": "Can I chat with the Times Course Finder team before applying for a course?",
    "answer": "Absolutely! You can engage with our team through the chat box on our website. Additionally, you have the option to schedule an online counseling session or call us directly at our provided number. If you are in Pakistan, feel free to visit our Times Consultant office near to your location for an in-person meeting. We're here to assist you every step of the way."
  },
  {
    "question": "What key factors should I focus on when discovering a course?",
    "answer": "When exploring courses, pay attention to crucial factors such as the program's relevance to your goals, program duration, intakes, and program overview. These insights, available on Times Course Finder, will guide you in making an informed decision about the best course for your academic journey."
  },
  {
    "question": "What information is required during the registration process on Times Course Finder?",
    "answer": "When registering, provide accurate details about yourself, and your education, including your highest qualifications in school, intermediate, bachelor's, or master's degree (if applicable). Indicate when you plan to start your studies and whether you've taken any English proficiency tests or standardized exams like SAT, GMAT, LSAT, GRE, or MCAT. Accurate information will ensure university and scholarship matches."
  },
  {
    "question": "How can I find courses for studying abroad?",
    "answer": "Explore courses with ease using our advanced search filter. Simply select your desired course, location, and qualification, then customize the search based on your specific needs. To delve deeper into a course, you can also click on the \"Discover\" feature for a more detailed exploration."
  },
  {
    "question": "Does Times Course Finder cover popular countries and regions for exploring desired courses?",
    "answer": "Yes, indeed. Times Course Finder allows you to find courses based on the specific country where you want to study. Moreover, you can explore universities and their courses across all regions, ensuring a comprehensive range of options for your academic exploration."
  },
  {
    "question": "How can I compare courses and universities after searching for my desired courses?",
    "answer": "When you search for your desired course, you'll notice a convenient \"Compare\" button. This feature allows you to compare up to 3 courses simultaneously, helping you make informed decisions. You can easily delete a course from the comparison and add another to ensure you're selecting the most suitable option for your academic journey."
  },
  {
    "question": "What information will Times Course Finder cover when I compare a course?",
    "answer": "When you compare a course, Times Course Finder provides a comprehensive overview, including the institute's logo, name, sector, establishment year, country, location, campus details, program information (degree level, course name, specialization, duration), yearly fee, intake, description, ranking, application fee, deadlines, and available scholarships (merit-based, academic, need-based, sports-based). This allows you to make a well-informed decision by considering various aspects of the course and institute at your ease."
  },
  {
    "question": "What key factors should I consider when searching for a course on Times Course Finder?",
    "answer": "When using Times Course Finder, it's crucial to consider key factors such as the program's relevance to your goals, program overview, course structure, upcoming intakes, and the overall reputation of the institute. Additionally, take advantage of advanced search filters to customize your search based on your preferred course, location, and qualification, ensuring the best exploration that aligns with your academic aspirations."
  },
  {
    "question": "How can I interpret the university rankings on Times Course Finder?",
    "answer": "To understand the ranking of universities on Times Course Finder, consider multiple indicators such as QS World University Ranking, Times Higher Education World Ranking, US News World Ranking, Shanghai Ranking, and our proprietary TCF Ranking. Each ranking system assesses universities based on different criteria, providing a comprehensive view of their global standing. By examining multiple rankings, you gain a better understanding of an institute's academic reputation and excellence across various dimensions."
  },
  {
    "question": "What is the process for registering a new account on Times Course Finder?",
    "answer": "To create a new account, navigate to the login page and click on \"create new account.\" Fill in the required information, and an OTP will be sent to your email for verification. Once verified, you gain access to a range of features for free, enhancing your experience on Times Course Finder."
  },
  {
    "question": "What's the purpose of registering for an account on Times Course Finder when I can access course information without logging in?",
    "answer": "Registering your account on Times Course Finder offers a multitude of benefits. It unlocks a seamless experience, allowing you to explore unlimited programs and fees, receive personalized admission support, compare multiple institutes, stay updated on scholarships and admission deadlines, effortlessly shortlist and save your searches, and access online counseling services. By registering, you enhance your user experience and gain access to a comprehensive set of tools that assist you at every step of your academic journey."
  },
  {
    "question": "Can I find information about employability and professional development after completing a course?",
    "answer": "Explore the details of the course, and under the \"Career Prospects\" section, you'll find valuable information about potential opportunities and the professional development outcomes you can expect after completing the course. It's a helpful resource to understand the career prospects associated with the program you are interested in."
  },
  {
    "question": "Will Times Course Finder provide information about the course structure in every course detail?",
    "answer": "Absolutely! Times Course Finder offers comprehensive information about the course structure, detailing what you'll learn abroad. You can access all the necessary course information on one platform, making evaluating and understanding the curriculum convenient. To explore the course structure, simply click on the \"Discover\" button and navigate to the dedicated section for detailed insights into what each course entails. This allows you to make informed decisions about the suitability of a course based on its structure."
  },
  {
    "question": "Is the information about tuition fees provided in Times Course Finder up to date?",
    "answer": "Absolutely, we ensure that the information about tuition fees on Times Course Finder is up-to-date. You can rely on us to provide accurate and current details regarding course fees based on the institution and country. Additionally, if there are any scholarships available, Times Course Finder is committed to including that information, ensuring you have a comprehensive understanding of the financial aspects of your chosen course."
  },
  {
    "question": "Can I shortlist courses on Times Course Finder to revisit and compare them later?",
    "answer": "Absolutely! Once you register your account on Times Course Finder, you gain the ability to shortlist courses for easy reference and comparison. This allows you to create a personalized list of courses that you can revisit at any time. Moreover, the shortlisted courses can be easily compared side by side, enabling you to make a well-informed decision about your academic journey."
  },
  {
    "question": "Is it possible to create multiple usernames using the same email address?",
    "answer": "No, the system allows only one account to be created per email address."
  }
]

export const FAQ_MODAL_STYLE = {
  position: 'absolute',
  bottom: '5px',
  right: '5px',
  transform: 'translaste(-10%, -10%)',
  width: 400,
  bgcolor: 'background.paper',
  // border: '0px solid #000',
  borderRadius: '15px',
  boxShadow: 24,
  p: 1,
  height: '80vh'
};