import { http } from "../../../services/apiService/apiService";
/// marketing card / advertisement card

export const GET_ALL_MARKETING_CARD = "GET_ALL_MARKETING_CARD";
export const GET_ALL_MARKETING_CARD_SUCCESS = "GET_ALL_MARKETING_CARD_SUCCESS";
export const GET_ALL_MARKETING_CARD_FAIL = "GET_ALL_MARKETING_CARD_FAIL";

export function getAllMarketingCard(page) {
	let url = `marketing-card/${page?'?page='+page:''}`;

	const request = http.get(url);

	return (dispatch) =>
		request
			.then((response) =>
				dispatch({
					type: GET_ALL_MARKETING_CARD_SUCCESS,
					payload: response.data,
				})
			)
			.catch((error) => {
				return dispatch({
					type: GET_ALL_MARKETING_CARD_FAIL,
					payload: [],
				});
			});
}

export const GET_MARKETING_CARD_DETAIL = "GET_MARKETING_CARD_DETAIL";
export const GET_MARKETING_CARD_DETAIL_SUCCESS =
	"GET_MARKETING_CARD_DETAIL_SUCCESS";
export const GET_MARKETING_CARD_DETAIL_FAIL = "GET_MARKETING_CARD_DETAIL_FAIL";

export function getMarketingCardDetail(cardId) {
	let url = "marketing-card/" + cardId;

	const request = http.get(url);

	return (dispatch) =>
		request
			.then((response) =>
				dispatch({
					type: GET_MARKETING_CARD_DETAIL_SUCCESS,
					payload: response.data,
				})
			)
			.catch((error) => {
				return dispatch({
					type: GET_MARKETING_CARD_DETAIL_FAIL,
					payload: [],
				});
			});
}
