import { useCallback, useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom-v5-compat"
import { ory, useSdkError } from "../../ory/pkg/sdk"
import registerImg from "../../../static/images/auth/register-bg.jpeg"
import logo from "../../../static/images/tcf.svg"

import { Button, FormHelperText, } from "@mui/material"
import { ThemeProvider, useTheme } from '@mui/material/styles';
import { Link } from "react-router-dom"
import { useForm } from "react-hook-form"
import LoadingSpinner from "../../components/loader/loader"
import { inputTheme } from "../../shared/utils/muiInputTheme"
import OTPInput from "../../components/OTPInput"
import { useSnackbar } from "notistack"

const Verification = () => {
  const outerTheme = useTheme();
  const [flow, setFlow] = useState(null)
  const [searchParams, setSearchParams] = useSearchParams()
  const [otp, setOtp] = useState('');

  const { register, handleSubmit } = useForm();

  const returnTo = searchParams.get("return_to")
  const flowId = searchParams.get("flow")

  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const getFlow = useCallback(
    (flowId) =>
      ory
        // the flow data contains the form fields, error messages and csrf token
        .getVerificationFlow({ id: flowId })
        .then(({ data: flow }) => setFlow(flow))
        .catch(sdkErrorHandler),
    [],
  )

  const sdkErrorHandler = useSdkError(getFlow, setFlow, "/verification", true)
  const csrfField = flow?.ui.nodes.find(node => node.attributes.name === 'csrf_token');

  const createFlow = () => {
    ory
      .createBrowserVerificationFlow({
        ...(returnTo && { returnTo: returnTo }),
      })
      // flow contains the form fields, error messages and csrf token
      .then(({ data: flow }) => {
        // Update URI query params to include flow id
        setSearchParams({ ["flow"]: flow.id })
        // Set the flow data
        setFlow(flow)
      })
      .catch(sdkErrorHandler)
  }

  const submitFlow = (data) => {
    // something unexpected went wrong and the flow was not set
    if (!flow) return navigate("/verification", { replace: true })

    let body = data;
    body.code = otp
    if (!body.code) {
      delete body.email;
    }
    ory
      .updateVerificationFlow({
        flow: flow.id,
        updateVerificationFlowBody: body,
      })
      .then(({ data: flow }) => {
        setFlow(flow)
        // navigate("/", { replace: true })
        if(flow?.ui?.messages[0].type === "success"){
          enqueueSnackbar("E-mail verified successfully, please login to continue.", {
            variant: "success",
            anchorOrigin: { vertical: 'top', horizontal: 'center' }
          })
          
          navigate("/login", { replace: true })
          window.location.reload()
        }
      })
      .catch(sdkErrorHandler)
  }

  useEffect(() => {
    // it could happen that we are redirected here with an existing flow
    if (flowId) {
      // if the flow failed to get since it could be expired or invalid, we create a new one
      getFlow(flowId).catch(createFlow)
      return
    }
    createFlow()
  }, [])
  // we check if the flow is set, if not we show a loading indicator
  return flow ? (
    <div className="container-fluid">
      <div className="row auth verification align-items-center">
        <div className="col-sm-12 col-md-12 col-lg-5 ory-fields-container">
          <div className="logo w-100 text-center">
            <Link to="/">
              <img src={logo} alt="logo" />
            </Link>
          </div>

          <div className="title-container pt-4">
            <h2>OTP Verification</h2>
          </div>

          {flow.ui.messages &&
            <FormHelperText className={`${flow?.ui?.messages[0].type === "error" ? "Mui-error" : ""} ory-helper-text pt-1 pb-4`}>{flow?.ui?.messages[0].text}</FormHelperText>
          }

          <div className="w-100 ory-input-fields">
            <ThemeProvider theme={inputTheme(outerTheme)}>
              <form
                action={flow.ui.action}
                method={flow.ui.method}
                onSubmit={handleSubmit(submitFlow)}
              >
                <OTPInput {...register("code")} value={otp} onChange={setOtp} length={6} />

                {csrfField &&
                  <input
                    {...register("csrf_token")}
                    type={csrfField.attributes.type}
                    name={csrfField.attributes.name}
                    value={csrfField.attributes.value}
                    disabled={csrfField.attributes.disabled}
                  />
                }

                <input {...register("method")} type="hidden" value="code" />

                <Button
                  variant="contained"
                  name="method"
                  type="submit"
                  value="code"
                  disabled={otp.length !== 6}
                  className="w-100 ory-submit-button mt-4"
                >
                  Validate
                </Button>
              </form>
            </ThemeProvider>
            <div className="need-account pt-4 text-center">
              <p>Didn't get the code? <Link to={`/verification?flow=${flow.id}`}>Resend</Link></p>
            </div>
          </div>
        </div>
        <div className="col-7 d-none d-lg-block p-0">
          <div className="image-container register">
            <div className="overlay"></div>
            <img className="main-image" src={registerImg} alt="Verification" />
          </div>
        </div>
      </div>

    </div>
  ) : (
    <LoadingSpinner height={'100vh'} />
  )
}

export default Verification
