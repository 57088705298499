import * as Actions from "../actions/pathway.actions";
import {
	GET_LOCATIONS_OF_PATHWAY_GROUP_FAIL,
	GET_PATHWAY_GROUP_DETAIL_SUCCESS,
} from "../actions/pathway.actions";

const allPathwayGroupsInitialState = {
	data: [],
};

export const allPathwayGroups = (
	state = allPathwayGroupsInitialState,
	action
) => {
	switch (action.type) {
		case Actions.GET_ALL_PATHWAY_GROUPS_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.GET_ALL_PATHWAY_GROUPS_FAIL: {
			return {
				...state,
				data: null,
			};
		}
		default: {
			return state;
		}
	}
};

const PathwayGroupsDetailinitialState = {
	data: [],
};

export const pathwayGroupDetail = (
	state = PathwayGroupsDetailinitialState,
	action
) => {
	switch (action.type) {
		case Actions.GET_PATHWAY_GROUP_DETAIL_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.GET_PATHWAY_GROUP_DETAIL_FAIL: {
			return {
				...state,
				data: null,
			};
		}
		default: {
			return state;
		}
	}
};

const PathwayGroupUniversitiesinitialState = {
	data: [],
};

export const pathwayGroupUniversities = (
	state = PathwayGroupUniversitiesinitialState,
	action
) => {
	switch (action.type) {
		case Actions.GET_PATHWAY_GROUP_ALL_UNIVERSITIES_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.GET_PATHWAY_GROUP_ALL_UNIVERSITIES_FAIL: {
			return {
				...state,
				data: [],
			};
		}
		default: {
			return state;
		}
	}
};

const pathwayGroupRelatedLocationsInitialState = {
	data: [],
};

export const pathwayGroupRelatedLocations = (
	state = pathwayGroupRelatedLocationsInitialState,
	action
) => {
	switch (action.type) {
		case Actions.GET_LOCATIONS_OF_PATHWAY_GROUP_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.GET_LOCATIONS_OF_PATHWAY_GROUP_FAIL: {
			return {
				...state,
				data: [],
			};
		}
		default: {
			return state;
		}
	}
};
const disciplineOfPathwayGroupInitialState = {
	data: [],
};

export const allDisciplineOfPathwayGroup = (
	state = disciplineOfPathwayGroupInitialState,
	action
) => {
	switch (action.type) {
		case Actions.GET_DISCIPLINES_OF_PATHWAY_GROUP_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.GET_DISCIPLINES_OF_PATHWAY_GROUP_FAIL: {
			return {
				...state,
				data: [],
			};
		}
		default: {
			return state;
		}
	}
};
const scholarshipOfPathwayGroupInitialState = {
	data: [],
};

export const allScholarshipsOfPathwayGroup = (
	state = scholarshipOfPathwayGroupInitialState,
	action
) => {
	switch (action.type) {
		case Actions.GET_SCHOLARSHIPS_OF_PATHWAY_GROUP_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.GET_SCHOLARSHIPS_OF_PATHWAY_GROUP_FAIL: {
			return {
				...state,
				data: [],
			};
		}
		default: {
			return state;
		}
	}
};
const courseOfPathwayGroupInitialState = {
	data: [],
};

export const allCourseOfPathwayGroup = (
	state = courseOfPathwayGroupInitialState,
	action
) => {
	switch (action.type) {
		case Actions.GET_ALL_COURSES_OF_ALL_PATHWAYS_GROUP_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.GET_ALL_COURSES_OF_ALL_PATHWAYS_GROUP_FAIL: {
			return {
				...state,
				data: [],
			};
		}
		default: {
			return state;
		}
	}
};
