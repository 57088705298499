

export const handleLoadingDispatcher = (action,loading) => ({
    type: action,
    payload: {
        loading,
    },
});
export const handleSuccessDispatcher = (action,success) => ({
    type: action,
    payload: {
        success,
    },
});

export const handleErrorDispatcher = (action,error) => ({
    type: action,
    payload: {
        error,
    },
});

export const dataDispatcher = (action,data) => ({
    type: action,
    payload: {
        ...data,
    },
});
