// actions/authActions.js
import { LOGIN, LOGOUT } from './types';

export const setOryUserData = (userData) => ({
  type: LOGIN,
  payload: userData,
});

export const logoutOryUser = () => ({
  type: LOGOUT,
});

