import React from 'react';
import CheckboxList from "../loaders/checklist";
import DisciplineFilter from "../filters/disciplines/disciplines_filters";

const DisciplinesIndividual = (props) => {
    return (
        <div className="filterBox">
            <h6 className={props.disciplines ? "filterOpen" : ""}>
                Discipline <i className="fa fa-angle-right"></i>
                <i className="fa fa-angle-down"></i>
            </h6>
            {props.disciplineLoader === true ? (
                <CheckboxList/>
            ) : (
                <div className={"content" + props.disciplines ? " show" : ""}>
                    <div className="filterSearchWrap">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Search Discipline"
                            onChange={props.searchDisciplinesHandler}
                        ></input>
                        <i className="fa fa-search"></i>
                    </div>
                    <div className="filterBoxScroll">
                        {props.filteredDisciplines.length > 0 ? (
                            props.filteredDisciplines.map((discipline, index) => {
                                return (
                                    <DisciplineFilter
                                        key={index}
                                        discipline={discipline}
                                        index={index}
                                        selectedDisciplines={props.selectedDisciplines}
                                        updateSelectedDisciplines={
                                            props.updateSelectedDisciplines
                                        }
                                        rand={Math.random()}
                                    />
                                );
                            })
                        ) : props.disciplines.length > 0 ? (
                            props.disciplines.map((discipline, index) => {
                                return (
                                    <DisciplineFilter
                                        key={index}
                                        discipline={discipline}
                                        index={index}
                                        selectedDisciplines={props.selectedDisciplines}
                                        updateSelectedDisciplines={
                                            props.updateSelectedDisciplines
                                        }
                                        rand={Math.random()}
                                    />
                                );
                            })
                        ) : (
                            <p style={{textAlign: "center"}}>No record found</p>
                        )}
                    </div>
                    <div className="text-center">
                        <button
                            onClick={() => props.coutryApplyNow("disciplines")}
                            className="btn defaultBtn sharePgBtn my-2"
                        >
                            Apply Selection
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DisciplinesIndividual;
