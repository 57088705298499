import { http } from "../../../services/apiService/apiService";
/// Country
export const COURSE_COMPARE_COUNTRIES = "COURSE_COMPARE_COUNTRIES";
export const COURSE_COMPARE_COUNTRIES_SUCCESS =
	"COURSE_COMPARE_COUNTRIES_SUCCESS";
export const COURSE_COMPARE_COUNTRIES_FAIL = "COURSE_COMPARE_COUNTRIES_FAIL";

export function getAllCourseCompareCountries() {
	const url = "search-course/countries";

	const request = http.get(url);

	return (dispatch) =>
		request
			.then((response) =>
				dispatch({
					type: COURSE_COMPARE_COUNTRIES_SUCCESS,
					payload: response.data,
				})
			)
			.catch((error) => {
				return dispatch({
					type: COURSE_COMPARE_COUNTRIES_FAIL,
					payload: [],
				});
			});
}
///////country related disciplines
export const COURSE_COMPARE_COUNTRY_DISCIPLINES =
	"COURSE_COMPARE_COUNTRY_DISCIPLINES";
export const COURSE_COMPARE_COUNTRY_DISCIPLINES_SUCCESS =
	"COURSE_COMPARE_COUNTRY_DISCIPLINES_SUCCESS";
export const COURSE_COMPARE_COUNTRY_DISCIPLINES_FAIL =
	"COURSE_COMPARE_COUNTRY_DISCIPLINES_FAIL";

export function getAllCourseCompareCountryDisciplines(countryId) {
	const url = "search-course/country/" + countryId + "/disciplines";

	const request = http.get(url);

	return (dispatch) =>
		request
			.then((response) =>
				dispatch({
					type: COURSE_COMPARE_COUNTRY_DISCIPLINES_SUCCESS,
					payload: response.data,
				})
			)
			.catch((error) => {
				return dispatch({
					type: COURSE_COMPARE_COUNTRY_DISCIPLINES_FAIL,
					payload: [],
				});
			});
}
////// country discipline related degree levels
export const COURSE_COMPARE_COUNTRY_DISCIPLINE_DEGREELEVELS =
	"COURSE_COMPARE_COUNTRY_DISCIPLINE_DEGREELEVELS";
export const COURSE_COMPARE_COUNTRY_DISCIPLINE_DEGREELEVELS_SUCCESS =
	"COURSE_COMPARE_COUNTRY_DISCIPLINE_DEGREELEVELS_SUCCESS";
export const COURSE_COMPARE_COUNTRY_DISCIPLINE_DEGREELEVELS_FAIL =
	"COURSE_COMPARE_COUNTRY_DISCIPLINE_DEGREELEVELS_FAIL";

export function getAllCourseCompareDisciplinesDegreeLevels(
	countryId,
	disciplineId
) {
	const url =
		"search-course/country/" +
		countryId +
		"/discipline/" +
		disciplineId +
		"/degree_levels";

	const request = http.get(url);

	return (dispatch) =>
		request
			.then((response) =>
				dispatch({
					type: COURSE_COMPARE_COUNTRY_DISCIPLINE_DEGREELEVELS_SUCCESS,
					payload: response.data,
				})
			)
			.catch((error) => {
				return dispatch({
					type: COURSE_COMPARE_COUNTRY_DISCIPLINE_DEGREELEVELS_FAIL,
					payload: [],
				});
			});
}
////////country -> disciplines-> degree level-> related institutes
export const COURSE_COMPARE_RELATED_INSTITUTES =
	"COURSE_COMPARE_RELATED_INSTITUTES";
export const COURSE_COMPARE_RELATED_INSTITUTES_SUCCESS =
	"COURSE_COMPARE_RELATED_INSTITUTES_SUCCESS";
export const COURSE_COMPARE_RELATED_INSTITUTES_FAIL =
	"COURSE_COMPARE_RELATED_INSTITUTES_FAIL";

export function getAllCourseCompareDegreeLevelsInstitutes(
	countryId,
	disciplineId,
	degreeLevelId
) {
	const url =
		"search-course/country/" +
		countryId +
		"/discipline/" +
		disciplineId +
		"/degree_level/" +
		degreeLevelId +
		"/campuses";

	const request = http.get(url);

	return (dispatch) =>
		request
			.then((response) =>
				dispatch({
					type: COURSE_COMPARE_RELATED_INSTITUTES_SUCCESS,
					payload: response.data,
				})
			)
			.catch((error) => {
				return dispatch({
					type: COURSE_COMPARE_RELATED_INSTITUTES_FAIL,
					payload: [],
				});
			});
}
/////
////////country -> disciplines-> degree level-> related institutes spec
export const COURSE_COMPARE_RELATED_INSTITUTES_SPECIALIZATIONS =
	"COURSE_COMPARE_RELATED_INSTITUTES_SPECIALIZATIONS";
export const COURSE_COMPARE_RELATED_INSTITUTES_SPECIALIZATIONS_SUCCESS =
	"COURSE_COMPARE_RELATED_INSTITUTES_SPECIALIZATIONS_SUCCESS";
export const COURSE_COMPARE_RELATED_INSTITUTES_SPECIALIZATIONS_FAIL =
	"COURSE_COMPARE_RELATED_INSTITUTES_SPECIALIZATIONS_FAIL";

export function getAllCourseCompareDegreeLevelsInstitutesSpecialization(
	countryId,
	disciplineId,
	degreeLevelId,
	campusID
) {
	const url =
		"search-course/country/" +
		countryId +
		"/discipline/" +
		disciplineId +
		"/degree_level/" +
		degreeLevelId +
		"/campus/" +
		campusID +
		"/specializations/";

	const request = http.get(url);

	return (dispatch) =>
		request
			.then((response) =>
				dispatch({
					type: COURSE_COMPARE_RELATED_INSTITUTES_SPECIALIZATIONS_SUCCESS,
					payload: response.data,
				})
			)
			.catch((error) => {
				return dispatch({
					type: COURSE_COMPARE_RELATED_INSTITUTES_SPECIALIZATIONS_FAIL,
					payload: [],
				});
			});
}
////// country -> disciplines-> degree level-> specializations related Courses

export const COURSE_COMPARE_RELATED_COURSES = "COURSE_COMPARE_RELATED_COURSES";
export const COURSE_COMPARE_RELATED_COURSES_SUCCESS =
	"COURSE_COMPARE_RELATED_COURSES_SUCCESS";
export const COURSE_COMPARE_RELATED_COURSES_FAIL =
	"COURSE_COMPARE_RELATED_COURSES_FAIL";

export function getAllCourseCompareCourses(
	countryId,
	disciplineId,
	degreeLevelId,
	instituteID,
	specializationId
) {
	const url =
		"search-course/country/" +
		countryId +
		"/discipline/" +
		disciplineId +
		"/degree_level/" +
		degreeLevelId +
		"/campus/" +
		instituteID +
		"/specialization/" +
		specializationId +
		"/courses";

	const request = http.get(url);

	return (dispatch) =>
		request
			.then((response) =>
				dispatch({
					type: COURSE_COMPARE_RELATED_COURSES_SUCCESS,
					payload: response.data,
				})
			)
			.catch((error) => {
				return dispatch({
					type: COURSE_COMPARE_RELATED_COURSES_FAIL,
					payload: [],
				});
			});
}
