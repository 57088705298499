import { CloseOutlined } from "@material-ui/icons";
import { Box, Button, Divider, FormControl, Grid, InputLabel, MenuItem, Modal, Select, TextField } from "@mui/material";
import { styles } from "../constants";
import { DEGREE_LEVELS, DISCIPLINES } from "../../../constants";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";

const EducationModal = ({
  openEducationModal,
  handleCloseEducationModal,
  editEducation,
  handleSubmitEducation,
  handleEditEducation,
  handleAddEducation,
  isMobile,
  errorsEducation,
  registerEducation,
  countryList,
  handleChangeCountry,
  setValueEducation
}) => {
  return (
    <Modal
      open={openEducationModal}
      onClose={handleCloseEducationModal}
      aria-labelledby="add-education"
    >
      <Box className="tcf-v2" sx={styles.educationModal}>
        <div className="d-flex justify-content-between pl-4 pr-4 pt-4">
          <h4 className="font-weight-bold">{editEducation ? "Edit Education" : "Add Education"} </h4>
          <CloseOutlined className="cursor-pointer tcf-icon" onClick={handleCloseEducationModal} />
        </div>
        <div className="pl-4 pr-4 pb-2">
          <Divider />
        </div>
        <div className="w-100 ory-input-fields pl-4 pr-4 pt-2">
          <form
            onSubmit={editEducation ? handleSubmitEducation(handleEditEducation) : handleSubmitEducation(handleAddEducation)}
          >
            <Grid container spacing={2} className="pb-4">
              <Grid item xs={isMobile ? 12 : 6}>
                <FormControl fullWidth>
                  <InputLabel>Degree Level*</InputLabel>
                  <Select
                    error={errorsEducation.degreeLevel}
                    label="Degree Level*"
                    defaultValue={editEducation?.degreeLevel}
                    {...registerEducation("degreeLevel", { required: true })}
                  >
                    {DEGREE_LEVELS.map(degree => (
                      <MenuItem value={degree.value}>{degree.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={isMobile ? 12 : 6}>
                <FormControl fullWidth>
                  <InputLabel>Discipline*</InputLabel>
                  <Select
                    error={errorsEducation.discipline}
                    label="Discipline*"
                    defaultValue={editEducation?.discipline}
                    {...registerEducation("discipline", { required: true })}
                  >
                    {DISCIPLINES?.map(discipline => (
                      <MenuItem value={discipline.value}>{discipline.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} className="pb-4">
              <Grid item xs={isMobile ? 12 : 6}>
                <TextField
                  error={errorsEducation.courseMajor}
                  {...registerEducation("courseMajor", { required: true })}
                  type="text"
                  label="Course Major*"
                  placeholder="Computer Systems Engineering"
                  variant="outlined"
                  className="w-100"
                  helperText={errorsEducation.courseMajor?.message}

                />
              </Grid>
              <Grid item xs={isMobile ? 12 : 6}>
                <TextField
                  error={errorsEducation.institute}
                  {...registerEducation("institute", { required: true })}
                  type="text"
                  label="University / School*"
                  variant="outlined"
                  className="w-100"
                  helperText={errorsEducation.institute?.message}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="pb-4">
              <Grid item xs={isMobile ? 12 : 6}>
                <FormControl fullWidth>
                  <InputLabel>Country</InputLabel>
                  <Select
                    label="Country"
                    defaultValue={editEducation?.country}
                    {...registerEducation("country")}
                  >
                    {countryList.map(country => (
                      <MenuItem onClick={() => handleChangeCountry(country.isoCode)} value={country.name}>{country.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>

              </Grid>
              <Grid item xs={isMobile ? 12 : 6}>
                <TextField
                  error={errorsEducation.grade}
                  {...registerEducation("grade")}
                  type="text"
                  label="Grade/Percentage/CGPA"
                  variant="outlined"
                  className="w-100"
                  helperText={errorsEducation.grade?.message}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="pb-4">
              <Grid item xs={12}>
                <TextField
                  error={errorsEducation.gradeSystem}
                  {...registerEducation("gradeSystem")}
                  type="text"
                  label="Grade System"
                  variant="outlined"
                  className="w-100"
                  helperText={errorsEducation.gradeSystem?.message}
                />

              </Grid>
            </Grid>
            <Grid container spacing={2} className="pb-4">
              <Grid item xs={isMobile ? 12 : 6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker views={['month', 'year']} defaultValue={dayjs(editEducation?.startDate)} onChange={(newValue) => setValueEducation('startDate', newValue.format('MMM YYYY'))} className="w-100" label="Start Date" name="startDate" />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={isMobile ? 12 : 6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker views={['month', 'year']} defaultValue={dayjs(editEducation?.endDate)} onChange={(newValue) => setValueEducation('endDate', newValue.format('MMM YYYY'))} className="w-100" label="End Date" name="endDate" />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Button
              variant="contained"
              className="tcf-button-small float-right mb-4"
              type="submit"
            >
              Save
            </Button>
          </form>
        </div>
      </Box>
    </Modal>
  )
}

export default EducationModal;