import axios from "axios";
import { http } from "../../../services/apiService/apiService";
import {
	dataDispatcher,
	handleErrorDispatcher,
	handleLoadingDispatcher,
	handleSuccessDispatcher,
} from "./data.handler";
//Scholarships
export const GET_ALL_COUNTRY_SCHOLARSHIPS_COUNT_SUCCESS =
	"GET_ALL_COUNTRY_SCHOLARSHIPS_COUNT_SUCCESS";
export const GET_ALL_COUNTRY_SCHOLARSHIPS_COUNT_FAIL =
	"GET_ALL_COUNTRY_SCHOLARSHIPS_COUNT_FAIL";
export const GET_ALL_COUNTRY_SCHOLARSHIPS_COUNT =
	"GET_ALL_COUNTRY_SCHOLARSHIPS_COUNT";
export const GET_ALL_COUNTRY_SCHOLARSHIPS_COUNT_LOADING =
	"GET_ALL_COUNTRY_SCHOLARSHIPS_COUNT_LOADING";
export const getAllCountryScholarshipsCount =
	() => async (dispatch, getState) => {
		dispatch(
			handleLoadingDispatcher(GET_ALL_COUNTRY_SCHOLARSHIPS_COUNT_LOADING, true)
		);
		dispatch(
			handleSuccessDispatcher(GET_ALL_COUNTRY_SCHOLARSHIPS_COUNT_SUCCESS, false)
		);

		if (!getState().apis.countriesScholarshipsCount.data) {
			let url = "country/scholarships/";
			try {
				url = process.env.REACT_APP_BASE_URL + url;
				const response = await axios.get(url);
				dispatch(
					dataDispatcher(GET_ALL_COUNTRY_SCHOLARSHIPS_COUNT, {
						loading: false,
						data: response.data,
						error: "",
						success: true,
					})
				);
			} catch (e) {
				dispatch(
					handleErrorDispatcher(
						GET_ALL_COUNTRY_SCHOLARSHIPS_COUNT_FAIL,
						e.response.data.message
					)
				);
				dispatch(
					handleLoadingDispatcher(
						GET_ALL_COUNTRY_SCHOLARSHIPS_COUNT_LOADING,
						false
					)
				);
			}
		}
	};

export const GET_COUNTRY_SCHOLARSHIPS = "GET_COUNTRY_SCHOLARSHIPS";
export const GET_COUNTRY_SCHOLARSHIPS_FAIL = "GET_COUNTRY_SCHOLARSHIPS_FAIL";
export const GET_COUNTRY_SCHOLARSHIPS_SUCCESS =
	"GET_COUNTRY_SCHOLARSHIPS_SUCCESS";
export const GET_COUNTRY_SCHOLARSHIPS_LOADING =
	"GET_COUNTRY_SCHOLARSHIPS_LOADING";
export const getCountryScholarships =
	(countryID) => async (dispatch, getState) => {
		dispatch(handleLoadingDispatcher(GET_COUNTRY_SCHOLARSHIPS_LOADING, true));
		dispatch(handleSuccessDispatcher(GET_COUNTRY_SCHOLARSHIPS_SUCCESS, false));

		if (!getState().apis.countryScholarships.countryScholarshipData.length) {
			let url = "country/" + countryID + "/scholarships";
			try {
				url = process.env.REACT_APP_BASE_URL + url;
				const response = await axios.get(url);
				dispatch(
					dataDispatcher(GET_COUNTRY_SCHOLARSHIPS, {
						loading: false,
						data: response.data,
						error: "",
						success: true,
					})
				);
			} catch (e) {
				dispatch(
					handleErrorDispatcher(
						GET_COUNTRY_SCHOLARSHIPS_FAIL,
						e.response.data.message
					)
				);
				dispatch(
					handleLoadingDispatcher(GET_COUNTRY_SCHOLARSHIPS_LOADING, false)
				);
			}
		}
	};

export const GET_SCHOLARSHIP_DETAIL = "GET_SCHOLARSHIP_DETAIL";
export const GET_SCHOLARSHIP_DETAIL_FAIL = "GET_SCHOLARSHIP_DETAIL_FAIL";
export const GET_SCHOLARSHIP_DETAIL_SUCCESS = "GET_SCHOLARSHIP_DETAIL_SUCCESS";
export const GET_SCHOLARSHIP_DETAIL_LOADING = "GET_SCHOLARSHIP_DETAIL_LOADING";
export const getScholarshipDetail =
	(scholarshipId) => async (dispatch, getState) => {
		dispatch(handleLoadingDispatcher(GET_SCHOLARSHIP_DETAIL_LOADING, true));
		dispatch(handleSuccessDispatcher(GET_SCHOLARSHIP_DETAIL_SUCCESS, false));
		if (!getState().apis.scholarshipDetails.scholarshipDetailData.length) {
			let url = "scholarship/" + scholarshipId;
			try {
				url = process.env.REACT_APP_BASE_URL + url;
				const response = await axios.get(url);
				dispatch(
					dataDispatcher(GET_SCHOLARSHIP_DETAIL, {
						loading: false,
						data: response.data,
						error: "",
						success: true,
					})
				);
			} catch (e) {
				dispatch(
					handleErrorDispatcher(
						GET_SCHOLARSHIP_DETAIL_FAIL,
						e.response?.data?.message
					)
				);
				dispatch(
					handleLoadingDispatcher(GET_SCHOLARSHIP_DETAIL_LOADING, false)
				);
			}
		}
	};

export const GET_SEARCH_SCHOLARSHIPS = "GET_SEARCH_SCHOLARSHIPS";
export const SEARCH_SCHOLARSHIPS_FAIL = "SEARCH_SCHOLARSHIPS_FAIL";
export const SEARCH_SCHOLARSHIPS_SUCCESS = "SEARCH_SCHOLARSHIPS_SUCCESS";
export const SEARCH_SCHOLARSHIPS_LOADING = "SEARCH_SCHOLARSHIPS_LOADING";
export const searchScholarships =
	(params, pageId) => async (dispatch, getState) => {
		dispatch(handleLoadingDispatcher(SEARCH_SCHOLARSHIPS_LOADING, true));
		dispatch(handleSuccessDispatcher(SEARCH_SCHOLARSHIPS_SUCCESS, false));
		let url = "scholarship/search/";
		if (pageId) {
			url = url + "?page=" + pageId;
		}
		try {
			url = process.env.REACT_APP_BASE_URL + url;
			const response = await axios.post(url, params);
			dispatch(
				dataDispatcher(GET_SEARCH_SCHOLARSHIPS, {
					loading: false,
					data: response.data,
					error: "",
					success: true,
				})
			);
		} catch (e) {
			dispatch(
				handleErrorDispatcher(
					SEARCH_SCHOLARSHIPS_FAIL,
					e.response?.data?.message
				)
			);
			dispatch(handleLoadingDispatcher(SEARCH_SCHOLARSHIPS_LOADING, false));
		}
	};

export const SCHOLARSHIP_RELATED_INSTITUTES = "SCHOLARSHIP_RELATED_INSTITUTES";
export const SCHOLARSHIP_RELATED_INSTITUTES_FAIL =
	"SCHOLARSHIP_RELATED_INSTITUTES_FAIL";
export const SCHOLARSHIP_RELATED_INSTITUTES_SUCCESS =
	"SCHOLARSHIP_RELATED_INSTITUTES_SUCCESS";
export const SCHOLARSHIP_RELATED_INSTITUTES_LOADING =
	"SCHOLARSHIP_RELATED_INSTITUTES_LOADING";
export const getScholarshipRelatedInstitutes =
	(params) => async (dispatch, getState) => {
		dispatch(
			handleLoadingDispatcher(SCHOLARSHIP_RELATED_INSTITUTES_LOADING, true)
		);
		dispatch(
			handleSuccessDispatcher(SCHOLARSHIP_RELATED_INSTITUTES_SUCCESS, false)
		);
		let url = "scholarship/scholarship_related_institutes/";
		try {
			url = process.env.REACT_APP_BASE_URL + url;
			const response = await axios.post(url, params);
			dispatch(
				dataDispatcher(SCHOLARSHIP_RELATED_INSTITUTES, {
					loading: false,
					data: response.data,
					error: "",
					success: true,
				})
			);
		} catch (e) {
			dispatch(
				handleErrorDispatcher(
					SCHOLARSHIP_RELATED_INSTITUTES_FAIL,
					e.response.data.message
				)
			);
			dispatch(
				handleLoadingDispatcher(SCHOLARSHIP_RELATED_INSTITUTES_LOADING, false)
			);
		}
	};

export const SCHOLARSHIP_RELATED_DISCIPLINES_LOADING =
	"SCHOLARSHIP_RELATED_DISCIPLINES_LOADING";
export const SCHOLARSHIP_RELATED_DISCIPLINES_FAIL =
	"SCHOLARSHIP_RELATED_DISCIPLINES_FAIL";
export const SCHOLARSHIP_RELATED_DISCIPLINES_SUCCESS =
	"SCHOLARSHIP_RELATED_DISCIPLINES_SUCCESS";
export const GET_SCHOLARSHIP_RELATED_DISCIPLINES =
	"GET_SCHOLARSHIP_RELATED_DISCIPLINES";

export const getScholarshipRelatedDisciplines =
	(params) => async (dispatch, getState) => {
		dispatch(
			handleLoadingDispatcher(SCHOLARSHIP_RELATED_DISCIPLINES_LOADING, true)
		);
		dispatch(
			handleSuccessDispatcher(SCHOLARSHIP_RELATED_DISCIPLINES_SUCCESS, false)
		);
		let url = "scholarship/scholarship_related_disciplines/";
		try {
			url = process.env.REACT_APP_BASE_URL + url;
			const response = await axios.post(url, params);
			dispatch(
				dataDispatcher(GET_SCHOLARSHIP_RELATED_DISCIPLINES, {
					loading: false,
					data: response.data,
					error: "",
					success: true,
				})
			);
		} catch (e) {
			dispatch(
				handleErrorDispatcher(
					SCHOLARSHIP_RELATED_DISCIPLINES_FAIL,
					e.response.data.message
				)
			);
			dispatch(
				handleLoadingDispatcher(SCHOLARSHIP_RELATED_DISCIPLINES_LOADING, false)
			);
		}
	};

export const LOADING_SCHOLARSHIP_RELATED_DEGREE_LEVELS =
	"LOADING_SCHOLARSHIP_RELATED_DEGREE_LEVELS";
export const GET_SCHOLARSHIP_RELATED_DEGREE_LEVELS =
	"GET_SCHOLARSHIP_RELATED_DEGREE_LEVELS";
export const SCHOLARSHIP_RELATED_DEGREE_LEVELS_ERROR =
	"SCHOLARSHIP_RELATED_DEGREE_LEVELS_ERROR";
export const SCHOLARSHIP_RELATED_DEGREE_LEVELS_SUCCESS =
	"SCHOLARSHIP_RELATED_DEGREE_LEVELS_SUCCESS";
export const getScholarshipRelatedDegreeLevels =
	(params) => async (dispatch, getState) => {
		dispatch(
			handleLoadingDispatcher(LOADING_SCHOLARSHIP_RELATED_DEGREE_LEVELS, true)
		);
		dispatch(
			handleSuccessDispatcher(SCHOLARSHIP_RELATED_DEGREE_LEVELS_SUCCESS, false)
		);
		let url = "scholarship/scholarship_related_degree_levels/";
		try {
			url = process.env.REACT_APP_BASE_URL + url;
			const response = await axios.post(url, params);
			dispatch(
				dataDispatcher(GET_SCHOLARSHIP_RELATED_DEGREE_LEVELS, {
					loading: false,
					data: response.data,
					error: "",
					success: true,
				})
			);
		} catch (e) {
			dispatch(
				handleErrorDispatcher(
					SCHOLARSHIP_RELATED_DEGREE_LEVELS_ERROR,
					e.response.data.message
				)
			);
			dispatch(
				handleLoadingDispatcher(
					LOADING_SCHOLARSHIP_RELATED_DEGREE_LEVELS,
					false
				)
			);
		}
	};

export const GET_SCHOLARSHIP_RELATED_TYPES = "GET_SCHOLARSHIP_RELATED_TYPES";
export const SCHOLARSHIP_RELATED_TYPES_FAIL = "SCHOLARSHIP_RELATED_TYPES_FAIL";
export const SCHOLARSHIP_RELATED_TYPES_SUCCESS =
	"SCHOLARSHIP_RELATED_TYPES_SUCCESS";
export const SCHOLARSHIP_RELATED_TYPES_LOADING =
	"SCHOLARSHIP_RELATED_TYPES_LOADING";

export const getScholarshipRelatedTypes =
	(params) => async (dispatch, getState) => {
		dispatch(handleLoadingDispatcher(SCHOLARSHIP_RELATED_TYPES_LOADING, true));
		dispatch(handleSuccessDispatcher(SCHOLARSHIP_RELATED_TYPES_SUCCESS, false));
		let url = "scholarship/scholarship_related_types/";
		try {
			url = process.env.REACT_APP_BASE_URL + url;
			const response = await axios.post(url, params);
			dispatch(
				dataDispatcher(GET_SCHOLARSHIP_RELATED_TYPES, {
					loading: false,
					data: response.data,
					error: "",
					success: true,
				})
			);
		} catch (e) {
			dispatch(
				handleErrorDispatcher(
					SCHOLARSHIP_RELATED_TYPES_FAIL,
					e.response.data.message
				)
			);
			dispatch(
				handleLoadingDispatcher(SCHOLARSHIP_RELATED_TYPES_LOADING, false)
			);
		}
	};

export const SCHOLARSHIP_RELATED_MONTHS = "SCHOLARSHIP_RELATED_MONTHS";
export const SCHOLARSHIP_RELATED_MONTHS_FAIL =
	"SCHOLARSHIP_RELATED_MONTHS_FAIL";
export const SCHOLARSHIP_RELATED_MONTHS_SUCCESS =
	"SCHOLARSHIP_RELATED_MONTHS_SUCCESS";
export const SCHOLARSHIP_RELATED_MONTHS_LOADING =
	"SCHOLARSHIP_RELATED_MONTHS_LOADING";
export const getScholarshipRelatedMonths =
	(params) => async (dispatch, getState) => {
		dispatch(handleLoadingDispatcher(SCHOLARSHIP_RELATED_MONTHS_LOADING, true));
		dispatch(
			handleSuccessDispatcher(SCHOLARSHIP_RELATED_MONTHS_SUCCESS, false)
		);
		let url = "scholarship/scholarship_related_months/";
		try {
			url = process.env.REACT_APP_BASE_URL + url;
			const response = await axios.post(url, params);
			dispatch(
				dataDispatcher(SCHOLARSHIP_RELATED_MONTHS, {
					loading: false,
					data: response.data,
					error: "",
					success: true,
				})
			);
		} catch (e) {
			dispatch(
				handleErrorDispatcher(
					SCHOLARSHIP_RELATED_MONTHS_FAIL,
					e.response.data.message
				)
			);
			dispatch(
				handleLoadingDispatcher(SCHOLARSHIP_RELATED_MONTHS_LOADING, false)
			);
		}
	};

export const SCHOLARSHIP_RELATED_YEARS = "SCHOLARSHIP_RELATED_YEARS";
export const SCHOLARSHIP_RELATED_YEARS_FAIL = "SCHOLARSHIP_RELATED_YEARS_FAIL";
export const SCHOLARSHIP_RELATED_YEARS_SUCCESS =
	"SCHOLARSHIP_RELATED_YEARS_SUCCESS";
export const SCHOLARSHIP_RELATED_YEARS_LOADING =
	"SCHOLARSHIP_RELATED_YEARS_LOADING";
export const getScholarshipRelatedYears =
	(params) => async (dispatch, getState) => {
		dispatch(handleLoadingDispatcher(SCHOLARSHIP_RELATED_YEARS_LOADING, true));
		dispatch(handleSuccessDispatcher(SCHOLARSHIP_RELATED_YEARS_SUCCESS, false));
		let url = "scholarship/scholarship_related_years/";
		try {
			url = process.env.REACT_APP_BASE_URL + url;
			const response = await axios.post(url, params);
			dispatch(
				dataDispatcher(SCHOLARSHIP_RELATED_YEARS, {
					loading: false,
					data: response.data,
					error: "",
					success: true,
				})
			);
		} catch (e) {
			dispatch(
				handleErrorDispatcher(
					SCHOLARSHIP_RELATED_YEARS_FAIL,
					e.response.data.message
				)
			);
			dispatch(
				handleLoadingDispatcher(SCHOLARSHIP_RELATED_YEARS_LOADING, false)
			);
		}
	};

export const SCHOLARSHIP_RELATED_COUNTRIES = "SCHOLARSHIP_RELATED_COUNTRIES";
export const SCHOLARSHIP_RELATED_COUNTRIES_FAIL =
	"SCHOLARSHIP_RELATED_COUNTRIES_FAIL";
export const SCHOLARSHIP_RELATED_COUNTRIES_SUCCESS =
	"SCHOLARSHIP_RELATED_COUNTRIES_SUCCESS";

export const getScholarshipRelatedCountries = () => {
	let url = "scholarship/scholarship_related_countries/";
	const request = http.get(url);
	return (dispatch) =>
		request
			.then((response) => {
				return dispatch({
					type: SCHOLARSHIP_RELATED_COUNTRIES_SUCCESS,
					payload: response.data,
				});
			})
			.catch((error) => {
				return dispatch({
					type: SCHOLARSHIP_RELATED_COUNTRIES_FAIL,
					payload: [],
				});
			});
};

// export const getScholarshipRelatedCountries = () => async (dispatch,getState) => {
// 	if(!getState().apis.scholarshipRelatedCountries.data.length) {
// 		let url = "scholarship/scholarship_related_countries/";
// 		try {
// 			url = process.env.REACT_APP_BASE_URL + url
// 			const response = await axios.get(url);
// 			dispatch(
// 				{
// 					type: SCHOLARSHIP_RELATED_COUNTRIES_SUCCESS,
// 					payload: response.data,
// 				}
// 			);
// 		} catch (e) {
// 			return dispatch({
// 				type: SCHOLARSHIP_RELATED_COUNTRIES_FAIL,
// 				payload: [],
// 			});
// 		}
// 	}
// };

export const GET_INSTITUTE_SCHOLARSHIPS = "GET_INSTITUTE_SCHOLARSHIPS";
export const GET_INSTITUTE_SCHOLARSHIPS_FAIL =
	"GET_INSTITUTE_SCHOLARSHIPS_FAIL";
export const GET_INSTITUTE_SCHOLARSHIPS_SUCCESS =
	"GET_INSTITUTE_SCHOLARSHIPS_SUCCESS";
export const GET_INSTITUTE_SCHOLARSHIPS_LOADING =
	"GET_INSTITUTE_SCHOLARSHIPS_LOADING";
export const getInstituteScholarships =
	(instituteId) => async (dispatch, getState) => {
		dispatch(handleLoadingDispatcher(GET_INSTITUTE_SCHOLARSHIPS_LOADING, true));
		dispatch(
			handleSuccessDispatcher(GET_INSTITUTE_SCHOLARSHIPS_SUCCESS, false)
		);
		if (!getState().apis.instituteScholarships.data.length) {
			let url = "scholarship/institute/" + instituteId;
			try {
				url = process.env.REACT_APP_BASE_URL + url;
				const response = await axios.get(url);
				dispatch(
					dataDispatcher(GET_INSTITUTE_SCHOLARSHIPS, {
						loading: false,
						data: response.data,
						error: "",
						success: true,
					})
				);
			} catch (e) {
				dispatch(
					handleErrorDispatcher(
						GET_INSTITUTE_SCHOLARSHIPS_FAIL,
						e.response.data.message
					)
				);
				dispatch(
					handleLoadingDispatcher(GET_INSTITUTE_SCHOLARSHIPS_LOADING, false)
				);
			}
		}
	};
