import * as Actions from "../actions/course.actions";

const searchCourseInitialState = {
	data: [],
};

export const searchCourse = (state = searchCourseInitialState, action) => {
	switch (action.type) {
		case Actions.SEARCH_COURSE_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.SEARCH_COURSE_FAIL: {
			return {
				...state,
				data: null,
			};
		}
		default: {
			return state;
		}
	}
};

const searchCoursesInitialState = {
	data: [],
};

export const searchCourses = (state = searchCoursesInitialState, action) => {
	switch (action.type) {
		case Actions.SEARCH_COURSES_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.SEARCH_COURSES_FAIL: {
			return {
				...state,
				data: null,
			};
		}
		default: {
			return state;
		}
	}
};

const courseDetailInitialState = {
	data: [],
};

export const courseDetail = (state = courseDetailInitialState, action) => {
	switch (action.type) {
		case Actions.COURSE_DETAIL_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.COURSE_DETAIL_FAIL: {
			return {
				...state,
				data: null,
			};
		}
		default: {
			return state;
		}
	}
};

const disciplineCourseInitialState = {
	data: [],
};

export const disciplineCourse = (
	state = disciplineCourseInitialState,
	action
) => {
	switch (action.type) {
		case Actions.DISCIPLINE_COURSE_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.DISCIPLINE_COURSE_FAIL: {
			return {
				...state,
				data: null,
			};
		}
		default: {
			return state;
		}
	}
};

const countryDisciplineCourseInitialState = {
	data: [],
};

export const countryDisciplineCourse = (
	state = countryDisciplineCourseInitialState,
	action
) => {
	switch (action.type) {
		case Actions.COUNTRY_DISCIPLINE_COURSE_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.COUNTRY_DISCIPLINE_COURSE_FAIL: {
			return {
				...state,
				data: null,
			};
		}
		default: {
			return state;
		}
	}
};

// course FILTERS apis reducers

const courseRelatedLocationsInitialState = {
	data: [],
};

export const courseRelatedLocations = (
	state = courseRelatedLocationsInitialState,
	action
) => {
	switch (action.type) {
		case Actions.COURSE_RELATED_LOCATIONS_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.COURSE_RELATED_LOCATIONS_FAIL: {
			return {
				...state,
				data: null,
			};
		}
		default: {
			return state;
		}
	}
};

const courseRelatedDegreelevelsInitialState = {
	data: [],
};

export const courseRelatedDegreeLevels = (
	state = courseRelatedDegreelevelsInitialState,
	action
) => {
	switch (action.type) {
		case Actions.COURSE_RELATED_DEGREE_LEVELS_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.COURSE_RELATED_DEGREE_LEVELS_FAIL: {
			return {
				...state,
				data: null,
			};
		}
		default: {
			return state;
		}
	}
};

const courseRelatedSpecializationsInitialState = {
	data: [],
};
export const courseRelatedSpecializations = (
	state = courseRelatedSpecializationsInitialState,
	action
) => {
	switch (action.type) {
		case Actions.COURSE_RELATED_SPECIALIZATIONS_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.COURSE_RELATED_SPECIALIZATIONS_FAIL: {
			return {
				...state,
				data: null,
			};
		}
		default: {
			return state;
		}
	}
};

const courseRelatedIntakesInitialState = {
	data: [],
};
export const courseRelatedIntakes = (
	state = courseRelatedIntakesInitialState,
	action
) => {
	switch (action.type) {
		case Actions.COURSE_RELATED_INTAKES_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.COURSE_RELATED_INTAKES_FAIL: {
			return {
				...state,
				data: null,
			};
		}
		default: {
			return state;
		}
	}
};

const courseCompareDetailInitialState = {
	data: null,
};
export const courseCompareDetail = (
	state = courseCompareDetailInitialState,
	action
) => {
	switch (action.type) {
		case Actions.COURSE_COMPARE_DETAIL_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.COURSE_COMPARE_DETAIL_FAIL: {
			return {
				...state,
				data: null,
			};
		}
		default: {
			return state;
		}
	}
};
const courseRelatedInstituteNameInitialState = {
	data: [],
};
export const courseRelatedInstituteName = (
	state = courseRelatedInstituteNameInitialState,
	action
) => {
	switch (action.type) {
		case Actions.COURSE_RELATED_INSTITUTE_NAME_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.COURSE_RELATED_INSTITUTE_NAME_FAIL: {
			return {
				...state,
				data: null,
			};
		}
		default: {
			return state;
		}
	}
};
