import { http } from "../../../services/apiService/apiService";
import axios from "axios";
import {
  GET_ALL_POPULAR_CITIES_FAIL,
  GET_ALL_POPULAR_CITIES_SUCCESS,
} from "./region.actions";

// Disciplines

export const GET_ALL_DISCIPLINES = "GET_ALL_DISCIPLINES";
export const GET_ALL_DISCIPLINES_SUCCESS = "GET_ALL_DISCIPLINES_SUCCESS";
export const GET_ALL_DISCIPLINES_FAIL = "GET_ALL_DISCIPLINES_FAIL";

// export function getAllDesciplines() {
// 	const request = http.get("discipline/");
//
// 	return (dispatch) =>
// 		request
// 			.then((response) =>
// 				dispatch({
// 					type: GET_ALL_DISCIPLINES_SUCCESS,
// 					payload: response.data,
// 				})
// 			)
// 			.catch((error) => {
// 				return dispatch({
// 					type: GET_ALL_DISCIPLINES_FAIL,
// 					payload: [],
// 				});
// 			});
// }

export const getAllDesciplines = () => async (dispatch, getState) => {
  // dispatch(handleLoadingDispatcher(LOADING_COUNTRY_RANKING, true));
  // dispatch(handleSuccessDispatcher(SUCCESS_COUNTRY_RANKING, false));
  if (!getState().apis.allDisciplineLevels.data.length) {
    let url = "discipline/";
    try {
      url = process.env.REACT_APP_BASE_URL + url;
      const response = await axios.get(url);
      dispatch({
        type: GET_ALL_DISCIPLINES_SUCCESS,
        payload: response.data.results,
      });
    } catch (e) {
      return dispatch({
        type: GET_ALL_DISCIPLINES_FAIL,
        payload: [],
      });
    }
  }
};

export const GET_DISCIPLINE_DETAIL = "GET_DISCIPLINE_DETAIL";
export const GET_DISCIPLINE_DETAIL_SUCCESS = "GET_DISCIPLINE_DETAIL_SUCCESS";
export const GET_DISCIPLINE_DETAIL_FAIL = "GET_DISCIPLINE_DETAIL_FAIL";

export function getDisciplineDetail(disciplineId) {
  const request = http.get("discipline/" + disciplineId);

  return (dispatch) =>
    request
      .then((response) =>
        dispatch({
          type: GET_DISCIPLINE_DETAIL_SUCCESS,
          payload: response.data,
        })
      )
      .catch((error) => {
        return dispatch({
          type: GET_DISCIPLINE_DETAIL_FAIL,
          payload: [],
        });
      });
}

export const GET_COUNTRY_ALL_DISCIPLINES = "GET_COUNTRY_ALL_DISCIPLINES";
export const GET_COUNTRY_ALL_DISCIPLINES_SUCCESS =
  "GET_COUNTRY_ALL_DISCIPLINES_SUCCESS";
export const GET_COUNTRY_ALL_DISCIPLINES_FAIL =
  "GET_COUNTRY_ALL_DISCIPLINES_FAIL";

export function getCountryAllDisciplines(countryId) {
  const url = "country/" + countryId + "/disciplines";
  const request = http.get(url);

  return (dispatch) =>
    request
      .then((response) =>
        dispatch({
          type: GET_COUNTRY_ALL_DISCIPLINES_SUCCESS,
          payload: response.data,
        })
      )
      .catch((error) => {
        return dispatch({
          type: GET_COUNTRY_ALL_DISCIPLINES_FAIL,
          payload: [],
        });
      });
}

export const GET_DISCIPLINE_ALL_SPECIALIZATION =
  "GET_DISCIPLINE_ALL_SPECIALIZATION";
export const GET_DISCIPLINE_ALL_SPECIALIZATION_SUCCESS =
  "GET_DISCIPLINE_ALL_SPECIALIZATION_SUCCESS";
export const GET_DISCIPLINE_ALL_SPECIALIZATION_FAIL =
  "GET_DISCIPLINE_ALL_SPECIALIZATION_FAIL";

export function getDisciplineAllSpecialization(disciplineId) {
  const url = "discipline/" + disciplineId + "/specializations";
  const request = http.get(url);

  return (dispatch) =>
    request
      .then((response) =>
        dispatch({
          type: GET_DISCIPLINE_ALL_SPECIALIZATION_SUCCESS,
          payload: response.data,
        })
      )
      .catch((error) => {
        let e = {};
        return dispatch({
          type: GET_DISCIPLINE_ALL_SPECIALIZATION_FAIL,
          payload: [],
        });
      });
}
