import * as Actions from "../actions/discover.action";

const initialStateAllDisciplines = {
	data: [],
};

export const allDiscoverDisciplines = (
	state = initialStateAllDisciplines,
	action
) => {
	switch (action.type) {
		case Actions.GET_ALL_DISCOVER_DISCIPLINES_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.GET_ALL_DISCOVER_DISCIPLINES_FAIL: {
			return {
				...state,
				data: null,
			};
		}
		default: {
			return state;
		}
	}
};
const initialStateAllScholarships = {
	data: [],
};

export const allDiscoverScholarships = (
	state = initialStateAllScholarships,
	action
) => {
	switch (action.type) {
		case Actions.GET_ALL_DISCOVER_SCHOLARSHIPS_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.GET_ALL_DISCOVER_SCHOLARSHIPS_FAIL: {
			return {
				...state,
				data: null,
			};
		}
		default: {
			return state;
		}
	}
};
