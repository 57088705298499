import * as actions from "../actions";
const initialState = {
	filters: [],
};
export function instituteScholarshipsFiltersReducer(
	state = initialState,
	action
) {
	switch (action.type) {
		case actions.ADD_INSTITUTE_SCHOLARSHIPS_FILTER_DATA_SUCCESS:
			return {
				...state,
				filters: action.payload,
			};
		case actions.ADD_INSTITUTE_SCHOLARSHIPS_FILTER_DATA_FAIL:
			return {
				...state,
				filters: [],
			};
		default:
			return state;
	}
}
