import React, {memo, useEffect, useState} from "react";
import $ from "jquery";
import {Link, useHistory} from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";
import {http} from "../../../services/apiService/apiService";
import CourseApplyForm from "../../forms/apply_form";
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookMessengerShareButton,
    WhatsappIcon,
    WhatsappShareButton,
} from "react-share";
import LoadingSpinner from "../../loader/loader";
import Pagination from "react-responsive-pagination";
import {Alert, Drawer} from "@mui/material";
import {useSelector} from "react-redux";
import ListViewDataRendering from "./ListViewRendering"
import { getSingleRoute } from "../../../../common/utils";

let selectedCourse = [];

function CourseListCardView(props) {
    const {courses} = props;
    const history = useHistory();
    const [applyCourse, setApplyCourse] = useState(null);
    const [applyCourseInstitute, setApplyCourseInstitute] = useState(null);
    const [relevantCourses, setRelevantCourses] = useState([]);
    const [institute, setInstitute] = useState(null);
    const marketingCard = useSelector(({apis}) => apis.allMarketingCards.data);
    const [fav_Course, setFavCourse] = useState(
        JSON.parse(localStorage.getItem("fav_course"))
    );
    let data = [];
    const [newCourses, setNewCourses] = useState();
    const [loader, setLoader] = useState(true);
    const [pageState, setPageState] = useState({
        offset: 0,
        perPage: 27,
        currentPage: 1,
        pageCount: 0,
    });
    const [relevantLoading, setRelevantLoading] = useState(true);
    const [relatedCoursesList, setRelatedCoursesList] = useState(null);
    const [url, setUrl] = useState();
    const [openModal, setOpenModal] = useState(false)
    const [drawerMessage, setDrawerMessage] = useState("")
    const [compareCourses, setCompareCourses] = useState([]);
    const [selected, setSelected] = useState(false);
    let index = 6;
    useEffect(() => {
        setFavCourse(JSON.parse(localStorage.getItem("fav_course")));
        setLoader(false);
    }, [props]);

    const commaSeparators = (num) => {
        let numParts = num.toString().split(".");
        numParts[0] = numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return numParts.join(".");
    };

    function clickApplyForm(course, institute) {
        if (localStorage.getItem("user_Id")) {
            $("#applyModal").modal("show");
            setApplyCourse(course);
            setApplyCourseInstitute(institute);
        } else {
            $("#login").modal("show");
        }
    }

    const handleModalOpen = (item) => {
        localStorage.setItem("ShareModal", "true");
        setUrl(
            `${window.location.origin}/course-detail/${item.course_slug}`
        );
        $("#shareCourse").modal("show");
    };
    

    async function onClickMoreRelevantCourses(institute, courses) {
        setRelevantLoading(true);
        setInstitute(institute);
        setRelatedCoursesList(courses);
        $("#MoreCoursesModal").modal("show");

        await http.post(`course/courses_by_ids/`, data = {'courses_ids': courses}).then((res) => {

            pageState.pageCount = Math.ceil(res.data.count / pageState.perPage);
            pageState.offset = pageState.current * pageState.perPage;
            setPageState(pageState);
            setRelevantCourses(res?.data?.results);
            setRelevantLoading(false);
        })
    }

    function handlePageClick(page_number) {
        let selectedPage = page_number;
        const offset = selectedPage * pageState.perPage;
        pageState.currentPage = selectedPage;
        pageState.offset = offset;

        setPageState(pageState);
        setRelevantLoading(true);
        http.post(`course/courses_by_ids/?page=${page_number}`, data = {'courses_ids': relatedCoursesList}).then((res) => {
            pageState.pageCount = Math.ceil(res.data.count / pageState.perPage);
            pageState.offset = pageState.current * pageState.perPage;
            setPageState(pageState);
            setRelevantCourses(res?.data?.results);
            setRelevantLoading(false);
        })
    }
    function copyToClickBoard() {
        navigator.clipboard.writeText(url);
        if (navigator.clipboard.writeText(url)) {
            setSelected(true);
        }
    }

    const openDrawer = (course) => {

        if (selectedCourse.length === 3) {

            setDrawerMessage("You can compare 3 courses ")
            setOpenModal(true);
        } else {
            if (selectedCourse.length > 1) {
                const status = selectedCourse.some((element, index) => {
                    return element.id === course.id;
                });
                if (status) {
                    toast.error("Course Already selected")
                    setOpenModal(false);
                } else {
                    setOpenModal(true);
                }
            }
            selectedCourse.push({id: course.id,slug:course.slug, name: course.name, logo: course.institute.logo})
            setOpenModal(true);
            setDrawerMessage("");

        }
        const ids = selectedCourse.map(o => o.id)
        selectedCourse = selectedCourse.filter(({id}, index) => !ids.includes(id, index + 1))
        setCompareCourses(selectedCourse);

    }
    const toggleDrawer = () => (event) => {
        setOpenModal(false);
        setDrawerMessage("");
    };
    const removeSelectedCourse = (e, course) => {
        e.preventDefault();
        setDrawerMessage("");
        selectedCourse = selectedCourse.filter(item => item.id !== course.id);
        if (selectedCourse.length === 0) {
            setOpenModal(false);
        }
        setCompareCourses(selectedCourse);

    }
    function navigateToCompare() {
        if (selectedCourse.length === 2) {
            getRoute('compare_course',{
                search: $.param({
                    crs1: selectedCourse[0]?.id,
                    crs2: selectedCourse[1]?.id,
                })
            })
            // history.push({
            //     pathname: "/compare-course",
            //     search: $.param({
            //         crs1: selectedCourse[0]?.id,
            //         crs2: selectedCourse[1]?.id,
            //     }),
            // })
        } else {
            getRoute('compare_course',{
                search: $.param({
                    crs1: selectedCourse[0]?.id,
                    crs2: selectedCourse[1]?.id,
                    crs3: selectedCourse[2]?.id,
                }),
            })
            // history.push({
            //     pathname: "/compare-course",
            //     search: $.param({
            //         crs1: selectedCourse[0]?.id,
            //         crs2: selectedCourse[1]?.id,
            //         crs3: selectedCourse[2]?.id,
            //     }),
            // })
        }

    }
    const getRoute = (page, id) => {
        getSingleRoute(page).then(d => {
            history.push({
                pathname: d,
                search: id?.search?id?.search:'',
            });
        })
    }
    return (
        <div className="universityListWrap pt-4">
            <ToastContainer
                position="top-center"
                autoClose={1000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover={false}
            />
            {courses ? courses.map((item, index) => {
                    if (item) {
                        return (
                            <ListViewDataRendering
                                key={index}
                                item={item}
                                handleModalOpen={handleModalOpen}
                                onClickApplyForm={clickApplyForm}
                                commaSeparators={commaSeparators}
                                relevantCoursesClick={onClickMoreRelevantCourses}
                                openDrawer={openDrawer}
                                data={data}
                                oldFav={fav_Course}
                            />
                        );
                    }
                }) :
                //     courses?.map((item, index) => {
                //     if (item) {
                //         return (
                //             <ListViewDataRendering
                //                 key={index}
                //                 item={item}
                //                 handleModalOpen={handleModalOpen}
                //                 onClickApplyForm={clickApplyForm}
                //                 commaSeparators={commaSeparators}
                //                 relevantCoursesClick={onClickMoreRelevantCourses}
                //                 openDrawer={openDrawer}
                //                 data={data}
                //                 oldFav={fav_Course}
                //             />
                //         );
                //     }
                // })
                null
            }

            {/*    // 		/!*{item.courses.length >= 2 ? (*!/*/}
            {/*    // 		/!*	<p className="relevantP">*!/*/}
            {/*    // 		/!*		<a*!/*/}
            {/*    // 		/!*			href*!/*/}
            {/*    // 		/!*			onClick={(e) => {*!/*/}
            {/*    // 		/!*				onClickMoreRelevantCourses(item.institute, item);*!/*/}
            {/*    // 		/!*			}}*!/*/}
            {/*    // 		/!*		>*!/*/}
            {/*    // 		/!*			{item.courses.length - 1} relevant programs also available**!/*/}
            {/*    // 		/!*		</a>*!/*/}
            {/*    // 		/!*	</p>*!/*/}
            {/*    // 		/!*) : null}*!/*/}


            {/*// <div className="uniListFoo">*/}
            {/*// 	{item?.courses?.length >= 2 ?(*/}
            {/*// 		<p className="relevantP">*/}
            {/*// 			<a href="#"*/}
            {/*// 				onClick={(e) => {*/}
            {/*// 					onClickMoreRelevantCourses(item.institute, item.courses);*/}
            {/*// 				}}*/}
            {/*// 			>*/}
            {/*// 				{item.courses.length - 1} relevant programs also available**/}
            {/*// 			</a>*/}
            {/*// 		</p>*/}
            {/*// 	):(<p style={{ marginBottom: 0 }}></p>)}*/}
            {/*// /!* {item?.courses?.length < 2 && <p style={{ marginBottom: 0 }}></p>} *!/*/}
            {/*// /!*<p className="relevantP">*!/*/}
            {/*// /!*	<a href="#">3 relevant programs also available*</a>*!/*/}
            {/*// /!*</p>*!/*/}


            <div
                className="modal fade signinModal shareCourse"
                id="shareCourse"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
                data-backdrop="static"
                data-keyboard="false"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                id=""
                                aria-label="Close"
                            >
                                <img
                                    src={require("../../../../static/images/modal-times.svg").default}
                                    alt="banner"
                                />
                            </button>

                            <h3>Share Course Link</h3>
                            <div className="shareSearch">
                                <input
                                    type="text"
                                    // disabled={urlSpinner}
                                    className="form-control"
                                    defaultValue={url}
                                />
                                <button
                                    className="btn defaultBtn"
                                    onClick={copyToClickBoard}
                                // disabled={urlSpinner}
                                >
                                    {selected ? (
                                        <span>Copied</span>
                                    ) : (
                                        <span>Copy</span>
                                    )}
                                </button>
                            </div>
                            <p>or Share via</p>
                            <ul>
                                <li>
                                    <WhatsappShareButton
                                        url={url}
                                        // disabled={urlSpinner}
                                        title={"Tap the Link below to open the website"}
                                    >
                                        <WhatsappIcon size="3rem" />
                                    </WhatsappShareButton>
                                </li>
                                <li>
                                    <FacebookMessengerShareButton
                                        appId={"867455750856341"}
                                        // disabled={urlSpinner}
                                        redirectUri="https://timescoursefinder.com"
                                        url={url}
                                        clientid={"102"}
                                        title="Tap the Link below to open website "
                                    >
                                        <FacebookIcon size={"3rem"} />
                                    </FacebookMessengerShareButton>
                                </li>
                                <li>
                                    <EmailShareButton
                                        subject={"Course Detail"}
                                        body={"Click the given link " + url + " ."}
                                    >
                                        <EmailIcon size={"3rem"} />
                                    </EmailShareButton>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="modal signinModal fade"
                id="applyModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="applyModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                id="login_modal_close_btn"
                                aria-label="Close"
                            >
                                <img
                                    src={
                                        require("../../../../static/images/modal-times.svg").default
                                    }
                                    alt="modal-times"
                                />
                            </button>
                            {applyCourse && (
                                <CourseApplyForm
                                    course={applyCourse}
                                    institute={applyCourseInstitute}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="modal fade"
                id="MoreCoursesModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="applyModalLabel"
                aria-hidden="true"
                data-backdrop="static"
                data-keyboard="false"
            >
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="requestInfoModalLabel">
                                Relevant Courses of {institute?.institute_name}
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {relevantLoading === true ? <LoadingSpinner/> : relevantCourses.map((item) => (
                                <div className="universityList">
                                    <div className="imgWrap">
                                        <img
                                            src={institute?.logo}
                                            alt={institute?.institute_name}
                                        />
                                    </div>
                                    <div className="contentWrap">
                                        <div className="topBtnGroup">
                                            <button
                                                className="btn"
                                                onClick={() => handleModalOpen(item.id)}
                                            >
                                                <img
                                                    src={
                                                        require("../../../../static/images/share-purple.svg")
                                                            .default
                                                    }
                                                    alt="share-purple"
                                                />
                                            </button>
                                            <button className="btn">
                                                <img
                                                    src={
                                                        require("../../../../static/images/fav-purple.svg")
                                                            .default
                                                    }
                                                    alt="fav-purple"
                                                />
                                            </button>
                                        </div>
                                        <a href="#">
                                            {/* <h4>{institute.institute_name}</h4>{" "} */}
                                            <h4>{item.campus.institute.institute_name}</h4>{" "}
                                        </a>
                                        <h5>{item.campus.campus}</h5>
                                        <h6>{item.name}</h6>
                                        <ul>
                                            <li>
                                                <img
                                                    src={
                                                        require("../../../../static/images/Specialization1.svg")
                                                            .default
                                                    }
                                                    alt="Specialization1"
                                                />{" "}
                                                {/* {item.discipline} */}
                                                {item.specialization.name}
                                            </li>
                                            <li>
                                                <img
                                                    src={
                                                        require("../../../../static/images/on_campus.svg")
                                                            .default
                                                    }
                                                    alt="on_campus"
                                                />{" "}
                                                On Campus
                                            </li>
                                            <li>
                                                <img
                                                    src={
                                                        require("../../../../static/images/English.svg")
                                                            .default
                                                    }
                                                    alt="English"
                                                />
                                                {item.course_language}
                                            </li>
                                            <li>
                                                <img
                                                    src={
                                                        require("../../../../static/images/Amount.svg").default
                                                    }
                                                    alt="Amount"
                                                />
                                                {item.course_fee.length >= 1
                                                    ? (item.course_fee[0]?.converted_value
                                                        ? commaSeparators(
                                                            item.course_fee[0]?.converted_value.toFixed(0)
                                                        ) +
                                                        " " +
                                                        item.course_fee[0]?.currency?.converted_currency
                                                        : commaSeparators(
                                                            item.course_fee[0]?.ceil_value
                                                        ) +
                                                        " " +
                                                        item.course_fee[0]?.currency?.display_name) +
                                                    "/Year"
                                                    : "To Be Confirmed"}
                                            </li>
                                            <li>
                                                <img
                                                    src={
                                                        require("../../../../static/images/bachelor_honors.svg")
                                                            .default
                                                    }
                                                    alt="bachelor_honors"
                                                />{" "}
                                                {item.degree_level.display_name} | {item.course_title.display_name}
                                            </li>
                                            <li>
                                                <img
                                                    src={
                                                        require("../../../../static/images/Years.svg").default
                                                    }
                                                    alt="Years"
                                                />{" "}
                                                {item.course_duration}
                                            </li>
                                            <li>
                                                <img
                                                    src={
                                                        require("../../../../static/images/full_time.svg")
                                                            .default
                                                    }
                                                    alt="full_time"
                                                />{" "}
                                                Full Time
                                            </li>
                                        </ul>
                                        <div className="uniListFoo">
                                            <div className="btnGroup">
                                                <button
                                                onClick={()=>getRoute('course_detail',{
                                                    search: $.param({
                                                        course_id: item.id,
                                                        course_name: item.name,
                                                    })
                                                })}
                                                    className="btn"
                                                    // to={{
                                                    //     pathname: "/course-detail",
                                                    //     search: $.param({
                                                    //         course_id: item.id,
                                                    //         course_name: item.name,
                                                    //     }),
                                                    // }}

                                                    // onClick={(e) => discover(e, item.id)}
                                                >
                                                    Discover
                                                </button>

                                                <button
                                                    // to={{
                                                    // 	pathname: "/compare-course",
                                                    // 	search: $.param({
                                                    // 		course_id: item.id,
                                                    // 		course_name: item.name,
                                                    // 	}),
                                                    // }}
                                                    className="btn"
                                                    onClick={() => item.openDrawer(item.item)}
                                                >
                                                    Compare
                                                </button>
                                                <button
                                                    className="btn"
                                                    onClick={() => clickApplyForm(item)}
                                                >
                                                    Apply
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div className="paginationWrap">
                                <Pagination
                                    current={pageState.currentPage}
                                    total={pageState.pageCount}
                                    onPageChange={handlePageClick}
                                    previousLabel={"Prev"}
                                    nextLabel={"Next"}
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* edit modal */}
            <div
                className="modal fade signinModal editModal"
                id="editModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                id="login_modal_close_btn"
                                aria-label="Close"
                                onClick={() => {
                                    localStorage.removeItem("EditModal");
                                }}
                            >
                                <img
                                    src={
                                        require("../../../../static/images/modal-times.svg").default
                                    }
                                    alt="modal-times"
                                />
                            </button>
                            <h3>Suggest an Edit or Post Review</h3>
                            <p>
                                Studied or Worked here? Share your Review or suggest editing
                            </p>
                            <form>
                                <div className="row">
                                    <div className="col-md">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                placeholder="First Name"
                                                className="form-control"
                                            />
                                            <img
                                                src={
                                                    require("../../../../static/images/forms/full_name.svg")
                                                        .default
                                                }
                                                alt="full_name"
                                                height=""
                                                width=""
                                                className="formIcon"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                placeholder="Last Name"
                                                className="form-control"
                                            />
                                            <img
                                                src={
                                                    require("../../../../static/images/forms/full_name.svg")
                                                        .default
                                                }
                                                alt="full_name"
                                                height=""
                                                width=""
                                                className="formIcon"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md">
                                        <div className="form-group">
                                            <Select
                                                name="filters"
                                                placeholder={"Phone Type"}
                                                isSearchable={false}
                                                theme={(theme) => ({
                                                    ...theme,
                                                })}
                                            />
                                            <img
                                                src={
                                                    require("../../../../static/images/forms/phone.svg")
                                                        .default
                                                }
                                                alt="phone"
                                                height=""
                                                width=""
                                                className="formIcon"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md">
                                        <div className="form-group">
                                            <PhoneInput
                                                autoFormat
                                                country={"pk"}
                                                placeholder="Enter the Number"
                                                enableAreaCodes="true"
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <input
                                        type="email"
                                        placeholder="Email Address"
                                        className="form-control"
                                    />
                                    <img
                                        src={
                                            require("../../../../static/images/forms/Email.svg").default
                                        }
                                        alt="Email"
                                        height=""
                                        width=""
                                        className="formIcon"
                                    />
                                </div>

                                <div className="form-group">
									<textarea
                                        placeholder="Comment"
                                        className="form-control"
                                    />
                                    <img
                                        src={
                                            require("../../../../static/images/forms/comment.svg")
                                                .default
                                        }
                                        alt="comment"
                                        height=""
                                        width=""
                                        className="formIcon"
                                    />
                                </div>
                            </form>
                            <h6>Please do not post: </h6>
                            <ul>
                                <li>Aggressive or discriminatory language</li>
                                <li>Profanities</li>
                                <li>Trade secrets or confidential information</li>
                            </ul>
                            <p>
                                We appreciate you for doing your part to Keep Times Course
                                Finder most trusted education portal
                            </p>
                            <div className="btnGroup">
                                <button className="btn defaultBtn">Add Review</button>
                                <button className="btn primaryBtn">Suggest Editing</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <Drawer
                    anchor={'bottom'}
                    open={openModal}
                    hideBackdrop={false}
                >
                    <div style={{textAlign: "center", padding: "20px", maxHeight: "500px", background: "#f5f5f5"}}>
                        <button
                            type="button"
                            className="close"
                            aria-label="Close"
                            onClick={toggleDrawer()}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <h5>You can compare upto 3 Universities</h5>
                        {drawerMessage !== "" ? <Alert severity={"error"}>{drawerMessage}</Alert> : null}
                        <div style={{
                            display: "flex",
                            justifyContent: "space-evenly", flexDirection: "row",
                            flexWrap: "wrap",
                            alignItems: "center",
                            padding: "50px"
                        }}>

                            {compareCourses.length ? compareCourses.map((item) => (
                                <div style={{
                                    width: "300px",
                                    background: "#E9DEFA"
                                }}>

                                    <button
                                        style={{display: "block"}}
                                    >
                                        <div style={{display: "block", padding: "8px"}}>
                                            <button
                                                className="close"
                                                style={{
                                                    float: "none",
                                                    display: "block",
                                                    color: "#ff0303",
                                                    width: "max-content",
                                                    marginLeft: "auto",
                                                }}
                                                onClick={(e) => removeSelectedCourse(e, item)}
                                            >
                                                <span className={"fa fa-trash"} aria-hidden="true"/>
                                            </button>
                                            <img
                                                style={{height: "100px", width: "100px"}}
                                                src={item.logo}
                                                alt={item.logo}
                                            />
                                        </div>
                                        <h6 style={{
                                            borderTop: "3px solid rebeccapurple",
                                            fontSize: "12px",
                                            margin: "0px",
                                            padding: "15px 10px",
                                            background: "#fff"
                                        }}>{item.name}</h6>
                                    </button>

                                </div>
                            )) : "No record Found"}
                            {selectedCourse.length > 1 ? <button style={{
                                fontSize: "14px",
                                background: "#ffb100",
                                color: "white",
                                padding: "14px 12px",
                                borderRadius: "4px",
                                border: "0px"
                            }} onClick={navigateToCompare}>Compare Course
                            </button> : null}
                        </div>
                    </div>
                </Drawer>
            </div>
        </div>
    );
}

export default memo(CourseListCardView)