import * as Actions from "../actions/search_course.actions";

/////Country
const courseCompareCountryInitialState = {
	data: [],
};

export const courseCompareCountry = (
	state = courseCompareCountryInitialState,
	action
) => {
	switch (action.type) {
		case Actions.COURSE_COMPARE_COUNTRIES_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.COURSE_COMPARE_COUNTRIES_FAIL: {
			return {
				...state,
				data: null,
			};
		}
		default: {
			return state;
		}
	}
};
///////country related disciplines
const courseCompareDisciplinesInitialState = {
	data: [],
};

export const courseCompareDisciplines = (
	state = courseCompareDisciplinesInitialState,
	action
) => {
	switch (action.type) {
		case Actions.COURSE_COMPARE_COUNTRY_DISCIPLINES_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.COURSE_COMPARE_COUNTRY_DISCIPLINES_FAIL: {
			return {
				...state,
				data: null,
			};
		}
		default: {
			return state;
		}
	}
};
////// country discipline related degree levels
const courseCompareDegreeLevelsInitialState = {
	data: [],
};

export const courseCompareDegreeLevels = (
	state = courseCompareDegreeLevelsInitialState,
	action
) => {
	switch (action.type) {
		case Actions.COURSE_COMPARE_COUNTRY_DISCIPLINE_DEGREELEVELS_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.COURSE_COMPARE_COUNTRY_DISCIPLINE_DEGREELEVELS_FAIL: {
			return {
				...state,
				data: null,
			};
		}
		default: {
			return state;
		}
	}
};
////////country -> disciplines-> degree level-> related institutes
const courseCompareInstitutesInitialState = {
	data: [],
};

export const courseCompareInstitutes = (
	state = courseCompareInstitutesInitialState,
	action
) => {
	switch (action.type) {
		case Actions.COURSE_COMPARE_RELATED_INSTITUTES_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.COURSE_COMPARE_RELATED_INSTITUTES_FAIL: {
			return {
				...state,
				data: null,
			};
		}
		default: {
			return state;
		}
	}
};
////////country -> disciplines-> degree level-> related specializations
const courseCompareSpecializationsInitialState = {
	data: [],
};

export const courseCompareSpecializations = (
	state = courseCompareSpecializationsInitialState,
	action
) => {
	switch (action.type) {
		case Actions.COURSE_COMPARE_RELATED_INSTITUTES_SPECIALIZATIONS_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.COURSE_COMPARE_RELATED_INSTITUTES_SPECIALIZATIONS_FAIL: {
			return {
				...state,
				data: null,
			};
		}
		default: {
			return state;
		}
	}
};
////// country -> disciplines-> degree level-> specializations related Courses
const courseCompareCoursesInitialState = {
	data: [],
};

export const courseCompareCourses = (
	state = courseCompareCoursesInitialState,
	action
) => {
	switch (action.type) {
		case Actions.COURSE_COMPARE_RELATED_COURSES_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.COURSE_COMPARE_RELATED_COURSES_FAIL: {
			return {
				...state,
				data: null,
			};
		}
		default: {
			return state;
		}
	}
};
