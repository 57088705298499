import * as Actions from "../actions/institute_group.actions";

const AllInstituteGroupsinitialState = {
	instituteGroupData:[],
	instituteGroupSuccess:false,
	instituteGroupError:"",
	instituteGroupLoading:false,
};

export const allInstituteGroups = (
	state = AllInstituteGroupsinitialState,
	{type,payload}
) => {
	 switch (type) {
        case Actions.GET_ALL_INSTITUTE_GROUPS:
            return {
                ...state,
                instituteGroupLoading: payload.loading,
                instituteGroupSuccess: payload.success,
                instituteGroupError: payload.error,
                instituteGroupData: payload.data,
            };
        case Actions.GET_ALL_INSTITUTE_GROUPS_LOADING:
            return {
                ...state,
                countryScholarshipLoading: payload.loading,
            };
        case Actions.GET_ALL_INSTITUTE_GROUPS_SUCCESS:
            return {
                ...state,
                countryScholarshipSuccess: payload.success,
            };
        case Actions.GET_ALL_INSTITUTE_GROUPS_FAIL:
            return {
                ...state,
                countryScholarshipError: payload.error,
            };
        default:
            return state;
    }
};

const InstituteGroupDetailinitialState = {
	data: [],
};

export const instituteGroupDetail = (
	state = InstituteGroupDetailinitialState,
	action
) => {
	switch (action.type) {
		case Actions.GET_INSTITUTE_GROUP_DETAIL_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.GET_INSTITUTE_GROUP_DETAIL_FAIL: {
			return {
				...state,
				data: null,
			};
		}
		default: {
			return state;
		}
	}
};

const InstituteGroupUniversitiesinitialState = {
	data: [],
};

export const instituteGroupUniversities = (
	state = InstituteGroupUniversitiesinitialState,
	action
) => {
	switch (action.type) {
		case Actions.GET_ALL_UNIVERSITIES_OF_INSITUTE_GROUP_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.GET_ALL_UNIVERSITIES_OF_INSITUTE_GROUP_FAIL: {
			return {
				...state,
				data: [],
			};
		}
		default: {
			return state;
		}
	}
};
const AllUniversitiesInstituteGroupinitialState = {
	data: [],
};

export const allUniversitiesOfInstitutesGroup = (
	state = AllUniversitiesInstituteGroupinitialState,
	action
) => {
	switch (action.type) {
		case Actions.GET_UNIVERSITIES_OF_ALL_INSTITUTE_GROUP_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.GET_UNIVERSITIES_OF_ALL_INSTITUTE_GROUP_FAIL: {
			return {
				...state,
				data: [],
			};
		}
		default: {
			return state;
		}
	}
};

const instituteGroupRelatedLocationsInitialState = {
	data: [],
};

export const instituteGroupRelatedLocations = (
	state = instituteGroupRelatedLocationsInitialState,
	action
) => {
	switch (action.type) {
		case Actions.GET_LOCATIONS_OF_INSITUTE_GROUP_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.GET_LOCATIONS_OF_INSITUTE_GROUP_FAIL: {
			return {
				...state,
				data: [],
			};
		}
		default: {
			return state;
		}
	}
};
const instituteGroupCoursesInitialState = {
	data: [],
};

export const allInstituteGroupCourses = (
	state = instituteGroupCoursesInitialState,
	action
) => {
	switch (action.type) {
		case Actions.GET_ALL_COURSES_OF_ALL_INSTITUTE_GROUP_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.GET_ALL_COURSES_OF_ALL_INSTITUTE_GROUP_FAIL: {
			return {
				...state,
				data: [],
			};
		}
		default: {
			return state;
		}
	}
};

const instituteGroupDisciplinesInitialState = {
	data: [],
};

export const allInstituteGroupDisciplines = (
	state = instituteGroupDisciplinesInitialState,
	action
) => {
	switch (action.type) {
		case Actions.GET_ALL_DISCIPLINES_OF_ALL_INSTITUTE_GROUP_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.GET_ALL_DISCIPLINES_OF_ALL_INSTITUTE_GROUP_FAIL: {
			return {
				...state,
				data: [],
			};
		}
		default: {
			return state;
		}
	}
};
const disciplinesByInstituteIdInitialState = {
	data: [],
};

export const allDisciplinesByInstituteGroup = (
	state = disciplinesByInstituteIdInitialState,
	action
) => {
	switch (action.type) {
		case Actions.GET_ALL_DISCIPLINES_OF_INSTITUTE_GROUP_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.GET_ALL_DISCIPLINES_OF_INSTITUTE_GROUP_FAIL: {
			return {
				...state,
				data: [],
			};
		}
		default: {
			return state;
		}
	}
};
const scholarshipsByInstituteIdInitialState = {
	data: [],
};

export const allScholarshipsByInstituteGroup = (
	state = scholarshipsByInstituteIdInitialState,
	action
) => {
	switch (action.type) {
		case Actions.GET_ALL_SCHOLARSHIPS_OF_INSTITUTE_GROUP_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.GET_ALL_SCHOLARSHIPS_OF_INSTITUTE_GROUP_FAIL: {
			return {
				...state,
				data: [],
			};
		}
		default: {
			return state;
		}
	}
};
