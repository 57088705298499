import React, { useEffect, useState } from "react";
import Select from "react-select";
import { http } from "../../services/apiService/apiService";
import { useDispatch, useSelector } from "react-redux";

export default function CourseSelectionFilter(props) {
	const dispatch = useDispatch();
	const [allCountriesList, setAllCountriesList] = useState([]);
	const [countryPlaceholder, setCountryPlaceholder] = useState(
		"Select your Desired Destination Country"
	);
	const [disciplinePlaceholder, setDisciplinePlaceholder] =
		useState("Select Discipline");
	const [degreeLevelPlaceholder, setDegreeLevelPlaceholder] = useState(
		"Select Degree-level"
	);
	const [specializationPlaceholder, setSpecializationPlaceholder] = useState(
		"Select Specialization"
	);
	const [institutePlaceholder, setInstitutePlaceholder] =
		useState("Select Institute");

	const [coursePlaceholder, setCoursePlaceholder] = useState("Select Course");
	const [dislist, setDislist] = useState([]);
	const [deglist, setDeglist] = useState([]);
	const [instiList, setInstilist] = useState([]);
	const [speclist, setSpeclist] = useState([]);
	const [courlist, setCourlist] = useState([]);
	const [countryId, setCountryId] = useState(null);
	const [disciplineId, setDisciplineId] = useState(null);
	const [degreeLevelId, setDegreeLevelId] = useState(null);
	const [instituteId, setInstituteId] = useState(null);

	const [specializationId, setSpecializationId] = useState(null);
	const [courseId, setCourseId] = useState(null);

	const discipline_get = (countryId) => {
		const url = "search-course/country/" + countryId + "/disciplines";
		const request = http.get(url);
		request.then((response) => {
			if (response.data) {
				var result = response.data.map(function (e) {
					return { label: e.name, value: e.id };
				});
				setDislist(result);
			}
		});
	};

	const degreeLevels_get = (disciplineId) => {
		const url =
			"search-course/country/" +
			countryId +
			"/discipline/" +
			disciplineId +
			"/degree_levels";
		const request = http.get(url);
		request.then((response) => {
			if (response.data) {
				var result = response.data.map(function (e) {
					return { label: e.name, value: e.id };
				});
				setDeglist(result);
			}
		});
	};

	const institutes_get = (degreeLevelId) => {
		const url =
			"search-course/country/" +
			countryId +
			"/discipline/" +
			disciplineId +
			"/degree_level/" +
			degreeLevelId +
			"/campuses";
		const request = http.get(url);
		request.then((response) => {
			if (response.data) {
				var result = response.data.map(function (item) {
					return {
						label: item?.institute?.institute_name + " (" + item.campus + ")",
						value: item.id,
					};
				});
				setInstilist(result);
			}
		});
	};
	const specialization_get = (instituteId) => {
		const url =
			"search-course/country/" +
			countryId +
			"/discipline/" +
			disciplineId +
			"/degree_level/" +
			degreeLevelId +
			"/campus/" +
			instituteId +
			"/specializations/";
		const request = http.get(url);
		request.then((response) => {
			if (response.data) {
				var result = response.data.map(function (e) {
					return { label: e.name, value: e.id };
				});
				setSpeclist(result);
			}
		});
	};

	const courses_get = (specializationId) => {
		const url =
			"search-course/country/" +
			countryId +
			"/discipline/" +
			disciplineId +
			"/degree_level/" +
			degreeLevelId +
			"/campus/" +
			instituteId +
			"/specialization/" +
			specializationId +
			"/courses";
		const request = http.get(url);
		request.then((response) => {
			if (response.data) {
				var result = response.data.map(function (e) {
					return { label: e.name, value: e.id };
				});
				setCourlist(result);
			}
		});
	};

	const handleChangeCountry = (id, name) => {
		setCountryId(id);
		// dataLoading();if (countryId) {
		discipline_get(id);
		setCountryPlaceholder(name);
	};

	const handleChangeDiscipline = (id, name) => {
		setDisciplineId(id);
		setDisciplinePlaceholder(name);
		if (countryId) {
			degreeLevels_get(id);
		}
	};

	const handleChangeDegreeLevel = (id, name) => {
		setDegreeLevelId(id);
		setDegreeLevelPlaceholder(name);
		if (countryId && disciplineId) {
			institutes_get(id);
		}
	};
	const handleChangeInstitute = (id, name) => {
		setInstituteId(id);
		setInstitutePlaceholder(name);
		if (countryId && disciplineId && degreeLevelId) {
			specialization_get(id);
		}
	};

	const handleChangeSpecialization = (id, name) => {
		setSpecializationId(id);
		setSpecializationPlaceholder(name);
		if (countryId && disciplineId && degreeLevelId && instituteId) {
			courses_get(id);
		}
	};

	const handleChangeCourse = (id, name) => {
		setCourseId(id);
		var filtered = courlist.filter(function (obj) {
			return obj.value === id;
		});
		props.filteredCourse(filtered);
		setCoursePlaceholder(name);
	};

	useEffect(() => {
		const url = "search-course/countries";
		const request = http.get(url);
		request.then((response) => {
			if (response.data) {
				var result = response.data.map(function (e) {
					return { label: e.name, value: e.id };
				});
				setAllCountriesList(result);
			}
		});
	}, [dispatch]);

	return (
		<>
			<div className="row">
				<div className="col-md">
					{allCountriesList ? (
						<div className="form-group">
							<Select
								isClearable="true"
								options={allCountriesList}
								value={countryPlaceholder}
								onChange={(e) => handleChangeCountry(e.value, e.label)}
								placeholder={countryPlaceholder}
							/>
							<img
								src={
									require("../../../static/images/forms/Nationality.svg")
										.default
								}
								alt="Nationality"
								height=""
								width=""
								className="formIcon"
							/>
						</div>
					) : (
						""
					)}
					{true ? (
						<div className="form-group">
							<Select
								isClearable="true"
								options={dislist}
								isDisabled={dislist?.length > 0 ? false : true}
								onChange={(e) => handleChangeDiscipline(e.value, e.label)}
								placeholder={disciplinePlaceholder}
								value={disciplinePlaceholder}
							/>
							<img
								src={
									require("../../../static/images/forms/Discipline.svg").default
								}
								alt="Discipline"
								height=""
								width=""
								className="formIcon"
							/>
						</div>
					) : (
						""
					)}
					{true ? (
						<div className="form-group">
							<Select
								isClearable="true"
								options={deglist}
								isDisabled={deglist?.length > 0 ? false : true}
								onChange={(e) => handleChangeDegreeLevel(e.value, e.label)}
								placeholder={degreeLevelPlaceholder}
								value={degreeLevelPlaceholder}
							/>
							<img
								src={
									require("../../../static/images/forms/study_level.svg")
										.default
								}
								alt="study_level"
								height=""
								width=""
								className="formIcon"
							/>
						</div>
					) : (
						""
					)}
					{true ? (
						<div className="form-group">
							<Select
								isClearable="true"
								options={instiList}
								isDisabled={instiList?.length > 0 ? false : true}
								onChange={(e) => handleChangeInstitute(e.value, e.label)}
								placeholder={institutePlaceholder}
								value={institutePlaceholder}
							/>
							<img
								src={
									require("../../../static/images/forms/study_level.svg")
										.default
								}
								alt="study_level"
								height=""
								width=""
								className="formIcon"
							/>
						</div>
					) : (
						""
					)}
					{true ? (
						<div className="form-group">
							<Select
								isClearable="true"
								options={speclist}
								isDisabled={speclist?.length > 0 ? false : true}
								onChange={(e) => handleChangeSpecialization(e.value, e.label)}
								placeholder={specializationPlaceholder}
								value={specializationPlaceholder}
							/>
							<img
								src={
									require("../../../static/images/forms/Specialization.svg")
										.default
								}
								alt="Specialization"
								height=""
								width=""
								className="formIcon"
							/>
						</div>
					) : (
						""
					)}
					{true ? (
						<div className="form-group">
							<Select
								isClearable="true"
								options={courlist}
								isDisabled={courlist?.length > 0 ? false : true}
								onChange={(e) => handleChangeCourse(e.value, e.label)}
								placeholder={coursePlaceholder}
								value={coursePlaceholder}
							/>
							<img
								src={require("../../../static/images/forms/course.svg").default}
								alt="course"
								height=""
								width=""
								className="formIcon"
							/>
						</div>
					) : (
						""
					)}
				</div>
			</div>
		</>
	);
}
