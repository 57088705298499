import CustomButton from "./CustomButton";
import FilterItem from "./FilterItem";
import LoadingSpinner from "./loader/loader";
import CheckboxList from "./loaders/checklist";

const CustomFilter = ({
    setOpenModal,
    isBlurred,
    title,
    data,
    searchPlaceholder,
    checkedItems,
    handleChangeItem,
    handleSearchChange,
    isLoading,
    hasSearch,
    isOpen,
    setIsOpen,
    onScroll,
    listInnerRef,
    nextPage,
    nextLoader,
    filter,
    setQuery,
    filtered,
    isFetchedAll,
    onApply
}) => {
    
    return (
        <div className="filterBox">
            <h6
                style={{ cursor: "pointer" }}
                onClick={() => setIsOpen((isOpen) => !isOpen)}
                className={data ? "filterOpen" : ""}
            >
                {title}
                {isOpen ? (
                    <i className="fa fa-angle-down"></i>
                ) : (
                    <i className="fa fa-angle-right"></i>
                )}
            </h6>
            {isOpen && (
                <div className="show">
                    {!nextLoader && isLoading ?
                        <CheckboxList /> :
                        <>
                            {hasSearch &&
                                <div className="filterSearchWrap">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={searchPlaceholder}
                                        onChange={!isBlurred?(e) => setQuery(e.target.value):()=>setOpenModal(true)}
                                    />
                                    <i className="fa fa-search"></i>
                                </div>
                            }
                            <div
                                className="filterBoxScroll"
                                id={`scrollableDiv${title}`}
                                onScroll={() => onScroll()}
                                ref={listInnerRef}
                                style={{ height: filter === "institutes" ? '300px' : 'auto', overflow: "auto" }}
                            >
                                {filtered ? (
                                    <div className="filterItemWrap">
                                        {filtered?.map((item, i) => (
                                            <FilterItem
                                                key={i}
                                                item={item}
                                                isChecked={checkedItems[item.id] || false}
                                                onChange={!isBlurred?handleChangeItem:()=>setOpenModal(true)}
                                            />
                                        ))}
                                    </div>
                                ) : (
                                    <div className="filterItemWrap">
                                        {data.length > 0 && data?.map((item, i) => (
                                            <FilterItem
                                                key={i}
                                                item={item}
                                                isChecked={checkedItems[item.id] }
                                                onChange={!isBlurred?handleChangeItem:()=>setOpenModal(true)}
                                            />
                                        ))}
                                    </div>
                                )}

                                {!isFetchedAll && nextLoader === true ?

                                    <LoadingSpinner height='10px' />

                                    :
                                    filter !== "instituteType" &&
                                    <p style={{ textAlign: "center" }}>
                                        <b>Yay! You have seen it all</b>
                                    </p>
                                }
                            </div>
                            <div className="text-center pb-2 pt-2">
                                <CustomButton
                                    onClick={!isBlurred?onApply:()=>setOpenModal(true)}
                                    buttonText="Apply Filter"
                                />
                            </div>
                        </>
                    }
                </div>
            )}
        </div>
    )
}

export default CustomFilter;
