import React, { useEffect, useRef, useState } from "react";
import CountriesIndividual from "../filters_individuals/Countries";
import DegreeLevelsIndividual from "../filters_individuals/DegreeLevels";
import IntakesIndividual from "../filters_individuals/Intakes";
import DisciplinesIndividual from "../filters_individuals/Disciplines";
import SpecializationIndividual from "../filters_individuals/Specializations";
import InstituteTypesIndividual from "../filters_individuals/InstituteTypes";
import InstitutesIndividual from "../filters_individuals/Institutes";
import DurationAndYearsIndividual from "../filters_individuals/DurationYears";
import FeeRangeIndividual from "../filters_individuals/FeeRange";
import CounterSectionIndividual from "../filters_individuals/CounterSection";
import CourseRequestInfoForm from "../forms/course_request_info_form";
import CurrentLocation from "../map/currentLocation";
import Select from "react-select";
import LocationSearchInput from "../map/autoloaction";
import CourseApplyForm from "../forms/apply_form";
import { useDispatch, useSelector } from "react-redux";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import $ from "jquery";
import * as Actions from "../../apis/store/actions";
import { http } from "../../services/apiService/apiService";
import _ from "lodash";
// import FeeMax from "../map/maxfee";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookMessengerShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import {
  getQueryString,
  arrayMergeStateCity,
} from "../../common/getQueryString";

let selectedLocationModal = {
  latitude: 0,
  longitude: 0,
  distance: 0,
  type: "",
};
let locationModalCheck = false;
let locationModalCheck2 = false;
let selectedLocations = { countries: [], states: [], cities: [] };
let selectedDegreeLevels = { types: [], levels: [] };
let selectedDisciplines = [];
let selectedRegionsList = [];
let selectedSpecializations = [];
let selectedInstitutes = [];
let selectedInstituteTypes = [];
let selectedIntakes = [];
let selectedCourse = [];
let selectedCampuses = [];
let selectedPopularity = [];
let sortOption;
let dura1 = 0;
let dura2 = 5;
let feeStart = 0;
// let feeEnd = { FeeMax };
let feeEnd = 5000;
let advanceFilters;
let main;
let filters;
// let selectedCourse = [];
let location_address = "";
let location_latitude = 0;
let location_longitude = 0;
let location_distance = 0;
let pageNumber = 1;
let filtersCurrency = 0;
let cityState = false;
let statesState = false;
let countryState = false;
let degreeLevelState = false;
let degreeTypesState = false;
let feeState = false;
let view = "grid";

function CoursesFilters(props) {
  let { onClickCourses } = props;
  let mainURl = window.location.pathname;
  mainURl = mainURl.split("?");


  const dispatch = useDispatch();
  const location = useLocation();
  const paramsSlug = useParams();
  const history = useHistory();
  const params = Object.fromEntries(new URLSearchParams(location.search));

  const AllCurrencies = useSelector(({ apis }) => apis.currencies.data);
  const allMarketingCard = useSelector(
    ({ apis }) => apis.allMarketingCards.data
  );

  const [courses, setCourses] = useState([]);
  const [coursesfilter, setCoursesfilter] = useState([]);
  const [durationMarks, setDurationMarks] = useState([
    { value: 0, label: "0" },
    { value: 5, label: "5+" },
  ]);
  const [feeLowerRange, setFeeLowerRange] = useState(feeStart);
  const [feeUpperRange, setFeeUpperRange] = useState(feeEnd);
  const [specializationSeacrh, setSpecializationSeacrh] = useState("");
  const [latitude, setLatitude] = useState(() => {
    if (location_latitude !== 0) {
      return location_latitude;
    } else {
      return null;
    }
  });
  const [longitude, setLongitude] = useState(() => {
    if (location_longitude !== 0) {
      return location_longitude;
    } else {
      return null;
    }
  });
  const [address, setAddress] = useState(null);
  const [autoAddress, setAutoAddress] = useState(null);
  const [autoDistance, setAutoDistance] = useState(() => {
    if (location_distance !== 0) {
      return { value: location_distance, label: location_distance };
    } else {
      return null;
    }
  });
  const [autoDistanceError, setAutoDistanceError] = useState("");
  const [autoLongitude, setAutoLongitude] = useState(() => {
    if (location_longitude !== 0) {
      return location_longitude;
    } else {
      return null;
    }
  });
  const [autoLatitude, setAutoLatitude] = useState(() => {
    if (location_latitude !== 0) {
      return location_latitude;
    } else {
      return null;
    }
  });

  const [distance, setDistance] = useState(() => {
    if (location_distance !== 0) {
      return { value: location_distance, label: location_distance };
    } else {
      return null;
    }
  });

  const [currencies, setCurrencies] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState(33);
  const locations_types = ["current_location", "any"];
  const [distanceError, setDistanceError] = useState("");
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [locationInput, setLocationInput] = useState("");
  const [sortOptions, setSortOptions] = useState([
    {
      // value: 7,
      label: "Reset",
      selected: false,
      image: require("../../../static/images/sort-icons/duration.svg").default,
      imageR: "",
    },
    {
      value: 1,
      label: "Fee Low to High",
      selected: false,
      //image: require("../../../static/images/sort-icons/fee low to high.svg")
      image: require("../../../static/images/sort-icons/Arrow up.svg").default,
      icon: "",
      imageR: "",
    },

    {
      value: 2,
      label: "Fee High to Low",
      selected: false,
      // image: require("../../../static/images/sort-icons/fee high to low.svg")
      image: require("../../../static/images/sort-icons/Arrow down.svg")
        .default,
      icon: "",
      imageR: "",
    },
    {
      value: 3,
      label: "Duration",
      selected: false,
      image: require("../../../static/images/sort-icons/duration.svg").default,
      imageR: require("../../../static/images/sort-icons/Arrow up.svg").default,
      icon: require("../../../static/images/sort-icons/Arrow up.svg").default,
    },
    {
      value: 4,
      label: "Duration",
      selected: false,
      image: require("../../../static/images/sort-icons/duration.svg").default,
      imageR: require("../../../static/images/sort-icons/Arrow down.svg")
        .default,
      icon: require("../../../static/images/sort-icons/Arrow down.svg").default,
    },
    {
      value: 5,
      label: "QS Ranking",
      selected: false,
      // image: require("../../../static/images/sort-icons/fee low to high.svg")
      image: require("../../../static/images/sort-icons/Arrow down.svg")
        .default,
      icon: "",
      imageR: "",
    },
    // {
    //   value: 6,
    //   label: 'QS Ranking',
    //   selected: false,
    //   // image: require("../../../static/images/sort-icons/fee high to low.svg")
    //   image: require('../../../static/images/sort-icons/Arrow up.svg').default,
    //   icon: '',
    // },

    // {
    //   // value: 7,
    //   label: 'Reset',
    //   selected: false,
    //   image: require('../../../static/images/sort-icons/duration.svg').default,
    // },
  ]);
  const [pageState, setPageState] = useState({
    offset: 0,
    perPage: 29,
    currentPage: 1,
    pageCount: 0,
  });
  const [campus, setCampuses] = useState([]);

  const [countries, setCountries] = useState([]);
  const [selectedCountry, setselectedCountry] = useState([]);
  const [disciplines, setDisciplines] = useState([]);
  const [filteredDisciplines, setFilteredDisciplines] = useState([]);
  const [filteredCountries, setFilteredCountries] = useState([]);
  const [filteredCountriesValue, setFilteredCountriesValue] = useState("");
  const [degreeLevels, setDegreeLevels] = useState([]);
  const [filteredDegreeLevels, setFilteredDegreeLevels] = useState([]);
  const [specializations, setSpecializations] = useState([]);
  const [filteredSpecializations, setFilteredSpecializations] = useState([]);
  const [institutes, setInstitutes] = useState([]);
  const [filteredInstitutes, setFilteredInstitutes] = useState([]);
  const [intakes, setIntakes] = useState([]);
  const [selectedInstituteType, setSelectedInstituteType] = useState([]);
  const [durationState, setDurationState] = useState(false);
  const [courseInput, setCourseInput] = useState();
  const [courseInputError, setCourseInputError] = useState(false);
  const [durationSliderValue, setDurationSliderValue] = useState([
    dura1,
    dura2,
  ]);
  const [showCounter, setShowCounter] = useState(
    JSON.parse(localStorage.getItem("Advance"))
  );
  const [toggleChecked, setToggleChecked] = useState(() => {
    if (params?.view === "list") {
      return true;
    } else {
      return false;
    }
  });
  const [showView, setView] = useState(() => {
    if (params?.view) {
      return params?.view;
    } else {
      return "grid";
    }
  });
  const [courseLoader, setCourseLoader] = useState(false);
  const [coursesCount, setCoursesCount] = useState(0);
  const distanceOption = [
    { value: 10, label: "10 Km" },
    { value: 20, label: "20 Km" },
    { value: 30, label: "30 Km" },
    { value: 40, label: "40 Km" },
    { value: 50, label: "50 Km" },
    { value: 100, label: "100 Km" },
    { value: 200, label: "200 Km" },
    { value: 300, label: "300 Km" },
    { value: 400, label: "400 Km" },
    { value: 500, label: "500 km" },
  ];
  const customStyles = {
    option: (provided) => ({
      ...provided,
      color: "black",
    }),
    control: (provided) => ({
      ...provided,
      color: "black",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "black",
    }),
  };
  const [marketingCardsList, setMarketingCardsList] = useState(
    allMarketingCard.results
  );
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // const [countriesLoader, setCountriesLoader] = useState(true);
  // const [degreeLevelLoader, setDegreeLevelLoader] = useState(true);
  const [disciplineLoader, setDisciplineLoader] = useState(false);
  const [specializationLoader, setSpecializationLoader] = useState(true);
  const [specializationNextDataLoader, setSpecializationNextDataLoader] =
    useState(false);
  const [instituteLoader, setInstituteLoader] = useState(true);
  const [intakeLoader, setIntakeLoader] = useState(true);
  const [requestInfoCourse, setRequestInfoCourse] = useState(null);
  const [applyCourse, setApplyCourse] = useState(null);
  const [page, setPage] = useState(null);
  const [pagespecialization, setPageSpecialization] = useState(null);
  const [pagespecializationCount, setPageSpecializationCount] = useState(null);
  const [institutePage, setInstitutePage] = useState(null);
  const [institutePageCount, setInstitutePageCount] = useState(null);
  const [selectedSortOption, setSelectedSortOption] = useState();
  const [universityCheck, setUniversityCheck] = useState(false);
  const [collegeCheck, setCollegeCheck] = useState(false);
  const [popularity, setPopularity] = useState(() => {
    if (localStorage.getItem("popularity") === "true") {
      return true;
    } else {
      return false;
    }
  });
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [confirmDialogHeading, setConfirmDialogHeading] = useState("");
  const [confirmDialogContent, setConfirmDialogContent] = useState("");
  const [locationType, setLocationType] = useState(null);
  const [specHasMore, setSpecHasMore] = useState(true);
  const [specHasMoreSpecialization, setSpecHasMoreSpecialization] =
    useState(true);
  const [institutesHasMore, setInstituteHasMore] = useState(true);

  useEffect(() => {
    if (onClickCourses.length) {
      locationCheck();
      pushParamsToUrl();
    }
  }, [onClickCourses]);
  useEffect(() => {
    scrollUpToTop();
  }, []);
  useEffect(() => {
    setTimeout(() => {
      if (parseInt(localStorage.getItem("userStay")) >= 1) {
        $("#login").modal({
          backdrop: "static",
          keyboard: false,
          show: true,
        });
      }
    }, 5000);
    if (params.discip) {
      selectedCourse = [];
      emptyAllStates();
      let param = getUrlParams();

      setCourseLoader(true);
      // setIntakeLoader(true);
      // setDegreeLevelLoader(true);
      setDisciplineLoader(true);
      setSpecializationLoader(true);
      setInstituteLoader(true);
      // setCountriesLoader(true);
      dispatch(Actions.getAllMarketingCard()).then((result) => {
        dispatch(Actions.getAllCurrencies()).then((response) => {
          if (response.payload) {
            setCurrencies(response.payload);
            coursesGet(
              param,
              pageNumber,
              response.payload,
              result.payload.results
            );
            // countriesGet(param);
            // degreeLevelGet(param);
            intakesGet(param);
            disciplineGet(param);
            specializationGet(param);
            institutesGet(param);
          }
        });
      });
    } else {
      selectedCourse = [];
      emptyAllStates();
      let paramForFilters = getUrlParams();
      setCourseLoader(true);
      filtersCalling(paramForFilters);
    }
  }, [onClickCourses, dispatch]);
  useEffect(() => {
    if (allMarketingCard) {
      setMarketingCardsList(allMarketingCard.results);
    }
    if (AllCurrencies.length) {
      setCurrencies(AllCurrencies);
    }
  }, [allMarketingCard, AllCurrencies]);
  const [selected, setSelected] = useState(false);

  function copyToClickBoard() {
    navigator.clipboard.writeText(urlValue);
    if (navigator.clipboard.writeText(urlValue)) {
      setSelected(true);
    }
  }

  const shareModal = () => {
    $("#sharePageModal").show();
    setSelected(false);
    setUrlSpinner(true);
    generateShareLink();
  };

  function mobileFilters() {
    $(".filterNavBtn").on("click", function () {
      $(".filterNav").addClass("show");
    });
    $(".closeFilterNav").on("click", function () {
      $(".filterNav").removeClass("show");
    });
    $(".applyFiltBtn").on("click", function () {
      $(".filterNav").removeClass("show");
    });
  }

  const emptyAllStates = () => {
    setPopularity(false);
    pageNumber = 1;
    filtersCurrency = 0;
    feeState = false;
    sortOption = 0;
    setSelectedCurrency([]);
    setPageState({
      offset: 0,
      currentPage: 1,
      perPage: 29,
      pageCount: 0,
    });
    locationModalCheck = false;
    locationModalCheck2 = false;
    selectedLocations = { countries: [], states: [], cities: [] };
    selectedDegreeLevels = { types: [], levels: [] };
    setselectedCountry([]);
    setLongitude(null);
    setLatitude(null);
    setAddress(null);
    setDistance(null);
    setAutoDistance(null);
    setAutoAddress(null);
    setAutoLatitude(null);
    setAutoLongitude(null);
    localStorage.removeItem("disciplines");
    localStorage.removeItem("specialization");
    localStorage.removeItem("popularity");
    selectedDisciplines = [];
    selectedCampuses = [];

    selectedSpecializations = [];
    selectedInstitutes = [];
    selectedIntakes = [];
    selectedInstituteTypes = [];
    selectedPopularity = [];
    setFeeLowerRange(0);
    setFeeUpperRange(5000000);
    setDurationSliderValue([0, 5]);
    setDurationMarks([
      { value: 0, label: "0" },
      { value: 5, label: "5+" },
    ]);
    setCollegeCheck(false);
    setUniversityCheck(false);
  };
  const getUrlParams = () => {
    let course = [];
    selectedCourse = [];
    let advance = false;
    if (params.advance) {
      setShowCounter(true);
      main = false;
      advanceFilters = false;
      filters = false;
      course = "";
      advance = true;
    }
    if (params.main) {
      setShowCounter(false);
      advanceFilters = false;
      filters = false;
      main = true;
    }
    if (params.filters) {
      setShowCounter(false);
      filters = true;
      main = false;
      advance = false;
    }

    if (params.curn) {
      filtersCurrency = parseInt(params.curn);
      setSelectedCurrency(parseInt(params.curn));
    }
    if (params.sld === "curL") {
      locationModalCheck = true;
      selectedLocationModal = {
        latitude: parseFloat(params.lat),
        longitude: parseFloat(params.lng),
        distance: parseInt(params.dst),
        type: params.sld,
      };
      localStorage.setItem("urloc", JSON.stringify(selectedLocationModal));
    } else if (params.sld === "autL") {
      locationModalCheck2 = true;
      selectedLocationModal = {
        latitude: parseFloat(params.lat),
        longitude: parseFloat(params.lng),
        distance: parseInt(params.dst),
        type: params.sld,
      };
      localStorage.setItem("urloc", JSON.stringify(selectedLocationModal));
    }
    let specializationList = [];
    let disciplineList = [];
    let intakesList = [];
    let institutesList = [];
    let instituteTypeList = [];
    let degreeLevelsList = [];
    let degreeLevelsTypesList = [];
    let regionsList = [];
    let countryList = [];
    let statesList = [];
    let citiesList = [];
    let campusList = [];

    let gLocation = {};
    for (const [key, value] of Object.entries(params)) {
      if (key.includes("location")) {
        if (value === "country") {
          countryList = [...countryList, { slug: paramsSlug.location }];
        } else if (value === "state") {
          statesList = [...statesList, { slug: paramsSlug.location }];
        } else if (value === "region") {
          regionsList = [...regionsList, { slug: paramsSlug.location }];
        } else if (value === "city") {
          citiesList = [...citiesList, { slug: paramsSlug.location }];
        }
      }
      if (key.includes("degree")) {
        if (value === "levels") {
          degreeLevelsList = [
            ...degreeLevelsList,
            { slug: paramsSlug.qualification },
          ];
        } else if (value === "types") {
          degreeLevelsTypesList = [
            ...degreeLevelsTypesList,
            { slug: paramsSlug.qualification },
          ];
        }
      }
      if (key.includes("caid")) {
        campusList = assignValues(value, campusList, "slug");
      }
      if (campusList.length) {
        selectedCampuses = campusList;
        apiParams.campuses = campusList.map((item) => {
          return item.slug;
        });
      }

      if (key.includes("course")) {
        course = assignValues(value, course, "slug");
      }
      /// institutes
      if (key.includes("institutes")) {
        institutesList = assignValues(value, institutesList, "slug");
      }

      // specialization
      if (key.includes("specialization")) {
        specializationList = assignValues(value, specializationList, "slug");
      }
      ////Intakes
      if (key.includes("intakes")) {
        intakesList = assignValues(value, intakesList, "month");
      }
      //	  degree_levels
      if (key.includes("levels")) {
        degreeLevelsList = assignValues(value, degreeLevelsList, "slug");
      }
      //	  degree_level_types
      if (key.includes("types")) {
        degreeLevelsTypesList = assignValues(
          value,
          degreeLevelsTypesList,
          "slug"
        );
      }
      // regions
      if (key.includes("regions")) {
        regionsList = assignValues(value, regionsList, "slug");
      }
      // countries
      if (key.includes("country")) {
        countryList = assignValues(value, countryList, "slug");
      }
      // states
      if (key.includes("state")) {
        statesList = assignValues(value, statesList, "slug");
      }
      // cities
      if (key.includes("city")) {
        citiesList = assignValues(value, citiesList, "slug");
      }
      // discipline
      if (key.includes("discipline")) {
        disciplineList = assignValues(value, disciplineList, "slug");
      }
      //institute types
      if (key.includes("ityp")) {
        let instance = key.split("[");
        let keyValue = parseInt(instance[1].replace("]", ""));
        if (value === "COLLEGE") {
          setCollegeCheck(true);
        } else if (value === "UNIVERSITY") {
          setUniversityCheck(true);
        }
        instituteTypeList.push({ type: value });
      }
      if (params.sld === "curL" || params.sld === "autL") {
        location_distance = parseInt(params.dst);
        location_latitude = parseFloat(params.lat);
        location_longitude = parseFloat(params.lng);
        gLocation = {
          latitude: parseFloat(params.lat),
          longitude: parseFloat(params.lng),
          distance: parseInt(params.dst),
        };
      }
      //duration in years
      if (key.includes("durationStartYear")) {
        dura1 = parseFloat(value);
      }
      if (key.includes("durationEndYear")) {
        dura2 = parseFloat(value);
      }
      //fee range
      if (key.includes("feeFrom")) {
        feeStart = parseInt(value);
        setFeeLowerRange(parseInt(value));
      }
      if (key.includes("feeTo")) {
        feeEnd = parseInt(value);
        setFeeUpperRange(parseInt(value));
      }
      //page number
      if (key.includes("pg")) {
        pageNumber = parseInt(value);
      }

      ///popularity
      if (key.includes("popularity")) {
        selectedPopularity.push(value);
      }

      //sort option
      if (key.includes("sortOption")) {
        sortOption = parseInt(value);
        for (let i = 0; i < sortOptions.length; i++) {
          if (sortOptions[i].value === sortOption) {
            sortOptions[i].selected = true;
            setSelectedSortOption(sortOptions[i]);
          }
        }
      }

      //	distance location
      if (key.includes("sld")) {
        if (key.includes("dst")) {
          gLocation["distance"] = parseInt(value);
        }
        if (key.includes("lat")) {
          gLocation["latitude"] = parseFloat(value);
        }
        if (key.includes("lng")) {
          gLocation["longitude"] = parseFloat(value);
        }
      }
      // any location
    }
    let apiParams = { advance: advance };
    if (course) {
      course?.map((item) => {
        selectedCourse.push(item.slug.replaceAll("-", " "));
      });
      setCourseInput(selectedCourse);
      if (onClickCourses.length > 0) {
        selectedCourse = onClickCourses;
        apiParams.course = onClickCourses;
      } else {
        apiParams.course = selectedCourse;
      }
    }
    if (disciplineList.length) {
      selectedDisciplines = disciplineList;
      apiParams.disciplines = disciplineList.map((item) => {
        return item.slug;
      });
    }
    if (regionsList.length) {
      selectedRegionsList = regionsList;
      apiParams.regions = regionsList.map((item) => {
        return item.slug;
      });
    }
    if (intakesList.length) {
      selectedIntakes = intakesList;
      apiParams.intakes = intakesList.map((item) => {
        return item.month;
      });
    }
    if (institutesList.length) {
      selectedInstitutes = institutesList;
      apiParams.institutes = institutesList.map((item) => {
        return item.slug;
      });
    }
    if (feeStart <= feeEnd) {
      apiParams.fees = [feeStart, feeEnd];
    }
    if (campusList.length) {
      selectedCampuses = campusList;
      apiParams.campuses = campusList.map((item) => {
        return item.slug;
      });
    }
    if (dura1 <= dura2) {
      setDurationSliderValue([dura1, dura2]);
      apiParams.durations = [dura1, dura2];
      let firstValue = dura1;
      firstValue = firstValue.toString();
      let secondValue = dura2;
      if (secondValue === 5) {
        secondValue = "5+";
      } else {
        secondValue = secondValue.toString();
      }
      setDurationMarks([
        {
          value: dura1,
          label: firstValue,
        },
        {
          value: dura2,
          label: secondValue,
        },
      ]);
    }
    if (sortOption) {
      apiParams.order = sortOption;
    }
    if (selectedPopularity.length) {
      setPopularity(true);
      apiParams.panels = ["p1"];
    } else {
      setPopularity(false);
    }
    if (instituteTypeList.length) {
      selectedInstituteTypes = instituteTypeList;
      apiParams.institute_type = instituteTypeList.map((item) => {
        return item.type;
      });
    }
    if (specializationList.length) {
      selectedSpecializations = specializationList;
      apiParams.specializations = specializationList.map((item) => {
        return item.slug;
      });
    }
    if (degreeLevelsList.length) {
      selectedDegreeLevels.levels = degreeLevelsList.map((item) => {
        return item;
      });
      apiParams.degree_levels = degreeLevelsList.map((item) => {
        return item.slug;
      });
    }
    if (countryList.length) {
      selectedLocations.countries = countryList;
      apiParams.countries = countryList.map((item) => {
        return item.slug;
      });
    }
    if (statesList.length) {
      selectedLocations.states = statesList;
      apiParams.states = statesList.map((item) => {
        return item.slug;
      });
    }
    if (citiesList.length) {
      selectedLocations.cities = citiesList;
      apiParams.cities = citiesList.map((item) => {
        return item.slug;
      });
    }
    // apis params
    if (Object.keys(gLocation).length !== 0) {
      apiParams.google_location = gLocation;
    }
    if (degreeLevelsTypesList.length) {
      selectedDegreeLevels.types = degreeLevelsTypesList;
      apiParams.degree_levels_types = degreeLevelsTypesList.map((item) => {
        return item.slug;
      });
    }
    return apiParams;
  };

  function assignValues(value, selectedVariable, option) {
    selectedVariable = [];
    value.split(",")?.map((d) => {
      if (option) {
        let obj = {};
        obj[option] = d;
        selectedVariable.push(obj);
      } else {
        selectedVariable.push(d);
      }
    });
    return selectedVariable;
  }

  const handleDistanceSelectChange = (selectedOption) => {
    setDistance(selectedOption);
    setDistanceError("");
    location_distance = selectedOption.value;
  };
  const handleCurrentLocation = (location) => {
    setAddress(location.address);
    location_address = location.address;
    location_latitude = location.lat;
    location_longitude = location.lng;
    setLatitude(location.lat);
    setLongitude(location.lng);
  };

  const currentLocationSubmitHandler = (e) => {
    e.preventDefault();
    let value = parseInt(e.target.distance.value);
    let label = e.target.distance.value + " Km";
    if (!e.target.distance.value) {
      setDistanceError("distance error");
    } else {
      setDistance({ value: value, label: label });
    }
    let userCurrentLocation = JSON.parse(localStorage.getItem("urlocMain"));
    let location_instance = {
      type: locations_types[0],
      longitude: userCurrentLocation?.lng,
      latitude: userCurrentLocation?.lat,
      address: userCurrentLocation?.address,
      value: value,
      name: label,
    };
    setSelectedLocation([location_instance]);
    setLocationInput(address);
    setselectedCountry([]);
    selectedLocations = { countries: [], states: [], cities: [] };
    $("#currentLocation").modal("hide");
    countryState = false;
    statesState = false;
    cityState = false;
    locationModalCheck = true;
    pushParamsToUrl();
    let params = getFilterSearchParams();
    dispatch(Actions.getAllMarketingCard()).then((response) => {
      coursesGet(params, pageNumber, null, response.payload.results);
    });
  };
  // apis
  const byPageMarketingCard = (
    array,
    page_number = 1,
    count,
    page_size = 4
  ) => {
    let pageSlice = Array(count).fill(array).flat();
    return pageSlice.slice(
      (page_number - 1) * page_size,
      page_number * page_size
    );
  };
  const coursesGet = async (params, pageId, currencies, marketingCards) => {
    params = {
      ...params,
      location_search: localStorage.location_search
        ? localStorage.location_search
        : "",
    };
    let url = "course/course_search/";
    if (pageId) {
      url = url + "?page=" + pageId;
    }
    const request = await http.post(url, params);
    let index = 6;
    if (request.data) {
      setCoursesCount(request.data.count);

      const { results } = request.data;
      if (results?.length > 0) {
        let withMarketingCard = [...results];
        var marketingCards_new = byPageMarketingCard(
          marketingCards,
          pageId,
          request.data.total_pages
        );
        let obj = [];
        if (filtersCurrency > 0) {
          updateCoursesCurrency(request.data, currencies);
        } else {
          if (marketingCards) {
            if (withMarketingCard.length >= 21) {
              if (marketingCards_new.length < 4) {
                localStorage.removeItem("scncrd");
                marketingCards.sort((a, b) => a.priority - b.priority);
                marketingCards_new = marketingCards_new.concat(
                  ...marketingCards
                );
                obj = [];
              }
              let j = 0;
              for (let i = 0; i < marketingCards_new.length; i++) {
                if (i <= 3) {
                  withMarketingCard.splice(index, 0, marketingCards_new[i]);
                  obj.push({
                    id: marketingCards_new[i].id,
                    seen_status: true,
                  });
                }
                index = index + 7;
                j = j + 1;
              }
              // if (allSeenCards.length === marketingCards.length) {
              //     localStorage.removeItem('scncrd');
              //     obj = _.uniqBy(obj, 'id')
              //     localStorage.setItem('scncrd', JSON.stringify(obj));
              // } else {
              localStorage.removeItem("scncrd");
              obj = _.uniqBy(obj, "id");
              localStorage.setItem("scncrd", JSON.stringify(obj));
              // }
              setCourses(withMarketingCard);
              setCoursesfilter(withMarketingCard);
            } else {
              setCourses(request.data.results);
              setCoursesfilter(request.data.results);
            }
          }
          setCourseLoader(false);
        }
      } else {
        setCourses(results);
      }
      setCourseLoader(false);
      pageState.currentPage = pageId;
      pageState.pageCount = Math.ceil(request.data.count / pageState.perPage);
      pageState.offset = pageId * pageState.perPage;
      setPageState(pageState);
    }
    return request.data;
  };
  const [urlValue, setUrlValue] = useState("https://timescoursefinder.com/");
  const [urlSpinner, setUrlSpinner] = useState(true);
  const generateShareLink = async () => {
    let url = "short-url/";
    let trimmedString = window.location.href.split("?");
    let newParams = {
      uuid: "",
      payload: { params, key: trimmedString[0] },
    };
    const request = await http.post(url, newParams);
    if (request.data.uuid) {
      setUrlValue(
        `https://timescoursefinder.com/loading?uuid=${request.data.uuid}`
      );

      setUrlSpinner(false);
    }
  };
  // const all_countriesGet = async (countries) => {
  //   const url = "course/course_all_locations/";
  //   const request = await http.get(url);
  //   var resp = request.data;
  //   var results = [];
  //   for (let i = 0; i < selectedLocations?.countries.length; i++) {
  //     var country = selectedLocations.countries[i];
  //     var tempCountry = countries.find((c) => c.slug === country?.slug);
  //     if (!tempCountry && country?.slug !== "any") {
  //       let selectedCountryDetail = resp.find((c) => c.slug === country.slug);
  //       results.push(selectedCountryDetail);
  //     }
  //   }
  //   return results;
  // };
  // const countriesGet = async (params) => {
  //   params = {
  //     course: params.course,
  //     advance: params.advance,
  //     panels: params.panels,
  //   };
  //   const url = "course/course_related_locations/";
  //   // const url = "course/base_filters/";

  //   const request = await http.post(url, params);
  //   if (request.data) {

  //     //Countries
  //     setCountries(request.data);
  //     setFilteredCountries(request.data);

  //     //Degree level
  //     // setDegreeLevels(request.data.degree_levels);
  //     // setFilteredDegreeLevels(request.data.degree_levels);
  //     // setDegreeLevelLoader(false);
  //     // setCountriesLoader(false);
  //     if (selectedLocations.countries.length >= 1) {
  //       all_countriesGet(request.data).then((result) => {
  //         const results = request.data.concat(result);
  //         setCountries(results);
  //         setFilteredCountries(results);
  //         for (let i = 0; i < selectedLocations.countries.length; i++) {
  //           var country = results.find(
  //             (c) => c?.slug === selectedLocations.countries[i]?.slug
  //           );
  //           if (country) {
  //             for (let j = 0; j < country.states.length; j++) {
  //               if (
  //                 selectedLocations.states.find(
  //                   (s) => s.slug === country.states[j].slug
  //                 )
  //               ) {
  //               } else {
  //                 var state = country.states[j];
  //                 selectedLocations.states.push(state);
  //                 for (let k = 0; k < state.cities.length; k++) {
  //                   if (
  //                     selectedLocations.cities.find(
  //                       (ci) => ci.slug === state.cities[k].slug
  //                     )
  //                   ) {
  //                   } else {
  //                     selectedLocations.cities.push(state.cities[k]);
  //                   }
  //                 }
  //               }
  //             }
  //           }
  //         }
  //       });
  //     }
  //     let locations = request.data;
  //     let citiesData = [];
  //     let stateData = [];
  //     for (let a = 0; a < locations?.length; a++) {
  //       var country = locations[a];
  //       for (let i = 0; i < country.states.length; i++) {
  //         var state = country.states[i];
  //         for (let x = 0; x < selectedLocations.states.length; x++) {
  //           if (selectedLocations.states[x].slug === state.slug) {
  //             stateData.push(state);
  //           }
  //         }
  //         for (let j = 0; j < state.cities.length; j++) {
  //           var city = state.cities[j];
  //           for (let k = 0; k < selectedLocations.cities.length; k++) {
  //             if (selectedLocations.cities[k].slug === city.slug) {
  //               citiesData.push(city);
  //             }
  //           }
  //         }
  //       }
  //     }
  //     selectedLocations.cities = citiesData;
  //     selectedLocations.states = stateData;
  //     setSelectedLocation(selectedLocations);
  //     return request.data;
  //   }
  //   return request.data;
  // };
  // const degreeLevelGet = async (params) => {
  //   const url = "course/course_related_degree_levels/";
  //   const request = await http.post(url, params);
  //   // degreeLevelGetCount(request.data, params);
  //   if (request.data) {
  //     setDegreeLevels(request.data);
  //     setFilteredDegreeLevels(request.data);
  //     setDegreeLevelLoader(false);
  //     return request.data;
  //   }
  //   return request.data;
  // };

  const disciplineGet = async (params) => {
    const url = "course/base_filters/";
    const request = await http.post(url, params);
    // disciplineGetCount(request.data, params);
    if (request.data) {
      setDisciplines(request.data.disciplines);
      setDegreeLevels(request.data.degree_levels);
      setFilteredDegreeLevels(request.data.degree_levels);
      setCountries(request.data.locations);
      setFilteredCountries(request.data.locations);
      setDisciplineLoader(false);
      return request.data.disciplines;
    }
    return request.data.disciplines;
  };

  const specializationGet = (params, page) => {
    let url = "";
    let urlCount = "";
    if (page?.length) {
      url = pagespecialization;
      urlCount = pagespecializationCount;
    } else {
      setSpecializations([]);
      setFilteredSpecializations([]);
      url = `course/course_related_specializations_new/${specializationSeacrh ? `?search=${specializationSeacrh}` : ""
        }`;
      urlCount = `course/course_related_specialization_count/${specializationSeacrh ? `?search=${specializationSeacrh}` : ""
        }`;
    }
    // var request = {};
    let request = http.post(url, params);
    request
      ?.then((d) => {
        if (d.data) {
          let special =
            page && specializations?.length && d.data?.links?.next
              ? [...specializations, ...d?.data?.results]
              : d.data.results;
          const ids = special.map((o) => o.id);
          const filtered = special.filter(
            ({ id }, index) => !ids.includes(id, index + 1)
          );
          getSpecializationsCount(urlCount, d.data.results || filtered, params);
          setSpecializations(filtered);
          setFilteredSpecializations(filtered);
          // setPage(d.data.links.next)
          // setPageSpecializationCount(d.data?.links?.next)
          setPageSpecialization(d.data?.links?.next);
          // fetchDataForSpecializations(d.data.result, d.data.links.next)
          setSpecializationLoader(false);
          setSpecializationNextDataLoader(false);
        }
        return d.data;
      })
      .catch((err) => {
        console.log('err', err)
        if (err) {
          setSpecHasMoreSpecialization(false);
        }
      });
    return request;
  };
  const getSpecializationsCount = async (urlCount, data, params) => {
  };
  const institutesGet = async (params) => {
    const url = "course/course_related_institutes_new/";
    const request = await http.post(url, params);
    // institutesGetCount(request.data.results, params);
    if (request.data) {
      setFilteredInstitutes(request.data.results);
      setInstitutes(request.data.results);
      setInstitutePage(request.data?.links?.next);
      setInstituteLoader(false);
      return request.data.results;
    }
    return request.data.results;
  };

  const intakesGet = async (params) => {
    const url = "course/course_related_intakes/";
    const request = await http.post(url, params);
    // getIntakeCount(request.data, params);
    if (request.data) {
      setIntakes(request.data);
      setIntakeLoader(false);
    }
  };

  const handleInstituteType = (value, checked) => {
    filters = true;
    // filtersCurrency = 0;
    sortOption = 0;
    emptySomeFilters();
    // setSelectedCurrency([]);
    setSelectedSortOption(null);
    if (value === "UNIVERSITY" && checked === true) {
      selectedInstituteTypes.push({ type: value });
      setUniversityCheck(checked);
      setShowCounter(false);
    } else if (value === "COLLEGE" && checked === true) {
      selectedInstituteTypes.push({ type: value });
      setCollegeCheck(checked);
      setShowCounter(false);
    } else if (value === "UNIVERSITY" && checked === false) {
      selectedInstituteTypes = selectedInstituteTypes.filter(
        (item) => item.type !== value
      );
      setUniversityCheck(checked);
    } else if (value === "COLLEGE" && checked === false) {
      setCollegeCheck(checked);
      selectedInstituteTypes = selectedInstituteTypes.filter(
        (item) => item.type !== value
      );
    }
    instituteTypeDependentFilterLoading();
    onApplyFilters();
  };

  function scrollUpToTop() {
    $("html, body").animate({ scrollTop: 0 }, "slow");
  }

  function countryDependentFilterLoading() {
    const params = getFilterSearchParams();
    localStorage.removeItem("location_search");
    // setDegreeLevelLoader(true);
    // setDisciplineLoader(true);
    // setSpecializationLoader(true);
    // setInstituteLoader(true);
    // setIntakeLoader(true);
    // degreeLevelGet(params).then((dResp) => {
    //   intakesGet(params).then((res) => {
    //     disciplineGet(params).then((disResp) => {
    //       specializationGet(params).then((sepResp) => {
    //         institutesGet(params);
    //       });
    //     });
    //   });
    // });
  }

  function degreeLevelDependentFilterLoading() {
    const params = getFilterSearchParams();
    setDisciplineLoader(true);
    setSpecializationLoader(true);
    setInstituteLoader(true);
    setIntakeLoader(true);
    intakesGet(params).then((res) => {
      disciplineGet(params).then((disResp) => {
        specializationGet(params).then((sepResp) => {
          institutesGet(params);
        });
      });
    });
  }

  function disciplineDependentFilterLoading() {
    const params = getFilterSearchParams();
    setSpecializationLoader(true);
    setInstituteLoader(true);
    specializationGet(params).then(() => {
      institutesGet(params);
    });
  }

  function specializationDependentFilterLoading() {
    const params = getFilterSearchParams();
    // console.log(params, "debugg");
    // debugger;
    setInstituteLoader(true);
    institutesGet(params);
  }

  function pushParamsToUrl() {
    // CheckAllStates();
    let paramObj = {};
    let course = [];
    if (params.page) {
      paramObj.page = params.page;
    }
    if (onClickCourses.length > 0) {
      onClickCourses.map((item) => {
        course.push({ name: item.replaceAll(" ", "-") });
      });
    } else {
      selectedCourse.map((item) => {
        course.push({ name: item.replaceAll(" ", "-") });
      });
    }
    paramObj.caid = selectedCampuses.map((d) => d.slug);
    paramObj.course = course.map((d) => d.name.replaceAll(" ", "-"));
    if (advanceFilters && !course.length) {
      paramObj.advance = advanceFilters;
      main = false;
      filters = false;
    }
    if (filters) {
      paramObj.discip = filters;
      main = false;
      advanceFilters = false;
    }
    if (main) {
      paramObj.discip = main;
      advanceFilters = false;
      filters = false;
    }
    if (countryState) {
      paramObj.country = selectedLocations.countries.map((item) => item.slug);
    } else if (statesState) {
      let states = [];
      selectedLocations.states.map((item) => {
        states.push(item.slug);
      });
      paramObj.state = states;
    } else if (cityState) {
      let cities = [];
      selectedLocations.cities.map((item) => {
        cities.push(item.slug);
      });
      paramObj.city = cities;
    }
    if (filtersCurrency >= 0) {
      paramObj.currency = filtersCurrency;
      // paramObj.fes = feeLowerRange;
      // paramObj.fee = feeUpperRange;
    }
    if (feeState === true) {
      paramObj.feeFrom = feeLowerRange;
      paramObj.feeTo = feeUpperRange;
    }
    // else {
    let userLocation = JSON.parse(localStorage.getItem("urlocMain"));
    if (locationModalCheck) {
      if (params.dst || distance) {
        paramObj.lat = userLocation?.lat;
        paramObj.lng = userLocation?.lng;
        paramObj.dst = parseInt(params.dst)
          ? parseInt(params.dst)
          : distance.value;
        paramObj.sld = "curL";
      }
    }
    if (locationModalCheck2) {
      let userLocation2 = JSON.parse(localStorage.getItem("urloc"));
      if (params.dst || autoDistance) {
        paramObj.lat = userLocation2.latitude;
        paramObj.lng = userLocation2.longitude;
        paramObj.dst = parseInt(params.dst)
          ? parseInt(params.dst)
          : autoDistance.value;
        paramObj.sld = "autL";
      }
    }
    paramObj.levels = selectedDegreeLevels.levels.map((d) => d.slug);
    paramObj.types = selectedDegreeLevels.types.map((d) => d.slug);
    paramObj.discipline = selectedDisciplines.map((d) => d.slug);
    paramObj.intakes = selectedIntakes.map((d) => d.month);
    paramObj.institutes = selectedInstitutes.map((d) => d.slug);
    paramObj.specializations = selectedSpecializations.map((d) => d.slug);
    paramObj.instituteTypes = selectedInstituteTypes.map((d) => d.type);
    paramObj.popularity = selectedPopularity;
    if (durationState) {
      paramObj.durationStartYear = durationSliderValue[0];
      paramObj.durationEndYear = durationSliderValue[1];
    }
    if (pageNumber > 1) {
      paramObj.pg = pageNumber;
    }
    if (sortOption !== 0) {
      paramObj.sortOption = sortOption;
    }
    // const paramQuery = $.param(paramObj)
    const paramQuery = getQueryString(paramObj);
    if (paramsSlug?.qualification?.length) {
      history.push({
        pathname: `/course`,
        search: paramQuery,
      });
    } else {
      history.replace({
        pathname: `${mainURl[0]}`,
        search: paramQuery,
      });
    }
  }

  function intakesDependentFilterLoading() {
    const params = getFilterSearchParams();
    setDisciplineLoader(true);
    setSpecializationLoader(true);
    setInstituteLoader(true);
    disciplineGet(params).then(() => {
      specializationGet(params).then(() => {
        institutesGet(params);
      });
    });
  }

  function instituteTypeDependentFilterLoading() {
    setInstituteLoader(true);
    const params = getFilterSearchParams();
    institutesGet(params);
  }

  // set selected values of filters
  function updateSelectedDisciplines(obj, status) {
    filters = true;
    // filtersCurrency = 0;
    // setSelectedCurrency([]);
    setSelectedSortOption(null);
    pageState.currentPage = 1;
    pageNumber = 1;
    sortOption = 0;
    emptySomeFilters();
    setPageState(pageState);
    if (status) {
      selectedDisciplines.push({ slug: obj.slug });
    } else {
      selectedDisciplines = selectedDisciplines.filter(
        (item) => item.slug !== obj.slug
      );
    }
    // pushParamsToUrl();
    CheckAllStates();
    disciplineDependentFilterLoading();
    // onApplyFilters();
  }

  function updateSelectedCountries(obj, typeOld, status) {
    emptySomeFilters();
    filters = true;
    pageState.currentPage = 1;
    pageNumber = 1;

    let type =
      obj?.country_id && obj?.state_id
        ? "city"
        : obj?.country_id && !obj?.state_id
          ? "state"
          : "country";
    obj["type"] = type;
    let objFilter = { ...obj };
    delete objFilter?.cities;
    delete objFilter?.states;
    if (status) {
      const updatedCtry = [(prev) => [...prev, objFilter]]
      setselectedCountry(updatedCtry);
    } else {
      let filterSel = selectedCountry?.filter((c) => c?.slug !== obj?.slug);
      setselectedCountry(filterSel);
    }
    setPageState(pageState);
    // filtersCurrency = 0;
    // setSelectedCurrency([]);
    setSelectedSortOption(null);

    sortOption = 0;
    locationModalCheck = false;
    locationModalCheck2 = false;
    if (type === "country" || filteredCountriesValue?.length) {
      statesState = false;
      countryState = true;
      cityState = false;
      if (status) {
        selectedLocations?.countries?.push({ slug: obj.slug });
        for (let i = 0; i < obj?.states?.length; i++) {
          selectedLocations?.states?.push({
            slug: obj?.states[i]?.slug,
          });
          let cities = obj?.states[i]?.cities;
          for (let j = 0; j < cities?.length; j++) {
            selectedLocations?.cities.push({
              slug: cities[j]?.slug,
            });
          }
        }
      } else {
        selectedLocations.countries = selectedLocations.countries.filter(
          (item) => item.slug !== obj.slug
        );
        for (let i = 0; i < obj?.states?.length; i++) {
          selectedLocations.states = selectedLocations.states.filter(
            (item) => item.slug !== obj.states[i]["slug"]
          );
        }
        for (let i = 0; i < obj?.states?.length; i++) {
          for (let j = 0; j < obj.states[i]["cities"].length; j++) {
            selectedLocations.cities = selectedLocations.cities.filter(
              (item) => item.slug !== obj.states[i]["cities"][j]["slug"]
            );
          }
        }
      }
    }
    if (type === "state") {
      statesState = true;
      countryState = false;
      cityState = false;
      if (status) {
        selectedLocations.states.push({ slug: obj.slug });
        for (let i = 0; i < filteredCountries.length; i++) {
          if (
            filteredCountries[i]?.slug === obj.country_slug &&
            filteredCountries[i]?.states?.length ===
            selectedLocations.states?.length
          ) {
            selectedLocations?.countries?.push({
              slug: filteredCountries[i]?.slug,
            });
          }
        }
        for (let i = 0; i < obj.cities.length; i++) {
          selectedLocations.cities.push({
            slug: obj.cities[i].slug,
          });
        }
      } else {
        selectedLocations.countries = selectedLocations.countries.filter(
          (item) => item.slug !== obj.country_slug
        );
        selectedLocations.states = selectedLocations.states.filter(
          (item) => item.slug !== obj.slug
        );
        for (let i = 0; i < obj.cities.length; i++) {
          selectedLocations.cities = selectedLocations.cities.filter(
            (item) => item.slug !== obj.cities[i]["slug"]
          );
        }
      }
    } else if (type === "city") {
      statesState = false;
      countryState = false;
      cityState = true;
      if (status) {
        selectedLocations.cities.push({ slug: obj.slug });
      } else {
        selectedLocations.countries = selectedLocations.countries.filter(
          (item) => item.slug !== obj.country_slug
        );
        selectedLocations.states = selectedLocations.states.filter(
          (item) => item.slug !== obj.state_slug
        );
        selectedLocations.cities = selectedLocations.cities.filter(
          (item) => item.slug !== obj.slug
        );
      }
    }
  }
  const coutryApplyNow = (type) => {
    CheckAllStates();
    onApplyFilters();
    if (type === "country") {
      countryDependentFilterLoading();
    } else if (type === "special") {
      specializationDependentFilterLoading();
    } else if (type === "institutes") {
      countryDependentFilterLoading();
    }
  };
  function emptySomeFilters() {
    setSortOptions([
      {
        value: 1,
        label: "Fee Low to High",
        selected: false,
        // image: require("../../../static/images/sort-icons/fee low to high.svg")
        image: require("../../../static/images/sort-icons/Arrow up.svg")
          .default,
        icon: "",
        imageR: "",
      },
      {
        value: 2,
        label: "Fee High to Low",
        selected: false,
        // image: require("../../../static/images/sort-icons/fee high to low.svg")
        image: require("../../../static/images/sort-icons/Arrow down.svg")
          .default,
        icon: "",
        imageR: "",
      },
      {
        value: 3,
        label: "Duration",
        selected: false,
        image: require("../../../static/images/sort-icons/duration.svg")
          .default,
        imageR: require("../../../static/images/sort-icons/Arrow up.svg")
          .default,
        icon: require("../../../static/images/sort-icons/Arrow up.svg").default,
      },
      {
        value: 4,
        label: "Duration",
        selected: false,
        image: require("../../../static/images/sort-icons/duration.svg")
          .default,
        imageR: require("../../../static/images/sort-icons/Arrow down.svg")
          .default,
        icon: require("../../../static/images/sort-icons/Arrow down.svg")
          .default,
      },
      {
        value: 5,
        label: "QS Ranking",
        selected: false,
        image:
          // image: require("../../../static/images/sort-icons/fee low to high.svg")
          require("../../../static/images/sort-icons/Arrow up.svg").default,
        icon: "",
        imageR: "",
      },
      // {
      //   value: 6,
      //   label: 'QS Ranking',
      //   selected: false,
      //   // image: require("../../../static/images/sort-icons/fee high to low.svg")
      //   image: require('../../../static/images/sort-icons/Arrow down.svg')
      //     .default,
      //   icon: '',
      // },
    ]);
    // filtersCurrency = 0;
    // setSelectedCurrency([]);
  }
  function updateSelectedDegreeLevels(obj, type, status) {
    locationCheck();
    emptySomeFilters();
    // filtersCurrency = 0;
    // setSelectedCurrency([]);
    setSelectedSortOption(null);
    filters = true;
    pageState.currentPage = 1;
    pageNumber = 1;
    sortOption = 0;
    setPageState(pageState);
    if (type === "type") {
      degreeTypesState = true;
      degreeLevelState = false;
      if (status) {
        selectedDegreeLevels.types.push({ slug: obj.slug });
      } else {
        selectedDegreeLevels.types = selectedDegreeLevels.types.filter(
          (item) => item.slug !== obj.slug
        );
      }
    } else {
      degreeTypesState = false;
      degreeLevelState = true;
      if (status) {
        selectedDegreeLevels.levels.push({
          slug: obj.slug,
        });
      } else {
        selectedDegreeLevels.types = selectedDegreeLevels.types.filter(
          (item) => item.slug !== obj.slug
        );
        selectedDegreeLevels.levels = selectedDegreeLevels.levels.filter(
          (item) => item.slug !== obj.slug
        );
      }
    }
    // pushParamsToUrl();
    CheckAllStates();
    degreeLevelDependentFilterLoading();
    // onApplyFilters();
  }
  function updateSelectedSpecialization(obj, status) {
    locationCheck();
    setSelectedSortOption(null);
    filters = true;
    sortOption = 0;
    emptySomeFilters();
    pageState.currentPage = 1;
    pageNumber = 1;
    setPageState(pageState);
    if (status) {
      selectedSpecializations.push({ slug: obj.slug });
    } else {
      selectedSpecializations = selectedSpecializations.filter(
        (item) => item.slug !== obj.slug
      );
    }
  }

  function removeSelectedSpecialization(obj, status) {
    selectedSpecializations = selectedSpecializations.filter(
      (item) => item.id !== obj.id
    );
    setFilteredSpecializations([obj, ...filteredSpecializations]);
    countryDependentFilterLoading();
    onApplyFilters();
  }

  function updateSelectedInstitutes(obj, status) {
    locationCheck();
    // filtersCurrency = 0;
    // setSelectedCurrency([]);
    setSelectedSortOption(null);
    pageState.currentPage = 1;
    pageNumber = 1;
    sortOption = 0;
    emptySomeFilters();
    setPageState(pageState);
    filters = true;
    if (status) {
      selectedInstitutes.push({ slug: obj.slug });
    } else {
      selectedInstitutes = selectedInstitutes.filter(
        (item) => item.slug !== obj.slug
      );
    }
    // pushParamsToUrl();
  }

  function updateSelectedIntakes(obj, type, status) {
    locationCheck();
    // filtersCurrency = 0;
    // setSelectedCurrency([]);
    setSelectedSortOption(null);
    filters = true;
    sortOption = 0;
    emptySomeFilters();
    pageState.currentPage = 1;
    pageNumber = 1;
    setPageState(pageState);
    if (type === "group") {
      if (status) {
        for (let i = 0; i < obj.value.length; i++) {
          if (!selectedIntakes.includes(obj.value[i].month)) {
            selectedIntakes.push({ month: obj.value[i].month });
          }
        }
      } else {
        for (let i = 0; i < obj.value.length; i++) {
          selectedIntakes = selectedIntakes.filter(
            (item) => item.month !== obj.value[i].month
          );
        }
      }
    } else {
      if (status) {
        selectedIntakes.push({ month: obj.month });
      } else {
        selectedIntakes = selectedIntakes.filter(
          (item) => item.month !== obj.month
        );
      }
    }
    // pushParamsToUrl();
    CheckAllStates();
    intakesDependentFilterLoading();
    // onApplyFilters();
  }

  const handleAutoDistanceSelectChange = (selectedOption) => {
    setAutoDistance(selectedOption);
    setAutoDistanceError("");
  };
  const genericLocationSubmitHandler = (e) => {
    e.preventDefault();
    if (!e.target.value) {
      setAutoDistanceError("distance error");
    } else {
      let value = parseInt(e.target.autodistance.value);
      let label = e.target.autodistance.value + " Km";
      setAutoDistance({ value: value, label: label });
    }
    let userLocation = JSON.parse(localStorage.getItem("urloc"));
    let location_instance = {
      type: locations_types[1],
      longitude: userLocation?.longitude,
      latitude: userLocation?.latitude,
      address: autoAddress,
      distance: autoDistance.value ? autoDistance.value : parseInt(params.dst),
      name: autoDistance.label ? autoDistance.label : location_distance,
    };
    localStorage.setItem("urloc", JSON.stringify(location_instance));
    setSelectedLocation([location_instance]);
    setLocationInput(autoAddress);
    setselectedCountry([]);
    selectedLocations = { countries: [], states: [], cities: [] };
    $("#autoLocation").modal("hide");
    countryState = false;
    statesState = false;
    cityState = false;
    locationModalCheck2 = true;
    pushParamsToUrl();
    let params = getFilterSearchParams();
    dispatch(Actions.getAllMarketingCard()).then((response) => {
      coursesGet(params, null, null, response.payload.results);
    });
  };
  const filterSearch = (arr = [], value) => {
    var result = [];
    arr?.filter((d1) =>
      d1?.name?.toLowerCase()?.includes(value)
        ? result?.push(d1)
        : d1?.states?.some((d2) =>
          d2?.name?.toLowerCase()?.includes(value)
            ? result?.push(d1)
            : d2?.cities?.some((d) =>
              d?.name?.toLowerCase()?.includes(value)
                ? result?.push({
                  ...d1,
                  states: [{ ...d2, cities: [{ ...d }] }],
                })
                : null
            )
        )
    );
    return result;
  };
  //search in filters
  // let fuse = new Fuse(countries, options)
  const searchCountriesHandler = (e) => {
    let newArray = filterSearch(countries, e.target.value?.toLowerCase());
    // let newArray = fuse.search(e.target.value)
    setFilteredCountriesValue(e.target.value);
    if (e.target.value?.length > 0) {
      setFilteredCountries(newArray);
    } else {
      setFilteredCountries(countries);
    }
  };
  const searchDisciplinesHandler = (e) => {
    let searcjQery = e.target.value.toLowerCase(),
      data = disciplines.filter((el) => {
        let searchValue = el.name.toLowerCase();
        return searchValue.indexOf(searcjQery) !== -1;
      });
    setFilteredDisciplines(data);
  };
  const searchInstituteHandler = (e) => {
    let params = getFilterSearchParams();
    if (e.target.value) {
      http
        .post(
          `course/course_related_institutes_new/?search=${e.target.value}`,
          params
        )
        .then(async (res) => {
          setFilteredInstitutes(res.data.results);
          setInstitutePage(res.data?.links?.next);
          setInstitutes(res.data.results);
        });
    }
    if (e.target.value === "") {
      institutesGet(params);
    }
  };
  const searchSpecializationsHandler = (val) => {
    let params = getFilterSearchParams();
    // debugger;
    setSpecializationSeacrh(val);
    if (val.length) {
      http

        .post(`course/course_related_specializations_new/?search=${val}`, params)
        .then((res) => {
          setFilteredSpecializations(res.data.results);
          setSpecializations(res.data.results);
          setPageSpecialization(res.data.links.next);
        });

    } else {
      http.post(`course/course_related_specializations_new/`, params).then((res) => {
        setFilteredSpecializations(res.data.results);
        setSpecializations(res.data.results);
        setPageSpecialization(res.data.links.next);
      });
    }
  };
  const handlePopularityCheck = (value, check) => {
    locationCheck();
    // setSelectedCurrency([]);
    setSelectedSortOption(null);
    // filtersCurrency = 0;
    sortOption = 0;
    emptySomeFilters();
    pageNumber = 0;
    setPageState({
      offset: 0,
      currentPage: 1,
      perPage: 27,
      pageCount: 0,
    });
    if (check === true && value === "p1") {
      localStorage.setItem("popularity", true);
      setPopularity(check);
      selectedPopularity.push(value);
    } else if (check === false && value === "p1") {
      localStorage.removeItem("popularity");
      setPopularity(check);
      const index = selectedPopularity.indexOf("p1");
      if (index > -1) {
        selectedPopularity.splice(index, 1);
      }
    }
    CheckAllStates();
    const params = getFilterSearchParams();
    filtersCalling(params);
    pushParamsToUrl();
  };

  // get params
  function getFilterSearchParams() {
    let params = { course: selectedCourse };
    params.countries = selectedLocations.countries.map((item) => {
      return item.slug;
    });
    params.states = selectedLocations.states.map((item) => {
      return item.slug;
    });
    params.cities = selectedLocations.cities.map((item) => {
      return item.slug;
    });
    params.degree_levels_types = selectedDegreeLevels.types.map((item) => {
      return item.slug ? item.slug : item;
    });
    params.degree_levels = selectedDegreeLevels.levels.map((item) => {
      return item.slug;
    });
    params.disciplines = selectedDisciplines.map((item) => {
      return item.slug;
    });
    params.specializations = selectedSpecializations.map((item) => {
      return item.slug;
    });
    params.campuses = selectedCampuses.map((item) => {
      return item.slug;
    });
    params.institutes = selectedInstitutes.map((item) => {
      return item.slug;
    });
    params.intakes = selectedIntakes.map((item) => {
      return item.month;
    });
    params.panels = selectedPopularity;

    params.institute_type = selectedInstituteTypes.map((item) => {
      return item.type;
    });
    params.order = sortOption;
    if (locationModalCheck === true) {
      if (distance) {
        let userLocation = JSON.parse(localStorage.getItem("urlocMain"));
        params.google_location = {
          latitude: userLocation?.lat,
          longitude: userLocation?.lng,
          distance: distance.value ? distance.value : autoDistance.value,
        };
      }
      if (locationModalCheck2) {
        let userLocation = JSON.parse(localStorage.getItem("urloc"));
        params.google_location = {
          latitude: userLocation.latitude,
          longitude: userLocation.longitude,
          distance: userLocation.distance,
        };
      }
    }
    if (feeStart <= feeEnd) {
      params.fees = [feeStart, feeEnd];
    }
    if (dura1 <= dura2) {
      params.durations = [dura1, dura2];
    }
    return params;
  }

  function getSearchParams() {
    let params = {
      course: selectedCourse,
    };
    params.countries = selectedLocations.countries.map((item) => {
      return item.slug;
    });
    params.states = selectedLocations.states.map((item) => {
      return item.slug;
    });
    params.cities = selectedLocations.cities.map((item) => {
      return item.slug;
    });
    params.degree_levels = selectedDegreeLevels.levels.map((item) => {
      return item.slug;
    });
    params.degree_levels_types = selectedDegreeLevels.types.map((item) => {
      return item.slug ? item.slug : item;
    });
    params.disciplines = selectedDisciplines.map((item) => {
      return item.slug;
    });
    params.specializations = selectedSpecializations.map((item) => {
      return item.slug;
    });
    params.institutes = selectedInstitutes.map((item) => {
      return item.slug;
    });
    if (locationModalCheck || locationModalCheck2) {
      if (params.dst) {
        let userLocation = JSON.parse(localStorage.getItem("urloc"));
        params.google_location = {
          latitude: userLocation.latitude,
          longitude: userLocation.longitude,
          distance: params.dst ? params.dst : userLocation.distance,
        };
      }
    }
    if (dura1 <= dura2) {
      params.durations = [dura1, dura2];
    }
    params.panels = selectedPopularity;
    params.intakes = selectedIntakes.map((item) => {
      return item.month;
    });
    if (feeStart <= feeEnd) {
      params.fees = [feeStart, feeEnd];
    }
    if (selectedInstituteTypes !== []) {
      params.institute_type = selectedInstituteTypes.map((item) => {
        return item.type;
      });
    }
    if (sortOption) {
      params.order = sortOption;
    }
    return params;
  }

  // pagination
  function handlePageClick(page_number) {
    let selectedPage = page_number;
    pageNumber = page_number;
    locationCheck();
    const offset = selectedPage * pageState.perPage;
    pageState.currentPage = selectedPage;
    pageState.offset = offset;
    setPageState(pageState);
    setCourseLoader(true);
    let params = getFilterSearchParams();
    if (selectedCurrency != null) {
      let ConvertCurrency = currencies.find(
        (cr) => cr.id === parseInt(selectedCurrency)
      );
      if (ConvertCurrency) {
        let lowerRange = ConvertCurrency.value_to_pkr * params.fees[0];
        let upperRange = ConvertCurrency.value_to_pkr * params.fees[1];
        params.fees = [lowerRange, upperRange];
      }
    }
    onApplyFilters();
    scrollUp();
  }

  function scrollUp() {
    let viewDiv = document.getElementById("course");
    if (viewDiv) {
      window.scrollTo(0, viewDiv.offsetTop);
    }
  }

  // submission
  const onApplyFilters = () => {
    const params = getSearchParams();
    setCourseLoader(true);
    setShowCounter(false);
    dispatch(Actions.getAllMarketingCard()).then((response) => {
      coursesGet(params, pageNumber, AllCurrencies, response.payload.results);
    });

    pushParamsToUrl();
  };
  const restFilters = () => {
    advanceFilters = true;
    filtersCurrency = 0;
    setSelectedCurrency([]);
    const paramQuery = $.param({
      view: showView,
      advanced: true,
      page: params.page ? params.page : "course-list",
    });
    history.replace({
      pathname: `${mainURl[0]}`,
      search: paramQuery,
    });
    setShowCounter(true);
    setCourseInput("");
    locationModalCheck = false;
    locationModalCheck2 = false;
    dura1 = 0;
    dura2 = 5;
    feeStart = 0;
    sortOption = 0;
    feeEnd = 5000000;
    setPopularity(false);
    setselectedCountry([]);
    selectedLocations = { countries: [], states: [], cities: [] };
    selectedDegreeLevels = { types: [], levels: [] };
    setLongitude(null);
    setLatitude(null);
    setAddress(null);
    setAutoDistance(null);
    setDistance(null);
    setAutoAddress(null);
    setAutoLatitude(null);
    setAutoLongitude(null);
    setSelectedSortOption(null);
    setSortOptions([
      {
        value: 1,
        label: "Fee Low to High",
        selected: false,
        image: require("../../../static/images/sort-icons/fee low to high.svg")
          .default,
        icon: "",
        imageR: "",
      },
      {
        value: 2,
        label: "Fee High to Low",
        selected: false,
        image: require("../../../static/images/sort-icons/fee high to low.svg")
          .default,
        icon: "",
        imageR: "",
      },
      {
        value: 3,
        label: "Duration",
        selected: false,
        image: require("../../../static/images/sort-icons/duration.svg")
          .default,
        imageR: require("../../../static/images/sort-icons/Arrow up.svg")
          .default,
        icon: require("../../../static/images/sort-icons/Arrow up.svg").default,
      },
      {
        value: 4,
        label: "Duration",
        selected: false,
        image: require("../../../static/images/sort-icons/duration.svg")
          .default,
        imageR: require("../../../static/images/sort-icons/Arrow down.svg")
          .default,
        icon: require("../../../static/images/sort-icons/Arrow down.svg")
          .default,
      },
      {
        value: 5,
        label: "QS Ranking",
        selected: false,
        image: require("../../../static/images/sort-icons/fee low to high.svg")
          .default,
        icon: "",
        imageR: "",
      },
    ]);
    selectedDisciplines = [];
    selectedSpecializations = [];
    selectedInstitutes = [];
    selectedCourse = [];
    selectedIntakes = [];
    selectedInstituteTypes = [];
    setSelectedCurrency([]);
    setFeeLowerRange(0);
    setFeeUpperRange(5000000);
    setDurationSliderValue([0, 5]);
    setDurationMarks([
      { value: 0, label: "0" },
      { value: 5, label: "5+" },
    ]);
    setCollegeCheck(false);
    setUniversityCheck(false);
    setCourseLoader(true);
    setFilteredSpecializations([]);
    setFilteredInstitutes([]);
    setIntakes([]);
    let param = {};
    filtersCalling(param);
  };
  const onSubmit = (e) => {
    setCourseInputError(false);
    if (courseInput === "") {
      setCourseInputError(true);
    } else if (e.key === "Enter") {
      setCourseInputError(false);
      setShowCounter(false);
      selectedCourse = [courseInput];
      pageNumber = 1;
      selectedDisciplines = [];
      selectedSpecializations = [];
      selectedInstitutes = [];
      selectedIntakes = [];
      selectedInstituteTypes = [];
      setSelectedCurrency([]);
      setPopularity(false);
      dura1 = 0;
      dura2 = 5;
      feeStart = 0;
      feeState = false;
      feeEnd = 5000000;
      setFeeLowerRange(0);
      setFeeUpperRange(5000000);
      localStorage.removeItem("lower_range");
      localStorage.removeItem("upper_range");
      setDurationSliderValue([0, 5]);
      localStorage.removeItem("disciplines");
      localStorage.removeItem("specialization");
      setDurationMarks([
        { value: 0, label: "0" },
        { value: 5, label: "5+" },
      ]);
      setCollegeCheck(false);
      locationCheck();
      setUniversityCheck(false);
      setCourseLoader(true);
      pushParamsToUrl();
      let params = getFilterSearchParams();
      filtersCalling(params);
    }
  };
  const filtersCalling = (params) => {
    setCourseLoader(true);
    // setCountriesLoader(true);
    // setDegreeLevelLoader(true);
    // setIntakeLoader(true);
    setDisciplineLoader(true);
    setSpecializationLoader(true);
    setInstituteLoader(true);
    dispatch(Actions.getAllMarketingCard()).then((result) => {
      dispatch(Actions.getAllCurrencies()).then((response) => {
        if (response.payload) {
          setCurrencies(response.payload);
          coursesGet(
            params,
            pageNumber,
            response.payload,
            result.payload.results
          );
          // countriesGet(params);
          // degreeLevelGet(params);
          intakesGet(params);
          disciplineGet(params);
          specializationGet(params);
          institutesGet(params);
        }
      });
    });
  };
  const submitSearch = () => {
    if (courseInput === "") {
      setCourseInputError(true);
    } else {
      setShowCounter(false);
      setCourseInputError(false);
      selectedCourse = [courseInput];
      emptyAllStates();
      setCourseLoader(true);
      pushParamsToUrl();
      let params = getFilterSearchParams();
      filtersCalling(params);
    }
  };
  // handlers
  const inputHandleChange = (event) => {
    if (selectedCourse) {
      selectedCourse = [event.target.value];
    }
    setCourseInput(event.target.value);
  };

  const handleDurationSliderValue = (event, value) => {
    setDurationState(true);
    pageState.currentPage = 1;
    pageNumber = 1;
    setPageState(pageState);
    setDurationSliderValue(value);
    dura1 = value[0];
    dura2 = value[1];
    let firstValue = value[0];
    firstValue = firstValue.toString();
    let secondValue = value[1];
    if (secondValue === 5) {
      secondValue = "5+";
    } else {
      secondValue = secondValue.toString();
    }
    setDurationMarks([
      {
        value: value[0],
        label: firstValue,
      },
      {
        value: value[1],
        label: secondValue,
      },
    ]);
  };
  // sort
  const locationCheck = () => {
    if (selectedLocations.countries.length > 0) {
      countryState = true;
    } else if (selectedLocations.states.length > 0) {
      statesState = true;
    } else if (selectedLocations.cities.length > 0) {
      cityState = true;
    }
  };

  function clickSortOption(option, e) {
    filters = true;
    pageNumber = 1;
    locationCheck();
    pageState.currentPage = 1;
    setSelectedSortOption(option);
    let ConvertCurrency = currencies.find((cr) => cr.id === parseInt(option));

    let options = sortOptions;
    for (let i = 0; i < options.length; i++) {
      if (options[i].value === option.value) {
        options[i].selected = true;
      }
      if (selectedSortOption) {
        if (options[i].value === selectedSortOption.value) {
          options[i].selected = false;
        }
      }
    }
    setSortOptions(options);
    sortOption = option.value;
    onApplyFilters();
  }

  const checkView = (value) => {
    if (value === "list") {
      if (showView === "list") {
        setView("grid");
        view = "grid";
      } else {
        setView("list");
        view = "list";
      }
      setToggleChecked(true);
    } else if (value === "map") {
      setView("map");
      view = "map";
      setToggleChecked(true);
    } else {
      setToggleChecked(false);
    }
    locationCheck();
    pushParamsToUrl();
  };

  const updateCoursesCurrency = (courses, currencies) => {
    let ConvertCurrency = currencies.find(
      (cr) => cr.id === parseInt(filtersCurrency)
    );
    if (ConvertCurrency) {
      let results = [];
      for (let i = 0; i < courses.results.length; i++) {
        let tempObj = courses.results[i];
        let base_pkr = tempObj.base_fee;
        // let base_pkr = tempObj.course_fee[0]?.ceil_value
        if (tempObj?.course_fee?.length > 0) {
          // if (tempObj.base_fee === 0) {
          //     base_pkr = tempObj.course_fee[0].ceil_value;
          // } else {
          base_pkr = tempObj.base_fee;
          // base_pkr = tempObj.course_fee[0]?.ceil_value
          // }
          let feeTemp = tempObj.course_fee[0];
          feeTemp.converted_value = base_pkr / ConvertCurrency.value_to_pkr;
          feeTemp.currency.converted_currency = ConvertCurrency.display_name;
          courses.results[i].course_fee = [feeTemp];
        }
        results.push(courses.results[i]);
      }
      setCoursesCount(courses.count);
      setCourses(results);
      setCourseLoader(false);
    }
  };
  const selectCurrencyFtn = (currency) => {
    filters = true;
    locationCheck();
    filtersCurrency = currency;
    feeState = false;
    setSelectedCurrency(currency);
    setCourses(coursesfilter);
    pushParamsToUrl();
    if (parseInt(currency) === 0) {
      // debugger;
      let original_results = [];
      for (let i = 0; i < coursesfilter.length; i++) {
        let Obj = coursesfilter[i];
        if (Obj.course_fee) {
          let fee = Obj.course_fee[0];
          delete fee["converted_value"];
          coursesfilter[i].course_fee = [fee];
        }
        original_results.push(coursesfilter[i]);
      }
      setCourses(coursesfilter);
    } else {
      let ConvertCurrency = AllCurrencies.find(
        (cr) => cr.id === parseInt(currency)
      );
      if (ConvertCurrency) {
        let results = [];
        for (let i = 0; i < coursesfilter.length; i++) {
          let tempObj = coursesfilter[i];
          let base_pkr = tempObj.base_fee;
          if (
            tempObj?.course_fee?.length >= 1 &&
            tempObj?.course_fee !== undefined
          ) {
            let feeTemp = tempObj.course_fee[0];
            base_pkr = tempObj.base_fee;
            // }
            feeTemp.converted_value = base_pkr / ConvertCurrency.value_to_pkr;
            feeTemp.currency.converted_currency = ConvertCurrency.display_name;
            coursesfilter[i].course_fee = [feeTemp];
            results.push(coursesfilter[i]);
          }
        }
        setCourses(results);
      }
    }
  };

  function handleFeeUpperChange(value) {
    if (value > 5000000) {
      alert("Fee Lower range can not be greater than Fee Upper range");
    } else {
      setFeeUpperRange(parseInt(value));
    }
  }

  function handleFeeLowerRangeValue(value) {
    if (value < -1) {
      alert("value cannot be less than zero");
    } else {
      setFeeLowerRange(parseInt(value));
    }
  }

  const applyFeeRange = () => {
    feeState = true;
    locationCheck();
    emptySomeFilters();
    setSelectedSortOption(null);
    filters = true;
    pageState.currentPage = 1;
    pageNumber = 1;
    sortOption = 0;
    setPageState(pageState);
    if (feeLowerRange < 0) {
      alert("Fee range can not be empty for lower range");
    } else if (feeUpperRange === 0) {
      alert("Fee range can not be empty for Upper range");
    } else {
      setFeeLowerRange(parseFloat(feeLowerRange));
      feeEnd = parseFloat(feeUpperRange);
      feeStart = parseFloat(feeLowerRange);
      setFeeUpperRange(parseFloat(feeUpperRange));
      if (feeUpperRange <= feeLowerRange) {
        alert("Fee Lower range can not be greater than upper ");
      } else {
        if (filtersCurrency !== 0) {
          let ConvertCurrency = currencies.find(
            (cr) => cr.id === parseInt(filtersCurrency)
          );
          if (ConvertCurrency) {
            feeStart = feeLowerRange * ConvertCurrency.value_to_pkr;
            feeEnd = feeUpperRange * ConvertCurrency.value_to_pkr;
            setFeeLowerRange(feeStart);
            setFeeUpperRange(feeEnd);
          }
        }
        onApplyFilters();
      }
    }
  };
  const handleDialogConfirmOpen = (type, subtype) => {
    setLocationType(type);
    if (type === locations_types[0]) {
      if (selectedLocation.length) {
        if (selectedLocation[0].type === locations_types[0]) {
          $("#currentLocation").modal("show");
        } else {
          setConfirmDialogHeading("Use my current location");
          setConfirmDialogContent(
            "All pre selected Location will be remove Results will be according to your current Location .Please confirm to proceed next or Cancel."
          );
          setConfirmDialog(true);
        }
      } else {
        if (
          selectedLocations.countries.length > 0 ||
          selectedLocations.states.length > 0 ||
          selectedLocations.cities.length > 0
        ) {
          let statusConfirm = window.confirm(
            "All pre selected Location will be remove Results will be according to your current Location .Please confirm to proceed OK or Cancel."
          );
          if (statusConfirm) {
            setselectedCountry([]);
            selectedLocations = { countries: [], states: [], cities: [] };
            countryState = false;
            statesState = false;
            cityState = false;
            $("#currentLocation").modal("show");
          } else {
            $("#currentLocation").modal("hide");
          }
        } else {
          $("#currentLocation").modal("show");
        }
      }
    }

    if (type === locations_types[1]) {
      if (selectedLocation.length) {
        if (selectedLocation[0].type === locations_types[1]) {
          $("#autoLocation").modal("show");
        } else {
          setConfirmDialogHeading("Enter a Town/ city or Postcode");
          setConfirmDialogContent(
            "All pre selected Location will be remove if you Select Town, City or any Location from google.Please confirm to proceed next or Cancel."
          );
          setConfirmDialog(true);
        }
      } else {
        if (selectedLocations.countries.length > 0) {
          $("#autoLocation").modal("hide");
          alert(
            "Kindly de-select the country to view Courses at your location."
          );
        } else {
          $("#autoLocation").modal("show");
        }
      }
    }
  };
  const handleAutoLocation = (location) => {
    setAutoAddress(location.address);
    setAutoLatitude(location.coordinates.lat);
    setAutoLongitude(location.coordinates.lng);
  };
  let specials = [];
  const fetchDataForSpecializations = (results, page) => {
    let params = getFilterSearchParams();
    delete params["specializations"];
    // debugger
    http
      .post(page, params)
      .then((res) => {
        // specials=results;
        specials = specials.concat(...results, res.data.results);
        specials = _.uniqBy(specials, "id");
        setFilteredSpecializations(specials);
        setPage(res.data.results);
        setPageSpecializationCount(res.data?.links?.next);

        if (res.data.next) {
          // change condition for fetch data
          // fetchDataForSpecializations(res.data.results, res.data.next)
        }
      })
      .catch((err) => {
        if (err) {
          setSpecHasMore(false);
        }
      });
  };
  const fetchDataForFilteredInstitutes = async (event) => {
    var params = getFilterSearchParams();
    const requestCount = await http.post(institutePageCount, params);
    http
      .post(institutePage, params)
      .then((res) => {
        let merge = arrayMergeStateCity(
          res.data.results,
          requestCount.data.results
        );
        var institutes = filteredInstitutes.concat(merge);
        setFilteredInstitutes(institutes);
        setInstitutes(res.data.results);
        setInstitutePage(res.data?.links.next);
        setInstitutePageCount(requestCount.data?.links.next);
      })
      .catch((err) => {
        if (err) {
          setInstituteHasMore(false);
        }
      });
  };

  function CheckAllStates() {
    if (
      selectedLocations.countries.length === 0 &&
      selectedLocations.cities.length === 0 &&
      selectedLocations.states.length === 0 &&
      selectedDegreeLevels.levels.length === 0 &&
      selectedDegreeLevels.types.length === 0 &&
      selectedIntakes.length === 0 &&
      selectedInstituteType.length === 0 &&
      selectedDisciplines.length === 0 &&
      selectedSpecializations.length === 0 &&
      selectedInstitutes.length === 0 &&
      selectedPopularity.length === 0 &&
      selectedCourse.length === 0
    ) {
      setShowCounter(true);
      advanceFilters = true;
      filters = false;
      main = false;
    } else {
      setShowCounter(false);
      advanceFilters = false;
      filters = true;
      main = false;
    }
  }

  const userLocationClick = (e, type) => {
    e.stopPropagation();
    if (type === "Current") {
      locationModalCheck = false;
    } else if (type === "Town") {
      locationModalCheck2 = false;
    }
  };
  const listInnerRef = useRef();
  const onScroll = () => {
    if (listInnerRef.current) {
      const element = document.getElementById("scrollableDiv");
      const { scrollTop, scrollHeight, clientHeight } = element;
      // var height = Math.max( scrollHeight, offsetHeight,
      //   clientHeight, scrollHeight, offsetHeight );
      // console.log('scrollHeight==',Math.round(height * .70),scrollTop,Math.round(height * .70) === scrollTop,)
      // if (Math.round(height * .70) === scrollTop) {
      if (Math.round(scrollTop + clientHeight) === scrollHeight) {
        if (pagespecialization) {
          setSpecializationNextDataLoader(true);
          const params = getFilterSearchParams();
          specializationGet(params, pagespecialization);
          element.scrollTo({
            bottom: scrollTop + clientHeight === scrollHeight,
            behavior: "smooth",
          });
        }
      }
    }
  };
  const getLocationCount = async (item) => {
  };
  const getDegreeLevel = async (item) => {
    let params = {
      levels: item.id,
    };
    const url = "course/course_related_degree_levels_count/";
    await http.get(url, params);
  };
  return (
    <div className="container">
      {/* {courseLoader ? (
        <div className="loader-web">
          <div className="loader-center">
            <div className="loaderCourse"></div>
          </div>
        </div>
      ) : null} */}
      <div className="mainFlex">
        <div className="sidebar filterSidebar webFilter">
          <div className="top">
            <h5> Filters </h5>
            <button className="btn" onClick={restFilters}>
              {" "}
              Reset All
            </button>
          </div>
          <CountriesIndividual
            filteredCountries={filteredCountries}
            filteredCountriesValue={filteredCountriesValue}
            countriesLoader={disciplineLoader}
            selectedCountry={selectedCountry}
            selectedLocations={selectedLocations}
            getLocationCount={getLocationCount}
            coutryApplyNow={coutryApplyNow}
            updateSelectedCountries={updateSelectedCountries}
            handleDialogConfirmOpen={handleDialogConfirmOpen}
            locationModalCheck={locationModalCheck}
            locationModalCheck2={locationModalCheck2}
            searchCountriesHandler={searchCountriesHandler}
            userLocationClick={userLocationClick}
            setselectedCountry={setselectedCountry}
          />
          <DegreeLevelsIndividual
            degreeLevelLoader={disciplineLoader}
            filteredDegreeLevels={filteredDegreeLevels}
            getDegreeLevel={getDegreeLevel}
            selectedDegreeLevels={selectedDegreeLevels}
            updateSelectedDegreeLevels={updateSelectedDegreeLevels}
            coutryApplyNow={coutryApplyNow}
          />
          <IntakesIndividual
            intakeLoader={intakeLoader}
            intakes={intakes}
            selectedIntakes={selectedIntakes}
            updateSelectedIntakes={updateSelectedIntakes}
            coutryApplyNow={coutryApplyNow}
          />
          <DisciplinesIndividual
            disciplineLoader={disciplineLoader}
            disciplines={disciplines}
            searchDisciplinesHandler={searchDisciplinesHandler}
            filteredDisciplines={filteredDisciplines}
            selectedDisciplines={selectedDisciplines}
            updateSelectedDisciplines={updateSelectedDisciplines}
            coutryApplyNow={coutryApplyNow}
          />
          <SpecializationIndividual
            specializationLoader={specializationLoader}
            specializations={specializations}
            specializationNextDataLoader={specializationNextDataLoader}
            pagespecialization={pagespecialization}
            listInnerRef={listInnerRef}
            coutryApplyNow={coutryApplyNow}
            onScroll={onScroll}
            searchSpecializationsHandler={searchSpecializationsHandler}
            fetchDataForSpecializations={specializationGet}
            filteredSpecializations={filteredSpecializations}
            specHasMore={specHasMoreSpecialization}
            selectedSpecializations={selectedSpecializations}
            updateSelectedSpecialization={updateSelectedSpecialization}
          />
          <InstituteTypesIndividual
            handleInstituteType={handleInstituteType}
            collegeCheck={collegeCheck}
            universityCheck={universityCheck}
          />
          <InstitutesIndividual
            institutes={institutes}
            instituteLoader={instituteLoader}
            searchInstituteHandler={searchInstituteHandler}
            filteredInstitutes={filteredInstitutes}
            coutryApplyNow={coutryApplyNow}
            fetchDataForFilteredInstitutes={fetchDataForFilteredInstitutes}
            institutesHasMore={institutesHasMore}
            selectedInstitutes={selectedInstitutes}
            updateSelectedInstitutes={updateSelectedInstitutes}
          />
          <DurationAndYearsIndividual
            durationSliderValue={durationSliderValue}
            durationMarks={durationMarks}
            handleDurationSliderValue={handleDurationSliderValue}
            onApplyFilters={onApplyFilters}
          />
          <FeeRangeIndividual
            feeLowerRange={feeLowerRange}
            feeUpperRange={feeUpperRange}
            handleFeeLowerRangeValue={handleFeeLowerRangeValue}
            handleFeeUpperChange={handleFeeUpperChange}
            applyFeeRange={applyFeeRange}
            selectedCurrency={
              selectedCurrency?.length !== 0 ? selectedCurrency : 33
            }
            currencies={currencies}
            courses={courses}
            setFeeUpperRange={setFeeUpperRange}
          />
        </div>
        <div className="mainContent" id={"course"}>
          <CounterSectionIndividual
            courseLoader={courseLoader}
            showCounter={showCounter}
            advance={params.advance}
            coursesCount={coursesCount}
            popularity={popularity}
            handlePopularityCheck={handlePopularityCheck}
            mobileFilters={mobileFilters}
            showView={showView}
            selectedSortOption={selectedSortOption}
            sortOptions={sortOptions}
            clickSortOption={clickSortOption}
            checkView={checkView}
            shareModal={shareModal}
            selectedCurrency={selectedCurrency}
            selectCurrencyFtn={selectCurrencyFtn}
            currencies={currencies}
            pageState={pageState}
            handlePageClick={handlePageClick}
            courses={courses}
          />
        </div>
        <div className="filterNav mobileFilter">
          <button className="closeFilterNav">
            <img
              src={require("../../../static/images/modal-times.svg").default}
              alt="modal-times"
            />
          </button>
          <div className="sidebar filterSidebar">
            <div className="top">
              <h5> Filters </h5>
              <button className="btn" onClick={restFilters}>
                {" "}
                Reset All
              </button>
            </div>
            <CountriesIndividual
              filteredCountries={filteredCountries}
              countriesLoader={disciplineLoader}
              selectedCountry={selectedCountry}
              selectedLocations={selectedLocations}
              getLocationCount={getLocationCount}
              updateSelectedCountries={updateSelectedCountries}
              handleDialogConfirmOpen={handleDialogConfirmOpen}
              locationModalCheck={locationModalCheck}
              locationModalCheck2={locationModalCheck2}
              searchCountriesHandler={searchCountriesHandler}
              userLocationClick={userLocationClick}
              setselectedCountry={setselectedCountry}
            />
            <DegreeLevelsIndividual
              degreeLevelLoader={disciplineLoader}
              filteredDegreeLevels={filteredDegreeLevels}
              selectedDegreeLevels={selectedDegreeLevels}
              updateSelectedDegreeLevels={updateSelectedDegreeLevels}
              coutryApplyNow={coutryApplyNow}
            />
            <IntakesIndividual
              intakeLoader={intakeLoader}
              intakes={intakes}
              selectedIntakes={selectedIntakes}
              updateSelectedIntakes={updateSelectedIntakes}
              coutryApplyNow={coutryApplyNow}
            />
            <DisciplinesIndividual
              disciplineLoader={disciplineLoader}
              disciplines={disciplines}
              searchDisciplinesHandler={searchDisciplinesHandler}
              filteredDisciplines={filteredDisciplines}
              selectedDisciplines={selectedDisciplines}
              updateSelectedDisciplines={updateSelectedDisciplines}
              coutryApplyNow={coutryApplyNow}
            />
            <SpecializationIndividual
              specializationLoader={specializationLoader}
              specializations={specializations}
              specializationNextDataLoader={specializationNextDataLoader}
              listInnerRef={listInnerRef}
              onScroll={onScroll}
              searchSpecializationsHandler={searchSpecializationsHandler}
              fetchDataForSpecializations={specializationGet}
              filteredSpecializations={filteredSpecializations}
              specHasMore={specHasMoreSpecialization}
              selectedSpecializations={selectedSpecializations}
              updateSelectedSpecialization={updateSelectedSpecialization}
            />
            <InstituteTypesIndividual
              handleInstituteType={handleInstituteType}
              collegeCheck={collegeCheck}
              universityCheck={universityCheck}
            />
            <InstitutesIndividual
              institutes={institutes}
              instituteLoader={instituteLoader}
              searchInstituteHandler={searchInstituteHandler}
              filteredInstitutes={filteredInstitutes}
              fetchDataForFilteredInstitutes={fetchDataForFilteredInstitutes}
              institutesHasMore={institutesHasMore}
              selectedInstitutes={selectedInstitutes}
              updateSelectedInstitutes={updateSelectedInstitutes}
            />
            <DurationAndYearsIndividual
              durationSliderValue={durationSliderValue}
              durationMarks={durationMarks}
              handleDurationSliderValue={handleDurationSliderValue}
              onApplyFilters={onApplyFilters}
            />
            <FeeRangeIndividual
              feeLowerRange={feeLowerRange}
              feeUpperRange={feeUpperRange}
              handleFeeLowerRangeValue={handleFeeLowerRangeValue}
              handleFeeUpperChange={handleFeeUpperChange}
              applyFeeRange={applyFeeRange}
              selectedCurrency={
                selectedCurrency?.length !== 0 ? selectedCurrency : 33
              }
              currencies={currencies}
              courses={courses}
              setFeeUpperRange={setFeeUpperRange}
            />
          </div>
          <button className="btn defaultBtn applyFiltBtn">Apply Filter</button>
        </div>
      </div>
      {/* All Modals*/}
      <div
        className="modal fade"
        id="requestInfoModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="requestInfoModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="requestInfoModalLabel">
                Request More Info
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <CourseRequestInfoForm course={requestInfoCourse} />
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade signinModal"
        id="currentLocation"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <img
                  src={
                    require("../../../static/images/modal-times.svg").default
                  }
                  alt="modal-times"
                />
              </button>
              <h3>Current Location</h3>
              {/* <CurrentLocation /> */}
              <CurrentLocation onLoadCurrentLocation={handleCurrentLocation} />
              <form
                onSubmit={(e) => {
                  currentLocationSubmitHandler(e);
                }}
              >
                <div className="form-group">
                  <Select
                    name="distance"
                    styles={customStyles}
                    placeholder="Search range"
                    options={distanceOption}
                    onChange={handleDistanceSelectChange}
                    className={distanceError ? " is-invalid" : null}
                  />
                  {distanceError && (
                    <div>
                      <span className="text-danger">Required filed</span>
                    </div>
                  )}
                  <img
                    className="formIcon"
                    src={
                      require("../../../static/images/forms/Distance.svg")
                        .default
                    }
                    alt="Distance"
                  />
                </div>
                <button className="formBtn" type="submit">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade signinModal"
        id="autoLocation"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <img
                  src={
                    require("../../../static/images/modal-times.svg").default
                  }
                  alt="modal-times"
                />
              </button>
              <h3>Enter Town, City or Postcode</h3>

              <form
                onSubmit={(e) => {
                  genericLocationSubmitHandler(e);
                }}
              >
                <div className="form-group">
                  <Select
                    name="autodistance"
                    styles={customStyles}
                    placeholder="Search range"
                    options={distanceOption}
                    onChange={handleAutoDistanceSelectChange}
                    className={autoDistanceError ? "is-invalid" : null}
                  />
                  {autoDistanceError && (
                    <div>
                      <span className="text-danger">Required filed</span>
                    </div>
                  )}
                  <img
                    className="formIcon"
                    src={
                      require("../../../static/images/forms/Distance.svg")
                        .default
                    }
                    alt="Distance"
                  />
                </div>
                <div className="form-group">
                  <LocationSearchInput
                    onLoadAutoLocation={handleAutoLocation}
                  />
                  <img
                    className="formIcon"
                    src={
                      require("../../../static/images/forms/preferred_location.svg")
                        .default
                    }
                    alt="preferred_location"
                  />
                </div>

                <button className="formBtn" type="submit">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal  fade"
        id="applyModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="applyModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <h3>Request Information</h3>
              <CourseApplyForm course={applyCourse} />
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade signinModal sharePageModal"
        id="sharePageModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                id=""
                aria-label="Close"
              >
                <img
                  src={
                    require("../../../static/images/modal-times.svg").default
                  }
                  alt="modal-times"
                />
              </button>

              <h3>Share Page Link</h3>
              <div className="shareSearch">
                <input
                  type="text"
                  disabled={urlSpinner}
                  className="form-control"
                  value={urlValue}
                ></input>
                <button
                  className="btn defaultBtn"
                  onClick={copyToClickBoard}
                  disabled={urlSpinner}
                >
                  {urlSpinner ? (
                    <span className="spinner-border" />
                  ) : selected ? (
                    <span>Copied</span>
                  ) : (
                    <span>Copy</span>
                  )}
                </button>
              </div>
              <p>or Share via</p>
              <ul>
                <li>
                  <WhatsappShareButton
                    url={urlValue}
                    disabled={urlSpinner}
                    title={"Tap the Link below to open the website"}
                  >
                    <WhatsappIcon size="3rem" />
                  </WhatsappShareButton>
                  {/*    <a href="#">*/}
                  {/*    <img*/}
                  {/*        src={*/}
                  {/*            require("../../../static/images/share-whatsapp.svg")*/}
                  {/*                .default*/}
                  {/*        }*/}
                  {/*        alt="banner"*/}
                  {/*    />*/}
                  {/*</a>*/}
                </li>
                <li>
                  <FacebookMessengerShareButton
                    appId={"867455750856341"}
                    disabled={urlSpinner}
                    redirectUri={urlValue}
                    clientId={"102"}
                    title="Tap the Link below to open website "
                  >
                    <FacebookIcon size={"3rem"} />
                  </FacebookMessengerShareButton>
                  {/*    <a href="javascript:void(0)">*/}
                  {/*    <img*/}
                  {/*        src={*/}
                  {/*            require("../../../static/images/share-facebook.svg")*/}
                  {/*                .default*/}
                  {/*        }*/}
                  {/*        alt="banner"*/}
                  {/*    />*/}
                  {/*</a>*/}
                </li>
                <li>
                  {/*    <a href="javascript:void(0)">*/}
                  {/*    <img*/}
                  {/*        src={*/}
                  {/*            require("../../../static/images/share-google.svg")*/}
                  {/*                .default*/}
                  {/*        }*/}
                  {/*        alt="banner"*/}
                  {/*    />*/}
                  {/*</a>*/}
                  <EmailShareButton
                    disabled={urlSpinner}
                    subject={"Course Detail"}
                    body={"Click the given link " + urlValue + " ."}
                  >
                    <EmailIcon size={"3rem"} />
                  </EmailShareButton>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CoursesFilters;
