import React, {useEffect, useState} from "react";

function InstituteFilter(props) {
	const { institute_name, selectedInstitutes } = props;
	const [instituteNameCheck, setInstituteNameCheck] = useState(false);
	const onChangeInstituteNameCheck = (institute_name, check) => {
		setInstituteNameCheck(!check);
		if (!check) {
			props.updateSelectedInstitutes(institute_name, true);
		} else {
			props.updateSelectedInstitutes(institute_name, false);
		}
		props.updateSelectedInstitutes(institute_name, !check);
	};
	useEffect(() => {
		if (selectedInstitutes?.length >= 1) {
			if (selectedInstitutes.some((e) => e.slug === institute_name.slug)) {
				setInstituteNameCheck(true);
			} else {
				setInstituteNameCheck(false);
			}
		} else {
			setInstituteNameCheck(false);
		}
	}, [props]);
	return (
			<div className="filterItemWrap">
				<div id={institute_name.name} className="filterItem">
					<input
						key={props.index + Math.floor(Math.random() * 1000)}
						type="checkbox"
						onChange={() => {
							onChangeInstituteNameCheck(institute_name, instituteNameCheck);
						}}
						checked={instituteNameCheck}
					></input>
					<span className={instituteNameCheck===true ? "active" : ""}>
					{institute_name.name} 
					{institute_name.course_count ? ` (${institute_name.course_count})` : ""}
						</span>
				</div>
			</div>
	);
}
export default InstituteFilter;
