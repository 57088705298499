import { useCallback, useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom-v5-compat"
import { ory, useSdkError } from "../../ory/pkg/sdk"
import registerImg from "../../../static/images/auth/forgot-bg.jpeg"
import logo from "../../../static/images/tcf.svg"

import { Button, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput } from "@mui/material"
import { ThemeProvider, useTheme } from '@mui/material/styles';
import { Link } from "react-router-dom"
import { useForm } from "react-hook-form"
import LoadingSpinner from "../../components/loader/loader"
import { inputTheme } from "../../shared/utils/muiInputTheme"
import { Visibility, VisibilityOff } from "@material-ui/icons"
import { useSnackbar } from "notistack"

const Settings = () => {
    const outerTheme = useTheme();
    const [flow, setFlow] = useState(null)
    const [searchParams, setSearchParams] = useSearchParams()
    const [showPassword, setShowPassword] = useState(false);
    const { enqueueSnackbar } = useSnackbar()

    const { register, handleSubmit, watch, getValues, formState: { errors } } = useForm();

    const navigate = useNavigate()

    const notify = (flow) => {
        const passwordField = flow?.ui.nodes.find(node => node.attributes.name === 'password');
        if (passwordField?.messages[0].text) {
            enqueueSnackbar(passwordField.messages[0].text, {
                variant: passwordField?.messages[0].type,
                anchorOrigin: { vertical: 'top', horizontal: 'center' }
            })
        }
    }

    const getFlow = useCallback(
        (flowId) =>
            ory
                // the flow data contains the form fields, error messages and csrf token
                .getSettingsFlow({ id: flowId })
                .then(({ data: flow }) => {
                    // notify(flow)
                    setFlow(flow)
                })
                .catch(sdkErrorHandler),
        [],
    )

    const sdkErrorHandler = useSdkError(getFlow, setFlow, "/settings", true, notify)
    const csrfField = flow?.ui.nodes.find(node => node.attributes.name === 'csrf_token');

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const createFlow = () => {
        ory
            // create a new settings flow
            // the flow contains the form fields, error messages and csrf token
            // depending on the Ory Network project settings, the form fields returned may vary
            .createBrowserSettingsFlow()
            .then(({ data: flow }) => {
                // Update URI query params to include flow id
                setSearchParams({ ["flow"]: flow.id })
                // Set the flow data
                setFlow(flow)
            })
            .catch(sdkErrorHandler)
    }

    const redirectToHome = () => {
        navigate('/');
        window.location.reload();
    };
    const submitFlow = (data) => {
        if (!flow) return navigate("/settings", { replace: true })

        let body = {
            csrf_token: data.csrf_token,
            method: "password",
            password: data.password
        }

        ory
            // submit the form data the user provided to Ory
            .updateSettingsFlow({ flow: flow.id, updateSettingsFlowBody: body })
            .then(({ data: flow }) => {
                // notify(flow)
                setFlow(flow)
                redirectToHome()
            })
            .catch(sdkErrorHandler)
    }

    useEffect(() => {
        // we might redirect to this page after the flow is initialized, so we check for the flowId in the URL
        const flowId = searchParams.get("flow")
        // the flow already exists
        if (flowId) {
            getFlow(flowId).catch(createFlow) // if for some reason the flow has expired, we need to get a new one

            return
        }
        createFlow()
    }, [])
    // we check if the flow is set, if not we show a loading indicator
    return flow ? (
        // <SnackbarProvider maxSnack={3}>
        <div className="container-fluid">
            <div className="row auth verification align-items-center">
                <div className="col-sm-12 col-md-12 col-lg-5 ory-fields-container">
                    <div className="logo w-100 text-center">
                        <Link to="/">
                            <img src={logo} alt="logo" />
                        </Link>
                    </div>

                    <div className="title-container pt-4">
                        <h2>Reset Password</h2>
                    </div>

                    {flow.ui.messages && flow.ui.messages[0].type === "error" &&
                        <FormHelperText className="Mui-error ory-helper-text pt-1 pb-4">{flow?.ui?.messages[0].text}</FormHelperText>
                    }

                    <FormHelperText className="ory-helper-text pt-1 pb-4">You have successfully recovered your account. Please set a new Password.</FormHelperText>

                    <div className="w-100 ory-input-fields">
                        <ThemeProvider theme={inputTheme(outerTheme)}>
                            <form
                                action={flow.ui.action}
                                method={flow.ui.method}
                                onSubmit={handleSubmit(submitFlow)}
                            >
                                <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined" className="w-100 m-0 pb-3">
                                    <InputLabel className={errors.password ? "Mui-error" : ""} htmlFor="outlined-adornment-password">New Password</InputLabel>
                                    <OutlinedInput
                                        name="password"
                                        error={errors.password}
                                        {...register("password", {
                                            required: true,
                                            minLength: 8
                                        })}

                                        type={showPassword ? 'text' : 'password'}
                                        label="New Password"
                                        variant="outlined"
                                        className="w-100"
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                    {errors.password &&
                                        <FormHelperText className="Mui-error">{errors.password?.type === "required" ? "This field is required" : "Must be at least 8 characters"}</FormHelperText>
                                    }

                                </FormControl>
                                <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined" className="w-100 m-0 pb-2">
                                    <InputLabel className={errors.password ? "Mui-error" : ""} htmlFor="outlined-adornment-password">Confirm New Password</InputLabel>
                                    <OutlinedInput
                                        error={errors.confirmPassword}
                                        name="confirmPassword"
                                        {...register("confirmPassword", { required: true })}
                                        type={showPassword ? 'text' : 'password'}
                                        label="Confirm New Password"
                                        variant="outlined"
                                        className="w-100"
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                    {errors.confirmPassword &&
                                        <FormHelperText className="Mui-error">{errors.password?.type === "required" ? "This field is required" : "Must be at least 8 characters"}</FormHelperText>
                                    }
                                    {watch("confirmPassword") !== watch("password") &&
                                        getValues("confirmPassword") &&
                                        <FormHelperText className="Mui-error">{errors.password?.type === "required" ? "This field is required" : "Password does not match."}</FormHelperText>
                                    }
                                </FormControl>

                                {csrfField &&
                                    <input
                                        {...register("csrf_token")}
                                        type={csrfField.attributes.type}
                                        name={csrfField.attributes.name}
                                        value={csrfField.attributes.value}
                                        disabled={csrfField.attributes.disabled}
                                    />
                                }

                                <input {...register("method")} type="hidden" value="code" />

                                <Button
                                    variant="contained"
                                    name="method"
                                    type="submit"
                                    value="code"
                                    className="w-100 ory-submit-button mt-4"
                                >
                                    Reset Password
                                </Button>
                            </form>
                        </ThemeProvider>
                    </div>
                </div>
                <div className="col-7 d-none d-lg-block p-0">
                    <div className="image-container register">
                        <div className="overlay"></div>
                        <img className="main-image" src={registerImg} alt="Verification" />
                    </div>
                </div>
            </div>

        </div>
    ) : (
        <LoadingSpinner height={'100vh'} />
    )
}

export default Settings