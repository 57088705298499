export const ADD_DISCIPLINE_FILTER_DATA = "ADD_DISCIPLINE_FILTER_DATA";
export const ADD_DISCIPLINE_FILTER_DATA_SUCCESS =
	"ADD_DISCIPLINE_FILTER_DATA_SUCCESS";
export const ADD_DISCIPLINE_FILTER_DATA_FAIL =
	"ADD_DISCIPLINE_FILTER_DATA_FAIL";

export function addDisciplineFilterData(data = {}) {
	return (Dispatch) =>
		Dispatch({
			type: "ADD_DISCIPLINE_FILTER_DATA_SUCCESS",
			payload: data,
		});
}
