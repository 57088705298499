export const ADD_INSTITUTE_PROGRAM_FILTER_DATA =
	"ADD_INSTITUTE_PROGRAM_FILTER_DATA";
export const ADD_INSTITUTE_PROGRAM_FILTER_DATA_SUCCESS =
	"ADD_INSTITUTE_PROGRAM_FILTER_DATA_SUCCESS";
export const ADD_INSTITUTE_PROGRAM_FILTER_DATA_FAIL =
	"ADD_INSTITUTE_PROGRAM_FILTER_DATA_FAIL";

export function addInstituteProgramFilterData(data = {}) {
	return (Dispatch) =>
		Dispatch({
			type: "ADD_INSTITUTE_PROGRAM_FILTER_DATA_SUCCESS",
			payload: data,
		});
}
