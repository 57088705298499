import React, { useEffect, useState } from "react";

function DisciplineFilter(props) {
	const { discipline, selectedDisciplines } = props;
	const [disciplineCheck, setDisciplineCheck] = useState(false);
	useEffect(() => {
		if (selectedDisciplines.length >= 1) {
			if (selectedDisciplines.some((e) => e.slug === discipline.slug)) {
				setDisciplineCheck(true);
			} else {
				setDisciplineCheck(false);
			}
		} else {
			setDisciplineCheck(false);
		}
	}, [props.rand]);
	const onChangeDisciplineCheck = (discipline, check) => {
		setDisciplineCheck(!check);
		if (!check) {
			props.updateSelectedDisciplines(discipline, true);
		} else {
			props.updateSelectedDisciplines(discipline, false);
		}
	};
	return (
		<>
			<div className="filterItemWrap">
				<div id={discipline.name} className="filterItem">
					<input
						key={props.index + Math.floor(Math.random() * 1000)}
						type="checkbox"
						onChange={() => {
							onChangeDisciplineCheck(discipline, disciplineCheck);
						}}
						checked={disciplineCheck}
					></input>
					<span className={disciplineCheck === true ? "active" : ""}>
						{discipline.name} 
						{discipline.courses_count ? ` (${discipline.courses_count})` : ""}
					</span>
				</div>
			</div>
		</>
	);
}

export default DisciplineFilter;
