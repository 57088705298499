import React, { useEffect, useState } from "react";
function SpecializationFilter(props) {
	const { specialization, selectedSpecializations} = props;
	const [specializationCheck, setSpecializationCheck] = useState(false);
	const onChangeSpecializationCheck = (specialization, check) => {
		setSpecializationCheck(!check);
		if (!check) {
			props.updateSelectedSpecialization(specialization, true);
		} else {
			props.updateSelectedSpecialization(specialization, false);
		}
	};
	useEffect(() => {
	    if (selectedSpecializations.length >= 1) {
	        if (selectedSpecializations.some((s) => s.slug === specialization.slug)) {
	            setSpecializationCheck(true);
	        } else {
	            setSpecializationCheck(false);
	        }
	    } else {
	        setSpecializationCheck(false);
	    }
	}, [props.rand]);
	return (
			<div className="filterItemWrap">
				<div id={specialization.specialization} className="filterItem">
					<input
						key={props.index + Math.floor(Math.random() * 1000)}
						type="checkbox"
						id={'specialization'+props.index}
						onChange={() => {
							onChangeSpecializationCheck(specialization, specializationCheck);
						}}
						checked={specializationCheck}
					></input>
					<label 
						htmlFor={'specialization'+props.index}
					className={specializationCheck === true ? "active" : ""}>
						{specialization.specialization} 
						{specialization.course_count ? ` (${specialization.course_count})` : ""}
					</label>
				</div>
			</div>
	);
}
export default SpecializationFilter;
