import React, { useEffect, useState } from "react";

function CityFilter(props) {
  const { city, selectedLocations } = props;
  const [cityCheck, setCityCheck] = useState(false);
  const [activeClass, setActiveClass] = useState(false);

  useEffect(() => {
    if (!props.status) {
      setCityCheck(
        selectedLocations.cities.some((e) => e?.slug === city?.slug)
      );
    }

    setActiveClass(selectedLocations.cities.some((c) => c.slug === city?.slug));
  }, [props]);

  const onChangeCityCheck = (city, check) => {
    setCityCheck(!check);
    setActiveClass(!check);
    props.callCityUpdate(city, "city", !check);
  };

  return (
    <>
      <div className="filterItem">
        <input
          key={props.index}
          type="checkbox"
          onChange={() => {
            onChangeCityCheck(props.city, cityCheck);
          }}
          checked={cityCheck}
        />
        <span className={activeClass ? "active" : ""}>
          {props.city.city_name} (
          {props.city.course_count >= 0 || props.city.institute_count || "0"}
          )
        </span>
      </div>
    </>
  );
}

export default CityFilter;
