import { outlinedInputClasses } from "@mui/material";
import { createTheme } from '@mui/material/styles';

export const inputTheme = (outerTheme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            '--TextField-brandBorderColor': '#E0E3E7',
            '--TextField-brandBorderHoverColor': '#B2BAC2',
            '--TextField-brandBorderFocusedColor': '#5551FF',
            color: '#232323',
            '& label.Mui-focused': {
              color: 'var(--TextField-brandBorderFocusedColor)',
            },
            '& label.Mui-error': {
              color: '#d32f2f',
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: 'var(--TextField-brandBorderColor)',
          },
          root: {
            '--TextField-brandBorderColor': '#E0E3E7',
            '--TextField-brandBorderHoverColor': '#B2BAC2',
            '--TextField-brandBorderFocusedColor': '#5551FF',
            color: '#232323',
            '& label.Mui-focused': {
              color: 'var(--TextField-brandBorderFocusedColor)',
            },
            '& label.Mui-focused.Mui-error': {
              color: '#d32f2f',
            },
            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'var(--TextField-brandBorderHoverColor)',
            },
            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'var(--TextField-brandBorderFocusedColor)',
              color: 'var(--TextField-brandBorderFocusedColor)',
              border: '1px solid'
            },
            [`&.Mui-error .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: '#d32f2f',
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            '--TextField-brandBorderFocusedColor': '#5551FF',
            fontSize: '14px',
            '&.Mui-focused': {
              color: 'var(--TextField-brandBorderFocusedColor)',
            },
            '&.Mui-error': {
              color: '#d32f2f',
            },
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            '--TextField-brandBorderFocusedColor': '#5551FF',
            color: '#232323',
            fontSize: '15px',
            '&.Mui-focused': {
              color: '#232323',
            },
            '&.Mui-error': {
              color: '#d32f2f',
            },
          },
        },
      },

    },
  });