import { useEffect, useState } from "react";
import CheckboxList from "../../../components/loaders/checklist";
import { getDegreeLevelsReq } from "../../../services/apiService";
import CustomButton from "../../../components/CustomButton";
import { useNavigate } from "react-router-dom-v5-compat";

export default function DegreeFilter({
  setOpenModal,
  isBlurred,
  filterName,
  updateSearchParams,
  searchParams,
}) {
  let typeParams = searchParams.degreelevel_type;
  if (searchParams.degreelevel_type?.length) {
    typeParams = typeParams.split(",");
  }

  let levelParams = searchParams.degreelevel;
  if (searchParams.degreelevel?.length) {
    levelParams = levelParams.split(",");
  }
  const [selectedDegreeLevels, setSelectedDegreeLevels] = useState(levelParams);
  const [selectedDegreeType, setSelectedDegreeType] = useState(typeParams);
  const [isOpen, setIsOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [degreeData, setDegreeData] = useState(null)
 const navigate=useNavigate();
  const getFilterData = async () => {
    setIsLoading(true)
    try {
      const res = await getDegreeLevelsReq()
      const { data } = res;
      setDegreeData(data)
    } catch (err) {
      console.log('Error', err)
    }
    setIsLoading(false)
  }

  const handleDegreeLevelSelect = (
    slug,
    degree,
    degreeType
  ) => {
    if (degree === "Type") {
      setSelectedDegreeType((prevSelected) => {
        if (prevSelected.includes(slug)) {

          const selectedDegreeEntry = degreeData.find((entry) => entry.slug === slug);
          if (selectedDegreeEntry) {
            const degreeLevelSlugs = selectedDegreeEntry.degree_levels.map((level) => level.slug);
            selectedDegreeLevels &&
              setSelectedDegreeLevels((prevLevels) =>
                prevLevels?.filter((levelSlug) => !degreeLevelSlugs.includes(levelSlug))
              );
          }
          return prevSelected.filter((id) => id !== slug);
        } else {
          const selectedDegreeEntry = degreeData.find((entry) => entry.slug === slug);

          if (selectedDegreeEntry) {
            const degreeLevelSlugs = selectedDegreeEntry.degree_levels.map((level) => level.slug);
            setSelectedDegreeLevels([...degreeLevelSlugs]);
            // setSelectedDegreeLevels((prevLevels) => [...prevLevels, ...degreeLevelSlugs]); //for multi select
          }
          return [slug];
          // return [...prevSelected, slug]; // for multi select
        }
      });
    }
    else {
      setSelectedDegreeLevels((prevSelected) => {
        if (prevSelected.includes(slug)) {
          return prevSelected.filter((item) => item !== slug);
        } else {
          if (selectedDegreeType[0] !== degreeType) {
            setSelectedDegreeType([degreeType])
            return [slug];
          } else {
            return [...prevSelected, slug];
          }
        }
      });
    }
  };

  const onApply = () => {
    updateSearchParams({
      degreelevel_type: selectedDegreeType && selectedDegreeType.join(","),
      degreelevel: selectedDegreeLevels && selectedDegreeLevels.join(",")
    });
  }

  useEffect(() => {
    getFilterData()
  }, []);

  return (
    <>
      <div className="filterBox">
        <h6
          style={{ cursor: "pointer" }}
          onClick={() => setIsOpen((isOpen) => !isOpen)}
          className="filterOpen"
        >
          {filterName}
          {isOpen ? (
            <i className="fa fa-angle-down"></i>
          ) : (
            <i className="fa fa-angle-right"></i>
          )}
        </h6>
        {isOpen && (
          <div className="show">
            {isLoading ?
              <CheckboxList /> :
              <>
                <div className="filterBoxScroll">
                  {degreeData?.map((degree, index) => (
                    <Degree
                    setOpenModal={setOpenModal}
                     isBlurred={isBlurred}
                      key={index}
                      degree={degree}
                      index={index}
                      selectedDegreeLevels={selectedDegreeLevels}
                      selectedDegreeType={selectedDegreeType}
                      onDegreeLevelSelect={handleDegreeLevelSelect}
                    />
                  ))}
                </div>
                <div className="text-center pb-2">
                  <CustomButton
                    onClick={!isBlurred? onApply:()=> setOpenModal(true)}
                    buttonText="Apply Filter"
                  />
                </div>
              </>
            }
          </div>
        )}
      </div>
    </>
  );
}

const Degree = ({
  setOpenModal,
  isBlurred,
  degree,
  selectedDegreeLevels,
  onDegreeLevelSelect,
  selectedDegreeType,
}) => {
  const [degreeArrowClicked, setDegreeArrowClicked] = useState(false)
  const [isChildSelected, setIsChildSelected] = useState({
    partial: false,
    full: false
  })
   const navigate=useNavigate();

  useEffect(() => {
    const degreeSlugs = degree.degree_levels.map(level => level.slug);

    // Check if any state is partially selected
    if (degree.degree_levels.some(obj => selectedDegreeLevels.includes(obj.slug))) {
      setIsChildSelected({
        full: false,
        partial: true,
      });
    } 
    else {
      setIsChildSelected((prevState) => ({
        ...prevState,
        partial: false,
      }));
    }

    // Check if all states are fully selected
    if (degreeSlugs.every(slug => selectedDegreeLevels.includes(slug))) {
      setIsChildSelected({
        partial: false,
        full: true,
      });
    } else {
      setIsChildSelected((prevState) => ({
        ...prevState,
        full: false,
      }));
    }

  }, [selectedDegreeLevels, selectedDegreeType]);
  return (
    <>
      <div className="filterItemWrap">
        <div
          id={degree?.name}
          className={"filterItem single-select " + (degreeArrowClicked && "filterOpen")}
        >
          <input
            className={(isChildSelected.partial && "childChecked")}
            type="checkbox"
            id={degree.id}
            name={degree.name}
            checked={selectedDegreeType.includes(degree.slug)}
            onChange={!isBlurred?() => onDegreeLevelSelect(degree.slug, "Type"):()=>setOpenModal(true)}
          />
          <label htmlFor={degree.id}>{degree.name}</label>
          {!degreeArrowClicked ?
            <i
              className="fa fa-angle-right"
              onClick={() => setDegreeArrowClicked(true)}
            /> :
            <i
              className="fa fa-angle-down"
              onClick={() => setDegreeArrowClicked(false)}
            />
          }
        </div>

        <div
          id={degree.slug}
          className={"filterItemCont " + (degreeArrowClicked && "show")}
        >
          {degree.degree_levels.map((degreeLevel, index) => (
            <div key={index} className="filterItemWrap">
              <div
                id={degreeLevel?.name}
                className={
                  "filterItem " + (degreeArrowClicked && "filterOpen")
                }
              >
                <input
                  type="checkbox"
                  id={degreeLevel.id}
                  name={degreeLevel.name}
                  checked={selectedDegreeLevels.includes(degreeLevel.slug)}
                  onChange={() =>
                    onDegreeLevelSelect(degreeLevel.slug, "Level", degree.slug)
                  }
                />
                <label htmlFor={degreeLevel.id}>
                  {degreeLevel.name}
                </label>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
