import React, { useEffect, useRef, useState } from "react";
import * as a from "../../apis/store/actions";
import { BASE_URL, http } from "../../services/apiService/apiService";
import { useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import Fuse from 'fuse.js'
import CurrentLocation from "../map/currentLocation";
import LocationSearchInput from "../map/autoloaction";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import axios from "axios";
import { DISTANCE_OPTIONS, MAIN_SEARCH_CUSTOM_STYLES, MAIN_SEARCH_LOCATION_TYPES } from "../../constants";
import PopularCitiesModal from "./PopularCitiesModal";
import DisciplineModal from "./DisciplineModal";
import CourseSearchBox from "./CourseSearchBox";
import QualificationSearchBox from "./QualificationSearchBox";
import LocationSearchBox from "./LocationSearchBox";
import PopularRegionsModal from "./PopularRegionsModal";
import { getDisciplineSpecializationReq } from "../../services/apiService"
import LoginModal from "../LoginModal-v1";

let degreeLevel_arrow = true;
let degreeTypes = []

function MainSearch(props) {
    const options = {
        useExtendedSearch: true,
        keys: [
            "name",
            "level_type"
        ]
    };
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    let userCount = 0;
    const wrapperRef = useRef(null);
    const history = useHistory();
    const dispatch = useDispatch();
    const degreeLevelList = useSelector(({ apis }) => apis.allDegreeLevels.data);

    const disciplinesList = useSelector(
        ({ apis }) => apis.allDisciplineLevels.data
    );
    const regionsList = useSelector(({ apis }) => apis.allRegions.data);
    const allRegionsCountries = useSelector(
        ({ apis }) => apis.allRegionsCountries.data
    );
    const popularCountriesList = useSelector(
        ({ apis }) => apis.allPopularCountries.data
    );
    const popularCitiesList = useSelector(
        ({ apis }) => apis.allPopularCities.data
    );
    const [disciplines, setDisciplines] = useState([]);
    const [selectedDiscipline, setSelectedDiscipline] = useState(null);
    const [spellCheck, setSpellCheck] = useState('')
    const [specializations, setSpecializations] = useState([]);
    const [degreeLevelsOptions, setDegreeLevelsOptions] = useState({});
    const [degreeLevels, setDegreeLevels] = useState([]);
    const [selectedDegreeLevel, setSelectedDegreeLevel] = useState([]);
    const [degreeLevelInput, setDegreeLevelInput] = useState("");
    const [degreeLevelSearch, setDegreeLevelSearch] = useState(false);
    const [undergraduate, setUndergraduate] = useState(false);
    const [postgraduate, setPostgraduate] = useState(false);
    const [research, setResearch] = useState(false);
    const [oldCountries, setOldCountries] = useState([]);
    const [selectedDegreeLevelError, setSelectedDegreeLevelError] = useState("");
    const [regions, setRegions] = useState([]);
    const [popularCountries, setPopularCountries] = useState([]);
    const [countries, setCountries] = useState([]);
    const [regionsCountries, setRegionsCountries] = useState([]);
    const [showCountryDetail, setShowCountryDetail] = useState(true);
    const [states, setStates] = useState([]);
    const [filteredStates, setfilteredStates] = useState([]);
    const [showStateDetail, setShowStateDetail] = useState(true);
    const [cities, setCities] = useState([]);
    const [filterCities, setFilterCities] = useState([]);
    const [popularCities, setPopularCities] = useState([]);
    const [locationInput, setLocationInput] = useState("");
    const [locationError, setLocationError] = useState(null);
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [address, setAddress] = useState(null);
    const [distance, setDistance] = useState(null);
    const [degreeType, setDegreeType] = useState('')
    const [locationType, setLocationType] = useState('')
    const [distanceError, setDistanceError] = useState("");
    const [autoAddress, setAutoAddress] = useState(null);
    const [autoDistance, setAutoDistance] = useState(null);
    const [autoDistanceError, setAutoDistanceError] = useState("");
    const [autoLocationError, setAutoLocationError] = useState("");
    const [autoLongitude, setAutoLongitude] = useState(null);
    const [autoLatitude, setAutoLatitude] = useState(null);
    const [suggestedLocation, setSuggestedLocation] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState([]);
    const [loadSpecializations, setLoadSpecializations] = useState(true);
    const [specializationList, setSpecializationList] = useState([]);
    const [disciplineId, setDisciplineId] = useState(null);
    const [page, setPage] = useState(null);
    const [selectedCourses, setSelectedCourses] = useState([]);
    const [courseInput, setCourseInput] = useState("");
    const [courseError, setCourseError] = useState("");
    const [countryInput, setCountryInput] = useState("");
    const [stateInput, setStateInput] = useState("");
    const [cityInput, setCityInput] = useState("");
    const [regionModal, setRegionModal] = useState(true);
    const [showRegion, setShowRegion] = useState(true);
    const [confirmDialog, setConfirmDialog] = useState(false);
    const [confirmDialogHeading, setConfirmDialogHeading] = useState("");
    const [confirmDialogContent, setConfirmDialogContent] = useState("");
    const [tempSuggestedLocation, setTempSuggestedLocation] = useState(null);
    const [degreeLevelPlaceholder, setDegreeLevelPlaceholder] = useState("Search by Qualification");
    const [locationPlaceholder, setLocationPlaceholder] = useState("Search by Location");
    const [coursePlaceholder, setCoursePlaceholder] = useState("Search by Course");
    const [emptyResult, setEmptyResult] = useState("")
    const locationRef = useRef(null);
    const [isLocationFocus, setIsLocationFocus] = useState(false);
    const[openModal,setOpenModal]=useState(false);

    useEffect(() => {
        dispatch(a.getAllCountriesWithDetail());
        dispatch(a.getAllDegreeLevels());
        dispatch(a.getAllRegions());
        dispatch(a.getAllPopularCountries());
        dispatch(a.getAllRegionsCountries());
        dispatch(a.getAllPopularCities());
        dispatch(a.getAllDesciplines());
    }, [dispatch]);
    useEffect(() => {
        if (degreeLevelList?.length) {
            degreeLevelList.map((item) => {
                item.active = false;
                return item;
            });
            setDegreeLevels(degreeLevelList)
            setDegreeLevelsOptions(groupByDegreeLevels(degreeLevelList));
            let keys = Object.keys(groupByDegreeLevels(degreeLevelList))
            for (let i = 0; i < keys.length; i++) {
                degreeTypes.push({
                    name: keys[i],
                })
            }
        }
        if (regionsList.length) {
            setRegions(regionsList);
        }
        if (popularCountriesList.length) {
            setPopularCountries(popularCountriesList);
        }
        if (allRegionsCountries.length) {
            setRegionsCountries(allRegionsCountries);
            setCountries(allRegionsCountries);
        }
        if (popularCitiesList.length) {
            setPopularCities(popularCitiesList);
        }
        if (disciplinesList?.length) {
            disciplinesList.map((item) => {
                item.type = "Discipline";
                item.checked = false;
                return item;
            });
            setDisciplines(disciplinesList);
        }
    }, [
        degreeLevelList,
        regionsList,
        popularCountriesList,
        allRegionsCountries,
        popularCitiesList,
        disciplinesList,
    ]);
    useEffect(() => {
        // if (selectedDegreeLevel.length > 0) {
        //     setDegreeLevelPlaceholder("Add more")
        // } else {
        //     setDegreeLevelPlaceholder("Search by Qualification")
        // }
        if (selectedLocation.length > 0) {
            setLocationPlaceholder("")
        } else {
            setLocationPlaceholder("Search by Location")
        }
        // if (selectedCourses.length > 0) {
        //     setCoursePlaceholder("Add more")
        // } else {
        //     setCoursePlaceholder("Search by Course")
        // }
    }, [selectedDegreeLevel, selectedLocation, selectedCourses])

    useEffect(() => {
        setCourseError("");
    }, [selectedCourses, courseInput]);

    useEffect(() => {
        $(document).on(
            "click",
            ".searchBox .dropdown-menu .accordBtn",
            function (e) {
                e.stopPropagation();
            }
        );
    });
    //  course section
    const onClickCourseModal = () => {
        $("#coursesModal").modal("show");
    };

    const onSelectCourses = (item) => {
        let updatedSelectedCourses = [...selectedCourses];

        if (item.type === "Discipline") {
            item.checked = true;
            const updatedDisciplines = disciplines.map((obj) => ({
                ...obj,
                checked: item.discipline_id === obj.slug,
            }));
            setDisciplines(updatedDisciplines);
            const updatedSpecializations = specializations.map((obj) => ({
                ...obj,
                checked: false,
            }));
            setSpecializations(updatedSpecializations);
        }

        if (item.type === "Specialization") {
            const updatedSpecializations = specializations.map((obj) => ({
                ...obj,
                checked: item.slug === obj.slug,
            }));
            setSpecializations(updatedSpecializations);

            const updatedDisciplines = disciplines.map((obj) => ({
                ...obj,
                checked: item.discipline_id === obj.slug,
            }));
            setDisciplines(updatedDisciplines);

            const indexToRemove = selectedCourses.findIndex((course) =>
                course.type === "Discipline" && item.discipline_id === course.slug
            );
            if (indexToRemove !== -1) {
                updatedSelectedCourses.splice(indexToRemove, 1);
            }
        }

        const isAlreadySelected = updatedSelectedCourses.some(
            (course) => course.slug === item.slug
        );

        if (!isAlreadySelected) {
            updatedSelectedCourses.push(item);
        }

        setSelectedCourses(updatedSelectedCourses);
    };

    const onRemoveCourse = (index, item) => {
        onFoucusOutCourse();
        const updatedSelectedCourses = [...selectedCourses];
        updatedSelectedCourses.splice(index + 1, 1);
        setSelectedCourses(updatedSelectedCourses);

        if (item.type === "Discipline") {
            const updatedDisciplines = disciplines.map(obj =>
                obj.slug === item.slug ? { ...obj, checked: false } : obj
            );
            setDisciplines(updatedDisciplines);

            const removedSpecializations = specializationList.filter(spec =>
                spec.discipline_id !== item.slug
            );
            setSpecializations(removedSpecializations);
        }

        if (item.type === "Specialization") {
            const updatedSpecializations = specializationList.map(spec => ({
                ...spec,
                checked: spec.discipline_id !== item.slug ? false : true
            }));
            setSpecializationList(updatedSpecializations);
            setSpecializations(updatedSpecializations.filter(spec =>
                spec.discipline_id !== item.slug
            ));
        }

        const hasDisciplineOrSpecialization = selectedCourses.some(course =>
            course.type === "Discipline" || course.type === "Specialization"
        );

        if (!hasDisciplineOrSpecialization) {
            setSpecializations([]);
            setSpecializationList([]);
        }

        if (updatedSelectedCourses.length === 1) {
            setCourseInput('')
            setCoursePlaceholder(selectedCourses[0].name)
        }
    };

    const clearAllCourse = () => {
        setSelectedCourses([]);
    };

    const onArrowDisciplineSpecializations = async (discipline) => {
        setSelectedCourses([discipline])
        setCoursePlaceholder(discipline.name)
        setCourseInput('')
        setLoadSpecializations(true);
        setSpecializations([]);
        setDisciplineId(discipline.slug);
        const request = await getDisciplineSpecializationReq(discipline.slug)
        let specializations1 = request.data.results;
        if (request.data.next.startsWith('http:')) {
            const updatedNext = request.data.next.replace(/^http:/, 'https:');
            setPage(updatedNext);
        } else {
            setPage(request.data.next);
        }
        setSpecializations(specializations1);
        setLoadSpecializations(false);
    };

    const fetchData = (event) => {
        http.get(page).then((res) => {
            var specializations12 = specializations.concat(res.data.results);
            setSpecializations(specializations12);
            if (res.data.next.startsWith('http:')) {
                const updatedNext = res.data.next.replace(/^http:/, 'https:');
                setPage(updatedNext);
            } else {
                setPage(res.data.next);
            }
        });
    };

    const onSelectDisciplineWithOpenSpecializations = (discipline) => {
        setSelectedDiscipline(discipline);
        setLoadSpecializations(true);
        // setCourseInput('');

        const updatedDisciplines = disciplines.map((item) => ({
            ...item,
            checked: discipline.slug === item.slug,
        }));
        setDisciplines(updatedDisciplines);
        setCoursePlaceholder(discipline.name)

        const isDisciplineSelected = selectedCourses.some(
            (course) => course.type === "Discipline" && course.slug === discipline.slug
        );

        if (!isDisciplineSelected) {
            // setSelectedCourses([...selectedCourses, discipline]); //Can be used to enable multi select
            setSelectedCourses([discipline]);
        }

        if (disciplineId !== discipline.slug) {
            onArrowDisciplineSpecializations(discipline);
        }

        const element = document.getElementById("couresOpen");
        const element1 = document.getElementById("couresSearchOpen");

        if (element) {
            element.className = "custom-dropdown-menu";
        }

        if (element1) {
            element1.className = "custom-dropdown-menu spellCheck-search";
        }
    };

    const onClickSelectedCoursesDone = () => {
        if (selectedCourses.length > 1) {
            setCoursePlaceholder('')
        }
        $("#coursesModal").modal("hide");
        onFoucusOutCourse()
        if (specializations.length) {
            localStorage.setItem("specialization", true);
            localStorage.removeItem("disciplines");
        }
        if (disciplines.length) {
            localStorage.setItem("disciplines", true);
            localStorage.removeItem("specialization");
        }
        if (disciplines.length && specializations.length) {
            localStorage.removeItem("disciplines");
            localStorage.removeItem("specialization");
        }
    };
    const handleCloseMOdal= ()=>{
        setOpenModal(false)
       }
    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            let obj = { slug: e.target.value, name: e.target.value, type: "custom" };
            let existingObj = selectedCourses.find((element) => {
                return element.slug === obj.slug && element.type === "custom";
            });
            if (!existingObj) {
                // setSelectedCourses([...selectedCourses, obj]);
                setCourseInput([obj.name]);
            }
            onFoucusOutCourse();
            // setCourseInput("");
        }
    };

    const searchCourses = (text) => {
        let element = document.getElementById("couresOpen");
        let element1 = document.getElementById("couresSearchOpen");
        if (!element.className.includes("show")) {
            element.className = "custom-dropdown-menu show";
        }
        if (!element1.className.includes("show")) {
            element1.className = "custom-dropdown-menu spellCheck-search show";
        }
        http.post(
            BASE_URL + `course/spell-check/?spell_check=${text}`).then((resp) => {
                setSpellCheck(resp?.data?.word === text ? {} : resp?.data?.word)
            });
        setCourseInput(text);
        setCoursePlaceholder('');
    };

    const onFoucusCourse = () => {
        var element = document.getElementById("couresOpen");
        var element1 = document.getElementById("couresSearchOpen");
        element.className += " show";
        element1.className += " show";
    };
    const onFoucusCourseArrow = () => {
        var element1 = document.getElementById("couresSearchOpen");
        var element = document.getElementById("couresOpen");
        if (element.className.includes("show") || element1.className.includes("show")) {
            $("#course_input").focusout();
        } else {
            $("#course_input").focus();
        }
    };
    const onFoucusOutCourse = () => {
        setTimeout(() => {
            var element1 = document.getElementById("couresSearchOpen");
            let element = document.getElementById("couresOpen");
            if (element) {
                element.className = "custom-dropdown-menu";
            }
            if (element1) {
                element1.className = "custom-dropdown-menu spellCheck-search";
            }
        }, 200);
    };
    const onFocusDiscipline = () => {
        let element = document.getElementById("disciplineOpen");
        element.className += " show";
    };
    const onFocusDisciplineArrow = () => {
        let element = document.getElementById("disciplineOpen");
        if (element.className.includes("show")) {
            $("#degree_levels_input").focusout();
        } else {
            document.getElementById("degree_levels_input").focus();
        }
    };
    const onFocusOutDiscipline = (e) => {
        setTimeout(() => {
            if (degreeLevel_arrow === true) {
                let element = document.getElementById("disciplineOpen");
                element.className = "custom-dropdown-menu qualificationDrop";
            } else {
                degreeLevel_arrow = true;
                document.getElementById("degree_levels_input").focus();
            }
        }, 400);
    };
    const onDirectFoucusOutDiscipline = (e) => {
        let element = document.getElementById("disciplineOpen");
        element.className = "custom-dropdown-menu";
    };

    const onFoucusLocation = () => {
        setIsLocationFocus(true)
        let element = document.getElementById("locationOpen");
        element.className += " show";
    };
    const onFoucusLocationArrow = () => {
        let element = document.getElementById("locationOpen");
        if (element.className.includes("show")) {
            $("#location_input").focusout();
        } else {
            $("#location_input").focus();
        }
    };
    const onFoucusOutLocation = () => {
        setIsLocationFocus(false)
        setTimeout(() => {
            let element = document.getElementById("locationOpen");
            element.className = "custom-dropdown-menu";
        }, 500);
    };

    const handleSpecializationSearch = (text) => {
        if (text) {
            if (disciplineId) {
                setLoadSpecializations(true);
                setSpecializations([]);

                const url = `discipline/${disciplineId}/specializations/?search=${text}`;
                http.get(url).then((res) => {
                    var specializations1 = res.data.results;
                    setPage(res.data.next);

                    setSpecializations(specializations1);
                    setLoadSpecializations(false);
                });
            } else {
                alert("Select the discipline first");
            }
        }
    };

    const showRegionModal = (type) => {
        if (type === "region") {
            setRegionModal(true);
        } else {
            setRegionModal(false);
        }
        $("#regionModal").modal("show");
    };
    const closeRegionModal = () => {
        setShowCountryDetail(true);
        setShowStateDetail(true);
        $("#regionModal").modal("hide");
        $("#citiesModal").modal("hide");
    };

    let uniqueAddresses;
    const onSelectLocation = (location, type) => {
        let locationObj = {
            id: location.id,
            slug: location.slug,
            name: location.name,
            type: location.type,
        };
        if (type === "Region") {
            setShowRegion(true);
            if (location.type === "Region") {
                let test = [...location.countries];
                if (oldCountries.length) {
                    uniqueAddresses = [...location.countries, ...regionsCountries.map(c => c.checked === false)];
                    const ids = uniqueAddresses.map((o) => o.id);
                    uniqueAddresses = uniqueAddresses.filter(
                        ({ id }, index) => !ids.includes(id, index + 1)
                    );
                } else {
                    setOldCountries([...location.countries]);
                    uniqueAddresses = [...location.countries];
                }
                setRegionsCountries(uniqueAddresses);
                let popularCountriesRegionTempList = popularCountries.map((obj) => {
                    obj.checked = false
                    return obj;
                });
                let countriesRegionTempList = countries.map((countryObj) => {
                    countryObj.states = countryObj.states.map((stateObj) => {
                        stateObj.cities = stateObj.cities.map((obj) => {
                            obj.checked = false;
                            return obj;
                        });
                        stateObj.checked = false;
                        return stateObj;
                    });
                    countryObj.checked = false;
                    return countryObj;
                });
                setCountries(countriesRegionTempList);
                setPopularCountries(popularCountriesRegionTempList);
                let existingRegionCountryObj = test.find((element) => {
                    return element.checked === true;
                });
                if (!existingRegionCountryObj) {
                    var regionsTempList = regions.map((obj) => {
                        if (location.id === obj.id) {
                            selectedLocation.checked = false;
                            obj.checked = true;
                            return obj;
                        } else {
                            obj.checked = false;
                            return obj;
                        }
                    });
                    setRegions(regionsTempList);

                    let existingRegionObj = selectedLocation.find((element) => {
                        return (
                            element.id === locationObj.id && element.type === locationObj.type
                        );
                    });
                    if (!existingRegionObj) {
                        setSelectedLocation([...selectedLocation, locationObj]);
                    }
                }
            }
            else if (location.type === "Country") {

                let region;
                for (let i = 0; i < regions.length; i++) {
                    let RegionsCountries = regions[i].countries;
                    for (let j = 0; j < RegionsCountries.length; j++) {
                        if (RegionsCountries[j].id === location.id) {
                            region = regions[i];
                            break;
                        }
                    }
                }
                let index = -1;
                for (let i = 0; i < selectedLocation.length; i++) {
                    if (
                        selectedLocation[i].id === region.id &&
                        selectedLocation[i].type === region.type
                    ) {
                        index = i;
                        break;
                    }
                }
                if (index !== -1) {
                    let regionsCheckTempList = regions.map((obj) => {
                        if (region.id === obj.id) {
                            obj.checked = false;
                            return obj;
                        } else {
                            obj.checked = false;
                            return obj;
                        }
                    });

                    setRegions(regionsCheckTempList);
                    setSelectedLocation([...selectedLocation]);
                }
                let popularCountriesRegionTempList = popularCountries.map((obj) => {

                    if (location.id === obj.id) {
                        obj.checked = true;
                        return obj;
                    } else {
                        obj.checked = false;
                        return obj;
                    }
                });
                setPopularCountries(popularCountriesRegionTempList);

                let countriesRegionTempList = countries.map((obj) => {
                    if (location.id === obj.id) {
                        obj.checked = true;
                        return obj;
                    } else {
                        obj.checked = false;
                        return obj;
                    }
                });

                setCountries(countriesRegionTempList);
                let regionsCountriesTempList = regions.map((obj) => {
                    let updateRegionCountryList = false;
                    let CountriesTempList = obj.countries.map((country) => {
                        if (country.id === location.id) {
                            updateRegionCountryList = true;
                            country.checked = true;
                            return country;
                        }
                        else {
                            country.checked = false;
                            return country;
                        }
                    });
                    if (updateRegionCountryList) {
                        setRegionsCountries(CountriesTempList);
                    }
                    setRegions(regionsTempList);
                    obj.countries = CountriesTempList;
                    return obj;
                });
                setRegions(regionsCountriesTempList);

                var existingCountryObj = selectedLocation.find((element) => {
                    return (
                        element.id === locationObj.id && element.type === locationObj.type
                    );
                });
                if (!existingCountryObj) {
                    setSelectedLocation([...selectedLocation, locationObj]);
                }
            }
        }
        if (type === "all_country") {
            if (location.type === "Country") {
                var existingallCountryObj = selectedLocation.find((element) => {
                    return (
                        element.id === locationObj.id && element.type === locationObj.type
                    );
                });
                if (!existingallCountryObj) {
                    var countryAddStatus = true;
                    for (let i = 0; i < location.states?.length; i++) {
                        var tempState = location.states[i];
                        if (tempState?.checked === true) {
                            countryAddStatus = false;
                            break;
                        }
                        for (let j = 0; j < tempState.cities.length; j++) {
                            var tempCity = tempState.cities[i];
                            if (tempCity?.checked === true) {
                                countryAddStatus = false;
                                break;
                            }
                        }
                    }
                    if (countryAddStatus) {
                        setSelectedLocation([...selectedLocation, locationObj]);
                        var countriesTempList = countries.map((obj) => {
                            if (location.id === obj.id) {
                                obj.checked = true;
                                return obj;
                            } else {
                                obj.checked = false;
                                return obj;
                            }
                        });
                        setCountries(countriesTempList);
                        var popularCountriesTempList = popularCountries.map((obj) => {
                            if (location.id === obj.id) {
                                obj.checked = true;
                                return obj;
                            } else {
                                obj.checked = false;
                                return obj;
                            }
                        });

                        setPopularCountries(popularCountriesTempList);
                        var CountryregionsCountriesTempList = regions.map((obj) => {
                            var updateRegionCountryList = false;
                            var CountriesTempList = obj.countries.map((country) => {
                                if (country.id === location.id) {
                                    updateRegionCountryList = false;
                                    country.checked = false;
                                    return country;
                                }
                                else {
                                    country.checked = false;
                                    return country;
                                }
                            });
                            if (updateRegionCountryList) {
                                setRegionsCountries(CountriesTempList);
                            }
                            setRegions(regionsTempList);
                            obj.countries = CountriesTempList;
                            return obj;
                        });
                        setRegions(CountryregionsCountriesTempList);
                    }
                }
                let regionsList = regions.map((obj) => {
                    obj.checked = false;
                    return obj;
                });
                let regionsCountriesList = regionsCountries.map((obj) => {
                    obj.checked = false;
                    return obj;
                });
                setRegions(regionsList)
                setRegionsCountries(regionsCountriesList);
            }
            if (location.type === "State") {
                var countryIndex = -1;
                for (let i = 0; i < selectedLocation.length; i++) {
                    if (
                        selectedLocation[i].id === location.country_id &&
                        selectedLocation[i].type === "Country"
                    ) {
                        countryIndex = i;
                        break;
                    }
                }

                if (countryIndex !== -1) {
                    var stateCountriesTempList = countries.map((obj) => {
                        if (selectedLocation[countryIndex].id === obj.id) {
                            obj.checked = true;
                            return obj;
                        } else {
                            obj.checked = false;
                            return obj;
                        }
                    });
                    setCountries(stateCountriesTempList);
                    var item = selectedLocation.splice(countryIndex, 1)[0];
                    setSelectedLocation([...item]);
                }
                popularCountriesTempList = popularCountries.map((obj) => {
                    if (location.country_id === obj.id) {
                        obj.checked = true;
                        return obj;
                    } else {
                        obj.checked = false;
                        return obj;
                    }
                });
                setPopularCountries(popularCountriesTempList);
                var existingallCountryStateObj = selectedLocation.find((element) => {
                    return (
                        element.id === locationObj.id && element.type === locationObj.type
                    );
                });
                if (!existingallCountryStateObj) {
                    var stateAddStatus = true;
                    for (let i = 0; i < location.cities.length; i++) {
                        var tempStateCity = location.cities[i];
                        if (tempStateCity?.checked === true) {
                            stateAddStatus = false;
                            break;
                        }
                    }
                    if (stateAddStatus) {
                        setSelectedLocation([...selectedLocation, locationObj]);
                        var countriesWithStatesTempList = countries.map((countryObj) => {
                            countryObj.states = countryObj.states.map((obj) => {
                                if (location.id === obj.id) {
                                    obj.checked = true;
                                    return obj;
                                } else {
                                    obj.checked = false;
                                    return obj;
                                }
                            });
                            return countryObj;
                        });
                        setCountries(countriesWithStatesTempList);
                    }
                }
                let regionsCountriesList = regionsCountries.map((obj) => {
                    obj.checked = false;
                    return obj;
                });
                let regionsList = regions.map((obj) => {
                    obj.checked = false;
                    return obj;
                });
                setRegions(regionsList)
                setRegionsCountries(regionsCountriesList);
            }

            if (location.type === "City") {
                var cityCountryIndex = -1;
                for (let i = 0; i < selectedLocation.length; i++) {
                    if (
                        selectedLocation[i].id === location.country_id &&
                        selectedLocation[i].type === "Country"
                    ) {
                        cityCountryIndex = i;
                        break;
                    }
                }

                if (cityCountryIndex !== -1) {
                    var citiesCountriesTempList = countries.map((obj) => {
                        if (selectedLocation[cityCountryIndex].id === obj.id) {
                            obj.checked = true;
                            return obj;
                        } else {
                            obj.checked = false;
                            return obj;
                        }
                    });
                    setCountries(citiesCountriesTempList);
                    var item = selectedLocation.splice(cityCountryIndex, 1)[0];
                    setSelectedLocation([...item]);
                }
                popularCountriesTempList = popularCountries.map((obj) => {
                    if (location.country_id === obj.id) {
                        obj.checked = true;
                        return obj;
                    } else {
                        obj.checked = false;
                        return obj;
                    }
                });
                setPopularCountries(popularCountriesTempList);
                var cityStateIndex = -1;
                for (let i = 0; i < selectedLocation.length; i++) {
                    if (
                        selectedLocation[i].id === location.state_id &&
                        selectedLocation[i].type === "State"
                    ) {
                        cityStateIndex = i;
                        break;
                    }
                }
                if (cityStateIndex !== -1) {
                    var citiesCountriesStatesTempList = countries.map((country) => {
                        country.states = country.states.map((state) => {
                            if (selectedLocation[cityStateIndex].id === state.id) {
                                state.checked = true;
                                return state;
                            } else {
                                state.checked = false;
                                return state;
                            }
                        });
                        return country;
                    });
                    setCountries(citiesCountriesStatesTempList);
                    setSelectedLocation([...selectedLocation]);
                }
                let existingallCountryCityObj = selectedLocation.find((element) => {
                    return (
                        element.id === locationObj.id && element.type === locationObj.type
                    );
                });
                if (!existingallCountryCityObj) {
                    setSelectedLocation([...selectedLocation, locationObj]);
                    let countriesWithStatesCitiesTempList = countries.map(
                        (countryObj) => {
                            countryObj.states = countryObj.states.map((stateObj) => {
                                stateObj.cities = stateObj.cities.map((cityObj) => {
                                    if (cityObj.id === location.id) {
                                        cityObj.checked = true;
                                        return cityObj;
                                    } else {
                                        cityObj.checked = false;
                                        return cityObj;
                                    }
                                });
                                return stateObj;
                            });
                            return countryObj;
                        }
                    );
                    setCountries(countriesWithStatesCitiesTempList);
                }
                let regionsCountriesList = regionsCountries.map((obj) => {
                    obj.checked = false;
                    return obj;
                });
                setRegionsCountries(regionsCountriesList);
                let regionsList = regions.map((obj) => {
                    obj.checked = false;
                    return obj;
                });
                setRegions(regionsList)
            }
        }
    };
    const onSelectSuggestedLocation = (location, type) => {
        let localTypes = ["Country", "City", "State", "Region"];
        if (selectedLocation.length) {
            let instance = selectedLocation[0];
            if (localTypes.indexOf(instance.type) === -1) {
                setLocationType(type);
                setTempSuggestedLocation(location);
                setConfirmDialogHeading("Location");
                setConfirmDialogContent(
                    "All pre selected Location will be remove Results will be according to Selected Locations .Please confirm to proceed next or Cancel."
                );
                setConfirmDialog(true);
            } else {
                let locationObj = {
                    id: location.id,
                    slug: location.slug,
                    name: location.name,
                    type: location.type,
                };
                let existingObj = selectedLocation.find((element) => {
                    return (
                        element.id === locationObj.id && element.type === locationObj.type
                    );
                });
                if (!existingObj) {

                    setSelectedLocation([...selectedLocation, locationObj]);
                }
                setLocationInput("");
            }
        } else {
            let locationObj = {
                id: location.id,
                slug: location.slug,
                name: location.name,
                type: location.type,
                country_name: location.country_name,
                city_name: location.city_name,
                state_name: location.state_name,
            };
            setSelectedLocation([...selectedLocation, locationObj]);
            setLocationInput("");
        }
        onFoucusOutLocation();
        setLocationError("")
    };

    const onLocationRemove = (index, regionName) => {
        if (regionName) {
            const object = regions.find((element) => element.id === regionName.id);

            if (object) {
                const countriesIDs = object?.countries?.map((o) => o.id);
                const updatedRegionsCountries = regionsCountries.filter(
                    (country) => !countriesIDs.includes(country.id)
                );
                setRegionsCountries(updatedRegionsCountries);

                if (updatedRegionsCountries.length <= 0) {
                    setRegionsCountries(allRegionsCountries);
                    setOldCountries([]);
                }
            }
        }

        onFoucusOutLocation();

        const removedObj = selectedLocation[index];

        if (
            removedObj.type === MAIN_SEARCH_LOCATION_TYPES[1] ||
            removedObj.type === MAIN_SEARCH_LOCATION_TYPES[0]
        ) {
            setLocationInput("");
            setSelectedLocation([...selectedLocation]);
        } else {
            if (removedObj.type === "Region") {
                const regionsTempList = regions.map((obj) => ({
                    ...obj,
                    checked: removedObj.id === obj.id ? false : obj.checked,
                }));
                setRegions(regionsTempList);
            } else if (removedObj.type === "Country") {
                const updatedRegionsCountriesTempList = regions.map((obj) => {
                    const CountriesTempList = obj.countries.map((country) => ({
                        ...country,
                        checked: removedObj.id === country.id ? false : country.checked,
                    }));
                    return { ...obj, countries: CountriesTempList };
                });
                setRegions(updatedRegionsCountriesTempList);

                const updatedCountriesTempList = countries.map((obj) => ({
                    ...obj,
                    checked: removedObj.id === obj.id ? false : obj.checked,
                }));
                setCountries(updatedCountriesTempList);

                const updatedPopularCountriesTempList = popularCountries.map((obj) => ({
                    ...obj,
                    checked: removedObj.id === obj.id ? false : obj.checked,
                }));
                setPopularCountries(updatedPopularCountriesTempList);
            } else if (removedObj.type === "State" || removedObj.type === "City") {
                const updatedCountriesWithStatesTempList = countries.map((countryObj) => ({
                    ...countryObj,
                    states: countryObj.states.map((stateObj) => ({
                        ...stateObj,
                        cities:
                            removedObj.type === "City"
                                ? stateObj.cities.map((cityObj) => ({
                                    ...cityObj,
                                    checked: removedObj.id === cityObj.id ? false : cityObj.checked,
                                }))
                                : stateObj.cities,
                        checked: removedObj.id === stateObj.id ? false : stateObj.checked,
                    })),
                }));
                setCountries(updatedCountriesWithStatesTempList);
            }

            setSelectedLocation([...selectedLocation.slice(0, index), ...selectedLocation.slice(index + 1)]);
        }
    };

    const clearLocationInput = () => {
        setSelectedLocation([]);
        setLocationInput("");
    };

    const handleCountriesSearch = (text) => {
        setCountryInput(text);
        if (text) {
            const filteredData = allRegionsCountries.filter((obj) => {
                const name = obj["name"].replace(/[^a-z0-9,. ]/gi, "");
                const formattedText = text.toLowerCase();

                return (
                    name.toLowerCase().includes(formattedText) ||
                    name.toUpperCase().includes(formattedText)
                );
            });
            setCountries(filteredData);
            setRegionsCountries(filteredData);
        } else {
            setCountries(allRegionsCountries);
            setRegionsCountries(allRegionsCountries);
            setOldCountries([]);
        }
    };


    const onClickCountryDetail = (states) => {
        setStates(states);
        setfilteredStates(states);
        setShowCountryDetail(false);
    };

    const onClickCountryDetailBack = () => {
        setStates([]);
        setShowCountryDetail(true);
    };
    const handleStatesSearch = (text) => {
        setStateInput(text);
        if (text) {
            const formattedText = text.toLowerCase();
            const filteredData = states.filter((obj) => {
                const name = obj["name"].replace(/[^a-z0-9,. ]/gi, "").toLowerCase();
                return name.includes(formattedText);
            });

            setfilteredStates(filteredData);
        } else {
            setfilteredStates(states);
        }
    };

    const onClickStateDetail = (cities) => {
        setCities(cities);
        setFilterCities(cities);
        setShowStateDetail(false);
    };
    const onClickStateDetailBack = () => {
        setCities([]);
        setShowStateDetail(true);
    };

    const handleCitiesSearch = (text) => {
        setCityInput(text);
        if (text) {
            const formattedText = text.toLowerCase();
            const filteredData = cities.filter((obj) => {
                const name = obj["name"].replace(/[^a-z0-9,. ]/gi, "").toLowerCase();

                return name.includes(formattedText);
            });
            setFilterCities(filteredData);
        } else {
            setFilterCities(popularCitiesList);
        }
    };

    const handlePopularCitiesSearch = (text) => {
        setCityInput(text);

        if (text) {
            const formattedText = text.toLowerCase();
            const filteredData = popularCitiesList.filter((obj) => {
                const name = obj["name"].replace(/[^a-z0-9,. ]/gi, "").toLowerCase();

                return name.includes(formattedText);
            });

            setFilterCities(filteredData);
            setPopularCities(filteredData);
        } else {
            setPopularCities(popularCitiesList);
            setFilterCities(popularCitiesList);
        }
    };

    const handleDialogConfirmClose = () => {
        setConfirmDialog(true);
    };
    const handleDialogConfirm = () => {
        setSelectedLocation([]);
        setConfirmDialog(false);
        setLocationInput("");
        if (MAIN_SEARCH_LOCATION_TYPES[0] === locationType) {
            $("#currentLocation").modal("show");
        } else if (MAIN_SEARCH_LOCATION_TYPES[1] === locationType) {
            $("#autoLocation").modal("show");
        } else if ("Region" === locationType) {
            setRegionModal(true);
            $("#regionModal").modal("show");
        } else if ("Country" === locationType) {
            setRegionModal(false);
            $("#regionModal").modal("show");
        } else if ("State" === locationType) {
            setRegionModal(false);
            $("#regionModal").modal("show");
        } else if ("City" === locationType) {
            $("#citiesModal").modal("show");
        } else if (MAIN_SEARCH_LOCATION_TYPES[3] === locationType) {
            setSelectedLocation([{ type: MAIN_SEARCH_LOCATION_TYPES[3], slug: MAIN_SEARCH_LOCATION_TYPES[3], name: "Any" }]);
            setLocationInput("");
        } else if (MAIN_SEARCH_LOCATION_TYPES[4] === locationType) {
            setSelectedLocation([tempSuggestedLocation]);
        }
    };
    const handleDialogConfirmCancel = () => {
        setConfirmDialog(false);
    };
    const handleDialogConfirmOpen = (type, subtype) => {
        setLocationType(type);
        if (type === MAIN_SEARCH_LOCATION_TYPES[0]) {
            if (selectedLocation.length) {
                if (selectedLocation[0].type === MAIN_SEARCH_LOCATION_TYPES[0]) {
                    $("#currentLocation").modal("show");
                } else {
                    setConfirmDialogHeading("");
                    setConfirmDialogContent(
                        "All pre selected Location will be remove Results will be according to your current Location .Please confirm to proceed next or Cancel."
                    );
                    setConfirmDialog(true);
                }
            } else {
                $("#currentLocation").modal("show");
            }
        }
        if (type === MAIN_SEARCH_LOCATION_TYPES[1]) {
            if (selectedLocation.length) {
                if (selectedLocation[0].type === MAIN_SEARCH_LOCATION_TYPES[1]) {
                    $("#autoLocation").modal("show");
                } else {
                    setConfirmDialogHeading("Enter a Town/ City or Postcode");
                    setConfirmDialogContent(
                        "All pre selected Location will be remove if you Select Town, City or any Location from google.Please confirm to proceed next or Cancel."
                    );
                    setConfirmDialog(true);
                }
            } else {
                $("#autoLocation").modal("show");
            }
        }
        if (type === MAIN_SEARCH_LOCATION_TYPES[2]) {
            let localTypes = ["Country", "City", "State", "Region"];
            if (selectedLocation.length) {
                let instance = selectedLocation[0];
                if (localTypes.indexOf(instance.type) === -1) {
                    setLocationType(subtype);
                    setConfirmDialogHeading(subtype);
                    setConfirmDialogContent(
                        "All pre selected Location will be remove.Please confirm to proceed next or Cancel."
                    );
                    setConfirmDialog(true);
                } else {
                    if (subtype === "Region") {
                        setRegionModal(true);
                        setShowRegion(true);
                        $("#regionModal").modal("show");
                    }
                    if (subtype === "Country") {
                        setRegionModal(false);
                        setShowRegion(false);
                        $("#regionModal").modal("show");
                    }
                    if (subtype === "State") {
                        setShowRegion(false);
                        setRegionModal(false);
                        $("#regionModal").modal("show");
                    }
                    if (subtype === "City") {
                        $("#citiesModal").modal("show");
                    }
                }
            } else {
                if (subtype === "Region") {
                    setRegionModal(true);
                    setShowRegion(true);
                    $("#regionModal").modal("show");
                }
                if (subtype === "Country") {
                    setShowRegion(false);
                    setRegionModal(false);
                    $("#regionModal").modal("show");
                }
                if (subtype === "State") {
                    setShowRegion(false);
                    setRegionModal(false);
                    $("#regionModal").modal("show");
                }
                if (subtype === "City") {
                    $("#citiesModal").modal("show");
                }
            }
        }
        if (type === MAIN_SEARCH_LOCATION_TYPES[3]) {
            if (selectedLocation.length) {
                if (selectedLocation[0].type === MAIN_SEARCH_LOCATION_TYPES[3]) {
                    setSelectedLocation([{ type: MAIN_SEARCH_LOCATION_TYPES[3], slug: MAIN_SEARCH_LOCATION_TYPES[3], name: "Any" }]);
                    setLocationInput("");
                } else {
                    setLocationType(type);
                    setConfirmDialogHeading("Any where in The world");
                    setConfirmDialogContent(
                        "All pre selected Location will be remove.Results will be from all over The world.Please confirm to proceed next or Cancel."
                    );
                    setConfirmDialog(true);
                }
            } else {
                setSelectedLocation([{ type: MAIN_SEARCH_LOCATION_TYPES[3], slug: MAIN_SEARCH_LOCATION_TYPES[3], name: "Any" }]);
                setLocationInput("");
            }
        }
        setLocationError("")
    };

    const handleAutoLocation = (location) => {
        setAutoAddress(location.address);
        setAutoLatitude(location.coordinates.lat);
        setAutoLongitude(location.coordinates.lng);
    };
    const handleDistanceSelectChange = (selectedOption) => {
        setDistance(selectedOption);
        setDistanceError("");
    };
    const handleAutoDistanceSelectChange = (selectedOption) => {
        setAutoDistance(selectedOption);
        setAutoDistanceError("");
    };
    const genericLocationSubmitHandler = (e) => {
        e.preventDefault();
        if (!e.target.autodistance.value) {
            setAutoDistanceError("distance error");
        } else {
            let value = parseInt(e.target.autodistance.value);
            let label = e.target.autodistance.value + " Km";
            setAutoDistance({ value: value, label: label });
        }
        if (!autoLatitude && !autoLongitude) {
            setAutoLocationError("must choose location");
        } else {
            let location_instance = {
                type: MAIN_SEARCH_LOCATION_TYPES[1],
                longitude: autoLongitude,
                latitude: autoLatitude,
                address: autoAddress,
                distance: autoDistance.value,
                name: autoDistance.label,
            };
            setSelectedLocation([location_instance]);
            setLocationInput(autoAddress);
            $("#autoLocation").modal("hide");
        }
    };
    const handleCurrentLocation = (location) => {
        setAddress(location.address);
        setLatitude(location.lat);
        setLongitude(location.lng);
    };
    const currentLocationSubmitHandler = (e) => {
        e.preventDefault();
        if (!e.target.distance.value) {
            setDistanceError("distance error");
        } else {
            let value = parseInt(e.target.distance.value);
            let label = e.target.distance.value + " Km";
            setDistance({ value: value, label: label });
        }
        if (!latitude && !longitude) {
            alert("allow permission to access location");
        } else {
            let location_instance = {
                type: MAIN_SEARCH_LOCATION_TYPES[0],
                longitude: longitude,
                latitude: latitude,
                address: address,
                distance: distance.value,
                name: distance.label,
            };
            setSelectedLocation([location_instance]);

            setLocationInput(address);
            $("#currentLocation").modal("hide");
        }
    };

    let cancelToken;

    const searchLocations = async (e) => {
        if (e.key === "Enter") {
            let element = document.getElementById("locationOpen");
            if (!element.className.includes("show")) {
                element.className = "custom-dropdown-menu show";
            }

            setLocationInput(e.target.value);
            if (typeof cancelToken !== typeof undefined) {
                cancelToken.cancel("Operation canceled due to new request.")
            }
            cancelToken = axios.CancelToken.source()
            try {
                const results = await axios.get(
                    BASE_URL + `location-auto-suggestion?search=${e.target.value}`,
                    { cancelToken: cancelToken.token }
                )
                let fuse = new Fuse(results.data.results, options);
                let newArray = fuse.search(e.target.value);
                if (newArray.length) {
                    setSelectedLocation([...selectedLocation, newArray[0]?.item])
                    setLocationInput("");
                    setSuggestedLocation([]);
                } else {
                    setSuggestedLocation([]);
                }

            } catch (error) {
                setSelectedLocation([]);
            }
            locationRef.current.blur();
            onFoucusOutLocation();
        } else {
            let element = document.getElementById("locationOpen");
            if (!element.className.includes("show")) {
                element.className = "custom-dropdown-menu show";
            }
            let currentValue = e.currentTarget.value;
            setLocationInput(currentValue);
            if (currentValue === null) {
                setSuggestedLocation([]);
            } else {
                if (typeof cancelToken !== typeof undefined) {
                    cancelToken.cancel("Operation canceled due to new request.")
                }
                cancelToken = axios.CancelToken.source()
                try {
                    const results = await axios.get(
                        BASE_URL + `location-auto-suggestion?search=${e.target.value}`,
                        { cancelToken: cancelToken.token }
                    )
                    if (results.data.results.length) {
                        setEmptyResult("")
                    } else {
                        setEmptyResult("No match");

                    }
                    setSuggestedLocation(results.data.results)
                } catch (error) {
                    setSuggestedLocation([]);
                }
            }

        }
    };
    // degree Levels
    const arrowUpDownClick = (e) => {
        degreeLevel_arrow = false;
    };
    const updateActiveStatus = (item, status) => {
        const updatedLevels = degreeLevels.map((obj) => ({
            ...obj,
            active: obj.slug === item.slug,
        }));

        setDegreeLevelsOptions(groupByDegreeLevels(updatedLevels));
        setDegreeLevels(updatedLevels);
        setDegreeLevelInput("");
        setDegreeLevelSearch(false);
    };

    const onSelectUndergraduate = (e) => {
        setDegreeLevelPlaceholder("Undergraduate")

        if (!undergraduate && degreeLevel_arrow) {
            const obj = { id: -1, name: "Undergraduate", slug: 'undergraduate' };

            setUndergraduate(true);
            setPostgraduate(false);
            setResearch(false);

            selectedDegreeLevel.filter(obj => obj.level_type !== "undergraduate");
            setSelectedDegreeLevel([obj]);

            const updatedDegreeLevels = degreeLevels.map(item => ({
                ...item,
                active: item.level_type === "undergraduate"
            }));

            setDegreeLevelsOptions(groupByDegreeLevels(updatedDegreeLevels));
            setDegreeLevels(updatedDegreeLevels);
            setDegreeLevelSearch(false);
            onDirectFoucusOutDiscipline();
        }
        setSelectedDegreeLevelError("")
    };

    const onSelectPostgraduate = (e) => {
        e.preventDefault();
        setDegreeLevelPlaceholder("Postgraduate")
        if (!postgraduate && degreeLevel_arrow) {
            const obj = { id: -2, name: "Postgraduate", slug: 'postgraduate' };

            setPostgraduate(true);
            setUndergraduate(false);
            setResearch(false);

            selectedDegreeLevel.filter(obj => obj.level_type !== "postgraduate");
            setSelectedDegreeLevel([obj]);

            const updatedDegreeLevels = degreeLevels.map(item => ({
                ...item,
                active: item.level_type === "postgraduate"
            }));

            setDegreeLevelsOptions(groupByDegreeLevels(updatedDegreeLevels));
            setDegreeLevels(updatedDegreeLevels);
            setDegreeLevelSearch(false);
        }
        setSelectedDegreeLevelError("")
    };

    const onSelectResearch = (e) => {
        e.preventDefault();
        setDegreeLevelPlaceholder("Postgraduate by Research")
        if (!research && degreeLevel_arrow) {
            const obj = { id: -3, name: "Postgraduate by Research", slug: 'postgraduate_by_research' };

            setResearch(true);
            setPostgraduate(false);
            setUndergraduate(false);

            selectedDegreeLevel.filter(obj => obj.level_type !== "postgraduate_by_research");
            setSelectedDegreeLevel([obj]);

            const updatedDegreeLevels = degreeLevels.map(item => ({
                ...item,
                active: item.level_type === "postgraduate_by_research"
            }));

            setDegreeLevelsOptions(groupByDegreeLevels(updatedDegreeLevels));
            setDegreeLevels(updatedDegreeLevels);
            setDegreeLevelSearch(false);
        }
        setSelectedDegreeLevelError("")
    };

    const onSelectDegreeLevel = (item, status) => {
        setDegreeLevelPlaceholder(item.name)
        degreeLevel_arrow = true;
        if (!status) {
            updateActiveStatus(item, true);
            setSelectedDegreeLevel([item]);
            onDirectFoucusOutDiscipline();
        }
        if (item.level_type === "undergraduate") {
            setResearch(false);
            setPostgraduate(false);
            setUndergraduate(true);
        }
        else if (item.level_type === "postgraduate") {
            setResearch(false);
            setPostgraduate(true);
            setUndergraduate(false);
        }
        else if (item.level_type === "postgraduate_by_research") {
            setResearch(true);
            setPostgraduate(false);
            setUndergraduate(false);
        }
        setSelectedDegreeLevelError("")
    };
    const onRemoveDegreeLevel = (index) => {
        onDirectFoucusOutDiscipline();
        setSelectedDegreeLevel([...selectedDegreeLevel]);

        degreeLevels.map((item) => {
            if (item.level_type === "undergraduate") {
                degreeLevelsOptions.undergraduate.map((i) => (i.slug === item.slug) ? i.active = false : "")
                setDegreeLevelsOptions(degreeLevelsOptions)
                item.active = false;
                delete item["active"];
                setUndergraduate(false);
            }
            return item;
        });
        setDegreeLevelsOptions(groupByDegreeLevels(degreeLevels));
        setDegreeLevels(degreeLevels);

        degreeLevels.map((item) => {
            if (item.level_type === "postgraduate") {
                degreeLevelsOptions.postgraduate.map((i) => (i.slug === item.slug) ? i.active = false : "")
                setDegreeLevelsOptions(degreeLevelsOptions)
                delete item["active"];
                item.active = false;
                setPostgraduate(false);
            }
            return item;
        });
        setDegreeLevelsOptions(groupByDegreeLevels(degreeLevels));
        setDegreeLevels(degreeLevels);

        setResearch(false);
        degreeLevels.map((item) => {
            if (item.level_type === "postgraduate_by_research") {
                degreeLevelsOptions.postgraduate_by_research.map((i) => (i.slug === item.slug) ? i.active = false : "")
                setDegreeLevelsOptions(degreeLevelsOptions)
                delete item["active"];
                item.active = false;
            }
            return item;
        });
        setDegreeLevelsOptions(groupByDegreeLevels(degreeLevels));
        setDegreeLevels(degreeLevels);

    };
    const clearDegreeLevel = () => {
        setSelectedDegreeLevel([]);
        degreeLevels.map((item) => {
            delete item["active"];
            item.active = false;
            return item;
        });
        setDegreeLevelsOptions(groupByDegreeLevels(degreeLevels));
        setDegreeLevels(degreeLevels);
        setPostgraduate(false);
        setUndergraduate(false);
        setResearch(false);
        setDegreeLevelSearch({});
    };
    const onClickViewMoreDegreeLevels = () => {
        var element = document.getElementById("disciplineOpen");
        element.className += " show";
    };

    const groupByDegreeLevels = (result) => {
        return result.reduce((item, { name, id, active, level_type, slug }) => {
            if (!item[level_type]) item[level_type] = [];
            item[level_type].push({
                id: id,
                active: active,
                name: name,
                level_type: level_type,
                slug: slug
            });
            return item;
        }, {});
    };
    const searchDegreeLevels = (event) => {
        const element = document.getElementById("disciplineOpen");
        if (!element.className.includes("show")) {
            element.className = "custom-dropdown-menu show";
        }

        setDegreeLevelInput(event.target.value);

        const fuse = new Fuse(degreeLevels, options);
        const newFuse = new Fuse(degreeTypes, options);
        const newFuseResults = newFuse.search(event.target.value);

        if (event.key === "Enter" && newFuseResults.length) {
            const itemName = newFuseResults[0].item.name;
            if (itemName === "undergraduate") {
                onSelectUndergraduate();
            } else if (itemName === "postgraduate") {
                onSelectPostgraduate();
            } else if (itemName === "postgraduate_by_research") {
                onSelectResearch();
            }
        }

        const fuseResults = fuse.search(event.target.value);
        if (fuseResults.length) {
            setSelectedDegreeLevel([fuseResults[0]?.item]);
            setDegreeLevelInput("");
        }

        const filteredData = degreeLevels.filter(obj => {
            const name = obj.name.replace(/[^a-z0-9,. ]/gi, "");
            const searchValue = event.target.value.toLowerCase();
            const normalizedName = name.toLowerCase();

            return normalizedName.includes(searchValue);
        });

        setDegreeLevelSearch(Boolean(event.target.value));
        setDegreeLevelsOptions(groupByDegreeLevels(filteredData));
    };


    function onAdvanceFilters() {
        userCount = userCount + 1;
        if (localStorage.getItem("user_Id")) {
            userCount = 0;
        } else {
            if (localStorage.getItem("userStay")) {
                if (parseInt(localStorage.getItem("userStay")) !== 0) {
                    userCount = userCount + 1;
                }

            }
        }
        localStorage.setItem("Advance", true);
        localStorage.removeItem("previous");
        localStorage.removeItem("upper_range");
        localStorage.removeItem("lower_range");
        localStorage.removeItem("popularity");
        const ParamObject = { view: "grid", advance: true };
        history.push({
            pathname: "/search",
            search: `advance=true`,
            query: ParamObject,
            state: { course: selectedCourses, mainSearch: true },
        });
    }

    function onSubmit(id) {
        let element = document.getElementById("locationOpen");
        element.className = "custom-dropdown-menu";
        userCount = userCount + 1;
        if (localStorage.getItem("user_Id")) {
            userCount = 0;
        } else if (selectedCourses.length > 0 || courseInput !== "") {
            if (localStorage.getItem("userStay")) {
                if (parseInt(localStorage.getItem("userStay")) !== 0) {
                    userCount = userCount + 1;
                }
            }
        }
        let formValid = false;
        const ParamObject = { view: id };
        if (selectedCourses.length === 0 && courseInput === "") {
            setCourseError("Course Name is required");
        } else if (selectedDegreeLevel.length === 0) { setSelectedDegreeLevelError('Degree level is required') } else if (selectedLocation.length === 0) { setLocationError('location is required') } else {
            formValid = true;
            ParamObject.search = [];
            ParamObject.course = [];
            if (courseInput !== "") {
                ParamObject.search = courseInput;
            }
            if (selectedCourses.length) {
                let disciplines = [];
                let specializations = [];

                for (let i = 0; i < selectedCourses.length; i++) {
                    if (selectedCourses[i]["type"] === "Discipline") {
                        disciplines.push(selectedCourses[i]["slug"]);
                    }
                    if (selectedCourses[i]["type"] === "Specialization") {
                        specializations.push(selectedCourses[i]["name"].replaceAll(' ', '-'));
                        // ParamObject.course.push(selectedCourses[i]["name"].replaceAll(' ', '-'));
                        ParamObject.course.push(selectedCourses[i]["slug"]);
                    }
                    if (selectedCourses[i]["type"] === "custom") {
                        ParamObject.course.push(selectedCourses[i]["slug"]);
                    }
                }
                if (disciplines.length) {
                    ParamObject.discipline = disciplines;
                }
                if (disciplines.length) {
                    ParamObject.discip = true;
                } else if (specializations.length) {
                    ParamObject.special = true;
                } else if (specializations.length && disciplines.length) {
                    ParamObject.discip = true;
                    ParamObject.special = true;
                }
            }

            if (selectedDegreeLevel.length) {
                var degree_levels = [];
                var degree_level_types = [];

                for (let i = 0; i < selectedDegreeLevel.length; i++) {
                    if (selectedDegreeLevel[i]["id"] === -1) {
                        degree_level_types.push("undergraduate");
                        for (let j = 0; j < degreeLevelsOptions.undergraduate.length; j++) {
                            degree_levels.push(degreeLevelsOptions.undergraduate[j].slug)
                        }
                    } else if (selectedDegreeLevel[i]["id"] === -2) {
                        degree_level_types.push("postgraduate");
                        for (let j = 0; j < degreeLevelsOptions.postgraduate.length; j++) {
                            degree_levels.push(degreeLevelsOptions.postgraduate[j].slug)
                        }
                    } else if (selectedDegreeLevel[i]["id"] === -3) {
                        degree_level_types.push("postgraduate_by_research");
                        for (let j = 0; j < degreeLevelsOptions.postgraduate_by_research.length; j++) {
                            degree_levels.push(degreeLevelsOptions.postgraduate_by_research[j].slug)
                        }
                    } else {
                        degree_levels.push(selectedDegreeLevel[i]["slug"]);
                    }
                }
                if (degree_levels.length) {
                    ParamObject.levels = degree_levels;
                }
                if (degree_level_types.length) {
                    ParamObject.types = degree_level_types;
                }
            }

            if (selectedLocation.length) {
                let regions = [];
                let country = [];
                let states = [];
                let cities = [];
                for (let i = 0; i < selectedLocation.length; i++) {
                    if (selectedLocation[i]["type"] === "Region") {
                        regions.push(selectedLocation[i]["slug"]);
                    }
                    if (selectedLocation[i]["type"] === "Country") {
                        country.push(selectedLocation[i]["slug"]);
                    }
                    if (selectedLocation[i]["type"] === "State") {
                        states.push(selectedLocation[i]["slug"]);
                    }
                    if (selectedLocation[i]["type"] === "City") {
                        cities.push(selectedLocation[i]["slug"]);
                        if (selectedLocation[0]?.state_name) {
                            localStorage.location_search = `${selectedLocation[0].country_name + '_'}${selectedLocation[0].state_name + '_'}${selectedLocation[0].city_name}`
                        }
                    }
                    if (
                        selectedLocation[i]["type"] === "current_location" ||
                        selectedLocation[i]["type"] === "auto_location"
                    ) {
                        ParamObject.lat = selectedLocation[i].latitude;
                        ParamObject.lng = selectedLocation[i].longitude;
                        ParamObject.dst = selectedLocation[i].distance;
                        if (selectedLocation[i]["type"] === "current_location") {
                            ParamObject.sld = "curL";
                        } else if (selectedLocation[i]["type"] === "auto_location") {
                            ParamObject.sld = "autL";
                        }
                    } else {
                        if (regions.length) {
                            ParamObject.reg = regions;
                        }
                        if (cities.length || states.length) {
                            if (states.length) {
                                ParamObject.state = states;
                            }
                            if (cities.length) {
                                ParamObject.city = cities;
                            }
                        }
                        // else {
                        if (country.length) {
                            ParamObject.country = country;
                        }
                        // else 
                        if (regions.length) {
                            let regCountries = [];
                            for (let i = 0; i < regionsCountries.length; i++) {
                                if (regionsCountries[i]["type"] === "Country") {
                                    regCountries.push(regionsCountries[i]["slug"]);
                                }
                            }
                            if (regCountries.length) {

                                ParamObject.country = regCountries;
                            }
                        }
                        // }
                    }
                    if (selectedLocation[i]["type"] === "any") {
                        ParamObject.location = "all";
                    }
                }
            }
        }
        if (formValid) {
            if (selectedDegreeLevel[0].slug === "undergraduate") {
                ParamObject.degreelevel_type = "undergraduate"
                selectedDegreeLevel[0].slug = ''
            }
            if (selectedDegreeLevel[0].slug === "postgraduate") {
                ParamObject.degreelevel_type = "postgraduate"
                selectedDegreeLevel[0].slug = ''
            }
            if (selectedDegreeLevel[0].slug === "postgraduate_by_research") {
                ParamObject.degreelevel_type = "postgraduate_by_research"
                selectedDegreeLevel[0].slug = ''
            }
            if (ParamObject.search.length) {
                ParamObject.discipline = ''
                ParamObject.course = ''
            }

            let searchurl = `${ParamObject?.discipline?.length ? '&discipline=' + ParamObject?.discipline.join(',') : ''}${ParamObject?.course?.length ? '&specialization=' + ParamObject?.course.join(',') : ''}${ParamObject?.country?.length ? '&country=' + ParamObject.country.join(',') : ''}${ParamObject?.state?.length ? '&state=' + ParamObject.state.join(',') : ''}${ParamObject?.city?.length ? '&city=' + ParamObject.city.join(',') : ''}${ParamObject?.levels?.length ? '&degreelevel=' + ParamObject?.levels : ''}${ParamObject?.degreelevel_type?.length ? '&degreelevel_type=' + ParamObject?.degreelevel_type : ''}${ParamObject?.search?.length ? '&search=' + ParamObject?.search : ''}`

            history.push({
                // pathname: `/course${selectedDegreeLevel?.length ? '/' + selectedDegreeLevel[0]?.slug : ''}${selectedLocation?.length ? '/' + selectedLocation[0]?.slug : ''}`,
                pathname: `/search`,
                search: searchurl,
                query: ParamObject,
                state: { course: selectedCourses, mainSearch: true },
            });
        }
    }
    return (
        <div>
            <Dialog
                open={confirmDialog}
                onClose={handleDialogConfirmClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {confirmDialogHeading}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {confirmDialogContent}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogConfirmCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDialogConfirm} color="primary" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <section className="courseBanner">
                <img
                    src={require("../../../static/images/background/image.webp")}
                    alt="home-banner"
                />
                <div className="content">
                    <h1>
                        World's Largest Course Search Platform
                        <span>For International Students</span>{" "}
                    </h1>
                    <div className="searchWrap">
                        <CourseSearchBox
                            selectedCourses={selectedCourses}
                            clearAllCourse={clearAllCourse}
                            onRemoveCourse={onRemoveCourse}
                            onFoucusCourse={onFoucusCourse}
                            coursePlaceholder={coursePlaceholder}
                            courseInput={courseInput}
                            handleKeyDown={handleKeyDown}
                            searchCourses={searchCourses}
                            onFoucusCourseArrow={onFoucusCourseArrow}
                            courseError={courseError}
                            wrapperRef={wrapperRef}
                            spellCheck={spellCheck}
                            disciplines={disciplines}
                            onSelectDisciplineWithOpenSpecializations={onSelectDisciplineWithOpenSpecializations}
                            setCourseInput={setCourseInput}
                            onArrowDisciplineSpecializations={onArrowDisciplineSpecializations}
                            onClickCourseModal={onClickCourseModal}
                            onFoucusOutCourse={onFoucusOutCourse}
                        />
                        <QualificationSearchBox
                            selectedDegreeLevel={selectedDegreeLevel}
                            clearDegreeLevel={clearDegreeLevel}
                            onRemoveDegreeLevel={onRemoveDegreeLevel}
                            onClickViewMoreDegreeLevels={onClickViewMoreDegreeLevels}
                            degreeLevelPlaceholder={degreeLevelPlaceholder}
                            degreeLevelInput={degreeLevelInput}
                            onFocusDiscipline={onFocusDiscipline}
                            onFocusOutDiscipline={onFocusOutDiscipline}
                            searchDegreeLevels={searchDegreeLevels}
                            onFocusDisciplineArrow={onFocusDisciplineArrow}
                            selectedDegreeLevelError={selectedDegreeLevelError}
                            degreeLevelsOptions={degreeLevelsOptions}
                            onSelectUndergraduate={onSelectUndergraduate}
                            setDegreeType={setDegreeType}
                            undergraduate={undergraduate}
                            arrowUpDownClick={arrowUpDownClick}
                            degreeLevelSearch={degreeLevelSearch}
                            onSelectDegreeLevel={onSelectDegreeLevel}
                            onSelectPostgraduate={onSelectPostgraduate}
                            postgraduate={postgraduate}
                            onSelectResearch={onSelectResearch}
                            research={research}
                        />
                        <LocationSearchBox
                            selectedLocation={selectedLocation}
                            clearLocationInput={clearLocationInput}
                            onLocationRemove={onLocationRemove}
                            showRegionModal={showRegionModal}
                            locationPlaceholder={locationPlaceholder}
                            locationInput={locationInput}
                            onFoucusLocation={onFoucusLocation}
                            onFoucusOutLocation={onFoucusOutLocation}
                            searchLocations={searchLocations}
                            onFoucusLocationArrow={onFoucusLocationArrow}
                            locationError={locationError}
                            wrapperRef={wrapperRef}
                            suggestedLocation={suggestedLocation}
                            onSelectSuggestedLocation={onSelectSuggestedLocation}
                            emptyResult={emptyResult}
                            handleDialogConfirmOpen={handleDialogConfirmOpen}
                            isLocationFocus={isLocationFocus}
                            locationRef={locationRef}
                        />
                        <div className="searchBtn">
                            <button
                                onClick={(e) => onSubmit(e.target.id)}
                                className="btn"
                                id="grid"
                            >
                                Search
                            </button>
                        </div>
                    </div>
                    <p className="advFilter">
                        <button className="btn" onClick={isAuthenticated?(e) => onAdvanceFilters():()=> setOpenModal(true)}>
                            <img
                                src={require("../../../static/images/filter-icon.svg").default}
                                alt="filter-icon"
                            />
                            Advance Filters
                        </button>
                        {" "}
                    </p>

                    {/* <div className="gridMap">
                        <button
                            onClick={(e) => onSubmit(e.target.id)}
                            className="btn"
                            id="map"
                        >
                            <img
                                src={
                                    require("../../../static/images/search-in-map.svg").default
                                }
                                alt="search-in-map"
                            />
                            Discover your selection in Map view
                         
                        </button>
                    </div> */}
                </div>
            </section >
            <LoginModal openModal={openModal} closeModal={handleCloseMOdal}/>
            <div
                className="modal fade signinModal"
                id="currentLocation"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
                data-backdrop="static"
                data-keyboard="false"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <img
                                    src={
                                        require("../../../static/images/modal-times.svg").default
                                    }
                                    alt="modal-times"
                                />
                            </button>
                            <h3>Current Location</h3>
                            {/* <CurrentLocation /> */}
                            <CurrentLocation onLoadCurrentLocation={handleCurrentLocation} />
                            <form
                                onSubmit={(e) => {
                                    currentLocationSubmitHandler(e);
                                }}
                            >
                                <div className="form-group">
                                    <Select
                                        name="distance"
                                        styles={MAIN_SEARCH_CUSTOM_STYLES}
                                        placeholder="Search range"
                                        options={DISTANCE_OPTIONS}
                                        onChange={handleDistanceSelectChange}
                                        className={distanceError ? " is-invalid" : null}
                                    />

                                    {distanceError && (
                                        <div>
                                            <span className="text-danger">Required filed</span>
                                        </div>
                                    )}
                                    <img
                                        className="formIcon"
                                        src={
                                            require("../../../static/images/forms/Distance.svg")
                                                .default
                                        }
                                        alt="Distance"
                                    />
                                </div>
                                <button className="formBtn" type="submit">
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div
                className="modal fade signinModal"
                id="autoLocation"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
                data-backdrop="static"
                data-keyboard="false"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <img
                                    src={
                                        require("../../../static/images/modal-times.svg").default
                                    }
                                    alt="modal-times"
                                />
                            </button>
                            <h3>Enter Town, City or Postcode</h3>

                            <form
                                onSubmit={(e) => {
                                    genericLocationSubmitHandler(e);
                                }}
                            >
                                <div className="form-group">
                                    <Select
                                        name="autodistance"
                                        styles={MAIN_SEARCH_CUSTOM_STYLES}
                                        placeholder="Search range"
                                        options={DISTANCE_OPTIONS}
                                        onChange={handleAutoDistanceSelectChange}
                                        className={autoDistanceError ? "is-invalid" : null}
                                    />
                                    {autoDistanceError && (
                                        <div>
                                            <span className="text-danger">Required filed</span>
                                        </div>
                                    )}
                                    <img
                                        className="formIcon"
                                        src={
                                            require("../../../static/images/forms/Distance.svg")
                                                .default
                                        }
                                        alt="Distance"
                                    />
                                </div>
                                <div className="form-group">
                                    <LocationSearchInput
                                        onLoadAutoLocation={handleAutoLocation}
                                    />
                                    {autoLocationError && (
                                        <div>
                                            <span className="text-danger">Must choose Location</span>
                                        </div>
                                    )}
                                    <img
                                        className="formIcon"
                                        src={
                                            require("../../../static/images/forms/preferred_location.svg")
                                                .default
                                        }
                                        alt="preferred_location"
                                    />
                                </div>

                                <button className="formBtn" type="submit">
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* popular regions modal*/}

            <PopularRegionsModal
                regionModal={regionModal}
                selectedLocation={selectedLocation}
                onLocationRemove={onLocationRemove}
                closeRegionModal={closeRegionModal}
                showCountryDetail={showCountryDetail}
                countryInput={countryInput}
                handleCountriesSearch={handleCountriesSearch}
                regions={regions}
                onSelectLocation={onSelectLocation}
                setShowRegion={setShowRegion}
                popularCountries={popularCountries}
                showRegion={showRegion}
                regionsCountries={regionsCountries}
                countries={countries}
                onClickCountryDetail={onClickCountryDetail}
                showStateDetail={showStateDetail}
                onClickCountryDetailBack={onClickCountryDetailBack}
                stateInput={stateInput}
                handleStatesSearch={handleStatesSearch}
                filteredStates={filteredStates}
                onClickStateDetail={onClickStateDetail}
                onClickStateDetailBack={onClickStateDetailBack}
                cityInput={cityInput}
                handleCitiesSearch={handleCitiesSearch}
                filterCities={filterCities}
            />

            {/*	popular cities  */}
            <PopularCitiesModal
                selectedLocation={selectedLocation}
                onLocationRemove={onLocationRemove}
                closeRegionModal={closeRegionModal}
                handlePopularCitiesSearch={handlePopularCitiesSearch}
                cityInput={cityInput}
                regions={regions}
                popularCountries={popularCountries}
                onSelectLocation={onSelectLocation}
                popularCities={popularCities}
            />

            {/*	courses  */}
            <DisciplineModal
                selectedCourses={selectedCourses}
                handleSpecializationSearch={handleSpecializationSearch}
                onRemoveCourse={onRemoveCourse}
                onClickSelectedCoursesDone={onClickSelectedCoursesDone}
                disciplines={disciplines}
                onSelectDisciplineWithOpenSpecializations={onSelectDisciplineWithOpenSpecializations}
                onArrowDisciplineSpecializations={onArrowDisciplineSpecializations}
                disciplineId={disciplineId} loadSpecializations={loadSpecializations}
                specializations={specializations} onSelectCourses={onSelectCourses}
                fetchData={fetchData} page={page}
            />
        </div >
    );
}

export default MainSearch;