import { http } from "../../../services/apiService/apiService";

// Disciplines

export const GET_ALL_BLOGS = "GET_ALL_BLOGS";
export const GET_ALL_BLOGS_SUCCESS = "GET_ALL_BLOGS_SUCCESS";
export const GET_ALL_BLOGS_FAIL = "GET_ALL_BLOGS_FAIL";

export function getAllBlogs(pageId,search) {
	let url="/blog/";
if(pageId)
{
url=url+"?page="+pageId +`${search?'&search='+search:''}`;
}
	const request = http.get(url);
	return (dispatch) =>
		request
			.then((response) =>
				dispatch({
					type: GET_ALL_BLOGS_SUCCESS,
					payload: response.data,
				})
			)
			.catch((error) => {
				return dispatch({
					type: GET_ALL_BLOGS_FAIL,
					payload: [],
				});
			});
}

export const GET_BLOGS_BY_ID = "GET_BLOGS_BY_ID";
export const GET_BLOGS_BY_ID_SUCCESS = "GET_BLOGS_BY_ID_SUCCESS";
export const GET_BLOGS_BY_ID_FAIL = "GET_BLOGS_BY_ID_FAIL";

export function getBlogsById(blogId) {
	const url = "/blog/" + blogId;
	const request = http.get(url);

	return (dispatch) =>
		request
			.then((response) =>
				dispatch({
					type: GET_BLOGS_BY_ID_SUCCESS,
					payload: response.data,
				})
			)
			.catch((error) => {
				return dispatch({
					type: GET_BLOGS_BY_ID_FAIL,
					payload: [],
				});
			});
}
