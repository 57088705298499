import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import TextField from '@mui/material/TextField';

let selectedCourse = [];
const CourseInputSearch = (props) => {
    const {submitSearch} = props;
    const location = useLocation();
    const params = Object.fromEntries(new URLSearchParams(location.search));
    const [courseInputError, setCourseInputError] = useState(false);
    const [courseInput, setCourseInput] = useState([]);
    useEffect(() => {
        getUrlParams();
    }, [props])
    const getUrlParams = () => {
        let course = [];
        selectedCourse = [];
        for (const [key, value] of Object.entries(params)) {
            if (key.includes("course")) {
                course = assignValuesForCourse(value, course, "slug");
            }
        }
        if (course) {
            course?.map((item) => {
                selectedCourse.push(item.slug);
            });
            setCourseInput(selectedCourse);
        }

    };

    function assignValuesForCourse(value, selectedVariable, option) {
        selectedVariable = []
        value.split(',')?.map(d => {
        if (option) {
            let obj = {}
            obj[option] = d
            selectedVariable.push(obj)
        } else {
            selectedVariable.push(d)
        }
        })
        return selectedVariable
        // let instance = key.split("[");
        // let keyValue = instance[1].replace("]", "");
        // let attribute = instance[2].replace("]", "");
        // let index = -1;
        // for (let i = 0; i < selectedVariable.length; i++) {
        //     if (keyValue === selectedVariable[i]["key"]) {
        //         index = keyValue;
        //         break;
        //     }
        // }
        // if (index !== -1) {
        //     if (attribute === option) {
        //         selectedVariable[index][attribute] = value;
        //     } else {
        //         selectedVariable[index][attribute] = value;
        //     }
        // } else {
        //     const myObj = {};
        //     if (attribute === option) {
        //         myObj[attribute] = value;
        //     }
        //     selectedVariable.push(myObj);
        // }
    }

    const inputHandleChange = (event) => {
        if (selectedCourse) {
            selectedCourse = [event.target.value];
        }
        let keyValue = []
        if(event.target.value?.split(',')?.length){
            keyValue = event.target.value.split(',')
        }else{
            keyValue = [event.target.value]
        }
        setCourseInput(keyValue);
    };
    const onSubmit = (e) => {
        setCourseInputError(false);
        if (courseInput === "") {
            setCourseInputError(true);
        } else if (e.key === "Enter") {
            setCourseInputError(false);
            selectedCourse = [courseInput];
        }
    };
    return (
        <div className="countryBanner marginBanner">
            <img
                src={require("../../../static/images/country-banner.svg").default}
                alt="country-banner"
            />
            <div className="content">
                {props.type === "sitemap" ? (
                    <h3>Search website...</h3>
                ) : (
                    <h3>Explore thousands of programs across the world.</h3>
                )}

                <div className="countrySearchWrap">
                    {/*<input*/}
                    {/*    type="text"*/}
                    {/*    placeholder="Type here"*/}
                    {/*    value={courseInput}*/}
                    {/*    onChange={inputHandleChange}*/}
                    {/*    className={*/}
                    {/*        courseInputError ? " form-control is-invalid" : " form-control"*/}
                    {/*    }*/}
                    {/*    onKeyPress={(e) => onSubmit(e)}*/}
                    {/*/>*/}
                    <TextField
                        fullWidth
                        placeholder="Type here"
                        value={courseInput&&courseInput?.map(d=>d?.replaceAll('-',' '))}
                        autoFocus={true}
                        type={"string"}
                        onChange={inputHandleChange}
                        className={
                            courseInputError ? " form-control is-invalid" : " form-control"
                        }
                        onKeyPress={(e) => onSubmit(e)}
                    />
                    <button className="btn" onClick={(e) => submitSearch(courseInput)}>
                        Search
                    </button>
                    <i className="fa fa-search"/>
                </div>
            </div>
        </div>

    );
};

export default CourseInputSearch;
