export const ADD_ALL_SCHOLARSHIPS_FILTER_DATA =
	"ADD_ALL_SCHOLARSHIPS_FILTER_DATA";
export const ADD_ALL_SCHOLARSHIPS_FILTER_DATA_SUCCESS =
	"ADD_ALL_SCHOLARSHIPS_FILTER_DATA_SUCCESS";
export const ADD_ALL_SCHOLARSHIPS_FILTER_DATA_FAIL =
	"ADD_ALL_SCHOLARSHIPS_FILTER_DATA_FAIL";

export function addAllScholarshipsFilterData(data = {}) {
	return (Dispatch) =>
		Dispatch({
			type: "ADD_ALL_SCHOLARSHIPS_FILTER_DATA_SUCCESS",
			payload: data,
		});
}
