import React from "react";
import ListViewCrdLoader from "../loaders/listViewCardLoader";
import Pagination from "react-responsive-pagination";
import GridViewCrdLoader from "../loaders/gridViewCardLoader";
import LoadingSpinner from "../loader/loader";
import CourseViewMap from "../map/courselistmap";
import CourseListCardView from "../card/ListViewCoursesCard/course_list_view_card";
import CourseGridCardView from "../card/GridViewCoursesCard/coures_grid_view_card";
import currencySvg from "../../../static/images/forms/Currency.svg";
import listViewSvg from "../../../static/images/list_view.svg";
import gridViewSvg from "../../../static/images/search-in-grid.svg";
import searchInMapSvg from "../../../static/images/search-in-map.svg";

const CounterSectionIndividual = (props) => {
  const {
    courses,
    showView,
    courseLoader,
    pageState,
    handlePageClick,
    showCounter,
    advance,
    coursesCount,
    popularity,
    handlePopularityCheck,
    shareModal,
    mobileFilters,
    selectedSortOption,
    sortOptions,
    clickSortOption,
    selectedCurrency,
    checkView,
    selectCurrencyFtn,
    currencies,
  } = props;
  return (
    <>
      <div className="mainContTop">
        <div className="sortFilterTop">
          {courseLoader ? (
            <h5>Searching For Desired Results</h5>
          ) : showCounter && advance && (
            <h5>{coursesCount} Results Found</h5>
          )}
          <div>
            <div className="popOptWrap">
              <label className="customCheckbox">
                <input
                  type="checkbox"
                  className={"form-control"}
                  checked={popularity}
                  value="p1"
                  onChange={(e) =>
                    handlePopularityCheck(e.target.value, e.target.checked)
                  }
                />
                <span>Popular Options</span>{" "}
              </label>
            </div>
            <button
              className="btn defaultBtn sharePgBtn"
              data-target="#sharePageModal"
              onClick={shareModal}
              data-toggle="modal"
            >
              <img
                src={require("../../../static/images/share-white.svg").default}
                alt="share-white"
              />
              Share Page
            </button>
            <button
              className="btn primarBtn filterNavBtn"
              onClick={mobileFilters}
            >
              <img
                src={require("../../../static/images/filter-icon.svg").default}
                alt="filter-icon"
              />
              Filters
            </button>
          </div>
        </div>

        <div className="sortFilter">
          {showView === "map" ? (
            <div />
          ) : (
            <div>
              <p>Sort by:</p>
              <div className="dropdown">
                <button
                  className="btn dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {selectedSortOption ? (
                    <span>
                      <img
                        src={selectedSortOption.image}
                        height=""
                        width=""
                        alt={selectedSortOption.label}
                      />
                      {selectedSortOption.label}
                    </span>
                  ) : (
                    <span>Select Option</span>
                  )}
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  {sortOptions.map((option, index) => {
                    if (!option.selected)
                      return (
                        <li
                          className="dropdown-item"
                          onClick={(e) => clickSortOption(option, e)}
                        >
                          <img
                            src={option?.image}
                            height=""
                            width=""
                            alt={option.label}
                          />
                          {option.label}
                          {option?.label === "Duration" ? (
                            <img src={option.icon} alt={option.label} />
                          ) : null}
                        </li>
                      );
                  })}
                </div>
              </div>
            </div>
          )}
          <div>
            <div className="filterCurrency">
              <select
                className="form-control"
                value={selectedCurrency}
                onChange={(e) => {
                  selectCurrencyFtn(e.target.value);
                }}
              >
                <option hidden>Select Currency</option>
                <option value="0">Default</option>
                {currencies.map((item, index) => {
                  return (
                    <option value={item.id}>
                      {" "}
                      {item.description} ({item.display_name})
                    </option>
                  );
                })}
              </select>

              <img
                src={currencySvg}
                alt="Currency"
              />
            </div>

            <button
              className={
                showView === "list" ? "btn primaryBtn active" : "btn primaryBtn"
              }
              onClick={() => checkView("list")}
            >
              <img
                src={
                  showView === "grid"
                    ? listViewSvg
                    : gridViewSvg
                }
                alt="list_view"
              />
              {showView === "grid" ? "List View" : "Grid View"}
            </button>

            <button
              onClick={() => checkView("map")}
              className={
                showView === "map" ? "btn primaryBtn active" : "btn primaryBtn"
              }
            >
              <img
                src={searchInMapSvg}
                alt="search-in-map"
              />
              Map View
            </button>
          </div>
        </div>
      </div>
      {(showView === "list" && (
        <div>
          {courseLoader ? (
            <ListViewCrdLoader />
          ) : (
            <div>
              {courses?.length > 0 ? (
                <CourseListCardView
                  courses={courses}
                  page={pageState.currentPage}
                />
              ) : (
                <p className="noRecP">Record not found</p>
              )}
            </div>
          )}
          {courses?.length > 0 ? (
            <div className="paginationWrap">
            <Pagination
              current={pageState.currentPage}
              total={pageState.pageCount}
              onPageChange={handlePageClick}
              previousLabel={"Prev"}
              nextLabel={"Next"}
            />
          </div>
      
          ) : null}
        </div>
      )) ||
        (showView === "grid" && (
          <div>
            {courseLoader ? (
              <GridViewCrdLoader />
            ) : (
              <div>
                {courses?.length > 0 ? (
                  <div>
                    <CourseGridCardView
                      courses={courses}
                      page={pageState.currentPage}
                    />
                  </div>
                ) : (
                  <p className="noRecP">Record not found</p>
                )}
              </div>
            )}
            {courses?.length > 0 ? (
              <div className="paginationWrap">
                <Pagination
                  current={pageState.currentPage}
                  total={pageState.pageCount}
                  onPageChange={handlePageClick}
                  previousLabel={"Prev"}
                  nextLabel={"Next"}
                />
              </div>
            ) : null}
          </div>
        )) ||
        (showView === "map" &&
          (courseLoader ? (
            <LoadingSpinner />
          ) : (
            <div>
              {courses.length ? (
                <CourseViewMap institutesLocation={courses} />
              ) : (
                "Not Found"
              )}
            </div>
          )))}
    </>
  );
};

export default CounterSectionIndividual;
