// import { LOGIN, LOGOUT } from "../../apis/store/actions/types";

import { LOGIN, LOGOUT } from "../actions/types";

const initialState = {
  user: null,
  isAuthenticated: false,
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        user: action.payload?.identity?.traits,
        isAuthenticated: action.payload.active || action.payload?.identity?.traits.email || false,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default auth;
