import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../apis/store/actions";
import { useHistory } from "react-router-dom";
import { getSingleRoute } from "../../../common/utils";
import { AUTH_PAGES, SCHOLARSHIPS } from "../../constants";
import { Link, useLocation, useNavigate } from "react-router-dom-v5-compat";
import tcoLogo from "../../../static/images/tco-logo.svg";
import fbImg from "../../../static/images/fb.png";
import twitterImg from "../../../static/images/Twitter.jpg";
import pinterestImg from "../../../static/images/Pinterest.png";
import instaImg from "../../../static/images/insta.png";
import { Divider, Grid } from "@mui/material";
import tcfLogo from "../../../static/images/course-footer-logo2.png";
import EmbedSocialForm from "../EmbedSocialForm";
function Footer() {
  const history = useNavigate();
  const allCountryList = useSelector(({ apis }) => apis.allCountries.data);
  const allDisciplineList = useSelector(
    ({ apis }) => apis.allDisciplineLevels.data
  );
  const { instituteGroupData } = useSelector(
    ({ apis }) => apis.allInstituteGroups
  );

  const footerDynamicData = useSelector(
    ({ apis }) => apis.getAllDynamicPagesByType?.data
  );
  const [countryList, setCountryList] = useState([]);
  const [disciplineList, setDisciplineList] = useState([]);

  const dispatch = useDispatch();
  const blogList = useSelector(({ apis }) => apis.allBlogs.data);
  const [blogsList1, setBlogsList1] = useState([blogList]);
  const location = useLocation();

  useEffect(() => {
    dispatch(actions.getAllBlogs());

    dispatch(actions.getAllCountries());
    dispatch(actions.getAllDesciplines());
    dispatch(actions.getAllInstituteGroups());
    dispatch(actions.allDynamicPagesByType());
  }, [dispatch]);
  useEffect(() => {
    if (allCountryList) {
      setCountryList(allCountryList);
    }
    if (allDisciplineList) {
      setDisciplineList(allDisciplineList);
    }

    if (blogList) {
      setBlogsList1(blogList.results);
    }
  }, [blogList, allCountryList, allDisciplineList, footerDynamicData]);

  const getRoute = (page, id) => {
    getSingleRoute(page).then((d) => {
      history({
        search: id?.search ? id?.search : "",
        pathname: d + `${id?.id ? "/" + id?.id : ""}`,
      });
      history(0);
    });
  };

  return !AUTH_PAGES.includes(location.pathname) ? (
    <footer>
      <Grid
        container
        justifyContent="space-between"
        spacing={3}
        sx={{ backgroundColor: "#f8f9fa" }}
        p={3}
      >
        <Grid
          item
          lg={2}
          md={4}
          sm={4}
          xs={12}
          className="ps-2 pe-2 pt-3"
          sx={{ display: { xs: "none", md: "block" } }}
        >
          <div>
            <div>
              <img
                alt=""
                src={tcfLogo}
                width={"180px"}
                className="d-block pt-2 pb-2"
                onClick={() =>
                  window.open("https://timescoursefinder.com/", "_blank")
                }
              />

              <img
                alt=""
                src={tcoLogo}
                width={"150px"}
                className="d-block pt-2 pb-2"
                onClick={() =>
                  window.open("https://timesconsultant.com/", "_blank")
                }
              />
            </div>
            <div className="pt-3 pb-1" style={{ width: "200px" }}>
              <p style={{ lineHeight: "inherit" }}>
                TCF is the World's largest course search platform, provide
                access to top universities, courses, and scholarships.
              </p>
            </div>
            <div>
              <img
                alt=""
                src={twitterImg}
                className="mr-3"
                onClick={() =>
                  window.open("https://twitter.com/FinderTimes", "_blank")
                }
              />
              <img
                alt=""
                src={fbImg}
                className="mr-3"
                onClick={() =>
                  window.open("https://www.facebook.com/TimesCF", "_blank")
                }
              />
              <img
                alt=""
                src={instaImg}
                className="mr-3"
                onClick={() =>
                  window.open("https://www.instagram.com/timescf/", "_blank")
                }
              />
              <img
                alt=""
                src={pinterestImg}
                className="mr-3"
                onClick={() =>
                  window.open(
                    "https://www.pinterest.com/timescoursefinder/",
                    "_blank"
                  )
                }
              />
            </div>
          </div>
        </Grid>

       
<Grid item lg={2} md={4} sm={6} xs={6}>
<h6 className="cursor-pointer" onClick={() => getRoute("all_countries")}>Destinations</h6>
<div>
  {Array.isArray(countryList) ? (
    countryList.slice(0, 11).map((country, i) => (
      <p
        key={i}
        className="cursor-pointer"
        onClick={() => getRoute("country", { id: country.slug })}
      >
        {country.name.charAt(0).toUpperCase() + country.name.slice(1)}
      </p>
    ))
  ) : (
    <div>Loading...</div>
  )}
</div>
</Grid>
        <Grid item lg={2} md={4} sm={6} xs={6}>
          <h6
            className="cursor-pointer"
            onClick={() => getRoute("all_scholarships")}
          >
            Scholarships
          </h6>
          <div>
            {SCHOLARSHIPS.map((scholarship, i) => (
              <p
                key={i}
                className="cursor-pointer"
                onClick={() => getRoute("all_scholarships")}
              >
                {scholarship}
              </p>
            ))}
          </div>
        </Grid>

        <Grid item lg={2} md={4} sm={6} xs={6}>
          <h6 className="cursor-pointer" onClick={() => getRoute("all_groups")}>
            Universities
          </h6>
          <div style={{ whiteSpace: "break-spaces" }}>
            {instituteGroupData && instituteGroupData.results ? (
              instituteGroupData.results.map((instituteGroup, i) => (
                <>
                  <p
                    key={i}
                    className="cursor-pointer"
                    onClick={() =>
                      getRoute("group_universities", {
                        id: instituteGroup.slug,
                      })
                    }
                  >
                    {instituteGroup.name.charAt(0).toUpperCase() +
                      instituteGroup.name.slice(1)}
                  </p>
                </>
              ))
            ) : (
              <div>No institute groups found</div>
            )}
            <p>
              <Link
                to={
                  "https://timescoursefinder.com/university-details/kings-college-london/waterloo-campus/"
                }
                style={{ color: "inherit" }}
              >
                King's College London
              </Link>
            </p>
            <p>
              <Link
                to={
                  "https://timescoursefinder.com/university-details/yale-university/new-haven/"
                }
                style={{ color: "inherit" }}
              >
                Yale University
              </Link>
            </p>
            <p>
              <Link
                to={
                  "https://timescoursefinder.com/university-details/newcastle-university/newcastle-none/"
                }
                style={{ color: "inherit" }}
              >
                Newcastle University
              </Link>
            </p>
            <p>
              <Link
                to={
                  "https://timescoursefinder.com/university-details/cardiff-university/cardiff/"
                }
                style={{ color: "inherit" }}
              >
                Cardiff University
              </Link>
            </p>
            <p>
              <Link
                to={
                  "https://timescoursefinder.com/university-details/durham-university/durham/"
                }
                style={{ color: "inherit" }}
              >
                Durham University
              </Link>
            </p>
          </div>
        </Grid>

        <Grid item lg={2} md={4} sm={6} xs={6}>
          <h6
            className="cursor-pointer"
            onClick={() => getRoute("all_programs")}
          >
            Disciplines
          </h6>
          <div>
            {disciplineList ? (
              disciplineList.slice(1, 12).map((discipline, i) => (
                <p
                  key={i}
                  className="cursor-pointer"
                  onClick={() =>
                    getRoute("discipline_detail", {
                      search: `?discipline=${discipline.slug}`,
                    })
                  }
                >
                  {discipline.name}
                </p>
              ))
            ) : (
              <div>Loading...</div>
            )}
          </div>
        </Grid>
        <Grid
        item
          lg={2}
          md={4}
          sm={6}
          xs={6}
          className="ps-2 pe-2 pt-3"
          sx={{ display: { xs: "none", md: "block", lg: "block" } }}
        >
          {/* <div> */}
            <h6 onClick={() => getRoute("blog")} className="cursor-pointer">
              Blogs
            </h6>
            <div>
              {blogsList1 ? (
                blogsList1.slice(5, 10).map((item, i) => (
                  <p
                    className="cursor-pointer"
                    key={i}
                    onClick={() => getRoute("blog_detail", { id: item.slug })}
                  >
                    {item.heading}
                  </p>
                ))
              ) : (
                <div>Loading...</div>
              )}
            </div>

            <div className="pt-3">
              <h6>Company</h6>
              <p>
                <Link to={"/tcf"} style={{ color: "inherit" }}>
                  About
                </Link>
              </p>
              <p>
                <Link to={"/careers"} style={{ color: "inherit" }}>
                  Careers
                </Link>
              </p>
              <p>
                <Link to={"/contact-us"} style={{ color: "inherit" }}>
                  Contact
                </Link>
              </p>
              <p>
                <Link to={"terms-of-use"} style={{ color: "inherit" }}>
                  Terms & conditions
                </Link>
              </p>
              <p>
                <Link to={"/privacy-policy"} style={{ color: "inherit" }}>
                  Privacy policy
                </Link>
              </p>
            </div>
          {/* </div> */}
        </Grid>
      </Grid>
      <Grid
        xs={6}
        sm={6}
        md={2}
        className="ps-2 pe-2 pt-3"
        sx={{ display: { xs: "block", lg: "none", md: "none" } }}
      >
        <div>
          <div>
            <img
              alt=""
              src={tcfLogo}
              width={"180px"}
              className="d-block pt-2 pb-2"
              onClick={() =>
                window.open("https://timescoursefinder.com/", "_blank")
              }
              style={{ marginInline: "auto" }}
            />

            <img
              alt=""
              src={tcoLogo}
              width={"150px"}
              className="d-block pt-2 pb-2"
              onClick={() =>
                window.open("https://timesconsultant.com/", "_blank")
              }
              style={{ marginInline: "auto" }}
            />
          </div>

          <div style={{ textAlign: 'center', marginTop: "10px" }}>
            <p style={{ margin: 'auto', maxWidth: '350px', textAlign: 'center' }}>
              TCF is the World's largest course search platform, providing access to top universities,
              courses, and scholarships.
            </p>
          </div>
          <div style={{ textAlign: "center", marginBottom: "20px", marginTop: "10px" }}>
            <img
              alt=""
              src={twitterImg}
              className="mr-3"
              onClick={() =>
                window.open("https://twitter.com/FinderTimes", "_blank")
              }
            />
            <img
              alt=""
              src={fbImg}
              className="mr-3"
              onClick={() =>
                window.open("https://www.facebook.com/TimesCF", "_blank")
              }
            />
            <img
              alt=""
              src={instaImg}
              className="mr-3"
              onClick={() =>
                window.open("https://twitter.com/FinderTimes", "_blank")
              }
            />
            <img
              alt=""
              src={pinterestImg}
              className="mr-3"
              onClick={() =>
                window.open(
                  "https://www.pinterest.com/timescoursefinder/",
                  "_blank"
                )
              }
            />
          </div>
        </div>
      </Grid>
      <Divider />
      <div style={{ backgroundColor: "#f8f9fa" }}>
        <p className="text-center m-0 pt-3">Copyright © Times Course Finder</p>
      </div>
      <EmbedSocialForm />
    </footer>
  ) : null;
}

export default Footer;
