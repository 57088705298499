import { CloseOutlined } from "@material-ui/icons";
import { Box, Button, Divider, Grid, Modal, TextField } from "@mui/material";
import { styles } from "../constants";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";

const CertificationModal = ({
  openCertificationModal,
  handleCloseCertificationModal,
  editCertification,
  handleSubmitCertification,
  handleEditCertification,
  handleAddCertification,
  errorsCertification,
  registerCertification,
  setValueCertification
}) => {
  return (
    <Modal
      open={openCertificationModal}
      onClose={handleCloseCertificationModal}
      aria-labelledby="add-education"
    >
      <Box className="tcf-v2" sx={styles.certificationModal}>
        <div className="d-flex justify-content-between pl-4 pr-4 pt-4">
          <h4 className="font-weight-bold">{editCertification ? "Edit Certification" : "Add Certification"} </h4>
          <CloseOutlined className="cursor-pointer tcf-icon" onClick={handleCloseCertificationModal} />
        </div>
        <div className="pl-4 pr-4 pb-2">
          <Divider />
        </div>
        <div className="w-100 ory-input-fields pl-4 pr-4 pt-2">
          <form
            onSubmit={editCertification ? handleSubmitCertification(handleEditCertification) : handleSubmitCertification(handleAddCertification)}
          >
            <Grid container spacing={2} className="pb-4">
              <Grid item xs={12}>
                <TextField
                  error={errorsCertification.name}
                  {...registerCertification("name", { required: true })}
                  type="text"
                  label="Certification name e.g. IELTS, SAT, GRE"
                  variant="outlined"
                  className="w-100"
                  helperText={errorsCertification.name?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={errorsCertification.score}
                  {...registerCertification("score", { required: true })}
                  type="text"
                  label="Score*"
                  variant="outlined"
                  className="w-100"
                  helperText={errorsCertification.score?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker views={['month', 'year']} defaultValue={dayjs(editCertification?.startDate)} onChange={(newValue) => setValueCertification('completionDate', newValue.format('MMM YYYY'))} className="w-100" label="Completion Date" name="completionDate" />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Button
              variant="contained"
              className="tcf-button-small float-right mb-4"
              type="submit"
            >
              Save
            </Button>
          </form>
        </div>
      </Box>
    </Modal>
  )
}

export default CertificationModal;