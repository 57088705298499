import { http } from "../../../services/apiService/apiService";

export const GET_INSTITUTE_DETAIL_BY_ID = "GET_INSTITUTE_DETAIL_BY_ID";
export const GET_INSTITUTE_DETAIL_BY_ID_SUCCESS =
	"GET_INSTITUTE_DETAIL_BY_ID_SUCCESS";
export const GET_INSTITUTE_DETAIL_BY_ID_FAIL =
	"GET_INSTITUTE_DETAIL_BY_ID_FAIL";

export function getInstituteDetailById(uniId) {
	const url = "institute/" + uniId;
	const request = http.get(url);

	return (dispatch) =>
		request
			.then((response) =>
				dispatch({
					type: GET_INSTITUTE_DETAIL_BY_ID_SUCCESS,
					payload: response.data,
				})
			)
			.catch((error) => {
				return dispatch({
					type: GET_INSTITUTE_DETAIL_BY_ID_FAIL,
					payload: [],
				});
			});
}

export const GET_ALL_INSTITUTES_DISCIPLINES = "GET_ALL_INSTITUTES_DISCIPLINES";
export const GET_ALL_INSTITUTES_DISCIPLINES_SUCCESS =
	"GET_ALL_INSTITUTES_DISCIPLINES_SUCCESS";
export const GET_ALL_INSTITUTES_DISCIPLINES_FAIL =
	"GET_ALL_INSTITUTES_DISCIPLINES_FAIL";

export function getInstitutesDisciplines(uniId,params) {
	let url = "institute/" + uniId + "/discipline/";
	// if(campus_id)
	// {
	// 	url=url+"?campus_id="+campus_id;
	// }
	const request = http.post(url,params);

	return (dispatch) =>
		request
			.then((response) =>
				dispatch({
					type: GET_ALL_INSTITUTES_DISCIPLINES_SUCCESS,
					payload: response.data,
				})
			)
			.catch((error) => {
				return dispatch({
					type: GET_ALL_INSTITUTES_DISCIPLINES_FAIL,
					payload: [],
				});
			});
}

export const GET_ALL_INSTITUTES_DISCIPLINES_RELATED_DEGREE_LEVELS =
	"GET_ALL_INSTITUTES_DISCIPLINES_RELATED_DEGREE_LEVELS";
export const GET_ALL_INSTITUTES_DISCIPLINES_RELATED_DEGREE_LEVELS_SUCCESS =
	"GET_ALL_INSTITUTES_DISCIPLINES_RELATED_DEGREE_LEVELS_SUCCESS";
export const GET_ALL_INSTITUTES_DISCIPLINES_RELATED_DEGREE_LEVELS_FAIL =
	"GET_ALL_INSTITUTES_DISCIPLINES_RELATED_DEGREE_LEVELS_FAIL";

export function getInstitutesDisciplinesRelatedDegreeLevels(uniId, params) {
	const url = "institute/" + uniId + "/degree_levels/";
	const request = http.post(url, params);

	return (dispatch) =>
		request
			.then((response) =>
				dispatch({
					type: GET_ALL_INSTITUTES_DISCIPLINES_RELATED_DEGREE_LEVELS_SUCCESS,
					payload: response.data,
				})
			)
			.catch((error) => {
				return dispatch({
					type: GET_ALL_INSTITUTES_DISCIPLINES_RELATED_DEGREE_LEVELS_FAIL,
					payload: [],
				});
			});
}

export const GET_ALL_INSTITUTES_DISCIPLINES_RELATED_INTAKES =
	"GET_ALL_INSTITUTES_DISCIPLINES_RELATED_INTAKES";
export const GET_ALL_INSTITUTES_DISCIPLINES_RELATED_INTAKES_SUCCESS =
	"GET_ALL_INSTITUTES_DISCIPLINES_RELATED_INTAKES_SUCCESS";
export const GET_ALL_INSTITUTES_DISCIPLINES_RELATED_INTAKES_FAIL =
	"GET_ALL_INSTITUTES_DISCIPLINES_RELATED_INTAKES_FAIL";

export function getInstitutesDisciplinesRelatedIntakes(uniId, params) {
	const url = "institute/" + uniId + "/intakes/";
	const request = http.post(url, params);

	return (dispatch) =>
		request
			.then((response) =>
				dispatch({
					type: GET_ALL_INSTITUTES_DISCIPLINES_RELATED_INTAKES_SUCCESS,
					payload: response.data,
				})
			)
			.catch((error) => {
				return dispatch({
					type: GET_ALL_INSTITUTES_DISCIPLINES_RELATED_INTAKES_FAIL,
					payload: [],
				});
			});
}

export const GET_ALL_INSTITUTES_DISCIPLINES_RELATED_SPECIALIZATIONS =
	"GET_ALL_INSTITUTES_DISCIPLINES_RELATED_SPECIALIZATIONS";
export const GET_ALL_INSTITUTES_DISCIPLINES_RELATED_SPECIALIZATIONS_SUCCESS =
	"GET_ALL_INSTITUTES_DISCIPLINES_RELATED_SPECIALIZATIONS_SUCCESS";
export const GET_ALL_INSTITUTES_DISCIPLINES_RELATED_SPECIALIZATIONS_FAIL =
	"GET_ALL_INSTITUTES_DISCIPLINES_RELATED_SPECIALIZATIONS_FAIL";

export function getInstitutesDisciplinesRelatedSpecializations(uniId, params) {
	const url = "institute/" + uniId + "/specializations/";
	const request = http.post(url, params);

	return (dispatch) =>
		request
			.then((response) =>
				dispatch({
					type: GET_ALL_INSTITUTES_DISCIPLINES_RELATED_SPECIALIZATIONS_SUCCESS,
					payload: response.data,
				})
			)
			.catch((error) => {
				return dispatch({
					type: GET_ALL_INSTITUTES_DISCIPLINES_RELATED_SPECIALIZATIONS_FAIL,
					payload: [],
				});
			});
}

export const GET_ALL_INSTITUTES = "GET_ALL_INSTITUTES";
export const GET_ALL_INSTITUTES_SUCCESS = "GET_ALL_INSTITUTES_SUCCESS";
export const GET_ALL_INSTITUTES_FAIL = "GET_ALL_INSTITUTES_FAIL";

export function getAllInstitutes() {
	const url = "institute/all";
	const request = http.get(url);

	return (dispatch) =>
		request
			.then((response) =>
				dispatch({
					type: GET_ALL_INSTITUTES_SUCCESS,
					payload: response.data,
				})
			)
			.catch((error) => {
				return dispatch({
					type: GET_ALL_INSTITUTES_FAIL,
					payload: [],
				});
			});
}
