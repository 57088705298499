import * as Actions from "../actions/map.actions";
const searchCoursesLocationInitialState = {
	data: [],
};

export const searchCoursesLocation = (
	state = searchCoursesLocationInitialState,
	action
) => {
	switch (action.type) {
		case Actions.SEARCH_LOCATION_COURSES_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.SEARCH_LOCATION_COURSES_FAIL: {
			return {
				...state,
				data: null,
			};
		}
		default: {
			return state;
		}
	}
};
