import * as Actions from "../actions/pageDescription.action";

const pageDescriptionInitialState = {
	data: [],
};

export const pageDescriptionDetailReducer = (
	state = pageDescriptionInitialState,
	action
) => {
	switch (action.type) {
		case Actions.GET_RELATED_PAGE_DETAIL_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.GET_RELATED_PAGE_DETAIL_FAIL: {
			return {
				...state,
				data: null,
			};
		}
		default: {
			return state;
		}
	}
};
