import { useState } from "react";
import CustomButton from "../../../components/CustomButton";
import React from 'react';
import Slider from "@material-ui/core/Slider";
import { useNavigate } from "react-router-dom-v5-compat";

const DurationFilter = ({ updateSearchParams,isBlurred,setOpenModal }) => {
    const [durationSliderValue, setDurationSliderValue] = useState([0, 5]);
    const [durationMarks, setDurationMarks] = useState([
        { value: 0, label: "0" },
        { value: 5, label: "5+" },
    ]);
    const [isOpen, setIsOpen] = useState(true);

    const navigate=useNavigate();

    const handleDurationSliderValue = (event, value) => {
        const firstValue = value[0].toString();
        const secondValue = value[1] === 5 ? "5+" : value[1].toString();

        const updatedMarks = [
            {
                value: value[0],
                label: firstValue,
            },
            {
                value: value[1],
                label: secondValue,
            },
        ];

        setDurationSliderValue(value);
        setDurationMarks(updatedMarks);
    };

    const handleApplySelection = () => {
        updateSearchParams({ course_duration: durationSliderValue.join(',') });
    }

    return (
        <>
            <div className="filterBox">
                <h6
                    style={{ cursor: "pointer" }}
                    onClick={() => setIsOpen((isOpen) => !isOpen)}
                    className="filterOpen"
                >
                    Duration in Year
                    {isOpen ? (
                        <i className="fa fa-angle-down"></i>
                    ) : (
                        <i className="fa fa-angle-right"></i>
                    )}
                </h6>
                {isOpen && (
                    <div className="content show">
                        <div className="filterBoxScroll">
                            <div className="rangeSlider">
                                <Slider
                                    max={5}
                                    value={durationSliderValue}
                                    step={0.5}
                                    marks={durationMarks}
                                    onChange={!isBlurred?handleDurationSliderValue:()=>setOpenModal(true)}
                                    aria-labelledby="range-slider"
                                />
                            </div>
                            <div className="text-center pb-2">
                                <CustomButton
                                    onClick={!isBlurred?handleApplySelection:()=>setOpenModal(true)}
                                    buttonText="Apply Duration Range"
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>

    )
}
export default DurationFilter;

