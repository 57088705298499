import React, { useEffect, useState } from "react";
import { http } from "../../services/apiService/apiService";
import {
	EmailIcon,
	EmailShareButton,
	FacebookIcon,
	FacebookMessengerShareButton,
	WhatsappIcon,
	WhatsappShareButton,
} from "react-share";

const ShareLinkModal = (props) => {
	const { urlParams } = props;
	const [selected, setSelected] = useState(false);
	const [urlValue, setUrlValue] = useState(urlParams);
	const [urlSpinner, setUrlSpinner] = useState(false);
	useEffect(() => {
		setSelected(false);
		// setUrlSpinner(true);
		// generateShareLink();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props]);

	function copyToClickBoard() {
		navigator.clipboard.writeText(urlValue);
		if (navigator.clipboard.writeText(urlValue)) {
			setSelected(true);
		}
	}
	const generateShareLink = async () => {
		let url = "short-url/";
		let trimmedString = window.location.href.split("?");
		let newParams = {
			uuid: "",
			payload: { urlParams, key: trimmedString[0] },
		};
		const request = await http.post(url, newParams);
		if (request.data.uuid) {
			setUrlValue(
				`https://timescoursefinder.com/loading?uuid=${request.data.uuid}`
			);
			setUrlSpinner(false);
		}
	};
	return (
		<div
			className="modal fade signinModal sharePageModal"
			id="sharePageModal"
			tabIndex="-1"
			role="dialog"
			aria-labelledby="exampleModalCenterTitle"
			aria-hidden="true"
			data-backdrop="static"
			data-keyboard="false"
		>
			<div className="modal-dialog modal-dialog-centered" role="document">
				<div className="modal-content">
					<div className="modal-body">
						<button
							type="button"
							className="close"
							data-dismiss="modal"
							id=""
							aria-label="Close"
						>
							<img
								src={require("../../../static/images/modal-times.svg").default}
								alt="banner"
							/>
						</button>

						<h3>Share Page Link</h3>
						<div className="shareSearch">
							<input
								type="text"
								disabled={urlSpinner}
								className="form-control"
								defaultValue={urlValue}
							/>
							<button
								className="btn defaultBtn"
								onClick={copyToClickBoard}
								disabled={urlSpinner}
							>
								{urlSpinner ? (
									<span className="spinner-border"/>
								) : selected ? (
									<span>Copied</span>
								) : (
									<span>Copy</span>
								)}
							</button>
						</div>
						<p>or Share via</p>
						<ul>
							<li>
								<WhatsappShareButton
									url={urlValue}
									disabled={urlSpinner}
									title={"Tap the Link below to open the website"}
								>
									<WhatsappIcon size="3rem" />
								</WhatsappShareButton>
							</li>
							<li>
								<FacebookMessengerShareButton
									appId={"875726697462209"}
									disabled={urlSpinner}
									redirectUri="https://timescoursefinder.com"
									url={urlValue}
									clientid={"102"}
									title="Tap the Link below to open website "
								>
									<FacebookIcon size={"3rem"} />
								</FacebookMessengerShareButton>
							</li>
							<li>
								<EmailShareButton
									disabled={urlSpinner}
									subject={"Course Detail"}
									body={"Click the given link " + urlValue + " ."}
								>
									<EmailIcon size={"3rem"} />
								</EmailShareButton>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ShareLinkModal;
