import { MAIN_SEARCH_LOCATION_TYPES } from "../../constants";

const LocationSearchBox = ({
    selectedLocation,
    clearLocationInput,
    onLocationRemove,
    showRegionModal,
    locationPlaceholder,
    locationInput,
    onFoucusLocation,
    onFoucusOutLocation,
    searchLocations,
    onFoucusLocationArrow,
    locationError,
    wrapperRef,
    suggestedLocation,
    onSelectSuggestedLocation,
    emptyResult,
    handleDialogConfirmOpen,
    isLocationFocus,
    locationRef
}) => {
    return (
        <div className={"searchBox"}>
            {selectedLocation.length >= 1 && (
                <button
                    className="unselectBtn"
                    onClick={() => {
                        clearLocationInput();
                    }}
                >
                    x
                </button>
            )}
            <div className="custom-dropdown">
                {!isLocationFocus &&
                    <div className="tcf-tag searchTagWrap">
                        {selectedLocation.length < 4
                            ? selectedLocation.map((item, index) => {
                                if (index < 3) {
                                    return (
                                        <div key={index} className="searchTag">
                                            {item.name}
                                            <button
                                                className="unselectBtn"
                                                onClick={(e) => {
                                                    onLocationRemove(index);
                                                }}
                                            >
                                                x
                                            </button>
                                        </div>
                                    );
                                } else {
                                    return null;
                                }
                            })
                            : selectedLocation.map((item, index) => {
                                if (index < 2) {
                                    return (
                                        <div key={index} className="searchTag">
                                            {item.name}
                                            <button
                                                className="unselectBtn"
                                                onClick={(e) => {
                                                    onLocationRemove(index);
                                                }}
                                            >
                                                x
                                            </button>
                                        </div>
                                    );
                                } else {
                                    return null;
                                }
                            })}
                        {selectedLocation.length >= 4 && (
                            <div className="searchTag" style={{ paddingRight: 5 }}>
                                <a
                                    href
                                    onClick={(e) => {
                                        showRegionModal();
                                    }}
                                >
                                    view More
                                </a>
                            </div>
                        )}
                    </div>
                }
                <div
                    className="btnWrap"
                >
                    <input
                        id="location_input"
                        placeholder={locationPlaceholder}
                        type="text"
                        autoComplete="off"
                        className="form-control"
                        value={locationInput}
                        onFocus={onFoucusLocation}
                        onBlur={onFoucusOutLocation}
                        onChange={(e) =>
                            searchLocations(e)
                        }
                        onKeyDown={(e) => searchLocations(e)}
                        ref={locationRef}
                    ></input>
                    <i
                        className="fa fa-angle-down"
                        onClick={onFoucusLocationArrow}
                    ></i>

                    {locationError && (
                        <p className="text-danger">Location is required</p>
                    )}
                </div>
                <div
                    id="locationOpen"
                    className="custom-dropdown-menu"
                    ref={wrapperRef}
                >
                    <div className="searchScroll">
                        <p style={{ paddingTop: "5px", margin: "0px !important" }}><b>Type &
                            Select Location</b></p>
                        {Object.keys(suggestedLocation).length ? (
                            <>
                                {suggestedLocation.map((item, index) => {
                                    return (
                                        <a href
                                            key={index}
                                            className="dropdown-item "
                                            onClick={(e) => {
                                                onSelectSuggestedLocation(
                                                    item,
                                                    MAIN_SEARCH_LOCATION_TYPES[4]
                                                );
                                            }}
                                        >
                                            {item.name}
                                        </a>
                                    );
                                })}
                            </>
                        ) : locationInput.length > 0 && suggestedLocation.length === 0 ? (
                            <p style={{ textAlign: "center" }}>{emptyResult}</p>
                        ) :
                            null
                        }
                    </div>
                    {locationInput.length < 1 ? <>
                        <a href
                            onClick={() =>
                                handleDialogConfirmOpen(MAIN_SEARCH_LOCATION_TYPES[3], "")
                            }
                            className="dropdown-item locationBtn"
                        >
                            <img
                                src={
                                    require("../../../static/images/search/any.svg").default
                                }
                                alt="any"
                            />
                            Any
                        </a>
                        {/* <a href
                            onClick={() =>
                                handleDialogConfirmOpen(MAIN_SEARCH_LOCATION_TYPES[0], "")
                            }
                            className="dropdown-item locationBtn"
                        >
                            <img
                                src={
                                    require("../../../static/images/search/use_my_current_location.svg")
                                        .default
                                }
                                alt="use_my_current_location"
                            />{" "}
                            Use my current location
                        </a> */}
                        <a href
                            onClick={() =>
                                handleDialogConfirmOpen(MAIN_SEARCH_LOCATION_TYPES[2], "Region")
                            }
                            className="dropdown-item locationBtn"
                        >
                            <img
                                src={
                                    require("../../../static/images/search/popular_regions.svg")
                                        .default
                                }
                                alt="popular_regions"
                            />
                            Popular Regions
                        </a>
                        <a href
                            onClick={() =>
                                handleDialogConfirmOpen(MAIN_SEARCH_LOCATION_TYPES[2], "Country")
                            }
                            className="dropdown-item locationBtn"
                        >
                            <img
                                src={
                                    require("../../../static/images/search/popular_countries.svg")
                                        .default
                                }
                                alt="popular_countries"
                            />
                            Popular Countries
                        </a>
                        <a href
                            className="dropdown-item locationBtn"
                            onClick={() =>
                                handleDialogConfirmOpen(MAIN_SEARCH_LOCATION_TYPES[2], "City")
                            }
                        >
                            <img
                                src={
                                    require("../../../static/images/search/popular_cities.svg")
                                        .default
                                }
                                alt="popular_cities"
                            />
                            Popular Cities
                        </a>
                    </> : null
                    }

                </div>
            </div>
        </div>
    )
}

export default LocationSearchBox;