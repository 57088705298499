import React from 'react';
import Slider from "@material-ui/core/Slider";
import CustomButton from '../CustomButton';

const DurationAndYearsIndividual = (props) => {
    return (
        <div className="filterBox">
            <h6 className="filterOpen">
                Duration in Year
                <i className="fa fa-angle-down" />
            </h6>
            <div className="content show">
                <div className="filterBoxScroll">
                    <div className="rangeSlider">
                        <Slider
                            max={5}
                            value={props.durationSliderValue}
                            step={0.5}
                            marks={props.durationMarks}
                            onChange={props.handleDurationSliderValue}
                            aria-labelledby="range-slider"
                        />
                    </div>

                    <CustomButton
                        onClick={props.onApplyFilters}
                        buttonText="Apply Duration Range"
                    />
                </div>
            </div>
        </div>
    );
};

export default DurationAndYearsIndividual;
