import React, { useEffect, useState } from "react";
import Pagination from "react-responsive-pagination";
import currencySvg from "../../../static/images/forms/Currency.svg";
import listViewSvg from "../../../static/images/list_view.svg";
import gridViewSvg from "../../../static/images/search-in-grid.svg";
// import searchInMapSvg from "../../../static/images/search-in-map.svg";
import ListViewCrdLoader from "../../components/loaders/listViewCardLoader";
import GridViewCrdLoader from "../../components/loaders/gridViewCardLoader";
import LoadingSpinner from "../../components/loader/loader";
import CourseViewMap from "../../components/map/courselistmap";
import CourseListCardView from "../../components/card/ListViewCoursesCard/course_list_view_card";
import shareSvg from "../../../static/images/share-white.svg";
import filterSvg from "../../../static/images/filter-icon.svg";
import $ from "jquery";
import { Link, useMediaQuery } from "@mui/material";
import ApplyNowModal from "../../components/modals/applynow_modal";
import CourseGridCardViewV2 from "../../components/card/GridViewCoursesCard/coures_grid_view_card_v2";
import { getDisciplinesReq } from "../../services/apiService";
import Filters from "./SearchPage.Filters";
import closeIcon from "../../../static/images/modal-times.svg";
import ShareLinkModal from "../../components/modals/ShareLinkModal";
import { useTheme } from "@mui/material/styles";

const Courses = (props) => {
  const {
    greaterCount,
    courses,
    showView,
    courseLoader,
    pageState,
    handlePageClick,
    coursesCount,
    handlePopularityCheck,
    selectedSortOption,
    sortOptions,
    clickSortOption,
    selectedCurrency,
    checkView,
    currencies,
    countLoader,
    searchParams,
    updateSearchParams,
    setSearchParams,
    coursesToShow,
    setOpenModal,
  } = props;

  const [disciplineData, setDisciplineData] = useState([]);
  const [showMobileFilters, setShowMobileFilters] = useState(false);
  const outerTheme = useTheme();
  const isMobile = useMediaQuery(outerTheme.breakpoints.down("sm"));

  const mobileFilters = () => {
    setShowMobileFilters(true);
  };

  const hideMobileFilter = () => {
    setShowMobileFilters(false);
  };

  const shareModal = () => {
    $("#sharePageModal").show();
  };

  const getDegreeData = async () => {
    try {
      const res = await getDisciplinesReq();
      const { data } = res;
      setDisciplineData(data);
    } catch (err) {
      console.log("Error", err);
    }
  };
  useEffect(() => {
    getDegreeData();
  }, []);
  return (
    <>
      <div className="mainContent" id="course">
        <div className="sortFilterTop">
          {searchParams.discipline || searchParams.search ? (
            countLoader ? (
              <h5>Searching For Desired Results</h5>
            ) 
            // : greaterCount ? (
            //   <h5> {coursesCount ? coursesCount : "No"} courses found  </h5>
            // ) 
            : coursesCount > 0 ? (
              <h5>{coursesCount} Results Found</h5>
            ) : (
              <h5>Oops! No courses match your criteria</h5>
            )
          ) : (
            <h5>Please select a Discipline</h5>
          )}
          {coursesCount > 0 &&
            (searchParams.discipline || searchParams.search) && (
              <div>
                <div className="popOptWrap">
                  <label className="customCheckbox">
                    <input
                      type="checkbox"
                      className={"form-control"}
                      value="p1"
                      onChange={(e) =>
                        handlePopularityCheck(e.target.value, e.target.checked)
                      }
                    />
                    <span>Recommended Courses</span>{" "}
                  </label>
                </div>
                <button
                  className="btn defaultBtn sharePgBtn"
                  data-target="#sharePageModal"
                  onClick={shareModal}
                  data-toggle="modal"
                >
                  <img src={shareSvg} alt="share-white" />
                  Share Page
                </button>
                <button
                  className="btn primarBtn filterNavBtn"
                  onClick={mobileFilters}
                >
                  <img src={filterSvg} alt="filter-icon" />
                  Filters
                </button>
              </div>
            )}
        </div>
        {coursesCount > 0 &&
          (searchParams.discipline || searchParams.search) && (
            <div className="sortFilter">
              {showView === "map" ? (
                <div />
              ) : (
                <div>
                  <p>Sort by:</p>
                  <div className="dropdown">
                    <button
                      className="btn dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {selectedSortOption ? (
                        <span>
                          <img
                            src={selectedSortOption.image}
                            height=""
                            width=""
                            alt={selectedSortOption.label}
                          />
                          {selectedSortOption.label}
                        </span>
                      ) : (
                        <span>Select Option</span>
                      )}
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <ul className="pl-0 mb-0">
                        {sortOptions.map((option, index) => {
                          if (!option.selected)
                            return (
                              <li
                                key={index}
                                className="dropdown-item"
                                onClick={(e) => clickSortOption(option, e)}
                              >
                                <img
                                  src={option?.image}
                                  height=""
                                  width=""
                                  alt={option.label}
                                />
                                {option.label}
                                {option?.label === "Duration" ? (
                                  <img src={option.icon} alt={option.label} />
                                ) : null}
                              </li>
                            );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              )}
              <div>
                <div className="filterCurrency">
                  <select
                    className="form-control"
                    value={selectedCurrency}
                    onChange={(e) => {
                      updateSearchParams({
                        currency: e.target.value,
                      });
                    }}
                  >
                    <option hidden>Select Currency</option>
                    <option value="">Default</option>
                    {currencies.map((item, index) => {
                      return (
                        <option key={index} value={item.key}>
                          {item?.description} ({item?.display_name})
                        </option>
                      );
                    })}
                  </select>
                  <img src={currencySvg} alt="Currency" />
                </div>

                <button
                  className={
                    showView === "list"
                      ? "btn primaryBtn active"
                      : "btn primaryBtn"
                  }
                  onClick={() =>
                    checkView(showView === "list" ? "grid" : "list")
                  }
                >
                  <img
                    src={showView === "grid" ? listViewSvg : gridViewSvg}
                    alt="list_view"
                  />
                  {showView === "grid" ? "List View" : "Grid View"}
                </button>

                {/* <button
                            onClick={() => checkView("map")}
                            className={
                                showView === "map" ? "btn primaryBtn active" : "btn primaryBtn"
                            }
                        >
                            <img
                                src={searchInMapSvg}
                                alt="search-in-map"
                            />
                            Map View
                        </button> */}
              </div>
            </div>
          )}

        {searchParams.discipline || searchParams.search ? (
          (showView === "list" && (
            <div>
              {courseLoader ? (
                <ListViewCrdLoader />
              ) : (
                <div>
                  {courses?.length > 0 ? (
                    <CourseListCardView
                      courses={courses}
                      page={pageState.currentPage}
                    />
                  ) : (
                    <div className="row d-flex pt-2">
                      <div className="col">
                        <p>
                          Here are some suggestions to help you find the courses
                          you're looking for
                        </p>

                        <p>
                          &nbsp;&nbsp;&nbsp;&#10022; Consider expanding your
                          search criteria to find more options<br></br>
                          &nbsp;&nbsp;&nbsp;&#10022; Double-check your spelling
                          and try different keywords
                        </p>
                        <p className="mb-0">
                          <b>Explore Related Categories</b>
                          <br></br>
                          Take a look at related categories for similar course
                          options<br></br>
                        </p>
                        <div className="row d-flex">
                          <div className="col-md-4 mb-1">
                            <p className="mb-0">
                              <Link
                                className="pr-4"
                                onClick={() => {
                                  window.open(
                                    "/search?&discipline=engineering-technology",
                                    "_self"
                                  );
                                }}
                              >
                                Engineering & Technology
                              </Link>
                            </p>
                          </div>
                          <div className="col-md-4 mb-1">
                            <p className="mb-0">
                              <Link
                                className="pr-4"
                                onClick={() => {
                                  window.open(
                                    "/search?&discipline=business-management",
                                    "_self"
                                  );
                                }}
                              >
                                Business & Management
                              </Link>
                            </p>
                          </div>
                          <div className="col-md-4 mb-">
                            <p className="mb-0">
                              <Link
                                className="pr-4"
                                onClick={() => {
                                  window.open(
                                    "/search?&discipline=computer-it",
                                    "_self"
                                  );
                                }}
                              >
                                Computer & IT
                              </Link>
                            </p>
                          </div>
                        </div>

                        <p>
                          <b>Browse Popular Courses</b>
                          <br></br>
                          Explore our most popular courses that other users have
                          found valuable<br></br>
                        </p>
                        <div className="row d-flex w-75">
                          <div className="col-md-6 mb-1">
                            <p className="mb-0">
                              <Link
                                className="pr-4"
                                onClick={() => {
                                  window.open(
                                    "/search?&search=data+analytics",
                                    "_self"
                                  );
                                }}
                              >
                                Data Analytics
                              </Link>
                            </p>
                          </div>
                          <div className="col-md-6 mb-1">
                            <p className="mb-0">
                              <Link
                                className="pr-4"
                                onClick={() => {
                                  window.open(
                                    "/search?&search=software+engineering",
                                    "_self"
                                  );
                                }}
                              >
                                Software Engineering
                              </Link>
                            </p>
                          </div>
                          <div className="col-md-6 mb-1">
                            <p className="mb-0">
                              <Link
                                className="pr-4"
                                onClick={() => {
                                  window.open(
                                    "/search?&search=computer+science",
                                    "_self"
                                  );
                                }}
                              >
                                Computer Science
                              </Link>
                            </p>
                          </div>
                          <div className="col-md-6 mb-1">
                            <p className="mb-0">
                              <Link
                                className="pr-4"
                                onClick={() => {
                                  window.open(
                                    "/search?&search=cyber+security",
                                    "_self"
                                  );
                                }}
                              >
                                Cyber Security
                              </Link>
                            </p>
                          </div>
                          <div className="col-md-6 mb-1">
                            <p className="mb-0">
                              <Link
                                className="pr-4"
                                onClick={() => {
                                  window.open(
                                    "/search?&search=mechanical+engineering",
                                    "_self"
                                  );
                                }}
                              >
                                Mechanical Engineering
                              </Link>
                            </p>
                          </div>
                          <div className="col-md-6 mb-1">
                            <p className="mb-0">
                              <Link
                                className="pr-4"
                                onClick={() => {
                                  window.open(
                                    "/search?&search=psychology",
                                    "_self"
                                  );
                                }}
                              >
                                Psychology
                              </Link>
                            </p>
                          </div>
                        </div>
                        <p>
                          Need personalized assistance? Feel free to{" "}
                          <Link
                            onClick={() => {
                              $("#ApplyNow").modal("show");
                            }}
                          >
                            contact
                          </Link>{" "}
                          our support team for help
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {coursesCount > 0 ? (
                <div className="paginationWrap">
                  <Pagination
                    current={searchParams.currentPage}
                    total={searchParams.pageCount}
                    onPageChange={handlePageClick}
                    previousLabel={"Prev"}
                    nextLabel={"Next"}
                  />
                </div>
              ) : null}
            </div>
          )) ||
          (showView === "grid" && (
            <div>
              {courseLoader ? (
                <GridViewCrdLoader />
              ) : (
                <div>
                  {courses?.length > 0 ? (
                    <div>
                      <CourseGridCardViewV2
                        courses={courses}
                        page={pageState.currentPage}
                        coursesToShow={coursesToShow}
                      />
                    </div>
                  ) : (
                    !courseLoader && (
                      <div className="row d-flex pt-2">
                        <div className="col">
                          <p>
                            Here are some suggestions to help you find the
                            courses you're looking for
                          </p>

                          <p>
                            &nbsp;&nbsp;&nbsp;&#10022; Consider expanding your
                            search criteria to find more options<br></br>
                            &nbsp;&nbsp;&nbsp;&#10022; Double-check your
                            spelling and try different keywords
                          </p>
                          <p className="mb-0">
                            <b>Explore Related Categories</b>
                            <br></br>
                            Take a look at related categories for similar course
                            options<br></br>
                          </p>
                          <div className="row d-flex">
                            <div className="col-md-4 mb-1">
                              <p className="mb-0">
                                <Link
                                  className="pr-4"
                                  onClick={() => {
                                    window.open(
                                      "/search?&discipline=engineering-technology",
                                      "_self"
                                    );
                                  }}
                                >
                                  Engineering & Technology
                                </Link>
                              </p>
                            </div>
                            <div className="col-md-4 mb-1">
                              <p className="mb-0">
                                <Link
                                  className="pr-4"
                                  onClick={() => {
                                    window.open(
                                      "/search?&discipline=business-management",
                                      "_self"
                                    );
                                  }}
                                >
                                  Business & Management
                                </Link>
                              </p>
                            </div>
                            <div className="col-md-4 mb-">
                              <p className="mb-0">
                                <Link
                                  className="pr-4"
                                  onClick={() => {
                                    window.open(
                                      "/search?&discipline=computer-it",
                                      "_self"
                                    );
                                  }}
                                >
                                  Computer & IT
                                </Link>
                              </p>
                            </div>
                          </div>

                          <p className="mb-0 mt-3">
                            <b>Browse Popular Courses</b>
                            <br></br>
                            Explore our most popular courses that other users
                            have found valuable<br></br>
                          </p>
                          <div className="row d-flex w-75">
                            <div className="col-md-6 mb-1">
                              <p className="mb-0">
                                <Link
                                  className="pr-4"
                                  onClick={() => {
                                    window.open(
                                      "/search?&search=data+analytics",
                                      "_self"
                                    );
                                  }}
                                >
                                  Data Analytics
                                </Link>
                              </p>
                            </div>
                            <div className="col-md-6 mb-1">
                              <p className="mb-0">
                                <Link
                                  className="pr-4"
                                  onClick={() => {
                                    window.open(
                                      "/search?&search=software+engineering",
                                      "_self"
                                    );
                                  }}
                                >
                                  Software Engineering
                                </Link>
                              </p>
                            </div>
                            <div className="col-md-6 mb-1">
                              <p className="mb-0">
                                <Link
                                  className="pr-4"
                                  onClick={() => {
                                    window.open(
                                      "/search?&search=computer+science",
                                      "_self"
                                    );
                                  }}
                                >
                                  Computer Science
                                </Link>
                              </p>
                            </div>
                            <div className="col-md-6 mb-1">
                              <p className="mb-0">
                                <Link
                                  className="pr-4"
                                  onClick={() => {
                                    window.open(
                                      "/search?&search=cyber+security",
                                      "_self"
                                    );
                                  }}
                                >
                                  Cyber Security
                                </Link>
                              </p>
                            </div>
                            <div className="col-md-6 mb-1">
                              <p className="mb-0">
                                <Link
                                  className="pr-4"
                                  onClick={() => {
                                    window.open(
                                      "/search?&search=mechanical+engineering",
                                      "_self"
                                    );
                                  }}
                                >
                                  Mechanical Engineering
                                </Link>
                              </p>
                            </div>
                            <div className="col-md-6 mb-1">
                              <p className="mb-0">
                                <Link
                                  className="pr-4"
                                  onClick={() => {
                                    window.open(
                                      "/search?&search=psychology",
                                      "_self"
                                    );
                                  }}
                                >
                                  Psychology
                                </Link>
                              </p>
                            </div>
                          </div>

                          <p>
                            Need personalized assistance? Feel free to{" "}
                            <Link
                              onClick={() => {
                                $("#ApplyNow").modal("show");
                              }}
                            >
                              contact
                            </Link>{" "}
                            our support team for help
                          </p>
                        </div>
                      </div>
                    )
                  )}
                </div>
              )}
              {coursesCount > 0 ? (
                <div className="paginationWrap">
                  <Pagination
                    current={pageState.currentPage}
                    total={pageState.pageCount}
                    onPageChange={handlePageClick}
                    previousLabel={"Prev"}
                    nextLabel={"Next"}
                  />
                </div>
              ) : null}
            </div>
          )) ||
          (showView === "map" &&
            (courseLoader ? (
              <LoadingSpinner />
            ) : (
              <div>
                {courses.length ? (
                  <CourseViewMap institutesLocation={courses} />
                ) : (
                  "Not Found"
                )}
              </div>
            )))
        ) : (
          <div className="row d-flex pt-2">
            <div className="col">
              <p>
                Here are some suggestions to help you find the courses you're
                looking for
              </p>

              <b>Explore Disciplines</b>
              <br></br>
              <div className="row d-flex w-75">
                {disciplineData.map((discipline, i) => (
                  <div key={i} className="col-md-6 mb-1">
                    <p className="mb-0">
                      <Link
                        className="pr-4"
                        onClick={() => {
                          window.open(
                            `/search?&discipline=${discipline.slug}`,
                            "_self"
                          );
                        }}
                      >
                        {discipline.name}
                      </Link>
                    </p>
                  </div>
                ))}
              </div>

              <div className="mt-3">
                <b>Browse Popular Courses</b>
                <br></br>
                <p>
                  Explore our most popular courses that other users have found
                  valuable
                </p>
                <div className="row d-flex w-75 ">
                  <div className="col-md-6 mb-1">
                    <p className="mb-0">
                      <Link
                        className="pr-4"
                        onClick={() => {
                          window.open(
                            "/search?&search=data+analytics",
                            "_self"
                          );
                        }}
                      >
                        Data Analytics
                      </Link>
                    </p>
                  </div>
                  <div className="col-md-6 mb-1">
                    <p className="mb-0">
                      <Link
                        className="pr-4"
                        onClick={() => {
                          window.open(
                            "/search?&search=software+engineering",
                            "_self"
                          );
                        }}
                      >
                        Software Engineering
                      </Link>
                    </p>
                  </div>
                  <div className="col-md-6 mb-1">
                    <p className="mb-0">
                      <Link
                        className="pr-4"
                        onClick={() => {
                          window.open(
                            "/search?&search=computer+science",
                            "_self"
                          );
                        }}
                      >
                        Computer Science
                      </Link>
                    </p>
                  </div>
                  <div className="col-md-6 mb-1">
                    <p className="mb-0">
                      <Link
                        className="pr-4"
                        onClick={() => {
                          window.open(
                            "/search?&search=cyber+security",
                            "_self"
                          );
                        }}
                      >
                        Cyber Security
                      </Link>
                    </p>
                  </div>
                  <div className="col-md-6 mb-1">
                    <p className="mb-0">
                      <Link
                        className="pr-4"
                        onClick={() => {
                          window.open(
                            "/search?&search=mechanical+engineering",
                            "_self"
                          );
                        }}
                      >
                        Mechanical Engineering
                      </Link>
                    </p>
                  </div>
                  <div className="col-md-6 mb-1">
                    <p className="mb-0">
                      <Link
                        className="pr-4"
                        onClick={() => {
                          window.open("/search?&search=psychology", "_self");
                        }}
                      >
                        Psychology
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <p>
                Need personalized assistance? Feel free to{" "}
                <Link
                  onClick={() => {
                    $("#ApplyNow").modal("show");
                  }}
                >
                  contact
                </Link>{" "}
                our support team for help
              </p>
            </div>
          </div>
        )}
      </div>
      {/* <ShareLinkModal /> */}
      <div
        className="modal fade signinModal whyFormModal"
        id="ApplyNow"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <ApplyNowModal />
      </div>

      <div
        className={`filterNav mobileFilter ${showMobileFilters ? "show" : ""}`}
      >
        <button className="closeFilterNav" onClick={hideMobileFilter}>
          <img src={closeIcon} alt="modal-times" />
        </button>
        <div className="sidebar filterSidebar">
          {isMobile && (
            <Filters
           
            setOpenModal={setOpenModal}
              searchParams={searchParams}
              updateSearchParams={updateSearchParams}
              setSearchParams={setSearchParams}
            />
          )}
        </div>
      </div>
      {/* <ShareLinkModal urlParams={`${window.location.origin}${location.pathname}${location.search}`} /> */}
      <ShareLinkModal urlParams={`${window.location.href}`} />
    </>
  );
};

export default Courses;
