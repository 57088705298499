import { http } from "../../../services/apiService/apiService";
/// map-course

export const SEARCH_LOCATION_COURSES = "SEARCH_LOCATION_COURSES";
export const SEARCH_LOCATION_COURSES_SUCCESS =
	"SEARCH_LOCATION_COURSES_SUCCESS";
export const SEARCH_LOCATION_COURSES_FAIL = "SEARCH_LOCATION_COURSES_FAIL";

export function searchCourseLocation(params, pageId) {
	let url = "course/location_search/";
	if (pageId) {
		url = url + "?page=" + pageId;
	}
	const request = http.post(url, params);

	return (dispatch) =>
		request
			.then((response) =>
				dispatch({
					type: SEARCH_LOCATION_COURSES_SUCCESS,
					payload: response.data,
				})
			)
			.catch((error) => {
				return dispatch({
					type: SEARCH_LOCATION_COURSES_FAIL,
					payload: [],
				});
			});
}
