import { http } from "../../../services/apiService/apiService";
// /countries

export const GET_ALL_CURRENCY  = "GET_ALL_CURRENCY";
export const GET_ALL_CURRENCY_SUCCESS = "GET_ALL_CURRENCY_SUCCESS";
export const GET_ALL_CURRENCY_FAIL = "GET_ALL_CURRENCY_FAIL";


export function getAllCurrencies() {
    const request = http.get("currency/all");

    return (dispatch) =>
        request
            .then((response) =>
                dispatch({
                    type: GET_ALL_CURRENCY_SUCCESS,
                    payload: response.data,
                })
            )
            .catch((error) => {
                return dispatch({
                    type: GET_ALL_CURRENCY_FAIL,
                    payload: [],
                });
            });
}


