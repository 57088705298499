import React, { useEffect, useState } from "react";

function IntakeCheckInput(props) {
	const { levels, index, selectedIntakes } = props;
	const [levelCheck, setLevelCheck] = useState(() => {
		return !!props.status;
	});
	useEffect(() => {
		if (selectedIntakes.some((month) => month.month === levels.month)) {
			setLevelCheck(true);
		} else {
			setLevelCheck(false);
		}
	}, [props.rand]);
	const onChangeStateCheck = (state, check) => {
		setLevelCheck(!check);
		props.onIntakeUpdate(state, !check);
	};
	return (
		<>
			<div className="filterItemWrap">
				<div id={props.levels.name} className="filterItem">
					<input
						key={props.index + Math.floor(Math.random() * 1000)}
						type="checkbox"
						onChange={() => {
							onChangeStateCheck(props.levels, levelCheck);
						}}
						checked={levelCheck}
					></input>
					<span className={levelCheck === true ? "active" : ""}>
						{props.levels.month} (
						{props.levels.course_count ? props.levels.course_count : "0"})
					</span>
				</div>
			</div>
		</>
	);
}

export default IntakeCheckInput;
