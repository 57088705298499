import * as Actions from "../actions/discipline.actions";
import {
	GET_COUNTRY_ALL_DISCIPLINES_FAIL,
	GET_COUNTRY_ALL_DISCIPLINES_SUCCESS,
} from "../actions/discipline.actions";

const initialState = {
	data: [],
};

export const allDisciplineLevels = (state = initialState, action) => {
	switch (action.type) {
		case Actions.GET_ALL_DISCIPLINES_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.GET_ALL_DISCIPLINES_FAIL: {
			return {
				...state,
				data: null,
			};
		}
		default: {
			return state;
		}
	}
};
const initialStateDisciplineDetail = {
	data: [],
};

export const disciplineDetailById = (
	state = initialStateDisciplineDetail,
	action
) => {
	switch (action.type) {
		case Actions.GET_DISCIPLINE_DETAIL_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.GET_DISCIPLINE_DETAIL_FAIL: {
			return {
				...state,
				data: null,
			};
		}
		default: {
			return state;
		}
	}
};

const countryAllDisciplinesInitialState = {
	data: [],
};

export const countryAllDisciplines = (
	state = countryAllDisciplinesInitialState,
	action
) => {
	switch (action.type) {
		case Actions.GET_COUNTRY_ALL_DISCIPLINES_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.GET_COUNTRY_ALL_DISCIPLINES_FAIL: {
			return {
				...state,
				data: null,
			};
		}
		default: {
			return state;
		}
	}
};

const disciplineSpecializationsInitialState = {
	data: [],
};

export const disciplineAllSpecialization = (
	state = disciplineSpecializationsInitialState,
	action
) => {
	switch (action.type) {
		case Actions.GET_DISCIPLINE_ALL_SPECIALIZATION_SUCCESS: {
			return {
				...state,
				data: action.payload,
			};
		}
		case Actions.GET_DISCIPLINE_ALL_SPECIALIZATION_FAIL: {
			return {
				...state,
				data: null,
			};
		}
		default: {
			return state;
		}
	}
};
