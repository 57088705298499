import { useEffect, useRef, useState } from "react";
import { getInstituteNamesFilterReq } from "../../../services/apiService";
import CustomFilter from "../../../components/CustomFilter";
import { useNavigate } from "react-router-dom-v5-compat";

const InstituteNameFilter = ({
  searchParams,
  updateSearchParams,
  isBlurred,
  setOpenModal,
}) => {
  const [instituteData, setInstituteData] = useState([]);
  const [checkedInstitutes, setCheckedInstitutes] = useState({});
  const [isOpen, setIsOpen] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [nextLoader, setNextLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [isFetchedAll, setIsFetchedAll] = useState(false);
  const [updatedFilters, setUpdatedFilters] = useState([]);

  const navigate = useNavigate();

  const listInnerRefInstitute = useRef();
  let filtered = "";

  const getFilterData = async (page) => {
    setIsLoading(true);
    try {
      let params = {
        discipline: searchParams?.discipline,
        specialization: searchParams?.specialization,
        degreelevel_type: searchParams?.degreelevel_type,
        degreelevel: searchParams?.degreelevel,
        country: searchParams?.country,
        state: searchParams?.state,
        city: searchParams?.city,
        institute: searchParams?.institute,
        institute_type: searchParams?.institute_type,
        course_duration: searchParams?.course_duration,
        fee_range: searchParams?.fee_range,
        intake_month: searchParams?.intake_month,
        search: searchParams?.search,
        page: page,
      };
      const res = await getInstituteNamesFilterReq(params);
      const { data } = res;

      if (data.result.length > 0) {
        setInstituteData((prevData) => [...prevData, ...data.result]);
      } else {
        setIsFetchedAll(true);
      }

      setCurrentPage(data.page);
    } catch (err) {
      console.error("Error:", err);
    }
    setIsLoading(false);
  };

  if (instituteData) {
    filtered = instituteData.filter((item) =>
      item?.name?.toLowerCase().includes(query.toLowerCase())
    );
  }

  const handleChange = (e) => {
    const filterValue = e.target.value;
    const { id, checked } = e.target;
    setCheckedInstitutes({ ...checkedInstitutes, [id]: checked });

    let params = searchParams.institute;
    if (params.length) {
      params = params.split(",");
    }

    const _updatedFilters = updatedFilters.includes(filterValue)
      ? updatedFilters.filter((value) => value !== filterValue)
      : [...updatedFilters, filterValue];

    setUpdatedFilters(_updatedFilters);
  };

  const onApply = () => {
    updateSearchParams({ institute: updatedFilters.join(",") });
  };

  const onScroll = async () => {
    if (!isFetchedAll) {
      if (listInnerRefInstitute.current) {
        const element = document.getElementById("scrollableDivInstitute");
        const { scrollTop, scrollHeight, clientHeight } = element;
        if (Math.round(scrollTop + clientHeight) === scrollHeight) {
          if (currentPage) {
            setNextLoader(true);
            // const params = getFilterSearchParams();
            await getFilterData(currentPage + 1);

            setNextLoader(false);
            element.scrollTo({
              bottom: scrollTop + clientHeight === scrollHeight,
              behavior: "smooth",
            });
          }
        }
      }
    }
  };

  useEffect(() => {
    if (!searchParams.institute) {
      setInstituteData([]);
      getFilterData(1);
    }
  }, [searchParams]);

  return (
    <>
      <CustomFilter
       setOpenModal={setOpenModal}
        isBlurred={isBlurred}
        naviagte={navigate}
        title="Institute"
        data={instituteData}
        searchPlaceholder="Search Institute"
        checkedItems={checkedInstitutes}
        handleChangeItem={handleChange}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onScroll={onScroll}
        listInnerRef={listInnerRefInstitute}
        currentPage={currentPage}
        nextLoader={nextLoader}
        isLoading={isLoading}
        hasSearch={true}
        setQuery={setQuery}
        filtered={filtered}
        isFetchedAll={isFetchedAll}
        onApply={onApply}
      />
    </>
  );
};
export default InstituteNameFilter;
