import profileBanner from '../../../static/images/account/banner-profile.jpg'
import profileBannerMobile from '../../../static/images/account/banner-profile-mobile.jpg'
import { styled } from '@mui/system';
import { Tab as BaseTab, tabClasses } from '@mui/base/Tab';
import { TabPanel as BaseTabPanel } from '@mui/base/TabPanel';
import { buttonClasses } from '@mui/base/Button';
import { TabsList as BaseTabsList } from '@mui/base/TabsList';

export const styles = {
  withBgImg: {
    backgroundImage: `url(${profileBanner})`,
    backgroundSize: 'cover',
    borderRadius: '12px',
    height: '250px',
    backgroundPosition: 'right'
  },
  profileImage: {
    width: '80px',
    height: '80px',
    borderRadius: '50%',
    border: '2px dotted #ccc',
    overflow: 'hidden',
    background: '#E6E8E7',
    padding: '5px'
  },
  withBgImgMobile: {
    backgroundImage: `url(${profileBannerMobile})`,
    backgroundSize: 'cover',
    borderRadius: '12px',
    height: '150px',
    backgroundPosition: 'right'
  },
  gridContainer: {
    height: '100%', // Set the height of the grid container to 100% to match the height of the Box
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  educationModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '15px'
  },
  certificationModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '75%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '15px',
    
  }
};

export const purple = {
  50: '#F0F7FF',
  100: '#C2E0FF',
  200: '#DDE4FF',
  300: '#66B2FF',
  400: '#3399FF',
  500: '#DDE4FF',
  600: '#C2CCFF',
  700: '#0059B2',
  800: '#004C99',
  900: '#5551FF',
};

export const Tab = styled(BaseTab)`
  font-family: 'IBM Plex Sans', sans-serif;
  color: #232323;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  background-color: transparent;
  padding: 10px 30px;
  border: none;
  border-radius: 25px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: #DAE2ED;
  }

  &:focus {
    color: #232323;
    outline: 3px solid ${purple[200]};
  }

  &.${tabClasses.selected} {
    background-color: ${purple[600]};
    color: #232323;
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const TabPanel = styled(BaseTabPanel)(
  ({ theme }) => `
  width: 100%;
  font-family: 'IBM Plex Sans', sans-serif;
  padding: 20px 0px;
  `,
);

export const TabsList = styled(BaseTabsList)(
  ({ theme }) => `
  min-width: 200px;
  width: fit-content;
  background-color: ${purple[500]};
  border-radius: 25px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  `,
);