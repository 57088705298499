import React, { useEffect, useState } from "react";
import $ from "jquery";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";
import { getData } from "country-list";
import { httpCrm } from "../../services/apiService/apiService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Alert } from "@mui/lab";
import CourseSelectionFilter from "./course_selection_filter";
import { http } from "../../services/apiService/apiService";
import { useDispatch } from "react-redux";
import DatePicker from "react-date-picker";
const customStyles = {
	option: (provided) => ({
		...provided,
		color: "black",
	}),
	control: (provided) => ({
		...provided,
		color: "black",
	}),
	singleValue: (provided) => ({
		...provided,
		color: "black",
	}),
};
export default function ApplyNowModal(props) {

	const phoneTypeOptions = [
		{ value: "call", label: "Call" },
		{ value: "whatsapp", label: "WhatsApp" },
		{ value: "other", label: "Other" },
	];
	const citiesOptions = [
		{ value: "toronto", label: "Toronto" },
		{ value: "islamabad", label: "Islamabad" },
		{ value: "other", label: "Other" },
	];

	const EnglishTestOptions = [
		{
			value: "IELTS",
			label: "International English Language Testing System (IELTS)",
		},
		{
			value: "TOEIC",
			label: "Test Of English for International Communication (TOEIC)",
		},
		{ value: "OPI", label: " Oral Proficiency Interview (OPI)" },
		{ value: "PET", label: "Cambridge English Preliminary (PET)" },
		{ value: "FCE", label: "Cambridge English First (FCE)" },
		{ value: "CAE", label: "Cambridge English Advanced (CAE)" },
		{ value: "CPE", label: "Cambridge English Proficiency (CPE)" },
		{ value: "TOEFL", label: "TOEFL" },
		{ value: "PTE", label: "PTE" },
	];

	const courseOptions = [
		{ value: "bscs", label: "Bacholors of Computer Science" },
		{ value: "msel", label: "Masters in English literature" },
		{ value: "other", label: "Other" },
	];

	const months = {
		"01": "January",
		"02": "February",
		"03": "March",
		"04": "April",
		"05": "May",
		"06": "June",
		"07": "July",
		"08": "August",
		"09": "September",
		10: "October",
		11: "November",
		12: "December",
	};

	let countriesOption = getData();

	countriesOption = countriesOption.map(function (item) {
		return {
			value: item.code,
			label: item.name,
		};
	});
	const dispatch = useDispatch();
	const [fullname, setFullname] = useState(
		localStorage.getItem("fname") ? localStorage.getItem("fname") + " " + (localStorage.getItem("lname") ? localStorage.getItem("lname") : '') : ''
	);
	const [firstname, setFirstName] = useState("");
	const [lastname, setLastName] = useState("");
	const [email, setEmail] = useState(localStorage.getItem("user_email") || '');
	const [phoneType, setPhoneType] = useState("");
	const [phNumber, setPhNumber] = useState(localStorage.getItem("user_number"));
	const [countryCode, setCountryCode] = useState("");
	const [start, setStart] = useState("");
	const [residence, setResidence] = useState("");
	const [citizenship, setCitizenship] = useState("");
	const [selectCourse, setSelectCourse] = useState("");
	const [currentCity, setCurrentCity] = useState("");
	const [preferredCountry, setPreferredCountry] = useState("");

	const [fullnameError, setFullnameError] = useState("");
	const [firstnameError, setFirstnameError] = useState("");
	const [lastnameError, setLastnameError] = useState("");
	const [emailError, setEmailError] = useState("");
	const [phoneTypeError, setPhoneTypeError] = useState("");
	const [phNumberError, setPhNumberError] = useState("");
	const [reqCheck, setReqCheck] = useState(false);
	const [reqCheckError, setReqCheckError] = useState("");
	const [citizenshipError, setCitizenshipError] = useState("");
	const [residenceError, setResidenceError] = useState("");
	const [currentCityError, setCurrentCityError] = useState("");
	const [startError, setStartError] = useState("");
	const [resultError, setResultError] = useState("");
	const [schoolQualificationError, setSchoolQualificationError] = useState("");
	const [collegeQualificationError, setCollegeQualificationError] = useState("");
	const [englishTestScoreError, setEnglishTestScoreError] = useState("");

	const [examTestError, setExamTestError] = useState("");
	const [englishTestScheduleDateError, setEnglishTestScheduleDateError] = useState("");
	const [schoolQualification, setSchoolQualification] = useState("");
	const [collegeQualification, setCollegeQualification] = useState("");
	const [bachelorQualification, setBachelorQualification] = useState("");
	const [masterQualification, setMasterQualification] = useState("");
	const [masterYear, setMasterYear] = useState("");
	const [bachelorYear, setBachelorYear] = useState("");
	const [collegeYear, setCollegeYear] = useState("");
	const [schoolYear, setSchoolYear] = useState("");
	const [englishProficiency, setEnglishProficiency] = useState("");
	const [englishTestType, setEnglishTestType] = useState("");
	const [englishTestScore, setEnglishTestScore] = useState("");
	const [englishTestSpeakingScore, setEnglishTestSpeakingScore] = useState("");
	const [englishTestListeningScore, setEnglishTestListeningScore] =
		useState("");
	const [englishTestReadingScore, setEnglishTestReadingScore] = useState("");
	const [englishTestWritingScore, setEnglishTestWritingScore] = useState("");
	const [englishTestScheduleDate, setEnglishTestScheduleDate] = useState("");
	const [englishTestTakenDate, setEnglishTestTakenDate] = useState("");
	const [satTest, setSatTestAppeared] = useState(false);
	const [gmatTest, setGmatTestAppeared] = useState(false);
	const [lsatTest, setLsatTestAppeared] = useState(false);
	const [greTest, setGreTestAppeared] = useState(false);
	const [mcatTest, setMcatTestAppeared] = useState(false);
	const [examInAppearedScore, setExamInAppearedScore] = useState("");
	const [levelForStudy, setLevelForStudy] = useState("");
	const [courseObject, setCourseObject] = useState(null);
	const [date, setDate] = useState(null);

	const examTestScoreHandleChange = (value) => {
		if (value.target.value) {
			setExamInAppearedScore(value.target.value);
			setResultError("")
		} else {
			setExamInAppearedScore("")
			setResultError("required");

		}

	};

	const schoolQualificationHandleChange = (value) => {
		setSchoolQualification(value);
	};

	const englishTestTypeHandleChange = (value) => {
		setEnglishTestType(value);
	};

	const handleEnglishTestScoreChange = (value) => {
		setEnglishTestScore(value);
	};

	const handleListeningScoreChange = (value) => {
		setEnglishTestListeningScore(value);
	};

	const handleSpeakingScoreChange = (value) => {
		setEnglishTestSpeakingScore(value);
	};

	const handleReadingScoreChange = (value) => {
		setEnglishTestReadingScore(value);
	};

	const handleWritingScoreChange = (value) => {
		setEnglishTestWritingScore(value);
	};

	const handleEnglishTestTakenDateChange = (value) => {
		setEnglishTestTakenDate(value.target.value);
	};

	const handleEnglishScheduledTestDate = (value) => {

		if (value.target.value) {
			setEnglishTestScheduleDate(value.target.value);
			setEnglishTestScheduleDateError("");
		}
		else {
			setEnglishTestScheduleDate("");
			setEnglishTestScheduleDateError("required");
		}

	};

	const handleSchoolYear = (value) => {
		setSchoolYear(value.target.value.split("-")[0]);
	};

	const collegeQualificationHandleChange = (value) => {
		setCollegeQualification(value);
	};

	const handleCollegeYear = (value) => {
		setCollegeYear(value.target.value.split("-")[0]);
	};

	const bachelorQualificationHandleChange = (value) => {
		setBachelorQualification(value);
	};

	const handleBachelorsYear = (value) => {
		setBachelorYear(value.target.value.split("-")[0]);
	};

	const masterQualificationHandleChange = (value) => {
		setMasterQualification(value);
	};

	const handleMastersYear = (value) => {
		setMasterYear(value.target.value.split("-")[0]);
	};

	const showPopup = () => {
		return (
			<div>
				<div className="form-group">
					<Select
						name="English_Test_Type"
						placeholder={"Select English Test Type"}
						value={englishTestType}
						isSearchable={true}
						options={EnglishTestOptions}
						// className={ ? "is-invalid" : ""}
						onChange={englishTestTypeHandleChange}
						styles={customStyles}
					/>
					<img
						src={require("../../../static/images/forms/test_type.svg").default}
						alt="test_type"
						height=""
						width=""
						className="formIcon"
					/>
				</div>

				<div className="row scoreRow">
					<div className="col-md-4 form-group">
						<label>Date Taken</label>
						<div>
							<input
								type="month"
								onChange={handleEnglishTestTakenDateChange}
								className="form-control eligibility-form-control"
								placeholder="Date"
							></input>
						</div>
					</div>
					<div className="col-md-4 form-group">
						<label>Score</label>
						<div>
							<input
								type="number"
								onChange={handleEnglishTestScoreChange}
								className="form-control eligibility-form-control"
							></input>
						</div>
					</div>
					<div className="col-md-4 form-group">
						<label>Listening Score</label>
						<div>
							<input
								type="number"
								onChange={handleListeningScoreChange}
								className="form-control eligibility-form-control"
							></input>
						</div>
					</div>
				</div>
				<div className="row scoreRow">
					<div className="col-md-4 form-group">
						<label>Speaking Score</label>
						<div>
							<input
								type="number"
								onChange={handleSpeakingScoreChange}
								className="form-control eligibility-form-control"
							></input>
						</div>
					</div>
					<div className="col-md-4 form-group">
						<label>Reading Score</label>
						<div>
							<input
								type="number"
								onChange={handleReadingScoreChange}
								className="form-control eligibility-form-control"
							></input>
						</div>
					</div>
					<div className="col-md-4 form-group">
						<label>Writing Score</label>
						<div>
							<input
								type="number"
								onChange={handleWritingScoreChange}
								className="form-control eligibility-form-control"
							></input>
						</div>
					</div>
				</div>
			</div>
		);
	};

	useEffect(() => {
		disciplineGet();
	}, [dispatch]);

	const disciplineGet = async () => {
		const url = "discipline/";
		const request = await http.get(url);
		var data = request.data.results;
		if (data) {
			for (let i = 0; i < data.length; i++) {
				courseOptions.push({ value: data[i]["id"], label: data[i]["name"] });
			}
		}
	};

	const fullnameHandleChange = (value) => {
		if (!value) {
			setFullnameError("Name is required");
		} else if (!/^[a-zA-Z]{4,}(?: [a-zA-Z]+)?(?: [a-zA-Z]+)?$/i.test(value)) {
			setFullnameError("incorrect name");
		} else if (/(?=.*[0-9])/i.test(value)) {
			setFullnameError("Invalid - should be a character");
		} else {
			setFullnameError("");
		}
		setFullname(value);
	};
	const emailHandleChange = (value) => {
		if (!value) {
			setEmailError("Email is required");
		} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
			setEmailError("Invalid email address");
		} else {
			setEmailError("");
		}
		setEmail(value);
	};
	const phoneTypeHandleChange = (value) => {
		if (!value) {
			setPhoneTypeError("required");
		} else {
			setPhoneTypeError("");
		}
		setPhoneType(value);
	};
	const phNumberHandleChange = (value, data, event) => {
		if (!value) {
			setPhNumberError("Phone number is required");
		} else if (!data.countryCode) {
			setPhNumberError("Phone number is required");
		} else {
			setPhNumberError("");
		}
		setCountryCode(data.countryCode);

		setPhNumber(value);
	};
	const startHandleChange = (value) => {
		// let date_regex = /(19|20)\d{2}$/;

		if (value.target.value) {
			setStart(value.target.value);
			setDate(value.target.value)
			setStartError("");
		} else {
			setStart("");
			setStartError("required");
		}

	};

	const residenceHandleChange = (value) => {
		if (value) {
			setResidence(value);
			setResidenceError("");
		} else {
			setResidence("");
			setResidenceError("required");
		}

	};
	const citizenshipHandleChange = (value) => {
		if (value) {
			setCitizenship(value);
			setCitizenshipError("");
		} else {
			setCitizenship("");
			setCitizenshipError("required");
		}

	};
	function onApplyNowCrossPress() {
		$("#ApplyNow").modal("hide");
		clearAllInputs();
		clearAllInputsError();
		localStorage.removeItem("ApplyModal");
	}
	function clearAllInputs() {
		// setFullname("");
		// setEmail("");
		// setPhNumber("");
		setPhoneType("");
		setStart("");
		setCitizenship("");
		setResidence("");
		setCourseObject(null);
		setReqCheck(false);
		setSatTestAppeared(false);
		setGmatTestAppeared(false);
		setLsatTestAppeared(false);
		setGreTestAppeared(false);
		setMcatTestAppeared(false);
		setEnglishProficiency(false);
		setEnglishTestScheduleDate(null);
		setLevelForStudy(null);
		setExamInAppearedScore("");


	}
	function clearAllInputsError() {
		setEmailError("");
		setFullnameError("");
		setPhoneTypeError("");
		setPhNumberError("");
		setReqCheckError("");
		setStartError("");
		setResidenceError("");
		setCitizenshipError("");
		setEnglishTestScheduleDateError("");
		setEnglishTestError("");
		setStudyLevelError("");
		setResultError("")
		setExamTestError("");

	}
	async function applyNowOnSubmit(e) {
		// debugger
		e.preventDefault();

		if (
			fullname &&
			email &&
			phNumber &&
			Object.keys(residence) &&
			Object.keys(phoneType) &&
			Object.keys(citizenship) &&
			Object.keys(preferredCountry) &&
			reqCheck && start && examInAppearedScore &&
			(satTest === true || gmatTest === true || lsatTest === true || greTest === true || mcatTest === true) &&
			levelForStudy
		) {

			let intake_c = date;
			localStorage.setItem("fname", fullname)
			localStorage.setItem("user_email", email)
			localStorage.setItem("user_number", phNumber)
			let data = {
				full_name: fullname,
				email: email,
				phone_type: phoneType,
				mobile: phNumber,
				city: currentCity,
				residence: residence,
				citizenship: citizenship,
				preferredCountry: preferredCountry,
				discipline: selectCourse,
				bachelors_degree: bachelorQualification,
				college_qualification: collegeQualification,
				masters_degree: masterQualification,
				school_qualification: schoolQualification,
				bachelor_passing_year: bachelorYear,
				college_passing_year: collegeYear,
				master_passing_year: masterYear,
				school_passing_year: schoolYear,
				english_test_schedule_date: englishTestScheduleDate,
				intake_c: intake_c,
				sat: satTest,
				gmat: gmatTest,
				lsat: lsatTest,
				greTest: greTest,
				mcat: mcatTest,
				result_c: examInAppearedScore,
				level_c: levelForStudy,
			};
			data = Object.assign({}, data, courseObject);

			if (reqCheck === true && emailError === "" && data.residence && data.citizenship) {
				if (!localStorage.getItem(
					"user_Id"
				)) {
					$("#ApplyNow").modal("hide");
					$("#signup").modal("show");
					localStorage.applyNowAfterRegister = 1
				} else {
					await httpCrm
						.post(
							`webForms.php?full_name=${data.full_name}
					&email=${data.email}&mobile=${data.mobile}
					&residence= ${data.residence.label}&citizenship=${data.citizenship.label
							}&bachelors_degree= ${data.bachelors_degree.label ? data.bachelors_degree.label : null}&
					english_test_schedule_date= ${data.english_test_schedule_date}&
					intake_c= ${data.intake_c}&sat= ${data.sat}&gmat= ${data.gmat}&lsat= ${data.lsat
							}&greTest= ${data.greTest}&mcat= ${data.mcat}&result_c= ${data.result_c
							}&
					level_c= ${data.level_c}&course_id= ${data.course_id}&campus_name= ${data.campus_name
							}&country_name= ${data.country_name}&
					discipline_name= ${data.discipline_name}&institute_name= ${data.institute_name}&
					campus_address= ${data.campus_address}&course_language= ${data.course_language}&
					course_title=${data.course_title}&degree_level= ${data.degree_level
							}&institute_sector= ${data.institute_sector}&
					program_discipline= ${data.program_discipline}&specialization= ${data.specialization
							}&
					source=Apply-Now&course_fee=${data.course_fee ? data.course_fee : "TBC"}&phone_type=${phoneType.label}`
						)
						.then((res) => {
							if (res.status === 200) {

								// props.ftn();

								$("#ApplyNow").modal("hide");

							}
						});
				}
			} else {
				if (emailError) {
					setEmailError("*required");
				}
				if (data.residence === null) {
					setResidenceError("required");
				}
				if (data.citizenship === null) {
					setCitizenshipError("required");
				}
				if (phoneType === null) {
					setPhoneTypeError("required");
				}




			}
		} else {

			if (email === "") {
				setEmailError("Email is required");
			}
			if (fullname === "") {
				setFullnameError("Name is required");
			}
			if (phoneType === "") {
				setPhoneTypeError("Phone-type is required");
			}
			if (phNumber === "") {
				setPhNumberError("Phone number is required");
			}

			if (citizenship === "") {
				setCitizenshipError("Citizenship is required");
			}
			if (residence === "") {
				setResidenceError("Residence is required");
			}

			if (reqCheck === false) {
				setReqCheckError("required");
			}
			if (englishTestScheduleDate === "") {
				setEnglishTestScheduleDateError("required")
			}
			if (start === "") {
				setStartError("required");
			}
			if (examInAppearedScore === "") {
				setResultError("required");
			}
			if (satTest === false && greTest === false && gmatTest === false && lsatTest === false && mcatTest === false) {
				setExamTestError("required");
			}
			if (levelForStudy === "") {
				setStudyLevelError("required");
			}
			if (englishProficiency === "") {
				setEnglishTestError("required");
			}
			// clearAllInputs();
		}
	}
	function filteredCourse(obj) {
		const url = "/course/" + obj[0].value + "/crm_course_detail";
		const request = http.get(url);
		request.then((response) => {
			if (response.data) {
				setCourseObject(response.data);
			}
		});
	}
	const checkBoxValidate = (event) => {
		if (event) {
			setReqCheck(event);
			setReqCheckError(null);
			return event;
		} else {
			setReqCheck(event);
		}
	};
	const [studyLevelError, setStudyLevelError] = useState("");
	const handleLevelForStudyChange = (value) => {
		if (value.target.value) {
			setLevelForStudy(value.target.value);
			setStudyLevelError("")
		} else {
			setStudyLevelError("required");
			setLevelForStudy("");
		}

	};
	const [englishTestError, setEnglishTestError] = useState("");
	const onChangeEnglish = (value) => {
		if (value.target.value === "no") {
			setEnglishProficiency(value.target.value);
			setEnglishTestScheduleDateError("")
		} else if (value.target.value) {
			setEnglishProficiency(value.target.value);
			setEnglishTestError("");

		} else {
			setEnglishTestError("required");
		}

	};
	const examTestHandleChange = (value) => {

		if (value.target.value === "sat") {
			setSatTestAppeared(true);
			setExamTestError("");
		} else if (value.target.value === "gmat") {
			setGmatTestAppeared(true);
			setExamTestError("");
		} else if (value.target.value === "lsat") {
			setLsatTestAppeared(true);
			setExamTestError("");
		} else if (value.target.value === "gre") {
			setGreTestAppeared(true);
			setExamTestError("");
		} else if (value.target.value === "mcat") {
			setMcatTestAppeared(true);
			setExamTestError("");
		} else {
			setExamTestError("required");
		}
	};

	return (
		<>

			<div className="modal-dialog modal-dialog-centered" role="document">
				<div className="modal-content">
					<div className="modal-body">
						<button
							type="button"
							className="close"
							onClick={onApplyNowCrossPress}
							aria-label="Close"
						>
							<img
								src={require("../../../static/images/modal-times.svg").default}
								alt="modal-times"
							/>
						</button>
						<div className="formSection">
							<div className="formSide">
								<h3>Register Now for More Assistance</h3>
								<p>Get details and latest updates</p>
								<form>
									<div className="row">
										<div className="col-md-6">
											<div className="form-group">
												<input
													type="text"
													value={fullname}
													placeholder="Full Name"
													className={
														"form-control"
														// +
														// (fullnameError ? " is-invalid" : "")
													}
													onChange={(e) => fullnameHandleChange(e.target.value)}
												></input>
												<img
													src={
														require("../../../static/images/forms/full_name.svg")
															.default
													}
													alt="full_name"
													height=""
													width=""
													className="formIcon"
												/>
												{fullnameError ? (
													<Alert severity="error">{fullnameError}</Alert>
												) : null}
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<input
													type="email"
													value={email}
													placeholder="Email Address"
													className={
														"form-control"
														// + (emailError ? " is-invalid" : "")
													}
													onChange={(e) => emailHandleChange(e.target.value)}
												></input>
												<img
													src={
														require("../../../static/images/forms/Email.svg")
															.default
													}
													alt="Email"
													height=""
													width=""
													className="formIcon"
												/>
												{emailError ? (
													<Alert severity="error">{emailError}</Alert>
												) : null}
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-6">
											<div className={"form-group"}>
												<Select
													name="filters"
													placeholder={"Phone Type"}
													value={phoneType}
													isSearchable={false}
													options={phoneTypeOptions}
													// className={
													// 	"form-control" + phoneTypeError ? " is-invalid" : ""
													// }
													onChange={phoneTypeHandleChange}
													styles={customStyles}
													theme={(theme) => ({
														...theme,
													})}
												/>

												<img
													src={
														require("../../../static/images/forms/phone.svg")
															.default
													}
													alt="phone"
													height=""
													width=""
													className="formIcon"
												/>
												{phoneTypeError ? (
													<Alert severity="error">{phoneTypeError}</Alert>
												) : null}
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<PhoneInput
													value={phNumber}
													onChange={(value, data, event) =>
														phNumberHandleChange(value, data, event)
													}
													autoFormat
													country={"pk"}
													placeholder="Enter the Number"
													enableAreaCodes="true"
													className={
														"form-control"
														// +
														// (phNumberError ? " is-invalid" : "")
													}
												/>

												{phNumberError ? (
													<Alert severity="error">{phNumberError}</Alert>
												) : null}
											</div>
										</div>
									</div>

									{/*<div className="form-group">*/}
									{/*	<input*/}
									{/*		type="number"*/}
									{/*		value={start}*/}
									{/*		className={*/}
									{/*			"form-control" + (startError ? " is-invalid" : "")*/}
									{/*		}*/}
									{/*		placeholder="When would you like to start?"*/}
									{/*		onChange={(e) => startHandleChange(e.target.value)}*/}
									{/*	></input>*/}

									{/*	<img*/}
									{/*		src={*/}
									{/*			require("../../../static/images/forms/starting_time.svg")*/}
									{/*				.default*/}
									{/*		}*/}
									{/*		alt="banner"*/}
									{/*		height=""*/}
									{/*		width=""*/}
									{/*		className="formIcon"*/}
									{/*	/>*/}
									{/*	{startError ? (*/}
									{/*		<Alert severity="error">{startError}</Alert>*/}
									{/*	) : (*/}
									{/*		""*/}
									{/*	)}*/}
									{/*</div>*/}
									<div className="row">
										<div className="col-md-6"></div>
										<div className="col-md-6"></div>
									</div>
									<div className="form-group">
										<Select
											name="filters"
											placeholder={"Country of Residence"}
											value={residence}
											isSearchable={true}
											options={countriesOption}
											// className={ ? "is-invalid" : ""}
											onChange={residenceHandleChange}
											styles={customStyles}
										/>
										<img
											src={
												require("../../../static/images/forms/preferred_location.svg")
													.default
											}
											alt="preferred_location"
											height=""
											width=""
											className="formIcon"
										/>
										{residenceError ? (
											<Alert severity="error">{residenceError}</Alert>
										) : null}
									</div>
									<div className="form-group">
										<Select
											name="filters"
											placeholder={"Country of Citizenship (if different)"}
											value={citizenship}
											isSearchable={true}
											options={countriesOption}
											// className={ ? "is-invalid" : ""}
											onChange={citizenshipHandleChange}
											styles={customStyles}
										/>

										<img
											src={
												require("../../../static/images/forms/preferred_location.svg")
													.default
											}
											alt="preferred_location"
											height=""
											width=""
											className="formIcon"
										/>
										{citizenshipError ? (
											<Alert severity="error">{citizenshipError}</Alert>
										) : null}
									</div>

									<CourseSelectionFilter filteredCourse={filteredCourse} />
									<div className="form-group">
										<label>When would you like to start?</label>
										<div className="checkboxGroup">
											<input
												type="date"
												onChange={startHandleChange}
												className={"form-control eligibility-form-control" + (startError ? " is-invalid" : "")}
												placeholder="Date"
											></input>
											{/* <DatePicker onChange={setDate} value={date} /> */}
											{/*<label className="customCheckbox">*/}
											{/*	<input type="checkbox" name="aaa" value=""></input>*/}
											{/*	<span>2021</span>*/}
											{/*</label>*/}
											{/*<label className="customCheckbox">*/}
											{/*	<input type="checkbox" name="aaa" value=""></input>*/}
											{/*	<span>2022</span>*/}
											{/*</label>*/}
											{/*<label className="customCheckbox">*/}
											{/*	<input type="checkbox" name="aaa" value=""></input>*/}
											{/*	<span>Later</span>*/}
											{/*</label>*/}

										</div>
									</div>

									<div className="form-group">
										<label>
											Have you Appeared or Scheduled for any English Proficiency
											Test?
										</label>
										<div className="checkboxGroup">
											<label className="custom-radio">
												<input
													type="radio"
													onChange={onChangeEnglish}
													name="eng"
													value="yes"
												></input>
												<span>Yes</span>
											</label>
											<label className="custom-radio">
												<input
													type="radio"
													name="eng"
													onChange={onChangeEnglish}
													value="no"
												></input>
												<span>No</span>
											</label>
											<label className="custom-radio">
												<input
													type="radio"
													name="eng"
													onChange={onChangeEnglish}
													value="scheduled"
												></input>
												<span>Scheduled</span>
											</label>
											<input
												type="date"
												onChange={handleEnglishScheduledTestDate}
												className={"form-control eligibility-form-control" + (englishTestScheduleDateError ? " is-invalid" : "")}
												placeholder="Date"
											></input>
											{englishTestError ? <Alert severity="error">{englishTestError}</Alert> : null}

										</div>
									</div>
									<div className="form-group">
										{englishProficiency === "yes" ? showPopup() : false}
									</div>
									<div className="form-group">
										<label>
											Have you Appeared or Scheduled for any these Exams?
										</label>
										<div className="checkboxGroup">
											<label className="custom-radio">
												<input
													type="radio"
													name="exam"
													onChange={examTestHandleChange}
													value="sat"
												></input>
												<span>SAT</span>
											</label>
											<label className="custom-radio">
												<input
													type="radio"
													name="exam"
													onChange={examTestHandleChange}
													value="gmat"
												></input>
												<span>GMAT</span>
											</label>
											<label className="custom-radio">
												<input
													type="radio"
													name="exam"
													onChange={examTestHandleChange}
													value="lsat"
												></input>
												<span>LSAT</span>
											</label>
											<label className="custom-radio">
												<input
													type="radio"
													name="exam"
													onChange={examTestHandleChange}
													value="gre"
												></input>
												<span>GRE</span>
											</label>
											<label className="custom-radio">
												<input
													type="radio"
													name="exam"
													onChange={examTestHandleChange}
													value="mcat"

												></input>
												<span>MCAT</span>
											</label>

											<input
												type="number"
												onChange={examTestScoreHandleChange}
												className={"form-control eligibility-form-control" + (resultError ? " is-invalid" : "")}
												placeholder="Result"
											></input>
											{examTestError ? <Alert severity="error">{examTestError}</Alert> : null}
										</div>
									</div>
									<div className="form-group">
										<label>Choose Level for Study</label>
										<div className="checkboxGroup">
											<label className="custom-radio">
												<input
													type="radio"
													name="level"
													onChange={handleLevelForStudyChange}
													value="Bachelors Degree"
												></input>
												<span>Bachelor</span>
											</label>
											<label className="custom-radio">
												<input
													type="radio"
													name="level"
													onChange={handleLevelForStudyChange}
													value="Masters Degree"
												></input>
												<span>Masters</span>
											</label>
											<label className="custom-radio">
												<input
													type="radio"
													name="level"
													onChange={handleLevelForStudyChange}
													value="Professional Certificates"
												></input>
												<span>Certificate</span>
											</label>
											<label className="custom-radio">
												<input
													type="radio"
													name="level"
													onChange={handleLevelForStudyChange}
													value="Short Term Diploma"
												></input>
												<span>Short Term Diploma</span>
											</label>
											<label className="custom-radio">
												<input
													type="radio"
													name="level"
													onChange={handleLevelForStudyChange}
													value="Other"
												></input>
												<span>Other</span>
											</label>
											{studyLevelError ? <Alert severity="error">{studyLevelError}</Alert> : null}
										</div>
									</div>
									<div className="form-group">
										<label className="customCheckbox">
											<input
												type="checkbox"
												defaultChecked={reqCheck}
												className={
													"form-control" + (reqCheckError ? " is-invalid" : "")
												}
												onClick={(e) => checkBoxValidate(e.target.checked)}
											></input>
											<span>
												Yes I hereby confirm that my age is over 16 and
												understand Times Course Finder{" "}
												<a href="/terms-of-use"> Terms & Conditions</a> and{" "}
												<a href="/privacy-policy">Privacy Policy</a>.{" "}
											</span>
										</label>
										{reqCheckError ? (
											<Alert severity="error">{reqCheckError}</Alert>
										) : null}
									</div>
									<button
										className="formBtn"
										disabled={reqCheck ? false : true}
										onClick={(e) => applyNowOnSubmit(e)}
									>
										Submit
									</button>

									<p className="noAccP">
										Already Registered? Click here to{" "}
										<a
											href="#"
											data-toggle="modal"
											data-dismiss="modal"
											data-target="#signup"
										>
											Login
										</a>{" "}
									</p>
								</form>
							</div>
							<div className="whyRegSide">
								<h5>Why to Register</h5>
								<div className="whyBoxWrap">
									<div>
										<img
											src={require("../../../static/images/why1.svg").default}
											alt="why1"
											height=""
											width=""
										/>
										<h6>Explore unlimited Programs & Fee</h6>
									</div>
									<div>
										<img
											src={require("../../../static/images/why2.svg").default}
											alt="why2"
											height=""
											width=""
										/>
										<h6>Admission Support to Apply</h6>
									</div>
									<div>
										<img
											src={require("../../../static/images/why3.svg").default}
											alt="why3"
											height=""
											width=""
										/>
										<h6>Compare multiple Institutes</h6>
									</div>
									<div>
										<img
											src={require("../../../static/images/why4.svg").default}
											alt="why4"
											height=""
											width=""
										/>
										<h6>Update on Scholarships & Admission Deadlines</h6>
									</div>
									<div>
										<img
											src={require("../../../static/images/why5.svg").default}
											alt="why5"
											height=""
											width=""
										/>
										<h6>Shortlist & save your searches</h6>
									</div>
									<div>
										<img
											src={require("../../../static/images/why6.svg").default}
											alt="why6"
											height=""
											width=""
										/>
										<h6>Online Counselling</h6>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
