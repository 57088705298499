import React from 'react';

const CustomButton = (props) => {
    return (
        <button
            className="btn defaultBtn"
            onClick={(e) => props.onClick(e)}
        >
            {props.buttonText}
        </button>
    )
};

export default CustomButton;