import React from 'react'
import ContentLoader from 'react-content-loader'

const GridViewCrdLoader = () => {
    return (
        <div>
            <GridViewCrd />
            <GridViewCrd />
            <GridViewCrd />
            <GridViewCrd />
            <GridViewCrd />
        </div>
    )
}

const GridViewCrd = props => (
    
    <ContentLoader viewBox="0 0 100% 900"
                   height={900}
                   width={'100%'}
                   backgroundColor="#ababab"
                   foregroundColor="#fafafa"
                   {...props}
    >
        <rect x="30" y="20" rx="8" ry="8" width="200" height="200" />
        <rect x="30" y="250" rx="0" ry="0" width="200" height="18" />
        <rect x="30" y="275" rx="0" ry="0" width="120" height="20" />

        <rect x="250" y="20" rx="8" ry="8" width="200" height="200" />
        <rect x="250" y="250" rx="0" ry="0" width="200" height="18" />
        <rect x="250" y="275" rx="0" ry="0" width="120" height="20" />

        <rect x="470" y="20" rx="8" ry="8" width="200" height="200" />
        <rect x="470" y="250" rx="0" ry="0" width="200" height="18" />
        <rect x="470" y="275" rx="0" ry="0" width="120" height="20" />


        <rect x="30" y="340" rx="8" ry="8" width="200" height="200" />
        <rect x="30" y="570" rx="0" ry="0" width="200" height="18" />
        <rect x="30" y="595" rx="0" ry="0" width="120" height="20" />

        <rect x="250" y="340" rx="8" ry="8" width="200" height="200" />
        <rect x="250" y="570" rx="0" ry="0" width="200" height="18" />
        <rect x="250" y="595" rx="0" ry="0" width="120" height="20" />

        <rect x="470" y="340" rx="8" ry="8" width="200" height="200" />
        <rect x="470" y="570" rx="0" ry="0" width="200" height="18" />
        <rect x="470" y="595" rx="0" ry="0" width="120" height="20" />



    </ContentLoader>
)


export default GridViewCrdLoader
