import { http } from "../../../services/apiService/apiService";
import {dataDispatcher, handleErrorDispatcher, handleLoadingDispatcher, handleSuccessDispatcher} from "./data.handler";
import axios from "axios";
import {
	ERROR_COUNTRY_RANKING,
	GET_COUNTRY_RANKING,
	LOADING_COUNTRY_RANKING,
	SUCCESS_COUNTRY_RANKING
} from "./ranking.action";
import {GET_ALL_DISCIPLINES_FAIL, GET_ALL_DISCIPLINES_SUCCESS} from "./discipline.actions";
// /countries

export const GET_ALL_COUNTRIES = "GET_ALL_COUNTRIES";
export const GET_ALL_COUNTRIES_SUCCESS = "GET_ALL_COUNTRIES_SUCCESS";
export const GET_ALL_COUNTRIES_FAIL = "GET_ALL_COUNTRIES_FAIL";

export const GET_ALL_COUNTRIES_WITH_STATES_CITIES =
	"GET_ALL_COUNTRIES_WITH_STATES_CITIES";
export const GET_ALL_COUNTRIES_WITH_STATES_CITIES_SUCCESS =
	"GET_ALL_COUNTRIES_WITH_STATES_CITIES_SUCCESS";
export const GET_ALL_COUNTRIES_WITH_STATES_CITIES_FAIL =
	"GET_ALL_COUNTRIES_WITH_STATES_CITIES_FAIL";

// export function getAllCountries() {
// 	const request = http.get("country/all-countries");
//
// 	return (dispatch) =>
// 		request
// 			.then((response) =>
// 				dispatch({
// 					type: GET_ALL_COUNTRIES_SUCCESS,
// 					payload: response.data,
// 				})
// 			)
// 			.catch((error) => {
// 				return dispatch({
// 					type: GET_ALL_COUNTRIES_FAIL,
// 					payload: [],
// 				});
// 			});
// }

export const getAllCountries = () => async (dispatch,getState) => {
	// dispatch(handleLoadingDispatcher(LOADING_COUNTRY_RANKING, true));
	// dispatch(handleSuccessDispatcher(SUCCESS_COUNTRY_RANKING, false));
	if(!getState().apis.allCountries.data.length) {
		let url = "country/all-countries/";
		try {
			url = process.env.REACT_APP_BASE_URL + url
			const response = await axios.get(url);
			dispatch(
				{
					type: GET_ALL_COUNTRIES_SUCCESS,
					payload: response.data,
				}
			);
		} catch (e) {
			return dispatch({
				type: GET_ALL_COUNTRIES_FAIL,
				payload: [],
			});
		}
	}
};


export const GET_ALL_COUNTRIES_WITH_DETAIL = "GET_ALL_COUNTRIES_WITH_DETAIL";
export const GET_ALL_COUNTRIES_WITH_DETAIL_SUCCESS =
	"GET_ALL_COUNTRIES_WITH_DETAIL_SUCCESS";
export const GET_ALL_COUNTRIES_WITH_DETAIL_FAIL =
	"GET_ALL_COUNTRIES_WITH_DETAIL_FAIL";


export const getAllCountriesWithDetail = () => async (dispatch,getState) => {
	// dispatch(handleLoadingDispatcher(LOADING_COUNTRY_RANKING, true));
	// dispatch(handleSuccessDispatcher(SUCCESS_COUNTRY_RANKING, false));
	if(!getState().apis.allCountriesWithDetail.data.length) {
		let url = "country/countries_states_cities/";
		try {
			url = process.env.REACT_APP_BASE_URL + url
			const response = await axios.get(url);
			dispatch(
				{
					type: GET_ALL_COUNTRIES_WITH_DETAIL_SUCCESS,
					payload: response.data,
				}
			);
		} catch (e) {
			return dispatch({
				type: GET_ALL_COUNTRIES_WITH_DETAIL_FAIL,
				payload: [],
			});
		}
	}
};


// export function getAllCountriesWithDetail() {
// 	const request = http.get("country/countries_states_cities");
//
// 	return (dispatch) =>
// 		request
// 			.then((response) =>
// 				dispatch({
// 					type: GET_ALL_COUNTRIES_WITH_DETAIL_SUCCESS,
// 					payload: response.data,
// 				})
// 			)
// 			.catch((error) => {
// 				return dispatch({
// 					type: GET_ALL_COUNTRIES_WITH_DETAIL_FAIL,
// 					payload: [],
// 				});
// 			});
// }
// }

export const GET_COUNTRY_DETAIL = "GET_COUNTRY_DETAIL";
export const GET_COUNTRY_DETAIL_SUCCESS = "GET_COUNTRY_DETAIL_SUCCESS";
export const GET_COUNTRY_DETAIL_FAIL = "GET_COUNTRY_DETAIL_FAIL";

export function getCountryDetail(countryId) {
	const url = "country/" + countryId;
	const request = http.get(url);

	return (dispatch) =>
		request
			.then((response) =>
				dispatch({
					type: GET_COUNTRY_DETAIL_SUCCESS,
					payload: response.data,
				})
			)
			.catch((error) => {
				return dispatch({
					type: GET_COUNTRY_DETAIL_FAIL,
					payload: [],
				});
			});
}

export const GET_COUNTRY_INSTITUTES = "GET_COUNTRY_INSTITUTES";
export const GET_COUNTRY_INSTITUTES_SUCCESS = "GET_COUNTRY_INSTITUTES_SUCCESS";
export const GET_COUNTRY_INSTITUTES_FAIL = "GET_COUNTRY_INSTITUTES_FAIL";

export function getCountryInstitutes(countryId, pageId, params) {
	let url = "country/" + countryId + "/institutes/";
	if (pageId) {
		url = "country/" + countryId + "/institutes/?page=" + pageId;
	}
	const request = http.get(url, params);

	return (dispatch) =>
		request
			.then((response) =>
				dispatch({
					type: GET_COUNTRY_INSTITUTES_SUCCESS,
					payload: response.data,
				})
			)
			.catch((error) => {
				return dispatch({
					type: GET_COUNTRY_INSTITUTES_FAIL,
					payload: [],
				});
			});
}
export const GET_COUNTRY_FAQS = "GET_COUNTRY_FAQS";
export const GET_COUNTRY_FAQS_SUCCESS = "GET_COUNTRY_FAQS_SUCCESS";
export const GET_COUNTRY_FAQS_FAIL = "GET_COUNTRY_FAQS_FAIL";

export function getCountryFAQs(countryId) {
	const url = "country/" + countryId + "/country_faqa";
	const request = http.get(url);

	return (dispatch) =>
		request
			.then((response) =>
				dispatch({
					type: GET_COUNTRY_FAQS_SUCCESS,
					payload: response.data,
				})
			)
			.catch((error) => {
				return dispatch({
					type: GET_COUNTRY_FAQS_FAIL,
					payload: [],
				});
			});
}

export function getAllCountriesDeatils() {
	const request = http.get("country/countries_states_cities/");

	return (dispatch) =>
		request
			.then((response) =>
				dispatch({
					type: GET_ALL_COUNTRIES_WITH_STATES_CITIES_SUCCESS,
					payload: response.data,
				})
			)
			.catch((error) => {
				return dispatch({
					type: GET_ALL_COUNTRIES_WITH_STATES_CITIES_FAIL,
					payload: [],
				});
			});
}

export const GET_ALL_INSTITUTE_LOCATION = "GET_COUNTRY_DETAIL";
export const GET_ALL_INSTITUTE_LOCATION_SUCCESS = "GET_COUNTRY_DETAIL_SUCCESS";
export const GET_ALL_INSTITUTE_LOCATION_FAIL = "GET_COUNTRY_DETAIL_FAIL";
export const UPDATE_ALL_INSTITUTE_LOCATION = "UPDATE_ALL_INSTITUTE_LOCATION";

export const updateInstitutesLocation = data => ({
	type: UPDATE_ALL_INSTITUTE_LOCATION,
	payload: data
})

export function getAllUniversitiesLocation(nextPageUrl) {
	let url = "campus/locations";
	if (nextPageUrl) {
		url = nextPageUrl;
	}
	const request = http.get(url);

	return (dispatch) =>
		request
			.then((response) =>
				dispatch({
					type: GET_ALL_INSTITUTE_LOCATION_SUCCESS,
					payload: response.data,
				})
			)
			.catch((error) => {
				return dispatch({
					type: GET_ALL_INSTITUTE_LOCATION_FAIL,
					payload: [],
				});
			});
}

export const GET_COUNTRY_DISCIPLINE_RELATED_DEGREE_LEVELS =
	"GET_COUNTRY_DISCIPLINE_RELATED_DEGREE_LEVELS";
export const GET_COUNTRY_DISCIPLINE_RELATED_DEGREE_LEVELS_SUCCESS =
	"GET_COUNTRY_DISCIPLINE_RELATED_DEGREE_LEVELS_SUCCESS";
export const GET_COUNTRY_DISCIPLINE_RELATED_DEGREE_LEVELS_FAIL =
	"GET_COUNTRY_DISCIPLINE_RELATED_DEGREE_LEVELS_FAIL";

export function getCountryDisciplineRelatedDegreeLevels(
	countryId,
	disciplineId
) {
	const request = http.get(
		"country/" + countryId + "/discipline/" + disciplineId + "/degree_levels"
	);

	return (dispatch) =>
		request
			.then((response) =>
				dispatch({
					type: GET_COUNTRY_DISCIPLINE_RELATED_DEGREE_LEVELS_SUCCESS,
					payload: response.data,
				})
			)
			.catch((error) => {
				return dispatch({
					type: GET_COUNTRY_DISCIPLINE_RELATED_DEGREE_LEVELS_FAIL,
					payload: [],
				});
			});
}

export const GET_COUNTRY_DISCIPLINE_RELATED_INTAKES =
	"GET_COUNTRY_DISCIPLINE_RELATED_INTAKES";
export const GET_COUNTRY_DISCIPLINE_RELATED_INTAKES_SUCCESS =
	"GET_COUNTRY_DISCIPLINE_RELATED_INTAKES_SUCCESS";
export const GET_COUNTRY_DISCIPLINE_RELATED_INTAKES_FAIL =
	"GET_COUNTRY_DISCIPLINE_RELATED_INTAKES_FAIL";

export function getCountryDisciplineRelatedIntakes(countryId, disciplineId) {
	const request = http.get(
		"country/" + countryId + "/discipline/" + disciplineId + "/intakes"
	);

	return (dispatch) =>
		request
			.then((response) =>
				dispatch({
					type: GET_COUNTRY_DISCIPLINE_RELATED_INTAKES_SUCCESS,
					payload: response.data,
				})
			)
			.catch((error) => {
				return dispatch({
					type: GET_COUNTRY_DISCIPLINE_RELATED_INTAKES_FAIL,
					payload: [],
				});
			});
}

export const GET_COUNTRY_DISCIPLINE_RELATED_SPECIALIZATIONS =
	"GET_COUNTRY_DISCIPLINE_RELATED_SPECIALIZATIONS";
export const GET_COUNTRY_DISCIPLINE_RELATED_SPECIALIZATIONS_SUCCESS =
	"GET_COUNTRY_DISCIPLINE_RELATED_SPECIALIZATIONS_SUCCESS";
export const GET_COUNTRY_DISCIPLINE_RELATED_SPECIALIZATIONS_FAIL =
	"GET_COUNTRY_DISCIPLINE_RELATED_SPECIALIZATIONS_FAIL";

export function getCountryDisciplineRelatedSpecializations(
	countryId,
	disciplineId
) {
	const request = http.get(
		"country/" + countryId + "/discipline/" + disciplineId + "/specializations"
	);

	return (dispatch) =>
		request
			.then((response) =>
				dispatch({
					type: GET_COUNTRY_DISCIPLINE_RELATED_SPECIALIZATIONS_SUCCESS,
					payload: response.data,
				})
			)
			.catch((error) => {
				return dispatch({
					type: GET_COUNTRY_DISCIPLINE_RELATED_SPECIALIZATIONS_FAIL,
					payload: [],
				});
			});
}

export const GET_COUNTRY_DISCIPLINE_RELATED_INSTITUTES =
	"GET_COUNTRY_DISCIPLINE_RELATED_INSTITUTES";
export const GET_COUNTRY_DISCIPLINE_RELATED_INSTITUTES_SUCCESS =
	"GET_COUNTRY_DISCIPLINE_RELATED_INSTITUTES_SUCCESS";
export const GET_COUNTRY_DISCIPLINE_RELATED_INSTITUTES_FAIL =
	"GET_COUNTRY_DISCIPLINE_RELATED_INSTITUTES_FAIL";

export function getCountryDisciplineRelatedInstitutes(countryId, disciplineId) {
	const request = http.get(
		"country/" + countryId + "/discipline/" + disciplineId + "/institutes"
	);

	return (dispatch) =>
		request
			.then((response) =>
				dispatch({
					type: GET_COUNTRY_DISCIPLINE_RELATED_INSTITUTES_SUCCESS,
					payload: response.data,
				})
			)
			.catch((error) => {
				return dispatch({
					type: GET_COUNTRY_DISCIPLINE_RELATED_INSTITUTES_FAIL,
					payload: [],
				});
			});
}

export const GET_COUNTRY_DISCIPLINE_RELATED_LOCATIONS =
	"GET_COUNTRY_DISCIPLINE_RELATED_LOCATIONS";
export const GET_COUNTRY_DISCIPLINE_RELATED_LOCATIONS_SUCCESS =
	"GET_COUNTRY_DISCIPLINE_RELATED_LOCATIONS_SUCCESS";
export const GET_COUNTRY_DISCIPLINE_RELATED_LOCATIONS_FAIL =
	"GET_COUNTRY_DISCIPLINE_RELATED_LOCATIONS_FAIL";

export function getCountryDisciplineRelatedLocations(countryId, disciplineId) {
	const request = http.get(
		"country/" + countryId + "/discipline/" + disciplineId + "/locations"
	);

	return (dispatch) =>
		request
			.then((response) =>
				dispatch({
					type: GET_COUNTRY_DISCIPLINE_RELATED_LOCATIONS_SUCCESS,
					payload: response.data,
				})
			)
			.catch((error) => {
				return dispatch({
					type: GET_COUNTRY_DISCIPLINE_RELATED_LOCATIONS_FAIL,
					payload: [],
				});
			});
}
