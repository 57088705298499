import React from 'react';
import Loader from "react-loader-spinner";
const LoadingSpinner = ({height,color}) => (
  <div style={{
        width: "100%",
        height: height?height:"100",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}>
      <Loader type="ThreeDots" color={color?color:"#291c5a"} height={height?height:"100"} width="100" />
  </div>
);
export default LoadingSpinner;

