import { useEffect, useState } from "react";
import React from 'react';
import CustomFilter from "../../../components/CustomFilter";
import { INSTITUTE_TYPES } from "../SearchPage.constants";
import { useNavigate } from "react-router-dom-v5-compat";

const InstituteTypeFilter = ({ searchParams, updateSearchParams ,isBlurred,setOpenModal}) => {
    const [checkedInstituteType, setCheckedInstituteType] = useState({});
    const [isOpen, setIsOpen] = useState(true);
    const [updatedFilters, setUpdatedFilters] = useState([])
    
    const navigate=useNavigate();
    const handleChange = (e) => {
        const filterValue = e.target.value
        const { checked } = e.target;
        setCheckedInstituteType({ ...checkedInstituteType, [filterValue]: checked });

        let params = searchParams.institute_type
        if (params.length) {
            params = params.split(',')
        }

        const _updatedFilters = updatedFilters.includes(filterValue)
            ? updatedFilters.filter((value) => value !== filterValue)
            : [...updatedFilters, filterValue];

        setUpdatedFilters(_updatedFilters);
    };


    const onApply = () => {
        updateSearchParams({ institute_type: updatedFilters.join(',') });
    }

    useEffect(() => {
        let instituteType = searchParams?.institute_type;
        if (instituteType.length) {
            instituteType = instituteType.split(",");
            const resultObject = instituteType.reduce((acc, value) => {
                acc[value] = true;
                return acc;
            }, {});
            setCheckedInstituteType(resultObject)
        }        
    }, [searchParams.institute_type]);



    return (
        <>
            <CustomFilter
               setOpenModal={setOpenModal}
               isBlurred={isBlurred}
                title="Institute Type"
                data={INSTITUTE_TYPES}
                searchPlaceholder="Search Institute Type"
                checkedItems={checkedInstituteType}
                handleChangeItem={handleChange}
                hasSearch={false}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                filter="instituteType"
                onApply={onApply}
            />
        </>

    )
}
export default InstituteTypeFilter;

