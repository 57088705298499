import { lazy } from "react";
import MarketingDetail from "../pages/DetailPages/marketing-detail";
import Profile from "../pages/account/editProfile";
import Landing from "../pages/home/landing";
import SearchPage from "../pages/SearchPage/SearchPage.index";
import Registration from "../pages/auth/registration.jsx";
import Verification from "../pages/auth/verification.jsx";
import Recovery from "../pages/auth/recovery.jsx";
import Settings from "../pages/auth/settings.jsx";

const Course = lazy(() => import("../pages/course/courses_list"));
const CourseDetail = lazy(() => import("../pages/DetailPages/course-detail"));
const Discipline = lazy(() => import("../pages/course/discipline"));
const Universities = lazy(() => import("../pages/universities/universities"));
const UniIndividual = lazy(() =>
  import("../pages/universities/university-individual")
);
const Country = lazy(() => import("../pages/country/country"));
const AllCountries = lazy(() => import("../pages/country/allCountries"));
const ScholarshipCountry = lazy(() =>
  import("../pages/scholarships/scholarship-country")
);
const Scholarships = lazy(() => import("../pages/scholarships/scholarships"));
const ScholarshipDetail = lazy(() =>
  import("../pages/DetailPages/scholarship-detail")
);
const CompareCourse = lazy(() => import("../pages/course/compare-course"));
const Blog = lazy(() => import("../pages/blog/blog"));
const BlogDetail = lazy(() => import("../pages/blog/blog-detail"));
const AllInstituteGroups = lazy(() =>
  import("../pages/groups/all-institute-groups")
);
const AllPathwayGroups = lazy(() =>
  import("../pages/groups/all_pathway_group")
);
const InstituteGroupUni = lazy(() =>
  import("../pages/universities/institute_group_universities")
);
const PathwayGroupUni = lazy(() =>
  import("../pages/universities/pathway_group_universities")
);
const PathwayIndividualUni = lazy(() =>
  import("../pages/universities/pathway_individual_uni")
);
const InstituteGroupIndividualUni = lazy(() =>
  import("../pages/universities/institute_individual_uni")
);
const ApplyPortalIndividualUni = lazy(() =>
  import("../pages/universities/apply_portal_individual_uni")
);
const AllApplyPortal = lazy(() => import("../pages/groups/apply_portals"));
const ApplyPortalUni = lazy(() =>
  import("../pages/universities/apply_portal_universities")
);
const ForgetPass = lazy(() => import("../pages/account/forget-password"));
const ResetPass = lazy(() => import("../pages/account/reset-password"));
const NewPass = lazy(() => import("../pages/account/new-password"));
const ConfirmPass = lazy(() => import("../pages/account/confirm-password"));
const OtpScreen = lazy(() => import("../pages/account/otp"));
const AppDetail = lazy(() => import("../pages/account/application-detail"));
const Shortlist = lazy(() => import("../pages/account/shortlist"));
const TrackApp = lazy(() => import("../pages/account/track-app"));
const UploadDoc = lazy(() => import("../pages/account/upload-doc"));
const UniversityDetail = lazy(() =>
  import("../pages/DetailPages/university_details")
);
const PrivacyPolicy = lazy(() =>
  import("../pages/footer-pages/privacy-policy")
);
const CancellationPolicy = lazy(() =>
  import("../pages/footer-pages/cancellation_policy")
);
const Terms = lazy(() => import("../pages/footer-pages/terms-of-use"));
const TCF = lazy(() => import("../pages/footer-pages/tcf"));
const Login = lazy(() => import("../pages/auth/login.jsx"));
const Careers = lazy(() => import("../pages/footer-pages/careers"));
const ContactUs = lazy(() => import("../pages/footer-pages/contact-us"));
const Marketing = lazy(() => import("../pages/footer-pages/marketing"));
const Apply = lazy(() => import("../pages/footer-pages/apply"));
const Mba = lazy(() => import("../pages/footer-pages/mba"));
const DisciplineDetail = lazy(() =>
  import("../pages/DetailPages/discipline_detail")
);
const Discover = lazy(() => import("../pages/course/discover-course"));
const InstituteProgram = lazy(() =>
  import("../pages/universities/institute_programs")
);
const PathwayProgram = lazy(() =>
  import("../pages/universities/pathway_programs")
);
const InstitutesScholarships = lazy(() =>
  import("../pages/scholarships/scholarship-institute")
);
const CourseComparison = lazy(() =>
  import("../pages/course/course-comparison")
);
const AllDisciplines = lazy(() => import("../pages/course/allDisiciplines"));
const AllScholarships = lazy(() =>
  import("../pages/scholarships/all-scholarships")
);
const WhatIsInteresting = lazy(() =>
  import("../pages/interesting/all-interesting")
);
const AllInstitutes = lazy(() =>
  import("../pages/universities/all_universities")
);
const LoadingUrl = lazy(() => import("../pages/Loading/loading.js"));
const DynamicPage = lazy(() => import("../pages/dynamicPages/dynamic_page"));
const VisaList = lazy(() => import("../components/header/visa/visa_list"));
const VisaDetail = lazy(() => import("../components/header/visa/visa_detail"));
const JazzCash = lazy(() => import("../pages/payments/jazz_cash"));
const CourseSingle = lazy(() => import("../pages/course/courses_single"));


function setIsAuthenticated() {
  return 
}
export const routes = [
  {
    id: 1,
    page_type: "terms_of_use",
    component: Terms,
  },
  {
    id: 2,
    page_type: "tcf",
    component: TCF,
  },
  {
    id: 3,
    page_type: "privacy_policy",
    component: PrivacyPolicy,
  },
  {
    id: 4,
    page_type: "cancellation_policy",
    component: CancellationPolicy,
  },
  {
    id: 5,
    page_type: "careers",
    component: Careers,
  },
  {
    id: 6,
    page_type: "marketing",
    component: Marketing,
  },
  {
    id: 7,
    page_type: "apply",
    component: Apply,
  },
  {
    id: 8,
    page_type: "mba",
    component: Mba,
  },
  {
    id: 9,
    page_type: "contact_us",
    component: ContactUs,
  },
  {
    id: 11,
    page_type: "all_interesting_about_tcf",
    component: WhatIsInteresting,
  },
  {
    id: 14,
    page_type: "forget_password",
    component: ForgetPass,
  },
  {
    id: 15,
    page_type: "reset_password",
    component: ResetPass,
  },
  {
    id: 16,
    page_type: "new_password",
    component: NewPass,
  },
  {
    id: 17,
    page_type: "confirm_password",
    component: ConfirmPass,
  },
  {
    id: 18,
    page_type: "otp_screen",
    component: OtpScreen,
  },
  {
    id: 23,
    page_type: "discover_courses",
    component: Course,
  },
  {
    id: 24,
    page_type: "course",
    slugId: "/:qualification/:location",
    component: Course,
  },
  {
    id: 2409,
    page_type: "course_no_slug",
    component: CourseSingle,
    route: "/course",
  },
  {
    id: 31,
    page_type: "university_course",
    component: Course,
  },
  {
    id: 231,
    page_type: "discover_courses",
    component: Course,
    slugId: "/:id/",
  },
  {
    id: 242,
    page_type: "course",
    component: Course,
    slugId: "/:id/",
  },
  {
    id: 313,
    page_type: "university_course",
    component: Course,
    slugId: "/:id/",
  },
  {
    id: 25,
    page_type: "course_detail",
    slugId: "/:slug/:duration/:fee",
    component: CourseDetail,
  },
  {
    id: 26,
    page_type: "discipline_detail",
    component: DisciplineDetail,
    // slugId:'/:id/'
  },
  {
    id: 27,
    page_type: "landing",
    component: Landing,
  },
  {
    id: 28,
    page_type: "all_countries",
    component: AllCountries,
  },
  {
    id: 29,
    page_type: "universities",
    component: Universities,
  },
  {
    id: 30,
    page_type: "top_universities",
    component: AllInstitutes,
  },
  {
    id: 32,
    page_type: "university_details",
    component: UniversityDetail,
    slugId:
      "/:slug/:courseSlug/:levelSlug/:disiplineSlug/:countrySlug/:campusSlug",
  },
  {
    id: 323,
    route: "university-details",
    page_type: "university_details_no_course_1",
    component: UniversityDetail,
    slugId: "/:slug/:countrySlug/:campusSlug",
  },
  {
    id: 324,
    route: "university-details",
    page_type: "university_details_no_course_2",
    component: UniversityDetail,
    slugId: "/:slug/:campusSlug",
  },
  {
    id: 325,
    route: "university-details",
    page_type: "university_details_no_course_4",
    component: UniversityDetail,
    slugId: "/:slug",
  },
  {
    id: 33,
    page_type: "marketing_detail",
    component: MarketingDetail,
  },
  {
    id: 34,
    page_type: "all_pathway_groups",
    component: AllPathwayGroups,
  },
  {
    id: 35,
    page_type: "pathway_courses",
    component: PathwayProgram,
  },
  {
    id: 36,
    page_type: "all_groups",
    component: AllInstituteGroups,
  },
  {
    id: 37,
    page_type: "institute_courses",
    component: InstituteProgram,
  },
  {
    id: 38,
    page_type: "institute_scholarships",
    component: InstitutesScholarships,
  },
  {
    id: 39,
    page_type: "all_apply_portals",
    component: AllApplyPortal,
  },
  {
    id: 40,
    page_type: "apply_portal_universities",
    component: ApplyPortalUni,
    slugId: "/:slug/",
  },
  {
    id: 41,
    page_type: "scholarship_country",
    component: ScholarshipCountry,
  },
  {
    id: 42,
    page_type: "scholarships",
    component: Scholarships,
  },
  {
    id: 43,
    page_type: "all_scholarships",
    component: AllScholarships,
  },
  {
    id: 44,
    page_type: "scholarship_detail",
    component: ScholarshipDetail,
    slugId: "/:slug/",
  },
  {
    id: 45,
    page_type: "discipline",
    component: Discipline,
  },
  {
    id: 46,
    page_type: "all_programs",
    component: AllDisciplines,
  },
  {
    id: 47,
    page_type: "compare_course",
    component: CompareCourse,
    // slugId:'/:slug/'
  },
  {
    id: 48,
    page_type: "course_compare",
    component: CourseComparison,
  },
  {
    id: 49,
    page_type: "discover_course",
    component: Discover,
  },
  {
    id: 50,
    page_type: "blog",
    component: Blog,
  },
  {
    id: 51,
    page_type: "payment_jazcash",
    component: JazzCash,
  },
  {
    id: 52,
    page_type: "loading",
    component: LoadingUrl,
  },
  {
    id: 53,
    page_type: "apply_portal_university",
    component: ApplyPortalIndividualUni,
    slugId: "/:portalId/:uniId/",
  },
  {
    id: 54,
    page_type: "blog_detail",
    component: BlogDetail,
    slugId: "/:blogId",
  },
  {
    id: 55,
    page_type: "What_is_interesting",
    slugId: "/:id",
    component: DynamicPage,
  },
  {
    id: 56,
    page_type: "country",
    slugId: "/:countryId/",
    component: Country,
  },
  {
    id: 57,
    page_type: "university_Individual",
    slugId: "/:uniId",
    component: UniIndividual,
  },
  {
    id: 58,
    page_type: "pathway_group_universities",
    slugId: "/:groupId",
    component: PathwayGroupUni,
  },
  {
    id: 59,
    page_type: "pathway_university",
    slugId: "/:pathwayID/:uniId/",
    component: PathwayIndividualUni,
  },
  {
    id: 60,
    page_type: "group_universities",
    slugId: "/:groupId",
    component: InstituteGroupUni,
  },
  {
    id: 61,
    page_type: "institute_group_university",
    slugId: "/:instituteGroupId/:uniId/",
    component: InstituteGroupIndividualUni,
  },
  {
    id: 10,
    page_type: "edit_profile",
    component: Profile,
    afterAuthRoute: true,
    auth: setIsAuthenticated(),
  },
  {
    id: 12,
    page_type: "all_visas",
    component: VisaList,
    afterAuthRoute: true,
    auth: setIsAuthenticated(),
  },
  {
    id: 13,
    page_type: "visa_detail",
    component: VisaDetail,
    afterAuthRoute: true,
    auth: setIsAuthenticated(),
  },
  {
    id: 19,
    page_type: "application_detail",
    component: AppDetail,
    afterAuthRoute: true,
    auth: setIsAuthenticated(),
  },
  {
    id: 20,
    page_type: "shortlist",
    component: Shortlist,
    afterAuthRoute: true,
    auth: setIsAuthenticated(),
  },
  {
    id: 21,
    page_type: "track_application",
    component: TrackApp,
    afterAuthRoute: true,
    auth: setIsAuthenticated(),
  },
  {
    id: 22,
    page_type: "upload_documents",
    component: UploadDoc,
    afterAuthRoute: true,
    auth: setIsAuthenticated(),
  },
  {
    id: 62,
    page_type: "search",
    component: SearchPage,
  },
  {
    id: 63,
    page_type: "login",
    component: Login,
  },
  {
    id: 64,
    page_type: "registration",
    component: Registration,
  },
  {
    id: 65,
    page_type: "verification",
    component: Verification,
  },
  {
    id: 66,
    page_type: "recovery",
    component: Recovery,
  },
  {
    id: 67,
    page_type: "settings",
    component: Settings,
  },
];
