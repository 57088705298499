import { http } from "../../../services/apiService/apiService";
import axios from "axios";
import {handleLoadingDispatcher,handleSuccessDispatcher,handleErrorDispatcher,dataDispatcher} from "./data.handler";
import {
	GET_COUNTRY_SCHOLARSHIPS,
	GET_COUNTRY_SCHOLARSHIPS_FAIL,
	GET_COUNTRY_SCHOLARSHIPS_LOADING
} from "./scholarship.actions";
//institute Groups

export const GET_ALL_INSTITUTE_GROUPS = "GET_ALL_INSTITUTE_GROUPS";
export const GET_ALL_INSTITUTE_GROUPS_SUCCESS =
	"GET_ALL_INSTITUTE_GROUPS_SUCCESS";
export const GET_ALL_INSTITUTE_GROUPS_LOADING =
	"GET_ALL_INSTITUTE_GROUPS_LOADING";
export const GET_ALL_INSTITUTE_GROUPS_FAIL = "GET_ALL_INSTITUTE_GROUPS_FAIL";

// export function getAllInstituteGroups() {
// 	const request = http.get("institute-group/");
//
// 	return (dispatch) =>
// 		request
// 			.then((response) =>
// 				dispatch({
// 					type: GET_ALL_INSTITUTE_GROUPS_SUCCESS,
// 					payload: response.data,
// 				})
// 			)
// 			.catch((error) => {
// 				return dispatch({
// 					type: GET_ALL_INSTITUTE_GROUPS_FAIL,
// 					payload: [],
// 				});
// 			});
// }

export const getAllInstituteGroups = () => async (dispatch,getState) => {
	dispatch(handleLoadingDispatcher(GET_ALL_INSTITUTE_GROUPS_LOADING, true));
	dispatch(handleSuccessDispatcher(GET_ALL_INSTITUTE_GROUPS_SUCCESS, false));
	if(!getState().apis.allInstituteGroups.instituteGroupData.length) {
		let url = "institute-group/";
		try {
			url = process.env.REACT_APP_BASE_URL + url
			const response = await axios.get(url);
			 dispatch(
                dataDispatcher(GET_ALL_INSTITUTE_GROUPS, {
                    loading: false,
                    data: response.data,
                    error: "",
                    success: true,
                })
            );
		} catch (e) {
			dispatch(handleErrorDispatcher(GET_ALL_INSTITUTE_GROUPS_FAIL, e.response?.data.message));
            dispatch(handleLoadingDispatcher(GET_ALL_INSTITUTE_GROUPS_LOADING, false));
		}
	}
};



export const GET_INSTITUTE_GROUP_DETAIL = "GET_INSTITUTE_GROUP_DETAIL";
export const GET_INSTITUTE_GROUP_DETAIL_SUCCESS =
	"GET_INSTITUTE_GROUP_DETAIL_SUCCESS";
export const GET_INSTITUTE_GROUP_DETAIL_FAIL =
	"GET_INSTITUTE_GROUP_DETAIL_FAIL";

export function getInstituteGroupDetail(groupID) {
	const url = "institute-group/" + groupID;
	const request = http.get(url);

	return (dispatch) =>
		request
			.then((response) =>
				dispatch({
					type: GET_INSTITUTE_GROUP_DETAIL_SUCCESS,
					payload: response.data,
				})
			)
			.catch((error) => {
				return dispatch({
					type: GET_INSTITUTE_GROUP_DETAIL_FAIL,
					payload: [],
				});
			});
}

export const GET_ALL_UNIVERSITIES_OF_INSITUTE_GROUP =
	"GET_ALL_UNIVERSITIES_OF_INSITUTE_GROUP";
export const GET_ALL_UNIVERSITIES_OF_INSITUTE_GROUP_SUCCESS =
	"GET_ALL_UNIVERSITIES_OF_INSITUTE_GROUP_SUCCESS";
export const GET_ALL_UNIVERSITIES_OF_INSITUTE_GROUP_FAIL =
	"GET_ALL_UNIVERSITIES_OF_INSITUTE_GROUP_FAIL";

export function getInstituteGroupUniversities(groupId, params, pageId) {
	let url = "institute-group/" + groupId + "/universities/";
	if (pageId) {
		url = url + "?page=" + pageId;
	}
	let params2 = params?params:{}
	const request = http.post(url, params2);

	return (dispatch) =>
		request
			.then((response) =>
				dispatch({
					type: GET_ALL_UNIVERSITIES_OF_INSITUTE_GROUP_SUCCESS,
					payload: response.data,
				})
			)
			.catch((error) => {
				return dispatch({
					type: GET_ALL_UNIVERSITIES_OF_INSITUTE_GROUP_FAIL,
					payload: [],
				});
			});
}
export const GET_UNIVERSITIES_OF_ALL_INSTITUTE_GROUP_GROUP =
	"GET_UNIVERSITIES_OF_ALL_INSTITUTE_GROUP_GROUP";
export const GET_UNIVERSITIES_OF_ALL_INSTITUTE_GROUP_SUCCESS =
	"GET_UNIVERSITIES_OF_ALL_INSTITUTE_GROUP_SUCCESS";
export const GET_UNIVERSITIES_OF_ALL_INSTITUTE_GROUP_FAIL =
	"GET_UNIVERSITIES_OF_ALL_INSTITUTE_GROUP_FAIL";
export function getUniversitiesOfInstituteGroup(params) {
	let url = "institute-group/universities/";
	const request = http.post(url, params);

	return (dispatch) =>
		request
			.then((response) =>
				dispatch({
					type: GET_UNIVERSITIES_OF_ALL_INSTITUTE_GROUP_SUCCESS,
					payload: response.data,
				})
			)
			.catch((error) => {
				return dispatch({
					type: GET_UNIVERSITIES_OF_ALL_INSTITUTE_GROUP_FAIL,
					payload: [],
				});
			});
}
export const GET_ALL_COURSES_OF_ALL_INSTITUTE_GROUP =
	"GET_ALL_COURSES_OF_ALL_INSTITUTE_GROUP";
export const GET_ALL_COURSES_OF_ALL_INSTITUTE_GROUP_SUCCESS =
	"GET_ALL_COURSES_OF_ALL_INSTITUTE_GROUP_SUCCESS";
export const GET_ALL_COURSES_OF_ALL_INSTITUTE_GROUP_FAIL =
	"GET_ALL_COURSES_OF_ALL_INSTITUTE_GROUP_FAIL";
export function getCoursesOfInstituteGroup(params, pageId) {
	let url = "institute-group/courses/";
	if (pageId) {
		url = url + "?page=" + pageId;
	}
	const request = http.post(url, params);

	return (dispatch) =>
		request
			.then((response) =>
				dispatch({
					type: GET_ALL_COURSES_OF_ALL_INSTITUTE_GROUP_SUCCESS,
					payload: response.data,
				})
			)
			.catch((error) => {
				return dispatch({
					type: GET_ALL_COURSES_OF_ALL_INSTITUTE_GROUP_FAIL,
					payload: [],
				});
			});
}
export const GET_ALL_DISCIPLINES_OF_ALL_INSTITUTE_GROUP =
	"GET_ALL_DISCIPLINES_OF_ALL_INSTITUTE_GROUP";
export const GET_ALL_DISCIPLINES_OF_ALL_INSTITUTE_GROUP_SUCCESS =
	"GET_ALL_DISCIPLINES_OF_ALL_INSTITUTE_GROUP_SUCCESS";
export const GET_ALL_DISCIPLINES_OF_ALL_INSTITUTE_GROUP_FAIL =
	"GET_ALL_DISCIPLINES_OF_ALL_INSTITUTE_GROUP_FAIL";
export function getDisciplinesOfInstituteGroup() {
	let url = "institute-group/disciplines";
	const request = http.get(url);

	return (dispatch) =>
		request
			.then((response) =>
				dispatch({
					type: GET_ALL_DISCIPLINES_OF_ALL_INSTITUTE_GROUP_SUCCESS,
					payload: response.data,
				})
			)
			.catch((error) => {
				return dispatch({
					type: GET_ALL_DISCIPLINES_OF_ALL_INSTITUTE_GROUP_FAIL,
					payload: [],
				});
			});
}
export const GET_LOCATIONS_OF_INSITUTE_GROUP =
	"GET_LOCATIONS_OF_INSITUTE_GROUP";
export const GET_LOCATIONS_OF_INSITUTE_GROUP_SUCCESS =
	"GET_LOCATIONS_OF_INSITUTE_GROUP_SUCCESS";
export const GET_LOCATIONS_OF_INSITUTE_GROUP_FAIL =
	"GET_LOCATIONS_OF_INSITUTE_GROUP_FAIL";

export function getInstituteGroupRelatedLocations(groupId) {
	let url = "institute-group/" + groupId + "/related_locations";
	const request = http.get(url);

	return (dispatch) =>
		request
			.then((response) =>
				dispatch({
					type: GET_LOCATIONS_OF_INSITUTE_GROUP_SUCCESS,
					payload: response.data,
				})
			)
			.catch((error) => {
				return dispatch({
					type: GET_LOCATIONS_OF_INSITUTE_GROUP_FAIL,
					payload: [],
				});
			});
}
export const GET_ALL_DISCIPLINES_OF_INSTITUTE_GROUP =
	"GET_ALL_DISCIPLINES_OF_INSTITUTE_GROUP";
export const GET_ALL_DISCIPLINES_OF_INSTITUTE_GROUP_SUCCESS =
	"GET_ALL_DISCIPLINES_OF_INSTITUTE_GROUP_SUCCESS";
export const GET_ALL_DISCIPLINES_OF_INSTITUTE_GROUP_FAIL =
	"GET_ALL_DISCIPLINES_OF_INSTITUTE_GROUP_FAIL";
export function getInstituteGroupDisciplinesById(instituteId) {
	let url = "institute-group/" + instituteId + "/disciplines";
	const request = http.get(url);

	return (dispatch) =>
		request
			.then((response) =>
				dispatch({
					type: GET_ALL_DISCIPLINES_OF_INSTITUTE_GROUP_SUCCESS,
					payload: response.data,
				})
			)
			.catch((error) => {
				return dispatch({
					type: GET_ALL_DISCIPLINES_OF_INSTITUTE_GROUP_FAIL,
					payload: [],
				});
			});
}
export const GET_ALL_SCHOLARSHIPS_OF_INSTITUTE_GROUP =
	"GET_ALL_SCHOLARSHIPS_OF_INSTITUTE_GROUP";
export const GET_ALL_SCHOLARSHIPS_OF_INSTITUTE_GROUP_SUCCESS =
	"GET_ALL_SCHOLARSHIPS_OF_INSTITUTE_GROUP_SUCCESS";
export const GET_ALL_SCHOLARSHIPS_OF_INSTITUTE_GROUP_FAIL =
	"GET_ALL_SCHOLARSHIPS_OF_INSTITUTE_GROUP_FAIL";
export function getInstituteGroupScholarshipsById(instituteId) {
	let url = "institute-group/" + instituteId + "/scholarships";
	const request = http.get(url);

	return (dispatch) =>
		request
			.then((response) =>
				dispatch({
					type: GET_ALL_SCHOLARSHIPS_OF_INSTITUTE_GROUP_SUCCESS,
					payload: response.data,
				})
			)
			.catch((error) => {
				return dispatch({
					type: GET_ALL_SCHOLARSHIPS_OF_INSTITUTE_GROUP_FAIL,
					payload: [],
				});
			});
}
