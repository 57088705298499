import React from 'react';
import CheckboxList from "../loaders/checklist";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingSpinner from "../loader/loader";
import InstituteFilter from "../filters/institute/institute_filter";

const InstitutesIndividual = (props) => {
    return (
        <div className="filterBox">
            <h6 className={"filterOpen"}>
                Institute Name <i className="fa fa-angle-right"></i>
                <i className="fa fa-angle-down"></i>
            </h6>
            {props.instituteLoader === true ? (
                <CheckboxList/>
            ) : (
                <div className={"content" + props.institutes + " show"}>
                    <div className="filterSearchWrap">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Institute Name"
                            onChange={props.searchInstituteHandler}
                        ></input>
                        <i className="fa fa-search"></i>
                    </div>
                    {/*<div className="filterBoxScroll">*/}
                    {/*  {selectedInstitutes.map((item, index) => (*/}
                    {/*      <SelectedInstituteFilter*/}
                    {/*          key={index}*/}
                    {/*          institute_name={item}*/}
                    {/*          index={index}*/}
                    {/*          removeSelectedInstitutes={removeSelectedInstitutes}*/}
                    {/*      />*/}
                    {/*  ))}*/}
                    {/*</div>*/}
                    <div className="filterBoxScroll">
                        <div
                            id="scrollableDiv1"
                            style={{maxHeight: 300, overflow: "auto"}}
                        >
                            <InfiniteScroll
                                dataLength={props.filteredInstitutes?.length} //This is important field to render the next data
                                next={(e) => props.fetchDataForFilteredInstitutes(e)}
                                hasMore={props.institutesHasMore}
                                loader={!props.institutesHasMore ? null : <LoadingSpinner height={'10px'}/>}
                                endMessage={
                                    <p style={{textAlign: "center"}}>
                                        <b>Yay! You have seen it all</b>
                                    </p>
                                }
                                scrollableTarget="scrollableDiv1"
                            >
                                {props.filteredInstitutes?.length !== 0
                                    ? props.filteredInstitutes?.map((item, index) => (
                                        <InstituteFilter
                                            key={index}
                                            institute_name={item}
                                            index={index}
                                            selectedInstitutes={props.selectedInstitutes.length ? props.selectedInstitutes : []}
                                            updateSelectedInstitutes={
                                                props.updateSelectedInstitutes
                                            }
                                        />
                                    ))
                                    : props.institutes.length > 0
                                        ? props.institutes.map((item, index) => {
                                            return (
                                                <InstituteFilter
                                                    key={index}
                                                    institute_name={item}
                                                    index={index}
                                                    selectedInstitutes={props.selectedInstitutes.length ? props.selectedInstitutes : []}
                                                    updateSelectedInstitutes={
                                                        props.updateSelectedInstitutes
                                                    }
                                                />
                                            );
                                        })
                                        : "No record Found"}
                            </InfiniteScroll>
                        </div>
                    </div>
                    <div className='text-center'>
                    <button onClick={()=>props.coutryApplyNow('institutes')} className='btn defaultBtn sharePgBtn my-2'>Apply Selection</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default InstitutesIndividual;
