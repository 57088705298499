import * as Actions from '../actions/degree_level.actions';

const allDegreeLevelsInitialState = {
    data: [],
};

export const allDegreeLevels = (state = allDegreeLevelsInitialState, action) => {
    switch (action.type) {
        case Actions.GET_ALL_DEGREE_LEVELS_SUCCESS: {
            return {
                ...state,
                data: action.payload
            };
        }
        case Actions.GET_ALL_DEGREE_LEVELS_FAIL: {
            return {
                ...state,
                data: null
            };
        }
        default: {
            return state;
        }
    }
};

