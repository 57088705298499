import React, { useEffect, useState } from "react";
import DegreeLevelCheckInPut from "./degree_level_check_input";

function DegreeLevelFilters(props) {
	const { degree_level, index, selectedDegreeLevels } = props;
	const [degreeLevelCheck, setDegreeLevelCheck] = useState(() => {
		return selectedDegreeLevels?.types?.includes(degree_level.slug);
	});
	const [activeClass, setActiveClass] = useState(false);
	useEffect(() => {
		if (selectedDegreeLevels.types.length >= 1) {
			if (selectedDegreeLevels.types.some((e) => e.slug === degree_level.slug)||selectedDegreeLevels.types.some((e) => e === degree_level.slug)) {
				setDegreeLevelCheck(true);
			} else {
				setDegreeLevelCheck(false);
			}
		} else {
			setDegreeLevelCheck(false);
		}
	}, [props.rand]);
	useEffect(() => {
		if (
			selectedDegreeLevels.types.length === 0 &&
			selectedDegreeLevels.levels.length === 0
		) {
			setActiveClass(false);
		} else {
			var status = true;
			if (selectedDegreeLevels.types.length >= 1) {
				let levelTypeInfo = selectedDegreeLevels.levels.find(
					(t) => t.slug === degree_level.slug
				);
				let levelTypeInfo2 = selectedDegreeLevels.levels.find(
					(t) => t === degree_level.slug
				);
				if (levelTypeInfo) {
					setActiveClass(true);
					status = false;
				}else if(levelTypeInfo2){
					setActiveClass(true);
					status = false;
				}
			}
			if (status) {
				if (selectedDegreeLevels.levels.length >= 1) {
					var levelInfo = selectedDegreeLevels.levels.find(
						(l) => l.slug === degree_level.slug
					);
					if (levelInfo) {
						setActiveClass(true);
						status = false;
					}
				}
			}
			if (status) {
				if (selectedDegreeLevels.levels.length >= 1) {
					for (let i = 0; i < degree_level.degree_levels.length; i++) {
						var level = selectedDegreeLevels.levels.find(
							(l) => l.slug === degree_level.degree_levels[i].slug
						);
						if (level) {
							setActiveClass(true);
							status = false;
							break;
						}
					}
				}
			}
			if (status) {
				setActiveClass(false);
			}
		}
	}, [props.rand]);

	const onChangeDegreeLevelCheck = (degreelevel, check) => {
		setDegreeLevelCheck(!check);
		props.updateSelectedDegreeLevels(degreelevel, "type", !check);
	};
	const arrowDownClick = (degree_Level, index) => {
		var element = document.getElementById(degree_Level);
		element.className += " filterOpen";
		var subElement = document.getElementById(degree_Level + String(index));
		subElement.className += " show";
	};
	const arrowUpClick = (degree_Level, index) => {
		var element = document.getElementById(degree_Level);
		element.className = " filterItem";
		var subElement = document.getElementById(degree_Level + String(index));
		subElement.className = " filterItemCont";
	};
	const onLevelUpdate = (obj, type, status) => {
		props.updateSelectedDegreeLevels(obj, type, status);
	};
	return (
		<>
			<div className="filterItemWrap">
				<div id={degree_level.name} className="filterItem">
					<input
						className={activeClass === true ? "childChecked" : ""}
						key={index + Math.floor(Math.random() * 1000)}
						id={'degreelevel'+index}
						type="checkbox"
						onChange={() => {
							onChangeDegreeLevelCheck(degree_level, degreeLevelCheck);
						}}
						checked={degreeLevelCheck}
					></input>
					<label
						htmlFor={'degreelevel'+index}
						className={activeClass === true ? "active" : ""}
						onClick={(e) => {
							arrowDownClick(degree_level.name, index);
						}}
					>
						{degree_level.name} 
						{degree_level.course_count
							? ` (${degree_level.course_count})`
							: degree_level.scholarship_count
							? ` (${degree_level.scholarship_count})`
							: ""}
					</label>

					<i
						className="fa fa-angle-right"
						onClick={(e) => {
							arrowDownClick(degree_level.name, index);
						}}
					></i>
					<i
						className="fa fa-angle-down"
						onClick={(e) => {
							arrowUpClick(degree_level.name, index);
						}}
					></i>
				</div>
				<div id={degree_level.name + String(index)} className="filterItemCont ">
					{degreeLevelCheck
						? degree_level.degree_levels?.map((level, index) => (
								<DegreeLevelCheckInPut
									key={index}
									level={level}
									type={degree_level.type}
									index={index}
									status={true}
									selectedDegreeLevels={selectedDegreeLevels}
									onLevelUpdate={onLevelUpdate}
								/>
						  ))
						: degree_level.degree_levels?.map((level, index) => (
								<DegreeLevelCheckInPut
									key={index}
									level={level}
									type={degree_level.type}
									index={index}
									status={false}
									selectedDegreeLevels={selectedDegreeLevels}
									onLevelUpdate={onLevelUpdate}
								/>
						  ))}
				</div>
			</div>
		</>
	);
}
export default DegreeLevelFilters;
