import React, { useEffect,useState } from 'react';
import CheckboxList from "../loaders/checklist";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingSpinner from "../loader/loader";
import SpecializationFilter from "../filters/specialization/specialization_filter";

const SpecializationIndividual = (props) => {
    const [searchTerm, setSearchTerm] = useState('')
//     useEffect(() => {
//     const delayDebounceFn = setTimeout(() => {
//       props.searchSpecializationsHandler(searchTerm)
//     }, 3000)
//     return () => clearTimeout(delayDebounceFn)
//   }, [searchTerm])
    return (
        <div className="filterBox" >
            <h6 className={"filterOpen"}>
                Specialization <i className="fa fa-angle-right" />
                <i className="fa fa-angle-down" />
            </h6>
            {props.specializationLoader === true ? (
                <CheckboxList />
            ) : (
                <div className={"content" + props.specializations ? +" show" : ""}>
                    <div className="filterSearchWrap">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Specialization"
                            onChange={(e)=>setSearchTerm(e.target.value)}
                        />
                        <i className="fa fa-search" />
                    </div>
                    {/*<div className="filterBoxScroll">*/}
                    {/*  {selectedSpecializations.map((item, index) => (*/}
                    {/*      <SelectedSpecializationFilter*/}
                    {/*          key={index}*/}
                    {/*          specialization={item}*/}
                    {/*          index={index}*/}
                    {/*          removeSelectedSpecialization={*/}
                    {/*            removeSelectedSpecialization*/}
                    {/*          }*/}
                    {/*      />*/}
                    {/*  ))}*/}
                    {/*</div>*/}
                    <div>
                        <div
                            id="scrollableDiv"
                            className='px-15'
                            onScroll={() => props.onScroll()}
                            ref={props.listInnerRef}
                            style={{ height: '300px', overflow: "auto" }}
                        >
                            {/* <InfiniteScroll
                                dataLength={props.filteredSpecializations.length} //This is important field to render the next data
                                next={(e) => props.fetchDataForSpecializations(e)}
                                hasMore={props.specHasMore}
                                loader={!props.specHasMore ? null : <LoadingSpinner/>}
                                endMessage={
                                    <p style={{textAlign: "center"}}>
                                        <b>Yay! You have seen it all</b>
                                    </p>
                                }
                                scrollableTarget="scrollableDiv"
                            > */}
                            {

                                props?.filteredSpecializations?.length !== 0 ? (
                                    props?.filteredSpecializations?.map(
                                        (specialization, index) => (
                                            <SpecializationFilter
                                                key={index}
                                                specialization={specialization}
                                                index={index}
                                                selectedSpecializations={
                                                    props.selectedSpecializations
                                                }
                                                updateSelectedSpecialization={
                                                    props.updateSelectedSpecialization
                                                }
                                                rand={Math.random()}
                                            />
                                        )
                                    )
                                ) :
                                    //     props.specializations.length > 0 ? (
                                    //     props.specializations.map((specialization, index) => {
                                    //         return (
                                    //             <SpecializationFilter
                                    //                 key={index}
                                    //                 specialization={specialization}
                                    //                 index={index}
                                    //                 selectedSpecializations={
                                    //                     props.selectedSpecializations
                                    //                 }
                                    //                 updateSelectedSpecialization={
                                    //                     props.updateSelectedSpecialization
                                    //                 }
                                    //                 rand={Math.random()}
                                    //             />
                                    //         );
                                    //     })
                                    // ) :
                                    (
                                        <p style={{ textAlign: "center" }}>No record found</p>
                                    )

                            }
                            {
                                props?.pagespecialization ?
                                    props.specializationNextDataLoader === true ?
                                        <LoadingSpinner height='10px' />
                                        // <div className="loaderCourse"></div>
                                        :
                                        null
                                    :
                                    <p style={{ textAlign: "center" }}>
                                        <b>Yay! You have seen it all</b>
                                    </p>
                            }
                            {/* </InfiniteScroll> */}
                        </div>
                        <div className='text-center'>
                            <button onClick={() => props.coutryApplyNow('special')} className='btn defaultBtn sharePgBtn my-2'>Apply Selection</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SpecializationIndividual;
